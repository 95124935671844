<template>
  <button
    class="course-button"
    :class="{
      'course-button--secondary': variation === 'secondary',
      'course-button--grey': variation === 'grey',
      'course-button--big': size === 'big',
      button__selected: selected,
      'course-button--active': active,
      'course-button--inactive': !active,
      'course-button--hidden': hidden
    }"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "appButton",
  props: {
    variation: String,
    size: String,
    text: String,
    active: Boolean,
    hidden: Boolean,
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.course-button {
  width: max-content;
  // justify-self: end !important;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.6rem;
  transform: translateY(-2.5rem);
  font-family: $font-primary-bold;
  font-weight: 100;
  font-size: $font-size-small;
  line-height: 1.2;
  background-color: $color-grey;
  pointer-events: none;

  transition: color 0.2s, background-color 0.2s;

  &--big {
    padding: 1.2rem 2.6rem;
    // font-family: $font-primary;
    // font-size: $font-size-standard;
  }

  &--active {
    background-color: $color-primary-dark;
    color: $color-white;
    // background-color: $color-secondary-dark;
    cursor: pointer;
    pointer-events: initial;

    &.course-button--secondary {
      color: $color-black;
      background-color: $color-secondary-dark;
    }

    &.course-button--grey {
      background-color: #242424;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $color-primary-light;

        &.course-button--secondary {
          background-color: $color-secondary;
        }

        &.course-button--grey {
          background-color: #333;
        }
      }

      &:active {
        background-color: $color-primary-dark;

        &.course-button--secondary {
          background-color: $color-secondary-dark;
          color: $color-black;
        }

        &.course-button--grey {
          background-color: #242424;
        }
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &.course-button--hidden {
    visibility: hidden;
    opacity: 0;
    // background-color: $color-secondary-dark;
    background-color: $color-grey;
    pointer-events: none;
    transition: opacity 0.4s, visibility 0s 0.4s, background-color 0s 0.4s;
  }

  &.course-button.course-button--inactive {
    color: $color-black;
    background-color: $color-grey;
    pointer-events: none;
  }
}
</style>
