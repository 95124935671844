<template>
  <div class="external-menu">
    <app-button
      @click="changeLanguage()"
      variation="transparent"
      size="big"
      :text="language"
    ></app-button>
    <app-button
      @click="openModal('app-login-modal')"
      variation="transparent"
      size="big"
      :text="$t('button_login')"
    ></app-button>
    <app-button
      @click="openModal('app-register-modal')"
      size="big"
      :text="$t('button_signup')"
    ></app-button>
  </div>
</template>

<script>
// import AppMenuItem from "@/components/Atoms/MenuItem.vue";
import AppButton from "@/components/Atoms/Button.vue";
// import AppBubble from "@/components/Atoms/Bubble.vue";

export default {
  name: "externalMenu",
  components: {
    // AppMenuItem,
    AppButton
    // AppBubble
  },
  data() {
    return {
      subMenuIsOpen: false,
      chosenSubMenu: 0
    };
  },
  computed: {
    language() {
      if (this.$i18n.locale === "en") {
        return "Deutsch";
      } else {
        return "English";
      }
    }
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "de";
      } else if (this.$i18n.locale === "de") {
        this.$i18n.locale = "en";
      }
    },
    openModal(modal) {
      this.$store.state.shownSidebarMenu = "none";
      this.toggleModal(modal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.external-menu {
  padding: 2.75rem 13.75rem 2.75rem 2.75rem;
  width: 32rem;
  height: 100%;
  background-color: $color-black;
  overflow: auto;

  & > *:not(:last-child) {
    margin-bottom: 2.75rem;
  }

  & > *:last-child {
    margin-top: 4.125rem;
    margin-left: 1.43rem;
  }
}
</style>
