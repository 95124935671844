<template>
  <div class="onboarding-unlock-course">
    <div class="onboarding-unlock-course__screen-container">
      <div class="onboarding-unlock-course__lock-container">
        <app-lock
          :isUnlocked="prop"
          class="onboarding-unlock-course__lock"
        ></app-lock>
      </div>
    </div>
  </div>
</template>

<script>
import AppLock from "@/components/Atoms/Icons/Lock.vue";

export default {
  components: {
    AppLock
  },
  props: {
    prop: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding-unlock-course {
  width: 100%;
  height: calc(100% - 1.6rem);

  &__screen-container {
    width: 100%;
    height: 100%;
    // background-color: red;
    background: repeating-linear-gradient(
      -75deg,
      #eee,
      #eee 8%,
      #fff 8%,
      #fff 16%
    );
  }

  &__lock-container {
    position: absolute;
    right: -1.667rem;
    top: -1.667rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    // background-color: $color-primary;
    // border: 0.3rem solid $color-primary-darker;
    background: radial-gradient($color-primary, $color-primary-dark);
    // background-color: #505050;
    // ackground-color: $color-secondary;
  }

  &__lock {
    position: absolute;
    left: 50%;
    top: 47.5%;
    transform: translate(-50%, -50%);
    width: 3.6rem;
    height: 3.6rem;
  }
}
</style>
