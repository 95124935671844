<template>
  <div class="modal tabstart" tabindex="0">
    <div class="modal__top-container">
      <h2 class="modal__heading">
        {{ $t("course_language_modal").heading }}
      </h2>
      <app-lottie
        :lottie="'earth7n'"
        :loop="true"
        class="modal__animation"
      ></app-lottie>
    </div>
    <div class="modal__text-container">
      <div @mousedown="setLanguage('de')" class="modal__language-choice">
        <div
          class="modal__language-circle"
          :class="{
            'modal__language-circle--is-selected': $i18n.locale === 'de'
          }"
        ></div>
        <div class="modal__language">Deutsch</div>
      </div>
      <div @mousedown="setLanguage('en')" class="modal__language-choice">
        <div
          class="modal__language-circle"
          :class="{
            'modal__language-circle--is-selected': $i18n.locale === 'en'
          }"
        ></div>
        <div class="modal__language">English</div>
      </div>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButtonXRound,
    AppLottie
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.modal {
  width: 46rem;
  border-radius: 1rem;
  font-family: $font-primary;
  color: $color-white;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3.3rem;
    }
  }

  &__animation {
    margin: 4.2rem 0 2.6rem;
    transform: translateX(calc(23rem - 50%));
    width: 20rem;
    height: 22.5rem;

    @include respond(tab-port) {
      transform: translate(calc(25rem - 50%), 0.9rem);
      height: 24.5rem;
    }

    @include respond(phone) {
      transform: translate(calc(min(207px, 50vw - 0.75rem) - 50%), -0.9rem);
      margin: 5.2rem 0 6.3rem;
      width: 22rem;
      height: 21.1rem;
    }
  }

  &__text-container {
    padding: 1.5rem 2.75rem;
    height: 8.5rem;
    font-size: 2rem;
    background-color: #151515;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @include respond(phone) {
      padding: 0.5rem 2.5rem 2.5rem 2.5rem;
      height: 6.5rem;
      font-size: 3rem;
      line-height: 1.45;
    }
  }

  &__language-choice {
    position: relative;
    display: inline-block;
    height: 3.6rem;
    margin-top: 0.8rem;

    cursor: pointer;
    transition: color 0.2s;

    &:first-child {
      margin-right: 3.3rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $color-primary-light;
      }
    }
  }

  &__language-circle {
    position: absolute;
    // box-sizing: content-box;
    top: 0.3rem;
    width: 3rem;
    height: 3rem;
    border: 0.2rem solid #3a3a3a;
    border-radius: 50%;
    background-color: $color-black;

    &--is-selected {
      &::after {
        @include pseudo-element;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        background-color: $color-primary-light;

        @include respond(phone) {
          // left: 0.65rem;
          // top: 0.65rem;
          width: 1.7rem;
          height: 1.7rem;
        }
      }
    }
  }

  &__language {
    display: inline-block;
    margin-left: 4.2rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    line-height: 3.6rem;

    @include respond(phone) {
      font-size: 3rem;
      line-height: 3rem;
      transform: translateY(-0.25rem);
    }
  }
}
</style>
