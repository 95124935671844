<template>
  <div class="course-start">
    <h2 class="course-start__heading">
      {{ t($store.state.headingOfChosenLesson) }}
    </h2>
    <h3 class="course-start__subheading">{{ $t("course_start").goal }}:</h3>
    <p class="course-start__paragraph">
      {{ t(content.goal) }}
    </p>
    <h3 class="course-start__subheading">
      {{ $t("course_start").description }}:
    </h3>
    <p class="course-start__paragraph">
      {{ t(content.description) }}
    </p>
    <h3 class="course-start__subheading">
      {{ $t("course_start").classification }}:
    </h3>
    <p class="course-start__paragraph">
      {{ t(content.classification) }}
    </p>

    <div class="course-start__box-bottom">
      <h3 class="course-start__subheading course-start__subheading--bottom">
        {{ $t("course_start").requirements }}:
      </h3>
      <div class="course-start__box-bottom-right">
        <ul class="course-start__prerequisite-list">
          <div
            v-for="prerequisite in content.prerequisites"
            :key="prerequisite"
            class="course-start__prerequisite-list-item"
          >
            <app-checkmark-blue
              alt="A checkmark marking a processed task"
              class="course-start__checkmark"
              :class="{
                'course-start__checkmark--blue':
                  prerequisite in $store.state.userDataLectures &&
                  $store.state.userDataLectures[prerequisite].finished
              }"
            ></app-checkmark-blue>
            <div
              class="course-start__prerequisite"
              @mousedown="
                $emit('changedToPrerequisiteCourse', [
                  $store.state.chosenLesson,
                  prerequisite
                ])
              "
            >
              {{ t($store.state.listOfLessonNames[prerequisite]) }}
            </div>
          </div>
          <div
            v-if="
              content.prerequisites === undefined ||
                content.prerequisites.length === 0
            "
            class="course-start__prerequisite-list-item"
          >
            <app-checkmark-blue
              alt="A checkmark marking a processed task"
              class="course-start__checkmark course-start__checkmark--blue"
            ></app-checkmark-blue>
            <div
              class="course-start__prerequisite course-start__no_knowledge_required"
            >
              {{ $t("course_start").no_knowledge_required }}
            </div>
          </div>
        </ul>
      </div>
      <h3 class="course-start__subheading course-start__subheading--bottom">
        {{ $t("course_start").outlook }}:
      </h3>
      <p class="course-start__outlook">
        {{ t(content.outlook) }}
      </p>
    </div>
    <div class="course-start__button-container">
      <app-course-button
        @mousedown="backButtonAction()"
        variation="grey"
        size="big"
        :active="true"
        :text="$t('course_start').back_button"
        class="course-start__back-button"
      ></app-course-button>
      <app-course-button
        @mousedown="startCourse()"
        size="big"
        :active="true"
        :text="startButtonText"
        class="course-start__start-button"
      ></app-course-button>
    </div>
  </div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppCourseButton from "@/components/Atoms/CourseButton.vue";

export default {
  components: {
    AppCheckmarkBlue,
    AppCourseButton
  },
  props: {
    content: Object
  },
  emits: ["changedToPrerequisiteCourse"],
  computed: {
    startButtonText() {
      if (
        this.o([
          this.$store.state.userDataLectures,
          this.$store.state.chosenLesson,
          "started",
          false
        ]) === true
      ) {
        return this.$t("course_start")["start_button"][1];
      } else {
        return this.$t("course_start")["start_button"][0];
      }
    }
  },
  methods: {
    backButtonAction() {
      let previous = this.$store.state.courseWasReachedFrom[
        this.$store.state.courseWasReachedFrom.length - 1
      ];

      if (previous === "home") {
        this.$store.state.courseWasReachedFrom = ["home"];
        this.$store.state.coursePage = -3;
        this.goTo("/overview");
      } else if (previous === "contents") {
        this.$store.state.courseWasReachedFrom = ["contents"];
        this.setPage("--");
      } else {
        this.$store.state.courseWasReachedFrom.splice(-1);
        this.$emit("changedToPrerequisiteCourse", ["noNew", previous]);
      }
    },
    startCourse() {
      // Check, if prerequisite courses were made
      let allPrerequisiteCoursesMade = true;

      if (
        !this.o([
          this.$store.state.userDataLectures,
          this.$store.state.chosenLesson,
          "isUnlocked",
          false
        ])
      ) {
        let prerequisites = this.content.prerequisites || [];
        for (let i = 0; i < prerequisites.length; i++) {
          if (
            !(prerequisites[i] in this.$store.state.userDataLectures) ||
            !(
              "finished" in this.$store.state.userDataLectures[prerequisites[i]]
            ) ||
            !this.$store.state.userDataLectures[prerequisites[i]].finished
          ) {
            allPrerequisiteCoursesMade = false;
          }
        }
      }

      if (!allPrerequisiteCoursesMade) {
        if (!this.$store.state.userWasAskedAboutSound) {
          this.toggleModal(
            "app-audio-choice",
            "audiochoice",
            "app-unlock-course"
          );
        } else {
          this.toggleModal("app-unlock-course", "audiochoice", "none");
        }
      } else if (
        this.o([
          this.$store.state.userDataLectures,
          this.$store.state.chosenLesson,
          "started",
          false
        ]) === true
      ) {
        if (!this.$store.state.userWasAskedAboutSound) {
          this.toggleModal(
            "app-audio-choice",
            "audiochoice",
            "app-resume-course"
          );
        } else {
          this.toggleModal("app-resume-course", "audiochoice", "none");
        }
      } else {
        if (!this.$store.state.userWasAskedAboutSound) {
          this.toggleModal("app-audio-choice", "audiochoice", "none");
        } else {
          // Reset remaining data
          this.resetData();
          // // //

          this.setPage("++");
        }
      }
    },
    resetData() {
      this.$store.dispatch("resetLectureDataForFinishedCourse");
      if (
        !(this.$store.state.chosenLesson in this.$store.state.userDataLectures)
      ) {
        this.$store.state.userDataLectures[this.$store.state.chosenLesson] = {};
      }
      this.$store.state.userDataLectures[this.$store.state.chosenLesson][
        "started"
      ] = false;
      this.$store.state.userDataLectures[this.$store.state.chosenLesson][
        "firstUnprocessedPage"
      ] = 1;

      this.$store.dispatch("updateCourse");
      this.$store.dispatch("getUserData");
    }
  },
  created() {
    setTimeout(() => {
      this.$store.state.timeRequired = this.content.timeRequired;
      let breadcrumbNavigation = document.querySelector(
        ".header__breadcrumb-navigation"
      );
      let breadcrumbHeight =
        this.height(breadcrumbNavigation) / this.remToPx(1);
      const doc = document.documentElement;
      doc.style.setProperty("--breadcrumb-height", breadcrumbHeight + "rem");
    }, 0);

    // Reset data of finished course before start
    if (
      this.o([
        this.$store.state.userDataLectures,
        this.$store.state.chosenLesson,
        "started",
        false
      ]) === "finished" &&
      this.$store.state.courseWasLeftOnce
    ) {
      this.resetData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

::selection {
  // background-color: transparent;
  // color: currentColor;
}

.course-start {
  // position: absolute;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
  padding-top: 13.6rem;
  color: $color-white;
  // background-color: #131313;
  background-color: #101010;

  @include grid-template-columns;
  align-content: start;

  @include respond(phone) {
    padding-top: calc(11.25rem + var(--breadcrumb-height, 3.2rem));
    background-color: $color-black;
  }

  &__heading {
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-weight: 400;

    @include respond(tab-land) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 2 / 5;
      font-size: 4rem;
    }
  }

  &__subheading {
    grid-column: 3 / 4;
    font-size: 2.6rem;

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 2 / 5;
      // margin-bottom: 0.1rem;
      font-size: 2.8rem;
    }

    &--bottom {
      grid-column: 1 / 2;
      margin-right: 1.9rem;

      @include respond(phone) {
        // grid-column: 2 / 3;
      }
    }
  }

  &__paragraph {
    width: min(70rem, 100vw - 2 * #{$side-margin});
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-size: 2.2rem;
    transform: rotateX(0);
    // color: #ccc;
    color: #aaa;

    @include respond(tab-land) {
      width: min(80rem, 100vw - 2 * #{$side-margin-land});
      margin-bottom: 4.125rem;
      font-size: 2.6rem;
      color: #ccc;
    }

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 2 / 5;
      width: 100%;
      font-size: 2.6rem;
      line-height: 1.4;
      color: #bbb;
    }
  }

  &__box-bottom {
    grid-column: 3 / 4;
    width: min(70rem, 100vw - 2 * #{$side-margin});
    margin: 1.375rem 0 23rem;

    display: grid;
    grid-template-columns: min-content 1fr;

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 2 / 5;
      grid-row-gap: 1.5rem;
      margin: 1.375rem 0 22.3rem;
      width: 100%;
    }
  }

  &__box-bottom-right {
    width: 100%;
    height: 100%;
    padding-top: 0.45rem;
  }

  &__prerequisite-list {
    list-style: none;
    // color: #ddd;
    color: #aaa;

    @include respond(tab-land) {
      color: #ccc;
    }
  }

  &__prerequisite-list-item {
    position: relative;

    display: grid;
    grid-template-columns: 4.45rem 1fr;

    @include respond(phone) {
      grid-template-columns: 3.9rem 1fr;
    }
  }

  &__checkmark {
    grid-column: 1 / 2;
    transform: translateY(0.5rem);
    width: 2.4rem;
    height: 2.4rem;

    filter: brightness(1.5) invert(1) grayscale(1);

    &--blue {
      filter: none;
    }
  }

  &__prerequisite {
    position: relative;
    grid-column: 2 / 3;
    margin-bottom: 0.75rem;
    width: max-content;
    padding-right: 1.5rem;
    font-size: 2.2rem;
    cursor: pointer;

    transition: color 0.2s;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #28aff8;
      }
    }

    @include respond(tab-land) {
      font-size: 2.6rem;
    }

    @include respond(phone) {
      padding-right: 0;
      width: 100%;
      // font-size: 2.5rem;
      line-height: 1.3;
      color: #bbb;
    }
  }

  &__no_knowledge_required {
    cursor: initial;

    &:hover {
      // color: #ddd;
      color: #aaa;

      @include respond(tab-land) {
        color: #ccc;
      }
    }
  }

  &__outlook {
    grid-column: 2 / 3;
    margin-top: 0.2rem;
    width: 100%;
    font-size: 2.2rem;
    // color: #ddd;
    color: #aaa;

    @include respond(tab-land) {
      font-size: 2.6rem;
      color: #ccc;
    }

    @include respond(phone) {
      // font-size: 2.5rem;
      line-height: 1.3;
      color: #bbb;
    }
  }

  &__button-container {
    position: absolute;
    bottom: 0;
    grid-column: 3 / 4;
    padding-bottom: 12rem;

    display: grid;
    grid-template-columns: 16rem 16rem;

    @include respond(phone) {
      grid-template-columns: min(20rem, 50vw - 0.375rem) min(
          20rem,
          50vw - 0.375rem
        );
    }

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 2 / 5;
      padding-bottom: 11.3rem;
    }

    & * {
      transform: translateY(0%);
    }
  }

  &__back-button {
    width: 100%;
    border-radius: 0.2rem !important;
    background-color: #333 !important;

    @include respond(phone) {
      height: 5.1rem;
      border-radius: 0.6rem !important;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #444 !important;
      }
    }
  }

  &__start-button {
    margin-left: 1.9rem;
    width: 100%;
    border-radius: 0.2rem !important;
    background-color: $color-primary-dark;

    &:hover {
      background-color: $color-primary;
    }

    @include respond(phone) {
      margin-left: 0.75rem;
      height: 5.1rem;
      border-radius: 0.6rem !important;
    }
  }
}
</style>
