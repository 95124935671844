<template>
  <div class="introduction3">
    <div class="introduction3__container">
      <div class="introduction3__smartphone-container">
        <img
          src="@/assets/Home/Introduction3/smartphone_black3.png"
          alt="A black smartphone"
          class="introduction3__smartphone"
        />
        <div class="introduction3__content-container">
          <app-smartphone3
            :checkmarkIsShown="checkmarkIsShown"
          ></app-smartphone3>
          <div class="introduction3__smartphone-header">
            <div class="introduction3__smartphone-section">
              4<span class="introduction3__slash">/</span>12
            </div>
            <div class="introduction3__smartphone-progress">
              <div
                aria-hidden="true"
                class="introduction3__smartphone-progress-bar"
              ></div>
            </div>
            <div class="introduction3__smartphone-menu">
              <svg>
                <line x1="0" y1="0" x2="100%" y2="0" />
                <line x1="0" y1="0" x2="100%" y2="0" />
                <line x1="0" y1="0" x2="100%" y2="0" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="introduction3__text-container">
        <div class="introduction3__explanation-container">
          <div class="introduction3__explanation--2">
            <h2 class="introduction3__screen-heading">
              {{ $t("step3_heading")[0] }}&nbsp; {{ $t("step3_heading")[1] }}
            </h2>
            <p class="introduction3__screen-description">
              {{ $t("step3_paragraph") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSmartphone3 from "@/assets/Home/Introduction3/Smartphone3.vue";

export default {
  data() {
    return {
      section: {},
      smartphone: {},
      content: {},
      checkmarkIsShown: false
    };
  },
  components: {
    AppSmartphone3
  },
  methods: {
    scrollHandler() {
      let sectionTop = this.top(this.section);

      let backgroundColor = this.value(
        sectionTop,
        26,
        0,
        this.$store.state.windowHeight / 2,
        0,
        "in",
        1
      );
      this.introductionContainer.style.backgroundColor =
        "rgba(" +
        backgroundColor +
        " ," +
        backgroundColor +
        " ," +
        backgroundColor +
        ")";

      let scaleOverflow = this.$store.state.scaleOverflow;
      /* let scaleOverflow2 = this.value(
        sectionTop,
        1.2 * scaleOverflow,
        0.8 * scaleOverflow,
        this.$store.state.windowHeight,
        0,
        "inOut",
        2
      );
      this.smartphone.style.transform =
        "translate(-25rem, 6rem) scale(" + scaleOverflow2 + ")"; */

      let mediaWidth = window.matchMedia("(max-width: 600px)");
      let mediaMediumWidth = window.matchMedia("(max-width: 1000px)"); // was: 750px
      let mediaHeight = window.matchMedia("(max-height: 520px)");

      let scaleFactor =
        1 + (this.$store.state.windowWidth - 750) / (750 * 4.444);

      if (!mediaMediumWidth.matches && !mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(-11%, 0, 0) scale(" + scaleOverflow1 + ")"; // was: -9%
      } else if (!mediaWidth.matches && !mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.35 * scaleFactor * scaleOverflow,
          0.9 * scaleFactor * scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translate(-42.5%, 6.5rem) scale(" +
          scaleOverflow1 +
          ")";
      } else if (!mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.2 * scaleOverflow,
          0.8 * scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translate(-42.5%, 6.5rem) scale(" +
          scaleOverflow1 +
          ")";
      } else {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          1.2 * scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 4.25rem, 0) translateX(-42.5%) scale(" +
          scaleOverflow1 +
          ")";
      }

      let translateExercise = this.value(
        sectionTop,
        0,
        4,
        this.$store.state.windowHeight / 7,
        -(2.5 / 21) * this.$store.state.windowHeight - 35, // -25
        "inOut",
        2
      );
      let translateZipper1 = this.value(
        sectionTop,
        0,
        12, // was: 12 or 34
        this.$store.state.windowHeight / 7,
        -(2.5 / 21) * this.$store.state.windowHeight - 35, // -25
        "in", // was: inOut
        2
      );
      let translateZipper3 = this.value(
        sectionTop,
        0,
        -28, // was: -29.5
        this.$store.state.windowHeight / 7,
        -(2.5 / 21) * this.$store.state.windowHeight - 35, // -25
        "inOut",
        2
      );
      let translateZipper1X = this.value(
        sectionTop,
        0,
        -45, // was: -45
        -(2.5 / 21) * this.$store.state.windowHeight - 70,
        -(6 / 21) * this.$store.state.windowHeight - 35,
        "in",
        2
      );
      let progressWidth = this.value(
        sectionTop,
        0.45,
        0.575,
        this.$store.state.windowHeight / 7,
        -(2.5 / 21) * this.$store.state.windowHeight - 35, // -25
        "inOut",
        2
      );
      this.exercise.style.transform =
        "translateY(" + translateExercise + "rem)";
      this.zipper1.style.transform =
        "translate(" + translateZipper1X + "rem, " + translateZipper1 + "rem)";
      this.zipper2.style.transform =
        "translate(" + translateZipper1X + "rem, " + translateZipper1 + "rem)";
      this.zipper3.style.transform = "translateY(" + translateZipper3 + "rem)";
      this.progressBar.style.transform = "scaleX(" + progressWidth + ")";

      let opacityExplanation = this.value(sectionTop, 0, 1, 300, 0, "inOut", 2);
      let translateYExplanation = this.value(
        sectionTop,
        3.5,
        0,
        300,
        0,
        "inOut",
        2
      );
      this.introductionExplanation.style.opacity = opacityExplanation;
      this.introductionExplanation.style.transform =
        "translateY(" + translateYExplanation + "rem)";

      if (sectionTop < -(2.5 / 21) * this.$store.state.windowHeight - 35) {
        this.checkmarkIsShown = true;
      } else {
        this.checkmarkIsShown = false;
      }
    }
  },
  created() {
    setTimeout(() => {
      this.section = document.querySelector(".introduction3");
      this.introductionContainer = document.querySelector(
        ".introduction3__container"
      );

      this.smartphone = document.querySelector(
        ".introduction3__smartphone-container"
      );

      this.exercise = document.querySelector(".smartphone3__exercise-element");
      this.zipper1 = document.querySelector(".smartphone3__zipper-element--1");
      this.zipper2 = document.querySelector(".smartphone3__zipper-element--2");
      this.zipper3 = document.querySelector(".smartphone3__zipper-element--3");
      this.progressBar = document.querySelector(
        ".introduction3__smartphone-progress-bar"
      );
      this.introductionExplanation = document.querySelector(
        ".introduction3__explanation--2"
      );
      this.scrollHandler();

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.introduction3 {
  position: relative;
  // height: calc(200vh + 85rem);
  min-height: calc(200vh + 50rem);
  z-index: -4;
  margin-bottom: -100vh;
  opacity: 0.9999;

  &__container {
    position: sticky;
    left: 0;
    top: 100vh;
    transform: translateY(-100vh);
    width: 100%;
    height: 100vh;
    border: 1px solid rgba(black, 0.01); // Solves artefact issue on Chrome

    overflow: hidden;

    @include respond(tiny-height) {
      height: calc(100vh + 23.5rem);
    }
  }

  &__smartphone-container {
    position: absolute;
    left: 43%;
    top: max(71vh - 78.2rem, 8.5vh);
    width: 60rem;
    height: 78rem;
    transform-origin: top;
    will-change: transform;
  }

  &__smartphone {
    position: absolute;
    bottom: 0;
    width: 100%;

    // opacity: 0;
  }

  &__content-container {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 39rem;
    height: 76rem;

    transform: translate(-46.9%, -2.25rem) skewY(1deg) rotate(-1.25deg)
      perspective(200rem) rotateX(17deg) rotateZ(25deg) scale(0.85);
    // transform: scale(1.6) translateY(5rem);
    overflow: hidden;
  }

  &__smartphone-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10.5rem;
    transform: translateY(-2.5rem) perspective(0);

    &::after {
      @include pseudo-element;
      left: 2.2rem;
      top: 10.5rem;
      width: calc(100% - 4.4rem);
      height: 1px;
      background-color: #424242;
    }
  }

  &__smartphone-section {
    position: absolute;
    left: 2.2rem;
    top: 3.65rem;
    font-family: $font-primary-bold;
    font-size: 2.5rem;
    color: #7cccff;
    color: #2fbcf8; /////

    @include respond(desktop-big) {
      font-size: 2.63889rem;
    }
    @include respond(desktop) {
      font-size: 2.8125rem;
    }
    @include respond(tab-land) {
      font-size: 2.91667rem;
    }
  }

  &__slash {
    margin: 0 0.3rem;
  }

  &__smartphone-progress {
    position: absolute;
    left: 50%;
    top: 5.35rem;
    width: 15rem;
    height: 0.9rem;
    transform: translateX(-50%);
    border-radius: 10rem;
    // background-color: #333;
    background-color: #222;
    overflow: hidden;
  }

  &__smartphone-progress-bar {
    width: 100%;
    height: 100%;
    // background-color: #0d7bc5;
    background-color: #2fbcf8;
    transform: scaleX(0.45);
    transform-origin: left;
  }

  &__smartphone-menu {
    position: absolute;
    right: 2.25rem;
    top: 4.7rem;
    width: 2.5rem;
    height: 1.9rem;

    & svg {
      position: absolute;
      width: 100%;

      line {
        stroke: #eee;
        stroke-width: 1.5;

        &:first-child {
          transform: translateY(1px);
        }

        &:nth-child(2) {
          transform: translateY(calc(0.85rem + 1px));
        }

        &:nth-child(3) {
          transform: translateY(calc(1.7rem + 1px));
        }
      }
    }
  }

  &__text-container {
    position: absolute;
    left: 50vw;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    height: 25vh;
    transform: translateX(-50%);
    font-family: $font-primary-bold;
    font-size: 6.5rem;
    color: #bbb;
  }

  &__explanation-container {
    position: absolute;
    width: 100%;
    transform: translateY(min(6.5rem, calc(25vh - 100% - 4.5rem)));
  }

  &__explanation--2 {
    width: 100%;

    display: grid;
    grid-template-columns: 4rem 1fr $width-center 1fr 4rem;

    @include respond(tab-port) {
      // was: tab-land
      grid-template-columns: 3rem 0 1fr 0 3rem;
    }

    @include respond(phone) {
      grid-template-columns: 1.5rem 0 1fr 0 1.5rem;
    }
  }

  &__screen-heading {
    grid-column: 3 / 4;

    width: 57rem;
    font-family: $font-primary-bold;
    font-size: 2.8rem;
    margin-bottom: 2rem;
    color: $color-white;
    z-index: 1;
    line-height: 1.3;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__screen-description {
    grid-column: 3 / 4;

    width: 54rem;
    color: rgb(200, 200, 200);
    font-family: $font-primary;
    font-size: 2.2rem;
    z-index: 1;

    @include respond(phone) {
      width: 100%;
      font-size: 2.6rem;
      line-height: 1.4;
      color: rgb(220, 220, 220);
    }
  }
}
</style>
