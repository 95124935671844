<template>
  <button
    type="button"
    onclick="this.blur()"
    @mouseenter="descriptionCanBeShown = true"
    @mousedown="descriptionCanBeShown = false"
    class="round-button"
    :class="{
      'round-button__active': active,
      'round-button__description-can-be-shown':
        descriptionCanBeShown &&
        !($store.state.TOCisOpen && icon === 'contents')
    }"
  >
    <img
      :src="require('@/assets/Menus/' + icon + '.svg')"
      :alt="capitalize(icon) + ' icon'"
      class="round-button__icon"
      :class="{ 'round-button__icon--smaller': icon === 'exit_18' }"
    />
    <div class="round-button__description">{{ text }}</div>
  </button>
</template>

<script>
export default {
  name: "menuItem",
  props: {
    icon: String,
    text: String,
    active: Boolean
  },
  data() {
    return {
      descriptionCanBeShown: true
    };
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.round-button {
  @include button-settings;

  z-index: 3000000;
  // transform: translateZ(10px);
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  // background-color: #303030;
  background-color: #282828;

  @include respond(tab-port) {
    transform: scale(1.1);
  }

  &:hover {
    background-color: #444;
  }

  &__active {
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary-light;
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;

    // filter: invert(1);
    // width: 2.4rem;
    // height: 2.4rem;

    &--smaller {
      width: 1.725rem;
      height: 1.725rem;
    }
  }

  &__description {
    position: absolute;
    top: 4.95rem;
    transform: translateX(calc(1.8rem - 50%));
    padding: 0.9rem 1.4rem;
    white-space: nowrap;
    // border-radius: 0.6rem;
    // border-radius: 10rem;
    border-radius: 0.2rem;
    font-family: $font-primary-bold;
    font-size: $font-size-smallest;
    color: $color-white;
    background-color: $color-grey-dark;
    pointer-events: none;

    visibility: hidden;
    opacity: 0;

    transition: visibility 0s 0.3s, opacity 0.3s; // was: delay 0.2s
  }

  .round-button__description-can-be-shown.round-button:hover &__description {
    visibility: visible;
    opacity: 1;

    transition: visibility 0s 0.25s, opacity 0.1s 0.25s;
  }
}
</style>
