<template>
  <button class="menu-item">
    <div class="menu-item__icon-container">
      <img
        :src="require('@/assets/Menus/' + icon + '.svg')"
        :alt="capitalize(icon) + ' icon'"
        class="menu-item__icon"
      />
    </div>
    <span
      class="menu-item__text"
      :class="{ 'menu-item__arrow-is-shown': arrow }"
    >
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: "menuItem",
  props: {
    icon: String,
    text: String,
    arrow: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.menu-item {
  @include button-settings;
  display: block;
  width: 100%;
  height: 5.5rem;
  padding: 0 1.75rem;
  font-size: 2rem;
  font-weight: 900;
  color: $color-white;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #444;
      // background-color: #222;
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &::before {
    @include pseudo-element;
    transform: translate(0.1rem, -0.775rem);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #3a3a3a;
  }

  &.main-menu__submenu-heading {
    &::before {
      background-color: transparent;
    }
  }

  &__icon {
    display: inline;
    position: absolute;
    left: 2.6rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;

    .main-menu__submenu-heading & {
      transform: translate(0rem, -50%);
      cursor: pointer;
    }
  }

  .main-menu__submenu-heading &__icon-container {
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover::before {
        // background-color: #666;
        // background-color: #3a3a3a;
        background-color: #444;
      }
    }

    &::before {
      @include pseudo-element;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      transform: translate(0.1rem, -0.55rem);
      transition: background-color 0.2s;
    }
  }

  &__text {
    display: block;
    margin: 0 8rem 0 5.25rem; //
    text-align: left;
    line-height: 1;

    &.menu-item__arrow-is-shown::before,
    &.menu-item__arrow-is-shown::after {
      @include pseudo-element;
      right: 2rem;
      width: 1.25rem;
      height: 2px;
      border-radius: 10rem;
      background-color: #999;
    }

    &.menu-item__arrow-is-shown::before {
      transform: translate(0.2rem, 0.55rem) rotate(45deg);
    }

    &.menu-item__arrow-is-shown::after {
      transform: translate(0.2rem, -0.7rem) rotate(-45deg);
    }
  }
}

.main-menu__submenu-heading {
  font-family: $font-primary-bold;
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  cursor: initial;

  &:hover {
    background-color: transparent;
  }
}
</style>
