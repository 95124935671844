<template>
  <div
    @click="initializeAudio()"
    class="app"
    :class="{
      'app__not-authenticated': !$store.state.isAuthenticated,
      'app__handle-overflow':
        $store.state.isAuthenticated || $route.path.split('/')[1].length === 0
    }"
  >
    <app-header></app-header>
    <router-view />
    <p v-if="$store.state.typesetMathIsDeactivated" class="app__translation">
      {{ $store.state.courseDataNew }}
    </p>
    <app-course
      v-if="$store.state.isAuthenticated"
      v-show="$route.path.split('/')[1] === 'course'"
      class="router"
    ></app-course>
    <app-footer-navigation
      class="app__footer-navigation"
      :class="{
        'app__footer-navigation--is-shown':
          $store.state.isAuthenticated && $store.state.coursePage < 0
      }"
    ></app-footer-navigation>
    <app-modal></app-modal>
    <app-alert></app-alert>
    <app-sidebar-menu></app-sidebar-menu>
    <app-onboarding
      v-if="$store.state.isAuthenticated && !$store.state.onboardingIsHidden"
    ></app-onboarding>
    <app-glossary-modal
      :topic="$store.state.glossaryTopic"
      :subtopic="$store.state.glossarySubtopic"
      :paragraph="$store.state.glossaryParagraph"
      :left="$store.state.glossaryLeft"
      :top="$store.state.glossaryTop"
      :arrow="$store.state.glossaryArrowShift"
      :isShown="$store.state.glossaryIsShown"
    ></app-glossary-modal>
    <app-lottie
      v-if="
        $store.state.spinnerIsShown ||
          (!$store.state.userDataIsFetched && $store.state.isAuthenticated)
      "
      :lottie="'loading'"
      :loop="true"
      class="app__spinner"
    ></app-lottie>
    <p
      v-if="
        $store.state.spinnerIsShown ||
          (!$store.state.userDataIsFetched && $store.state.isAuthenticated)
      "
      class="app__spinner-text"
    >
      {{ $t("loading") }} ...
    </p>
    <teleport to="body">
      <div
        class="app__change-orientation"
        :class="{
          'app__change-orientation--shown': $store.state.isAuthenticated
        }"
      >
        <div class="app__orientation-container">
          <app-lottie
            :lottie="'orientation_change'"
            :loop="true"
            class="app__orientation-lottie"
            :class="{
              'app__landscape-primary': windowOrientation === 'landscapePrimary'
            }"
          ></app-lottie>
          <p class="app__change-orientation-text">
            {{ $t("turn_device") }}
          </p>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
// import firebase from "firebase";
// require("firebase/auth");

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

import AppHeader from "@/components/Organisms/Header/Header.vue";
import AppFooterNavigation from "@/components/Organisms/FooterNavigation.vue";
import AppModal from "@/components/Templates/Modal.vue";
import AppAlert from "@/components/Templates/Alert.vue";
import AppSidebarMenu from "@/components/Templates/SidebarMenu.vue";
import AppOnboarding from "@/components/Organisms/Onboarding.vue";
import AppGlossaryModal from "@/components/Organisms/GlossaryModal.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

import AppCourse from "@/components/Views/Course.vue";

export default {
  name: "app",
  components: {
    AppHeader,
    AppFooterNavigation,
    AppModal,
    AppAlert,
    AppSidebarMenu,
    AppOnboarding,
    AppGlossaryModal,
    AppLottie,

    AppCourse
  },
  data() {
    return {
      positionHasToBeDetermined: true,
      lastScrolledPosition: 0,
      windowOrientation: "portrait",
      recentInitialScale: 1,

      paidFor: false
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    }
  },
  methods: {
    determineScreenValues() {
      // alert(1);
      // this.$store.state.windowWidth = window.innerWidth;
      this.$store.state.windowHeight = Math.max(
        this.$store.state.windowHeight,
        window.innerHeight
      );
      if (
        this.$store.state.windowWidth !== window.innerWidth ||
        this.$store.state.windowOuterWidth !== window.outerWidth
        // this.$store.state.windowOuterHeight !== window.outerHeight // !!!!!!!!
      ) {
        this.$store.state.windowWidth = window.innerWidth;
        this.$store.state.windowOuterWidth = window.outerWidth;
        this.$store.state.windowOuterHeight = window.outerHeight;
        this.$store.state.windowHeight = window.innerHeight;

        this.$store.state.devicePixelRatio = window.devicePixelRatio;
      }

      this.$store.state.remValue = this.remToPx(1);
      this.$store.state.scaleOverflow = Math.min(
        1,
        (0.66 * this.$store.state.windowHeight) /
          (80 * this.$store.state.remValue)
      );

      if (window.orientation === -90) {
        this.windowOrientation = "landscapePrimary";
      } else {
        this.windowOrientation = "other";
      }

      // Set number of zigzag Jags for zipper elements (with total of 1.5rem padding)
      let windowWidthInRem =
        this.$store.state.windowWidth / this.$store.state.remValue - 1.5;
      let numberOfZigZagJags = Math.floor(windowWidthInRem / 3.4) + 0.5; // with width at least 3.4rem
      let widthZigZags = windowWidthInRem / numberOfZigZagJags;
      let widthOfZigZagJags = widthZigZags + "rem";
      const doc = document.documentElement;
      doc.style.setProperty("--width-of-ZZJ", widthOfZigZagJags);

      // Set CSS variable with window inner height
      doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
      setTimeout(() => {
        doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
      }, 100);
    },
    determineValuesOfScreen() {
      // this.determineScreenValues();
      setTimeout(() => {
        this.determineScreenValues();
      }, 0);
      setTimeout(() => {
        this.determineScreenValues();

        /* setTimeout(() => {
          this.scrollToTop();
        }, 2000); */
      }, 250);
    },
    smoothScroll(target, speed, smooth) {
      if (this.$store.state.isAuthenticated) {
        return;
      }

      // this.hero = document.querySelector(".app");
      let vm = this;

      if (target === document) {
        target =
          document.scrollingElement ||
          document.documentElement ||
          document.body.parentNode ||
          document.body;
      } // cross browser support for document scrolling

      let moving = false;
      let pos = target.scrollTop;

      let frame =
        target === document.body && document.documentElement
          ? document.documentElement
          : target; // for Safari

      target.addEventListener("mousewheel", scrolled, { passive: false });
      target.addEventListener("DOMMouseScroll", scrolled, { passive: false });

      function scrolled(e) {
        if (
          vm.$store.state.shownModal !== "none" ||
          vm.$store.state.alertText !== ""
          // vm.modalIsOpen ||
          // vm.$store.state.alertIsShown ||
          // vm.$store.state.coursePage >= 0
        ) {
          e.preventDefault();
          return;
        }

        let appTop = vm.top(vm.hero);
        // let difference = Math.abs(vm.lastScrolledPosition - vm.top(vm.hero));
        let difference = Math.abs(vm.lastScrolledPosition - appTop);

        if (difference >= 15) {
          // pos = -vm.top(vm.hero);
          pos = -appTop;
          // alert(1);
        }

        if (vm.$store.state.isScrolling) {
          e.preventDefault();
          return;
        }

        if (vm.positionHasToBeDetermined) {
          pos = target.scrollTop;
          vm.positionHasToBeDetermined = false;
        }

        e.preventDefault(); // disable default scrolling

        var delta = normalizeWheelDelta(e);

        pos += -delta * speed;
        pos = Math.max(
          0,
          Math.min(pos, target.scrollHeight - frame.clientHeight)
        ); // limit scrolling
        if (!moving) update();
      }

      function normalizeWheelDelta(e) {
        if (e.detail) {
          if (e.wheelDelta) {
            return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1); // Opera
          } else {
            return -e.detail / 3;
          } // Firefox
        } else {
          return e.wheelDelta / 120;
        } // IE, Safari, Chrome
      }

      function update() {
        /* if (vm.isScrolling) {
          moving = false;
          return;
        } */
        if (
          vm.$store.state.shownModal !== "none" ||
          vm.$store.state.shownSidebarMenu !== "none"
        ) {
          return;
        }

        moving = true;

        var delta = (pos - target.scrollTop) / smooth;

        target.scrollTop += delta;

        vm.lastScrolledPosition = vm.top(vm.hero);
        if (Math.abs(delta) >= 1) {
          requestFrame(update);
        } else {
          moving = false;
        } // was > 0.5
      }

      var requestFrame = (function() {
        // requestAnimationFrame cross browser
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function(func) {
            window.setTimeout(func, 1000 / 50);
          }
        );
      })();
    },
    hashNavigation() {
      let urlHash = this.$route.hash.split("/")[1];
      if (urlHash === undefined) {
        document.body.style.position = "initial";
        document.body.style.overflow = "initial";
        return;
      }

      if (this.$store.state.userDataIsFetched) {
        return;
      }

      if (urlHash === "lectures") {
        this.$store.state.coursePage = -2;
      } else if (urlHash === "lessons") {
        this.$store.state.coursePage = -1;
      } else {
        let urlHash2 = this.$route.hash.split("/")[3];

        let course = document.querySelector(".course");
        let internalNavigation = document.querySelector(
          ".header__internal-navigation-container"
        );
        course.style.visibility = "hidden";
        internalNavigation.style.visibility = "hidden";
        this.$store.state.spinnerIsShown = true;
        this.$store.state.breadcrumbNavigationIsShown = false;

        this.$store.state.redirectAfterUserDataIsFetched = [true, urlHash2];
      }
    },
    /* checkFullscreen() {
      let vm = this;

      function fullScreenCheck() {
        // if (document.documentElement.requestFullscreen()) {
        if (
          document.fullscreenEnabled ||
          document.mozFullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.msFullscreenEnabled
        ) {
          vm.$store.state.supportsFullscreen = true;
        }
      }

      async function check() {
        try {
          await fullScreenCheck();
        } catch (err) {
          // console.log(err);
        }
      }

      check();
    }, */
    orientationChange() {
      let isAndroidDevice = navigator.userAgent.includes("Android");
      // alert(isAndroidDevice);

      if (isAndroidDevice) {
        if (this.recentInitialScale === 1) {
          document
            .getElementById("viewport")
            .setAttribute("content", "initial-scale=1.001");
          this.recentInitialScale = 1.001;
        } else {
          document
            .getElementById("viewport")
            .setAttribute("content", "initial-scale=1");
          this.recentInitialScale = 1;
        }
      }
    }
  },
  created() {
    let courseData = [];
    let translatedStrings = [];

    let vm = this;
    translate(2);

    /////
    function translate(step) {
      if (step === 0) {
        getText();
      } else if (step === 1) {
        generateNewData();
      }
    }
    function getText() {
      let stringArray = [];

      const iterate = obj => {
        Object.keys(obj).forEach(key => {
          if (
            typeof obj[key] === "string" &&
            obj[key][obj[key].length - 1] === "ⓣ"
          ) {
            stringArray.push(obj[key].slice(0, -1));
          }

          if (typeof obj[key] === "object" && obj[key] !== null) {
            iterate(obj[key]);
          }
        });
      };

      iterate(courseData);
      vm.$store.state.typesetMathIsDeactivated = true;
      vm.$store.state.courseDataNew = stringArray;
    }
    function generateNewData() {
      let stringsToBeTranslated = [];
      const iterate = obj => {
        Object.keys(obj).forEach(key => {
          if (
            typeof obj[key] === "string" &&
            obj[key][obj[key].length - 1] === "ⓣ"
          ) {
            stringsToBeTranslated.push(obj[key].slice(0, -1));

            if (key === "de") {
              obj["en"] = translatedStrings[0];
            } else {
              let stringGerman = obj[key].slice(0, -1) + "₮";
              obj[key] = {};
              obj[key]["de"] = stringGerman;
              obj[key]["en"] = translatedStrings[0];
            }
            translatedStrings.shift();
          }

          if (typeof obj[key] === "object" && obj[key] !== null) {
            iterate(obj[key]);
          }
        });
      };

      iterate(courseData);

      const iterate2 = obj => {
        Object.keys(obj).forEach(key => {
          if (
            typeof obj[key] === "string" &&
            obj[key][obj[key].length - 1] === "₮"
          ) {
            obj[key] = obj[key].slice(0, -1);
            obj[key] = obj[key] + "ⓣ";
          }

          if (typeof obj[key] === "object" && obj[key] !== null) {
            iterate2(obj[key]);
          }
        });
      };

      iterate2(courseData);
      vm.$store.state.typesetMathIsDeactivated = true;
      vm.$store.state.courseDataNew = courseData;
    }
    /////

    /////
    setTimeout(() => {
      let isAndroidDevice = navigator.userAgent.includes("Android");

      if (isAndroidDevice) {
        // alert(1);
        document
          .getElementById("viewport")
          .setAttribute("content", "initial-scale=1");
        this.recentInitialScale = 1;
      }
    }, 500);

    this.$store.state.this = this;

    let html = document.getElementsByTagName("html")[0];
    setTimeout(() => {
      if (!this.$store.state.isAuthenticated) {
        html.style.backgroundColor = "white";
      } else {
        setTimeout(() => {
          html.style.backgroundColor = "white";
        }, 3000);
      }
    }, 0);

    // Check fullscreen
    // this.checkFullscreen();

    // *** Disable zoom per pinch gesture on mobile phones ***
    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      // document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      // document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      //  document.body.style.zoom = 0.99;
    });

    if (window.orientation === -90) {
      this.windowOrientation = "landscapePrimary";
    }

    /* setTimeout(() => {
      alert(window.outerHeight - window.innerHeight);
    }, 3000); */

    /* document.body.requestFullscreen();
    screen.orientation.lock("portrait"); */

    /* function fullScreenCheck() {
      // alert(0);
      if (document.fullscreenElement) return;
      return document.documentElement.requestFullscreen();
    }

    async function rotate() {
      try {
        await fullScreenCheck();
      } catch (err) {
        alert(err);
      }
      const newOrientation = "landscape";
      await screen.orientation.lock(newOrientation);
      // alert(2);
    } */

    // document.body.addEventListener("load", rotate);
    // window.addEventListener("resize", this.determineScreenValues, false);
    // let vm2 = this;
    // window.addEventListener("load", vm2.setFullscreen);

    //let vm = this;

    window.onpopstate = function() {
      vm.$store.state.triggerPreparationOfCourse++;
    };
    history.pushState({}, "");

    setTimeout(() => {
      this.$store.dispatch("tryAutoLogin", this);

      let vm = this; // ???

      let mode = this.$store.state.urlParameters[0];
      let actionCode = this.$store.state.urlParameters[1];

      let lang = localStorage.getItem("language") || "de";
      lang = this.$store.state.urlParameters[2] || lang;
      this.$i18n.locale = lang;
      // this.$i18n.locale = "en";
      localStorage.setItem("language", lang);

      // let email = this.$store.state.urlParameters[3];

      this.$store.state.urlParameters = [];

      // Retrieve stored data data from local storage
      let chosenLecture = localStorage.getItem("chosenLecture");
      this.$store.state.chosenLecture = JSON.parse(chosenLecture);
      this.$store.state.contentsOfLecture =
        localStorage.getItem("contentsOfLecture") || "analysis1"; // in the moment "analysis1" needed
      let topicGroup = localStorage.getItem("topicGroup");
      this.$store.state.topicGroup = JSON.parse(topicGroup);
      let topic = localStorage.getItem("topic");
      this.$store.state.topic = JSON.parse(topic);

      this.$store.state.chosenLesson =
        localStorage.getItem("chosenLesson") || "lesson1"; // in the moment "lesson1" needed
      let headingOfChosenLesson = localStorage.getItem("headingOfChosenLesson");
      this.$store.state.headingOfChosenLesson = JSON.parse(
        headingOfChosenLesson
      );

      this.$store.state.soundIsTurnedOn =
        JSON.parse(localStorage.getItem("soundIsTurnedOn") || true) === true;

      this.typesetMath();
      this.typesetMath(50);
      /* setTimeout(() => {
        let course = document.querySelector(".course");
        course.style.opacity = 1;
      }, 1275); */

      if (mode === "verifyEmail") {
        firebase
          .auth()
          .applyActionCode(actionCode)
          .then(() => {
            // this.$store.state.emailAddress = email
            this.toggleModal("app-email-confirmed");
          })
          .catch(error => {
            if (error.code === "auth/network-request-failed") {
              vm.toggleAlert(vm.$t("network_error"));
              return;
            }

            (this.$store.state.modalData = {
              heading: vm.$t("alert_verify_mail_link_not_valid")["heading"],
              lottie: "monster",
              loop: true,
              text: vm.$t("alert_verify_mail_link_not_valid")["message"],
              button: vm.$t("button_ok"),
              ghostButton: vm.$t("alert_verify_mail_link_not_valid")[
                "ghostButton"
              ],
              /* ghostButtonAction: () => {
                vm.$store.dispatch("resendEmailVerification", {
                  email: "pbreuning@yahoo.de",
                  language: lang
                });
              } */
              ghostButtonAction: () => {
                vm.changeModal("app-password-modal");
              }
            }),
              this.toggleModal("app-modal");
          });
      } else if (mode === "resetPassword") {
        this.$store.state.actionCode = actionCode;
        setTimeout(() => {
          this.toggleModal("app-password-modal");
        }, 0);
        // this.toggleModal("app-password-modal");
      }

      document.head.querySelector('meta[name="description"]').content = this.$t(
        "meta_description"
      );

      setTimeout(() => {
        this.hero = document.querySelector(".app"); // // //

        this.determineScreenValues();
        this.smoothScroll(document, 120, 12);
      }, 500);

      // window.addEventListener("resize", this.determineScreenValues, false);
      window.addEventListener("resize", this.determineValuesOfScreen, false);
      // window.addEventListener("mousedown", this.closeLanguageMenu, false);
      window.addEventListener("mousedown", this.closeMenu, false);
      window.addEventListener("touchstart", this.closeMenu, false);
      window.addEventListener(
        "orientationchange",
        this.orientationChange,
        false
      );
    }, 0);
  },
  mounted() {
    setTimeout(() => {
      this.$store.state.awardsAreShown = true;
    }, 0);
  },
  watch: {
    $route(to, from) {
      // Lock internal area for back button when not logged in
      /* let auth = localStorage.getItem('userId')
      if (!auth && to.path !== '/' && to.path !== '/imprint' && to.path !== '/terms' && to.path !== '/data-protection') {
        this.goTo('/')
      } */

      // this.closeAlert();
      // this.closeAll();
      this.hashNavigation();
      if (from.path !== "/" && to.path === "/") {
        setTimeout(() => {
          // window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
          window.MathJax.typeset();
        }, 0);
      }
    },
    isAuthenticated(to) {
      // this.typesetMath(100);
      this.typesetMath(250); // <-- improve !!!

      if (to) {
        if (document.documentElement.requestFullscreen === undefined) {
          // return;
        }
        setTimeout(() => {
          // this.toggleModal("app-fullscreen-modal", "fullscreen", "none");
          // this.toggleModal("app-language-modal", "language", "app-language");
        }, 1000);

        //// Alert after reload of page due to new course version
        if (sessionStorage.reloadAfterPageLoad == 1) {
          this.$store.state.onboardingIsHidden = true;
          setTimeout(() => {
            this.toggleAlert(
              this.$t("new_course_version")["one_course_1"] +
                this.t(this.$store.state.headingOfChosenLesson) +
                this.$t("new_course_version")["one_course_2"]
            );
          }, 0);
          sessionStorage.reloadAfterPageLoad = 0;
        }
        ////
      }
    }
  }
};
</script>

<style lang="scss">
@import "sass/main.scss";
</style>

<style lang="scss" scoped>
@import "sass/main.scss";

.app {
  position: relative;
  background-color: #131313;
  // transform-style: preserve-3d;
  // height: initial !important;
  // height: 100vh !important;

  // height: 100vh !important;

  &.app__handle-overflow {
    @media (pointer: coarse) {
      overflow-y: auto;
    }
  }

  &__translation {
    max-width: $width-center;
    min-height: 100vh;
    transform: translateX(calc(50vw - 0.5 * #{$width-center}));
    padding: 15rem 0;
    font-size: 0.4rem;
    color: red;
  }

  &__footer-navigation {
    display: none;

    &--is-shown {
      @include respond(tab-port) {
        display: grid;
      }
    }
  }

  @media (pointer: coarse) {
    // overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100vh !important;
    // margin-top: -100vh;

    @include respond(phone) {
      background-color: $color-black;
      // margin-top: -100vh;
    }

    &__not-authenticated.app__handle-overflow {
      /* @include respond(phone) {
        margin-top: -100vh;
      } */

      @media (pointer: coarse) {
        margin-top: -100vh;
      }
    }
  }

  &__spinner {
    position: absolute;
    left: 50vw;
    top: 0;
    transform: translate(-50%, calc(var(--vh, 1vh) * 50 - 2rem - 50%));
    width: 8.88rem;

    will-change: transform;
    transition: transform 0.75s;

    &::before {
      @include pseudo-element;
      left: calc(50% - 50vw);
      top: calc(0rem - var(--vh, 1vh) * 50 + 2rem + 50%);
      width: 100vw;
      height: 101vh;
      background-color: $color-black;
    }
  }

  &__spinner-text {
    position: absolute;
    left: 50vw;
    top: 0;
    transform: translate(-50%, calc(var(--vh, 1vh) * 50 + 5.8rem - 50%));
    font-size: 3.4rem;
    color: $color-white;

    will-change: transform;
    transition: transform 0.75s;
  }

  &__change-orientation {
    display: none;
    z-index: 100000000000;
    transform: translateZ(1000000px);
    pointer-events: none;
    // opacity: 0;

    //  transition: opacity 0.25s 0.25s;
  }

  /* &__change-orientation--shown {
    display: block;
  } */

  &__orientation-container {
    transform: translateY(calc(var(--vh, 1vh) * 50 - 50%));

    @media only screen and (max-height: 600px) {
      transform: translateY(calc(var(--vh, 1vh) * 50 - 37.5%));
    }
  }

  &__orientation-lottie {
    width: 20rem;
    transform: translateX(calc(50vw - 50% - 1rem)) rotate(-90deg);

    &.app__landscape-primary {
      transform: translateX(calc(50vw - 50% - 1rem)) rotate(90deg) scaleX(-1);
    }
  }

  &__change-orientation-text {
    transform: translate(calc(50vw - 50%), -0.6rem);
    text-align: center;
    font-size: 3.5rem;
  }
}

// @media screen and (max-height: 2000px) and (orientation: landscape) {
@media screen and (max-height: 2000px) and (min-aspect-ratio: 13/9) {
  // !!!
  // was: 1000px
  .app__change-orientation--shown.app__change-orientation {
    @media (pointer: coarse) {
      display: block;
      // display: none !important;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // opacity: 1;
      color: $color-white;
      background-color: #131313;

      &.app__change-orientation--shown {
        // display: block;
      }
    }
  }
}
</style>
