<template>
  <div class="login-modal tabstart" tabindex="0">
    <h2 class="login-modal__heading">12 Monate für 4,95 € / Monat</h2>
    <p class="login-modal__text">
      <!-- {{ $t("license_form").text }} -->
      (59,40 € jährlich verrechnet).
    </p>
    <p class="login-modal__payment-method">Wähle eine Zahlungsmethode:</p>
    <div class="app__paypal" ref="paypal"></div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
// import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
// import AppFooterLink from "@/components/Molecules/FooterLink.vue";

export default {
  name: "appLoginForm",
  components: {
    // AppButton,
    AppButtonXRound
    // AppFooterLink
  },
  data() {
    return {
      licenseNumber: "",
      placeholderIsSmall: false, // for password
      inputType: "password",
      password: ""
    };
  },
  methods: {
    loginOnEnterKey(e) {
      if (e.code === "Enter" || e.keyCode === 13) {
        this.getLicense();
      }
    },
    login() {
      this.email = this.email.trim(); // Remove whitespaces

      if (this.email === "") {
        if (this.password === "") {
          this.toggleAlert(
            this.$t("form_login_feedback")["no_email_no_password"]
          );
        } else {
          this.toggleAlert(this.$t("form_login_feedback")["no_email"]);
        }
        return;
      } else if (this.password === "") {
        this.toggleAlert(this.$t("form_login_feedback")["no_password"]);
        return;
      } else if (this.password.length < 6) {
        this.toggleAlert(this.$t("form_login_feedback")["wrong_password"]);
        return;
      }
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });
    },
    getLicense() {
      if (this.licenseNumber === "") {
        this.toggleAlert("Bitte gib eine Lizenznummer ein.");
        return;
      }
      this.$store.dispatch("getLicense", this.licenseNumber);
    },
    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Bla",
                  amount: {
                    // currency_code: "USD",
                    value: 4.95
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            console.log(order);

            this.toggleModal(); // // //
            this.$store.state.isLicensed = true; // // //
          },
          onError: err => {
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AT_TUuA5v8otqpCpZAwaM_goQqMrLZkleHeyHojTWgs-S1LFjJNibsA-x04mW5kwbsoGttGbIRdaihqM&currency=EUR";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.login-modal {
  width: 46rem;
  padding: 3.5rem 5.5rem 2.75rem;
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  color: $color-white;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
    padding: 3.5rem 3rem 0;
  }

  &:focus {
    outline: none;
  }

  &__heading {
    color: $color-white;
    // margin-bottom: 2.75rem;
    font-size: 3.2rem;
    line-height: 1.2;

    @include respond(phone) {
      font-size: 3.2rem;
    }
  }

  &__text {
    padding-top: 2rem;
    margin-bottom: 2.25rem;
    font-size: 2.2rem;
    line-height: 1.3;
    color: #aaa;

    // border-top: 1px solid #333;

    @include respond(phone) {
      padding-top: 0;
      font-size: 2.4rem;
      color: #ccc;
    }
  }

  &__payment-method {
    margin-top: 4.25rem;
    font-family: $font-primary-bold;
  }

  &__button-group {
    padding-bottom: 2.5rem;
    // margin-top: 0rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #333;
  }

  &__button {
    margin-top: 2.75rem;
    // margin-bottom: 5.5rem;
    width: 100%;
    height: 5rem;
    // height: 4.5rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    font-size: 2.4rem;
    text-align: center;
    background-color: $color-primary-dark;

    @include respond(phone) {
      margin-top: 2rem;
      margin-bottom: 3.5rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }
}
</style>
