<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 225.4 477.4"
    style="enable-background:new 0 0 225.4 477.4"
  >
    <path
      class="st0"
      d="M190 2.3H38.6C15.2 2.3 2.3 18 2.3 37.8v403.4c0 22.1 17.2 36.2 36.2 36.2H190c20.3.0 35.4-16.4 35.4-36.2V37.8C225.4 18 212.1 2.3 190 2.3z"
    />
    <path class="st1" d="M12.8 62.7h201.4v351.9H12.8z" />
    <path
      class="st2"
      d="M189.9 2.3H38.5C15.2 2.3 2.3 18 2.3 37.8v403.4c0 22.1 17.1 36.2 36.2 36.2h151.4c20.3.0 35.4-16.4 35.4-36.2V37.8C225.4 18 212 2.3 189.9 2.3zM222.3 441.1c0 18.4-14 33.1-32.3 33.1H38.5c-16.3.0-33.1-11.6-33.1-33.1V37.8c0-19.9 13.2-32.3 33.1-32.3h151.4c19.3.0 32.3 13.1 32.3 32.3v403.3H222.3z"
    />
    <path
      class="st3"
      d="M189.9 472.7H38.5C23.4 472.7 7 460.6 7 441.1V37.8C7 20.2 20.5 7 38.5 7h151.4c17.3.0 30.8 13.5 30.8 30.8v403.4C220.7 457.7 206.1 472.7 189.9 472.7zM38.5 7.7C21 7.7 7.7 20.7 7.7 37.8v403.4c0 21 18.4 30.8 30.8 30.8h151.4c15.7.0 30-14.7 30-30.8V37.8c0-16.8-13.2-30-30-30L38.5 7.7v0z"
    />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="150.3"
      y1="476.34"
      x2="185.9"
      y2="476.34"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset="0" style="stop-color:#7f8c8c" />
      <stop offset=".0418785" style="stop-color:#bdc3c7" />
      <stop offset=".1193" style="stop-color:#7f8c8c" />
      <stop offset=".8388" style="stop-color:#7f8c8c" />
      <stop offset=".9299" style="stop-color:#bdc3c7" />
      <stop offset="1" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path class="st4" d="M150.3.8h35.6v1.5H150.3z" />
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="150.3"
      y1="477.49"
      x2="185.9"
      y2="477.49"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset="0" style="stop-color:#7f8c8c" />
      <stop offset=".0323998" style="stop-color:#94a4a3" />
      <stop offset=".1099" style="stop-color:#7f8c8c" />
      <stop offset=".2522" style="stop-color:#94a4a3" />
      <stop offset=".7557" style="stop-color:#828791" />
      <stop offset=".8767" style="stop-color:#7f8c8c" />
      <stop offset=".9394" style="stop-color:#94a4a3" />
      <stop offset="1" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path class="st5" d="M185.9.8h-35.6l1.5-.8c12.7.4 19.8.5 32.5.0L185.9.8z" />
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="-583.1963"
      y1="516.8635"
      x2="-561.4963"
      y2="516.8635"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#7f8c8c" />
      <stop offset=".0397817" style="stop-color:#bdc3c7" />
      <stop offset=".1106" style="stop-color:#7f8c8c" />
      <stop offset=".8577" style="stop-color:#7f8c8c" />
      <stop offset=".9347" style="stop-color:#bdc3c7" />
      <stop offset="1" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path class="st6" d="M.8 68.4h1.5v21.7H.8z" />
    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="-583.1774"
      y1="518.0135"
      x2="-561.5052"
      y2="518.0135"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#ecf1f2" />
      <stop offset=".0640242" style="stop-color:#7f8c8c" />
      <stop offset=".186" style="stop-color:#828791" />
      <stop offset=".8064" style="stop-color:#828791" />
      <stop offset=".9191" style="stop-color:#7f8c8c" />
      <stop offset="1" style="stop-color:#bdc3c7" />
    </linearGradient>
    <path class="st7" d="M.8 68.4v21.7l-.8-1C.4 81.4.4 77 0 69.3L.8 68.4z" />
    <linearGradient
      id="SVGID_5_"
      gradientUnits="userSpaceOnUse"
      x1="-625.0259"
      y1="516.8635"
      x2="-608.7601"
      y2="516.8635"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#7f8c8c" />
      <stop offset=".044521" style="stop-color:#bdc3c7" />
      <stop offset=".1059" style="stop-color:#7f8c8c" />
      <stop offset=".8388" style="stop-color:#7f8c8c" />
      <stop offset=".9252" style="stop-color:#bdc3c7" />
      <stop offset="1" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path class="st8" d="M.8 115.6h1.5v16.3H.8z" />
    <linearGradient
      id="SVGID_6_"
      gradientUnits="userSpaceOnUse"
      x1="-625.0037"
      y1="518.0135"
      x2="-608.7534"
      y2="518.0135"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#bdc3c7" />
      <stop offset=".0735028" style="stop-color:#7f8c8c" />
      <stop offset=".1765" style="stop-color:#bdc3c7" />
      <stop offset=".8253" style="stop-color:#bdc3c7" />
      <stop offset=".9191" style="stop-color:#7f8c8c" />
      <stop offset="1" style="stop-color:#bdc3c7" />
    </linearGradient>
    <path class="st9" d="M.8 115.6v16.3l-.8-1c.4-5.7.4-8.8.0-14.4L.8 115.6z" />
    <linearGradient
      id="SVGID_7_"
      gradientUnits="userSpaceOnUse"
      x1="-661.5259"
      y1="516.8635"
      x2="-645.2601"
      y2="516.8635"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#7f8c8c" />
      <stop offset=".044521" style="stop-color:#bdc3c7" />
      <stop offset=".1059" style="stop-color:#7f8c8c" />
      <stop offset=".8388" style="stop-color:#7f8c8c" />
      <stop offset=".9252" style="stop-color:#bdc3c7" />
      <stop offset="1" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path class="st10" d="M.8 152.1h1.5v16.3H.8z" />
    <linearGradient
      id="SVGID_8_"
      gradientUnits="userSpaceOnUse"
      x1="-661.5093"
      y1="518.0135"
      x2="-645.259"
      y2="518.0135"
      gradientTransform="matrix(0 -1 -1 0 518.4135 -493.1259)"
    >
      <stop offset="0" style="stop-color:#bdc3c7" />
      <stop offset=".0735028" style="stop-color:#7f8c8c" />
      <stop offset=".1765" style="stop-color:#bdc3c7" />
      <stop offset=".8253" style="stop-color:#bdc3c7" />
      <stop offset=".9191" style="stop-color:#7f8c8c" />
      <stop offset="1" style="stop-color:#bdc3c7" />
    </linearGradient>
    <path class="st11" d="M.8 152.1v16.3l-.8-1c.4-5.7.4-8.8.0-14.4L.8 152.1z" />
    <linearGradient
      id="SVGID_9_"
      gradientUnits="userSpaceOnUse"
      x1="114"
      y1="448.5134"
      x2="114"
      y2="457.4909"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset="0" style="stop-color:#666" />
      <stop offset="1" style="stop-color:#010104" />
    </linearGradient>
    <circle class="st12" cx="114" cy="25.1" r="4.6" />
    <linearGradient
      id="SVGID_10_"
      gradientUnits="userSpaceOnUse"
      x1="-230.3411"
      y1="1161.1296"
      x2="-230.3411"
      y2="1164.6926"
      gradientTransform="matrix(-1 0 0 1 -116.3411 -1137.7256)"
    >
      <stop offset="0" style="stop-color:#0b131c" />
      <stop offset="1" style="stop-color:#354039" />
    </linearGradient>
    <path
      class="st13"
      d="M112.2 25.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8S112.2 26.1 112.2 25.1z"
    />
    <linearGradient
      id="SVGID_11_"
      gradientUnits="userSpaceOnUse"
      x1="113.9"
      y1="451.4181"
      x2="113.9"
      y2="454.2971"
      gradientTransform="matrix(1 0 0 -1 0 477.891)"
    >
      <stop offset="0" style="stop-color:#4b4e4f" />
      <stop offset=".2831" style="stop-color:#3a3d3e" />
      <stop offset=".5492" style="stop-color:#2f3232" />
      <stop offset=".7614" style="stop-color:#242727" />
      <stop offset="1" style="stop-color:#1c201f" />
    </linearGradient>
    <path
      class="st14"
      d="M115.4 25.1c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5C114.8 23.6 115.4 24.3 115.4 25.1z"
    />
    <linearGradient
      id="SVGID_12_"
      gradientUnits="userSpaceOnUse"
      x1="114.8412"
      y1="451.9305"
      x2="113.1024"
      y2="453.6693"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset="0" style="stop-color:#231f20" />
      <stop offset=".08317667" style="stop-color:#222326" />
      <stop offset=".1958" style="stop-color:#1f2c37" />
      <stop offset=".3254" style="stop-color:#1a3d54" />
      <stop offset=".4675" style="stop-color:#13537c" />
      <stop offset=".62" style="stop-color:#0b71ae" />
      <stop offset=".7788" style="stop-color:#0094ec" />
      <stop offset=".7847" style="stop-color:#0095ee" />
      <stop offset=".7998" style="stop-color:#0488d6" />
      <stop offset=".8384" style="stop-color:#0d689f" />
      <stop offset=".8761" style="stop-color:#154e72" />
      <stop offset=".912" style="stop-color:#1b394e" />
      <stop offset=".9455" style="stop-color:#1f2b35" />
      <stop offset=".9758" style="stop-color:#222225" />
      <stop offset="1" style="stop-color:#231f20" />
    </linearGradient>
    <path
      class="st15"
      d="M115.2 25.1c0 .7-.6 1.2-1.2 1.2-.7.0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2C114.6 23.9 115.2 24.4 115.2 25.1z"
    />
    <path d="M13.9 63.9H213V413.4H13.9" />
    <linearGradient
      id="SVGID_13_"
      gradientUnits="userSpaceOnUse"
      x1="91.7808"
      y1="62.8589"
      x2="128.9071"
      y2="15.7142"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset=".2865" style="stop-color:#7f8c8c" />
      <stop offset=".32" style="stop-color:#899595" />
      <stop offset=".5159" style="stop-color:#bec7c7" />
      <stop offset=".6628" style="stop-color:#dfe5e6" />
      <stop offset=".7429" style="stop-color:#ecf1f2" />
      <stop offset=".9621" style="stop-color:#7f8c8c" />
    </linearGradient>
    <path
      class="st16"
      d="M114.2 424.1c6.9.0 13.3 3.7 16.8 9.7s3.5 13.4.0 19.4-9.9 9.7-16.8 9.7-13.3-3.7-16.8-9.7-3.5-13.4.0-19.4C100.9 427.8 107.3 424.1 114.2 424.1zM99.2 434.8c-3.1 5.4-3.1 12 0 17.4s8.9 8.7 15.1 8.7 12-3.3 15.1-8.7c3.1-5.4 3.1-12 0-17.4-3.1-5.4-8.9-8.7-15.1-8.7C108 426.1 102.3 429.4 99.2 434.8z"
    />
    <linearGradient
      id="SVGID_14_"
      gradientUnits="userSpaceOnUse"
      x1="94.0252"
      y1="60.009"
      x2="127.4388"
      y2="17.5787"
      gradientTransform="matrix(1 0 0 -1 0 477.89)"
    >
      <stop offset="0" style="stop-color:#bdc3c7" />
      <stop offset=".1232" style="stop-color:#c8ced1" />
      <stop offset=".416" style="stop-color:#dce1e3" />
      <stop offset=".7089" style="stop-color:#e8edee" />
      <stop offset="1" style="stop-color:#ecf1f2" />
    </linearGradient>
    <path
      class="st17"
      d="M114.2 426.1c6.2.0 12 3.3 15.1 8.7 3.1 5.4 3.1 12 0 17.4-3.1 5.4-8.9 8.7-15.1 8.7s-12-3.3-15.1-8.7c-3.1-5.4-3.1-12 0-17.4C102.3 429.4 108 426.1 114.2 426.1z"
    />
    <path
      class="st18"
      d="M133.2 42.8c0 2-1.6 3.7-3.7 3.7H97.4c-2 0-3.7-1.6-3.7-3.7v-.4c0-2 1.6-3.7 3.7-3.7h32.2c2 0 3.7 1.6 3.7 3.7v.4H133.2z"
    />
    <path class="st19" d="M96.5 38.7h.9v.9H96.5z" />
    <path class="st19" d="M98.4 38.7h.9v.9H98.4z" />
    <path class="st19" d="M100.2 38.7h.9v.9H100.2z" />
    <path class="st19" d="M102.1 38.7h.9v.9H102.1z" />
    <path class="st19" d="M104 38.7h.9v.9H104z" />
    <path class="st19" d="M105.9 38.7h.9v.9H105.9z" />
    <path class="st19" d="M107.8 38.7h.9v.9H107.8z" />
    <path class="st19" d="M109.7 38.7h.9v.9H109.7z" />
    <path class="st19" d="M111.6 38.7h.9v.9H111.6z" />
    <path class="st19" d="M113.5 38.7h.9v.9H113.5z" />
    <path class="st19" d="M115.4 38.7h.9v.9H115.4z" />
    <path class="st19" d="M95.5 39.6h.9v.9H95.5z" />
    <path class="st19" d="M97.4 39.6h.9v.9H97.4z" />
    <path class="st19" d="M99.3 39.6h.9v.9H99.3z" />
    <path class="st19" d="M101.2 39.6h.9v.9H101.2z" />
    <path class="st19" d="M103.1 39.6h.9v.9H103.1z" />
    <path class="st19" d="M105 39.6h.9v.9H105z" />
    <path class="st19" d="M106.9 39.6h.9v.9H106.9z" />
    <path class="st19" d="M108.8 39.6h.9v.9H108.8z" />
    <path class="st19" d="M110.7 39.6h.9v.9H110.7z" />
    <path class="st19" d="M112.5 39.6h.9v.9H112.5z" />
    <path class="st19" d="M114.4 39.6h.9v.9H114.4z" />
    <path class="st19" d="M94.6 40.6h.9v.9H94.6z" />
    <path class="st19" d="M96.5 40.6h.9v.9H96.5z" />
    <path class="st19" d="M98.4 40.6h.9v.9H98.4z" />
    <path class="st19" d="M100.2 40.6h.9v.9H100.2z" />
    <path class="st19" d="M102.1 40.6h.9v.9H102.1z" />
    <path class="st19" d="M104 40.6h.9v.9H104z" />
    <path class="st19" d="M105.9 40.6h.9v.9H105.9z" />
    <path class="st19" d="M107.8 40.6h.9v.9H107.8z" />
    <path class="st19" d="M109.7 40.6h.9v.9H109.7z" />
    <path class="st19" d="M111.6 40.6h.9v.9H111.6z" />
    <path class="st19" d="M113.5 40.6h.9v.9H113.5z" />
    <path class="st19" d="M115.4 40.6h.9v.9H115.4z" />
    <path class="st19" d="M95.5 41.5h.9v.9H95.5z" />
    <path class="st19" d="M97.4 41.5h.9v.9H97.4z" />
    <path class="st19" d="M99.3 41.5h.9v.9H99.3z" />
    <path class="st19" d="M101.2 41.5h.9v.9H101.2z" />
    <path class="st19" d="M103.1 41.5h.9v.9H103.1z" />
    <path class="st19" d="M105 41.5h.9v.9H105z" />
    <path class="st19" d="M106.9 41.5h.9v.9H106.9z" />
    <path class="st19" d="M108.8 41.5h.9v.9H108.8z" />
    <path class="st19" d="M110.7 41.5h.9v.9H110.7z" />
    <path class="st19" d="M112.5 41.5h.9v.9H112.5z" />
    <path class="st19" d="M114.4 41.5h.9v.9H114.4z" />
    <path class="st19" d="M94.6 42.5h.9v.9H94.6z" />
    <path class="st19" d="M96.5 42.5h.9v.9H96.5z" />
    <path class="st19" d="M98.4 42.5h.9v.9H98.4z" />
    <path class="st19" d="M100.2 42.5h.9v.9H100.2z" />
    <path class="st19" d="M102.1 42.5h.9v.9H102.1z" />
    <path class="st19" d="M104 42.5h.9v.9H104z" />
    <path class="st19" d="M105.9 42.5h.9v.9H105.9z" />
    <path class="st19" d="M107.8 42.5h.9v.9H107.8z" />
    <path class="st19" d="M109.7 42.5h.9v.9H109.7z" />
    <path class="st19" d="M111.6 42.5h.9v.9H111.6z" />
    <path class="st19" d="M113.5 42.5h.9v.9H113.5z" />
    <path class="st19" d="M115.4 42.5h.9v.9H115.4z" />
    <path class="st19" d="M95.5 43.4h.9v.9H95.5z" />
    <path class="st19" d="M97.4 43.4h.9v.9H97.4z" />
    <path class="st19" d="M99.3 43.4h.9v.9H99.3z" />
    <path class="st19" d="M101.2 43.4h.9v.9H101.2z" />
    <path class="st19" d="M103.1 43.4h.9v.9H103.1z" />
    <path class="st19" d="M105 43.4h.9v.9H105z" />
    <path class="st19" d="M106.9 43.4h.9v.9H106.9z" />
    <path class="st19" d="M108.8 43.4h.9v.9H108.8z" />
    <path class="st19" d="M110.7 43.4h.9v.9H110.7z" />
    <path class="st19" d="M112.5 43.4h.9v.9H112.5z" />
    <path class="st19" d="M114.4 43.4h.9v.9H114.4z" />
    <path class="st19" d="M94.6 44.4h.9v.9H94.6z" />
    <path class="st19" d="M96.5 44.4h.9v.9H96.5z" />
    <path class="st19" d="M98.4 44.4h.9v.9H98.4z" />
    <path class="st19" d="M100.2 44.4h.9v.9H100.2z" />
    <path class="st19" d="M102.1 44.4h.9v.9H102.1z" />
    <path class="st19" d="M104 44.4h.9v.9H104z" />
    <path class="st19" d="M105.9 44.4h.9v.9H105.9z" />
    <path class="st19" d="M107.8 44.4h.9v.9H107.8z" />
    <path class="st19" d="M109.7 44.4h.9v.9H109.7z" />
    <path class="st19" d="M111.6 44.4h.9v.9H111.6z" />
    <path class="st19" d="M113.5 44.4h.9v.9H113.5z" />
    <path class="st19" d="M115.4 44.4h.9v.9H115.4z" />
    <path class="st19" d="M95.5 45.3h.9v.9H95.5z" />
    <path class="st19" d="M97.4 45.3h.9v.9H97.4z" />
    <path class="st19" d="M99.3 45.3h.9v.9H99.3z" />
    <path class="st19" d="M101.2 45.3h.9v.9H101.2z" />
    <path class="st19" d="M103.1 45.3h.9v.9H103.1z" />
    <path class="st19" d="M105 45.3h.9v.9H105z" />
    <path class="st19" d="M106.9 45.3h.9v.9H106.9z" />
    <path class="st19" d="M108.8 45.3h.9v.9H108.8z" />
    <path class="st19" d="M110.7 45.3h.9v.9H110.7z" />
    <path class="st19" d="M112.5 45.3h.9v.9H112.5z" />
    <path class="st19" d="M114.4 45.3h.9v.9H114.4z" />
    <path class="st19" d="M117.3 38.7h.9v.9H117.3z" />
    <path class="st19" d="M119.2 38.7h.9v.9H119.2z" />
    <path class="st19" d="M121.1 38.7h.9v.9H121.1z" />
    <path class="st19" d="M122.9 38.7h.9v.9H122.9z" />
    <path class="st19" d="M124.8 38.7h.9v.9H124.8z" />
    <path class="st19" d="M126.7 38.7h.9v.9H126.7z" />
    <path class="st19" d="M128.6 38.7h.9v.9H128.6z" />
    <path class="st19" d="M116.3 39.6h.9v.9H116.3z" />
    <path class="st19" d="M118.2 39.6h.9v.9H118.2z" />
    <path class="st19" d="M120.1 39.6h.9v.9H120.1z" />
    <path class="st19" d="M122 39.6h.9v.9H122z" />
    <path class="st19" d="M123.9 39.6h.9v.9H123.9z" />
    <path class="st19" d="M125.8 39.6h.9v.9H125.8z" />
    <path class="st19" d="M127.7 39.6h.9v.9H127.7z" />
    <path class="st19" d="M129.6 39.6h.9v.9H129.6z" />
    <path class="st19" d="M131.5 39.6h.9v.9H131.5z" />
    <path class="st19" d="M117.3 40.6h.9v.9H117.3z" />
    <path class="st19" d="M119.2 40.6h.9v.9H119.2z" />
    <path class="st19" d="M121.1 40.6h.9v.9H121.1z" />
    <path class="st19" d="M122.9 40.6h.9v.9H122.9z" />
    <path class="st19" d="M124.8 40.6h.9v.9H124.8z" />
    <path class="st19" d="M126.7 40.6h.9v.9H126.7z" />
    <path class="st19" d="M128.6 40.6h.9v.9H128.6z" />
    <path class="st19" d="M130.5 40.6h.9v.9H130.5z" />
    <path class="st19" d="M116.3 41.5h.9v.9H116.3z" />
    <path class="st19" d="M118.2 41.5h.9v.9H118.2z" />
    <path class="st19" d="M120.1 41.5h.9v.9H120.1z" />
    <path class="st19" d="M122 41.5h.9v.9H122z" />
    <path class="st19" d="M123.9 41.5h.9v.9H123.9z" />
    <path class="st19" d="M125.8 41.5h.9v.9H125.8z" />
    <path class="st19" d="M127.7 41.5h.9v.9H127.7z" />
    <path class="st19" d="M129.6 41.5h.9v.9H129.6z" />
    <path class="st19" d="M131.5 41.5h.9v.9H131.5z" />
    <path class="st19" d="M117.3 42.5h.9v.9H117.3z" />
    <path class="st19" d="M119.2 42.5h.9v.9H119.2z" />
    <path class="st19" d="M121.1 42.5h.9v.9H121.1z" />
    <path class="st19" d="M122.9 42.5h.9v.9H122.9z" />
    <path class="st19" d="M124.8 42.5h.9v.9H124.8z" />
    <path class="st19" d="M126.7 42.5h.9v.9H126.7z" />
    <path class="st19" d="M128.6 42.5h.9v.9H128.6z" />
    <path class="st19" d="M130.5 42.5h.9v.9H130.5z" />
    <path class="st19" d="M116.3 43.4h.9v.9H116.3z" />
    <path class="st19" d="M118.2 43.4h.9v.9H118.2z" />
    <path class="st19" d="M120.1 43.4h.9v.9H120.1z" />
    <path class="st19" d="M122 43.4h.9v.9H122z" />
    <path class="st19" d="M123.9 43.4h.9v.9H123.9z" />
    <path class="st19" d="M125.8 43.4h.9v.9H125.8z" />
    <path class="st19" d="M127.7 43.4h.9v.9H127.7z" />
    <path class="st19" d="M129.6 43.4h.9v.9H129.6z" />
    <path class="st19" d="M131.5 43.4h.9v.9H131.5z" />
    <path class="st19" d="M117.3 44.4h.9v.9H117.3z" />
    <path class="st19" d="M119.2 44.4h.9v.9H119.2z" />
    <path class="st19" d="M121.1 44.4h.9v.9H121.1z" />
    <path class="st19" d="M122.9 44.4h.9v.9H122.9z" />
    <path class="st19" d="M124.8 44.4h.9v.9H124.8z" />
    <path class="st19" d="M126.7 44.4h.9v.9H126.7z" />
    <path class="st19" d="M128.6 44.4h.9v.9H128.6z" />
    <path class="st19" d="M130.5 44.4h.9v.9H130.5z" />
    <path class="st19" d="M116.3 45.3h.9v.9H116.3z" />
    <path class="st19" d="M118.2 45.3h.9v.9H118.2z" />
    <path class="st19" d="M120.1 45.3h.9v.9H120.1z" />
    <path class="st19" d="M122 45.3h.9v.9H122z" />
    <path class="st19" d="M123.9 45.3h.9v.9H123.9z" />
    <path class="st19" d="M125.8 45.3h.9v.9H125.8z" />
    <path class="st19" d="M127.7 45.3h.9v.9H127.7z" />
    <path class="st19" d="M129.6 45.3h.9v.9H129.6z" />
    <path
      class="st20"
      d="M134 41.8c0-2.1-1.7-3.9-3.9-3.9H96.8c-2.1.0-3.9 1.7-3.9 3.9v1.5c0 2.1 1.7 3.9 3.9 3.9h33.3c2.1.0 3.9-1.7 3.9-3.9V41.8zM132.4 43.4c0 1.3-1 2.3-2.3 2.3H96.8c-1.3.0-2.3-1-2.3-2.3v-1.5c0-1.3 1-2.3 2.3-2.3h33.3c1.3.0 2.3 1 2.3 2.3v1.5.0z"
    />
    <path class="st21" d="M20.7 311.9h186v31.7H20.7z" />
    <path class="st22" d="M20.7 101.5h186v1H20.7z" />
    <path class="st23" d="M20.7 361h186v1H20.7z" />
    <path class="st24" d="M193.2 79h14v1.1h-14z" />
    <path class="st24" d="M193.2 83.3h14v1.1h-14z" />
    <path class="st24" d="M193.2 87.6h14v1.1h-14z" />
    <path class="st24" d="M27.5 380.3h14v1h-14z" />
    <path class="st24" d="M34.1 373.8h1v14h-1z" />
    <path
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -177.5507 229.7334)"
      class="st24"
      d="M188 323.7h1v11h-1z"
    />
    <path
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -175.4495 234.7658)"
      class="st24"
      d="M190.2 328.7h11v1h-11z"
    />
    <circle class="st21" cx="112.7" cy="180.7" r="42.2" />
    <g>
      <g transform="translate(0.000000,756.000000) scale(0.100000,-0.100000)">
        <path
          class="st24"
          d="M1133.9 6016.7c-2.6-.4-6.2-1.2-7.8-1.7-14.1-4.6-26.4-17.4-30-31.4-2-7.5.7-11.5 7.7-11.5 5.1.0 8.8 2.4 10.8 7.1 2.9 6.4-.2 11.9-6.4 11.9-1.6.0-2.8.3-2.8.7.0 1.7 6.6 9 10.8 11.9 7.8 5.3 12.9 6.8 24.3 6.8 9.3.0 10.2-.2 14.9-2.4 14.3-7 21.8-23.6 20.7-45.5-.4-9.1-2.6-22.3-5.3-33l-1.5-5.7-1.5 5.3c-3.1 11.1-9.5 18.8-18.5 22.3-6.2 2.5-19.6 2.6-27 .4-25.9-7.8-46-27.9-53.8-53.8-2.8-9.7-3.2-24-.8-31 4.1-12.1 11.4-21.1 21.2-25.9 7.8-3.9 13.4-5 24.3-4.5 16 .7 27.8 6.4 41.3 20 13.5 13.5 23.7 31.9 31.6 56.7 12.1 38.2 8.7 69.3-10 88.9C1165.5 6013.8 1149.6 6019.2 1133.9 6016.7zM1148.9 5946.6c12.8-5.5 18.7-22.3 14.6-42-5.3-25.9-19.7-49.4-35.2-57-6.9-3.4-12.7-4.5-20.4-4.1-14 .8-21.8 8.3-24.1 22.9-.7 4.9-.6 7.3.8 15.5 2 11.8 6.7 28.5 10.1 35.7 7.1 15.4 21.2 27.8 34.6 30.6C1135.1 5949.4 1144.2 5948.7 1148.9 5946.6z"
        />
        <path class="st24" d="M987 5737.1v-5h140 140v5 5h-140H987v-5z" />
        <path
          class="st24"
          d="M1058.7 5680.7c-8.1-2.3-14.8-6.4-20.6-12.6-5.6-6-10.7-15.1-11.6-21.1-1.1-6.9 4-11.4 11-9.4 4 1.1 5.4 2.1 7.6 5.8 3.7 6.2 1.2 12.1-5.6 13l-3.4.5 1.5 2.4c4 7.1 15.6 14.5 25.6 16.4 21.4 4.1 37.8-7.2 42.8-29.6 2.6-11.8.7-33.2-4.6-53.1l-1-3.7-1.8 6.2c-2.1 7.3-4.7 11.6-9.6 16-16 14.5-47.7 7.6-69.5-15-14-14.5-21.2-29.9-22-47.3-.2-5.1-.1-10.7.5-13.3 2.2-10.6 10.6-22.9 18.7-27.7 19.7-11.5 45.9-7.4 65.8 10.4 26.3 23.5 46.7 81.2 40.7 115.5-4.2 24.4-18.4 41.6-38.2 46.7C1078.2 5682.7 1065.3 5682.6 1058.7 5680.7zM1079.8 5612c11.4-5 17.8-19.7 15.7-35.8-2.6-20.4-11.8-41-23.9-53.5-9.5-9.9-18.1-13.8-30.3-13.8-9.4.0-14.7 2.1-19.7 7.6-7.9 8.8-8.8 20.4-3.4 42.4 5.7 23 9.4 30.6 19.9 41.4 7.4 7.4 13.7 11.2 22.2 13.2C1066.6 5614.8 1074.8 5614.2 1079.8 5612z"
        />
        <path
          class="st24"
          d="M1174.9 5614.1c-10.9-3.1-20.6-12.5-27.5-26.4-3.6-7.5-4-11.4-.9-11.8 1.4-.2 2.1.3 2.8 2.1 4.1 10.2 8.8 18.3 13.2 22.8 11.5 11.5 25.6 12.6 30.6 2.6 3.4-6.9 2.7-12.2-5.3-44.3-7.6-30-10.5-37.8-16.2-43.5-1.7-1.7-4.4-3.6-6-4.5-3.8-1.8-12-1.8-16.4-.1l-3.4 1.4 3.2 1.4c3.9 1.7 6.5 5.3 7.3 9.7.4 2.9.2 3.6-2 6.1s-3 2.8-6.4 2.7c-11.7-.2-16.4-15.7-6.9-23 6.4-4.8 19.4-6.1 26.4-2.6 4.5 2.2 9.7 7.1 13 12.2l2.8 4.5 2.4-4.8c4.7-9.3 13.5-14.3 24.6-13.7 14 .7 25.5 10 33.9 27.3 3.8 7.7 4.1 11.6 1.2 12.1-1.6.2-2.1-.6-4-5.4-6.8-17.5-17.8-28.2-30.1-28.9-8.5-.6-12.4 2.6-14.3 11.6-.9 4.1-.8 5.9.8 14 3.2 15.9 13.4 53.6 15.8 58.9 2.9 6.1 7.4 11.4 12 13.8 4 2.1 12.1 2.4 17.3.7l3.1-1.1-3.6-2c-4.1-2.4-5.4-3.8-6.6-7.9-2.5-7.8 5.5-13.2 13.3-9 4.3 2.2 6.6 6.1 6.6 11.1.0 7.5-4.2 11.7-14.1 14.3-11.8 3.1-21.7-1.1-29.7-12.2l-3.9-5.5-1.9 4c-2.4 5-7.5 10.2-12.6 12.4C1188.4 5615.2 1180.5 5615.7 1174.9 5614.1z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #ecf1f2;
}
.st1 {
  display: none;
  fill: #7f8c8c;
}
.st2 {
  fill: #7f8c8c;
}
.st3 {
  fill: #5f6572;
}
.st4 {
  fill: url(#SVGID_1_);
}
.st5 {
  fill: url(#SVGID_2_);
}
.st6 {
  fill: url(#SVGID_3_);
}
.st7 {
  fill: url(#SVGID_4_);
}
.st8 {
  fill: url(#SVGID_5_);
}
.st9 {
  fill: url(#SVGID_6_);
}
.st10 {
  fill: url(#SVGID_7_);
}
.st11 {
  fill: url(#SVGID_8_);
}
.st12 {
  fill: url(#SVGID_9_);
}
.st13 {
  fill: url(#SVGID_10_);
}
.st14 {
  fill: url(#SVGID_11_);
}
.st15 {
  fill: url(#SVGID_12_);
}
.st16 {
  opacity: 0.53;
  fill: url(#SVGID_13_);
  enable-background: new;
}
.st17 {
  fill: url(#SVGID_14_);
}
.st18 {
  fill: #010104;
}
.st19 {
  fill: #7f7f81;
}
.st20 {
  fill: #bdc3c7;
}
.st21 {
  fill: #1b1c13;
}
.st22 {
  fill: #242424;
}
.st23 {
  fill: #888;
}
.st24 {
  fill: #fff;
}
</style>
