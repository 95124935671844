<template>
  <div
    class="calculator"
    :class="{ 'calculator--is-shown': isShown && calculatorMayBeShown }"
  >
    <input type="text" v-model="inputEntry" class="calculator__input-field" />
    <div class="calculator__button-container">
      <div
        @click="buttonClicked('sin')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--inverse-text': inverseMode
        }"
      >
        {{ inverseMode ? "arcsin" : "sin" }}
      </div>
      <div
        @click="buttonClicked('cos')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--inverse-text': inverseMode
        }"
      >
        {{ inverseMode ? "arccos" : "cos" }}
      </div>
      <div
        @click="buttonClicked('tan')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--inverse-text': inverseMode
        }"
      >
        {{ inverseMode ? "arctan" : "tan" }}
      </div>
      <div
        @click="buttonClicked('cot')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--inverse-text': inverseMode
        }"
      >
        {{ inverseMode ? "arccot" : "cot" }}
      </div>
      <div
        @click="buttonClicked('AC')"
        class="calculator__button calculator__button--small-text calculator__button--blue-background"
      >
        AC
      </div>
      <div
        @click="buttonClicked('sqrt')"
        class="calculator__button calculator__button--square-root"
      >
        {{ inverseMode ? "$x^2$" : "$\\sqrt{\\,}$" }}
      </div>
      <div @click="buttonClicked(7)" class="calculator__button">7</div>
      <div @click="buttonClicked(8)" class="calculator__button">8</div>
      <div @click="buttonClicked(9)" class="calculator__button">9</div>
      <div
        @click="buttonClicked('+')"
        class="calculator__button calculator__button--blue-background"
        :class="{ 'calculator__button--selected': selectedOperation === '+' }"
      >
        +
      </div>
      <div
        @click="buttonClicked('INV')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--blue-background': inverseMode
        }"
      >
        Inv
      </div>
      <div @click="buttonClicked(4)" class="calculator__button">4</div>
      <div @click="buttonClicked(5)" class="calculator__button">5</div>
      <div @click="buttonClicked(6)" class="calculator__button">6</div>
      <div
        @click="buttonClicked('-')"
        class="calculator__button calculator__button--blue-background"
        :class="{ 'calculator__button--selected': selectedOperation === '-' }"
      >
        -
      </div>
      <div
        @click="buttonClicked('radians')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--blue-background': angleMode === 'radians'
        }"
      >
        Rad
      </div>
      <div @click="buttonClicked(1)" class="calculator__button">1</div>
      <div @click="buttonClicked(2)" class="calculator__button">2</div>
      <div @click="buttonClicked(3)" class="calculator__button">3</div>
      <div
        @click="buttonClicked('*')"
        class="calculator__button calculator__button--blue-background"
        :class="{ 'calculator__button--selected': selectedOperation === '*' }"
      >
        &#xd7;
      </div>
      <div
        @click="buttonClicked('degrees')"
        class="calculator__button calculator__button--small-text"
        :class="{
          'calculator__button--blue-background': angleMode === 'degrees'
        }"
      >
        Deg
      </div>
      <div @click="buttonClicked(0)" class="calculator__button">0</div>
      <div @click="buttonClicked(',')" class="calculator__button">
        {{ $i18n.locale === "de" ? "," : "." }}
      </div>
      <div @click="buttonClicked('=')" class="calculator__button">=</div>
      <div
        @click="buttonClicked('/')"
        class="calculator__button calculator__button--blue-background"
        :class="{ 'calculator__button--selected': selectedOperation === '/' }"
      >
        &#xf7;
      </div>
      <!-- <div
        @click="$emit('closeCalculator')"
        class="calculator__button calculator__button--close"
      >
        Schließen
      </div> -->
    </div>
    <app-button-x
      @click="closeCalculator()"
      class="calculator__closing-x"
    ></app-button-x>
  </div>
</template>

<script>
export default {
  name: "calculator",
  components: {},
  props: {
    isShown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      calculatorMayBeShown: true,
      inputEntry: 0,
      angleMode: "degrees",
      inverseMode: false,
      entryIncludesDot: false,
      entry1: false,
      entry2: false,
      entryNumber: 1,
      resetCalculator: false,
      selectedOperation: false
    };
  },
  computed: {
    entry() {
      return Number(String(this.inputEntry).replace(",", "."));
    },
    language() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getRoundedResult(number) {
      let result = Math.round(100000000 * number) / 100000000;
      if (Number.isInteger(result)) {
        this.entryIncludesDot = true;
      }
      if (this.$i18n.locale === "de") {
        result = String(result).replace(".", ",");
      }

      return result;
    },
    getAngle(angle) {
      if (this.angleMode === "radians") {
        return angle;
      } else {
        return (180 * angle) / Math.PI;
      }
    },
    buttonClicked(button) {
      if (typeof button === "number") {
        if (this.resetCalculator) {
          this.inputEntry = 0;
          this.entryIncludesDot = false;
          this.entry1 = false;
          this.entry2 = false;
          this.entryNumber = 1;
          this.resetCalculator = false;
          this.selectedOperation = false;
        }

        if (
          this.inputEntry === 0 ||
          this.inputEntry === "0" ||
          (this.selectedOperation !== false && this.entry2 === false)
        ) {
          this.inputEntry = button;
        } else {
          this.inputEntry += "" + button;
        }
      } else if (
        button === "sqrt" ||
        button === "sin" ||
        button === "cos" ||
        button === "tan" ||
        button === "cot"
      ) {
        this.resetCalculator = true;

        let angle =
          this.angleMode === "degrees"
            ? this.entry * (Math.PI / 180)
            : this.entry;

        if (button === "sqrt") {
          if (!this.inverseMode) {
            this.inputEntry = this.getRoundedResult(Math.sqrt(this.entry));
          } else {
            this.inputEntry = this.getRoundedResult(Math.pow(this.entry, 2));
          }
        } else if (button === "sin") {
          if (!this.inverseMode) {
            this.inputEntry = this.getRoundedResult(Math.sin(angle));
          } else {
            this.inputEntry = this.getRoundedResult(
              this.getAngle(Math.asin(this.entry))
            );
          }
        } else if (button === "cos") {
          if (!this.inverseMode) {
            this.inputEntry = this.getRoundedResult(Math.cos(angle));
          } else {
            this.inputEntry = this.getRoundedResult(
              this.getAngle(Math.acos(this.entry))
            );
          }
        } else if (button === "tan") {
          if (!this.inverseMode) {
            this.inputEntry = this.getRoundedResult(Math.tan(angle));
          } else {
            this.inputEntry = this.getRoundedResult(
              this.getAngle(Math.atan(this.entry))
            );
          }
        } else if (button === "cot") {
          if (!this.inverseMode) {
            this.inputEntry = this.getRoundedResult(1 / Math.tan(angle));
          } else {
            this.inputEntry = this.getRoundedResult(
              this.getAngle(Math.PI / 2 - Math.atan(this.entry))
            );
          }
        }
      } else if (button === ",") {
        if (this.entryIncludesDot) {
          return;
        } else {
          if (this.$i18n.locale === "de") {
            this.inputEntry += ",";
          } else {
            this.inputEntry += ".";
          }

          this.entryIncludesDot = true;
        }
      } else if (button === "AC") {
        this.inputEntry = 0;
        this.entryIncludesDot = false;
        this.entry1 = false;
        this.entry2 = false;
        this.entryNumber = 1;
        this.resetCalculator = false;
        this.selectedOperation = false;
      } else if (button === "degrees" || button === "radians") {
        this.angleMode = button;
      } else if (button === "INV") {
        this.inverseMode = !this.inverseMode;
        this.typesetMath();
      }

      if (this.entryNumber === 1) {
        this.entry1 = this.entry;
      } else {
        this.entry2 = this.entry;
      }

      if (
        button === "+" ||
        button === "-" ||
        button === "*" ||
        button === "/"
      ) {
        this.selectedOperation = button;
        this.entryNumber = 2;
        this.entryIncludesDot = false;
      } else if (button === "=") {
        this.resetCalculator = true;

        if (this.selectedOperation === "+") {
          this.inputEntry = this.getRoundedResult(this.entry1 + this.entry2);
        } else if (this.selectedOperation === "-") {
          this.inputEntry = this.getRoundedResult(this.entry1 - this.entry2);
        } else if (this.selectedOperation === "*") {
          this.inputEntry = this.getRoundedResult(this.entry1 * this.entry2);
        } else if (this.selectedOperation === "/") {
          this.inputEntry = this.getRoundedResult(this.entry1 / this.entry2);
        }
        this.entryNumber = 1;
        this.entry1 = this.entry;
        this.entry2 = false;
        this.selectedOperation = false;
      }

      if (this.inputEntry === "Infinity" || this.inputEntry === "NaN") {
        this.inputEntry = "Fehler";
      }
    },
    closeCalculator() {
      this.calculatorMayBeShown = false;
      setTimeout(() => {
        this.calculatorMayBeShown = true;
      }, 750);
    }
  },
  watch: {
    language(to) {
      if (to === "de") {
        this.inputEntry = String(this.inputEntry).replace(".", ",");
      } else {
        this.inputEntry = String(this.inputEntry).replace(",", ".");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.calculator {
  position: fixed;
  visibility: hidden;
  // left: 4rem;
  top: 0;
  z-index: 10;
  transform: translateY(calc(100 * var(--vh) - 6.5rem)) scale(0.8);
  width: 53rem;
  padding: 3rem;
  // border-top: 1px solid #2a2a2a;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  background-color: #000;

  transition: transform 0.75s, visibility 0s 0.75s;

  @include respond(tab-port) {
    border-top: 1px solid #2a2a2a;
  }

  @include respond(phone) {
    transform: translateY(calc(100 * var(--vh) - 6.5rem));
    width: 100vw;
  }

  &--is-shown {
    visibility: visible;
    transform: translateY(calc(100 * var(--vh) - 100% - 6.5rem)) scale(0.8);

    transition: transform 0.75s;

    @include respond(phone) {
      transform: translateY(calc(100 * var(--vh) - 100% - 6.5rem));
    }
  }

  &__input-field {
    vertical-align: top;
    margin: 0 !important;
    padding: 0 1.2rem;
    width: 100%;
    height: 7rem;
    border: none;
    border-radius: 0.6rem;
    text-align: end;
    font-family: $font-primary;
    font-size: 4rem;
    line-height: 7rem;
    color: $color-white;
    background-color: #2a2a2a;
  }

  &__button-container {
    display: grid;
    margin-top: 2rem;
    grid-template-columns: repeat(5, 7rem);
    grid-column-gap: calc((53rem - 6rem - 35rem) / 4);
    grid-row-gap: 0.75rem;

    @include respond(phone) {
      grid-column-gap: calc((100vw - 6rem - 35rem) / 4);
    }
  }

  &__button {
    width: 7rem;
    height: 7rem;
    border-radius: 0.6rem;
    font-size: 4rem;
    text-align: center;
    line-height: 7rem;
    background-color: #2a2a2a;
    cursor: pointer;

    transition: background-color 0.2s, color 0.2s;

    &--small-text {
      font-size: 3rem;
    }

    &--square-root {
      font-size: 2.4rem;
      line-height: 6.3rem;
    }

    &--blue-background {
      background-color: $color-primary-dark;
    }

    &--selected {
      background-color: $color-white;
      color: $color-primary;
    }

    &--inverse-text {
      font-size: 2.8rem;
    }

    &--close {
      grid-column: 1 / 6;
      width: initial;
      font-size: 2.8rem;
      background-color: #1a1a1a;
    }
  }

  &__closing-x {
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
  }
}
</style>
