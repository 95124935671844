<template>
  <div
    class="stackrel"
    :class="{ 'stackrel--active': active1, 'stackrel--shown': shown }"
  >
    <span class="stackrel__top-symbol">{{ top }}</span
    ><br />
    <span class="stackrel__middle-symbol">{{ middle }}</span
    ><br />
    <span class="stackrel__bottom-symbol">{{ bottom }}</span>
  </div>
</template>

<script>
export default {
  name: "stackrel",
  data() {
    return {
      active1: false
    };
  },
  props: {
    top: {
      type: String,
      default: ""
    },
    middle: {
      type: String,
      default: ""
    },
    bottom: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: true
    },
    shown: {
      type: Boolean,
      default: false
    }
  },
  created() {
    // alert(1);
  },
  watch: {
    active(to) {
      if (to) {
        setTimeout(() => {
          this.active1 = true;
        }, 1);
      } else {
        this.active1 = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.stackrel {
  display: inline-block;
  transform: translateY(calc(100% - 1em));
  // width: 0;
  line-height: 1;
  text-align: center;

  &__top-symbol {
    display: inline-block;
    // width: 0;
    // margin-right: -50%;
    // line-height: 1;
  }

  &__middle-symbol {
    display: inline-block;
    // transform: translate(-0%, 100%);
    transform: translate(-1em, 0.3em);
    width: 0;
    // font-size: 0.875em;
    // line-height: 1;
    // color: $color-primary-light;
    // color: #3a3a3a;
    color: transparent;

    transform: translate(calc(-1em - 1.5rem), calc(0.3em + 1.5rem));
    transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.35);

    .stackrel--shown & {
      transition: none;
    }

    .stackrel--active &,
    .stackrel--shown & {
      color: $color-primary-light;
      transform: translate(-1em, 0.3em);
    }
  }

  &__bottom-symbol {
    display: inline-block;
    // transform: translate(-0%, 255%);
    transform: translate(-2.5em, 0.3em);
    width: 0;
    font-size: 0.875em;
    // line-height: 1;
    // color: $color-primary-light;
    // color: #3a3a3a;
    color: transparent;

    .stackrel--active &,
    .stackrel--shown & {
      color: $color-primary-light;
    }
  }
}
</style>
