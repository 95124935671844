<template>
  <div class="login-modal tabstart" tabindex="0">
    <app-input-complex
      :autocompleteInput="'email'"
      :placeholder="$t('email')"
      :text="email"
      @inputEvent="email = $event"
      @keyupEvent="loginOnEnterKey($event)"
      @blurEvent="checkEmail"
    ></app-input-complex>
    <app-input-complex
      :icon="'lock'"
      :id="'login-modal__password'"
      :type="inputType"
      :placeholder="$t('password')"
      :placeholderIsSmall="placeholderIsSmall"
      :eyeIsShown="true"
      @inputEvent="password = $event"
      @keyupEvent="loginOnEnterKey($event)"
      @mousedownEvent="placeholderIsSmall = true"
      @mouseupEvent="placeholderIsSmall = false"
    ></app-input-complex>
    <app-button
      @click="login()"
      size="big"
      :text="$t('button_login')"
      class="login-modal__button"
    ></app-button>
    <div
      @click="changeModal('app-changepassword-modal')"
      class="login-modal__forgotten-password-container"
    >
      <span class="login-modal__forgotten-password">{{
        $t("forgotten_password")
      }}</span>
    </div>
    <app-footer-link
      :text="$t('form_login').footer_link"
      @click="
        $store.state.footerLinkIsShown = true;
        changeModal('app-register-modal');
      "
      class="footer-link"
    ></app-footer-link>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppInputComplex from "@/components/Molecules/InputComplex.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppFooterLink from "@/components/Molecules/FooterLink.vue";

export default {
  name: "appLoginForm",
  components: {
    AppInputComplex,
    AppButton,
    AppButtonXRound,
    AppFooterLink
  },
  data() {
    return {
      email: "",
      placeholderIsSmall: false, // for password
      inputType: "password",
      password: ""
    };
  },
  methods: {
    loginOnEnterKey(e) {
      if (e.code === "Enter" || e.keyCode === 13) {
        this.login();
      }
    },
    login() {
      this.email = this.email.trim(); // Remove whitespaces

      if (this.email === "") {
        if (this.password === "") {
          this.toggleAlert(
            this.$t("form_login_feedback")["no_email_no_password"]
          );
        } else {
          this.toggleAlert(this.$t("form_login_feedback")["no_email"]);
        }
        return;
      } else if (this.password === "") {
        this.toggleAlert(this.$t("form_login_feedback")["no_password"]);
        return;
      } else if (this.password.length < 6) {
        this.toggleAlert(this.$t("form_login_feedback")["wrong_password"]);
        return;
      }
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.login-modal {
  width: 46rem;
  padding: 5.5rem 5.5rem 0;
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
    padding: 7.5rem 3rem 0;
  }

  &:focus {
    outline: none;
  }

  &__button {
    margin-top: 2.875rem;
    width: 100%;
    height: 5rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    font-size: 2.4rem;
    text-align: center;

    @include respond(phone) {
      margin-top: 5.5rem;
      margin-bottom: 3rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }

  &__forgotten-password-container {
    position: relative;
    height: 5rem;
  }

  &__forgotten-password {
    position: absolute;
    right: 0;
    top: 2rem;
    text-align: right;
    font-size: 2rem;
    color: $color-white;

    cursor: pointer;
    transition: color 0.2s;

    @include respond(phone) {
      top: 1rem;
      font-size: 2.6rem;
    }

    &:hover {
      color: $color-primary-light;
    }
  }
}
</style>
