<template>
  <button
    class="button"
    :class="{
      button__secondary: variation === 'secondary',
      button__big: size === 'big',
      button__bigger: size === 'bigger',
      button__transparent: variation === 'transparent',
      button__grey: variation === 'grey',
      button__selected: selected
    }"
  >
    <app-menu-icon
      v-if="icon"
      :icon="icon"
      class="button__icon"
      :class="{ 'button__icon--bubble': icon === 'bubble' }"
    ></app-menu-icon>
    <span v-if="iconText" class="button__icon-text">{{ iconText }}</span>
    {{ text }}
  </button>
</template>

<script>
import AppMenuIcon from "@/components/Atoms/Icons/MenuIcons.vue";

export default {
  name: "appButton",
  props: {
    variation: String,
    size: String,
    text: String,
    icon: String,
    iconText: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppMenuIcon
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.button {
  display: block;
  position: relative;
  padding: 0.7rem 1.3rem;
  border: none;
  border-radius: $border-radius;
  text-align: left;

  font-family: $font-primary-bold;
  font-weight: 100;
  font-size: $font-size-smaller;
  line-height: 1.2;
  color: $color-white;
  background-color: $color-primary;
  cursor: pointer;

  transition: background-color 0.2s;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $color-primary-light;
    }

    &:active {
      // background-color: $color-primary-darker;
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }

  &__big {
    padding: 0.77rem 1.43rem;
    font-size: $font-size-small;
    // font-size: 2.4rem;
  }

  &__bigger {
    // font-size: 2.4rem;
    padding: 1rem 2rem;
    // font-size: $font-size-small;
    font-size: 2.2rem;
  }

  &__secondary {
    background-color: $color-secondary;
    color: $color-black;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $color-secondary-light;
      }

      &:active {
        background-color: $color-secondary-dark;
      }
    }
  }

  &__transparent {
    background-color: transparent;
    transition: color 0.2s;

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:active {
        background-color: transparent;
        color: $color-primary-light;
      }
    }
  }

  &__grey {
    width: 100%;
    padding: 1rem 1.5rem 1rem 2.5rem;
    margin: 0.75rem 0;
    border-radius: 0.5rem;
    background-color: transparent;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        // // // background-color: $color-grey-light;
        background-color: #2a2a2a;
      }

      &:active {
        background-color: transparent;
      }

      &:active:hover {
        // // // background-color: $color-grey-light;
        background-color: #2a2a2a;
      }
    }

    &::after {
      @include pseudo-element;
      left: 1.05rem;
      top: 0.75rem;
      z-index: 0;
      width: 2.9rem;
      height: 2.9rem;
      border-radius: 50%;
      background-color: $color-grey-light;

      transition: background-color 0.2s;
    }

    &:hover::after {
      // // // background-color: #666;
      background-color: #555;
    }

    &.button__selected::after {
      background-color: $color-primary;
    }

    &::before {
      // Enlargen hover area
      @include pseudo-element;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 0.75rem);
    }
  }

  &__icon {
    float: left;

    z-index: 1000;
    position: relative;

    transform: translateY(0.275rem);
    margin-right: 1.3rem;
    width: 1.9rem;
    fill: $color-white;

    transition: fill 0.2s;

    &--bubble {
      transform: translate(-0.8rem, 0.25rem);
      width: 1.6rem;
      height: 1.6rem;
      fill: $color-white;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &__transparent:hover &__icon {
      fill: $color-primary-light;
    }
  }

  &__icon-text {
    position: absolute;
    transform: translate(-3.475rem, 0.6rem);
    font-family: $font-primary-bold;
    font-size: 1.2rem;
    line-height: 1;
    color: $color-black;
    width: max-content;
    z-index: 1000;

    transition: color 0.2s;
  }

  &__selected &__icon {
    fill: $color-white;
  }

  @media (hover: hover) and (pointer: fine) {
    &__selected:hover &__icon {
      fill: $color-white;
    }
  }
}
</style>
