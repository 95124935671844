<template>
  <svg
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Asset-Artboard-Page">
      <g id="Mathopia-Logo">
        <g id="Minimiert">
          <g>
            <path
              :style="{ fill: color }"
              d="M16 0c-8.823 0-16 7.177-16 16s7.177 16 16 16 16-7.177 16-16-7.177-16-16-16zM19.151 20.589c-0.013 0.44-0.113 0.859-0.285 1.238 0 0.004-0.001 0.007-0.003 0.011-0.065 0.16-0.14 0.314-0.222 0.46-0.547 0.965-1.476 1.496-2.616 1.496h-0.003c-0.036 0-0.071 0-0.107-0.002-0.025 0.002-0.049 0.002-0.075 0.002-1.253 0-2.346-0.698-2.909-1.725-0.008-0.014-0.015-0.027-0.022-0.041-0.636-1.134-0.607-2.521 0.083-3.724l0.922-1.686 1.045 1.885c0.008 0.014 0.016 0.028 0.025 0.042h0l-0.361 0.662c-0.004 0.007-0.007 0.013-0.011 0.020-0.204 0.353-0.302 0.73-0.292 1.093 0.001 0.052 0.004 0.104 0.010 0.155v0.004c0.026 0.231 0.097 0.453 0.214 0.656 0.282 0.487 0.769 0.765 1.38 0.792h0.004c0.524-0.028 0.967-0.338 1.196-0.777 0.218-0.585 0.159-1.375-0.162-1.911-0.006-0.009-0.011-0.018-0.017-0.026l-0.738-1.333c-0.008-0.019-0.017-0.037-0.027-0.055l-1.852-3.343c-0.008-0.014-0.015-0.027-0.025-0.040-0.002-0.005-0.005-0.009-0.008-0.014l-1.863-3.36-4.455 8.14c-0.247 0.451-0.814 0.618-1.265 0.369-0.451-0.247-0.618-0.813-0.371-1.264l5.268-9.624c0.163-0.299 0.475-0.485 0.815-0.485 0.001 0 0.003 0 0.003 0 0.338 0 0.651 0.183 0.816 0.48l1.827 3.295c0 0.001 0 0.001 0.001 0.003 0.009 0.023 0.020 0.046 0.033 0.068l1.854 3.342c0.010 0.018 0.021 0.036 0.033 0.053 0 0.003 0.001 0.004 0.002 0.005l1.579 2.845c0.464 0.691 0.637 1.505 0.58 2.293zM25.322 19.56c-0.441 0.265-1.015 0.121-1.28-0.321l-4.939-8.228-1.479 2.703-1.070-1.927 0.001-0.001 1.692-3.097c0.161-0.294 0.465-0.478 0.8-0.485 0.335-0.005 0.646 0.165 0.818 0.452l5.776 9.624c0.265 0.442 0.123 1.014-0.319 1.28z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "signetFull",
  props: {
    color: {
      type: String,
      default: "white"
    }
  }
};
</script>

<style scoped>
path {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff;
}
</style>
