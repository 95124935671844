<template>
  <div class="notification-modal tabstart" tabindex="0">
    <div class="notification-modal__lottie-container">
      <app-lottie
        :lottie="'email_read'"
        :playedframes="[0, 50, 0]"
        :timeout="150"
        class="notification-modal__animation"
      ></app-lottie>
    </div>
    <h2 class="notification-modal__heading">
      {{ t($store.state.shownNotification.heading) }}
    </h2>
    <p class="notification-modal__text">
      {{ t($store.state.shownNotification.text) }}
    </p>
    <app-button
      @mousedown="toggleModal()"
      size="bigger"
      :text="$t('notifications').close_notification"
      class="notification-modal__button"
    ></app-button>

    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButton,
    AppButtonXRound,
    AppLottie
  },
  data() {
    return {
      instructionsAreShown: false
    };
  },
  computed: {
    registrationSuccessful() {
      return this.$store.state.registrationSuccessful;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.notification-modal {
  position: relative;
  width: 66.4rem;
  padding: 4.5rem;
  border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  overflow: visible;

  @include respond(phone) {
    padding: 1.5rem 2rem;
    width: calc(100vw - 1.5rem);
    min-height: calc(100vh - 5.25rem);
  }

  &:focus {
    outline: none;
  }

  &__heading {
    padding: 4.5rem 0 2.25rem;
    font-size: 2.6rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3rem;
    }
  }

  &__lottie-container {
    position: relative;
    transform: translateX(23.7rem);
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: #181818;

    @include respond(phone) {
      transform: translate(calc(50vw - 8rem), 1.5rem);
    }
  }

  &__animation {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 6.5rem;
    transform: translate(-50%, calc(-50% - 0.5rem));
    filter: grayscale(1);
  }

  &__text {
    padding-bottom: 4.5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    font-size: 2.2rem;
    color: #999;

    @include respond(tab-port) {
      // padding: 1.915rem 2.5rem 2.5rem 2.5rem;
      font-size: 2.4rem;
      line-height: 1.45;
      color: #ccc;
    }

    @include respond(phone) {
      font-size: 2.6rem;
      // color: #aaa;
      color: #ccc;
    }
  }

  &__button {
    transform: translateX(calc(57rem - 100%));
    font-family: $font-primary-bold;
    background-color: #222;

    @include respond(phone) {
      transform: translateX(calc(100vw - 100% - 5.5rem));
      font-size: 2.6rem;
    }

    &:hover {
      background-color: #333;
    }
  }
}
</style>
