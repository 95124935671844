<template>
  <div class="onboarding-drag-and-drop">
    <div class="onboarding-drag-and-drop__drop-area">
      <div
        v-for="n in 2"
        :key="n"
        class="onboarding-drag-and-drop__draggable"
      ></div>
    </div>
    <div>
      <div
        v-for="n in 4"
        :key="n"
        class="onboarding-drag-and-drop__draggable onboarding-drag-and-drop__draggable--not-dragged"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding-drag-and-drop {
  width: 100%;
  height: calc(100% - 1.6rem);

  &__drop-area {
    // left: 50%;
    margin: 2.8rem auto 0.9rem; // was: 2.6rem auto 0.9rem;
    width: 13rem;
    height: 2.4rem;
    border-radius: 1rem;
    background-color: #d8d8d8;
  }

  &__draggable {
    float: left;
    margin: 0.7rem 0 0 0.35rem;
    width: 2rem;
    height: 1rem;
    border-radius: 10rem;
    background-color: $color-white;

    &:first-child {
      margin-left: 0.7rem;
    }

    &--not-dragged {
      background-color: $color-primary-light;

      &:first-child {
        margin-left: calc((100% - 9.05rem) / 2);
      }
    }
  }
}
</style>
