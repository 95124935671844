<template>
  <div class="about-us">
    <h2 class="heading-secondary about-us__heading">
      {{ $t("about_us_heading") }}
    </h2>
    <p class="about-us__introduction">
      {{ $t("about_us_paragraph")[0] }}
      <br /><br />
      {{ $t("about_us_paragraph")[1] }}
    </p>
    <div class="about-us__container">
      <img
        src="@/assets/Home/AboutUs/founder_new12.png"
        alt="Ein Bild vom Gründer Dr. Patrick Breuning"
        class="about-us__picture"
      />
      <!-- was: founder_19, founder_test4.png, founder_testnew2.png, founder_new12.png -->
      <p class="about-us__quotation-container">
        <span class="about-us__quotation">
          &bdquo;{{ $t("about_us_quote")[0] }} &ndash;
          {{ $t("about_us_quote")[1] }}&ldquo;<br />
        </span>
        <img
          src="@/assets/Home/AboutUs/signature_founder.png"
          alt="Unterschrift von Patrick Breuning"
          class="about-us__signature"
        />
        <span class="about-us__name">Dr. Patrick Breuning</span>
        <span class="about-us__name-description">{{
          $t("about_us_quote")[2]
        }}</span>
      </p>
    </div>
    <p class="about-us__about-person">
      <span class="about-us__about-person-bold">{{
        $t("about_us_person")[0]
      }}</span>
      {{ $t("about_us_person")[1] }}
      <span class="about-us__subject">{{ $t("about_us_person")[2] }}</span>
      {{ $t("about_us_person")[3] }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.about-us {
  position: relative;
  z-index: 3;
  width: 100%;
  min-height: 100vh;
  padding-top: 12rem;
  padding-bottom: 25vh;
  color: $color-white;
  background-color: #131313;

  display: grid;
  grid-template-columns: 1fr $width-center 1fr;

  @include respond(tab-land) {
    grid-template-columns:
      1fr min(100% - 2 * #{$side-margin-land}, #{$width-center})
      1fr;
  }

  /* @include respond(tab-port) {
  } */

  @include respond(phone) {
    grid-template-columns: 1.5rem 1fr 1.5rem;
    padding-bottom: 12rem;
  }

  &__heading {
    position: relative;
    grid-column: 2 / 3;
    width: max-content;

    @include respond(phone) {
      width: auto;
      line-height: 1.3;
    }
  }

  &__introduction {
    grid-column: 2 / 3;
    align-self: start;

    margin-top: 5.75rem;
    width: min(90rem, 100%);
    font-size: 2.6rem;
    line-height: 1.6;
    color: #d4d5d6;

    @include respond(tab-port) {
      width: min(80rem, 100%);
      font-size: 2.8rem;
      line-height: 1.5;
      color: rgb(220, 220, 220);
    }

    @include respond(phone) {
      margin-bottom: -1.5rem;
    }
  }

  &__subheading {
    grid-column: 2 / 3;
    margin-top: 12rem;
    font-size: 3rem;
    line-height: 1;
  }

  &__text {
    grid-column: 2 / 3;
    font-size: 2.4rem;
    color: #999;
  }

  &__container {
    grid-column: 2 / 3;
    margin-top: 12rem;
    padding: 5rem;
    padding-bottom: 7.5rem;
    border-radius: $border-radius;
    font-size: 2.4rem;
    color: rgb(160, 160, 160);
    background-color: $color-black;

    display: grid;
    grid-template-columns: 55rem 1fr;

    @include respond(tab-port) {
      grid-template-columns: min(50rem, 60%) 1fr;
    }

    @include respond(phone) {
      // position: relative;
      // margin-top: 5.5rem;
      // height: calc(0.66667 * (100vw - 3rem));
      // padding: 3rem 1.5rem 0;
      padding: 1.125rem 1.5rem 0;

      grid-template-columns: 100%;
      grid-template-rows: max-content 1fr;
    }
  }

  &__picture {
    grid-column: 1 / 2;
    transform: translateX(3.4rem);
    width: max(78.2%, 25rem);
    // width: max(70%, 21rem);

    // opacity: 0.95;

    @include respond(phone) {
      transform: translate(-3.25%, -1.125%);
      justify-self: center;
      // width: max(60%, 25rem);
      width: 90%;
      height: auto;
    }
  }

  &__quotation-container {
    grid-column: 2 / 3;

    @include respond(phone) {
      // grid-column: 2 / 3;
      grid-column: 1 / 2;
      margin-left: -1.8rem;
      // // // margin-top: 4.5rem;
      padding: 5rem 0.3rem;
      width: calc(100% + 3.6rem);
      background-color: #131313;
    }
  }

  &__quotation {
    display: block;
    font-style: italic;

    @include respond(phone) {
      margin-top: 0.3rem;
      font-size: 2.6rem;
      // color: rgb(160, 160, 160);
      color: rgb(179, 179, 179);
    }
  }

  &__signature {
    position: relative;
    margin-top: 4.9rem;
    width: min(28.8rem, 100%);
    height: auto;

    @include respond(phone) {
      margin-top: 4.2rem;
      width: min(28.8rem, 90%);
      height: auto;
    }
  }

  &__name {
    display: block;
    margin-top: 2.25rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    line-height: 1;
    color: #f9f9f9;

    @include respond(phone) {
      color: $color-white;
      margin-top: 1.2rem;
      font-size: 2.6rem;
    }
  }

  &__name-description {
    display: block;
    margin: 0.4rem 0;
    font-size: 2rem;
    line-height: 1.2;
    color: rgb(160, 160, 160);

    @include respond(phone) {
      font-size: 2.4rem;
      color: rgb(179, 179, 179);
    }
  }

  &__about-person {
    grid-column: 2 / 3;
    max-width: 93rem;
    margin-top: 2.5rem;
    border-radius: $border-radius;
    font-size: 2rem;
    line-height: 1.4;
    color: rgb(160, 160, 160);

    @include respond(phone) {
      // margin-top: 2.75rem;
      font-size: 2.4rem;
      color: rgb(179, 179, 179);
    }
  }

  &__about-person-bold {
    // font-size: 2.6rem;
    font-family: $font-primary-bold;
    color: $color-white;

    @include respond(phone) {
      display: block;
    }
  }

  &__subject {
    font-style: italic;
  }
}
</style>
