<template>
  <div class="modal tabstart" tabindex="0">
    <div class="modal__top-container">
      <h2 class="modal__heading">
        {{ $t("course_audio_modal").heading }}
      </h2>
      <app-lottie
        :lottie="'speaker'"
        :playedframes="[30, 128, 1176]"
        :speed="0.85"
        :play="$store.state.soundIsTurnedOn"
        :loop="true"
        class="modal__animation"
      ></app-lottie>
      <!-- speaker: [30, 128, 1000] bei speed 1-->
      <!-- headphones: [47, 70, 1156] bei speed 1 -->
    </div>
    <div class="modal__text-container">
      {{ $t("course_audio_modal").paragraph }}
      <div class="modal__button-container">
        <app-select-button
          @mousedown="$store.dispatch('toggleAudio')"
          :text="selectButtonSwitchText"
          :selected="$store.state.soundIsTurnedOn"
          class="modal__select-button"
        ></app-select-button>
        <app-button
          @mousedown="startCourse()"
          :text="buttonText"
          class="modal__button"
        ></app-button>
      </div>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppSelectButton from "@/components/Atoms/SelectButton.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButton,
    AppSelectButton,
    AppButtonXRound,
    AppLottie
  },
  computed: {
    selectButtonSwitchText() {
      return this.$store.state.soundIsTurnedOn
        ? this.$t("course_audio_modal").audio_on
        : this.$t("course_audio_modal").audio_off;
    },
    buttonText() {
      return this.$store.state.shownModal2 === "none"
        ? this.$t("course_audio_modal").start_button[0]
        : this.$t("course_audio_modal").start_button[1];
    }
  },
  methods: {
    startCourse() {
      if (this.$store.state.shownModal2 === "none") {
        // Reset remaining data
        this.$store.dispatch("resetLectureDataForFinishedCourse");
        if (
          !(
            this.$store.state.chosenLesson in this.$store.state.userDataLectures
          )
        ) {
          this.$store.state.userDataLectures[
            this.$store.state.chosenLesson
          ] = {};
        }
        this.$store.state.userDataLectures[this.$store.state.chosenLesson][
          "started"
        ] = false;
        this.$store.state.userDataLectures[this.$store.state.chosenLesson][
          "firstUnprocessedPage"
        ] = 1;

        this.$store.dispatch("updateCourse");
        this.$store.dispatch("getUserData");
        // // //

        if (this.$store.state.coursePageRequestedViaTOC > 0) {
          this.setPage(this.$store.state.coursePageRequestedViaTOC);
          if (this.$store.state.windowWidth < this.remToPx(189.1)) {
            this.$store.state.TOCisOpen = false;
          }
        } else {
          this.setPage("++");
        }

        this.toggleModal();
        this.$store.state.userWasAskedAboutSound = true;
      } else {
        this.$store.state.modalNumber = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.modal {
  width: 46rem;
  border-radius: 1rem;
  font-family: $font-primary;
  color: $color-white;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__top-container {
    height: 36.6rem;
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3.3rem;
    }
  }

  &__animation {
    margin: 4.25rem 0 8.5rem;
    transform: translate(calc(23rem - 50%), 1.825rem);
    width: 30rem;
    height: 32rem;
    margin-top: -2.55rem;
    margin-bottom: 0.35rem;

    @include respond(tab-port) {
      transform: translate(calc(25rem - 50%), 1.825rem);
    }

    @include respond(phone) {
      transform: translate(calc(min(207px, 50vw - 0.75rem) - 50%), 2.2rem);
    }
  }

  &__text-container {
    padding: 2.75rem 2.75rem 1.375rem;
    font-size: 2rem;
    background-color: #151515;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @include respond(tab-port) {
      padding: 1.915rem 2.5rem 2.5rem 2.5rem;
      font-size: 2.4rem;
      line-height: 1.45;
    }

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__button-container {
    margin-top: 2.2rem;
    display: grid;
    grid-template-columns: calc(100% - 28.75rem) 1fr max-content;
  }

  @include respond(tab-port) {
    transform: translateX(calc(50rem - 5rem - 100%));
  }

  @include respond(phone) {
    margin-top: 0;
  }

  &__select-button {
    grid-column: 2 / 3;
    transform: translateY(-0.4625rem);
    font-size: 2.4rem;

    @include respond(phone) {
      margin-top: 1rem;
      font-size: 2.6rem;
    }
  }

  &__button {
    grid-column: 3 / 4;
    padding: 0.7rem 2rem;
    min-width: 11.5rem;
    text-align: center;
    background-color: $color-primary;

    @include respond(phone) {
      margin-top: 1rem;
      font-size: 2.6rem;
    }
  }
}
</style>
