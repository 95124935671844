<template>
  <div class="smartphone2">
    <img
      src="./bulb_blue.svg"
      alt="A sum sign as an icon"
      class="smartphone2__icon"
    />
    <span class="smartphone2__heading">
      <div class="smartphone2__heading-line">
        {{ $t("step2_smartphone")[0] }}
      </div>
    </span>
    <div class="smartphone2__item smartphone2__item--2">
      <img
        src="@/assets/Home/Introduction2/checkmark_blue.svg"
        alt="A checkmark marking a processed task"
        class="smartphone2__checkmark"
      />
      <span class="smartphone2__subheading smartphone2__subheading--1">
        {{ $t("step2_smartphone")[1] }}
      </span>
      <p class="smartphone2__description smartphone2__description--1">
        {{ $t("step2_smartphone")[2] }} <span>\(n\)</span
        ><span aria-hidden="true" class="smartphone2__correction">\(n\)</span
        >{{ $t("step2_smartphone")[3] }}
        <span class="smartphone2__paragraph">
          {{ $t("step2_smartphone")[4] }} <span>\(n \geq 1\)</span
          >{{ $t("step2_smartphone")[5] }} <span>\(n=1\)</span>
          {{ $t("step2_smartphone")[6] }}
        </span>
      </p>
      <img
        src="@/assets/Home/Introduction2/checkmark_blue.svg"
        alt="A checkmark marking a processed task"
        class="smartphone2__checkmark smartphone2__checkmark--2"
      />
      <span class="smartphone2__subheading smartphone2__subheading--2">
        {{ $t("step2_smartphone")[7] }}
      </span>
      <p class="smartphone2__description smartphone2__description--2">
        {{ $t("step2_smartphone")[8] }} <span>\(n \geq 1\)</span>
        {{ $t("step2_smartphone")[9] }}
        <span>$$1+2+\ldots+n=\frac{n(n+1)}{2}.$$</span>
        {{ $t("step2_smartphone")[10] }} <span>\(n=1\)</span
        >{{ $t("step2_smartphone")[11] }}
      </p>
      <div class="smartphone2__circle"></div>
      <span class="smartphone2__subheading smartphone2__subheading--3">
        {{ $t("step2_smartphone")[12] }}
      </span>
      <p class="smartphone2__description smartphone2__description--3">
        {{ $t("step2_smartphone")[13] }} <span>\(n=1\)</span>
        {{ $t("step2_smartphone")[14] }} <span>\(1\)</span>.
        <span class="smartphone2__paragraph--2">
          {{ $t("step2_smartphone")[15] }} <span>\(n=1\)</span>
          {{ $t("step2_smartphone")[16] }}
          <span>\(\frac{1(1+1)}{2}=1\).</span>
        </span>
        <span class="smartphone2__paragraph--2">
          {{ $t("step2_smartphone")[17] }} <span>\(n=1\)</span>
          {{ $t("step2_smartphone")[18] }}&nbsp;&ndash;
          {{ $t("step2_smartphone")[19] }}
        </span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

span {
  display: inline-block;
  transform: rotateZ(0.001deg);
}

.smartphone2 {
  display: grid;
  grid-template-rows: 410px max-content 10px max-content max-content;

  width: 390px !important;
  padding: 0 22px;
  color: #ccc;

  &__icon {
    grid-row: 1 / 2;
    position: absolute;
    left: 50%;
    top: 225.5px;
    transform: translateX(-50%);
    width: 95px;
  }

  &__heading {
    grid-row: 2 / 3;

    font-size: 29px;
    line-height: 1.2;
  }

  &__heading-line {
    display: block;
  }

  &__subject-container {
    grid-row: 3 / 4;
    position: relative;
    height: 57.5px;
    margin-top: 20px;
    padding: 0 15px;
    font-size: 24px;
    background-color: rgba(#10120c, 0.25);

    display: grid;
  }

  &__subject {
    grid-column: 1 / 2;
    position: relative;
    line-height: 1;
    align-self: center;
  }

  &__item {
    border-top: 1px solid var(--header-line-color);
    font-size: 25px;
    padding-top: 3px;
    line-height: 1;

    display: grid;
    grid-template-columns: 55px 1fr;
    align-items: center;

    &--1 {
      grid-row: 4 / 5;
    }

    &--2 {
      grid-row: 5 / 6;

      padding: 10px 0;
    }

    &--3 {
      grid-row: 6 / 7;
      border-bottom: 1px solid var(--header-line-color);
    }
  }

  &__menu-symbol {
    font-size: 50px;
    justify-self: center;
  }

  &__content {
    grid-column: 2 / 3;
    margin-left: 5px;
  }

  &__checkmark {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    z-index: 1;
    width: 24px;
    height: 24px;
    transform: translate(13.5px, 6px);

    &--2 {
      grid-column: 1 / 2;
      grid-row: 4 / 5;

      transform: translate(13.5px, -1.5px);
    }
  }

  &__circle {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #eee;
    transform: translate(13.5px, -1.5px);
  }

  &__lock {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
    width: 29px;
    height: auto;
    transform: translate(10.5px, -4px);
  }

  &__subheading {
    grid-column: 2 / 3;

    margin-left: 5px;
    font-family: $font-primary-bold;
    font-size: 22px;

    &--1 {
      grid-row: 2 / 3;
      margin-top: 17px;
    }

    &--2 {
      grid-row: 4 / 5;
    }

    &--3 {
      grid-row: 6 / 7;
      margin-top: 6px;
    }
  }

  &__description {
    grid-column: 2 / 3;
    position: relative;

    width: calc(100% - 22px);
    margin-left: 5px;
    margin-top: 6px;
    font-size: 19px;
    line-height: 1.4;
    color: #a6a6a6;

    &::after {
      @include pseudo-element;
      left: -35px;
      top: -8.5px;
      height: 100%;
      border-left: 1.5px dashed #444;
    }

    &:last-child {
      &::after {
        border-left: none;
      }
    }

    &--1 {
      grid-row: 3 / 4;
      padding-bottom: 35px;
    }

    &--2 {
      grid-row: 5 / 6;
      padding-bottom: 35px;
    }

    &--3 {
      grid-row: 7 / 8;
      padding-bottom: 10px;
    }
  }

  &__correction {
    // Without this correction the first n is flickering
    display: inline-block;
    transform: scaleX(0);
    margin-right: -13px;
    visibility: hidden;
  }

  &__paragraph {
    display: block;
    margin-top: 7.5px;

    &--2 {
      display: block;
      margin-top: 20px;
    }
  }
}
</style>
