<template>
  <div class="register-modal tabstart" tabindex="0">
    <app-input-complex
      :autocompleteInput="'email'"
      :placeholder="$t('email')"
      :text="email"
      @inputEvent="checkFormatOfEmail"
      @keyupEvent="registerOnEnterKey($event)"
      @blurEvent="checkEmail"
    ></app-input-complex>
    <div
      v-if="hint.length > 0"
      class="register-modal__hint"
      :class="{
        'register-modal__hint--is-shown':
          hintIsShown && !$store.state.registrationSuccessful
      }"
    >
      <span v-html="hint"></span>
      <span
        @click="changeEmail"
        @mousedown="changeEmailMouseDown()"
        @mouseup="changeEmailMouseUp()"
        class="register-modal__hint-email"
        >{{ suggestedEmail }}</span
      >
      <span v-if="suggestedEmail.length > 0">?</span>
    </div>
    <app-input-complex
      :icon="'lock'"
      :id="'register-modal__password'"
      :type="inputType"
      :autocompleteInput="'new-password'"
      :placeholder="$t('password')"
      :placeholderIsSmall="placeholderIsSmall"
      :eyeIsShown="true"
      @focusEvent="setFocusOnPassword"
      @inputEvent="evaluatePassword"
      @keyupEvent="registerOnEnterKey($event)"
      @blurEvent="hideSecondHint()"
      @mousedownEvent="
        placeholderIsSmall = true;
        secondHintIsShownEye = true;
      "
      @mouseupEvent="
        placeholderIsSmall = false;
        secondHintIsShownEye = false;
      "
    ></app-input-complex>
    <div
      v-if="secondHintIsShown > 0"
      class="register-modal__hint register-modal__hint--2"
      :class="{
        'register-modal__hint--is-shown':
          (secondHintIsShown > 1 || secondHintIsShownEye) &&
          !$store.state.registrationSuccessful
      }"
    >
      {{ $t("form_signup").password_strength[0] }}
      <div class="register-modal__password-strength">
        <div class="register-modal__password-strength-bar"></div>
      </div>
      <span class="register-modal__password-strength-word">{{
        passwordStrengthEvaluation
      }}</span>
    </div>
    <app-button
      @click="register()"
      size="big"
      :text="$t('button_signup')"
      class="register-modal__button"
    ></app-button>
    <p class="register-modal__legal-text">
      {{ $t("form_signup").legal_text[0] }}
      <app-link-button
        type="button"
        onclick="this.blur()"
        @click="openPage('terms')"
        class="register-modal__legal-link"
        :text="$t('form_signup').legal_text[1]"
      ></app-link-button>
      {{ $t("form_signup").legal_text[2] }}
      <app-link-button
        type="button"
        onclick="this.blur()"
        @click="openPage('data-protection')"
        class="register-modal__legal-link"
        :text="$t('form_signup').legal_text[3]"
      ></app-link-button>
    </p>
    <app-footer-link
      :text="$t('form_signup').footer_link"
      @click="
        $store.state.footerLinkIsShown = true;
        changeModal('app-login-modal');
      "
      class="footer-link"
    ></app-footer-link>
    <div
      v-if="$store.state.registrationSuccessful"
      class="register-modal__success"
      :class="{
        'register-modal__instructions-are-shown': instructionsAreShown
      }"
    >
      <h2 class="register-modal__heading">
        {{ $t("form_signup_feedback").registration_successful }}
      </h2>
      <app-lottie
        :lottie="'checkmark'"
        v-if="checkmarkIsShown"
        class="register-modal__checkmark"
      ></app-lottie>
      <p class="register-modal__instructions">
        {{ $t("form_signup_feedback").registration_email_sent }}
      </p>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppInputComplex from "@/components/Molecules/InputComplex.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLinkButton from "@/components/Atoms/LinkButton.vue";
import AppFooterLink from "@/components/Molecules/FooterLink.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppInputComplex,
    AppButton,
    AppButtonXRound,
    AppLinkButton,
    AppFooterLink,
    AppLottie
  },
  data() {
    return {
      hintIsShown: false,
      hint: "",
      secondHintIsShown: 0,
      secondHintIsShownEye: false,
      suggestedEmail: "",
      email: "",
      emailHasWrongFormat: false,
      emailDoesNotExist: false,
      placeholderIsSmall: false, // for password
      inputType: "password",
      passwordStrengthEvaluation: "zu schwach",
      password: "",
      passwordIsTooWeak: true,
      registrationPassword: "",

      instructionsAreShown: false,
      checkmarkIsShown: false
    };
  },
  computed: {
    registrationSuccessful() {
      return this.$store.state.registrationSuccessful;
    }
  },
  methods: {
    registerOnEnterKey(e) {
      if (e.code === "Enter" || e.keyCode === 13) {
        this.checkEmail(this.email);
        this.register();
      }
    },
    register() {
      // alert(this.email);
      // this.checkEmail(this.email);
      this.email = this.email.trim();

      let alertMessage1 = "";
      if (this.email === "") {
        alertMessage1 = this.$t("form_signup_feedback")["no_email"];
      } else if (this.emailHasWrongFormat) {
        alertMessage1 = this.$t("form_signup")["not_valid"];
      } else if (this.emailDoesNotExist) {
        alertMessage1 =
          this.$t("form_signup")["not_existent"][0] +
          " " +
          this.$t("form_signup")["not_existent"][1];
      }

      let alertMessage2 = "";
      if (this.password === "") {
        if (alertMessage1 === "") {
          alertMessage2 = this.$t("form_signup_feedback")["no_password_1"];
        } else {
          alertMessage2 = this.$t("form_signup_feedback")["no_password_2"];
        }
      } else if (this.passwordIsTooWeak) {
        if (alertMessage1 === "") {
          alertMessage2 = this.$t("form_signup_feedback")[
            "too_weak_password_1"
          ];
        } else {
          alertMessage2 = this.$t("form_signup_feedback")[
            "too_weak_password_2"
          ];
        }
      }

      if (
        alertMessage1 === "" &&
        alertMessage2 === "" &&
        this.email === this.password
      ) {
        alertMessage1 = this.$t("form_signup_feedback")[
          "password_equals_email"
        ];
      }

      if (alertMessage1 !== "" || alertMessage2 !== "") {
        this.toggleAlert(alertMessage1 + alertMessage2);
        return;
      }

      this.$store.dispatch("register", {
        email: this.email,
        password: this.registrationPassword
      });
    },
    checkFormatOfEmail(email) {
      this.email = email;
      /* let mail = email.trim();
      mail = mail.toLowerCase();

      if (mail === "") {
        return;
      }

      const emailPlausible = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      let mailIsPlausible = emailPlausible.test(mail);
      if (mailIsPlausible) {
        this.checkEmail(email);
      } */
    },
    openPage(page) {
      let routeData = this.$router.resolve({
        name: page,
        query: { data: "someData" }
      });
      window.open(routeData.href, "_blank");
    },
    checkEmail(email) {
      /* if (this.hint !== "") {
        this.hintIsShown = true;
        return;
      } */

      if (email === "") {
        return;
      }

      this.email = email;
      let vm = this;

      function httpGet(url) {
        fetch(url).then(r =>
          r.json().then(j => {
            // let response = JSON.stringify(j, null, 4);
            // alert("Message" in j);
            // alert(response);
            if ("Message" in j) {
              vm.emailHasWrongFormat = false;
              vm.emailDoesNotExist = false;
              vm.hint =
                vm.$t("form_signup").check_email[0] +
                " <br> " +
                vm.$t("form_signup").check_email[1];
              vm.suggestedEmail = "";
              setTimeout(() => {
                vm.hintIsShown = true;
              }, 0);
              return;
            } else if (j.validFormat === false) {
              vm.emailHasWrongFormat = true;
              vm.emailDoesNotExist = false;
              vm.hint = vm.$t("form_signup").not_valid;
              vm.suggestedEmail = "";
              setTimeout(() => {
                vm.hintIsShown = true;
              }, 0);
              return;
            } else if (
              j.deliverable !== true &&
              j.suggestion === "" &&
              vm.suggestEmail(email) === ""
            ) {
              vm.emailHasWrongFormat = false;
              vm.emailDoesNotExist = true;
              vm.hint =
                vm.$t("form_signup").not_existent[0] +
                " <br> " +
                vm.$t("form_signup").not_existent[1];
              vm.suggestedEmail = "";
              setTimeout(() => {
                vm.hintIsShown = true;
              }, 0);
              return;
            } else if (
              (j.deliverable !== true || j.catchAll) &&
              (j.suggestion !== "" || vm.suggestEmail(email) !== "")
            ) {
              vm.emailHasWrongFormat = false;
              if (j.deliverable !== true) {
                vm.emailDoesNotExist = true;
              } else {
                vm.emailDoesNotExist = false;
              }
              let suggestion;
              if (j.suggestion !== "") {
                suggestion = j.suggestion;
              } else {
                suggestion = vm.suggestEmail(email);
              }
              let newUrl =
                "https://api.emailverifyapi.com/v3/lookups/json?key=650B3D3361447EAB&email=" +
                suggestion;
              fetch(newUrl).then(r2 =>
                r2.json().then(k => {
                  if (
                    !("Message" in k) &&
                    (k.validFormat === false || k.deliverable !== true)
                  ) {
                    if (j.catchAll) {
                      vm.hint =
                        vm.$t("form_signup").check_email[0] +
                        " <br> " +
                        vm.$t("form_signup").check_email[1];
                    } else {
                      vm.hint =
                        vm.$t("form_signup").not_existent[0] +
                        " <br> " +
                        vm.$t("form_signup").not_existent[1];
                    }
                    vm.suggestedEmail = "";
                    setTimeout(() => {
                      vm.hintIsShown = true;
                    }, 0);
                    return;
                  } else {
                    if (!j.catchAll) {
                      vm.hint =
                        vm.$t("form_signup").not_existent[0] +
                        " <br> " +
                        vm.$t("form_signup").not_existent[2];
                    } else {
                      vm.hint = vm.$t("form_signup").not_existent[2];
                    }

                    vm.suggestedEmail = suggestion;
                    setTimeout(() => {
                      vm.hintIsShown = true;
                    }, 0);
                    return;
                  }
                })
              );
              return;
            }

            // alert(3);
            vm.emailHasWrongFormat = false;
            vm.emailDoesNotExist = false;
            vm.hintIsShown = false;
            setTimeout(() => {
              vm.hint = "";
              vm.suggestedEmail = "";
            }, 300);
          })
        );
      }

      vm.emailHasWrongFormat = false;
      vm.emailDoesNotExist = false;
      let mail = email.trim();
      // this.suggestEmail(mail);

      /* if (mail === "") {
        vm.hintIsShown = false;
        setTimeout(() => {
          vm.hint = "";
          vm.suggestedEmail = "";
        }, 300);
        return;
      } */

      var url =
        "https://api.emailverifyapi.com/v3/lookups/json?key=650B3D3361447EAB&email=" +
        mail;

      httpGet(url);
    },
    suggestEmail(email) {
      let providers = [
        "gmx",
        "aol",
        "web",
        "msn",
        "kit",
        "mail",
        "zoho",
        "live",
        "gmail",
        "yahoo",
        "icloud",
        "hotmail",
        "outlook",
        "comcast",
        "t-online",
        "passport",
        "googlemail",
        "protonmail"
      ];

      function getHost(email) {
        let mail = email.trim();
        mail = mail.toLowerCase();
        let mailArray = mail.split("@");
        let domain = mailArray[mailArray.length - 1];
        let domainArray = domain.split(".");
        return domainArray[0];
      }
      let host = getHost(email);
      let hostWithoutZero = host.replace(/0/g, "o");

      function compareTruncatedMail(host1, host2) {
        for (let i = 0; i < host1.length; i++) {
          let truncatedMail = host1.slice(0, i) + host1.slice(i + 1);
          if (truncatedMail === host2) {
            return true;
          }
        }
      }
      function checkForSimilarity(host1, host2) {
        for (let i = 0; i < host1.length; i++) {
          let truncatedMail1 = host1.slice(0, i) + host1.slice(i + 1);
          let truncatedMail2 = host2.slice(0, i) + host2.slice(i + 1);
          if (truncatedMail1 === truncatedMail2) {
            return true;
          }
        }
      }
      function compareInterchangedMail(host1, host2) {
        for (let i = 0; i < host1.length - 1; i++) {
          let interchangedMail =
            host1.slice(0, i) +
            host1.slice(i + 1, i + 2) +
            host1.slice(i, i + 1) +
            host1.slice(i + 2);
          if (interchangedMail === host2) {
            return true;
          }
        }
      }

      let suggestion = "";
      for (let i = 0; i < providers.length; i++) {
        if (checkForSimilarity(host, providers[i])) {
          suggestion = providers[i];
        }
        if (compareTruncatedMail(providers[i], host)) {
          suggestion = providers[i];
        }
        if (compareTruncatedMail(host, providers[i])) {
          suggestion = providers[i];
        }
        if (compareInterchangedMail(host, providers[i])) {
          suggestion = providers[i];
        }
        if (hostWithoutZero === providers[i]) {
          suggestion = providers[i];
        }
      }

      function getSuggestedEmail(email, suggestion) {
        if (suggestion === "") {
          return "";
        } else {
          let mailArray = email.split("@");
          let firstPart = mailArray[0];
          let lastPart = mailArray[1].substr(mailArray[1].indexOf("."));
          return firstPart + "@" + suggestion + lastPart;
        }
      }

      return getSuggestedEmail(email, suggestion);
    },
    changeEmail() {
      this.emailDoesNotExist = false;
      this.placeholderIsSmall = true;
      this.email = this.suggestedEmail;
      this.hintIsShown = false;
      setTimeout(() => {
        this.hint = "";
      }, 300);
      let passwordField = document.getElementById("register-modal__password");
      passwordField.focus();
      setTimeout(() => {
        this.placeholderIsSmall = false;
      }, 500);
    },
    changeEmailMouseDown() {
      this.placeholderIsSmall = true;
      this.secondHintIsShownEye = true;
    },
    changeEmailMouseUp() {
      this.secondHintIsShownEye = false;
    },
    setFocusOnPassword() {
      this.secondHintIsShown = 1;
      setTimeout(() => {
        this.secondHintIsShown = 2;
      }, 0);
    },
    hideSecondHint() {
      if (this.secondHintIsShownEye) {
        return;
      }
      this.secondHintIsShown = 1;
      setTimeout(() => {
        this.secondHintIsShown = 0;
      }, 300);
    },
    evaluatePassword(password) {
      this.registrationPassword = password;
      password = this.truncatedPassword(password);

      this.password = password;
      let strength = 0;

      strength = Math.min((1 / 22) * password.length, 0.5);

      let characterTypes = 0;
      let onlyNumbers = true;
      if (/[a-z]/.test(password)) {
        characterTypes++;
        onlyNumbers = false;
      }
      if (/[A-Z]/.test(password)) {
        characterTypes++;
        onlyNumbers = false;
      }
      if (/\d/.test(password)) {
        characterTypes++;
      }
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      if (format.test(password)) {
        characterTypes++;
        onlyNumbers = false;
      }
      strength += 0.1 * (characterTypes - 1);
      if (onlyNumbers) {
        let devaluation = Math.min(password.length * 0.0125, 0.075);
        strength -= devaluation;
      }

      let differentLetters = 0;
      for (let i = 0; i < password.length; i++) {
        let occuredBefore = false;
        for (let j = 0; j < i; j++) {
          if (password.charAt(j) === password.charAt(i)) {
            occuredBefore = true;
          }
        }
        if (!occuredBefore === true) {
          differentLetters++;
        }
      }
      strength += Math.min((1 / 30) * differentLetters, 0.2);

      // Limit password strength
      if (password.length < 7 || differentLetters <= 2) {
        strength = Math.min(strength, 0.075 * password.length);
        strength = Math.min(strength, differentLetters * 0.15);
      } else {
        strength = Math.min(strength, 0.1 * (password.length - 1));
        if (password.length === 7) {
          strength = Math.min(strength, 0.5625);
        }
        if (differentLetters <= 4) {
          strength = Math.min(strength, 0.15 * differentLetters);
        } else if (differentLetters === 5) {
          strength = Math.min(strength, 0.9);
        }
      }

      strength = Math.max(strength, 0);

      let color;
      if (strength <= 0.4) {
        this.passwordIsTooWeak = true;
        this.passwordStrengthEvaluation = this.$t(
          "form_signup"
        ).password_strength[1];
        color = "red";
      } else if (strength <= 0.7) {
        this.passwordIsTooWeak = false;
        this.passwordStrengthEvaluation = this.$t(
          "form_signup"
        ).password_strength[2];
        color = "gold";
      } else if (strength < 0.99) {
        this.passwordIsTooWeak = false;
        this.passwordStrengthEvaluation = this.$t(
          "form_signup"
        ).password_strength[3];
        color = "green";
      } else {
        this.passwordIsTooWeak = false;
        this.passwordStrengthEvaluation = this.$t(
          "form_signup"
        ).password_strength[4];
        color = "green";
      }

      let html = document.getElementsByTagName("html")[0];
      html.style.setProperty("--password-strength-bar-scale-x", strength);
      html.style.setProperty("--password-strength-bar-color", color);
    },
    truncatedPassword(password) {
      // alert(password.slice(0, 1));
      let commonPasswords = [
        "01234567890",
        "012345678910",
        "000000000000",
        "111111111111",
        "222222222222",
        "333333333333",
        "444444444444",
        "555555555555",
        "666666666666",
        "777777777777",
        "888888888888",
        "999999999999",
        "100000000000",
        "200000000000",
        "300000000000",
        "400000000000",
        "500000000000",
        "600000000000",
        "700000000000",
        "800000000000",
        "900000000000",
        "123123123123",
        "121212121212",
        "696969696969",
        "123321",
        "1234561234567890",
        "123456012345678910",
        "11223344556677889900",
        "01012011",
        "1926",
        "1927",
        "1928",
        "1929",
        "1930",
        "1931",
        "1932",
        "1933",
        "1934",
        "1935",
        "1936",
        "1937",
        "1938",
        "1939",
        "1940",
        "1941",
        "1942",
        "1943",
        "1944",
        "1945",
        "1946",
        "1947",
        "1948",
        "1949",
        "1950",
        "1951",
        "1952",
        "1953",
        "1954",
        "1955",
        "1956",
        "1957",
        "1958",
        "1959",
        "1960",
        "1961",
        "1962",
        "1963",
        "1964",
        "1965",
        "1966",
        "1967",
        "1968",
        "1969",
        "1970",
        "1971",
        "1972",
        "1973",
        "1974",
        "1975",
        "1976",
        "1977",
        "1978",
        "1979",
        "1980",
        "1981",
        "1982",
        "1983",
        "1984",
        "1985",
        "1986",
        "1987",
        "1988",
        "1989",
        "1990",
        "1991",
        "1992",
        "1993",
        "1994",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "123abcdefghijklmnopqrstuvwxyz",
        "1qaz2wsx3edc4rfv5tgb6yhn7ujm8ik9ol0p",
        "123qwertyuiop",
        "123asdfghjkl",
        "123zxcvbnm",
        "123qwertzuiop",
        "123yxcvbnm",
        "123456qwertyuiop",
        "123456asdfghjkl",
        "123456zxcvbnm",
        "123456qwertzuiop",
        "123456yxcvbnm",
        "qweqweqweqwe",
        "asdasdasdasd",
        "zxczxczxczxc",
        "yxcyxcyxcyxc",
        "qwe1234567890",
        "qwe012345678910",
        "qwerty1234567890",
        "qwerty012345678910",
        "qwertz1234567890",
        "qwertz012345678910",
        "test1234567890",
        "test012345678910",
        "password1234567890",
        "password012345678910",
        "passwort1234567890",
        "passwort012345678910",
        "a1234567890",
        "ab1234567890",
        "abc1234567890",
        "abcd1234567890",
        "abcde1234567890",
        "abcdef1234567890",
        "aaaaaa123456.",
        "qwer1234567890",
        "qwer012345678910",
        "asdf1234567890",
        "asdf012345678910",
        "zxcv1234567890",
        "zxcv012345678910",
        "yxcv1234567890",
        "yxcv012345678910",
        "aaaaaaaaaaaa",
        "bbbbbbbbbbbb",
        "cccccccccccc",
        "dddddddddddd",
        "eeeeeeeeeeee",
        "ffffffffffff",
        "gggggggggggg",
        "hhhhhhhhhhhh",
        "iiiiiiiiiiii",
        "jjjjjjjjjjjj",
        "kkkkkkkkkkkk",
        "llllllllllll",
        "mmmmmmmmmmmm",
        "nnnnnnnnnnnn",
        "oooooooooooo",
        "pppppppppppp",
        "qqqqqqqqqqqq",
        "rrrrrrrrrrrr",
        "ssssssssssss",
        "tttttttttttt",
        "uuuuuuuuuuuu",
        "vvvvvvvvvvvv",
        "wwwwwwwwwwww",
        "xxxxxxxxxxxx",
        "yyyyyyyyyyyy",
        "zzzzzzzzzzzz",
        "zinch",
        "g_czechout",
        "iloveyou",
        "dubsmash",
        "princess",
        "sunshine",
        "bvttest123",
        "ashley",
        "monkey",
        "livetest",
        "soccer",
        "charlie",
        "family",
        "michael",
        "football",
        "baseball",
        "q1w2e3r4t5y6u7i8o9p0",
        "1q2w3e4r5t6y7u8i9o0p",
        "q1w2e3r4t5z6u7i8o9p0",
        "1q2w3e4r5t6z7u8i9o0p",
        "nicole",
        "jessica",
        "purple",
        "shadow",
        "hannah",
        "chocolate",
        "michelle",
        "maggie",
        "hello",
        "jordan",
        "tigger",
        "superman",
        "summer",
        "fitness",
        "bailey",
        "fuckyou",
        "buster",
        "butterfly",
        "dragon",
        "jennifer",
        "amanda",
        "justin",
        "cookie",
        "basketball",
        "shopping",
        "pepper",
        "joshua",
        "hunter",
        "ginger",
        "matthew",
        "taylor",
        "samantha",
        "whatever",
        "andrew",
        "thomas",
        "jasmine",
        "animoto",
        "madison",
        "flower",
        "maria",
        "babygirl",
        "lovely",
        "sophie",
        "chegg1234567890",
        "computer",
        "anthony",
        "peanut",
        "bubbles",
        "pakistan",
        "liverpool",
        "elizabeth",
        "harley",
        "chelsea",
        "familia",
        "yellow",
        "william",
        "george",
        "loveme",
        "letmein",
        "oliver",
        "batman",
        "cheese",
        "banana",
        "testing",
        "secret",
        "angel",
        "friends",
        "jackson",
        "softball",
        "chicken",
        "lauren",
        "welcome",
        "robert",
        "orange",
        "testing1234567890",
        "testing012345678910",
        "pokemon",
        "melissa",
        "morgan",
        "qazwsxedcrfvtgbyhnujmikolp",
        "qaywsxedcrfvtgbzhnujmikolp",
        "diamond",
        "brandon",
        "jesus",
        "mickey",
        "olivia",
        "changeme",
        "danielle",
        "victoria",
        "gabriel",
        "123456abcdef",
        "123456aaaaaa",
        "0.000000000000",
        "hockey",
        "freedom",
        "azerty",
        "snoopy",
        "skinny",
        "myheritage",
        "159753",
        "forever",
        "iloveu",
        "killer",
        "joseph",
        "master",
        "mustang",
        "hellokitty",
        "school",
        "patrick",
        "blink182",
        "tinkerbell",
        "rainbow",
        "nathan",
        "cooper",
        "onedirection",
        "alexander",
        "jordan23",
        "lol1234567890",
        "lol012345678910",
        "jasper",
        "junior",
        "benjamin",
        "jonathan",
        "passw0rd",
        "passw0rt",
        "samsung",
        "love1234567890",
        "love012345678910",
        "nothing",
        "secret",
        "admin1234567890",
        "admin012345678910",
        "mynoob",
        "18atcskd2w",
        "3rjs1la7qe",
        "google",
        "login",
        "target1234567890",
        "target012345678910",
        "1g2w3e4r",
        "zag12wsx",
        "tinkle",
        "gwerty",
        "adv24",
        "12qwaszx",
        "parola",
        "golfer",
        "cjmasterinf",
        "diosefiel",
        "dpbk1234567890",
        "dpbk012345678910",
        "tudelft",
        "u38fa39",
        "290966",
        "apple",
        "user1234567890",
        "user012345678910",
        "1342",
        "12413",
        "adv23",
        "backend",
        "hallo1234567890",
        "hallo012345678910",
        "ficken",
        "schalke04",
        "59trick",
        "30media",
        "24crow",
        "19weed",
        "59mile",
        "10pace",
        "66bob",
        "naruto",
        "dennis",
        "schatz",
        "arschloch",
        "starwars",
        "huhbbhzu78",
        "internet",
        "fussball",
        "andreas",
        "sandra",
        "sommer",
        "florian",
        "berlin",
        "wasser",
        "christian",
        "martin",
        "sebastian",
        "werder",
        "mercedes",
        "ichliebedich",
        "hamburg",
        "marcel",
        "porsche1",
        "geheim",
        "hahahahahaha",
        "vanessa",
        "handball",
        "anton",
        "sultan",
        "gregor",
        "stefan",
        "casanova",
        "stonecold",
        "digital1",
        "merlin",
        "sputnik",
        "dortmund",
        "dinosaurier",
        "deejay",
        "dolphin1",
        "dolphins",
        "matrix",
        "sexyboy",
        "sexygirl",
        "vampires",
        "sabrina",
        "bayern",
        "insanity",
        "smokie",
        "subzero",
        "caprice",
        "strength",
        "testpass",
        "temppass",
        "medicine",
        "bluefish",
        "manman",
        "logitech",
        "india",
        "fountain",
        "illinois",
        "fuckme1",
        "eminem",
        "bamboo",
        "icehouse",
        "magnet",
        "highheel",
        "bingo1",
        "joel",
        "daniels",
        "sentnece",
        "canyon",
        "bacchus",
        "electra",
        "eddie1",
        "letter",
        "tripper",
        "pippo",
        "shanna",
        "jacobs",
        "clowns",
        "sascha",
        "mathematics",
        "maths",
        "mathematik",
        "number",
        "nummer",
        "zahl",
        "integral",
        "derivative",
        "ableitung",
        "sonnenschein",
        "corvette",
        "trustno1",
        "maverick",
        "steelers",
        "hardcore",
        "midnight",
        "bigdaddy",
        "cocacola",
        "marlboro",
        "jordan23",
        "scorpion",
        "slipknot",
        "redskins",
        "butthead",
        "nicholas",
        "elephant",
        "mountain",
        "metallic",
        "shithead",
        "creative",
        "rush2112",
        "bullshit",
        "garfield",
        "godzilla",
        "airborne",
        "lifehack",
        "brooklyn",
        "platinum",
        "darkness",
        "snowball",
        "redwings",
        "nintendo",
        "guinness",
        "november",
        "minecraft",
        "lasvegas"
      ];

      let truncatedBeginning = "";
      let restOfPassword = password;

      function comparePasswords(pw) {
        if (pw.length <= 3) {
          truncatedBeginning = truncatedBeginning + pw;
          restOfPassword = "";
          return;
        }

        let longestEqualString = 3;
        function iterateThroughPw(n, m) {
          function reverseString(str) {
            if (str === "") return "";
            else return reverseString(str.substr(1)) + str.charAt(0);
          }

          let isEqual = false;
          for (let i = 0; i < commonPasswords[m].length - n + 1; i++) {
            if (
              pw.slice(0, n).toLowerCase() ===
                commonPasswords[m].slice(i, i + n) ||
              pw.slice(0, n).toLowerCase() ===
                reverseString(commonPasswords[m].slice(i, i + n))
            ) {
              isEqual = true;
              break;
            }
          }
          if (isEqual) {
            if (n > longestEqualString) {
              longestEqualString = n;
            }
            iterateThroughPw(n + 1, m);
          } else {
            /* if (n === 4 && m < commonPasswords.length - 1) {
              iterateThroughPw(4, m + 1);
            } else if (n === 4) {
              truncatedBeginning = truncatedBeginning + pw.slice(0, 1);
              restOfPassword = restOfPassword.slice(1);
            } else {
              truncatedBeginning = truncatedBeginning + pw.slice(0, 3);
              restOfPassword = restOfPassword.slice(n - 1);
            } */

            /* if (n > longestEqualString) {
              longestEqualString = n;
            } */
            if (m < commonPasswords.length - 1) {
              iterateThroughPw(4, m + 1);
            } else {
              // alert(longestEqualString);
              if (longestEqualString === 3) {
                truncatedBeginning = truncatedBeginning + pw.slice(0, 1);
                restOfPassword = restOfPassword.slice(1);
              } else {
                truncatedBeginning = truncatedBeginning + pw.slice(0, 3);
                restOfPassword = restOfPassword.slice(longestEqualString);
              }
            }
          }

          /* else {
            iterateThroughPw(n + 1, m);
          } */

          /* else {
            iterateThroughPw(n + 1, m);
          } */
        }
        iterateThroughPw(4, 0);
        if (restOfPassword !== "") {
          // alert(restOfPassword);
          comparePasswords(restOfPassword);
        }
      }

      comparePasswords(restOfPassword);
      // alert(truncatedBeginning);
      return truncatedBeginning;
    }
  },
  watch: {
    registrationSuccessful() {
      setTimeout(() => {
        this.$store.state.modalMayBeClosed = false;
        this.instructionsAreShown = true;
      }, 15);
      setTimeout(() => {
        this.checkmarkIsShown = true;
      }, 15);
      setTimeout(() => {
        this.$store.state.modalMayBeClosed = true;
      }, 1400);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.register-modal__success {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  border-radius: 1rem;
  background-color: $color-black;
  opacity: 0;

  // transition: opacity 0.4s;

  overflow: hidden;
}

.register-modal__heading {
  margin-top: 3.3rem;
  text-align: center;
  font-size: 3.2rem;
  line-height: 1;
  color: $color-white;

  opacity: 0;
  transform: translateY(1.5rem);
}

.register-modal__checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 33.33333%;
  transform: translate(-50%, calc(-50% - 1.05rem));
}

.register-modal__instructions {
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0.75rem 1.75rem;
  border-top: none;
  border-radius: 0.95rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 2rem;
  color: $color-white;

  background-color: #008000;
  transform: translateY(100%);
  transition: transform 0.5s 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.35);
}

.register-modal__instructions-are-shown {
  opacity: 1;
}

.register-modal__instructions-are-shown * {
  opacity: 1;
  transform: none;
}

.register-modal__instructions-are-shown .register-modal__checkmark {
  transform: translate(-50%, calc(-50% - 1.05rem));
}

.register-modal {
  width: 46rem;
  padding: 5.5rem 5.5rem 0;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
    padding: 7.5rem 3rem 0;
  }

  &:focus {
    outline: none;
  }

  &__hint {
    position: absolute;
    left: 100%;
    top: 5.5rem;
    width: max-content;
    max-width: calc(50vw - 23rem);
    padding: 0.6rem 1.5rem;
    border-radius: 0.2rem;
    font-size: 1.9rem;
    color: $color-white;
    background-color: $color-primary-dark;
    transform: translateX(1rem);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;

    @include respond(tab-port) {
      display: none;
    }

    &::after {
      @include pseudo-element;
      left: -1.1rem;
      top: 0;
      width: 1.2rem;
      height: 1.2rem;

      background: linear-gradient(
        45deg,
        transparent 0,
        transparent 50%,
        $color-primary-dark 50%,
        $color-primary-dark 100%
      );
    }

    &--is-shown {
      transform: none;
      opacity: 1;
    }

    &--2 {
      top: 13.25rem;
      width: 23.4rem;
      height: 7.2rem;
      background: linear-gradient(
        $color-primary-dark 0,
        $color-primary-dark 3.6rem,
        $color-black 3.6rem,
        $color-black 100%
      ); // was: #101010
      border-bottom-left-radius: 0;

      &::before {
        @include pseudo-element;
        left: 0;
        top: 3.6rem;
        width: 1px;
        height: 3.6rem;
        background-color: #303030;
      }
    }
  }

  &__hint-email {
    text-decoration: underline;
    text-decoration-color: #eee;
    cursor: pointer;
  }

  &__password-strength-word {
    position: absolute;
    left: 14.8rem;
    bottom: 1.075rem;
    font-size: 1.6rem;
    line-height: 1;
  }

  &__password-strength {
    position: absolute;
    bottom: 1.6rem;
    z-index: 1;
    width: 12rem;
    height: 0.4rem;
    border-radius: 10rem;
    background-color: #626262;
    overflow: hidden;
  }

  &__password-strength-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: (var(--password-strength-bar-color));
    transform: scaleX(var(--password-strength-bar-scale-x));
    transform-origin: left;
    transition: transform 1s, background-color 0.5s;
  }

  &__input {
    padding: 0 4.5rem;
    width: 100%;
    height: 5rem;
    border: none;
    border-radius: 10rem;
    background-color: #626262;

    &:not(:first-child) {
      margin-top: 2.875rem;
    }
  }

  &__button {
    margin-top: 2.875rem;
    width: 100%;
    height: 5rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    font-size: 2.4rem;
    text-align: center;

    @include respond(phone) {
      margin-top: 5.5rem;
      margin-bottom: 3rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }

  &__legal-text {
    padding-top: calc(2.875rem - 0.32rem);
    font-size: 1.6rem;
    line-height: 1.4;
    color: #999;

    @include respond(phone) {
      font-size: 2rem;
      color: #bbb;
    }
  }

  &__legal-link {
    @include respond(phone) {
      font-size: 2rem;
    }
  }
}
</style>
