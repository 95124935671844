<template>
  <div class="lottie-container">
    <!-- <lottie-animation
      ref="anim"
      :animationData="require('@/assets/videos/lottie.json')"
    /> -->
    <lottie
      ref="anim"
      :lottie="lottie"
      :timeout="timeout"
      :loop="loop"
      :speed="speed"
      :playedframes="playedframes"
      :playFromFrame="playFromFrame"
      :play="play"
      :frame="frame"
      @frameReached="$emit('frameReached')"
    >
    </lottie>
  </div>
</template>

<script>
// import LottieAnimation from "lottie-web-vue"; // import lottie-web-vue
// import lottie from "lottie-web";
import lottie from "@/components/Views/Lottie.vue";

export default {
  components: {
    // LottieAnimation
    lottie
  },
  props: {
    lottie: String,
    timeout: {
      type: Number,
      default: 0
    },
    loop: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 1
    },
    playedframes: {
      type: Array,
      default: () => [0, 0, 0] // first frame, lastframe, delay
    },
    playFromFrame: {
      type: Number,
      default: 0
    },
    frame: {
      type: Number,
      default: 0
    },
    play: {
      type: Boolean,
      default: true
    }
  }
};
</script>
