<template>
  <div
    class="cloze"
    :class="{
      'cloze__is-dialog': content.subtype === 'dialog',
      'cloze__transition-is-suspended': clozeTransitionIsSuspended
    }"
  >
    <h2 class="cloze__heading">
      {{ t(content.heading) }}
    </h2>
    <p v-show="content.introduction !== undefined" class="cloze__introduction">
      {{ t(content.introduction) }}
    </p>
    <div class="cloze__text">
      <p
        v-for="(paragraph, paragraphNumber) in answers"
        :key="paragraph"
        v-show="
          paragraphNumber <= recentParagraph &&
            firstParagraphIsShown &&
            recentParagraphIsShown
        "
        :ref="'cloze__paragraph--' + paragraphNumber"
        class="cloze__paragraph"
        :class="{
          'cloze__paragraph--1': answers[paragraphNumber][0].person === 1,
          'cloze__paragraph--2': answers[paragraphNumber][0].person === 2,
          'cloze__paragraph--blue-color': answers[paragraphNumber][0].blueColor,
          'cloze__paragraph--blue-border':
            answers[paragraphNumber][0].blueBorder
        }"
        style="white-space: pre-line;"
      >
        <img
          :src="
            require('@/assets/Internal/Course/Cloze/avatar' +
              getAvatar(paragraphNumber) +
              '.svg')
          "
          v-show="content.subtype === 'dialog'"
          alt="An avatar"
          class="cloze__avatar"
        />
        <!-- <app-lottie
          v-show="content.subtype === 'dialog'"
          :lottie="'avatar_man_' + getAvatar(paragraphNumber)"
          :playedframes="[23, 50, 0]"
          :loop="true"
          :speed="0.75"
          :play="avatarIsAnimated && animationIsPlayed(paragraphNumber)"
          class="test_avatar1 cloze__avatar"
        ></app-lottie> -->
        <template
          v-for="(gapGroup, gapGroupNumber) in paragraph"
          :key="gapGroup"
        >
          <template
            v-for="(sentence, index) in paragraph[gapGroupNumber].text"
            :key="sentence"
            ref="cloze__sentence"
          >
            <span
              v-if="!Array.isArray(sentence)"
              v-html="t(sentence)"
              :class="{
                'cloze__paragraph--list-style':
                  answers[paragraphNumber][gapGroupNumber].listStyle
              }"
            ></span>

            <template v-if="Array.isArray(sentence)">
              <template v-for="part in sentence" :key="part">
                <span v-if="!Array.isArray(part)" v-html="t(part)"></span>
                <app-stackrel
                  v-if="Array.isArray(part)"
                  :top="part[0]"
                  :middle="part[1]"
                  :bottom="part[2]"
                  :active="
                    recentParagraph === paragraphNumber &&
                      recentGapGroup === gapGroupNumber &&
                      recentGap === part[3]
                  "
                  :shown="
                    (recentParagraph === paragraphNumber &&
                      recentGapGroup > gapGroupNumber) ||
                      recentParagraph > paragraphNumber
                  "
                ></app-stackrel>
              </template>
            </template>
            <div
              v-if="index !== paragraph[gapGroupNumber].correctAnswers.length"
              :style="
                'font-size:' +
                  returnFontSize(
                    paragraph[gapGroupNumber].choiceOfAnswers.length
                  ) +
                  'rem'
              "
              class="cloze__gap"
              :class="{
                'cloze__gap--active':
                  !gapHasBeenProcessed(
                    paragraphNumber,
                    gapGroupNumber,
                    index
                  ) &&
                  paragraphNumber === recentParagraph &&
                  gapGroupNumber === recentGapGroup &&
                  index === recentGap,
                'cloze__gap--correct':
                  correctAnswerIsIndicated > -1 &&
                  paragraphNumber === recentParagraph &&
                  gapGroupNumber === recentGapGroup &&
                  index === recentGap &&
                  (recentParagraphFirst !== recentParagraph ||
                    recentGapGroupFirst !== recentGapGroup ||
                    recentGapFirst !== recentGap),
                'cloze__gap--processed': gapHasBeenProcessed(
                  paragraphNumber,
                  gapGroupNumber,
                  index
                )
              }"
            >
              <span
                v-for="(choice, choiceNumber) in gapGroup.choiceOfAnswers"
                :key="choice"
                v-show="
                  choiceNumber >=
                    lowerBound[paragraphNumber][gapGroupNumber][index] &&
                    choiceNumber <=
                      upperBound[paragraphNumber][gapGroupNumber][index]
                "
                class="test"
                :class="{
                  'test--equalize-bottom-line':
                    gapGroup.clozeWidthEqualized && gapGroup.answerWidthFixed
                }"
                ><span class="test2">{{ t(choice) }}</span>
              </span>
              <span
                v-for="(choice, choiceIndex) in gapGroup.choiceOfAnswers"
                :key="choice"
                v-show="
                  solutionIsShown(
                    paragraphNumber,
                    gapGroupNumber,
                    index,
                    choiceIndex
                  )
                "
                :ref="'cloze__solution--' + index + '--' + choiceIndex"
                class="cloze__solution"
                :class="{
                  'cloze__solution--not-equalized': !gapGroup.clozeWidthEqualized,
                  'cloze__solution--shown': !gapGroup.answerWidthFixed
                }"
              >
                {{ t(choice) }}
              </span>
            </div>
          </template>
          <template
            v-if="
              'images' in content &&
                typeof content.images[paragraphNumber] === 'object' &&
                'image' in content.images[paragraphNumber]
            "
          >
            <app-image
              :svg="content.images[paragraphNumber].image"
              :imageData="content.images[paragraphNumber].imageData"
              :arrows="content.images[paragraphNumber].arrows"
              :imageText="content.images[paragraphNumber].imageText"
            ></app-image>
          </template>
        </template>
      </p>
    </div>
    <app-message
      :data="messageData"
      :isShown="messageIsShown"
      :transitionIsSuspended="transitionIsSuspended"
      @messageClosed="closeFeedback()"
      class="cloze__message"
      :class="{ 'cloze__message--is-shown': messageIsShown }"
    ></app-message>

    <div class="cloze__answer-button-positioning-container">
      <div
        v-for="(paragraph, numberOfParagraph) in answers"
        :key="paragraph"
        v-show="
          (recentParagraph === numberOfParagraph ||
            (recentParagraph === content.answers.length &&
              numberOfParagraph === content.answers.length - 1)) &&
            answerButtonsAreShown
        "
        class="cloze__answer-button-outer-container"
        :class="{
          'cloze__answer-button-outer-container--no-border':
            recentParagraph === content.answers.length
        }"
      >
        <div
          v-for="(gapGroup, numberOfGapGroup) in paragraph"
          :key="gapGroup"
          v-show="
            recentGapGroup === numberOfGapGroup &&
              recentParagraph < content.answers.length
          "
          class="cloze__answer-button-container"
        >
          <app-button
            v-for="(answer, numberOfAnswer) in gapGroup.choiceOfAnswers"
            :key="answer"
            :text="t(answer)"
            size="big"
            @click="evaluateAnswer(numberOfAnswer)"
            class="cloze__answer-button"
            :class="{
              'cloze__answer-button--correct':
                correctAnswerIsIndicated === numberOfAnswer,
              'cloze__answer-button--wrong':
                wrongAnswerIsIndicated === numberOfAnswer,
              'cloze__answer-button--deactivated': buttonsAreDeactivated,
              'cloze__answer-button--inactive':
                usedButtons.indexOf(numberOfAnswer) !== -1
            }"
            :style="{
              height:
                gapGroup.buttonHeight !== undefined
                  ? gapGroup.buttonHeight + 'rem'
                  : 'initial'
            }"
          ></app-button>
        </div>
      </div>
    </div>
    <app-button
      @click="setPage('++')"
      size="big"
      :text="$t('course_continue_button')"
      class="cloze__continue-button"
      :class="{
        'cloze__continue-button--is-shown': recentParagraph === answers.length,
        'cloze__continue-button--no-transition': transitionIsSuspended
      }"
    ></app-button>

    <template v-for="(audio1, audioIndex1) in audioArray" :key="audio1">
      <template
        v-for="(audio2, audioIndex2) in audioArray[audioIndex1]"
        :key="audio2"
      >
        <audio
          v-for="audio3 in audioArray[audioIndex1][audioIndex2]"
          :ref="'sound-effect-' + audio3"
          :key="audio3"
        >
          <source
            v-if="audio3 !== undefined"
            :src="
              // 'http://mathopia3.s3-website.eu-central-1.amazonaws.com/' +
              'https://mathopia.com/audio/' + t(audio3) + '.mp3'
            "
            type="audio/mpeg"
            preload="auto"
          />
        </audio>
      </template>
    </template>
    <audio v-if="'ambientNoise' in content" ref="sound-effect-ambient">
      <source
        :src="
          // 'http://mathopia3.s3-website.eu-central-1.amazonaws.com/' +
          'https://mathopia.com/audio/' + t(content.ambientNoise) + '.mp3'
        "
        type="audio/mpeg"
        preload="auto"
      />
    </audio>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppImage from "@/components/Organisms/Image.vue";
import AppMessage from "@/components/Molecules/Message.vue";
// import AppLottie from "@/components/Views/Lottiecontainer.vue";
import AppStackrel from "@/components/Atoms/Mathjax/Stackrel.vue";

export default {
  name: "appCloze",
  props: {
    content: Object,
    isShown: {
      type: Boolean,
      default: false
    },
    exerciseIsReset: {
      type: Boolean,
      default: false
    },
    userDataIsFetched: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppButton,
    AppImage,
    AppMessage,
    AppStackrel
    // AppLottie
  },
  data() {
    return {
      audioBehaviour: "adapt",
      answers: [
        [
          {
            text: ["text1", "text2"],
            choiceOfAnswers: ["$u$", "$\\cos x$"],
            correctAnswers: [[0, 1], [1], [3], [2]],
            clozeWidthEqualized: true,
            answerWidthFixed: true,
            audio: { 1: "moo", 3: "bark" },
            wrongMessages: ["Meldung"],
            assignmentWrong: [{ 1: 0 }]
          }
        ],
        [
          {
            text: ["Text"],
            choiceOfAnswers: ["Menge", "Bla$"],
            correctAnswers: [[1, 2], [3]],
            correctMessages: ["Genau richtig!", "Gut gemacht!"],
            assignmentCorrect: [{ 1: 0, 2: 1 }, { 3: 1 }],
            wrongMessages: ["Nix gut!", "Nö!"],
            assignmentWrong: [{ 0: 0, 3: 1 }, { 1: 1 }],
            reUse: false,
            equalizeGaps: true
          }
        ]
      ],

      courseWasStarted: false,
      firstParagraphIsShown: true,
      recentParagraphIsShown: true,
      recentGap: 0,
      recentGapGroup: 0,
      recentParagraph: 0,
      recentGapFirst: 0,
      recentGapGroupFirst: 0,
      recentParagraphFirst: 0,
      buttonsAreDeactivated: false,
      usedButtons: [],
      answerButtonsAreShown: true,
      lowerBound: [],
      upperBound: [],
      correctAnswerIsIndicated: -1,
      wrongAnswerIsIndicated: -1,
      audioArray: [],
      timeout: [],
      transitionIsSuspended: false,
      clozeTransitionIsSuspended: false,
      avatarIsAnimated: false,
      courseWasRestarted: false,

      // Save cloze data
      givenAnswers: [],
      subtype: "cloze",

      messageText: "",
      message: "",
      type: "message",
      image: "",
      messageData: {},
      messageIsShown: false
    };
  },
  computed: {
    totalGapNumber() {
      let counter = 0;
      for (let i = 0; i <= this.recentParagraph; i++) {
        for (let j = 0; j < this.answers[i].length; j++) {
          for (let k = 0; k < this.answers[i][j].correctAnswers.length; k++) {
            if (this.recentParagraph > i) {
              counter++;
            } else if (this.recentParagraph === i) {
              if (this.recentGapGroup > j) {
                counter++;
              } else if (this.recentGapGroup === j) {
                if (this.recentGap > k) {
                  counter++;
                }
              }
            }
          }
        }
      }
      return counter;
    }
  },
  methods: {
    getAvatar(paragraph) {
      if (this.answers[paragraph][0].person === 1) {
        return this.content.avatarLeft || 1;
      } else {
        return this.content.avatarRight || 2;
      }
    },
    animationIsPlayed(paragraphNumber) {
      let isSamePerson = true;
      let person = this.answers[paragraphNumber][0].person;
      for (let i = paragraphNumber; i <= this.recentParagraph; i++) {
        if (this.answers[i][0].person !== person) {
          isSamePerson = false;
        }
      }
      return isSamePerson;
    },
    solutionIsShown(p, gG, g, c) {
      let clozeEqualized = this.answers[p][gG].clozeWidthEqualized;
      let answerFixed = this.answers[p][gG].answerWidthFixed;
      let gapProcessed = this.gapHasBeenProcessed(p, gG, g);
      let lowerBound = this.lowerBound[p][gG][g];
      let upperBound = this.upperBound[p][gG][g];
      let firstCorrect = this.answers[p][gG].correctAnswers[g][0];

      if (clozeEqualized && answerFixed) {
        return true;
      } else if (clozeEqualized) {
        if (!gapProcessed) {
          return true;
        } else if (c >= lowerBound && c <= upperBound) {
          return true;
        } else {
          return false;
        }
      } else if (answerFixed === true) {
        if (c === firstCorrect) {
          return true;
        } else {
          return false;
        }
      } else {
        if (!gapProcessed && c === firstCorrect) {
          return true;
        } else if (gapProcessed && c >= lowerBound && c <= upperBound) {
          return true;
        } else {
          return false;
        }
      }
    },
    returnFontSize(numberOfWords) {
      return 2.6 / numberOfWords;
    },
    gapHasBeenProcessed(paragraph, gapGroup, gap) {
      if (this.recentParagraphFirst > paragraph) {
        return true;
      } else if (
        this.recentParagraphFirst === paragraph &&
        this.recentGapGroupFirst > gapGroup
      ) {
        return true;
      } else if (
        this.recentParagraphFirst === paragraph &&
        this.recentGapGroupFirst === gapGroup &&
        this.recentGapFirst > gap
      ) {
        return true;
      } else {
        return false;
      }
    },
    evaluateAnswer(index) {
      if (this.buttonsAreDeactivated) {
        return;
      }
      if (
        this.answers[this.recentParagraph][this.recentGapGroup].correctAnswers[
          this.recentGap
        ].indexOf(index) !== -1 // Checks if answer is correct
      ) {
        this.buttonsAreDeactivated = true;
        setTimeout(() => {
          this.answerButtonsAreShown = false;
          this.buttonsAreDeactivated = false;
        }, 850);

        let audioDuration = this.getAudioDuration(500);
        setTimeout(() => {
          this.buttonsAreDeactivated = false;
        }, audioDuration + 351); // was 851
        this.$store.dispatch("setExerciseStatus", [this.totalGapNumber, 4]);
        this.lowerBound[this.recentParagraph][this.recentGapGroup][
          this.recentGap
        ] = index;
        this.upperBound[this.recentParagraph][this.recentGapGroup][
          this.recentGap
        ] = index;
        this.handleAudio(audioDuration);
        this.showMessageCorrect(index);
        this.indicateCorrectAnswer(index);

        this.setUsedButtons(index, audioDuration + 350);
        this.increaseGapData(audioDuration + 350);

        this.givenAnswers.push(index);
        this.$store.dispatch("addExerciseData", [
          "givenAnswers",
          this.givenAnswers
        ]);
      } else {
        this.$store.dispatch("setExerciseStatus", [this.totalGapNumber, 2]);
        this.playAudio("wrong");
        this.showMessageWrong(index);
        this.indicateWrongAnswer(index);
      }
    },
    autoSolve() {
      let vm = this;

      function autoSolveCloze(index) {
        vm.lowerBound[vm.recentParagraph][vm.recentGapGroup][
          vm.recentGap
        ] = index;
        vm.upperBound[vm.recentParagraph][vm.recentGapGroup][
          vm.recentGap
        ] = index;

        vm.increaseGapDataWithoutDelay();
      }

      if (
        this.content.answers[this.recentParagraph][this.recentGapGroup].text
          .length <= 1
      ) {
        this.increaseGapDataWithoutDelay();
      }
      for (let i = 0; i < vm.givenAnswers.length; i++) {
        autoSolveCloze(vm.givenAnswers[i]);
      }
    },
    getAudioDuration(defaultDuration) {
      let audio = this.audioArray[this.recentParagraph][this.recentGapGroup][
        this.recentGap
      ];
      let audioElement = this.$refs["sound-effect-" + audio];

      if (!this.$store.state.soundIsTurnedOn) {
        return 500;
      } else if (audio !== undefined) {
        return audioElement.duration * 1000 + 150;
      } else if (defaultDuration !== undefined) {
        return defaultDuration;
      } else {
        return null; // was: 0
      }
    },
    handleAudio(time) {
      if (!this.isShown) return;

      let audio = this.audioArray[this.recentParagraph][this.recentGapGroup][
        this.recentGap
      ];

      if (
        (this.audioBehaviour === "both" ||
          (this.audioBehaviour === "adapt" && audio === undefined)) &&
        this.answers[this.recentParagraph][this.recentGapGroup].text.length > 1
      ) {
        this.playAudio("correct");
      }
      let delay = null; // was: 0
      if (
        this.audioBehaviour === "both" &&
        this.answers[this.recentParagraph][this.recentGapGroup].text.length > 1
      ) {
        delay = 500;
      }
      setTimeout(() => {
        this.playAudio(audio);
        this.avatarIsAnimated = true;
        setTimeout(() => {
          this.avatarIsAnimated = false;
        }, time);
      }, delay);
    },
    showMessageCorrect(index) {
      if (
        "correctMessages" in
          this.answers[this.recentParagraph][this.recentGapGroup] &&
        "assignmentCorrect" in
          this.answers[this.recentParagraph][this.recentGapGroup] &&
        this.answers[this.recentParagraph][this.recentGapGroup]
          .assignmentCorrect[this.recentGap] !== undefined &&
        index in
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentCorrect[this.recentGap] &&
        this.answers[this.recentParagraph][this.recentGapGroup].correctMessages[
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentCorrect[this.recentGap][index]
        ] !== undefined
      ) {
        let message = this.answers[this.recentParagraph][this.recentGapGroup]
          .correctMessages[
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentCorrect[this.recentGap][index]
        ];
        if (message === this.messageText) {
          return;
        }
        let delay = 1000;
        if (this.messageText !== "") {
          this.messageIsShown = false;
          delay = 1300;
        }
        this.messageText = message;
        setTimeout(() => {
          this.messageData = this.generateMessage(message);
        }, delay - 1000);
        setTimeout(() => {
          this.messageIsShown = true;
        }, 1000);
      } else {
        this.closeFeedback();
      }
    },
    showMessageWrong(index) {
      if (
        "wrongMessages" in
          this.answers[this.recentParagraph][this.recentGapGroup] &&
        "assignmentWrong" in
          this.answers[this.recentParagraph][this.recentGapGroup] &&
        this.answers[this.recentParagraph][this.recentGapGroup].assignmentWrong[
          this.recentGap
        ] !== undefined &&
        index in
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentWrong[this.recentGap] &&
        this.answers[this.recentParagraph][this.recentGapGroup].wrongMessages[
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentWrong[this.recentGap][index]
        ] !== undefined
      ) {
        let message = this.answers[this.recentParagraph][this.recentGapGroup]
          .wrongMessages[
          this.answers[this.recentParagraph][this.recentGapGroup]
            .assignmentWrong[this.recentGap][index]
        ];
        if (message === this.messageText) {
          return;
        }
        let delay = 500;
        if (this.messageText !== "") {
          this.messageIsShown = false;
          delay = 800;
        }
        this.messageText = message;
        setTimeout(() => {
          this.messageData = this.generateMessage(message, "error");
        }, delay - 500);
        setTimeout(() => {
          this.messageIsShown = true;
        }, delay);
      } else {
        this.closeFeedback();
      }
    },
    closeFeedback() {
      this.messageIsShown = false;
      setTimeout(() => {
        this.messageText = "";
      }, 301);
    },

    indicateCorrectAnswer(index) {
      this.correctAnswerIsIndicated = index;
      setTimeout(() => {
        this.correctAnswerIsIndicated = -1;
      }, 850);
    },
    indicateWrongAnswer(index) {
      this.wrongAnswerIsIndicated = index;
      setTimeout(() => {
        this.wrongAnswerIsIndicated = -1;
      }, 850);
    },
    setUsedButtons(index, delay) {
      setTimeout(() => {
        if (
          index === -1 ||
          ("reUse" in this.answers[this.recentParagraph][this.recentGapGroup] &&
            this.answers[this.recentParagraph][this.recentGapGroup].reUse ===
              true)
        ) {
          this.usedButtons = [];
        } else {
          this.usedButtons.push(index);
        }
      }, delay);
    },
    increaseGapData(delay) {
      if (
        this.content.subtype !== "dialog" &&
        "correctAnswers" in
          this.answers[this.recentParagraph][this.recentGapGroup] &&
        this.answers[this.recentParagraph][this.recentGapGroup].correctAnswers
          .length === 0
      ) {
        delay = null;
      }

      if (
        this.recentGap <
        this.answers[this.recentParagraph][this.recentGapGroup].correctAnswers
          .length -
          1
      ) {
        this.recentGapFirst++;
        this.setTimeout(
          () => {
            this.recentGap++;
          },
          delay,
          "cloze3"
        );
      } else if (
        this.recentGapGroup <
        this.answers[this.recentParagraph].length - 1
      ) {
        this.recentGapGroupFirst++;
        this.recentGapFirst = 0;
        this.setTimeout(
          () => {
            this.recentGapGroup++;
            this.recentGap = 0;
            this.usedButtons = [];
          },
          delay,
          "cloze4"
        );
      } else {
        this.recentParagraphFirst++;
        this.recentGapGroupFirst = 0;
        this.recentGapFirst = 0;
        this.setTimeout(
          () => {
            this.recentParagraph++;
            this.recentGapGroup = 0;
            this.recentGap = 0;
            this.usedButtons = [];
          },
          delay,
          "cloze5"
        );
      }
      this.setTimeout(
        () => {
          if (
            this.content.answers[this.recentParagraph][this.recentGapGroup].text
              .length <= 1
          ) {
            let delay2 = 550;
            if (this.content.subtype !== "dialog") {
              delay2 = null;
            }
            this.scrollToNextParagraph();
            this.setTimeout(
              () => {
                let audioDuration = this.getAudioDuration(); //
                this.handleAudio(audioDuration);
                this.increaseGapData(audioDuration + 350); // was: 0
              },
              delay2,
              "cloze6"
            );
          }

          if (
            this.recentParagraph >= this.content.answers.length - 1 ||
            this.content.answers[this.recentParagraph][this.recentGapGroup].text
              .length > 1
          ) {
            this.scrollToNextParagraph();
          }
        },
        delay,
        "cloze7"
      );
    },
    increaseGapDataWithoutDelay() {
      if (
        this.recentGap <
        this.answers[this.recentParagraph][this.recentGapGroup].correctAnswers
          .length -
          1
      ) {
        this.recentGapFirst++;
        this.recentGap++;
      } else if (
        this.recentGapGroup <
        this.answers[this.recentParagraph].length - 1
      ) {
        this.recentGapGroupFirst++;
        this.recentGapFirst = 0;

        this.recentGapGroup++;
        this.recentGap = 0;
        this.usedButtons = [];
      } else {
        this.recentParagraphFirst++;
        this.recentGapGroupFirst = 0;
        this.recentGapFirst = 0;

        this.recentParagraph++;
        this.recentGapGroup = 0;
        this.recentGap = 0;
        this.usedButtons = [];
      }
      if (
        this.content.answers[this.recentParagraph][this.recentGapGroup].text
          .length <= 1
      ) {
        this.increaseGapDataWithoutDelay();
      }
    },
    scrollToNextParagraph() {
      if (this.givenAnswers[0] === undefined) {
        return;
      }

      let paragraphNumber = this.recentParagraph;
      if (this.recentParagraph >= this.answers.length) {
        paragraphNumber = this.answers.length - 1;
      }

      let clozeParagraph = this.$refs["cloze__paragraph--" + paragraphNumber];
      this.scrollElement(clozeParagraph, this.remToPx(26.5));
    },
    initializeExercise() {
      // Create lower and upper bound
      for (let i = 0; i < this.answers.length; i++) {
        this.lowerBound[i] = [];
        this.upperBound[i] = [];
        for (let j = 0; j < this.answers[i].length; j++) {
          this.lowerBound[i][j] = [];
          this.upperBound[i][j] = [];
          for (let k = 0; k < this.answers[i][j].correctAnswers.length; k++) {
            if (this.answers[i][j].clozeWidthEqualized) {
              this.lowerBound[i][j][k] = 0;
              this.upperBound[i][j][k] =
                this.answers[i][j].correctAnswers.length - 1;
            } else {
              this.lowerBound[i][j][k] = this.answers[i][j].correctAnswers[
                k
              ][0];
              this.upperBound[i][j][k] = this.answers[i][j].correctAnswers[
                k
              ][0];
            }
          }
        }
      }

      // Create audio array
      for (let i = 0; i < this.answers.length; i++) {
        this.audioArray[i] = [];
        for (let j = 0; j < this.answers[i].length; j++) {
          this.audioArray[i][j] = [];
          for (
            let k = 0;
            k < this.answers[i][j].correctAnswers.length + 1;
            k++
          ) {
            if ("audio" in this.answers[i][j]) {
              if (k in this.answers[i][j].audio) {
                this.audioArray[i][j][k] = this.answers[i][j].audio[k];
              }
            }
          }
        }
      }
    },
    startExercise() {
      if (this.courseWasStarted) {
        return;
      }
      this.courseWasStarted = true;
      document.querySelector(".app").scrollTo(0, 0);
      if (
        this.subtype === "dialog" &&
        this.content.introduction !== undefined
      ) {
        this.firstParagraphIsShown = false;
      }

      let delayAmbient = 750;
      if (this.content.introduction === undefined) {
        delayAmbient = 250;
      }

      this.setTimeout(
        () => {
          if (!this.courseWasRestarted && this.content.subtype === "dialog") {
            this.playAudio(this.content.ambientNoise);
          }
        },
        delayAmbient,
        "cloze1"
      );

      let delay = 3350; // was 3350
      if (!this.$store.state.soundIsTurnedOn) {
        delay = 1700;
      }
      if (this.content.introduction === undefined) {
        delay = 0;
      }
      // // // delay = 0;
      if (this.givenAnswers.length > 0) {
        delay = 0;
      }

      let vm = this;
      this.setTimeout(
        () => {
          vm.firstParagraphIsShown = true;
          setTimeout(() => {
            this.scrollToNextParagraph();
          }, 0);

          let delay1 = null;
          if (this.content.subtype === "dialog") {
            delay1 = this.$store.state.soundIsTurnedOn ? 1250 : 350;
          }
          if (vm.content.answers[0][0].text.length <= 1) {
            this.setTimeout(() => {
              let audioDuration = vm.getAudioDuration(500); // // // !!!

              if (this.givenAnswers.length === 0) {
                vm.handleAudio(audioDuration);
              }

              if (this.givenAnswers.length === 0) {
                vm.increaseGapData(audioDuration); // 0
              } else {
                this.autoSolve();
              }
            }, delay1);
          } else {
            if (this.givenAnswers.length > 0) {
              this.autoSolve();
            }
          }
        },
        delay,
        "cloze2"
      );
    },
    resetExercise() {
      this.clearAllTimeouts();
      this.$store.dispatch("setExerciseStatus", 0);

      this.courseWasStarted = false;
      (this.firstParagraphIsShown = true),
        (this.recentGap = 0),
        (this.recentGapGroup = 0),
        (this.recentParagraph = 0),
        (this.recentGapFirst = 0),
        (this.recentGapGroupFirst = 0),
        (this.recentParagraphFirst = 0),
        (this.usedButtons = []),
        (this.answerButtonsAreShown = true),
        (this.lowerBound = []),
        (this.upperBound = []),
        (this.correctAnswerIsIndicated = -1),
        (this.wrongAnswerIsIndicated = -1),
        (this.audioArray = []),
        (this.givenAnswers = []),
        (this.messageText = ""),
        (this.message = ""),
        (this.type = "message"),
        (this.image = ""),
        (this.messageData = {}),
        (this.messageIsShown = false);

      this.$store.dispatch("addExerciseData", ["givenAnswers", []]);

      this.initializeExercise();
      this.scrollToTop();
    }
  },
  created() {
    this.transitionIsSuspended = true;
    setTimeout(() => {
      this.transitionIsSuspended = false;
    }, 100);

    this.givenAnswers = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "givenAnswers",
      []
    ]);
    if (this.givenAnswers.length > 0) {
      this.clozeTransitionIsSuspended = true;
      setTimeout(() => {
        this.clozeTransitionIsSuspended = false;
      }, 500);
    }

    this.subtype = this.content.subtype;

    let soundStatus = this.$store.state.soundIsTurnedOn;
    if (this.givenAnswers.length > 0) {
      this.courseWasRestarted = true;
      this.subtype = "cloze";
      this.$store.state.soundIsTurnedOn = false;
    }

    this.audioBehaviour = this.content.audioBehaviour;
    this.answers = this.content.answers;
    this.initializeExercise();

    if (this.isShown) {
      this.startExercise();
    }

    if (this.givenAnswers.length > 0) {
      setTimeout(() => {
        this.$store.state.soundIsTurnedOn = soundStatus;
      }, 100);
    }
  },
  watch: {
    isShown(to) {
      if (to) {
        this.startExercise();
      } else {
        let sounds = document.getElementsByTagName("audio");
        for (let i = 0; i < sounds.length; i++) {
          sounds[i].pause();
        }
      }
    },
    exerciseIsReset(to) {
      if (to) {
        this.resetExercise();
        this.startExercise();
      }
    },
    userDataIsFetched(to) {
      if (to) {
        // alert("Yeah!");
      }
    },
    recentGap() {
      this.answerButtonsAreShown = true;
    },
    recentGapGroup() {
      this.answerButtonsAreShown = true;
    },
    recentParagraph(to) {
      this.answerButtonsAreShown = true;
      if (to >= this.answers.length) {
        this.scrollToNextParagraph();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.test_avatar {
  position: absolute;
  left: -8.95rem;
  top: 0;
  width: 6rem;
}

.test {
  position: relative !important;
  transform: translateY(0.3rem);
  display: none;
  width: 0 !important;
  border-radius: 0.1rem;
  font-size: 2.6rem !important;
  color: #1279b7;
  filter: brightness(1.15);

  @include respond(phone) {
    color: $color-primary-light;
    filter: none;
  }

  &--equalize-bottom-line {
    transform: translateY(0.3rem);
  }
}

.cloze__gap--processed .test {
  display: inline-block;
}

.cloze__gap--correct .test {
  color: $color-white;
}

.test2 {
  display: inline-block;
  height: 100%;
  padding: 0.1rem 0;
}

.cloze__gap--correct .test2 {
  box-shadow: 0 0 0 0.25rem green;
  border-radius: 0.05rem;
  color: $color-white;
  background-color: green !important;
}

.cloze__paragraph--1 .test {
  color: #26a5a9;
}

.cloze__paragraph--2 .test {
  color: #1279b7;
  filter: brightness(1.2);
}

.cloze {
  position: absolute;
  top: 6rem;
  width: 100%;
  min-height: calc(100vh + 50rem);
  // padding-top: max(11rem, min(13.75rem, 10vh));
  padding-top: 5.5rem;
  padding-bottom: 20rem;
  color: $color-white;
  // background-color: $color-black;
  // background-color: #131313;
  background-color: #101010;
  // background-color: $color-black;

  overflow: hidden; // // //

  @include grid-template-columns;
  grid-template-rows: max-content max-content max-content;

  @include respond(phone) {
    position: relative;
    top: 6rem;
    padding-top: 4.6rem;
    padding-bottom: 7.5rem;
    min-height: 100vh;
    background: linear-gradient(
      #000 0,
      #000 calc(100% - 9rem),
      #131313 calc(100% - 9rem),
      #131313 100%
    );
  }

  &.cloze__is-dialog {
    width: 100vw;
  }

  &__heading {
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-weight: 400;

    text-align: center;

    @include respond(tab-land) {
      line-height: 1.2;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      margin-bottom: 3.6rem;
      text-align: center;
      font-size: 4rem;
    }

    .cloze__is-dialog & {
      text-align: center;
    }
  }

  &__introduction {
    grid-column: 3 / 4;
    transform: translateX(9.25rem);
    width: calc(100% - 18.5rem);
    margin-bottom: 4.2rem;
    padding: 0.5rem 1.8rem;
    text-align: center;
    font-size: 2.6rem;
    border-radius: 1rem;
    background-color: #222;
    border: none;

    @include respond(phone) {
      grid-column: 1 / 6;
      transform: none;
      width: 100vw;
      padding: 0.5rem 1.8rem;
      margin-bottom: 5.375rem;
      line-height: 1.4;
    }
  }

  &__text {
    grid-column: 3 / 4;
    font-size: 2.6rem;
    line-height: 1.6;
    height: max-content;

    filter: brightness(1.1);
    white-space: pre-line;

    @include respond(phone) {
      grid-column: 1 / 6;
      margin-top: calc((1.4 - 1.55) * 1.3rem);
      line-height: 1.55;
      border-radius: 0.6rem;

      width: 100vw;
      padding-bottom: 20rem;
    }
  }

  &__is-dialog .cloze__avatar {
    position: absolute;
    top: -0.6rem;
    width: 6.5rem;
    height: 6.5rem;
    margin: 0.6rem 1rem;
  }

  &__paragraph {
    max-width: 84rem;
    border-radius: 0.6rem;

    // max-width: $width-center;
    // text-align: center;

    &--blue-color {
      margin-top: 5rem !important;
      margin-bottom: 1rem !important;
      padding-left: 0;
      font-family: $font-primary-bold; // // //
      // color: $color-primary-light; // // //
      color: #1279b7;
      filter: brightness(1.15);

      &:nth-child(1) {
        margin-top: 1.5rem !important;
      }

      @include respond(phone) {
        font-family: $font-primary-bold;
        color: $color-primary-light;
        filter: none;
      }
    }

    &--blue-border {
      padding-left: 1.5rem;

      &::after {
        @include pseudo-element;
        left: 0;
        top: 0;
        width: 0.3rem;
        height: 100%;
        border-radius: 10rem;
        background-color: #1279b7;
        filter: brightness(1.15);

        @include respond(phone) {
          color: $color-primary-light;
          filter: none;
        }
      }
    }

    &--list-style {
      position: relative;
      display: block;
      padding-left: 2.125rem;
      margin-bottom: 0.75rem;
      font-family: $font-primary-bold;
      line-height: 1.4;
      color: $color-primary-light;
      // font-family: $font-primary;;
      // color: $color-white;

      &:last-child {
        margin-bottom: -2.25rem;
      }

      &::after {
        @include pseudo-element;
        content: "•";
        left: 0;
        top: 0;
      }
    }

    .cloze__is-dialog & {
      max-width: 48rem;
    }

    &:not(:last-child) {
      // margin-top: 1rem; // // // <-- !!!
      margin-bottom: 3rem; // was: 5rem

      .cloze__is-dialog & {
        margin-bottom: 4.2rem;

        @include respond(phone) {
          margin-bottom: 0.9rem;
        }
      }
    }

    @include respond(phone) {
      &:not(:last-child) {
        margin-bottom: calc(3.6rem - (1.4 - 1.55) * 1.3rem);
      }
    }

    position: relative;

    .cloze__is-dialog & {
      display: block;
      width: max-content;

      padding: 0.5rem 1.8rem;
      border-radius: 1.2rem;
      background-color: #151515;

      @include respond(phone) {
        width: calc(100vw - 8.45rem);
      }
    }

    .cloze__is-dialog &--1 {
      margin-left: 9.25rem;
      border-top-left-radius: 0;

      @include respond(phone) {
        margin-left: 8.45rem;
      }

      .cloze__avatar {
        top: -0.6rem;
        left: -10.45rem;

        @include respond(phone) {
          left: -9.45rem;
        }
      }

      & + .cloze__paragraph--1 .cloze__avatar {
        display: none;
      }
    }

    .cloze__is-dialog &--2 {
      left: 100%;
      transform: translateX(calc(-100% - 9.25rem));
      margin-right: 9.25rem;
      border-top-right-radius: 0;

      .cloze__avatar {
        right: -9.45rem;
      }

      & + .cloze__paragraph--2 .cloze__avatar {
        display: none;
      }
    }

    &--1 + &--1 {
      margin-top: -3.2rem;

      @include respond(phone) {
        margin-top: -0.3rem;
      }
    }

    &--2 + &--2 {
      margin-top: -3.2rem;

      @include respond(phone) {
        margin-top: -0.3rem;
      }
    }

    &--1 + &--2 {
      @include respond(phone) {
        margin-top: 1.25rem;
      }
    }

    &--2 + &--1 {
      @include respond(phone) {
        margin-top: 5.375rem;
      }
    }
  }

  &__gap {
    position: relative;
    display: inline-block;
    top: -0.3rem;
    margin: 0 0.075rem;
    border-radius: 0.1rem;
    line-height: 2.6rem !important;
    white-space: nowrap;

    &:before,
    &::after {
      @include pseudo-element;
      bottom: 0.4rem;
      width: 100%;
      height: 1.5px;
      background-color: $color-grey-light;

      @include respond(phone) {
        height: 1.5px;
      }
    }

    &::after {
      height: 2.5px;
      transform: scaleX(0);

      @include respond(phone) {
        height: 1.5px;
      }
    }

    &--active::after {
      border-radius: 1rem;
      background-color: $color-primary;
      transform: scaleX(1);
      transition: transform 0.15s 0.2s ease-in-out;

      @include respond(phone) {
        background-color: #1876b8;
      }

      .cloze__paragraph--1 & {
        background-color: #26a5a9;
      }

      .cloze__paragraph--2 & {
        background-color: #1279b7;
      }
    }

    .cloze__transition-is-suspended &--active::after {
      transition: none;
    }

    &--processed::before {
      background-color: transparent;
    }

    &--correct {
      &::before,
      &::after {
        background-color: transparent !important;
      }
    }
  }

  &__solution {
    visibility: hidden;
    display: inline-block;
    transform: translateY(0.3rem);

    &--not-equalized {
      font-size: 2.6rem;
    }

    .cloze__gap--processed &--shown {
      font-size: 2.6rem;
      color: #3dabf5;

      @include respond(phone) {
        color: #1876b8;
      }
    }

    .cloze__gap--correct & {
      color: $color-white;
      background-color: green;
    }
  }

  &__message {
    position: fixed;
    left: calc(50vw - 0.5 * min(#{$width-center}, 100vw - 6rem));
    top: 0;
    z-index: 10;
    width: min(#{$width-center}, 100vw - 6rem);
    transform: translateY(calc(var(--vh, 1vh) * 100 - 6.75rem));

    transition: transform 0.75s, visibility 0s 0.75s;

    &--is-shown {
      transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
      transition: transform 0.75s;
    }

    @include respond(phone) {
      left: 0;
      width: 100%;

      &--is-shown {
        // transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 12.75rem));
      }
    }
  }

  &__answer-button-positioning-container {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateY(calc(var(--vh, 1vh) * 100 - 6.15rem + 1px - 100%));
    width: 100%;
    will-change: transform;
    transition: transform 0.75s;
  }

  &__answer-button-outer-container {
    top: 0;
    width: 100%;
    // padding: 1rem 0;
    // background-color: rgba($color-black, 0.25);
    border-top: 1px solid #1d1d1d;
    background-color: $color-black;
    backdrop-filter: blur(10px);

    // background-color: red;

    @include respond(phone) {
      padding: 0;
      border-top: none;
      // background-color: $color-black;
    }
  }

  &__answer-button-container {
    width: 100%;
    height: calc(100% - 0.55rem);
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    padding-left: calc(50% - 0.5 * #{$width-center});
    border-top: none;

    // background-color: green;

    @include respond(tab-port) {
      padding-left: 3rem;
    }

    @include respond(phone) {
      top: 0.55rem;
      padding-left: 0;
    }

    .cloze__is-dialog & {
      padding-left: calc(50% - 0.5 * #{$width-center} + 9.25rem);

      @include respond(tab-port) {
        padding-left: 3rem;
      }

      @include respond(phone) {
        padding-left: 0;
      }
    }
  }

  &__answer-button {
    display: block;
    float: left;
    width: max-content;
    border: 1px solid transparent !important;
    margin: 0.875rem 0;
    font-size: 2.4rem;
    background-color: $color-primary-dark;
    color: $color-white;

    display: flex;
    // align-content: center;
    align-items: center;

    @include respond(phone) {
      // margin-bottom: 0 0 1rem 0;
      margin-top: 0.4375rem !important;
      margin-bottom: 0.4375rem !important;
      padding: 0.625rem 1.25rem;
      font-size: 2.7rem;
      border-radius: 0.6rem;
      background-color: #1876b8;

      // background-color: red;

      &:last-child {
        margin-bottom: 1.5rem !important;
      }
    }

    .cloze__is-dialog & {
      border-radius: 0.6rem;
    }

    &:active {
      background-color: $color-primary !important;
    }

    &:not(:last-child) {
      margin-right: 1.6rem;

      @include respond(phone) {
        margin-right: 1rem;
      }
    }

    &--correct {
      background-color: green !important;
      transition: none !important;
      pointer-events: none;
      font-size: 2.40001rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      @include respond(phone) {
        padding: 0.625rem 1.25rem !important;
        font-size: 2.7rem !important;

        &:not(:last-child) {
          margin-right: 1rem !important;
        }
      }
    }

    &--wrong {
      // display: inline-block;
      // color: $color-wrong !important;
      color: $color-black !important;
      // border: 1px solid $color-wrong !important;
      // background-color: transparent !important;
      background-color: $color-wrong !important;
      transition: none !important;
      pointer-events: none;
      animation: shake 0.667s ease-in-out;
      font-size: 2.40001rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      @include respond(phone) {
        padding: 0.625rem 1.25rem !important;
        border-radius: 0.6rem;
        font-size: 2.7rem !important;

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    &--deactivated {
      pointer-events: none;
      font-size: 2.40001rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      @include respond(phone) {
        padding: 0.625rem 1.25rem !important;
        font-size: 2.7rem !important;

        &:not(:last-child) {
          margin-right: 1rem !important;
        }
      }
    }

    &--inactive {
      color: $color-black;
      background-color: $color-grey !important;
      transition: none !important;
      pointer-events: none;
      font-size: 2.40001rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      @include respond(phone) {
        padding: 0.625rem 1.25rem !important;
        font-size: 2.7rem !important;

        &:not(:last-child) {
          margin-right: 1rem !important;
        }
      }
    }
  }

  &__continue-button {
    visibility: hidden;
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    width: max-content;
    height: max-content;
    left: calc(50vw + 0.5 * min(#{$width-center}, 100vw - 6rem));
    transform: translateX(-100%);
    border: 1px solid transparent !important;
    border-radius: 0.6rem;
    padding: 0.85rem 2.4rem !important;
    background-color: $color-primary-dark;

    @include respond(phone) {
      position: fixed;
      display: block;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 6.5rem + 1px));
      width: 100vw;
      height: 6rem;
      font-size: 2.8rem;
      text-align: center;
      visibility: hidden;
      will-change: transform;
      transition: transform 0.75s;

      &--is-shown {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 13.5rem + 1px));
      }
    }

    &--is-shown {
      visibility: visible;
    }

    &--no-transition {
      transition: none;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
</style>
