<template>
  <div class="course-end">
    <h2 class="course-end__heading">
      {{ $t("course_end").final_word[finalWord] }}
    </h2>
    <p class="course-end__statement">
      {{ $t("course_end").evaluation[feedback] }}
    </p>
    <p class="course-end__score">
      {{ $store.state.achievedScore }} /
      {{ $store.state.numberOfExercises }}
    </p>
    <span class="course-end__achieved-score">
      {{ $t("course_end").reached_score }}
    </span>
    <app-lottie
      :lottie="'award_1'"
      :play="true"
      class="course-end__animation"
    ></app-lottie>
    <div class="course-end__container">
      <h3 class="course-end__new-knowledge-heading">
        {{ $t("course_end").new_skills }}
      </h3>
      <p class="course-end__new-knowledge">
        {{ t(content.knowledge) }}
      </p>
      <app-course-button
        @click="setPage(-1)"
        size="big"
        :variation="'grey'"
        :active="true"
        :text="$t('course_end').exit_button"
        class="course-end__button"
      ></app-course-button>
    </div>
  </div>
</template>

<script>
import AppLottie from "@/components/Views/Lottiecontainer.vue";
import AppCourseButton from "@/components/Atoms/CourseButton.vue";

export default {
  props: {
    content: Object
  },
  data() {
    return {
      shownScore: 0,
      lottieIsPlayed: false
    };
  },
  components: {
    AppLottie,
    AppCourseButton
  },
  computed: {
    coursePage() {
      return this.$store.state.coursePage;
    },
    scoreRatio() {
      if (this.$store.state.numberOfExercises === 0) {
        return 1;
      } else {
        return (
          this.$store.state.achievedScore / this.$store.state.numberOfExercises
        );
      }
    },
    finalWord() {
      if (this.scoreRatio >= 0.45) {
        return "gratulation";
      } else {
        return "done";
      }
    },
    feedback() {
      if (this.scoreRatio === 1) {
        return "perfect";
      } else if (this.scoreRatio >= 0.9) {
        return "good";
      } else if (this.scoreRatio >= 0.45) {
        return "sufficient";
      } else {
        return "done";
      }
    }
  },
  methods: {
    countScore() {
      if (this.shownScore < this.$store.state.achievedScore) {
        this.shownScore++;
        if (this.shownScore === 1) {
          this.playAudio("count");
        }
        let delay = Math.round(1000 / (this.$store.state.achievedScore - 1));
        setTimeout(() => {
          this.countScore();
        }, delay);
      }
    },
    showAward(videoNumber) {
      // let video1 = document.querySelector(".course-end__video--1");
      let video = document.querySelector(".course-end__video--" + videoNumber);
      video.style.display = "block";
      setTimeout(() => {
        video.style.opacity = 1;
      }, 10);
      setTimeout(() => {
        video.play();
        this.playAudio("award");
      }, 50);
    }
  },
  watch: {
    /* coursePage(to) {
      if (to === this.$store.state.numberOfPages - 1) {
        setTimeout(() => {
          this.lottieIsPlayed = true;
        }, 2375);
        if (
          this.$store.state.achievedScore < this.$store.state.numberOfExercises
        ) {
          this.buttonVariation = "";
        }
        if (this.$store.state.achievedScore >= 1) {
          // was 10
          setTimeout(() => {
            this.countScore();
          }, 500);
          if (
            this.$store.state.achievedScore >= 0
            // this.$store.state.numberOfExercises
          ) {
            setTimeout(() => {
              // // // alert(1);
              this.playAudio("award");
              // // // alert(2);
              // // // this.showAward(1);
            }, 2375); // was 2000
          } else if (
            this.$store.state.achievedScore >=
            0.9 * this.$store.state.numberOfExercises
          ) {
            setTimeout(() => {
              this.showAward(2);
            }, 2375); // was 2000
          }
        } else {
          this.shownScore = this.$store.state.achievedScore;
          if (
            this.$store.state.achievedScore ===
            this.$store.state.numberOfExercises
          ) {
            setTimeout(() => {
              this.showAward(1);
            }, 500);
          } else if (
            this.$store.state.achievedScore >=
            0.9 * this.$store.state.numberOfExercises
          ) {
            setTimeout(() => {
              this.showAward(2);
            }, 500);
          }
        }
      } else {
        this.lottieIsPlayed = false;
      }
    } */
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.course-end {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: max(100vh, 105rem);
  padding-top: max(11rem, min(13.75rem, 10vh));
  color: $color-white;

  background-color: #131313;

  @include grid-template-columns;
  grid-template-rows: repeat(4, min-content);

  &__heading {
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-size: 7rem;
    line-height: 1.4;
    font-weight: 400;

    z-index: 10000;
  }

  &__statement {
    grid-column: 3 / 4;
    font-size: 3rem;

    z-index: 10000;
  }

  &__score {
    grid-column: 3 / 4;
    margin-top: 18.4rem;
    z-index: 10000;
    font-size: 7rem;

    @include respond(tab-land) {
      margin-top: 9.2rem;
    }
  }

  &__achieved-score {
    grid-column: 3 / 4;
    margin-top: -2.4rem;
    font-size: 2rem;

    // transform: translateX(20.5rem);
    transform: translateX(0.3rem);

    z-index: 10000;
  }

  &__animation {
    grid-column: 3 / 4;
    // transform: translate(5rem, 10rem);
    transform: translate(26rem, -11.4rem);
    width: 8.5rem;
    height: max-content;
    border-radius: 50%;

    &::after {
      @include pseudo-element;
      left: -3rem;
      top: -3rem;
      z-index: -1;
      width: 14.5rem;
      height: 14.5rem;
      // // // border: 0.5rem solid #1a1a1a;
      border: 0.25rem solid #1a1a1a;
      // border-radius: 1rem;
      border-radius: 50%;
      background-color: $color-black;
    }
  }

  &__container {
    position: absolute;
    bottom: 0;
    grid-column: 3 / 4;
    margin-top: 80rem;
    padding-bottom: 11rem;
  }

  &__new-knowledge-heading {
    font-size: 3rem;
    margin-bottom: 0.6rem;
  }

  &__new-knowledge {
    margin-bottom: 16.8rem;
    width: min(70rem, 100vw - 2 * #{$side-margin});
    font-size: 2.4rem;
    line-height: 1.5;

    color: #acacac;

    @include respond(phone) {
      margin-bottom: 6rem;
      width: calc(100vw - 1.5rem);
      font-size: 2.8rem;
      color: #bbb;
    }
  }

  &__button {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
    justify-self: end;
    padding: 0.85rem 2.4rem !important;
    margin-top: 10.125rem;
    margin-bottom: -6rem;

    background-color: $color-primary-dark;
    position: fixed;
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    left: calc(50vw + 0.5 * min(#{$width-center}, 100vw - 6rem));
    top: 0;
    transform: translate(-100%, calc(var(--vh, 1vh) * 100 - 12rem));
    border-radius: 0.6rem;
    font-size: 2.3rem;
    text-align: center;
    will-change: transform;
    transition: transform 0.75s;

    @include respond(phone) {
      position: fixed;
      display: block;
      margin-top: 0;
      margin-bottom: 0;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 6.5rem + 1px));
      width: 100vw;
      height: 6rem;
      font-size: 2.8rem;
      text-align: center;
      will-change: transform;
      transition: transform 0.75s;
    }
  }
}
</style>
