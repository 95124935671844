<template>
  <div class="courses-contents">
    <app-button
      v-show="$store.state.coursePage === -1"
      @mousedown="setPage('--')"
      @touchstart="setPage('--')"
      text="''"
      variation="transparent"
      class="courses-contents__back-button"
    >
      &laquo; {{ $t("contents").back_button }}</app-button
    >
    <h2 class="courses-contents__heading" v-if="$store.state.coursePage === -2">
      {{ $t("internal_header")[1] }}
      <!-- {{ $t("courses_heading") }}
      {{ $t("subject")[$store.state.user.subject] }} -->
    </h2>
    <h2
      @mousedown="setPage('--')"
      @touchstart="setPage('--')"
      class="courses-contents__heading courses-contents__heading--topic"
      v-show="$store.state.coursePage === -1"
    >
      {{ t($store.state.chosenLecture) }}
    </h2>
    <app-lecture-group
      v-show="$store.state.coursePage === -2"
      v-for="(item, index) in contents.contents"
      :key="'firstKey' + JSON.stringify(item)"
      :index="index"
      :contentsPage="1"
      :payload="contents.contents"
      :lectureData="lessons"
      @initializeCourse="$emit('initializeCourse')"
      class="courses-contents__lecture-group"
    ></app-lecture-group>
    <template v-for="(lecture, lectureName) in lessons">
      <app-lecture-group
        v-show="
          ($store.state.coursePage === -1 &&
            lectureName === $store.state.contentsOfLecture) ||
            lessonsAreShown
        "
        v-for="(item, index) in lecture"
        :key="'firstKey' + JSON.stringify(item)"
        :index="index"
        :contentsPage="2"
        :payload="lecture"
        :lectureName="lectureName"
        @initializeCourse="$emit('initializeCourse')"
        class="courses-contents__lecture-group"
      ></app-lecture-group>
    </template>
  </div>
</template>

<script>
// import AppButton from "@/components/Atoms/Button.vue";
import AppLectureGroup from "@/components/Organisms/LectureGroup.vue";

// import contents from "/public/contents.json";

export default {
  name: "coursesContents",
  components: {
    // AppButton,
    AppLectureGroup
  },
  props: {
    contents: {
      type: Object,
      default: () => {}
    },
    lessons: {
      type: Object,
      default: () => {}
    }
  },
  emits: ["lectureChanged", "initializeCourse"],
  data() {
    return {
      lessonsAreShown: false
    };
  },
  methods: {
    backToCourses() {
      this.setPage("--");
      scroll(0, 0);
    }
  },
  created() {
    setTimeout(() => {
      this.lessonsAreShown = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.courses-contents {
  position: relative;
  z-index: 1000;
  width: 100%;
  min-height: 100vh;
  background-color: #131313;

  padding: 12rem calc(50vw - 0.5 * #{$width-center});
  color: $color-white;

  @include respond(tab-port) {
    padding: 12rem 3rem;
  }

  @include respond(phone) {
    padding: 15rem 0;
    background-color: $color-black;
  }

  &__lecture-group {
    margin-top: 4.5rem;

    @include respond(phone) {
      margin-top: 3.5rem;
    }
  }

  &__back-button {
    display: block;
    height: 0;
    margin-top: 0.6rem;
    transform: translateY(-6.05rem);
    padding-left: 0;
    padding-right: 0;
    font-family: $font-primary;
    font-size: 2rem;
    color: $color-primary-light;

    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: $color-primary-lighter;
    }

    @include respond(tab-port) {
      font-size: 2.4rem;
    }
  }

  &__heading {
    line-height: 1;
    margin-bottom: 5rem;
    font-weight: 400;

    @include respond(phone) {
      margin-left: 0.75rem;
      margin-bottom: 6.25rem;
    }

    &--topic {
      cursor: pointer;
    }
  }
}
</style>
