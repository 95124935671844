<template>
  <div
    :tabindex="lessonsAreShown ? 0 : -1"
    class="lesson"
    :class="{
      lesson__finished:
        payload3.lesson in $store.state.userDataLectures &&
        $store.state.userDataLectures[payload3.lesson].finished === true,
      lesson__started:
        payload3.lesson in $store.state.userDataLectures &&
        $store.state.userDataLectures[payload3.lesson].started === true
    }"
  >
    <!-- <div @mousedown="startCourse()" class="lesson__text-container"></div> -->
    <div
      class="lesson__text-container"
      :class="{
        'lesson__text-container--top-line': checkIfTopLineIsShown(),
        'lesson__text-container--bottom-line': checkIfBottomLineIsShown()
      }"
    >
      <div class="lesson__circle-container">
        <div
          v-if="
            !checkIfLockIsShown() &&
              !checkIfCheckmarkIsShown() &&
              !checkIfPendingIconIsShown()
          "
          class="lesson__circle"
          :class="{ 'lesson__circle--restricted': restricted }"
        ></div>
        <app-lock
          v-if="
            checkIfLockIsShown() &&
              !checkIfCheckmarkIsShown() &&
              !checkIfPendingIconIsShown()
          "
          icon="lock"
          class="lesson__checkmark lesson__lock"
        />
        <app-checkmark-blue
          v-if="checkIfCheckmarkIsShown()"
          alt="A checkmark marking a processed task"
          color="#1a7dc2"
          class="lesson__checkmark"
        ></app-checkmark-blue>
        <app-pending
          v-if="checkIfPendingIconIsShown()"
          alt="A clock marking a task in progress"
          class="lesson__checkmark"
        ></app-pending>
      </div>
      <h4 class="lesson__heading">
        {{ t(payload3.heading) }}
      </h4>
      <span class="lesson__text">{{ t(payload3.text) }} </span>
      <!-- <img
        src="@/assets/images/medal10.svg"
        alt="New courses icon"
        v-show="determineAward() === 'award1'"
        class="lesson__award"
      /> -->
      <!-- or: medal7.svg -->
      <!-- <app-lottie
        v-show="determineAward() === 'award1'"
        :lottie="'award_n19'"
        class="lesson__award"
      ></app-lottie> -->
      <div
        v-if="
          o([$store.state.userDataLectures, payload3.lesson, 'finished', false])
        "
        class="lesson__score-container"
      >
        <!-- <span class="lesson__score-word">Punkte:</span> -->
        {{ $t("contents").points }}:&nbsp;
        {{
          o([
            $store.state.userDataLectures,
            payload3.lesson,
            "maximumScore",
            0
          ])
        }}<span class="lesson__score-slash">/</span
        >{{
          o([
            $store.state.userDataLectures,
            payload3.lesson,
            "totalNumberOfExercises",
            0
          ])
        }}
      </div>
    </div>
  </div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppPending from "@/components/Atoms/Images/CourseIcons/Pending.vue";
import AppLock from "@/components/Atoms/Icons/Lock.vue";
// import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "lesson",
  components: {
    AppCheckmarkBlue,
    AppPending,
    AppLock
    // AppLottie
  },
  props: {
    payload3: {
      type: Object
    },
    payloadBefore: {
      type: Object
    },
    payloadAfter: {
      type: Object
    },
    lessonsAreShown: {
      type: Boolean
    }
  },
  computed: {
    restricted() {
      if (
        "restricted" in this.payload3 &&
        this.payload3.restricted &&
        !this.$store.state.isLicensed
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    checkIfTopLineIsShown() {
      return (
        this.checkIfCheckmarkIsShown() &&
        this.payloadBefore !== undefined &&
        this.o([
          this.$store.state.userDataLectures,
          this.payloadBefore.lesson,
          "finished",
          false
        ])
      );
    },
    checkIfBottomLineIsShown() {
      return (
        this.checkIfCheckmarkIsShown() &&
        this.payloadAfter !== undefined &&
        this.o([
          this.$store.state.userDataLectures,
          this.payloadAfter.lesson,
          "finished",
          false
        ])
      );
    },
    checkIfLockIsShown() {
      let prerequisites = this.payload3.prerequisites || [];

      let allPrerequisiteCoursesMade = true;

      if (
        !this.o([
          this.$store.state.userDataLectures,
          this.payload3.lesson,
          "isUnlocked",
          false
        ])
      ) {
        for (let i = 0; i < prerequisites.length; i++) {
          if (
            !(prerequisites[i] in this.$store.state.userDataLectures) ||
            !(
              "finished" in this.$store.state.userDataLectures[prerequisites[i]]
            ) ||
            !this.$store.state.userDataLectures[prerequisites[i]].finished
          ) {
            allPrerequisiteCoursesMade = false;
          }
        }
      }

      return !this.restricted && !allPrerequisiteCoursesMade;
    },
    checkIfCheckmarkIsShown() {
      return (
        !this.restricted &&
        this.o([
          this.$store.state.userDataLectures,
          this.payload3.lesson,
          "finished",
          false
        ])
      );
    },
    checkIfPendingIconIsShown() {
      return (
        !this.restricted &&
        this.o([
          this.$store.state.userDataLectures,
          this.payload3.lesson,
          "started",
          false
        ]) &&
        !this.checkIfCheckmarkIsShown()
      );
    }
    /* determineAward() {
      let scoreRatio =
        this.o([
          this.$store.state.userDataLectures,
          this.payload3.lesson,
          "maximumScore",
          0
        ]) /
        this.o([
          this.$store.state.userDataLectures,
          this.payload3.lesson,
          "totalNumberOfExercises",
          1
        ]);
      if (this.checkIfCheckmarkIsShown && scoreRatio === 1) {
        return "award1";
      } else {
        return false;
      }
    } */
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

::selection {
  background-color: transparent;
  color: currentColor;
}

.lesson {
  position: relative;
  width: calc(100% - 17.7rem);
  margin-left: 17.1rem;
  min-height: 4.5rem;

  &:first-child {
    margin-top: 2.5rem;

    @include respond(phone) {
      margin-top: 2rem;
    }
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.35rem;

    @include respond(phone) {
      margin-bottom: 0.55rem;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus:not(:active) {
    //
  }

  @include respond(phone) {
    width: 100%;
    margin-left: 0;
  }

  &__text-container {
    padding: 1.75rem 1.75rem 1.75rem 6.05rem;
    min-height: 4.5rem;
    font-size: 2rem;
    line-height: 1.2;

    border-radius: $border-radius;
    background-color: #131313;

    transform-style: preserve-3d;
    transition: background-color 0.2s;

    @include respond(phone) {
      padding: 1.5rem 1.5rem 1.5rem 6.1rem;
      // background-color: $color-black;
      // background-color: #131313;
      background-color: #1a1a1a;
      font-size: 2.3rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #222;
      }
    }

    &::before,
    &::after {
      left: 2.85rem;
      z-index: -1;
      border-left: 1px dashed #666;
    }

    .lesson:not(:first-child) &::before {
      @include pseudo-element;
      top: 0;
      height: 1.75rem;
    }

    .lesson:not(:last-child) &::after {
      @include pseudo-element;
      top: 4.3rem;
      height: calc(100% - 4.3rem);
    }

    &--top-line::before,
    &--bottom-line::after {
      border-left: 1px solid #666;
    }
  }

  &__circle-container {
    position: absolute;
    left: 1.75rem;
    top: 1.85rem;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1.5px solid #666;
    border-radius: 50%;

    // overflow: hidden;

    &.lesson__circle--restricted {
      border: 1px solid #e00;

      &::after {
        @include pseudo-element;
        left: calc(50% - 0.55rem);
        top: calc(50% - 0.1rem);
        // transform: rotate(45deg);
        width: 1.1rem;
        height: 0.2rem;
        background-color: #e00;
      }
    }
  }

  &__checkmark {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__lock {
    top: -0.5rem;
    left: -0.35rem;
    width: 125%;
    height: 125%;

    filter: brightness(0.5);
  }

  &__heading {
    margin-bottom: 0.375rem;
    color: #eee;
  }

  &__text {
    font-size: 1.8rem;
    color: #aaa;

    @include respond(phone) {
      display: inline-block;
      margin-top: 0.25rem;
      font-size: 2.3rem;
    }
  }

  &__award {
    position: absolute;
    right: 1.75rem;
    top: 0;
    // width: 5rem;
    width: 2.4rem;
  }

  &__score-container {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    padding: 0.175rem 0.35rem;
    border-radius: 0.2rem;
    font-size: 1.6rem;

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &__score-slash {
    margin: 0 0.3rem;
  }
}
</style>
