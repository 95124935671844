<template>
  <teleport to="body">
    <div
      class="feedback"
      :class="{
        'feedback--is-shown': feedbackFormIsShown,
        'feedback--toc-is-open': $store.state.TOCisOpen
      }"
    >
      <div class="feedback__header">{{ $t("feedback_form")["header"] }}</div>

      <div class="feedback__bottom-container">
        <p class="feedback__introduction">
          {{ $t("feedback_form")["introduction"] }}
        </p>

        <textarea
          v-model="message"
          :placeholder="$t('feedback_form')['placeholder']"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="feedback__text-field"
        ></textarea>

        <app-button
          @click="sendFeedback()"
          :text="$t('feedback_form')['button']"
          size="big"
          class="feedback__button"
        ></app-button>
      </div>

      <app-button-x
        @click="$store.state.feedbackFormIsShown = false"
        class="feedback__x-button"
      ></app-button-x>
    </div>
  </teleport>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonX from "@/components/Atoms/ButtonX.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButton,
    AppButtonX
  },
  data: () => {
    return {
      message: "",
      buttonIsDeactivated: false
    };
  },
  computed: {
    feedbackFormIsShown() {
      return this.$store.state.feedbackFormIsShown;
    },
    TOCisOpen() {
      return this.$store.state.TOCisOpen;
    }
  },
  methods: {
    sendFeedback() {
      if (this.buttonIsDecativated) {
        return;
      }

      if (this.message.length < 10) {
        this.toggleAlert(this.$t("feedback_form")["message_too_short"]);
        return;
      }

      this.buttonIsDeactivated = true;

      let numberOfPagesInRecentSection = 1;
      if (
        this.$store.state.recentSection > 0 &&
        this.$store.state.recentPage.type !== "end"
      ) {
        numberOfPagesInRecentSection =
          this.$store.state.sectionBeginsAtPage[
            this.$store.state.recentSection
          ] -
          this.$store.state.sectionBeginsAtPage[
            this.$store.state.recentSection - 1
          ];
      }

      let section = "Abschnitt " + this.$store.state.recentSection;
      if (this.$store.state.recentSection === 0) {
        section = "Startseite";
      } else if (this.$store.state.recentPage.type === "end") {
        section = "Schlussseite";
      }

      let location =
        this.t(this.$store.state.chosenLecture, "de") +
        " > " +
        this.t(this.$store.state.topicGroup, "de") +
        " > " +
        this.t(this.$store.state.topic, "de") +
        " > " +
        this.t(this.$store.state.headingOfChosenLesson, "de") +
        ": " +
        section;

      if (
        this.$store.state.recentSection > 0 &&
        this.$store.state.recentPage.type !== "end" &&
        numberOfPagesInRecentSection > 1
      ) {
        let pageInSection =
          this.$store.state.pageNumberInSection[this.$store.state.coursePage] +
          1;
        location += ", Seite " + pageInSection;
      }

      let language = this.$i18n.locale;
      if (language === "de") {
        language = "Deutsch";
      } else if (language === "en") {
        language = "Englisch";
      }

      const url =
        "https://us-central1-mathopia-71418.cloudfunctions.net/sendEmail?location=" +
        location +
        "&language=" +
        language +
        "&emailFrom=" +
        this.$store.state.user.email +
        "&message=" +
        this.message;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };

      fetch(url, requestOptions);

      this.toggleAlert(this.$t("feedback_form")["successfully_sent"]);
      setTimeout(() => {
        this.$store.state.feedbackFormIsShown = false;
      }, 100);
    }
  },
  watch: {
    feedbackFormIsShown(to) {
      if (to) {
        this.buttonIsDeactivated = false;
        document.querySelector(".feedback").style.transition =
          "transform 0.5s ease-out";
      } else {
        document.querySelector(".feedback").style.transition =
          "transform 0.5s ease-in, visibility 0s 0.5s";
        setTimeout(() => {
          this.message = "";
        }, 600);
      }
    },
    TOCisOpen(to) {
      if (to) {
        document.querySelector(".feedback").style.transition =
          "transform 0.5s ease-out, visibility 0s 0.5s";
      } else {
        document.querySelector(".feedback").style.transition =
          "transform 0.5s ease-in";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.feedback {
  position: fixed;
  right: 0;
  bottom: 6rem;
  z-index: 1000000;
  width: 36rem;
  border: 1px solid #1d1d1d;
  border-radius: 0.4rem;
  background-color: $color-black;

  visibility: hidden;
  transform: translateY(calc(100% + 6.1rem));

  transition: transform 0.5s ease-in, visibility 0s 0.5s;

  @include respond(tab-port) {
    width: 43.35rem;
  }

  @include respond(phone) {
    display: grid;
    // grid-template-rows: 6rem 50vh 40vh;

    bottom: 0;
    top: 6rem;
    width: 100vw;
    // height: calc(100vh - 6rem);
    height: calc(100 * var(--vh) - 6rem);
  }

  &--is-shown {
    visibility: visible;
    transform: none;

    transition: transform 0.5s ease-out;

    &.feedback--toc-is-open {
      transform: translateX(calc(-43.35rem + 1px));
    }
  }

  &--toc-is-open {
    transform: translate(calc(-43.35rem + 1px), calc(100% + 6.1rem));
  }

  &__bottom-container {
    padding: 1.5rem;
    padding-top: 0.42rem;
    padding-bottom: 2.25rem;

    @include respond(phone) {
      display: grid;
      grid-template-rows: min-content 1fr min-content;
      // height: calc(100vh - 12rem);
      height: calc(100 * var(--vh) - 12rem);
      padding: 1.5rem 0;
      // background-color: green;
    }
  }

  &__header {
    height: 5rem;
    border-bottom: 1px solid #1d1d1d;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    line-height: 5rem;
    text-align: center;
    color: $color-white;
    background-color: #1d1d1d;

    @include respond(phone) {
      height: 6rem;
      line-height: 6rem;
      font-size: 2.6rem;

      background-color: $color-primary-dark;
      // background-color: #1a1a1a;
    }
  }

  &__introduction {
    margin-top: 1.375rem;
    margin-bottom: 5.25rem;
    font-size: 1.6rem;
    color: #999;

    @include respond(tab-port) {
      font-size: 2.2rem;
      color: #bbb;
    }

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__text-field {
    resize: none;
    left: 1.5rem;
    width: 100%;
    height: 21rem;
    padding: 0.75rem;
    border-radius: 0.4rem;
    border: 1px solid #202020;
    font-family: $font-primary;
    font-size: 2rem !important;
    color: $color-white !important;
    background-color: #101010;

    @include respond(tab-port) {
      font-size: 2.2rem !important;
    }

    @include respond(phone) {
      // left: -1.5rem;
      width: 100%;
      // height: 50vh;
      // height: max-content;
      height: initial;
      font-size: 2.6rem !important;
      border-radius: 0.6rem;
      background-color: #1a1a1a;
    }

    &:focus {
      outline: none;
      border: 1px solid $color-primary;
    }

    &::placeholder {
      color: $color-white;
    }
  }

  &__button {
    width: 100%;
    height: 4.5rem;
    text-align: center;
    background-color: $color-primary-dark;

    @include respond(tab-port) {
      height: 5rem;
      font-size: 2.4rem;
    }

    @include respond(phone) {
      margin-top: 6rem;
      height: 6rem;
    }
  }

  &__x-button {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    width: 2.1rem;
    height: 2.1rem;

    @include respond(phone) {
      width: 2.675rem;
      height: 2.675rem;
    }
  }
}
</style>
