import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n/index"; // was: from "vue-i18n"
import messages from "./lang";

import { language } from "./lang/index.js";
import { utilities } from "./mixins/utilities.js";

// // // import firebase from "firebase";
// import { set } from 'vue/types/umd'
// // // require("firebase/auth");

export const i18n = new createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages
});

router.beforeEach((to, from, next) => {
  // alert(store.state.isAuthenticated);

  // Handle URL Parameters for email links
  let mode = "";
  let actionCode = "";
  let lang = "";
  let email = "";
  if (to.query.oobCode) {
    actionCode = to.query.oobCode;
  }
  if (to.query.lang) {
    lang = to.query.lang;
  } else {
    lang = "de";
  }
  if (to.query.continueUrl) {
    email = to.query.continueUrl.split("=")[1];
  }
  if (to.query.mode) {
    mode = to.query.mode;
    store.state.urlParameters = [mode, actionCode, lang, email];
    localStorage.removeItem("isAuthenticated");
    next("/");
    return;
  }

  let auth = localStorage.getItem("isAuthenticated") || false;
  if (auth) {
    store.state.isAuthenticated = true;
  }
  if (auth && to.path === "/") {
    next("/overview");
  } else if (
    !auth &&
    (to.path === "/overview" ||
      to.path === "/course/" ||
      to.path === "/course" ||
      to.path === "/account")
  ) {
    next("/");
    /* setTimeout(() => {
      // // // next("/");
      next();
    }, 0); */
  } else {
    next();
  }
});

const mixin = {
  methods: {
    // *** Routing and managing hash parameters ***
    goTo(destination) {
      // this.$router.push(destination).catch(() => {});
      this.$router.replace(destination);
      // scroll(0, 0);
      /* setTimeout(() => {
        this.scrollToTop();
      }, 15); */
    },
    setPage(page, replace) {
      if (this.$store.state.coursePage === page) {
        return;
      }
      // scroll(0, 0);
      if (page === "++") {
        if (
          this.$store.state.coursePage + 1 ===
            this.$store.state.numberOfPages &&
          this.$store.state.coursePage !== -1
        ) {
          this.$store.state.coursePage = -1;
        } else {
          this.$store.state.coursePage++;
        }
      } else if (page === "--") {
        this.$store.state.coursePage--;
      } else {
        this.$store.state.coursePage = page;
      }
      let state = { page: this.$store.state.coursePage };
      const title = "";
      let url;
      if (this.$store.state.coursePage === -2) {
        url = "/course/#/lectures";
      } else if (this.$store.state.coursePage === -1) {
        url = "/course/#/lessons";
      } else if (this.$store.state.coursePage === 0) {
        url =
          "/course/#/" +
          this.$store.state.contentsOfLecture +
          "/" +
          store.state.chosenLesson +
          "/" +
          "start";
      } else if (
        this.$store.state.coursePage + 1 ===
        this.$store.state.numberOfPages
      ) {
        url =
          "/course/#/" +
          this.$store.state.contentsOfLecture +
          "/" +
          store.state.chosenLesson +
          "/" +
          "finish";
      } else {
        url =
          "/course/#/" +
          this.$store.state.contentsOfLecture +
          "/" +
          store.state.chosenLesson +
          "/" +
          this.$store.state.coursePage;
      }
      if (replace) {
        history.replaceState(state, title, url);
      } else {
        history.pushState(state, title, url);
      }
    },

    // *** General utilities ***
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },

    resetVariables() {
      store.state.coursePageRequestedViaTOC = 0;
      let html = document.getElementsByTagName("html")[0];
      html.style.setProperty("--password-strength-bar-scale-x", 0);
      html.style.setProperty("--password-strength-bar-color", "red");
      setTimeout(() => {
        store.state.registrationSuccessful = false;
        store.state.modalNumber = 0;
      }, 500);
    },
    toggleAlert(text, textGhostButton, ghostAction) {
      // this.a(text);
      if (text !== undefined) {
        this.$store.state.alertText = this.t(text);
      } else {
        this.$store.state.alertText = "";
      }
      if (textGhostButton !== undefined) {
        this.$store.state.alertTextGhostButton = this.t(textGhostButton);
      } else {
        this.$store.state.alertTextGhostButton = "";
      }
      if (ghostAction !== undefined) {
        this.$store.state.alertGhostAction = ghostAction;
      } else {
        this.$store.state.alertGhostAction = undefined;
      }
    },
    toggleModal(modal, submodal, modal2) {
      if (store.state.shownModal === "none" && modal !== undefined) {
        store.state.shownModal = modal;
        store.state.shownSubModal = submodal;
        setTimeout(() => {
          let tabstart = document.querySelector(".tabstart");
          tabstart.focus();
        }, 0);
        if (modal2 !== undefined) {
          store.state.shownModal2 = modal2;
        }
      } else {
        if (store.state.modalMayBeClosed) {
          store.state.shownModal = "none";
          setTimeout(() => {
            store.state.shownModal2 = "none";
          }, 500);
          this.resetVariables();
        }
      }
    },
    changeModal(modal, submodal) {
      let overlay = document.querySelector(".modal__overlay");
      let modalContainer = document.querySelector(".modal__container");
      overlay.style.display = "block";
      // // // overlay.style.opacity = 0.625;
      overlay.style.opacity = 1;
      modalContainer.style.display = "block";
      setTimeout(() => {
        overlay.style.display = "";
        overlay.style.opacity = "";
        modalContainer.style.display = "";
      }, 1020);
      this.toggleModal();
      setTimeout(() => {
        this.toggleModal(modal, submodal);
      }, 510);
    },
    toggleSidebarMenu(modal, e) {
      e.preventDefault();

      if (store.state.shownSidebarMenu === "none") {
        store.state.shownSidebarMenu = modal;
        // // // navigator.vibrate(1000);
      } else {
        store.state.shownSidebarMenu = "none";
      }
    },
    closeLanguageMenu(event) {
      if (
        document.querySelector(".language-menu") &&
        !document.querySelector(".language-menu").contains(event.target) &&
        !document
          .querySelector(".header__button--language")
          .contains(event.target)
      ) {
        store.state.languageMenuIsShown = false;
      }
    },
    closeMenu(event) {
      this.toggleGlossaryModal(
        false,
        store.state.glossaryTopic,
        store.state.glossarySubtopic,
        store.state.glossaryParagraph
      );
      if (event === undefined) {
        store.state.mainMenuIsShown = false;
        store.state.languageMenuIsShown = false;
      } else if (
        store.state.mainMenuIsShown &&
        document.querySelector(".main-menu") &&
        !document.querySelector(".main-menu").contains(event.target) &&
        document.querySelector(".header__hamburger") &&
        !document.querySelector(".header__hamburger").contains(event.target)
      ) {
        store.state.mainMenuIsShown = false;
      } else if (
        document.querySelector(".language-menu") &&
        !document.querySelector(".language-menu").contains(event.target) &&
        !document
          .querySelector(".header__button--language")
          .contains(event.target)
      ) {
        store.state.languageMenuIsShown = false;
      }
    },
    toggleGlossaryModal(isShown, topic, subtopic, paragraph, index) {
      store.state.glossaryTopic = topic;
      store.state.glossarySubtopic = subtopic;
      store.state.glossaryParagraph = paragraph;
      this.typesetMath();

      function isFixedPosition(node) {
        while (node && node.nodeName.toLowerCase() !== "body") {
          if (
            window
              .getComputedStyle(node)
              .getPropertyValue("position")
              .toLowerCase() === "fixed"
          ) {
            return true;
          }
          node = node.parentNode;
        }
        return false; // if got this far
      }

      setTimeout(() => {
        if (index !== undefined) {
          let element = document.getElementById("glossary__link--" + index);
          let elementIsFixed = isFixedPosition(element);
          if (elementIsFixed) {
            store.state.glossaryIsFixed = true;
          } else {
            store.state.glossaryIsFixed = false;
          }
          let elementTotalLeft = this.left(element);
          let elementWidth = this.width(element);
          let elementLeft =
            elementTotalLeft + 0.5 * elementWidth - this.remToPx(6);
          let glossaryModal = document.querySelector(".glossary-modal");
          let elementRight = elementLeft + this.width(glossaryModal);
          let rightBorder = Math.min(
            0.5 * store.state.windowWidth + this.remToPx(51.2),
            store.state.windowWidth - this.remToPx(3)
          );
          if (elementRight > rightBorder) {
            elementLeft -= elementRight - rightBorder;
            store.state.glossaryArrowShift = elementRight - rightBorder;
          } else {
            store.state.glossaryArrowShift = 0;
          }
          store.state.glossaryLeft = elementLeft + 0.5;

          let elementTop = this.top(element);
          let elementBottom = this.bottom(element);
          let bottomOfElement;
          let scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          if (elementTop > store.state.windowHeight - elementBottom) {
            store.state.glossaryIsOnTop = true;
            if (elementIsFixed) {
              bottomOfElement = elementTop - 12 - glossaryModal.clientHeight;
            } else {
              bottomOfElement =
                elementTop + scrollTop - 12 - glossaryModal.clientHeight;
            }
          } else {
            store.state.glossaryIsOnTop = false;
            if (elementIsFixed) {
              bottomOfElement = elementBottom + 12;
            } else {
              bottomOfElement = elementBottom + scrollTop + 12;
            }
          }

          // setTimeout(() => {
          store.state.glossaryTop = bottomOfElement;
          // }, 0);
        }
      }, 0);

      if (isShown === "toggle") {
        let newIsShown = store.state.glossaryIsShown ? false : true;
        this.toggleGlossaryModal(newIsShown, topic, subtopic, paragraph, index);
        return;
      }

      if (isShown && isShown === true) {
        setTimeout(() => {
          store.state.glossaryIsShown = true;
          this.clearTimeout("hideGlossaryModal");
          setTimeout(() => {
            this.clearTimeout("hideGlossaryModal");
          }, 15);
        }, 0);
      } else if (isShown === false) {
        this.setTimeout(
          () => {
            store.state.glossaryIsShown = false;
          },
          30,
          "hideGlossaryModal"
        );
      }
    },

    // *** Typeset of MathJax formulas ***
    typesetMath(delay) {
      if (store.state.typesetMathIsDeactivated) {
        return;
      }
      let delayOfTypeset = delay || "noDelay";

      if (typeof window.MathJax.typesetPromise === "undefined") {
        setTimeout(() => {
          this.typesetMath(delay);
        }, 1);
        return;
      }

      function executeTypeset() {
        window.MathJax.typesetPromise().then(() => {
          // modify the DOM here
          window.MathJax.typesetPromise();
        });
      }

      if (delayOfTypeset === "noDelay") {
        executeTypeset();
      } else {
        setTimeout(() => {
          executeTypeset();
        }, delayOfTypeset);
      }
    },

    clearSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    },

    // *** Translation utilities ***
    t(expression, language) {
      if (expression === null || expression === undefined) {
        return "";
      }

      if (!Array.isArray(expression)) {
        expression = [expression];
      }

      let returnText = "";
      for (let i = 0; i < expression.length; i++) {
        let entry = expression[i];

        if (typeof entry === "string") {
          returnText += entry;
        } else if (language !== undefined) {
          if (language in entry) {
            returnText += entry[language];
          } else if ("de" in entry) {
            returnText += entry["de"];
          } else if ("en" in entry) {
            returnText += entry["en"];
          } else if (this.$i18n.locale in entry) {
            returnText += entry[this.$i18n.locale];
          }
        } else {
          if (this.$i18n.locale in entry) {
            returnText += entry[this.$i18n.locale];
          } else if ("de" in entry) {
            returnText += entry["de"];
          } else if ("en" in entry) {
            returnText += entry["en"];
          }
        }
      }

      returnText = returnText.replace(/ⓣ/g, "");

      if (this.$store.state.windowWidth <= 600) {
        returnText = returnText.replace(/@@/g, "\\\\ &");
      } else {
        returnText = returnText.replace(/@@/g, "");
      }
      return returnText;
    },

    isDefined(value) {
      let isDefined = false;

      if (value && value !== undefined && value !== null && value !== "") {
        if (Array.isArray(value) && value.length > 0) {
          isDefined = true;
        } else if (typeof value === "object" && Object.keys(value).length > 0) {
          isDefined = true;
        } else if (typeof value === "string" && value.length > 0) {
          isDefined = true;
        } else if (typeof value == "number") {
          isDefined = true;
        }
      }

      return isDefined;
    },

    // *** Create HTML utilities ***
    getHTML(text) {
      if (typeof text === "string") {
        let textNew = {};
        let key = this.$i18n.locale;
        textNew[key] = text;
        text = JSON.parse(JSON.stringify(textNew));
      }

      let returnValue = [];
      for (let key in text) {
        let splitText = text[key].split("##");
        for (let i = 0; i < splitText.length; i++) {
          if (Math.abs(i % 2) === 1) {
            let splitOpenBracket = splitText[i].split("[");
            let splitCloseBracket = splitOpenBracket[
              splitOpenBracket.length - 1
            ].split("]");
            let bracketData = splitCloseBracket[0];
            let bracketDataArray = bracketData.split(",");
            for (let j = 0; j < bracketDataArray.length; j++) {
              bracketDataArray[j] = bracketDataArray[j].trim();
            }
            let newSplitText = "";
            for (let k = 0; k < splitOpenBracket.length - 1; k++) {
              newSplitText += splitOpenBracket[k];

              if (k !== splitOpenBracket.length - 2) {
                newSplitText += "[";
              }
            }
            splitText[i] = {
              type: "glossary",
              text: newSplitText,
              topic: bracketDataArray[0],
              subtopic: bracketDataArray[1],
              paragraph: bracketDataArray[2]
            };
          } else {
            splitText[i] = {
              type: "text",
              text: splitText[i]
            };
          }
        }
        returnValue[key] = splitText;
      }

      return returnValue;
    },

    // Handle vertical space for displayed MathJax equations
    verticalSpace(text) {
      let splitText = text.split("$$");

      for (let i = 0; i < splitText.length; i++) {
        if (Math.abs(i % 2) === 1) {
          splitText[i] = {
            type: "formula",
            content: "$$" + splitText[i] + "$$"
          };
        } else {
          splitText[i] = {
            type: "text",
            content: splitText[i]
          };
        }
      }

      return splitText;
    },

    // *** Audio utilities ***
    initializeAudio() {
      if (this.$store.state.soundWasInitialized) return;
      this.$store.state.soundWasInitialized = true;

      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioCtx = new AudioContext();
      store.state.audioCtx = audioCtx;

      for (let i = 0; i <= 9; i++) {
        this.$store.state.soundEffect[i] = new Audio();
        this.$store.state.soundEffect[i].autoplay = true;

        this.$store.state.soundEffect[i].src =
          "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
      }
    },
    playAudio(audio) {
      if (!store.state.soundIsTurnedOn) {
        return;
      }

      let delay = null;
      if (!this.$store.state.soundWasInitialized) {
        delay = 10;
        this.initializeAudio();
      }

      this.setTimeout(() => {
        if (
          audio === "accept" ||
          audio === "award" ||
          audio === "click" ||
          audio === "completion" ||
          audio === "correct" ||
          audio === "count" ||
          audio === "snap" ||
          audio === "success" ||
          audio === "unlock" ||
          audio === "whoosh" ||
          audio === "wrong"
        ) {
          this.$store.state.soundEffect[
            this.$store.state.recentAudioChannel
          ].src = require("@/assets/audio/" + audio + ".mp3");
        } else {
          this.$store.state.soundEffect[
            this.$store.state.recentAudioChannel
          ].src =
            // "http://mathopia3.s3-website.eu-central-1.amazonaws.com/" +
            "https://mathopia.com/audio/" + this.t(audio) + ".mp3";
        }

        if (this.$store.state.recentAudioChannel < 9) {
          this.$store.state.recentAudioChannel++;
        } else {
          this.$store.state.recentAudioChannel = 0;
        }
      }, delay);
    },
    getAudioDuration(audio) {
      let audioFile = new Audio();
      audioFile.src =
        // "http://mathopia3.s3-website.eu-central-1.amazonaws.com/" +
        "https://mathopia.com/audio/" + this.t(audio) + ".mp3";

      setTimeout(() => {
        store.state.audioDurations[audio] = audioFile.duration * 1000;
      }, 500);
    },

    clearAudio() {
      for (let i = 0; i <= this.$store.state.soundEffect.length - 1; i++) {
        this.$store.state.soundEffect[i].pause();
      }
    },

    generateArray(number) {
      let array = [];
      for (let i = 0; i < number; i++) {
        array.push([]);
      }
      return array;
    },

    turnNestedArrayIntoSimpleArray(array) {
      let simpleArray = [];

      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array[i].length; j++) {
          simpleArray.push(array[i][j]);
        }
      }

      return simpleArray;
    },

    generateMessage(data, type) {
      let newMessage = {};
      if (typeof data === "string" || Array.isArray(data)) {
        newMessage.message = data;
      } else if ("message" in data) {
        newMessage.message = data.message;
      } else {
        newMessage.message = data;
      }
      if (type) {
        newMessage.type = type;
      } else {
        newMessage.type = "message";
      }
      if (typeof data === "object" && "image" in data) {
        newMessage.image = data.image;
      } else {
        newMessage.image = false;
      }
      return newMessage;
    },

    o(data) {
      // let returnData = data;

      let expression = data[0];
      for (let i = 1; i < data.length - 1; i++) {
        if (data[i] in expression) {
          expression = expression[data[i]];
        } else {
          expression = data[data.length - 1];
          break;
        }
      }

      return expression;
    },

    compareObjects(a, b) {
      let s = o =>
        Object.entries(o)
          .sort()
          .map(i => {
            if (i[1] instanceof Object) i[1] = s(i[1]);
            return i;
          });
      return JSON.stringify(s(a)) === JSON.stringify(s(b));
    },

    roundCourseProgress(percentage) {
      if (percentage > 0 && percentage < 1) {
        return 1;
      }
      return Math.floor(percentage);
    },

    scrollTo(target, differenceY) {
      let scrollDocument =
        document.scrollingElement ||
        document.documentElement ||
        document.body.parentNode ||
        document.body;

      scrollDocument = document.querySelector(".app");

      // let target = document.querySelector(".lecture"); // make general for other pages
      let translateY = differenceY || 0;
      let targetTop = this.top(target) + translateY;
      let documentTop = -scrollDocument.scrollTop;

      let difference = targetTop;
      if (difference === 0) {
        return;
      }
      let iterations = Math.max(Math.floor(Math.abs(difference / 50)), 50); // was 50 // 25

      let k = 1;
      // // this.setScrollStatus(true);
      // let vm = this;
      update();

      function update() {
        let l = k / iterations;

        if (l < 0.5) {
          l = 2 * l * l;
        } else {
          l = 1 - 2 * (l - 1) * (l - 1);
        }

        let newPosition = Math.floor(-documentTop + l * difference);
        scrollDocument.scrollTop = newPosition;

        k++;
        if (k > iterations) {
          setTimeout(() => {
            // // vm.setScrollStatus(false);
          }, 250);
          // vm.setScrollStatus(false)
          return;
        }

        /* setTimeout(() => {
          update();
        }, 15); */
        window.requestAnimationFrame(update);
      }
    },
    scrollElement(element, bottom, numberOfIterations) {
      let elementBottom = this.bottom(element);
      let bottomFromTop = window.innerHeight - bottom;

      if (elementBottom <= bottomFromTop) {
        return;
      }

      document.body.style.position = "initial";
      document.body.style.overflow = "initial";

      let app = document.querySelector(".app");
      app.style.height = "initial";

      let scrollPosition = window.pageYOffset;
      if (store.state.windowWidth <= 600) {
        scrollPosition = app.scrollTop;
      }

      let scrollDestination =
        bottom - (window.innerHeight - elementBottom) + scrollPosition;

      let iterations = 40;
      if (numberOfIterations !== undefined) {
        iterations = numberOfIterations;
      }

      let k = 0;
      function update() {
        if (k > iterations) {
          return;
        }

        let l = k / iterations;

        if (l < 0.5) {
          l = 2 * l * l;
        } else {
          l = 1 - 2 * (l - 1) * (l - 1);
        }
        window.scrollTo(
          0,
          scrollPosition + l * (scrollDestination - scrollPosition)
        );
        app.scrollTo(
          0,
          scrollPosition + l * (scrollDestination - scrollPosition)
        );

        k++;
        window.requestAnimationFrame(update);
      }

      update();
    },
    scrollToTop() {
      document.body.style.position = "initial";
      document.body.style.overflow = "initial";

      let app = document.querySelector(".app");
      // app.style.height = "initial";
      setTimeout(() => {
        window.scrollTo(0, 0);
        app.scrollTo(0, 0);
      }, 0);
    },

    // Timeout functions
    setTimeout(fct, delay, name) {
      if (delay === null) {
        fct();
      } else {
        if (name === undefined) {
          store.state.auxiliaryNumber++;
          let name2 = "auxiliaryName" + store.state.auxiliaryNumber;
          this.setTimeout(fct, delay, name2);
        } else {
          let timeout = () => {
            this.$store.state.timeouts[name] = setTimeout(() => {
              fct();
            }, delay);
          };
          timeout();
        }
      }
    },
    clearTimeout(name) {
      clearTimeout(this.$store.state.timeouts[name]);
    },
    clearAllTimeouts() {
      for (let name in this.$store.state.timeouts) {
        clearTimeout(this.$store.state.timeouts[name]);
      }
    },

    // Utility for showing alerts with objects
    a(message) {
      alert(JSON.stringify(message, null, 4));
    },

    // *** Get position of the address bar for iPhones with iOS 15 ***
    /* getAddressBarPosition() {
      if (window.innerHeight === 664) {
        return { position: "bottom", collapsed: false };
      } else if (window.innerHeight === 745) {
        return { position: "bottom", collapsed: true };
      } else if (
        window.innerHeight <= 733 &&
        window.innerHeight >= 732
      ) {
        return { position: "top", collapsed: false };
      } else {
        return { position: "top", collapsed: true };
      }
    } */

    // Fullscreen utilities
    setFullscreen(isRecentlyInFullscreen) {
      function fullScreenCheck() {
        if (!isRecentlyInFullscreen) {
          setTimeout(() => {
            const newOrientation = "portrait";
            screen.orientation.lock(newOrientation);
          }, 0);
          return document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
      }

      async function rotate() {
        try {
          await fullScreenCheck();
        } catch (err) {
          // console.log(err);
        }
      }

      rotate();
    }
  }
};

createApp(App)
  .mixin(language)
  .mixin(utilities)
  .mixin(mixin)
  .use(store)
  .use(router)
  .use(i18n)
  .mount("#app");

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

/* firebase.initializeApp({
  apiKey: "AIzaSyCNcfKjtrCSPfLsfQxiWi_HgzNjOo4IfPA",
  authDomain: "mathopia.firebaseapp.com",
  databaseURL: "https://mathopia-39a05.europe-west1.firebasedatabase.app/",
  projectId: "mathopia",
  storageBucket: "mathopia.appspot.com",
  messagingSenderId: "886495238581",
  appId: "1:886495238581:web:69f0ef652a50980d0461fc"
}); */

firebase.initializeApp({
  apiKey: "AIzaSyC3LjLeOPmSJf4q5GieOgB-jd2VJba4_tc",
  authDomain: "mathopia-71418.firebaseapp.com",
  databaseURL:
    "https://mathopia-71418-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mathopia-71418",
  storageBucket: "mathopia-71418.appspot.com",
  messagingSenderId: "817897712774",
  appId: "1:817897712774:web:fceea63665a698d568f507",
  measurementId: "G-F4J5VCXK5E"
});
