<template>
  <div
    class="course"
    @touchstart="initializeAudio()"
    :key="$store.state.courseKey2"
  >
    <app-courses-contents
      v-show="
        $store.state.coursePage < 0 &&
          $store.state.coursePage > -3 &&
          $store.state.userDataIsFetched
      "
      :contents="contents"
      :lessons="lessons"
      @lectureChanged="getShownCourses()"
      @initializeCourse="initializeCourse()"
    ></app-courses-contents>

    <template v-if="$store.state.userDataIsFetched">
      <div v-for="(section, index1) in recentCourse" :key="index1">
        <component
          v-for="(item, index2) in section"
          :key="item.key"
          :is="'app-course-' + item.type"
          :content="item"
          :section="index1"
          :page="index2"
          :isShown="
            pageOfCourse(index1, index2) === $store.state.coursePage ||
              pageOfCourse(index1, index2) === $store.state.coursePage - 1 ||
              true
          "
          :exerciseIsReset="
            (pageOfCourse(index1, index2) === $store.state.coursePage &&
              exerciseIsReset) ||
              wholeCourseIsReset
          "
          :userDataIsFetched="$store.state.userDataIsFetched"
          v-show="
            pageOfCourse(index1, index2) === $store.state.coursePage ||
              pageOfCourse(index1, index2) === $store.state.coursePage - 1 ||
              true
          "
          @changedToPrerequisiteCourse="
            if ($event[0] !== 'noNew') {
              $store.state.courseWasReachedFrom.push($event[0]);
            }
            prepareCourse($event[1]);
          "
        >
          <!-- had also: @mouseup="playClickSound()" -->
        </component>
      </div>

      <app-table-of-contents
        :prerequisites="recentCourse[0][0].prerequisites"
        :contents="getTOCContents"
        v-if="TOCisShown()"
      ></app-table-of-contents>
    </template>

    <div
      class="course__footer"
      :class="{ 'course__footer--no-transition': transitionIsSuspended }"
      v-show="
        $store.state.coursePage >= 1 &&
          $store.state.coursePage < $store.state.numberOfPages - 1
      "
    >
      <button
        @mousedown="handleFooterButton($event, '--')"
        @touchstart="handleFooterButton($event, '--')"
        class="course__button course__button--left button"
      >
        <span> &#x2039; </span>
      </button>
      <button
        @mousedown="handleFooterButton($event, 'reset')"
        @touchstart="handleFooterButton($event, 'reset')"
        class="course__button course__button--refresh button"
      >
        <app-icon
          icon="refresh"
          :stroke="1.5"
          :iconWidth="1.64"
          class="course__button-icon"
        />
      </button>
      <button
        @mousedown="handleFooterButton($event, '++')"
        @touchstart="handleFooterButton($event, '++')"
        class="course__button course__button--right button"
      >
        <span> &#x203A; </span>
      </button>
    </div>

    <app-feedback></app-feedback>

    <app-footer
      v-if="
        $store.state.coursePage < 0 && $route.path.split('/')[1] === 'course'
      "
      class="course__contents-footer"
    ></app-footer>
  </div>
</template>

<script>
import AppCoursesContents from "@/components/Templates/CoursesContents.vue";
import AppCourseStart from "@/components/Views/CourseStart.vue";
import AppCourseCalculation from "@/components/Views/Calculation.vue";
import AppCourseCloze from "@/components/Views/Cloze.vue";
import AppCourseDraganddrop from "@/components/Views/DragAndDrop.vue";
import AppCourseDrawing from "@/components/Views/Drawing.vue";
import AppCourseInformation from "@/components/Views/Information.vue";
import AppCourseMultiplechoice from "@/components/Views/MultipleChoice.vue";
import AppCoursePicture from "@/components/Views/Picture.vue";
import AppCourseZipper from "@/components/Views/Zipper.vue";
import AppCourseEnd from "@/components/Views/CourseEnd.vue";
import AppCourseVoid from "@/components/Views/Void.vue";
import AppIcon from "@/components/Atoms/Icons/Icon.vue";
import AppTableOfContents from "@/components/Organisms/TableOfContents.vue";
import AppFeedback from "@/components/Organisms/Feedback.vue";
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "courses",
  data() {
    return {
      // audioWasInitialized: false,
      course: {},
      contents: {},
      lessons: {},
      allCourses: {},
      userDataCourses: {},
      shownCourses: [],
      referralList: {},
      exerciseIsReset: false,
      wholeCourseIsReset: false,
      lessonWasChanged: false,

      transitionIsSuspended: false,

      // Precaching
      maximumNumberOfPrecachedLessons: 100,
      precachedLessons: {},
      orderOfPrecachedLessons: []
      // clickSoundWasRequested: false
    };
  },
  components: {
    AppCoursesContents,
    AppCourseStart,
    AppCourseCalculation,
    AppCourseCloze,
    AppCourseDraganddrop,
    AppCourseDrawing,
    AppCourseInformation,
    AppCourseMultiplechoice,
    AppCoursePicture,
    AppCourseZipper,
    AppCourseEnd,
    AppCourseVoid,
    AppIcon,
    AppTableOfContents,
    AppFeedback,
    AppFooter

    // AppCourseExplanation1,
  },
  computed: {
    coursePage() {
      return this.$store.state.coursePage;
    },
    selectedSection() {
      return this.$store.state.selectedSection;
    },
    lecture() {
      return this.$store.state.chosenLecture;
    },
    contentsOfLecture() {
      return this.$store.state.contentsOfLecture;
    },
    lesson() {
      return this.$store.state.chosenLesson;
    },
    triggerPreparationOfCourse() {
      return this.$store.state.triggerPreparationOfCourse;
    },
    courseWasStarted() {
      return this.o([
        this.$store.state.userDataLectures,
        this.$store.state.chosenLesson,
        "started",
        false
      ]);
    },
    courseIsFinished() {
      return this.$store.state.courseIsFinished;
    },
    /* recentCourse() {
      if (this.precachedLessons[this.$store.state.chosenLesson] !== undefined) {
        let courseData = this.precachedLessons[this.$store.state.chosenLesson]
          .pages;
        let returnCourse = [];
        for (let i = 0; i < courseData.length + 1; i++) {
          if (i === this.$store.state.coursePage) {
            returnCourse[i] = courseData[i];
          } else {
            returnCourse[i] = [{ type: "void" }];
          }
        }
        return returnCourse;
      } else {
        return [];
      }
    }, */
    recentCourse() {
      if (this.precachedLessons[this.$store.state.chosenLesson] !== undefined) {
        let courseData = this.precachedLessons[this.$store.state.chosenLesson]
          .pages;
        let returnCourse = [[]];

        for (let i = 0; i < courseData.length; i++) {
          returnCourse[i] = [];
          for (let j = 0; j < courseData[i].length; j++) {
            if (
              (i === 0 && j === 0 && this.$store.state.coursePage === 0) ||
              (i === this.$store.state.numberOfSections + 1 &&
                j === 0 &&
                this.$store.state.coursePage ===
                  this.$store.state.numberOfPages - 1) ||
              (this.$store.state.recentSection === i &&
                this.$store.state.pageNumberInSection[
                  this.$store.state.coursePage
                ] === j)
            ) {
              returnCourse[i][j] = courseData[i][j];
            } else {
              returnCourse[i][j] = { type: "void" };
            }
          }
        }
        return returnCourse;
      } else {
        return [[{ type: "void" }]];
      }
    },
    recentCourseVersion() {
      let courseVersion = 0;
      if (this.precachedLessons[this.$store.state.chosenLesson] !== undefined) {
        if (
          "version" in this.precachedLessons[this.$store.state.chosenLesson]
        ) {
          courseVersion = this.precachedLessons[this.$store.state.chosenLesson]
            .version;
        } else {
          courseVersion = 1;
        }
      }
      return courseVersion;
    },
    getTOCContents() {
      let contentsArray = [];
      if (this.precachedLessons[this.$store.state.chosenLesson] !== undefined) {
        for (
          let i = 0;
          i <
          this.precachedLessons[this.$store.state.chosenLesson].pages.length -
            2;
          i++
        ) {
          if (
            this.precachedLessons[this.$store.state.chosenLesson].pages[
              i + 1
            ][0].TOCitem !== undefined
          ) {
            contentsArray[i] = this.precachedLessons[
              this.$store.state.chosenLesson
            ].pages[i + 1][0].TOCitem;
          } else {
            contentsArray[i] = this.precachedLessons[
              this.$store.state.chosenLesson
            ].pages[i + 1][0].heading;
          }
        }
      }

      return contentsArray;
    },
    goToLesson() {
      return this.$store.state.goToLesson;
    },
    userDataLectures() {
      return JSON.stringify(this.$store.state.userDataLectures);
    },
    triggerPrecacheOfLessons() {
      return this.$store.state.triggerPrecacheOfLessons;
    },
    userDataIsFetched() {
      return this.$store.state.userDataIsFetched;
    }
  },
  methods: {
    getCourseItems() {
      if (this.precachedLessons[this.$store.state.chosenLesson] !== undefined) {
        let courseData = this.precachedLessons[this.$store.state.chosenLesson]
          .pages;
        this.$store.state.courseData = courseData;
        return courseData;
      } else {
        this.$store.state.courseData = [];
        return [];
      }
    },
    /* getVersionOfRecentCourse() {
      this.a(this.recentCourseVersion);
      this.$store.state.chosenLessonVersion = this.recentCourseVersion;
    }, */
    TOCisShown() {
      return this.allCourses[this.$store.state.contentsOfLecture] !== undefined
        ? true
        : false;
    },

    pageOfCourse(index1, index2) {
      if (this.course === []) {
        return 0;
      } else {
        let pageCounter = 0;
        for (let i = 0; i < index1; i++) {
          if (this.course[i] !== undefined) {
            pageCounter += this.course[i].length;
          }
        }
        pageCounter += index2;
        return pageCounter;
      }
    },
    resetExercise() {
      this.exerciseIsReset = true;
      setTimeout(() => {
        this.exerciseIsReset = false;
      }, 600);
    },
    resetWholeCourse() {
      this.wholeCourseIsReset = true;
      setTimeout(() => {
        this.wholeCourseIsReset = false;
      }, 600);
    },
    getContents() {
      fetch("/contents.json")
        .then(r => r.json())
        .then(
          json => {
            this.contents = json;

            /* if (this.$store.state.listOfProgressedLectures.length === 0) {
              this.$store.state.listOfProgressedLectures = this.contents.shownContentsForNewUsers;
            } */

            // Create list of lecture names for store
            let listOfAllLectures = {};

            for (let i = 0; i < this.contents.contents.length; i++) {
              for (
                let j = 0;
                j < this.contents.contents[i].topics.length;
                j++
              ) {
                if ("contents" in this.contents.contents[i].topics[j]) {
                  listOfAllLectures[
                    this.contents.contents[i].topics[j].contents
                  ] = {};
                  if ("subheading" in this.contents.contents[i].topics[j]) {
                    listOfAllLectures[
                      this.contents.contents[i].topics[j].contents
                    ].subheading = this.contents.contents[i].topics[
                      j
                    ].subheading;
                  }
                  /* if ("shortForm" in this.contents.contents[i].topics[j]) {
                    listOfAllLectures[
                      this.contents.contents[i].topics[j].contents
                    ].shortForm = this.contents.contents[i].topics[j].shortForm;
                  } */
                }
              }
            }
            this.$store.state.listOfLectureNames = listOfAllLectures;
          }
          /* response => {
            console.log("Error loading json:", response);
          } */
        );
    },
    getLessons(lecture) {
      let lessons = [];
      for (let i = 0; i < lecture.length; i++) {
        if ("topics" in lecture[i]) {
          for (let j = 0; j < lecture[i].topics.length; j++) {
            if ("subtopics" in lecture[i].topics[j]) {
              for (let k = 0; k < lecture[i].topics[j].subtopics.length; k++) {
                lessons.push(lecture[i].topics[j].subtopics[k].lesson);
              }
            }
          }
        } else {
          lessons = []; // was: [1]
        }
      }
      return lessons;
    },
    getNameOfLesson() {
      let listOfLessonNames = {};

      for (let key in this.lessons) {
        for (let i = 0; i < this.lessons[key].length; i++) {
          if ("topics" in this.lessons[key][i]) {
            for (let j = 0; j < this.lessons[key][i].topics.length; j++) {
              if ("subtopics" in this.lessons[key][i].topics[j]) {
                for (
                  let k = 0;
                  k < this.lessons[key][i].topics[j].subtopics.length;
                  k++
                ) {
                  listOfLessonNames[
                    this.lessons[key][i].topics[j].subtopics[k].lesson
                  ] = this.lessons[key][i].topics[j].subtopics[k].heading;
                }
              }
            }
          }
        }
      }

      this.$store.state.listOfLessonNames = listOfLessonNames;
    },
    getShownCourses() {
      let shownLessons = this.allCourses[this.$store.state.contentsOfLecture];

      if (shownLessons === undefined) {
        return;
      }

      for (let i = 0; i < shownLessons.length; i++) {
        fetch(
          "/contents/" +
            this.$store.state.contentsOfLecture +
            "/" +
            shownLessons[i] +
            ".json"
        )
          .then(r => r.json())
          .then(
            json => {
              this.shownCourses[i] = json;
            }
            /* response => {
              console.log("Error loading json:", response);
            } */
          );
      }
    },
    addKeyToCoursePages(course) {
      for (let i = 0; i < course.pages.length; i++) {
        for (let j = 0; j < course.pages[i].length; j++) {
          course.pages[i][j]["key"] = this.$store.state.courseKey;
          this.$store.state.courseKey++;
        }
      }
      return course;
    },
    precacheLessons(lessons, prepareCourse) {
      if (lessons === undefined || lessons === null) {
        lessons = [];
      }

      let numberOfAlreadyPrecachedElements = 0;
      for (let i = 0; i < lessons.length; i++) {
        if (lessons[i] in this.precachedLessons) {
          numberOfAlreadyPrecachedElements++;
        }
      }

      let numberOfNewElementsToBePrecached =
        lessons.length - numberOfAlreadyPrecachedElements;
      let counterOfNewPrecachedElements = 0;

      for (let i = 0; i < lessons.length; i++) {
        if (lessons[i] in this.precachedLessons) {
          this.orderOfPrecachedLessons.push(
            this.orderOfPrecachedLessons.splice(
              this.orderOfPrecachedLessons.indexOf(lessons[i]),
              1
            )[0]
          );
          continue;
        }

        fetch(
          "/contents/" +
            this.getLessonData(lessons[i]).lecture +
            "/" +
            lessons[i] +
            ".json"
        )
          .then(r => r.json())
          .then(
            json => {
              json = this.addKeyToCoursePages(json);
              this.precachedLessons[lessons[i]] = json;
              this.orderOfPrecachedLessons.push(lessons[i]);
              counterOfNewPrecachedElements++;
              if (
                counterOfNewPrecachedElements ===
                numberOfNewElementsToBePrecached
              ) {
                this.deleteCachedLessons(lessons.length);
                if (prepareCourse) {
                  this.prepareCourse(this.$store.state.chosenLesson, false);
                }
              }
            },
            () => {
              // was: response => { ...
              // console.log("Error loading json:", response);
              counterOfNewPrecachedElements++;
              if (
                counterOfNewPrecachedElements ===
                numberOfNewElementsToBePrecached
              ) {
                this.deleteCachedLessons(lessons.length);
                if (prepareCourse) {
                  this.prepareCourse(this.$store.state.chosenLesson, false);
                }
              }
            }
          );
      }
    },
    deleteCachedLessons(numberOfAddedElements) {
      let numberOfNewElements = Math.max(
        this.maximumNumberOfPrecachedLessons,
        numberOfAddedElements
      );
      let numberOfElementsToBeDeleted = Math.max(
        this.orderOfPrecachedLessons.length - numberOfNewElements,
        0
      );

      for (let i = 0; i < numberOfElementsToBeDeleted; i++) {
        delete this.precachedLessons[this.orderOfPrecachedLessons[i]];
      }
      this.orderOfPrecachedLessons.splice(0, numberOfElementsToBeDeleted);
    },
    getContentsOfLecture(lecture) {
      for (let i = 0; i < this.contents.contents.length; i++) {
        if ("topics" in this.contents.contents[i]) {
          for (let j = 0; j < this.contents.contents[i].topics.length; j++) {
            if (this.contents.contents[i].topics[j].contents === lecture) {
              return this.contents.contents[i].topics[j].subheading;
            }
          }
        }
      }
    },
    getLessonData(lesson) {
      for (let key in this.lessons) {
        for (let i = 0; i < this.lessons[key].length; i++) {
          if ("topics" in this.lessons[key][i]) {
            for (let j = 0; j < this.lessons[key][i].topics.length; j++) {
              if ("subtopics" in this.lessons[key][i].topics[j]) {
                for (
                  let k = 0;
                  k < this.lessons[key][i].topics[j].subtopics.length;
                  k++
                ) {
                  if (
                    this.lessons[key][i].topics[j].subtopics[k].lesson ===
                    lesson
                  ) {
                    let lecture = key;
                    let topicGroup = this.lessons[lecture][i].heading;
                    let topic = this.lessons[lecture][i].topics[j].subheading;
                    let restricted = this.lessons[lecture][i].topics[j]
                      .subtopics[k].restricted;
                    let lessonData = {
                      lecture,
                      topicGroup,
                      topic,
                      restricted
                    };
                    return lessonData;
                  }
                }
              }
            }
          }
        }
      }
      return { lecture: "", topicGroup: "", topic: "" };
    },
    getReferralList() {
      let prerequisiteList = {};
      let referralList = {};
      for (let key in this.lessons) {
        for (let i = 0; i < this.lessons[key].length; i++) {
          if ("topics" in this.lessons[key][i]) {
            for (let j = 0; j < this.lessons[key][i].topics.length; j++) {
              if ("subtopics" in this.lessons[key][i].topics[j]) {
                for (
                  let k = 0;
                  k < this.lessons[key][i].topics[j].subtopics.length;
                  k++
                ) {
                  prerequisiteList[
                    this.lessons[key][i].topics[j].subtopics[k].lesson
                  ] =
                    this.lessons[key][i].topics[j].subtopics[k].prerequisites ||
                    [];
                  referralList[
                    this.lessons[key][i].topics[j].subtopics[k].lesson
                  ] = [];
                }
              }
            }
          }
        }
      }

      for (let key in prerequisiteList) {
        for (let i = 0; i < prerequisiteList[key].length; i++) {
          referralList[prerequisiteList[key][i]].push(key);
        }
      }

      this.referralList = referralList;
    },
    prepareCourse(lesson, pushNewUrl) {
      if (pushNewUrl === undefined) {
        pushNewUrl = true;
      }

      this.$store.state.chosenLesson = lesson;
      this.$store.state.headingOfChosenLesson = this.$store.state.listOfLessonNames[
        lesson
      ];
      let lessonData = this.getLessonData(lesson);
      this.$store.state.topicGroup = lessonData.topicGroup; // 1
      localStorage.setItem("topicGroup", JSON.stringify(lessonData.topicGroup));
      this.$store.state.topic = lessonData.topic;
      localStorage.setItem("topic", JSON.stringify(lessonData.topic));
      this.$store.state.isRestricted = lessonData.restricted;

      this.$store.state.contentsOfLecture = this.getLessonData(lesson).lecture;
      this.$store.state.chosenLecture = this.getContentsOfLecture(
        this.$store.state.contentsOfLecture
      );

      if (pushNewUrl) {
        let state = { page: this.$store.state.coursePage };
        const title = "";
        let url =
          "/course/#/" +
          this.$store.state.contentsOfLecture +
          "/" +
          this.$store.state.chosenLesson +
          "/" +
          "start";
        history.pushState(state, title, url);
      }

      this.course = this.getCourseItems();
      this.$store.dispatch("initializeCourse", this.getCourseItems());
      this.$store.dispatch("updateCourse");
      this.typesetMath();

      this.$store.state.chosenLessonVersion = this.recentCourseVersion;

      scroll(0, 0);

      // Set local storage
      localStorage.setItem(
        "chosenLecture",
        JSON.stringify(this.$store.state.chosenLecture)
      );
      localStorage.setItem(
        "contentsOfLecture",
        this.$store.state.contentsOfLecture
      );
      localStorage.setItem("chosenLesson", lesson);
      localStorage.setItem(
        "headingOfChosenLesson",
        JSON.stringify(this.$store.state.listOfLessonNames[lesson])
      );

      // this.clickSoundWasRequested = true;

      /* if (
        this.o([
          this.$store.state.userDataLectures,
          this.$store.state.chosenLesson,
          "started",
          false
        ]) === true
      ) {
        setTimeout(() => {
          this.toggleModal("app-resume-course", "resumecourse");
        }, 450); // was 150
      } */
    },
    initializeCourse() {
      setTimeout(() => {
        this.course = this.getCourseItems();
        this.$store.dispatch("initializeCourse", this.getCourseItems());
      }, 100); // probably change value 100
    },
    /* playClickSound() {
      if (this.$store.state.coursePage === 0 && this.clickSoundWasRequested) {
        this.clickSoundWasRequested = false;
        this.playAudio("click", 0.2);
      }
    }, */
    handleFooterButton(e, action) {
      if (typeof window.ontouchstart != "undefined" && e.type == "mousedown") {
        return;
      }
      if (action === "--") {
        this.setPage("--");
      } else if (action === "++") {
        this.setPage("++");
      } else {
        this.resetExercise();
      }
    },
    orientationChange() {
      this.transitionIsSuspended = true;
      setTimeout(() => {
        this.transitionIsSuspended = false;
      }, 1000);
    }
    /* createUserDataLectures() {
      let userDataLectures = {
        lesson2: {
          finished: true,
          score: 15,
          totalScore: 43,
          started: false,
          newScore: 0,
          newTotalScore: 48
        }
      };
      this.$store.state.userDataLectures = userDataLectures;
    } */
  },
  created() {
    // this.createUserDataLectures();
    // let vm = this;
    /* this.setPage(-2);
    setTimeout(() => {
      // this.setPage(-1);
      setTimeout(() => {
        this.setPage(1);
      }, 1000);
    }, 3000); */

    this.getContents();

    fetch("/lessons.json")
      .then(r => r.json())
      .then(
        json => {
          this.lessons = json;
          this.getNameOfLesson();
          this.getReferralList();

          /* setTimeout(() => {
            this.getVersionOfRecentCourse();
          }, 3000); */

          for (let lecture in this.lessons) {
            this.allCourses[lecture] = this.getLessons(this.lessons[lecture]);

            for (
              let i = 0;
              i < this.getLessons(this.lessons[lecture]).length;
              i++
            ) {
              this.userDataCourses[
                this.getLessons(this.lessons[lecture])[i]
              ] = {};
              this.$store.state.lessons = this.lessons;
            }
          }
          // // // this.a(this.userDataCourses);
          this.$store.state.userDataLectures = this.userDataCourses;
        }
        /* response => {
          console.log("Error loading json:", response);
        } */
      );

    this.course = [0];
    this.$store.dispatch("initializeCourse", this.course);

    setTimeout(() => {
      if (this.$store.state.coursePage >= 0) {
        this.getShownCourses();

        setTimeout(() => {
          this.course = this.getCourseItems();
          this.$store.dispatch("initializeCourse", this.getCourseItems());
          this.$store.dispatch("updateCourse");

          let lessonData = this.getLessonData(this.$store.state.chosenLesson);
          this.$store.state.topicGroup = lessonData.topicGroup;
          this.$store.state.topic = lessonData.topic;
          this.$store.state.isRestricted = lessonData.restricted;

          let prerequisites = [];
          if (
            Array.isArray(this.course) &&
            this.course.length > 0 &&
            Array.isArray(this.course[0]) &&
            this.course[0].length > 0 &&
            "prerequisites" in this.course[0][0]
          ) {
            prerequisites = this.course[0][0].prerequisites;
          }
          this.precacheLessons(prerequisites);
        }, 50);
      }
    }, 500);

    setTimeout(() => {
      this.precacheLessons(
        this.allCourses[this.$store.state.contentsOfLecture]
      );
      setTimeout(() => {
        this.course = this.getCourseItems();
        this.$store.dispatch("initializeCourse", this.getCourseItems());
        this.typesetMath();
      }, 500);
    }, 500);

    setTimeout(() => {
      this.getShownCourses();
    }, 100);

    setTimeout(() => {
      this.$store.state.courseComponentCreated = true;
    }, 500);

    window.addEventListener("orientationchange", this.orientationChange);
  },
  watch: {
    coursePage(to, from) {
      /* if (to === -1 || to === 0) {
        if (this.$store.state.courseComponentCreated) {
          this.typesetMath();
        }
      } */
      this.typesetMath();
      this.clearAllTimeouts();
      this.clearAudio();
      this.$store.state.coursePageRequestedViaTOC = 0;

      // if (to >= 0) {
      this.scrollToTop();
      // }

      if (to < 0) {
        this.$store.state.courseWasLeftOnce = true;
      } else if (to > 0) {
        this.$store.state.courseWasLeftOnce = false;
      }

      if (to === -1) {
        this.precacheLessons(
          this.allCourses[this.$store.state.contentsOfLecture]
        );
      }

      if (to === 0 && this.$store.state.courseComponentCreated) {
        this.course = this.getCourseItems();
        this.$store.dispatch("initializeCourse", this.getCourseItems());

        let prerequisites = [];
        if (
          Array.isArray(this.course) &&
          this.course.length > 0 &&
          Array.isArray(this.course[0]) &&
          this.course[0].length > 0 &&
          "prerequisites" in this.course[0][0]
        ) {
          prerequisites = this.course[0][0].prerequisites;
        }
        this.precacheLessons(prerequisites);
      }

      if (to >= 0 && this.$store.state.courseComponentCreated) {
        this.$store.dispatch("updateCourse");
      }

      if (to === 0 && from === -1) {
        let lessonData = this.getLessonData(this.$store.state.chosenLesson);
        this.$store.state.topicGroup = lessonData.topicGroup; // 2
        localStorage.setItem(
          "topicGroup",
          JSON.stringify(lessonData.topicGroup)
        );
        this.$store.state.topic = lessonData.topic;
        localStorage.setItem("topic", JSON.stringify(lessonData.topic));
        this.$store.state.isRestricted = lessonData.restricted;

        /* this.$store.dispatch("getFirstUnprocessedPage");
        if (this.$store.state.courseWasStarted) {
          setTimeout(() => {
            this.toggleModal("app-onboarding", "continuecourse");
          }, 150);
        } */
      }

      /* if (to >= 1 && (from === -1 || from === 0)) {
        this.typesetMath();
      } */
      /* if (to >= 1) {
        this.typesetMath();
      } */

      if (to < 0) {
        this.$store.state.TOCisOpen = false;
      }

      this.$store.state.chosenLessonVersion = this.recentCourseVersion;

      if (to > 0) {
        this.$store.state.courseWasReachedFrom = [
          this.$store.state.courseWasReachedFrom[0]
        ];
      }
    },
    lesson(to) {
      this.scrollToTop();
      this.lessonWasChanged = true;
      setTimeout(() => {
        this.lessonWasChanged = false;
      }, 1000);

      if (this.$store.state.courseComponentCreated) {
        this.precacheLessons(this.referralList[to]);
      }
    },
    triggerPreparationOfCourse() {
      let newLecture = this.$route.hash.split("/")[1];
      let newLesson = this.$route.hash.split("/")[2];

      if (newLecture === undefined || newLesson === undefined) {
        return;
      }

      this.$store.state.contentsOfLecture = newLecture;
      this.$store.state.chosenLesson = newLesson;

      if (!(newLesson in this.precachedLessons)) {
        this.precacheLessons([newLesson], true);
        return;
      }

      if (this.$store.state.coursePage === 0) {
        /* this.clickSoundWasRequested = true;
        this.playClickSound();
        setTimeout(() => {
          this.clickSoundWasRequested = false;
        }, 50); */

        this.prepareCourse(this.$store.state.chosenLesson, false);
      }
    },
    goToLesson(to) {
      if (to === "") {
        return;
      }
      this.prepareCourse(to);
      this.setPage(0);
      this.goTo(
        "/course/#/" + this.$store.state.contentsOfLecture + "/" + to + "/start"
      );
      this.$store.state.goToLesson = "";
    },
    exerciseIsReset(to) {
      if (to) {
        this.typesetMath(500);
      }
    },
    courseWasStarted(to) {
      if (to === "finished") {
        return;
      }
      if (to) {
        if (
          !(
            this.$store.state.chosenLesson in this.$store.state.userDataLectures
          )
        ) {
          this.$store.state.userDataLectures[
            this.$store.state.chosenLesson
          ] = {};
        }
        this.$store.state.userDataLectures[
          this.$store.state.chosenLesson
        ].started = true;
      }
      /* alert(
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .finished
      ); */
    },
    courseIsFinished(to) {
      // alert(to);
      if (to) {
        if (
          !(
            this.$store.state.chosenLesson in this.$store.state.userDataLectures
          )
        ) {
          this.$store.state.userDataLectures[
            this.$store.state.chosenLesson
          ] = {};
        }
        this.$store.state.userDataLectures[
          this.$store.state.chosenLesson
        ].finished = true;
        this.$store.state.userDataLectures[
          this.$store.state.chosenLesson
        ].started = "finished";
        /* this.a(
          this.o([
            this.$store.state.userDataLectures,
            this.$store.state.chosenLesson,
            "started",
            false
          ])
        ); */
      }
    },
    /* userDataLectures: {
      handler() {
        // alert(to === from);
        // console.log(1);
        this.$store.dispatch("saveUserDataOnDatabase", this);
      },
      deep: true
    } */
    userDataLectures() {
      this.$store.dispatch("saveUserDataOnDatabase");
    },
    triggerPrecacheOfLessons(to) {
      if (to.length > 0) {
        this.precacheLessons(this.$store.state.triggerPrecacheOfLessons);
      }
    },
    userDataIsFetched() {
      if (!this.$store.state.redirectAfterUserDataIsFetched[0]) {
        return;
      }

      this.$store.state.spinnerIsShown = false;
      let course = document.querySelector(".course");
      let internalNavigation = document.querySelector(
        ".header__internal-navigation-container"
      );
      setTimeout(() => {
        this.$store.state.coursePage++;
        setTimeout(() => {
          this.$store.state.coursePage++;
          setTimeout(() => {
            let redirectionPage = this.$store.state
              .redirectAfterUserDataIsFetched[1];
            if (redirectionPage === "start") {
              redirectionPage = 1;
            } else if (redirectionPage === "finish") {
              redirectionPage = this.$store.state.numberOfPages - 1;
            } else {
              redirectionPage = parseInt(redirectionPage);
            }

            this.$store.state.coursePage = redirectionPage;

            if (
              this.$store.state.redirectAfterUserDataIsFetched[1] === "start"
            ) {
              setTimeout(() => {
                this.$store.state.coursePage = 0;

                setTimeout(() => {
                  course.style.visibility = "visible";
                  internalNavigation.style.visibility = "visible";
                  this.$store.state.breadcrumbNavigationIsShown = true;
                }, 0);
              }, 0);
            } else {
              setTimeout(() => {
                course.style.visibility = "visible";
                internalNavigation.style.visibility = "visible";
                this.$store.state.breadcrumbNavigationIsShown = true;
              }, 0);
            }
          }, 0);
          setTimeout(() => {
            this.$store.dispatch("updateCourse");
          }, 0);
        }, 0);
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.course {
  width: 100%;
  min-height: calc(100vh - 6rem);
  color: $color-white;
  // position: fixed;

  background-color: #131313;

  @media (pointer: coarse) {
    background-color: $color-black;
  }

  @include respond(tab-port) {
    // padding: 0 3rem;
    // background-color: green;
  }

  @include respond(phone) {
    // padding: 0 1.5rem;
    // background-color: blue;
  }

  &__contents-footer {
    background-color: $color-black;

    @include respond(phone) {
      // margin-top: 5.5rem;
      // background-color: #131313;
      // background-color: red;
      // background-color: #1a1a1a;

      padding-bottom: 10rem;
      background-color: #131313;
    }
  }

  &__footer {
    position: fixed;
    left: 0;
    // bottom: 0;
    // top: calc(100vh - 9rem);
    top: 0;
    // border-bottom: 1rem solid $color-black !important;
    transform: translateZ(2px) translateY(calc(var(--vh, 1vh) * 100 - 6rem)); // was: - 9rem
    z-index: 1000000;
    width: 100%;
    height: 6.1rem;
    border-top: 1px solid #1d1d1d;
    background-color: $color-black;
    // filter: blur(5px);
    // background-color: green;

    @include respond(phone) {
      bottom: 2.25rem !important; // ???
      transform: translateZ(2px) translateY(calc(var(--vh, 1vh) * 100 - 6.7rem));
      height: 6.75rem;
      border-top: max(0.15rem, 1px) solid #222;
      // border-bottom: 1px solid #2c2c2c !important; // !!!

      // will-change: top;
      // transition: top 0.75s;
      will-change: transform;
      transition: transform 0.75s;

      &::after {
        // @include pseudo-element;
        top: calc(6.75rem - 1px);
        width: 100vw;
        // height: 2.8rem;
        height: 30rem;
        background-color: #131313;
      }
    }

    &--no-transition {
      transition: none;
    }
  }

  &__button {
    @include button-settings;
    position: absolute;
    top: 50%;
    width: 4.65rem;
    height: 4.5rem;
    border: 1px solid #333;
    font-family: $font-primary;
    font-size: 4.5rem;
    line-height: 1;
    font-weight: 100;
    color: $color-white;
    background-color: $color-black;

    transition: background-color 0.2s;
    cursor: pointer;

    @include respond(phone) {
      // top: 55%;
      width: 4.91rem;
      height: 4.75rem;
      font-size: 4.75rem;
    }

    & span {
      display: inline-block;
      transform: translateY(-0.25rem);

      @include respond(phone) {
        transform: translateY(-0.264rem);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $color-white;
        background-color: #222;
      }

      &:active {
        // background-color: $color-secondary-dark;
      }
    }

    /* &:hover {
      color: $color-white;
      background-color: #222;
    } */

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &--left {
      left: 50%;
      transform: translate(calc(-100% + 0.5px - 2.3rem), -50%);
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;

      @include respond(phone) {
        transform: translate(calc(-100% + 0.5px - 2.428rem), -50%);
      }
    }

    &--right {
      right: 50%;
      transform: translate(calc(100% - 0.5px + 2.3rem), -50%);
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;

      @include respond(phone) {
        transform: translate(calc(100% - 0.5px + 2.428rem), -50%);
      }
    }

    &--refresh {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:focus.button:not(:active)::after {
      @include pseudo-element;
      position: absolute;
      top: -0.1rem;
      // // // border: 1.5px solid $color-primary-light-1;
    }

    &--left:focus.button:not(:active)::after {
      left: -0.25rem;
      width: 4.6rem;
      height: calc(4.5rem - 0.5px);
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
      border-top-right-radius: 3rem;
      border-bottom-right-radius: 3rem;
    }

    &--right:focus.button:not(:active)::after {
      right: -0.25rem;
      width: 4.6rem;
      height: calc(4.5rem - 0.5px);
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;
    }

    &--refresh:focus.button:not(:active)::after {
      width: 4.4rem;
      height: calc(4.5rem - 0.5px);
      // // // border-radius: $global-border-radius;
    }
  }

  &__button-icon {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(1.475rem, 1.39rem) scaleX(-1) rotate(256deg);
    width: 1.64rem;
    height: 1.64rem;

    @include respond(phone) {
      transform: translate(1.475rem, 1.467rem) scaleX(-1) rotate(256deg);
      width: 1.73rem;
      height: 1.73rem;
    }
  }
}
</style>
