<template>
  <div
    :style="'width:' + imgData.width + 'px; height:' + imgData.height"
    class="image"
  >
    <div
      :style="
        'transform: translate(calc(-50% + ' +
          imgData.position[0] +
          'px), calc(-50% + ' +
          imgData.position[1] +
          'px)) scale(' +
          imgData.scale +
          ')'
      "
      class="image__picture"
    >
      <div v-html="svg" class="image__svg"></div>
      <template v-for="(arrow, index) in arrows" :key="arrow + 'And' + index">
        <div
          class="image__arrow-container"
          :class="'image__arrow-container--' + index"
          :style="{
            left: arrow.x + 'px',
            top: arrow.y + 'px',
            transform: 'rotate(' + -arrow.angle + 'deg)'
          }"
        >
          <div
            class="image__arrow"
            :class="'image__arrow--' + index"
            :style="{
              height: arrow.length + 'px',
              'background-color': getArrowColor(arrow.color)
            }"
          ></div>
          <svg
            width="9.51074"
            height="8.54754"
            viewBox="0 0 9.51074 8.54754"
            class="image__arrow-tip"
            :class="'image__arrow-tip--' + index"
          >
            <g>
              <path
                d="M4.75555,0A30.222,30.222,0,0,1,0,8.54754L4.7558,6.82715l4.75494,1.72A30.19626,30.19626,0,0,1,4.75555,0Z"
                :style="{
                  fill: getArrowColor(arrow.color)
                }"
              />
            </g>
          </svg>
        </div>
      </template>
      <span
        v-for="(caption, index) in imageText"
        :key="caption + 'andIndex' + index"
        :style="
          'left:' +
            caption.position[0] +
            'px; top:' +
            caption.position[1] +
            'px; color:' +
            getColor(caption.color) +
            '; transform: rotate(' +
            caption.rotation +
            'deg); font-size:' +
            caption.fontSize +
            'px'
        "
        class="image__text"
      >
        {{ t(caption.text) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "image",
  props: {
    svg: {
      type: String,
      default: ""
    },
    imageData: {
      type: Object,
      default: () => ({})
    },
    arrows: {
      type: Array,
      default: () => []
    },
    imageText: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    imgData() {
      // let data = this.imageData;
      let data = JSON.parse(JSON.stringify(this.imageData));
      if (!("position" in data)) {
        data["position"] = [0, 0];
      }
      if (!("width" in data)) {
        data["width"] = 0;
      }
      if (!("height" in data)) {
        data["height"] = "100%";
      } else {
        data["height"] += "px";
      }
      if (!("scale" in data)) {
        data["scale"] = 1;
      }

      return data;
    }
  },
  methods: {
    getColor(color) {
      if (color !== undefined) {
        return color;
      } else {
        return "white";
      }
    },
    getArrowColor(color) {
      if (color !== undefined) {
        return color;
      } else {
        return "red";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.image {
  position: relative;
  // width: calc(#{$width-center} - 0rem) !important;
  width: min(#{$width-center}, 100vw) !important;

  // background-color: $color-black;
  // overflow: visible !important;
  // background-color: green;

  @include respond(tab-port) {
    // width: calc(100vw - 3rem) !important;
    width: calc(100vw - 6rem) !important;
  }

  @include respond(phone) {
    width: 100vw !important;
  }

  &__picture {
    position: absolute;
    left: 50%;
    top: 50%;

    // background-color: pink;

    @include respond(phone) {
      //  transform: none !important;
    }
  }

  &__arrow-container {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__arrow {
    position: absolute;
    left: 4.25px;
    top: 2.5px;
    width: 1.5px;
    height: 20px;
    border-radius: 100rem;
    background-color: red;
  }

  &__arrow-tip {
    position: absolute;
    transform: scale(0.6);
  }

  &__text {
    position: absolute;
    font-size: 20px;
    line-height: 1;
  }
}
</style>
