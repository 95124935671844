<template>
  <div class="calculation">
    <h2 class="calculation__heading">
      {{ t(content.heading) }}
    </h2>
    <span class="calculation__introduction-heading">
      {{ t(content.instructionsHeading) }}
    </span>
    <p class="calculation__introduction">
      {{ t(content.instructions) }}
    </p>
    <span
      class="calculation__introduction-heading calculation__introduction-units"
    >
      {{ t(content.instructionsUnits) }}
    </span>

    <div class="calculation__instructions-container">
      <div class="calculation__icon-container">
        <img
          src="@/assets/Internal/Course/Calculation/pen6.svg"
          alt="A pen icon"
          class="calculation__icon"
        />
      </div>
      <div class="calculation__instructions" style="white-space: pre-line;">
        <span class="calculation__instructions-start"
          >{{ $t("course_calculation").exercise }}:</span
        >
        {{ t(content.exercise) }}
      </div>
      <!-- <span class="calculation__show-hints"
        >Für Tipps klicke unten auf die Glühbirne.</span
      > -->
      <span
        v-if="aHintIsShown || solutionHintsAreShown"
        class="calculation__solution-hints"
      >
        {{ $t("course_calculation").hints }}:
      </span>
      <div
        v-for="(hint, index) in content.hints"
        :key="hint + 'andIndex' + index"
        :style="
          'height:' + hintHeight[index] + '; overflow:' + hintVisibility[index]
        "
        class="calculation__hint-outer-container"
        :class="'calculation__hint-outer-container--' + index"
      >
        <div
          class="calculation__hint-container"
          :class="[
            {
              'calculation__hint-container--with-border':
                hintState[index + 1] === 2
            },
            'calculation__hint-container--' + index
          ]"
          style="white-space: pre-line;"
        >
          <div
            v-if="hintState[index] === 2 || true"
            class="calculation__icon-container calculation__icon-container--bulb calculation__icon-container--clear"
          >
            <span class="calculation__number">{{ index + 1 }}</span>
          </div>
          <p
            v-if="hintState[index] === 2 || true"
            class="calculation__hint-paragraph calculation__hint-paragraph--hint"
            :class="'calculation__hint-paragraph--' + index"
          >
            {{ hint.tip }}
          </p>
          <p
            v-if="hintState[index] === 2 || true"
            class="calculation__hint-paragraph"
          >
            <span
              @click="showHintSolution(index)"
              class="calculation__show-step-solution"
              :class="{
                'calculation__hide-step-solution': hintSolutionIsShown[index]
              }"
              >{{
                hintSolutionIsShown[index]
                  ? $t("course_calculation").hide_solution
                  : $t("course_calculation").show_solution
              }}
            </span>
            <span
              :style="'height:' + hintSolutionHeight[index]"
              class="calculation__hint-solution"
              :class="'calculation__hint-solution--' + index"
            >
              <span :class="'calculation__hint-solution-span--' + index">{{
                t(hint.solution)
              }}</span>
            </span>
          </p>
        </div>
      </div>
      <div
        class="calculation__solution"
        :class="{ 'calculation__solution--is-shown': solutionIsShown }"
      >
        <span class="calculation__hint"
          >{{ $t("course_calculation").result }}:</span
        >
        <p class="calculation__result">{{ content.result }}</p>
      </div>
    </div>
    <div class="calculation__icon-outer-container">
      <div class="calculation__hints">
        <span class="calculation__hints-tips">
          {{ $t("course_calculation").tips }}:
        </span>
      </div>
      <!-- <div class="calculation__icon-container calculation__icon-container--1">
        <img
          src="@/assets/Internal/Course/Calculation/bulbFull2.svg"
          alt="A pen icon"
          class="calculation__icon calculation__icon--bulb calculation__icon--bulb-1"
        />
        Tipps:
      </div> -->
      <div
        v-if="notepadIsShown"
        class="calculation__icon-container calculation__icon-container--2"
      >
        <img
          src="@/assets/Internal/Course/Calculation/notepad5.svg"
          alt="A pen icon"
          class="calculation__icon calculation__icon--notepad"
        />
      </div>
      <div
        v-if="calculatorIsShown"
        @click="calculatorIsDisplayed = !calculatorIsDisplayed"
        class="calculation__icon-container calculation__icon-container--3"
      >
        <img
          src="@/assets/Internal/Course/Calculation/calculator.svg"
          alt="A pen icon"
          class="calculation__icon calculation__icon--calculator"
        />
      </div>
      <div
        v-for="(hint, index) in content.hints"
        :key="hint + 'andAgainIndex' + index"
        @click="showHint(index)"
        class="calculation__icon-container calculation__icon-container--4"
        :class="{
          'calculation__icon-container--inactive': hintState[index] === 0,
          'calculation__icon-container--selected': hintState[index] === 2
        }"
      >
        <!-- <img
          src="@/assets/Internal/Course/Calculation/bulbFull2.svg"
          alt="A pen icon"
          class="calculation__icon calculation__icon--bulb"
        /> -->
        <span class="calculation__bulb-caption">{{ index + 1 }}</span>
      </div>
    </div>

    <div class="calculation__button-container">
      <app-button
        @click="showSolution()"
        size="big"
        :text="$t('course_calculation').result"
        class="calculation__button calculation__button--result"
        :class="{ 'calculation__button--result-finished': exerciseIsFinished }"
      ></app-button>
      <app-button
        @click="setPage('++')"
        @touchstart="setPage('++')"
        :text="$t('course_continue_button')"
        size="big"
        class="calculation__button calculation__button--continue"
        :class="{
          'calculation__button--continue-finished': exerciseIsFinished
        }"
      ></app-button>
    </div>
    <app-calculator
      :isShown="calculatorIsDisplayed"
      @closeCalculator="calculatorIsDisplayed = false"
    ></app-calculator>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppCalculator from "@/components/Organisms/Calculator.vue";

export default {
  name: "calculation",
  components: {
    AppButton,
    AppCalculator
  },
  props: {
    content: Object,
    exerciseIsReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hintState: [],
      hintSolutionIsShown: [],
      hintHeight: [],
      hintVisibility: [],
      hintSolutionHeight: [],
      solutionIsShown: false,
      solutionHintsAreShown: false,
      notepadIsShown: true,
      calculatorIsShown: true,
      calculatorIsDisplayed: false,
      exerciseIsFinished: false
    };
  },
  computed: {
    aHintIsShown() {
      return this.hintState.indexOf(2) > -1 ? true : false;
    }
  },
  methods: {
    showHint(index) {
      this.solutionHintsAreShown = true;
      this.setTimeout(() => {
        this.solutionHintsAreShown = false;
      }, 300);
      if (this.hintState[index] === 1) {
        this.hintState[index] = 2;
        this.playAudio("snap");

        let hintHeight =
          this.height(
            document.querySelector(".calculation__hint-paragraph--" + index)
          ) + 50;
        if (this.hintSolutionIsShown[index]) {
          hintHeight +=
            this.height(
              document.querySelector(
                ".calculation__hint-solution-span--" + index
              )
            ) + 5;
        }

        let hintContainer = document.querySelector(
          ".calculation__hint-outer-container--" + index
        );
        hintContainer.style.height = hintHeight + "px";
        setTimeout(() => {
          hintContainer.style.overflow = "visible";
        }, 300);

        if (
          index !== this.content.hints.length - 1 &&
          this.hintState[index + 1] === 0
        ) {
          this.hintState[index + 1] = 1;
        }
        this.$store.dispatch("addExerciseData", ["hintState", this.hintState]);
        this.typesetMath();

        setTimeout(() => {
          let hint = document.querySelector(
            ".calculation__hint-container--" + index
          );
          this.scrollElement(hint, this.remToPx(32));
        }, 0);

        return;
      } else if (this.hintState[index] === 2) {
        this.hintState[index] = 1;
        let hintContainer = document.querySelector(
          ".calculation__hint-outer-container--" + index
        );
        hintContainer.style.overflow = "hidden";
        hintContainer.style.height = 0;
      }
      // }
    },
    showHintSolution(index) {
      if (!this.hintSolutionIsShown[index]) {
        // this.playAudio("snap");
        let solutionHeight =
          this.height(
            document.querySelector(".calculation__hint-solution-span--" + index)
          ) + 5;
        document.querySelector(
          ".calculation__hint-solution--" + index
        ).style.height = solutionHeight + "px";
        let hintHeight =
          this.height(
            document.querySelector(".calculation__hint-paragraph--" + index)
          ) +
          solutionHeight +
          50;

        let hintContainer = document.querySelector(
          ".calculation__hint-outer-container--" + index
        );
        hintContainer.style.height = hintHeight + "px";
      } else {
        document.querySelector(
          ".calculation__hint-solution--" + index
        ).style.height = 0;
        let hintContainer = document.querySelector(
          ".calculation__hint-outer-container--" + index
        );
        let hintHeight =
          this.height(
            document.querySelector(".calculation__hint-paragraph--" + index)
          ) + 50;
        hintContainer.style.height = hintHeight + "px";
      }

      this.hintSolutionIsShown[index] = !this.hintSolutionIsShown[index];
      this.$store.dispatch("addExerciseData", [
        "hintSolutionIsShown",
        this.hintSolutionIsShown
      ]);
      this.typesetMath();

      setTimeout(() => {
        let hint = document.querySelector(
          ".calculation__hint-container--" + index
        );
        this.scrollElement(hint, this.remToPx(32), 30);
      }, 260);
    },
    showSolution() {
      this.$store.dispatch("setExerciseStatus", 4);
      this.exerciseIsFinished = true;
      this.$store.dispatch("addExerciseData", ["exerciseIsFinished", true]);
      this.solutionIsShown = true;
      this.$store.dispatch("addExerciseData", ["solutionIsShown", true]);
      this.playAudio("correct");

      setTimeout(() => {
        let solution = document.querySelector(".calculation__solution");
        this.scrollElement(solution, this.remToPx(32), 30);
      }, 0);

      if (this.$store.state.windowWidth > 600) {
        document.querySelector(".calculation__button--result").style.display =
          "none";
      } else {
        setTimeout(() => {
          document.querySelector(".calculation__button--result").style.display =
            "none";
        }, 750);
      }

      setTimeout(() => {
        document.querySelector(".calculation__button--continue").style.display =
          "block";
      }, 750);
    },
    resetExercise() {
      this.$store.dispatch("setExerciseStatus", 0);

      for (let i = 0; i < this.content.hints.length; i++) {
        if (i === 0) {
          this.hintState[i] = 1;
        } else {
          this.hintState[i] = 0;
        }
        this.hintSolutionIsShown[i] = false;

        let hintContainer = document.querySelector(
          ".calculation__hint-outer-container--" + i
        );
        hintContainer.style.overflow = "hidden";
        hintContainer.style.transition = "none";
        hintContainer.style.height = 0;
        let hintSolution = document.querySelector(
          ".calculation__hint-solution--" + i
        );
        hintSolution.style.transition = "none";
        hintSolution.style.height = 0;
        setTimeout(() => {
          hintContainer.style.transition = "height 0.375s";
          hintSolution.style.transition = "height 0.375s";

          this.scrollToTop();
        }, 0);
      }
      document.querySelector(".calculation__button--continue").style.display =
        "none";
      document.querySelector(".calculation__button--result").style.display =
        "block";
      this.solutionIsShown = false;
      this.solutionHintsAreShown = false;
      this.calculatorIsDisplayed = false;
      this.exerciseIsFinished = false;

      this.$store.dispatch("addExerciseData", ["exerciseIsFinished", false]);
      this.$store.dispatch("addExerciseData", ["solutionIsShown", false]);
    }
  },
  created() {
    this.hintState = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "hintState",
      []
    ]);
    this.hintSolutionIsShown = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "hintSolutionIsShown",
      []
    ]);
    for (let i = 0; i < this.hintSolutionIsShown.length; i++) {
      if (this.hintSolutionIsShown[i]) {
        this.hintSolutionHeight[i] = "100%";
      }
    }

    for (let i = 0; i < this.hintState.length; i++) {
      if (this.hintState[i] === 2) {
        this.hintHeight[i] = "100%";
        this.hintVisibility[i] = "visible";
      } else {
        this.hintHeight[i] = "0px";
        this.hintVisibility[i] = "hidden";
      }
    }

    if (this.hintState.length === 0) {
      for (let i = 0; i < this.content.hints.length; i++) {
        if (i === 0) {
          this.hintState[i] = 1;
        } else {
          this.hintState[i] = 0;
        }
        this.hintSolutionIsShown[i] = false;
      }
    }

    this.solutionIsShown = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "solutionIsShown",
      false
    ]);
    if (this.solutionIsShown) {
      setTimeout(() => {
        document.querySelector(".calculation__button--result").style.display =
          "none";
      }, 0);
    }
    this.exerciseIsFinished = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "exerciseIsFinished",
      false
    ]);

    if (this.content.notepadIsShown === false) {
      this.notepadIsShown = false;
    }
    if (this.content.calculatorIsShown === false) {
      this.calculatorIsShown = false;
    }
  },
  watch: {
    exerciseIsReset(to) {
      if (to) {
        this.resetExercise();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.calculation {
  position: absolute;
  top: 6rem;
  width: 100%;
  min-height: calc(100vh - 6rem);
  // padding-top: 5.5rem;
  // padding-bottom: 32rem;
  padding: 5.5rem calc(50vw - 0.5 * #{$width-center}) 32rem;
  color: $color-white;
  background-color: #101010;
  overflow: hidden;

  display: grid;
  @include grid-template-columns;
  align-content: start;

  @include respond(tab-port) {
    padding: 5.5rem 3rem 32rem;
  }

  @include respond(phone) {
    padding: 5.5rem calc(50vw - 0.5 * #{$width-center}) 32rem;
    padding-top: 4.6rem;
    min-height: 100vh;
    background-color: $color-black;
  }

  &__heading {
    grid-column: 1 / 6;
    margin-bottom: 4.125rem;
    font-weight: 400;
    text-align: center;

    @include respond(tab-land) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      margin-bottom: 3.6rem;
      font-size: 4rem;
    }
  }

  &__introduction-heading {
    grid-column: 1 / 6;
    margin-top: 1rem;
    margin-bottom: -0.5rem;
    font-size: 2.6rem;
    font-family: $font-primary-bold;
    color: $color-primary-light;
  }

  &__introduction-units {
    font-family: $font-primary;
    color: $color-white;
  }

  &__introduction {
    position: relative;
    grid-column: 1 / 6;
    margin-bottom: -1.75rem;
    // font-size: 2.8rem;
    font-size: 2.4rem;
    // border-radius: 1rem;

    transform: translateZ(0rem); // Removes artefacts from MathJax formulas

    // padding: 1.5rem;
    // padding-top: 0;
    // border-radius: 1rem;
    // border-radius: 0.6rem;
    // // // background-color: #1a1a1a;
    //  background-color: $color-black;
    // border: 0.2rem solid #222;
    // border-left: 2px solid $color-primary;

    // background-color: green;

    @include respond(phone) {
      font-size: 2.6rem;
      // background-color: $color-black;
      // background-color: #1a1a1a;
      // border: none;
    }

    &::after {
      @include pseudo-element;
      top: 1.5rem;
      width: 0.2rem;
      height: calc(100% - 3rem);
      border-radius: 10rem;
      background-color: $color-primary;
    }
  }

  &__instructions-container {
    grid-column: 1 / 6;
    // // // margin-top: 2.75rem;
    margin-top: 6.5rem; // was: 6rem

    display: grid;
    grid-template-columns: 6.5rem 1fr; // was: 8rem
    grid-column-gap: 2.75rem;

    @include respond(phone) {
      grid-column-gap: 1.5rem;
    }
  }

  &__solution {
    visibility: hidden;
    grid-column: 1 / 6;
    // margin-top: 1rem;
    font-size: 2.8rem;

    margin-left: 11rem;
    margin-top: 1rem;

    @include respond(phone) {
      margin-left: 0;
      margin-top: 0;
    }

    &--is-shown {
      visibility: visible;
    }
  }

  &__icon-outer-container {
    position: fixed;
    top: 0;
    transform: translateY(calc(var(--vh, 1vh) * 100 - 13.1925rem));
    border-bottom: 0.6925rem solid #101010;
    width: 100%;
    background-color: #101010;

    will-change: transform;
    transition: transform 0.75s;

    display: grid;
    grid-template-columns: 7.5rem 7.5rem 7.5rem 7.5rem 7.5rem;

    @include respond(phone) {
      transform: translateY(
        calc(var(--vh, 1vh) * 100 - 20rem)
      ); // was: -22.1rem
      border-bottom: 0.75rem solid $color-black;
      background-color: $color-black;

      // background-color: #1a1a1a;
      // // // border-top: 1px solid #222;
      // // // padding-top: 1rem;
      // // // padding-bottom: 1rem;

      grid-template-columns: repeat(7, 6.75rem);
    }
  }

  &__hints {
    position: absolute;
    top: -3.8rem;
    width: 100vw;
    height: 5rem;
    padding-bottom: 0.3rem;
    font-family: $font-primary-bold;
    font-size: 2.2rem;
    background-color: #101010;

    @include respond(phone) {
      padding-left: 0.7rem;
      background-color: $color-black;
    }
  }

  &__hints-tips {
    position: absolute;
    left: 3rem;
    transform: translateX(-50%);
  }

  &__icon-container {
    position: relative;
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    background-color: $color-primary-dark;

    font-family: $font-primary-bold;
    font-size: 2rem;
    line-height: 6.5rem;
    text-align: center;
    // border-radius: 0.6rem;

    cursor: pointer;

    &--bulb {
      position: absolute;
      // background-color: #1a1a1a;
      background-color: #222;
    }

    @include respond(phone) {
      width: 8rem;
      height: 8rem;
    }

    &--1 {
      border-radius: 0.6rem;
      background-color: #222;

      @include respond(phone) {
        width: 6rem;
        height: 6rem;
      }
    }

    &--2 {
      // border-radius: 50%;
      // background-color: #1a1a1a;
      background-color: #222;
    }

    &--3 {
      // border-radius: 50%;
      // background-color: #1a1a1a;
      background-color: #222;
    }

    &--4 {
      // border-radius: 0.6rem;
      // background-color: #1a1a1a;
      background-color: #222;

      @include respond(phone) {
        width: 6rem;
        height: 6rem;
      }
    }

    &--inactive {
      // visibility: hidden;
      color: #666;
      opacity: 0.667;
      pointer-events: none;
    }

    &--selected {
      background-color: $color-primary-dark;
    }

    &--clear {
      left: 11rem;
      width: 4rem;
      height: 4rem;
      background-color: transparent;
      // border: 1px solid #fff;
      // border: 1px solid $color-primary-light;
      background-color: $color-black;
      background-color: $color-primary-dark;

      @include respond(phone) {
        left: 0;
        top: -0.3rem;
        // width: 4rem;
        // height: 4rem;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;

    &--calculator {
      width: 6rem;
    }

    &--notepad {
      width: 3.2rem;
    }

    &--bulb {
      width: 4rem;
    }

    /* &--bulb-1 {
      width: 3rem;
      transform: translate(-50%, -2.5rem);
    } */
  }

  &__number {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-primary-bold;
    font-size: 2rem;
    color: $color-white;
    // color: $color-primary-light;
  }

  &__bulb-caption {
    position: absolute;
    left: 50%;
    //  top: 43%;
    transform: translate(-50%, -50%);
    font-family: $font-primary-bold;
    // font-size: 1.8rem;
    // color: $color-black;

    font-size: 3rem;
    top: 50%;
  }

  &__instructions {
    grid-column: 2 / 6;
    margin-bottom: 0.75rem;
    // // // padding: 1rem 1.75rem;
    padding: 0 1.75rem;
    border-radius: 1rem;
    font-size: 2.6rem;
    // // // background-color: #1a1a1a;

    &--notepad {
      align-self: center;
      padding: 0;
      margin-top: 2rem;
      line-height: 1.2;
      background-color: transparent;
    }
  }

  &__instructions-start {
    display: block;
    font-family: $font-primary-bold;
    line-height: 1.4;
    // color: $color-primary-light;
  }

  &__show-hints {
    grid-column: 2 / 3;
    padding-left: 2rem;
    padding-top: 0.75rem;
    font-size: 2.4rem;
    line-height: 1.2;
    color: #bbb;

    font-style: italic;
  }

  &__solution-hints {
    margin-left: 11rem;
    margin-top: 1.25rem;
    margin-bottom: -0.5rem;
    width: max-content;
    font-family: $font-primary-bold;
    font-size: 2.6rem;
    color: $color-primary-light;

    @include respond(phone) {
      margin-left: 0;
    }
  }

  &__hint {
    display: inline-block;
    margin-top: 2rem;
    grid-column: 1 / 2;
    width: 9rem;
    font-family: $font-primary-bold;
    font-size: 2.6rem;
    line-height: 1;
    color: $color-primary-light;
  }

  &__hint-outer-container {
    grid-column: 1 / 6; //
    height: 0;
    overflow: hidden;

    transition: height 0.375s;
  }

  &__hint-container {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    transform: translateZ(0);
    font-size: 2rem;
    line-height: 1.3;

    // background-color: red;

    @include respond(phone) {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
    }

    transition: height 0.375s;

    @include respond(phone) {
      padding: initial;
      border-radius: initial;
      font-size: 2.6rem;
      line-height: 1.4;
    }

    &--with-border {
      &::after {
        @include pseudo-element;
        left: calc(13rem - 0.5px);
        top: 4rem;
        width: 1px;
        height: 100%;
        border-left: 1px dashed #666;

        @include respond(phone) {
          left: calc(2rem - 0.5px);
          top: 4rem;
          border-left: none;
        }
      }
    }
  }

  &__hint-paragraph {
    // display: inline;
    //  text-indent: 30px;
    grid-column: 1 / 3;
    padding-left: 17.5rem;
    max-width: 82.5rem;

    // background-color: green;

    @include respond(phone) {
      // padding-left: 6.2rem;
      padding-left: 0;
      max-width: initial;
    }

    &--hint {
      @include respond(phone) {
        // margin-top: 2rem;
        text-indent: 5rem;
      }
    }
  }

  &__hint-solution {
    display: block;
    height: 0rem;
    overflow: hidden;

    transition: height 0.375s;
  }

  &__show-step-solution {
    grid-column: 1 / 2;
    margin-top: 1rem;
    display: block;
    font-family: $font-primary-bold;
    color: $color-primary-light;

    transition: transform 0.4s;
    cursor: pointer;
  }

  &__hide-step-solution {
    transform: translateX(0.2rem) skewX(-15deg);
  }

  &__button-container {
    position: fixed;
    bottom: 6rem;
    margin-top: 4.5rem;
    padding: 0.75rem 0;
    width: $width-center;
    pointer-events: none;

    @include respond(phone) {
      position: fixed;
      display: block;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 18.75rem + 1px));
      width: 100vw;
      background-color: $color-black;
      will-change: transform;
      transition: transform 0.75s;

      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 0.75rem;
    }
  }

  &__hint-button {
    grid-column: 2 / 3;
    width: max-content;
    border-radius: 0.6rem;
    background-color: #1a1a1a;
  }

  &__solution-button {
    grid-column: 1 / 3;
    background-color: #333;
  }

  &__button {
    height: max-content;
    left: 0;
    border: 1px solid transparent !important;
    border-radius: 0.6rem;
    padding: 0.85rem 2.4rem !important;
    background-color: $color-primary-dark;

    &--result,
    &--continue {
      position: absolute;
      left: $width-center;
      bottom: 0.5rem;
      transform: translate(-100%, -1rem);
      width: max-content;
      pointer-events: initial;

      @include respond(tab-port) {
        left: calc(100vw - 6rem);
      }

      @include respond(phone) {
        position: relative;
        bottom: 0;
        left: initial;
        transform: initial;
      }
    }

    &--continue {
      visibility: hidden;

      transition: visibility 0s 0.75s;

      @include respond(phone) {
        visibility: visible;
      }
    }

    @include respond(phone) {
      height: 6rem;
      font-size: 2.8rem;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &--result,
      &--continue {
        grid-column: 1 / 4;
        transform: none;
        width: initial;

        transition: transform 0.75s;
      }

      &--continue {
        transform: translateY(6.65rem);

        transition: transform 0.75s 0.75s;
      }
    }

    &--result-finished {
      @include respond(phone) {
        transform: translateY(6.65rem);
      }
    }

    &--continue-finished {
      visibility: visible;

      @include respond(phone) {
        transform: none;
      }
    }
  }
}
</style>
