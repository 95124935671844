<template>
  <div class="select-button" :class="{ 'select-button__selected': selected }">
    <button class="select-button__switch"></button>
    <span class="select-button__label">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "appSelectButton",
  props: {
    text: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.select-button {
  cursor: default;

  &__label {
    display: inline-block;
    margin-left: 1rem;
    transform: translateY(0.925rem);
  }

  &__switch {
    position: relative;
    width: 5rem;
    height: 2.5rem;
    padding-left: 1rem;
    border: 1px solid $color-white;
    border-radius: 10rem;
    background-color: $color-black;
    text-align: left;

    cursor: pointer;

    &::after {
      @include pseudo-element;
      left: 0;
      top: 0;
      width: 2.3rem;
      height: 2.3rem;
      border-radius: 50%;
      background-color: #aaa;

      transition: transform 0.2s, background-color 0.2s;
    }

    .select-button__selected &:after {
      transform: translateX(calc(2.7rem - 2px));
      background-color: #0d7bc5; // Same color as button
    }
  }
}
</style>
