<template>
  <div class="login-modal tabstart" tabindex="0">
    <h2 class="login-modal__heading">
      <!-- {{ $t("form_password")["enter_password"] }} -->
      {{ $t("form_change_password")["enter_email"] }}
    </h2>
    <app-input-complex
      :autocompleteInput="'email'"
      :placeholder="$t('email')"
      :text="email"
      @inputEvent="email = $event"
      @keyupEvent="requestPasswordOnEnterKey($event)"
    ></app-input-complex>
    <app-button
      @click="requestNewPassword()"
      size="big"
      :text="$t('form_change_password').button"
      class="login-modal__button"
    ></app-button>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppInputComplex from "@/components/Molecules/InputComplex.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";

export default {
  name: "appLoginForm",
  components: {
    AppInputComplex,
    AppButton,
    AppButtonXRound
  },
  data() {
    return {
      placeholderIsSmall: false,
      email: ""
    };
  },
  methods: {
    requestPasswordOnEnterKey(e) {
      if (e.code === "Enter") {
        this.requestNewPassword();
      }
    },
    requestNewPassword() {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!re.test(this.email)) {
        this.toggleAlert(this.$t("form_change_password").email_not_valid);
        return;
      }

      this.$store.dispatch("handleForgottenPassword", this.email);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.login-modal {
  width: 46rem;
  padding: 5.5rem;
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__heading {
    margin-top: -0.6rem;
    margin-bottom: 3.625rem;
    // font-size: 2.8rem;
    font-size: 2.6rem;
    line-height: 1;
    font-weight: 100;
    color: $color-white;

    @include respond(phone) {
      margin-bottom: 4.25rem;
      font-size: 3rem;
    }
  }

  &__button {
    margin-top: 2.875rem;
    width: 100%;
    height: 5rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    font-size: 2.4rem;
    text-align: center;

    @include respond(phone) {
      margin-top: 5.5rem;
      margin-bottom: 3rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }
}
</style>
