<template>
  <teleport to="body">
    <div
      @mousedown="closeModal"
      class="modal__overlay"
      :class="{
        'modal-is-visible': modalIsVisible,
        'transform-modal': transformModal
      }"
    ></div>
    <div
      class="modal__container"
      :class="{
        'modal-is-visible': modalIsVisible,
        'modal-is-notification': shownModal === 'app-notification'
      }"
    >
      <component
        :is="shownModal"
        class="modal"
        :class="{
          'transform-modal': transformModal,
          'modal--is-left': $store.state.modalNumber === 1
        }"
      ></component>
      <component
        :is="shownModal2"
        class="modal modal--2"
        :class="{
          'transform-modal': transformModal,
          'modal--is-right': $store.state.modalNumber === 0
        }"
      ></component>
    </div>
  </teleport>
</template>

<script>
import AppInquiryModal from "@/components/Organisms/Modals/Inquiry.vue";
import AppModal from "@/components/Organisms/Modals/Modal.vue";
import AppLanguageModal from "@/components/Organisms/Modals/Language.vue";
import AppLicenseModal from "@/components/Organisms/Modals/License.vue";
import AppLoginModal from "@/components/Organisms/Modals/Login.vue";
import AppRegisterModal from "@/components/Organisms/Modals/Register.vue";
import AppEmailConfirmed from "@/components/Organisms/Modals/EmailConfirmed.vue";
import AppPasswordModal from "@/components/Organisms/Modals/Password.vue";
import AppChangepasswordModal from "@/components/Organisms/Modals/ChangePassword.vue";
import AppNotification from "@/components/Organisms/Modals/Notification.vue";
import AppAudioChoice from "@/components/Organisms/Modals/AudioChoice.vue";
import AppPayment from "@/components/Organisms/Modals/Payment.vue";
import AppResumeCourse from "@/components/Organisms/Modals/ResumeCourse.vue";
import AppUnlockCourse from "@/components/Organisms/Modals/UnlockCourse.vue";
import AppOnboarding from "@/components/Organisms/Modals/Onboarding.vue";

export default {
  name: "app",
  components: {
    AppInquiryModal,
    AppModal,
    AppLanguageModal,
    AppLicenseModal,
    AppLoginModal,
    AppRegisterModal,
    AppEmailConfirmed,
    AppPasswordModal,
    AppChangepasswordModal,
    AppNotification,
    AppAudioChoice,
    AppPayment,
    AppResumeCourse,
    AppUnlockCourse,
    AppOnboarding
  },
  data() {
    return {
      modalIsVisible: false,
      transformModal: false,
      shownModal: "none",
      shownModal2: "none"
    };
  },
  computed: {
    modal() {
      return this.$store.state.shownModal;
    },
    modal2() {
      return this.$store.state.shownModal2;
    }
  },
  methods: {
    closeModal() {
      this.toggleModal();
    }
  },
  watch: {
    modal(to) {
      if (to === "none") {
        this.transformModal = false;
        setTimeout(() => {
          this.modalIsVisible = false;
          this.shownModal = to;
        }, 500);
      } else {
        this.shownModal = to;
        this.modalIsVisible = true;
        setTimeout(() => {
          this.transformModal = true;
        }, 15);
      }
    },
    modal2(to) {
      this.shownModal2 = to;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.modal__overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000;
  width: 100%;
  height: 100vh;
  background-color: rgba(#444, 0.675);
  opacity: 0;

  overflow: scroll;

  &.transform-modal {
    opacity: 1;
  }
}

.modal {
  position: absolute;
  left: 0rem;
  top: 0;
  transform: scale(0.7);
  opacity: 0;

  backface-visibility: hidden;
  will-change: transform, opacity;

  transform: translateY(-50%) scale(0.7);
  transform-origin: 50% calc(50vh - 100%);
  transition: transform 0.3s ease-out, opacity 0.3s;

  @include respond(desktop) {
    transform-origin: 50% calc(50vh - 50%);
  }

  &.transform-modal {
    transform: none;
    opacity: 1;

    transform: translateY(-50%);
  }

  &--is-left {
    transform: translate(calc(-100% - 1.5rem), -50%) !important;
    opacity: 0 !important;
    transition: transform 0.3s ease-in, opacity 0.3s;

    pointer-events: none;
  }

  &--is-right {
    transform: translate(calc(100% + 1.5rem), -50%) !important;
    opacity: 0 !important;

    pointer-events: none;
  }
}

.modal__container {
  display: none;
  position: fixed;
  left: 50%;
  top: 50vh;
  transform: translateX(-50%);
  z-index: 3000000000000;
  width: 46rem;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &.modal-is-notification {
    width: 66.4rem;
  }

  @include respond(phone) {
    &.modal-is-notification {
      width: calc(100vw - 1.5rem);
    }
  }
}

.modal-is-visible {
  display: block;
}
</style>
