<template>
  <div class="footer-navigation">
    <div
      @mousedown="goTo('/overview')"
      @touchstart="goTo('/overview')"
      class="footer-navigation__item"
      :class="{
        'footer-navigation__item--active':
          $route.path.split('/')[1] === 'overview'
      }"
    >
      <img
        src="@/assets/Internal/FooterNavigation/home.svg"
        alt="daschboard icon  "
        class="footer-navigation__icon footer-navigation__icon--1"
      />
      <figcaption class="footer-navigation__figure-caption">
        {{ $t("internal_header")[0] }}
      </figcaption>
    </div>
    <div
      @mousedown="
        setPage(-2);
        goTo('/course/#/lectures');
      "
      @touchstart="
        setPage(-2);
        goTo('/course/#/lectures');
      "
      class="footer-navigation__item"
      :class="{
        'footer-navigation__item--active':
          $route.path.split('/')[1] === 'course'
      }"
    >
      <img
        src="@/assets/Internal/FooterNavigation/doctorsHat.svg"
        alt="daschboard icon"
        class="footer-navigation__icon footer-navigation__icon--2"
      />
      <figcaption class="footer-navigation__figure-caption">
        {{ $t("internal_header")[1] }}
      </figcaption>
    </div>
    <div
      @mousedown="goTo('/vocabulary')"
      @touchstart="goTo('/vocabulary')"
      class="footer-navigation__item"
      :class="{
        'footer-navigation__item--active':
          $route.path.split('/')[1] === 'vocabulary'
      }"
    >
      <img
        src="@/assets/Internal/FooterNavigation/vocabulary.svg"
        alt="daschboard icon"
        class="footer-navigation__icon footer-navigation__icon--3"
      />
      <figcaption class="footer-navigation__figure-caption">
        {{ $t("internal_header")[2] }}
      </figcaption>
    </div>
    <div
      @mousedown="goTo('/contact')"
      @touchstart="goTo('/contact')"
      class="footer-navigation__item"
      :class="{
        'footer-navigation__item--active':
          $route.path.split('/')[1] === 'contact'
      }"
    >
      <img
        src="@/assets/Internal/FooterNavigation/contact.svg"
        alt="daschboard icon"
        class="footer-navigation__icon footer-navigation__icon--4"
      />
      <figcaption class="footer-navigation__figure-caption">
        {{ $t("internal_header")[3] }}
      </figcaption>
    </div>
    <div
      @mousedown="goTo('/help')"
      @touchstart="goTo('/help')"
      class="footer-navigation__item"
      :class="{
        'footer-navigation__item--active': $route.path.split('/')[1] === 'help'
      }"
    >
      <img
        src="@/assets/Internal/FooterNavigation/help.svg"
        alt="daschboard icon"
        class="footer-navigation__icon footer-navigation__icon--5"
      />
      <figcaption class="footer-navigation__figure-caption">
        {{ $t("internal_header")[4] }}
      </figcaption>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
@import "sass/main.scss";

.footer-navigation {
  display: none;

  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  // // // height: 8.7rem;
  height: 6rem;
  padding: 0 calc(100vw - 90.5rem);
  background-color: $color-black;

  @include respond(tab-port) {
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }

  @include respond(phone) {
    height: 8rem;
  }

  &__item {
    padding-top: 0.65rem;
    opacity: 0.5;
    transition: opacity 0.2s;

    display: grid;
    grid-template-rows: 4.1rem 1fr;

    @include respond(phone) {
      padding-top: 1.3rem;
    }

    &--active {
      opacity: 1;
    }
  }

  &__icon {
    justify-self: center;
    // transform: translateY(-0.5rem);
    // // // height: 3rem;
    height: 2.8rem;

    &--4,
    &--5 {
      transform: translateY(-0.1rem);
      height: 3rem;
    }
  }

  &__figure-caption {
    justify-self: center;
    transform: translateY(-0.9rem);
    font-size: 1.7rem;
    color: $color-white;

    @include respond(phone) {
      transform: translateY(-0.45rem);
    }
  }
}
</style>
