<template>
  <div class="plan-college">
    <div class="plan-college__container plan-college__container--1">
      <ul class="plan-college__list">
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue>
          {{ $t("plans_plan_college")[0] }}
        </li>
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue>
          {{ $t("plans_plan_college")[1] }}
        </li>
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue
          >{{ $t("plans_plan_college")[2] }}
        </li>
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue
          >{{ $t("plans_plan_college")[3] }}
        </li>
      </ul>
    </div>
    <div class="plan-college__container plan-college__container--2">
      <ul class="plan-college__list">
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue
          >{{ $t("plans_plan_college")[4] }}
        </li>
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue
          >{{ $t("plans_plan_college")[5] }}
        </li>
        <li>
          <!-- <span class="plan-college__checkmark">&#x2713;</span> -->
          <app-checkmark-blue
            class="plan-college__checkmark"
          ></app-checkmark-blue
          >{{ $t("plans_plan_college")[6] }} <br />
          {{ $t("plans_plan_college")[7] }}
        </li>
      </ul>
      <app-button
        @mousedown="toggleModal('app-inquiry-modal')"
        :text="$t('plans_button_offer')"
        variation="primary"
        class="plan-college__button"
      ></app-button>
    </div>
  </div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppButton from "@/components/Atoms/Button.vue";

export default {
  name: "loginForm",
  components: {
    AppButton,
    AppCheckmarkBlue
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.plan-college {
  grid-column: 1 / 5;
  margin-top: 4.4rem;
  padding: 4.6rem 5rem;

  background-color: #101010;
  border-radius: 0.6rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: min(5rem, 3vw);

  @include respond(phone) {
    padding: 4.6rem 0.25rem;
    // background-color: #1a1a1a;
    background-color: #161616;
  }

  @media only screen and (max-width: 530px) {
    grid-column: 1 / 3;
    padding: 4.6rem 0;
    grid-template-columns: calc(50vw - 2.5rem) calc(50vw - 2.5rem);
    grid-column-gap: 2rem;
  }

  @media only screen and (max-width: 380px) {
    padding: 4.6rem 2rem 15.8rem 2.25rem;
    grid-template-columns: calc(100vw - 4.5rem);
  }
}

.plan-college__container {
  border-radius: $border-radius;

  @media only screen and (max-width: 380px) {
    padding: 0 1.5rem;
    width: calc(100% - 3rem);
  }

  &--1 {
    grid-column: 1 / 2;
  }

  &--2 {
    position: relative;
    grid-column: 2 / 3;

    @media only screen and (max-width: 380px) {
      grid-column: 1 / 2;
      margin-top: 2.9rem;

      // background-color: green;
    }
  }
}

.plan-college__list {
  margin-left: 3.35rem;
  font-size: 2.3rem;
  line-height: 1.4;
  list-style: none;
  color: #ddd;
  padding-right: 3rem;

  @include respond(phone) {
    margin-left: 4.2rem;
  }

  @media only screen and (max-width: 380px) {
    margin-left: 3.9rem;
  }

  & li:not(:last-child) {
    margin-bottom: 2.5rem;

    @include respond(phone) {
      // margin-bottom: 2.9rem;
      margin-bottom: 2.95rem;
    }

    @media only screen and (max-width: 380px) {
      font-size: 2.4rem;
    }
  }
}

.plan-college__checkmark {
  position: absolute;
  transform: translate(-4.15rem, 0.75rem);
  width: 2.25rem;
  height: auto;

  @include respond(phone) {
    transform: translate(-3.45rem, 0.75rem);
  }

  @media only screen and (max-width: 380px) {
    width: 3rem;
    transform: translate(-5.3rem, 0.75rem);
  }
}

.plan-college__button {
  position: absolute;
  margin-left: 3.35rem;
  bottom: 0.4rem;
  min-width: calc(100% - 6.35rem);
  height: 4.4rem;
  text-align: center;
  font-size: 2.4rem;
  white-space: nowrap;
  background-color: $color-primary-dark;

  @include respond(phone) {
    height: 5rem;
  }

  @media only screen and (max-width: 530px) {
    right: 3.75rem;
  }

  @media only screen and (max-width: 380px) {
    bottom: -11.2rem;
    right: 0;
    margin-left: 0;
    width: 100%;
    height: 5.5rem;
  }
}
</style>
