<template>
  <div class="zipper">
    <div class="zipper__container">
      <h2 class="zipper__heading">
        {{ t(content.heading) }}
      </h2>
      <p class="zipper__instructions" style="white-space: pre-line;">
        {{ t(content.instructions) }} {{ test }}
      </p>
      <div class="zipper__medium-container">
        <div class="zipper__left-outer-container" ref="container">
          <div
            v-for="(element, index) in zipperElements"
            :key="element"
            @click="playZipperAudio(index)"
            :style="[
              'transform: translateX(' + leftZipperPosition(index) + ')'
            ]"
            ref="left"
            class="zipper__element zipper__element--left"
            :class="{
              'zipper__element--shown':
                (index <= recentZipper &&
                  index < numberOfQuestions &&
                  zipperIsShown(index, recentZipper)) ||
                (recentZipper >= numberOfQuestions &&
                  index < numberOfQuestions),
              'zipper__element--active-question': index === recentZipper,
              'zipper__element--dark':
                questionsAreVisible &&
                index > recentZipper &&
                index < numberOfQuestions,
              'zipper__element--solved': recentZipper >= numberOfQuestions,
              'zipper__element--background-is-green':
                solvedZipper > recentZipper,
              'zipper__element--zigzag-is-invisible':
                zigzagIsInvisible && index === recentZipper,
              'zipper__element--no-transition': transitionIsSuspended
            }"
          >
            <!-- delete: index >= recentZipper -->
            {{ t(element.question) }}
            <div class="testneu">
              <svg
                id="Ebene_1"
                data-name="Ebene 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 27 90"
              >
                >
                <polyline
                  class="cls-1"
                  points="0 0 27 0 18 9 27 18 18 27 27 36 18 45 27 54 18 63 27 72 18 81 27 90 0 90"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="zipper__right-outer-container"
          :class="{
            'zipper__element-small': !answersAreShifted
          }"
          ref="rightContainer"
        >
          <div
            v-for="(answer, index) in answersInNewOrder"
            :key="answer"
            :style="['transform: translate(' + zipperPosition(index) + ')']"
            class="zipper__element-container"
            :class="{
              'zipper__element-on-top':
                solvedZipper > recentZipper &&
                index === correctAnswers[recentZipper] &&
                !answersAreShifted,
              'zipper__element--no-transition': transitionIsSuspended,
              'zipper__element--no-transition-smartphone':
                recentZipper >= numberOfQuestions
            }"
          >
            <!-- :style="['transform: translate(' + zipperPosition(index) + ')']" -->
            <div
              @click="evaluateAnswer(index)"
              onclick="this.blur();"
              v-on:keypress="evaluateAnswer(index, $event)"
              :tabindex="returnTabindex(index)"
              ref="right"
              class="zipper__element zipper__element--right"
              :class="{
                'zipper__element--translated':
                  correctAnswers.indexOf(index) < recentZipper &&
                  recentZipper < numberOfQuestions,
                'zipper__element--active': returnActiveStatus(index),
                'zipper__element--correct':
                  solvedZipper > recentZipper &&
                  index === correctAnswers[recentZipper] &&
                  !zigzagIsInvisible,
                'zipper__element--wrong': index === indexOfWrongAnswer,
                'zipper__element--autosolved':
                  numberOfAutoSolvedAnswers > correctAnswers.indexOf(index),
                'zipper__element--right-2': index === 1,
                'zipper__element--dark':
                  recentZipper >= numberOfQuestions - 0.5 &&
                  recentZipper <= correctAnswers.indexOf(index),
                'zipper__element--solved-answer':
                  recentZipper >= numberOfQuestions &&
                  correctAnswers.indexOf(index) < numberOfQuestions,
                'zipper__element--zigzag-is-invisible':
                  zigzagIsInvisible &&
                  correctAnswers.indexOf(index) === recentZipper
              }"
            >
              {{ t(answer) }}
              <div class="testneu2">
                <svg
                  id="Ebene_1"
                  data-name="Ebene 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 27 90"
                >
                  >
                  <polyline
                    class="cls-2"
                    points="9 0 0 9 9 18 0 27 9 36 0 45 9 54 0 63 9 72 0 81 9 90 27 90 26 0"
                  />
                </svg>
              </div>
            </div>
            <audio
              v-if="zipperElements[index].audio"
              :ref="'sound-effect-' + index"
              :key="zipperElements[index].audio + index"
            >
              <source
                :src="
                  // 'http://mathopia3.s3-website.eu-central-1.amazonaws.com/' +
                  'https://mathopia.com/audio/' +
                    zipperElements[index].audio +
                    '.mp3'
                "
                type="audio/mpeg"
                preload="auto"
              />
            </audio>
          </div>
        </div>
      </div>

      <app-message
        :data="messageData"
        :isShown="messageIsShown"
        :transitionIsSuspended="transitionIsSuspended"
        @messageClosed="closeFeedback()"
        class="zipper__message"
        :class="{
          'zipper__message--is-shown': messageIsShown,
          'zipper__message--with-continue-button': continueButtonIsShown
        }"
      ></app-message>
      <app-button
        @click="setPage('++')"
        size="big"
        :text="$t('course_continue_button')"
        class="zipper__continue-button"
        :class="{
          'zipper__continue-button--is-shown': continueButtonIsShown
        }"
      ></app-button>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppMessage from "@/components/Molecules/Message.vue";

export default {
  name: "appCloze",
  components: {
    AppButton,
    AppMessage
  },
  props: {
    content: Object,
    isShown: {
      type: Boolean,
      default: false
    },
    exerciseIsReset: {
      type: Boolean,
      default: false
    },
    section: Number,
    page: Number
  },
  data() {
    return {
      zipperElements: [],
      additionalAnswers: [],
      orderOfAnswers: [],
      feedbackGeneral: "",
      feedbackQuestion: {},
      feedbackAnswer: {},
      randomizeQuestions: false,
      randomizeAnswers: true,
      preventIdenticalOrder: 4,
      questionsAreVisible: false,
      answersAreShifted: true,
      playCompletionSound: false,
      playSuccessLastSound: false,
      playZipperAudioOnly: false,
      messageWasJustClosed: false,

      orderOfQuestions: [],
      answersInNewOrder: [],
      correctAnswers: [],
      solvedZipper: 0,
      recentZipper: 0,
      zigzagIsInvisible: false,
      horizontalDistance: 0,
      verticalDistance: 0,
      horizontalPositions: [],
      verticalPositions: [],
      indexOfWrongAnswer: -1,
      numberOfQuestions: 1,
      noWrongAnswersWereGiven: true,
      transitionIsSuspended: true,
      autoOrderOfAnswers: [],
      numberOfAutoSolvedAnswers: 0,
      continueButtonIsShown: false,
      buttonsAreDeactivated: false,

      messageText: "",

      message: "",
      type: "message",
      image: "",
      messageData: {},
      messageIsShown: false
    };
  },
  computed: {
    audioFile() {
      return this.$store.state.recentAudio;
    }
  },
  methods: {
    zipperIsShown(index, recentZipper) {
      let isPhone = window.matchMedia("(max-width: 600px)");
      if (isPhone.matches) {
        return index >= recentZipper;
      } else {
        return true;
      }
    },
    zipperPosition(index) {
      let isPhone = window.matchMedia("(max-width: 600px)");

      if (isPhone.matches) {
        let verticalPosition = 0;
        if (
          this.solvedZipper <= this.recentZipper &&
          this.recentZipper < this.numberOfQuestions
        ) {
          let verticalPosition =
            this.verticalPositions[index] * this.verticalDistance;
          return "0," + verticalPosition + "px"; // elements at beginning
        } else if (
          this.recentZipper >= this.numberOfQuestions &&
          this.correctAnswers.indexOf(index) < this.numberOfQuestions
        ) {
          let verticalPosition =
            this.verticalPositions[index] * this.verticalDistance +
            this.correctAnswers.indexOf(index) * this.remToPx(8.3) -
            this.verticalTranslation; // // //
          return "0," + verticalPosition + "px"; // solutions very end position
        } else if (this.recentZipper >= this.numberOfQuestions) {
          let verticalPosition =
            (this.verticalPositions[index] + 1) * this.verticalDistance +
            (this.answersInNewOrder.length - this.numberOfQuestions) *
              this.verticalDistance;
          return "0," + verticalPosition + "px"; // additional elements very end position
        } else if (index !== this.correctAnswers[this.recentZipper]) {
          let verticalPosition =
            this.verticalPositions[index] * this.verticalDistance;
          return "0," + verticalPosition + "px"; // remaining elements at solution
        } else {
          verticalPosition =
            -this.verticalTranslation +
            (this.verticalPositions[index] - this.recentZipper) *
              this.verticalDistance;
          return "0," + verticalPosition + "px"; // translation at solution
        }
      } else {
        if (this.recentZipper >= this.numberOfQuestions) {
          let horizontalPosition = this.horizontalDistance / 2;
          return (
            horizontalPosition +
            "px, " +
            this.verticalPositions[index] * this.verticalDistance +
            "px"
          ); // solutions end position
        } else {
          return (
            this.horizontalPositions[index] * this.horizontalDistance +
            "px," +
            this.verticalPositions[index] * this.verticalDistance +
            "px"
          );
        }
      }
    },
    leftZipperPosition() {
      if (this.$store.state.windowWidth > 600) {
        if (this.recentZipper >= this.numberOfQuestions) {
          let horizontalPosition = -this.horizontalDistance / 2;
          return horizontalPosition + "px";
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    playZipperAudio(index) {
      /* if (this.buttonsAreDeactivated) {
        return;
      } */

      if (index < this.solvedZipper) {
        this.playAudio(this.zipperElements[index].audio);
      }
    },
    evaluateAnswer(index, key) {
      if (this.messageWasJustClosed) {
        return;
      }

      if (this.correctAnswers.indexOf(index) < this.solvedZipper) {
        let audioIndex = this.correctAnswers.indexOf(index);
        this.playAudio(this.zipperElements[audioIndex].audio);
        return;
      }

      if (this.buttonsAreDeactivated) {
        return;
      }

      if (this.numberOfQuestions - 0.5 <= this.solvedZipper) {
        return;
      }

      this.buttonsAreDeactivated = true;

      if (key) {
        let keycode = key.keyCode ? key.keyCode : key.which;
        if (keycode !== 13 && keycode !== 32) return;
      }

      if (index !== this.correctAnswers[this.recentZipper]) {
        this.$store.dispatch("setExerciseStatus", [
          this.orderOfQuestions[this.recentZipper],
          2
        ]);

        this.playAudio("wrong");
        this.noWrongAnswersWereGiven = false;
        this.indexOfWrongAnswer = index;
        setTimeout(() => {
          this.indexOfWrongAnswer = -1;
        }, 500);
        setTimeout(() => {
          this.buttonsAreDeactivated = false;
        }, 950);
        this.determineFeedback(index);
      } else {
        this.$store.dispatch("setExerciseStatus", [
          this.orderOfQuestions[this.recentZipper],
          4
        ]);

        this.messageIsShown = false;
        setTimeout(() => {
          this.messageIndicatesError = false;
          this.messageText = "";
        }, 201);
        this.solvedZipper++;
        this.horizontalPositions[index] = 1;

        if (!this.playZipperAudioOnly) {
          if (
            this.recentZipper === this.numberOfQuestions - 1 &&
            this.noWrongAnswersWereGiven &&
            this.playSuccessLastSound
          ) {
            this.playAudio("success");
          } else if (
            this.recentZipper === this.numberOfQuestions - 1 &&
            this.playCompletionSound
          ) {
            this.playAudio("completion");
          } else {
            this.playAudio("correct");
          }
        }

        let audioIndex = this.correctAnswers.indexOf(index);
        let audioDuration = 100;
        if (
          this.$refs["sound-effect-" + audioIndex] &&
          this.$store.state.soundIsTurnedOn
        ) {
          audioDuration =
            this.$refs["sound-effect-" + audioIndex].duration * 1000;
        }
        if (this.zipperElements[audioIndex].audio) {
          let delay = this.playZipperAudioOnly ? 0 : 500;
          setTimeout(() => {
            this.playAudio(this.zipperElements[audioIndex].audio);
          }, delay);
        }

        for (let i = 0; i < this.correctAnswers.length; i++) {
          if (
            i < this.correctAnswers[this.recentZipper] &&
            this.correctAnswers.indexOf(i) > this.recentZipper &&
            this.answersAreShifted
          ) {
            let numberOfShifts = 0;
            for (let j = 0; j <= this.recentZipper; j++) {
              if (
                i < this.correctAnswers[j] &&
                this.correctAnswers.indexOf(i) > j
              )
                numberOfShifts++;
            }
            this.verticalPositions[i] = numberOfShifts;
          } else if (this.correctAnswers.indexOf(i) <= this.recentZipper) {
            this.verticalPositions[i] = this.correctAnswers.indexOf(i) - i;
          }
        }

        if (this.zipperElements[audioIndex].info) {
          setTimeout(() => {
            this.messageText = this.zipperElements[audioIndex].info;
            let feedback = this.generateMessage(this.messageText);
            this.messageData = feedback;
            this.messageIsShown = true;
          }, 400);
        }

        /* let delayAfterLastZipper = 0;
        if (this.recentZipper === this.numberOfQuestions - 1) {
          delayAfterLastZipper = 500;
        } */
        setTimeout(() => {
          this.zigzagIsInvisible = true;
        }, 300);

        this.setTimeout(
          () => {
            this.setTimeout(
              () => {
                if (this.recentZipper < this.numberOfQuestions - 1) {
                  this.recentZipper++;
                  this.zigzagIsInvisible = false;
                  this.buttonsAreDeactivated = false;
                } else {
                  this.handleLastZipper();
                }
              },
              audioDuration,
              0
            );
          },
          475,
          1
        );

        /* setTimeout(() => {
          this.determineDistances();
        }, 680 + audioDuration); */
        if (this.recentZipper === this.numberOfQuestions - 1) {
          this.setTimeout(() => {
            this.scrollToBottomAndShowContinueButton();
          }, 800 + audioDuration);
        }
      }
    },
    handleLastZipper() {
      this.setTimeout(
        () => {
          this.recentZipper++;
          this.zigzagIsInvisible = false;
        },
        200,
        3
      );
    },
    scrollToBottomAndShowContinueButton() {
      let childNumber =
        this.orderOfAnswers.indexOf(this.orderOfAnswers.length - 1) + 1;
      /* if (this.orderOfAnswers.length > this.numberOfQuestions) {
        for (let i = 0; i < this.orderOfAnswers.length; i++) {
          if (
            this.orderOfAnswers[this.orderOfAnswers.length - i] >
            this.numberOfQuestions - 1
          ) {
            childNumber =
              this.orderOfAnswers[this.orderOfAnswers.length - i] + 1;
            break;
          }
        }
      } */

      let lastZipperElement = document.querySelector(
        ".zipper__element-container:nth-child(" +
          childNumber +
          ") .zipper__element--right"
      );

      setTimeout(() => {
        this.scrollElement(lastZipperElement, this.remToPx(26.5));
      }, 250);

      this.setTimeout(
        () => {
          this.continueButtonIsShown = true;
        },
        700,
        4
      );
      let isPhone = window.matchMedia("(max-width: 600px)");
      if (isPhone.matches) {
        this.setTimeout(
          () => {
            this.continueButtonIsShown = true;
          },
          700,
          4
        );
      } else {
        this.setTimeout(
          () => {
            this.continueButtonIsShown = true;
          },
          0,
          4
        );
      }
    },
    autoSolve(numberOfSolvedZippers) {
      let vm = this;
      function autoSolveZipper() {
        // let index = vm.correctAnswers[vm.recentZipper];
        vm.$store.dispatch("setExerciseStatus", [
          vm.orderOfQuestions[vm.recentZipper],
          4
        ]);
        vm.messageIsShown = false;
        vm.messageIndicatesError = false;
        vm.messageText = "";
        vm.solvedZipper++;

        for (let i = 0; i < vm.correctAnswers.length; i++) {
          if (
            i < vm.correctAnswers[vm.recentZipper] &&
            vm.correctAnswers.indexOf(i) > vm.recentZipper &&
            vm.answersAreShifted
          ) {
            let numberOfShifts = 0;
            for (let j = 0; j <= vm.recentZipper; j++) {
              if (i < vm.correctAnswers[j] && vm.correctAnswers.indexOf(i) > j)
                numberOfShifts++;
            }
            vm.verticalPositions[i] = numberOfShifts;
          } else if (vm.correctAnswers.indexOf(i) <= vm.recentZipper) {
            vm.horizontalPositions[i] = 1;
            vm.verticalPositions[i] = vm.correctAnswers.indexOf(i) - i;
          }
        }

        if (vm.recentZipper < vm.numberOfQuestions) {
          vm.recentZipper++;
        }
      }

      for (let i = 1; i <= numberOfSolvedZippers; i++) {
        autoSolveZipper();
      }

      if (numberOfSolvedZippers >= this.numberOfQuestions) {
        setTimeout(() => {
          this.scrollToBottomAndShowContinueButton();
        }, 250);
      }
    },

    determineFeedback(index) {
      let newFeedback;
      if (this.feedbackQuestion && this.feedbackQuestion[this.recentZipper]) {
        if (typeof this.feedbackQuestion[this.recentZipper] === "object") {
          if (
            this.feedbackQuestion[this.recentZipper][
              this.correctAnswers.indexOf(index)
            ]
          ) {
            newFeedback = this.feedbackQuestion[this.recentZipper][
              this.correctAnswers.indexOf(index)
            ];
            this.executeFeedback(newFeedback);
            return;
          } else if (this.feedbackQuestion[this.recentZipper].general) {
            newFeedback = this.feedbackQuestion[this.recentZipper].general;
            this.executeFeedback(newFeedback);
            return;
          }
        } else {
          newFeedback = this.feedbackQuestion[this.recentZipper];
          this.executeFeedback(newFeedback);
          return;
        }
      }
      if (
        this.feedbackAnswer &&
        this.feedbackAnswer[this.correctAnswers.indexOf(index)]
      ) {
        newFeedback = this.feedbackAnswer[this.correctAnswers.indexOf(index)];
        this.executeFeedback(newFeedback);
        return;
      }
      if (this.feedbackGeneral && this.isDefined(this.feedbackGeneral)) {
        newFeedback = this.feedbackGeneral;
        this.executeFeedback(newFeedback);
      } else {
        this.messageIsShown = false;
        setTimeout(() => {
          this.messageText = "";
        }, 301);
      }
    },
    executeFeedback(newFeedback) {
      if (newFeedback === this.messageText) {
        return;
      } else {
        this.messageIsShown = false;
        setTimeout(() => {
          this.messageText = newFeedback;
        }, 600);
        setTimeout(() => {
          let feedback = this.generateMessage(newFeedback, "error");
          this.messageData = feedback;
          this.messageIsShown = true;
        }, 725);
      }
    },
    closeFeedback() {
      this.messageIsShown = false;
      setTimeout(() => {
        this.messageText = "";
      }, 301);
    },

    returnTabindex(index) {
      return this.correctAnswers.indexOf(index) >= this.recentZipper ? 0 : -1;
    },
    returnActiveStatus(index) {
      return this.numberOfQuestions - 0.5 > this.solvedZipper &&
        this.correctAnswers.indexOf(index) >= this.solvedZipper
        ? true
        : false;
    },
    setOrderOfAnswers(numberOfAnswersArray) {
      let orderOfAnswers = this.orderOfAnswers;

      if (this.randomizeAnswers) {
        orderOfAnswers = this.shuffle(this.answersInNewOrder.length);
        this.orderOfAnswers = orderOfAnswers;
      }
      let answersInRandomOrder = [];
      for (let i = 0; i < this.answersInNewOrder.length; i++) {
        answersInRandomOrder[i] = this.answersInNewOrder[orderOfAnswers[i]];
      }
      this.answersInNewOrder = answersInRandomOrder;
      for (let i = 0; i < this.correctAnswers.length; i++) {
        this.correctAnswers[i] = orderOfAnswers.indexOf(this.correctAnswers[i]);
      }

      // Avoid identical order of questions and answers
      let newOrderIsRequested =
        this.preventIdenticalOrder === true ||
        (typeof this.preventIdenticalOrder === "number" &&
          numberOfAnswersArray.length >= this.preventIdenticalOrder);
      let orderIsIdentical =
        this.correctAnswers.slice(0, this.numberOfQuestions) ==
        "" + numberOfAnswersArray.slice(0, this.numberOfQuestions);
      if (
        numberOfAnswersArray.length > 1 &&
        this.randomizeAnswers &&
        newOrderIsRequested &&
        orderIsIdentical
      ) {
        this.setOrderOfAnswers(numberOfAnswersArray);
      }
    },

    determineDistances() {
      setTimeout(() => {
        if (this.answersInNewOrder.length > 0) {
          let container = this.$refs.container;
          let rightContainer = this.$refs.rightContainer;
          // let rightElement = this.$refs.right;

          this.verticalDistance = 70;
          // let shiftLeft = 0;
          let windowWidth = window.innerWidth;
          // let windowHeight = window.innerHeight;
          /* if (windowWidth <= 1200 || windowHeight <= 1000) {
            this.verticalDistance = 70;
            shiftLeft = 2.5;
          } */

          /* this.horizontalDistance = -(
            this.right(rightContainer) -
            this.right(container) -
            this.width(rightElement) -
            shiftLeft
          ); */

          this.horizontalDistance = -100;

          // // // alert(this.recentZipper >= this.numberOfQuestions);

          if (windowWidth <= 600) {
            this.horizontalDistance = 0;
            this.verticalDistance = this.remToPx(9.1);
            this.verticalTranslation =
              this.top(rightContainer) -
              this.bottom(container) -
              this.remToPx(0.5);
            // shiftLeft = 2;
          }
        }
      }, 0);
    },
    initializeExercise() {
      this.zipperElements = [];
      for (let i = 0; i < this.content.zipperElements.length; i++) {
        this.zipperElements[i] = this.content.zipperElements[i];
      }
      this.additionalAnswers = this.content.additionalAnswers;

      if ("orderOfAnswers" in this.content) {
        //
        this.orderOfAnswers = this.content.orderOfAnswers;
      } else {
        this.orderOfAnswers = false;
      } //

      if (this.autoOrderOfAnswers.length > 0) {
        this.orderOfAnswers = this.autoOrderOfAnswers;
        this.randomizeAnswers = false;
      } else if ("orderOfAnswers" in this.content) {
        this.orderOfAnswers = this.content.orderOfAnswers;
      } else {
        this.orderOfAnswers = false;
      }

      if (this.additionalAnswers) {
        for (let i = 0; i < this.additionalAnswers.length; i++) {
          this.zipperElements.push({
            question: "",
            answer: this.additionalAnswers[i]
          });
        }
      }

      let auxArray = [];
      for (let i = 0; i < this.zipperElements.length; i++) {
        this.answersInNewOrder[i] = this.zipperElements[i].answer;
        this.correctAnswers[i] = i;
        this.horizontalPositions[i] = 0;
        this.verticalPositions[i] = 0;
        auxArray[i] = i;
      }

      // Handle given order of answers
      if (
        this.orderOfAnswers &&
        Array.isArray(this.orderOfAnswers) &&
        this.orderOfAnswers.length > 0
      ) {
        let a = this.orderOfAnswers.slice(0),
          b = auxArray;
        let orderIsAdmissible =
          JSON.stringify(a.sort()) === JSON.stringify(b.sort());
        if (!orderIsAdmissible) {
          alert("!");
          this.orderOfAnswers = auxArray;
        }
      } else if (this.orderOfAnswers && !Array.isArray(this.orderOfAnswers)) {
        alert("!");
        this.orderOfAnswers = auxArray;
      } else {
        this.orderOfAnswers = auxArray;
      }

      // Randomize Questions
      let orderOfQuestions = auxArray;
      if (this.randomizeQuestions) {
        orderOfQuestions = this.shuffle(this.zipperElements.length);
      }
      orderOfQuestions = this.shiftEmptyElements(
        orderOfQuestions,
        this.zipperElements,
        "question"
      );

      this.orderOfQuestions = orderOfQuestions.slice(0);

      let questionsInNewOrder = [];
      for (let i = 0; i < this.zipperElements.length; i++) {
        questionsInNewOrder[i] = this.zipperElements[orderOfQuestions[i]];
      }
      this.zipperElements = questionsInNewOrder;
      this.correctAnswers = orderOfQuestions;

      let noQuestionCounter = 0;
      for (let i = 0; i < this.zipperElements.length; i++) {
        if (!this.zipperElements[i].question) {
          noQuestionCounter++;
          this.zipperElements[i].question = ".";
        }
      }
      this.numberOfQuestions = this.zipperElements.length - noQuestionCounter;

      // Randomize Answers
      this.setOrderOfAnswers(auxArray);

      // Get feedback for wrong answers
      this.feedbackGeneral = this.content.feedbackGeneral;
      this.feedbackAnswer = this.content.feedbackAnswer;
      this.feedbackQuestion = this.content.feedbackQuestion;

      // Determine distances
      this.verticalDistance = 70;
      this.horizontalDistance = -100;

      let vm = this;
      setTimeout(() => {
        this.determineDistances();
        window.addEventListener("resize", vm.determineDistances, false);
      }, 0);
      setTimeout(() => {
        this.determineDistances();
      }, 1000);
    },
    resetExercise() {
      this.$store.dispatch("setExerciseStatus", 0);
      this.clearAllTimeouts();

      (this.orderOfAnswers = []), //
        (this.answersInNewOrder = []),
        (this.correctAnswers = []),
        (this.solvedZipper = 0),
        (this.recentZipper = 0),
        (this.zigzagIsInvisible = false),
        (this.horizontalDistance = -464),
        (this.verticalDistance = 90),
        (this.horizontalPositions = []),
        (this.verticalPositions = []),
        (this.indexOfWrongAnswer = -1),
        (this.numberOfQuestions = 1),
        (this.noWrongAnswersWereGiven = true),
        (this.messageText = ""),
        (this.messageIndicatesError = false),
        (this.messageIsShown = false);
      this.autoOrderOfAnswers = [];
      this.numberOfAutoSolvedAnswers = 0;
      this.continueButtonIsShown = false;
      this.buttonsAreDeactivated = false;

      this.$store.dispatch("addExerciseData", ["orderOfAnswers", []]);

      this.transitionIsSuspended = true;
      this.initializeExercise();
      this.scrollToTop();
      setTimeout(() => {
        this.transitionIsSuspended = false;
      }, 550);
    }
  },
  created() {
    if (
      this.content.randomizeQuestions !== undefined &&
      this.content.randomizeQuestions
    ) {
      this.randomizeQuestions = true;
    }
    if (
      this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "orderOfAnswers",
        "length",
        0
      ]) > 0
    ) {
      this.autoOrderOfAnswers = this.$store.state.userDataLectures[
        this.$store.state.chosenLesson
      ].savedExerciseData[this.$store.state.coursePage].orderOfAnswers;
    }
    this.initializeExercise();
    this.$store.dispatch("addExerciseData", [
      "orderOfAnswers",
      this.orderOfAnswers
    ]);

    this.numberOfAutoSolvedAnswers = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "solvedExercises",
      0
    ]);
    this.autoSolve(
      this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "solvedExercises",
        0
      ])
    );

    setTimeout(() => {
      this.transitionIsSuspended = false;
    }, 500);
  },
  watch: {
    isShown(to) {
      if (to) {
        setTimeout(() => {
          this.determineDistances();
        }, 500);
      }
    },
    exerciseIsReset(to) {
      if (to) {
        this.resetExercise();
      }
    },
    messageIsShown(to) {
      if (!to) {
        this.messageWasJustClosed = true;
        setTimeout(() => {
          this.messageWasJustClosed = false;
        }, 850);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

// Zipper height
$height-desktop: 50px;
$height-tab-land: 50px;
$height-phone: 40px;

$vertical-distance-desktop: 20px;
$vertical-distance-tab-land: 20px;

$gradient-desktop: 0.1 * $height-desktop;
$gradient-tab-land: 0.1 * $height-tab-land;
$gradient-phone: 0.1 * $height-phone;

$size-desktop: 0.2 * $height-desktop;
$size-tab-land: 0.2 * $height-tab-land;
$size-phone: 0.2 * $height-phone;

$color-zipper-correct: #006600;

.testneu {
  position: absolute;
  right: -2.5px;
  top: 0;
  width: 18px;

  @include respond(phone) {
    display: none;
  }
}
.cls-1 {
  fill: #242424;
  transform: scale(0.835);

  .zipper__element--active-question & {
    fill: $color-primary-dark;
  }
}

.testneu2 {
  position: absolute;
  left: -2.5px;
  top: 0;
  width: 18px;

  @include respond(phone) {
    display: none;
  }
}

.cls-2 {
  fill: #242424;
  transform: translateY(0.35px) scale(0.835);

  .zipper__element--active.zipper__element:hover & {
    @media (hover: hover) and (pointer: fine) {
      fill: #444;
    }
  }

  .zipper__element--correct & {
    fill: $color-zipper-correct !important;
  }

  .zipper__element--wrong & {
    fill: $color-wrong !important;
  }
}

.zipper {
  position: absolute;
  top: 6rem;
  width: 100%;
  min-height: 100vh;
  // padding-top: max(11rem, min(13.75rem, 10vh));
  padding-top: 5.5rem;
  color: $color-white;

  text-align: center;

  background-color: $color-black;
  // background-color: #131313;
  // background-color: #101010;

  @include grid-template-columns;
  grid-template-rows: min-content min-content 1fr;

  @include respond(phone) {
    min-height: max-content;
    padding-top: 4.6rem;
    padding-bottom: 300rem;
    background-color: $color-black;
    overflow: hidden;
  }

  &__container {
    grid-column: 3 / 4;

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr min-content;

    @include respond(phone) {
      position: relative;
      grid-column: 2 / 5;
      min-height: calc(var(--vh, 1vh) * 100 - 18.4rem);
      grid-template-rows: min-content min-content min-content 1fr;
    }
  }

  &__heading {
    grid-column: 1 / 3;
    margin-bottom: 4.125rem;
    text-align: center;
    font-weight: 400;

    @include respond(medium-size) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      padding: 0 0.75rem;
      margin-bottom: 3.6rem;
      font-size: 4rem;
    }
  }

  &__instructions {
    grid-column: 1 / 3;
    margin-bottom: 6.5rem;
    text-align: center;
    font-size: 2.6rem;

    @include respond(medium-size) {
      margin-bottom: 4.125rem;
    }

    @include respond(phone) {
      padding: 0 0.75rem;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 4.25rem;
    }
  }

  &__medium-container {
    grid-column: 1 / 3;
    transform: translateX(min(0.5 * #{$width-center} - 50%, 50vw - 50% - 3rem));
    width: min-content;

    display: grid;
    grid-template-columns: min-content min-content;
    // background-color: red;

    @include respond(phone) {
      grid-row: 3 / 5;
      transform: none;
    }
  }

  &__left-outer-container {
    grid-column: 1 / 2;
    white-space: nowrap;
    // background-color: green;

    @include respond(phone) {
      grid-column: 1 / 3;
      max-width: initial;
      height: 6.75rem;
    }
  }

  &__right-outer-container {
    grid-column: 2 / 3;
    margin-left: calc(2 * #{$height-desktop});
    width: max-content;
    display: grid;
    // background-color: blue;

    @include respond(phone) {
      grid-column: 1 / 3;
      align-self: end;
      // margin-top: 9rem; // Can be changed!
      // padding-bottom: 1rem;
      padding-bottom: 0.2rem;
      margin-left: 0;
      width: calc(100vw - 1.5rem);
    }

    &.zipper__element-small {
      width: 48.5%; // !!!
    }

    /* &.zipper__right-container-at-top {
      margin-top: 0;
      align-self: start;
    } */
  }

  &__element-container {
    will-change: transform;
    transition: transform 0.3s ease-in-out;

    // background-color: green;

    &:not(:last-child) .zipper__element {
      margin-bottom: $vertical-distance-desktop;

      @include respond(medium-size) {
        margin-bottom: $vertical-distance-tab-land;
      }

      @include respond(phone) {
        margin-bottom: 2.35rem;
      }
    }
  }

  &__element--no-transition {
    transition: none !important;
  }

  &__element--no-transition-smartphone {
    @include respond(phone) {
      transition: none;
    }
  }

  &__element-on-top {
    position: relative;
    z-index: 100;
  }

  &__element {
    position: relative;

    height: $height-desktop;
    line-height: $height-desktop;

    // // // overflow: hidden;

    @include respond(medium-size) {
      height: $height-tab-land;
      line-height: $height-tab-land;
    }

    @include respond(phone) {
      // height: $height-phone; // !!!
      width: 100% !important;
      height: 6.75rem;
      // line-height: $height-phone;
      line-height: 6.75rem;
      text-align: center;

      overflow: visible;
    }

    &:after {
      @include respond(phone) {
        @include pseudo-element;
        left: 0;
        top: calc(100% - var(--width-of-ZZJ) / 5.414214);
        bottom: initial;
        width: 100%;
        height: calc(var(--width-of-ZZJ) / 2);
        background-image: linear-gradient(
            45deg,
            transparent 33.333333%,
            $color-primary-dark 33.333333%,
            $color-primary-dark 66.666667%,
            transparent 66.666667%
          ),
          linear-gradient(
            315deg,
            transparent 33.333333%,
            $color-primary-dark 33.333333%,
            $color-primary-dark 66.666667%,
            transparent 66.666667%
          );
        transform: scaleY(-1);
        background-size: calc(var(--width-of-ZZJ) / 2) var(--width-of-ZZJ);
        background-repeat: repeat-x;
      }
    }

    &.zipper__element--zigzag-is-invisible {
      @include respond(phone) {
        background-color: $color-primary-dark;
        filter: none;

        &::after {
          display: none;
        }
      }
    }

    &--left {
      visibility: hidden;
      width: 100%;
      padding-left: 4.3rem;
      padding-right: 4.7rem;

      border-right: 0.5px solid $color-black;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      background: linear-gradient(
        to left,
        transparent 0.7rem,
        $color-grey 0.7rem
      );

      transition: transform 0.3s ease-in-out;

      @include respond(phone) {
        display: none;
        height: 0;
        padding: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
        background: $color-primary-dark;
      }

      &:not(:last-child) {
        margin-bottom: $vertical-distance-desktop;

        @include respond(medium-size) {
          margin-bottom: $vertical-distance-tab-land;
        }

        @include respond(phone) {
          margin-bottom: 10.65rem;
        }
      }
    }

    &--solved {
      @include respond(phone) {
        background: #1a1a1a;

        &::after {
          content: initial;
        }
      }
    }

    &--right {
      padding-right: 4.3rem;
      padding-left: 4.7rem;

      // border-left: 0.5px solid $color-black;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      background: linear-gradient(
        to right,
        transparent 0.7rem,
        $color-grey 0.7rem
      );

      float: right;
      width: 100%;

      @include respond(phone) {
        padding: 0;
        background: #1c1c1c; // was: $color-grey-dark
        border-top-right-radius: 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      &.zipper__element--solved-answer {
        &::after {
          @include respond(phone) {
            content: initial;
          }
        }
      }

      &.zipper__element--autosolved {
        float: left;

        @include respond(phone) {
          float: initial;
          transform: initial;
        }
      }

      &.zipper__element--wrong {
        animation: shake 0.667s ease-in-out;
      }

      &:focus:hover:focus {
        outline: none;
      }

      &::after {
        @include respond(phone) {
          left: 0;
          top: calc(0vw - var(--width-of-ZZJ) / 2.828427);
          bottom: initial;
          width: 100%;
          height: calc(var(--width-of-ZZJ) / 2);
          background-image: linear-gradient(
              45deg,
              #1c1c1c 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              #1c1c1c 66.666667%
            ),
            linear-gradient(
              315deg,
              #1c1c1c 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              #1c1c1c 66.666667%
            );
          background-size: calc(var(--width-of-ZZJ) / 2) var(--width-of-ZZJ);
          background-position: calc(50% - 0.25 * var(--width-of-ZZJ)) top;
          transform: scaleY(-1);
          background-repeat: repeat-x;
        }
      }

      &.zipper__element--active:hover {
        @media (hover: hover) and (pointer: fine) {
          background: linear-gradient(
            to right,
            transparent 0.7rem,
            $color-grey-light 0.7rem
          );
          cursor: pointer;

          @include respond(phone) {
            background: #333;
          }

          &::after {
            @include respond(phone) {
              background-image: linear-gradient(
                  45deg,
                  #333 33.333333%,
                  transparent 33.333333%,
                  transparent 66.666667%,
                  #333 66.666667%
                ),
                linear-gradient(
                  315deg,
                  #333 33.333333%,
                  transparent 33.333333%,
                  transparent 66.666667%,
                  #333 66.666667%
                );
            }
          }
        }
      }

      &.zipper__element--correct::after {
        @include respond(phone) {
          background-image: linear-gradient(
              45deg,
              $color-zipper-correct 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              $color-zipper-correct 66.666667%
            ),
            linear-gradient(
              315deg,
              $color-zipper-correct 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              $color-zipper-correct 66.666667%
            ) !important;
        }
      }

      &.zipper__element--wrong::after {
        @include respond(phone) {
          background-image: linear-gradient(
              45deg,
              $color-wrong 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              $color-wrong 66.666667%
            ),
            linear-gradient(
              315deg,
              $color-wrong 33.333333%,
              transparent 33.333333%,
              transparent 66.666667%,
              $color-wrong 66.666667%
            ) !important;
        }
      }

      &.zipper__element--correct {
        background: linear-gradient(
          to right,
          transparent 0.7rem,
          $color-zipper-correct 0.7rem
        ) !important;

        @include respond(phone) {
          background: $color-zipper-correct !important;
        }
      }

      &.zipper__element--wrong {
        background: linear-gradient(
          to right,
          transparent 0.7rem,
          $color-wrong 0.7rem
        ) !important;
        color: $color-black;

        @include respond(phone) {
          background: $color-wrong !important;
        }
      }

      &.zipper__element--translated {
        @include respond(phone) {
          visibility: hidden;
        }
      }
    }

    &--shown {
      visibility: visible;

      @include respond(phone) {
        display: block;
        height: 6.75rem;
      }
    }

    &--active-question {
      @media only screen and (min-width: 600.1px) {
        background: linear-gradient(
          to left,
          transparent 0.7rem,
          $color-primary-dark 0.7rem
        );
      }
    }

    &--wrong {
      animation: shake 0.667s ease-in-out;
    }

    &--dark {
      visibility: visible;
      color: #999;
      opacity: 0.6;
    }
  }

  .zipper__message {
    position: fixed;
    left: calc(50vw - 0.5 * min(#{$width-center}, 100vw - 6rem));
    top: 0;
    z-index: 10;
    width: min(#{$width-center}, 100vw - 6rem);
    transform: translateY(calc(var(--vh, 1vh) * 100 - 5.75rem));
    text-align: initial;

    transition: transform 0.75s, visibility 0s 0.75s;

    &--is-shown {
      transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 6.75rem));
      transition: transform 0.75s;
    }

    @include respond(phone) {
      position: fixed;
      margin-top: 0;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 5.75rem));
      width: 100vw;

      &--is-shown {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 6.75rem));
        transition: transform 0.75s;

        &.zipper__message--with-continue-button {
          transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
        }
      }
    }
  }

  &__continue-button {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    z-index: 1;
    margin-top: 5.5rem;
    width: max-content;
    height: max-content;
    justify-self: end;
    border: 1px solid transparent !important;
    border-radius: 0.6rem;
    padding: 0.85rem 2.4rem !important;
    background-color: $color-primary-dark;
    visibility: hidden;

    transform-style: preserve-3d;

    /* &::after {
      @include respond(phone) {
        @include pseudo-element;
        left: -0.75rem;
        top: calc(-1.1rem - 1px);
        transform: translateZ(-1px);
        width: 100vw;
        height: 8.2rem;
        // backdrop-filter: blur(10px);
        background-color: green;
      }
    } */

    @include respond(phone) {
      position: fixed;
      display: block;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 6.5rem + 1px));
      margin-top: initial;
      width: 100vw;
      height: 6rem;
      justify-self: initial;
      font-size: 2.8rem;
      text-align: center;
      // background-color: #1876b8;
      will-change: transform;
      transition: transform 0.75s;

      &--is-shown {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 13.5rem + 1px));
      }
    }

    &--is-shown {
      visibility: visible;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
