<template>
  <div class="language-menu">
    <div>
      <app-button
        @mousedown="setLanguage('de')"
        icon="bubble"
        variation="grey"
        text="Deutsch"
        iconText="DE"
        :selected="$i18n.locale === 'de'"
      ></app-button>
      <app-button
        @mousedown="setLanguage('en')"
        icon="bubble"
        variation="grey"
        text="English"
        iconText="EN"
        :selected="$i18n.locale === 'en'"
      ></app-button>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";

export default {
  name: "languageMenu",
  components: {
    AppButton
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.language-menu {
  position: absolute;
  z-index: 1000000;
  border: 1px solid $color-grey;
  border-top: none;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding: 0 0.5rem;
  @include grey-backdrop-blurred;
}
</style>
