import { createStore } from "vuex";

// // // import firebase from "firebase";
// // // require("firebase/auth");
// import { getAuth, updateProfile } from "firebase/auth"; // <-- Firebase 9

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from "firebase/auth";

export default createStore({
  state: {
    this: undefined,

    urlParameters: [],
    actionCode: "",
    externalPages: ["/", "/imprint", "/terms", "/data-protection"],

    // Screen values
    windowWidth: 1,
    windowHeight: 1,
    windowOuterWidth: 1,
    windowOuterHeight: 1,
    devicePixelRatio: 1,
    remValue: 10,
    scaleOverflow: 1,

    typesetMathIsDeactivated: false,

    spinnerIsShown: false,
    isScrolling: false,
    shownModal: "none",
    shownSubModal: "",
    shownModal2: "none",
    modalData: {},
    modalNumber: 0,
    modalMayBeClosed: true,
    alertText: "",
    alertTextGhostButton: "",
    alertGhostAction: () => {},
    shownSidebarMenu: "none",
    languageMenuIsShown: false,
    onboardingIsShown: false,
    onboardingIsHidden: false,

    glossaryTopic: "",
    glossarySubtopic: "",
    glossaryParagraph: "",
    glossaryLeft: 0,
    glossaryTop: 0,
    glossaryArrowShift: 0,
    glossaryIsOnTop: false,
    glossaryIsFixed: false,
    glossaryIsShown: false,

    feedbackFormIsShown: false,

    timeouts: {},
    auxiliaryNumber: 0,

    registrationSuccessful: false,

    mainMenuIsShown: false,
    TOCisOpen: false,
    coursePageRequestedViaTOC: 0,

    // supportsFullscreen: false,

    // Registration data
    isAuthenticated: false,
    isLicensed: false,
    license: false,
    licenses: {},

    // User data
    user: {},
    userDataLectures: {},
    savedExerciseData: [],
    notifications: [],
    shownNotification: {},
    indexOfNotificationOnFirstLogin: false,
    welcomeMessageNoMoreVisible: false,
    listOfReadNotifications: [],
    welcomeMessageReadAtDate: 0,
    onboardingDesktopWasShown: false,
    onboardingPhoneWasShown: false,
    dateOfRecentLogin: 0,
    newlyPublishedCourses: [],

    // Fetching user data from database
    loginIsAllowed: false,
    numberOfDataItemsFetched: 0,
    userDataIsFetched: false,
    redirectAfterUserDataIsFetched: [false, 0],
    breadcrumbNavigationIsShown: true,

    // Sound variables
    soundIsTurnedOn: true,
    userWasAskedAboutSound: false,
    soundWasInitialized: false,
    recentAudioChannel: 0,
    soundEffect: [],
    audioDurations: {},
    recentAudio: "soundWrong4", // ???

    // Contents variables
    courseComponentCreated: false,
    chosenLecture: "",
    contentsOfLecture: "", // was: "analysis1"
    chosenLesson: "", // was: "lesson1"
    chosenLessonVersion: 0,
    topicGroup: {},
    topic: {},
    headingOfChosenLesson: "",
    lessons: {},
    listOfLectureNames: {},
    listOfLessonNames: {},
    triggerPreparationOfCourse: 0,
    triggerPrecacheOfLessons: 0,
    timeRequired: undefined,
    isRestricted: false,
    courseWasLeftOnce: false,

    totalProgressOfLectures: {},

    goToLesson: "",

    // List of progressed courses
    listOfProgressedLectures: [],
    listOfProgressedCourses: [],

    // Course variables
    courseData: {},

    courseWasReachedFrom: ["home"],
    coursePage: -2,
    recentPage: {},
    recentSection: 0,
    numberOfSections: 0,
    numberOfPages: 0,
    numberOfSteps: 0,
    numberOfExercises: 0,
    stepsPerSection: [],
    stepsUpToPage: [],
    sectionBeginsAtPage: [],
    pageBelongsToSection: [],
    pageNumberInSection: [],
    courseWasStarted: false,
    courseIsFinished: false,
    firstUnprocessedPage: 1,
    estimatedRequiredTime: 10,

    processedSections: [],
    processedExercises: [],
    widthsOfSections: [],

    maximumScore: 0,
    achievedScore: 0,

    processedVersions: {},

    courseKey: 0,
    courseKey2: 0,

    // Table of contents on course
    selectedSection: -1,

    timeout: 0, // Hover intent for progress bar
    timeout2: 0,
    timeoutIsSet: false
  },
  mutations: {},
  actions: {
    register({ state }, payload) {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then(() => {
          state.registrationSuccessful = true;

          let user = firebase.auth().currentUser;
          firebase.auth().languageCode = state.this.$i18n.locale;
          let language = state.this.$i18n.locale;

          user
            .updateProfile({
              photoURL: language
            })
            .then(() => {})
            .catch(error => {
              console.log(error);
            });

          let updates = {};
          updates["/user/" + user.uid + "/bullets"] = payload.password.length;
          firebase
            .database()
            .ref()
            .update(updates)
            .catch(error => {
              console.log(error);
              if (error.code === "PERMISSION_DENIED") {
                // dispatch('logout')
              }
            });

          /* var actionCodeSettings = {
            url: "https://mathopia.com/?email=" + payload.email
          }; */

          sendEmailVerification(auth.currentUser).catch(error => {
            if (error.code === "auth/network-request-failed") {
              state.this.toggleAlert(state.this.$t("network_error"));
            }
          });
        })
        .catch(error => {
          console.log(error);
          if (error.code === "auth/network-request-failed") {
            state.this.toggleAlert(state.this.$t("network_error"));
          }
          if (error.code === "auth/email-already-in-use") {
            state.this.toggleAlert(
              state.this.$t("form_signup_feedback")["already_registered"],
              state.this.$t("forgotten_password"),
              () => {
                state.this.toggleAlert();
                state.this.changeModal("app-changepassword-modal");
              }
            );
          }
        });
    },

    login({ state, dispatch }, payload) {
      let numberOfTries = 0;
      function redirectToInternal() {
        if (state.loginIsAllowed) {
          state.this.toggleModal();
          state.this.$router.replace("/overview");
          state.loginIsAllowed = false;
        } else if (numberOfTries < 667) {
          numberOfTries++;
          setTimeout(() => {
            redirectToInternal();
          }, 15);
        }
      }

      const auth = getAuth();
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(userCredential => {
          if (!userCredential.user.emailVerified) {
            state.this.toggleAlert(
              state.this.$t("form_login_feedback").email_not_verified,
              "Link erneut senden",
              () => {
                state.this.$store.dispatch("resendEmailVerification", {
                  email: payload.email,
                  language: state.this.$i18n.locale
                });
              }
            );
          } else {
            state.this.$i18n.locale = userCredential.user.photoURL;
            state.isAuthenticated = true;
            localStorage.setItem("isAuthenticated", "true");
            dispatch("fetchUser");

            redirectToInternal();
          }
        })
        .catch(error => {
          if (error.code === "auth/invalid-email") {
            state.this.toggleAlert(
              state.this.$t("form_login_feedback").email_not_valid
            );
          } else if (error.code === "auth/user-not-found") {
            state.this.toggleAlert(
              state.this.$t("form_login_feedback").email_not_registered
            );
          } else if (error.code === "auth/wrong-password") {
            state.this.toggleAlert(
              state.this.$t("form_login_feedback").wrong_password,
              state.this.$t("forgotten_password"),
              () => {
                state.this.toggleAlert();
                state.this.changeModal("app-changepassword-modal");
              }
            );
          } else if (error.code === "auth/network-request-failed") {
            state.this.toggleAlert(state.this.$t("network_error"));
          } else {
            console.log(error.code);
          }
        });
    },

    tryAutoLogin({ state, dispatch }, vm) {
      const auth = getAuth();
      onAuthStateChanged(auth, user => {
        if (user === null) {
          setTimeout(() => {
            if (state.isAuthenticated) {
              dispatch("logout", true);
            }
          }, 0);
        }
        if (user) {
          if (
            "photoURL" in user &&
            user.photoURL !== null &&
            state.isAuthenticated
          ) {
            vm.$i18n.locale = user.photoURL;
          }
          dispatch("fetchUser", vm);
        }
      });
    },

    saveLanguage({ state }) {
      let user = firebase.auth().currentUser;
      // firebase.auth().languageCode = state.this.$i18n.locale;
      let language = state.this.$i18n.locale;

      user
        .updateProfile({
          photoURL: language
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
        });
    },

    fetchUser({ state, dispatch }) {
      state.numberOfDataItemsFetched = 0;

      let userId = "";
      let user = firebase.auth().currentUser;
      if (user === null && state.isAuthenticated) {
        dispatch("logout");
        return;
      }
      userId = user.uid;

      state.user.email = user.email;

      firebase
        .database()
        .ref("/user/" + userId)
        .once("value")
        .then(snapshot => {
          let fetchedData = (snapshot.val() && snapshot.val().userData) || {};
          fetchedData = insertEmptyArraysIntoFetchedData(fetchedData);
          state.userDataLectures = {
            ...state.userDataLectures,
            ...fetchedData
          };
          // alert(JSON.stringify(state.userDataLectures, null, 4));

          /* let language = (snapshot.val() && snapshot.val().language) || "";
            if (language.length > 0) {
              vm.$i18n.locale = language;
            } */
          let listOfProgressedLectures =
            (snapshot.val() && snapshot.val().listOfProgressedLectures) || [];
          if (listOfProgressedLectures.length > 0) {
            state.listOfProgressedLectures = listOfProgressedLectures;
          }
          let listOfProgressedCourses =
            (snapshot.val() && snapshot.val().listOfProgressedCourses) || [];
          state.listOfProgressedCourses = listOfProgressedCourses;
          let listOfReadNotifications =
            (snapshot.val() && snapshot.val().listOfReadNotifications) || [];
          state.listOfReadNotifications = listOfReadNotifications;

          state.welcomeMessageReadAtDate =
            (snapshot.val() && snapshot.val().welcomeMessageReadAtDate) || 0;
          state.dateOfRecentLogin = Date.now();

          let onboardingDesktopWasShown =
            (snapshot.val() && snapshot.val().onboardingDesktopWasShown) ||
            false;
          state.onboardingDesktopWasShown = onboardingDesktopWasShown;
          let onboardingPhoneWasShown =
            (snapshot.val() && snapshot.val().onboardingPhoneWasShown) || false;
          state.onboardingPhoneWasShown = onboardingPhoneWasShown;
          let processedVersions =
            (snapshot.val() && snapshot.val().processedVersions) || {};
          state.processedVersions = processedVersions;

          state.license = (snapshot.val() && snapshot.val().license) || false;

          state.numberOfDataItemsFetched++;

          if (state.numberOfDataItemsFetched >= 2) {
            setTimeout(() => {
              state.loginIsAllowed = true;
            }, 100);
            state.userDataIsFetched = true;
            this.dispatch("compareCourseVersions");
            this.dispatch("saveIndexOfNotificationOnFirstLogin");

            state.numberOfDataItemsFetched = 0;
            if (state.externalPages.indexOf(state.this.$route.path) > -1) {
              state.this.toggleModal();
              state.this.$router.replace("/overview");
              // alert(1);
              setTimeout(() => {
                state.this.$router.replace("/overview");
              }, 0);
            }

            /* if (state.license in state.licenses) {
              if (state.license in state.licenses) {
                let start = state.licenses[state.license].start;
                start = start.split(".");
                start = new Date(start[2], start[1] - 1, start[0]);

                let end = state.licenses[state.license].end;
                end = end.split(".");
                end = new Date(end[2], end[1] - 1, end[0]);

                let date = new Date();
                date.setHours(0, 0, 0, 0);

                if (date - start >= 0 && end - date >= -86400000) {
                  state.isLicensed = true;
                }
              }
            } */
          }

          return firebase
            .database()
            .ref("/licenses/" + state.license)
            .once("value")
            .then(snapshot => {
              state.licenses[state.license] = snapshot.val() || {};
              // state.this.a(snapshot);

              // if (state.license in state.licenses) {
              let start = state.licenses[state.license].start;
              start = start.split(".");
              start = new Date(start[2], start[1] - 1, start[0]);

              let end = state.licenses[state.license].end;
              end = end.split(".");
              end = new Date(end[2], end[1] - 1, end[0]);

              let date = new Date();
              date.setHours(0, 0, 0, 0);

              if (date - start >= 0 && end - date >= -86400000) {
                state.isLicensed = true;
              }
              // }
            })
            .catch(error => {
              console.log(error);
              // alert(error);
            });
        })
        .catch(error => {
          console.log(error);
          // alert(error);
          // dispatch("logout");
        });

      return firebase
        .database()
        .ref("/generalData")
        .once("value")
        .then(snapshot => {
          state.notifications = snapshot.val().notifications || [];
          state.newlyPublishedCourses =
            (snapshot.val() && snapshot.val().newlyPublishedCourses) || [];
          state.indexOfNotificationOnFirstLogin =
            (snapshot.val() &&
              snapshot.val().indexOfNotificationOnFirstLogin) ||
            false;
          state.welcomeMessageNoMoreVisible =
            (snapshot.val() && snapshot.val().welcomeMessageNoMoreVisible) ||
            false;
          state.courseVersions =
            (snapshot.val() && snapshot.val().courseVersions) || {};
          // state.licenses = (snapshot.val() && snapshot.val().licenses) || {};

          state.numberOfDataItemsFetched++;
          if (state.numberOfDataItemsFetched >= 2) {
            setTimeout(() => {
              state.loginIsAllowed = true;
            }, 100);
            state.userDataIsFetched = true;
            this.dispatch("compareCourseVersions");
            this.dispatch("saveIndexOfNotificationOnFirstLogin");

            state.numberOfDataItemsFetched = 0;
            if (state.externalPages.indexOf(state.this.$route.path) > -1) {
              state.this.toggleModal();
              state.this.$router.replace("/overview");
              // alert(2);
              setTimeout(() => {
                state.this.$router.replace("/overview");
              }, 0);
            }

            if (state.license in state.licenses) {
              let start = state.licenses[state.license].start;
              start = start.split(".");
              start = new Date(start[2], start[1] - 1, start[0]);

              let end = state.licenses[state.license].end;
              end = end.split(".");
              end = new Date(end[2], end[1] - 1, end[0]);

              let date = new Date();
              date.setHours(0, 0, 0, 0);

              if (date - start >= 0 && end - date >= -86400000) {
                state.isLicensed = true;
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
          // dispatch("logout");
        });

      function insertEmptyArraysIntoFetchedData(object) {
        for (let key in object) {
          if ("processedExercises" in object[key]) {
            for (let i = 0; i < object[key].processedExercises.length; i++) {
              if (
                object[key].processedExercises[i] === undefined ||
                object[key].processedExercises[i] === null
              ) {
                object[key].processedExercises[i] = [];
              }
            }
          }
        }
        return object;
      }
    },

    getLicense({ state, dispatch }, payload) {
      state.numberOfDataItemsFetched = 0;

      let user = firebase.auth().currentUser;
      if (user === null && state.isAuthenticated) {
        dispatch("logout");
        return;
      }
      state.user.email = user.email;

      return firebase
        .database()
        .ref("/licenses/" + payload)
        .once("value")
        .then(snapshot => {
          let fetchedData = snapshot.val() || {};

          if (fetchedData.end === undefined) {
            state.this.toggleAlert(state.this.$t("get_license").not_valid);
          } else {
            let myDate = fetchedData.end;
            myDate = myDate.split(".");
            const newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);

            let start = fetchedData.start;
            start = start.split(".");
            const newStart = new Date(start[2], start[1] - 1, start[0]);

            let date = new Date();
            date.setHours(0, 0, 0, 0);

            if (newDate - date <= -86400000) {
              state.this.toggleAlert(state.this.$t("get_license").expired);
            } else if (date - newStart < 0) {
              state.this.toggleAlert(
                state.this.$t("get_license").not_yet_valid[0] +
                  fetchedData.start +
                  state.this.$t("get_license").not_yet_valid[1]
              );
            } else if (
              fetchedData.licensesUsed !== undefined &&
              Object.keys(fetchedData.licensesUsed).length >= fetchedData.amount
            ) {
              state.this.toggleAlert(
                state.this.$t("get_license").maximum_number_reached
              );
            } else {
              state.license = payload;
              state.isLicensed = true;
              state.this.toggleModal();
              state.this.toggleAlert(
                state.this.$t("get_license").unlocked[0] +
                  fetchedData.end +
                  state.this.$t("get_license").unlocked[1]
              );

              let user = firebase.auth().currentUser;
              if (user === null || !state.userDataIsFetched) {
                return;
              }

              let updates = {};

              updates[
                "/licenses/" + payload + "/licensesUsed/" + new Date()
              ] = true;
              updates["/licensesUsed/" + payload + "/" + user.uid] =
                state.user.email;
              updates["/user/" + user.uid + "/license"] = payload;

              firebase
                .database()
                .ref()
                .update(updates)
                .catch(error => {
                  alert(error);
                  if (error.code === "PERMISSION_DENIED") {
                    // dispatch('logout')
                  }
                });
            }
          }
        })
        .catch(error => {
          alert(error);
        });
    },

    compareCourseVersions({ state, dispatch }) {
      // state.this.$i18n.locale = "en";
      let listOfChangedCourses = [];

      for (let key in state.processedVersions) {
        if (key in state.courseVersions) {
          if (state.courseVersions[key] > state.processedVersions[key]) {
            listOfChangedCourses.push(key);
            state.userDataLectures[key] = {};

            for (let i = 0; i < state.listOfProgressedCourses.length; i++) {
              if (key === state.listOfProgressedCourses[i][0]) {
                state.listOfProgressedCourses.splice(i, 1);
              }
            }
          }
        }
      }

      if (listOfChangedCourses.length === 1) {
        state.this.toggleAlert(
          state.this.$t("new_course_version")["one_course_1"] +
            state.this.t(state.listOfLessonNames[listOfChangedCourses[0]]) +
            state.this.$t("new_course_version")["one_course_2"]
        );
      } else if (listOfChangedCourses.length >= 2) {
        let alertMessage =
          state.this.$t("new_course_version")["several_courses_1"] +
          state.this.t(state.listOfLessonNames[listOfChangedCourses[0]]) +
          state.this.$t("new_course_version")["several_courses_2"] +
          state.this.t(state.listOfLessonNames[listOfChangedCourses[1]]);

        if (listOfChangedCourses.length === 2) {
          alertMessage += state.this.$t("new_course_version")["two_courses"];
        }

        if (listOfChangedCourses.length === 3) {
          alertMessage += state.this.$t("new_course_version")["three_courses"];
        }

        if (listOfChangedCourses.length >= 4) {
          let numberOfAdditionalCourses = listOfChangedCourses.length - 2;
          alertMessage +=
            state.this.$t("new_course_version")["four_courses_1"] +
            numberOfAdditionalCourses +
            state.this.$t("new_course_version")["four_courses_2"];
        }

        alertMessage += state.this.$t("new_course_version")[
          "several_courses_3"
        ];

        state.this.toggleAlert(alertMessage);
      }

      state.processedVersions = [];
      dispatch("saveUserDataOnDatabase", listOfChangedCourses);
    },

    saveIndexOfNotificationOnFirstLogin({ state, dispatch }) {
      if (typeof state.indexOfNotificationOnFirstLogin === Number) {
        return;
      }
      let maximumId = 0;
      for (let i = 0; i < state.notifications.length; i++) {
        if (
          "id" in state.notifications[i] &&
          state.notifications[i].id > maximumId
        ) {
          maximumId = state.notifications[i].id;
        }
      }
      state.indexOfNotificationOnFirstLogin = maximumId;
      dispatch("saveUserDataOnDatabase");
    },

    logout({ state }, showAutoLogoutAlert) {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          state.isAuthenticated = false;
          localStorage.removeItem("isAuthenticated");
          state.this.closeMenu();
          state.this.goTo("/");
          state.this.setPage(-4); // ???
          state.spinnerIsShown = false;

          if (showAutoLogoutAlert !== true) {
            (state.this.$store.state.modalData = {
              heading: state.this.$t("modal_logout").heading,
              lottie: "logout",
              loop: true,
              background: true,
              overflowHidden: true,
              text: state.this.$t("modal_logout").message,
              button: state.this.$t("button_ok"),
              ghostButton: state.this.$t("modal_logout").ghostButton,
              ghostButtonAction: () => {
                state.this.changeModal("app-login-modal");
              }
            }),
              state.this.toggleModal("app-modal");
          }

          if (showAutoLogoutAlert === true) {
            state.this.toggleAlert(state.this.$t("alert_auto_logout"));
          }

          localStorage.clear();
          state.soundIsTurnedOn = true;
        })
        .catch(error => {
          alert(error);
        });
    },

    resendEmailVerification({ state }, payload) {
      let user = firebase.auth().currentUser;
      firebase.auth().languageCode = payload.language;

      if (payload.email === user.email) {
        /* let actionCodeSettings = {
          url: "https://mathopia.com/?email=" + payload.email
        }; */
        firebase
          .auth()
          .currentUser.sendEmailVerification()
          .then(() => {
            state.this.toggleModal();
            state.this.toggleAlert();
            setTimeout(() => {
              state.this.toggleAlert(
                state.this.$t("alert_verify_mail_link_not_valid")[
                  "new_link_sent"
                ]
              );
            }, 510);
          })
          .catch(error => {
            state.this.toggleAlert();
            if (error.code === "auth/network-request-failed") {
              state.this.toggleAlert(state.this.$t("network_error"));
            } else if (error.code === "auth/too-many-requests") {
              state.this.toggleModal();
              setTimeout(() => {
                state.this.toggleAlert(state.this.$t("security_issues"));
              }, 510);
            }
          });
      }

      /* let data = {
        text: {
          heading: i18n.t('modal_email_sent_again'),
          message: [i18n.t('modal_signed_up.message_1'), email + '.', i18n.t('modal_signed_up.message_2')]
        }
      }
      this.$app.changeModal(data, 'text-alert') */
    },

    resendEmailVerificationWithPassword({ dispatch }, payload) {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          dispatch("resendEmailVerification", payload);
        })
        .catch(error => {
          // alert(error);
          if (error.code === "auth/network-request-failed") {
            this.state.this.toggleAlert(this.state.this.$t("network_error"));
          } else if (error.code === "auth/wrong-password") {
            this.state.this.toggleAlert(
              this.state.this.$t("form_password").wrong_password
            );
          } else if (error.code === "auth/too-many-requests") {
            this.state.this.toggleModal();
            setTimeout(() => {
              this.state.this.toggleAlert(
                this.state.this.$t("security_issues")
              );
            }, 400);
          }
        });
    },

    handleForgottenPassword({ state }, email) {
      const auth = getAuth();
      firebase.auth().languageCode = state.this.$i18n.locale;

      sendPasswordResetEmail(auth, email)
        .then(() => {
          state.this.toggleModal();
          state.this.toggleAlert(
            this.state.this.$t("handle_forgotten_password").email_sent
          );
        })
        .catch(error => {
          const errorCode = error.code;
          if (errorCode === "auth/user-not-found") {
            state.this.toggleAlert(
              this.state.this.$t("handle_forgotten_password")
                .email_not_registered
            );
          } else {
            state.this.toggleModal();
            state.this.toggleAlert(
              this.state.this.$t("handle_forgotten_password").not_possible
            );
          }
        });
    },

    // Course related actions
    initializeCourse({ state }, data) {
      state.courseData = data;
      // alert(JSON.stringify(data, null, 4));

      // Set number of sections
      let numberOfSections = data.length - 2;
      state.numberOfSections = numberOfSections;

      let numberOfPages = 0;
      let numberOfSteps = 0; // Here = numberOfExercises - can later be distinguished
      let numberOfExercises = 0;
      let stepsPerSection = [];
      let stepsUpToPage = [];
      let pageBelongsToSection = [];
      let sectionBeginsAtPage = [];
      let widthsOfSections = [];
      let estimatedRequiredTime = 0;

      // Get number of steps (here = number of exercises)
      function stepCounter(exercise, getTime) {
        if (exercise.type === "cloze") {
          let numberOfGaps = 0;
          for (let i = 0; i < exercise.answers.length; i++) {
            for (let j = 0; j < exercise.answers[i].length; j++) {
              numberOfGaps += exercise.answers[i][j].text.length - 1;
            }
          }
          if (getTime) {
            return 14 * numberOfGaps; // // time
          } else {
            return numberOfGaps;
          }
        } else if (
          exercise.type === "calculation" ||
          exercise.type === "information" ||
          exercise.type === "draganddrop" ||
          exercise.type === "drawing" ||
          (exercise.type === "picture" && exercise.subtype !== "arrows")
        ) {
          if (getTime) {
            if (exercise.type === "calculation") {
              if ("hints" in exercise && exercise.hints.length > 0) {
                return 85 * exercise.hints.length; // // time
              } else {
                return 132; // // time
              }
            } else if (exercise.type === "information") {
              return 32; // // time
            } else if (exercise.type === "draganddrop") {
              return 42; // // time
            } else if (exercise.type === "drawing") {
              return 28; // // time
            } else if (
              exercise.type === "picture" &&
              exercise.subtype !== "arrows"
            ) {
              return 45; // // time
            }
          } else {
            return 1;
          }
        } else if (exercise.type === "multiplechoice") {
          let startStep = exercise.startFromStep || 0;
          if (getTime) {
            return 14 * (exercise.correctAnswer.length - startStep); // // time
          } else {
            return exercise.correctAnswer.length - startStep;
          }
        } else if (
          exercise.type === "picture" &&
          exercise.subtype === "arrows"
        ) {
          if (getTime) {
            return 16 * Object.keys(exercise.arrowData).length; // // time
          } else {
            return Object.keys(exercise.arrowData).length;
          }
        } else if (exercise.type === "zipper") {
          if (getTime) {
            return 10 * exercise.zipperElements.length; // // time
          } else {
            return exercise.zipperElements.length;
          }
        } else {
          return 0;
        }
      }

      // Set number of pages
      let pageCounter = 0;
      let stepCounter2 = 0;
      for (let i = 0; i < data.length; i++) {
        numberOfPages += data[i].length;
        let stepCounter1 = 0;
        for (let j = 0; j < data[i].length; j++) {
          numberOfSteps += stepCounter(data[i][j]);
          numberOfExercises += stepCounter(data[i][j]);
          estimatedRequiredTime += stepCounter(data[i][j], true);
          stepCounter1 += stepCounter(data[i][j]);
          stepCounter2 += stepCounter(data[i][j]);

          pageCounter++;
          stepsUpToPage[pageCounter] = stepCounter2;
          pageBelongsToSection[pageCounter - 1] = i;
        }
        stepsPerSection[i] = stepCounter1;
        sectionBeginsAtPage[i] = pageCounter;
      }
      pageBelongsToSection[numberOfPages - 1] = numberOfSections;

      state.numberOfPages = numberOfPages;
      state.numberOfSteps = numberOfSteps;
      state.numberOfExercises = numberOfExercises;
      estimatedRequiredTime += 40; // // time
      state.estimatedRequiredTime = Math.ceil(estimatedRequiredTime / 60);
      state.stepsPerSection = stepsPerSection;
      state.stepsUpToPage = stepsUpToPage;
      state.pageBelongsToSection = pageBelongsToSection;

      // // //
      let pageNumberInSection = [];
      for (let i = 0; i < numberOfPages; i++) {
        pageNumberInSection[i] =
          i - sectionBeginsAtPage[pageBelongsToSection[i] - 1];
      }
      state.pageNumberInSection = pageNumberInSection;

      for (let i = 0; i < data.length; i++) {
        widthsOfSections[i] =
          100 * (stepsPerSection[i + 1] / numberOfSteps) + "%";
      }

      let processedExercises = [];
      let processedSections = [];
      for (let i = 0; i < data.length; i++) {
        processedExercises[i] = [];
        processedSections[i] = 0;
        for (let j = 0; j < data[i].length; j++) {
          processedExercises[i][j] = [];
          if (stepCounter(data[i][j]) >= 0) {
            for (let k = 0; k < stepCounter(data[i][j]); k++) {
              processedExercises[i][j][k] = 0;
            }
          } else {
            processedExercises[i][j] = [-1];
          }
        }
      }

      if (
        state.chosenLesson in state.userDataLectures &&
        "processedExercises" in state.userDataLectures[state.chosenLesson]
      ) {
        state.processedExercises =
          state.userDataLectures[state.chosenLesson].processedExercises;
      } else {
        state.processedExercises = processedExercises;
      }
      state.processedSection = processedSections;

      state.sectionBeginsAtPage = sectionBeginsAtPage;
      state.widthsOfSections = widthsOfSections;
    },

    getFirstUnprocessedPage({ state }) {
      if (
        state.chosenLesson in state.userDataLectures &&
        "started" in state.userDataLectures[state.chosenLesson] &&
        state.userDataLectures[state.chosenLesson].started
      ) {
        for (let i = 0; i < state.processedExercises.length; i++) {
          for (let j = 0; j < state.processedExercises[i].length; j++) {
            for (let k = 0; k < state.processedExercises[i][j].length; k++) {
              if (
                state.processedExercises[i][j][k] >= 0 &&
                state.processedExercises[i][j][k] <= 5
              ) {
                state.firstUnprocessedPage =
                  state.sectionBeginsAtPage[i - 1] + j;
                if (j === 1 && state.processedExercises[i][0][0] === -1) {
                  let wholePageUnprocessed = true;
                  for (let l = 1; l < state.processedExercises[i].length; l++) {
                    for (
                      let m = 0;
                      m < state.processedExercises[i][l].length;
                      m++
                    ) {
                      if (state.processedExercises[i][l][m] > 5) {
                        wholePageUnprocessed = false;
                      }
                    }
                  }
                  if (wholePageUnprocessed) {
                    state.firstUnprocessedPage =
                      state.sectionBeginsAtPage[i - 1];
                  }
                }
                return;
              }
            }
          }
        }
      }
    },

    checkIfCourseIsFinished({ state }) {
      let courseIsFinished = true;

      if (
        state.processedSections.length === 0 ||
        state.processedSections.includes(0) ||
        state.processedSections.includes(1)
      ) {
        courseIsFinished = false;
      }
      state.courseIsFinished = courseIsFinished;
    },

    createListOfProgressedCourses({ state }) {
      for (let i = 0; i < state.listOfProgressedCourses.length; i++) {
        if (state.listOfProgressedCourses[i][0] === state.chosenLesson) {
          state.listOfProgressedCourses.splice(i, 1);
        }
      }

      let totalNumberOfSections = state.processedSections.length;
      let numberOfProcessedSections = 0;
      for (let i = 0; i < totalNumberOfSections; i++) {
        if (state.processedSections[i] === 2) {
          numberOfProcessedSections++;
        }
      }

      let value = [
        state.chosenLesson,
        numberOfProcessedSections,
        totalNumberOfSections
      ];
      state.listOfProgressedCourses.unshift(value);
    },

    saveExerciseData({ state, dispatch }) {
      if (state.coursePage + 1 === state.numberOfPages) {
        return;
      }
      let solvedExercises = 0;
      for (
        let i = 0;
        i <
        state.processedExercises[state.recentSection][
          state.pageNumberInSection[state.coursePage]
        ].length;
        i++
      ) {
        if (
          state.processedExercises[state.recentSection][
            state.pageNumberInSection[state.coursePage]
          ][i] >= 6
        ) {
          solvedExercises++;
        }
      }

      dispatch("getUserData", { solvedExercises: solvedExercises });
    },
    addExerciseData({ state, dispatch }, payload) {
      if (state.savedExerciseData[state.coursePage] === undefined) {
        state.savedExerciseData[state.coursePage] = {};
      }

      let payloadData = {};
      payloadData[payload[0]] = payload[1];
      dispatch("getUserData", payloadData);
    },

    resetLectureDataForFinishedCourse({ state }) {
      if (
        !(state.chosenLesson in state.userDataLectures) ||
        !("processedExercises" in state.userDataLectures[state.chosenLesson])
      ) {
        return;
      }

      for (
        let i = 0;
        i <
        state.userDataLectures[state.chosenLesson].processedExercises.length;
        i++
      ) {
        for (
          let j = 0;
          j <
          state.userDataLectures[state.chosenLesson].processedExercises[i]
            .length;
          j++
        ) {
          for (
            let k = 0;
            k <
            state.userDataLectures[state.chosenLesson].processedExercises[i][j]
              .length;
            k++
          ) {
            state.userDataLectures[state.chosenLesson].processedExercises[i][j][
              k
            ] = 0;
          }
        }
      }

      state.savedExerciseData = [];
      state.userDataLectures[state.chosenLesson].savedExerciseData = [];
      state.userDataLectures[state.chosenLesson].started = false;
    },

    getUserData({ state }, payload) {
      // payload for adding savedExerciseData
      let totalNumberOfExercises = 0;
      let completelyFinishedExercises = 0;

      function checkStatus(element) {
        if (element >= 6 && element <= 9) {
          return true;
        } else {
          return false;
        }
      }

      for (let i = 0; i < state.processedExercises.length; i++) {
        for (let j = 0; j < state.processedExercises[i].length; j++) {
          for (let k = 0; k < state.processedExercises[i][j].length; k++) {
            totalNumberOfExercises++;
            if (checkStatus(state.processedExercises[i][j][k])) {
              completelyFinishedExercises++;
            }
          }
        }
      }

      if (!(state.chosenLesson in state.userDataLectures)) {
        state.userDataLectures[state.chosenLesson] = {};
      }

      let maximumScore;
      if ("maximumScore" in state.userDataLectures[state.chosenLesson]) {
        if (
          state.achievedScore >
          state.userDataLectures[state.chosenLesson].maximumScore
        ) {
          maximumScore = state.achievedScore;
        } else {
          maximumScore =
            state.userDataLectures[state.chosenLesson].maximumScore;
        }
      } else {
        maximumScore = state.achievedScore;
      }

      state.userDataLectures[state.chosenLesson] = {
        ...state.userDataLectures[state.chosenLesson],
        ...{
          totalNumberOfExercises,
          completelyFinishedExercises,
          processedExercises: state.processedExercises,
          achievedScore: state.achievedScore,
          maximumScore,
          firstUnprocessedPage: state.firstUnprocessedPage
        }
      };

      if (
        !("savedExerciseData" in state.userDataLectures[state.chosenLesson])
      ) {
        state.userDataLectures[state.chosenLesson].savedExerciseData = [];
      }
      if (
        state.userDataLectures[state.chosenLesson].savedExerciseData[
          state.coursePage
        ] === null ||
        state.userDataLectures[state.chosenLesson].savedExerciseData[
          state.coursePage
        ] === undefined
      ) {
        state.userDataLectures[state.chosenLesson].savedExerciseData[
          state.coursePage
        ] = {};
      }
      if (payload) {
        state.userDataLectures[state.chosenLesson].savedExerciseData[
          state.coursePage
        ] = {
          ...state.userDataLectures[state.chosenLesson].savedExerciseData[
            state.coursePage
          ],
          ...payload
        };
      }
    },

    saveUserDataOnDatabase({ state }, listOfLessons) {
      if (state.isRestricted && !state.isLicensed) {
        state.this.setPage(-1);
        return;
      }

      let user = firebase.auth().currentUser;
      if (user === null || !state.userDataIsFetched) {
        return;
      }

      let updates = {};
      if (state.listOfProgressedCourses.length > 0) {
        updates["/user/" + user.uid + "/listOfProgressedCourses"] =
          state.listOfProgressedCourses;
      }

      if (state.indexOfNotificationOnFirstLogin !== false) {
        updates["/user/" + user.uid + "/indexOfNotificationOnFirstLogin"] =
          state.indexOfNotificationOnFirstLogin;
      }
      if (state.listOfReadNotifications.length > 0) {
        updates["/user/" + user.uid + "/listOfReadNotifications"] =
          state.listOfReadNotifications;
      }
      if (state.welcomeMessageReadAtDate !== 0) {
        updates["/user/" + user.uid + "/welcomeMessageReadAtDate"] =
          state.welcomeMessageReadAtDate;
      }
      if (state.onboardingDesktopWasShown !== false) {
        updates["/user/" + user.uid + "/onboardingDesktopWasShown"] =
          state.onboardingDesktopWasShown;
      }
      if (state.onboardingPhoneWasShown !== false) {
        updates["/user/" + user.uid + "/onboardingPhoneWasShown"] =
          state.onboardingPhoneWasShown;
      }

      if (state.userDataLectures[state.chosenLesson] !== {}) {
        updates["/user/" + user.uid + "/userData/" + state.chosenLesson] =
          state.userDataLectures[state.chosenLesson];
      }

      if (listOfLessons !== undefined && listOfLessons.length > 0) {
        for (let i = 0; i < listOfLessons.length; i++) {
          updates["/user/" + user.uid + "/userData/" + listOfLessons[i]] =
            state.userDataLectures[listOfLessons[i]];
        }
      }

      if (
        state.userDataLectures[state.chosenLesson].started &&
        state.chosenLessonVersion > 0
      ) {
        updates[
          "/user/" + user.uid + "/processedVersions/" + state.chosenLesson
        ] = state.chosenLessonVersion;
      }

      if (listOfLessons !== undefined && listOfLessons.length > 0) {
        updates["/user/" + user.uid + "/processedVersions"] = [];
      }

      firebase
        .database()
        .ref()
        .update(updates)
        .catch(error => {
          alert(error);
          if (error.code === "PERMISSION_DENIED") {
            // dispatch('logout')
          }
        });
    },

    setExerciseStatus({ state, dispatch }, payload) {
      // payload: exercise, value. Or: Only value.
      // value: 0 - reset, 1 - begun, 2 - wrong not finished, 3 - wrong finished, 4 - correct.

      let section = state.pageBelongsToSection[state.coursePage];
      let page = state.pageNumberInSection[state.coursePage];

      // Handle reset of whole page
      if (typeof payload === "number" && payload === 0) {
        let subarray = state.processedExercises[section];
        for (let i = 0; i < subarray[page].length; i++) {
          let code = state.processedExercises[section][page][i];
          if (code === 4 || code === 6 || code === 7) {
            subarray[page][i] = 1;
          } else if (code === 5 || code === 8 || code === 9) {
            subarray[page][i] = 2;
          } else if (code === 3) {
            subarray[page][i] = 0;
          }
        }
        state.processedExercises[section] = subarray;

        dispatch("updateCourse", state.courseData);
        return;
      }

      // Handle individual exercises
      let exercise = typeof payload === "number" ? 0 : payload[0];
      let code = state.processedExercises[section][page][exercise];
      let value = typeof payload === "number" ? payload : payload[1];
      let newCode = code;

      if (code === 0 || code === 3) {
        if (value === 1) {
          newCode = 3;
        } else if (value === 2) {
          newCode = 4;
        } else if (value === 3) {
          newCode = 6;
        } else if (value === 4) {
          newCode = 9;
        }
      } else if (code === 1 || code === 4) {
        if (value === 0) {
          newCode = 1;
        } else if (value === 1 || value === 2) {
          newCode = 4;
        } else if (value === 3) {
          newCode = 6;
        } else {
          newCode = 7;
        }
      } else if (code === 2 || code === 5) {
        if (value === 0) {
          newCode = 2;
        } else if (value === 1 || value === 2) {
          newCode = 5;
        } else if (value === 3) {
          newCode = 8;
        } else {
          newCode = 9;
        }
      } else if (code === 6 || code === 7) {
        if (value === 0) {
          newCode = 1;
        }
      } else if (code === 8 || code === 9) {
        if (value === 0) {
          newCode = 2;
        }
      }

      let subarray = state.processedExercises[section];
      subarray[page][exercise] = newCode;
      state.processedExercises[section] = subarray;

      dispatch("updateCourse", state.courseData);
    },

    updateCourse({ state, dispatch }) {
      let counter = 0;
      if (
        state.coursePage >= 1 &&
        state.coursePage + 1 !== state.numberOfPages
      ) {
        for (
          let i = 0;
          i <
          state.processedExercises[
            state.pageBelongsToSection[state.coursePage]
          ][state.pageNumberInSection[state.coursePage]].length;
          i++
        ) {
          if (
            state.processedExercises[
              state.pageBelongsToSection[state.coursePage]
            ][state.pageNumberInSection[state.coursePage]][i] >= 6
          ) {
            counter++;
          }
        }
      }

      let html = document.getElementsByTagName("html")[0];
      let progressBar =
        100 *
          ((state.stepsUpToPage[state.coursePage] + counter) /
            state.numberOfSteps) +
        "%";
      html.style.setProperty("--header-progress-bar", progressBar);
      state.progressBar =
        100 *
        ((state.stepsUpToPage[state.coursePage] - 1 + counter) /
          state.numberOfSteps);

      let processedSections = [];
      let recentSection = state.pageBelongsToSection[state.coursePage];
      state.recentSection = recentSection;
      let courseWasStarted = false;
      for (let i = 1; i <= state.numberOfSections; i++) {
        let bigSet = new Set([]);

        // Delete user course data if course has changed
        if (state.processedExercises[i] === undefined) {
          state.userDataLectures[state.chosenLesson] = {};
          for (let i = 0; i < state.listOfProgressedCourses.length; i++) {
            if (state.chosenLesson === state.listOfProgressedCourses[i][0]) {
              state.listOfProgressedCourses.splice(i, 1);
            }
          }
          dispatch("saveUserDataOnDatabase");
          sessionStorage.reloadAfterPageLoad = 1;
          location.reload();
          return;
        }
        // // //

        for (let j = 0; j < state.processedExercises[i].length; j++) {
          let smallSet = new Set(state.processedExercises[i][j]);
          bigSet = new Set([...bigSet, ...smallSet]);
        }
        if (
          !bigSet.has(3) &&
          !bigSet.has(4) &&
          !bigSet.has(5) &&
          !bigSet.has(6) &&
          !bigSet.has(7) &&
          !bigSet.has(8) &&
          !bigSet.has(9)
        ) {
          processedSections[i - 1] = 0;
        } else if (bigSet.has(0) || bigSet.has(1) || bigSet.has(2)) {
          if (
            state.processedSections[i - 1] === 1 ||
            i !== recentSection ||
            state.coursePage + 1 === state.numberOfPages
          ) {
            processedSections[i - 1] = 1;
          } else {
            processedSections[i - 1] = 0;
          }
        } else if (bigSet.has(3) || bigSet.has(4) || bigSet.has(5)) {
          if (state.processedSections[i - 1] === 1 || i !== recentSection) {
            processedSections[i - 1] = 1;
          }
        } else {
          processedSections[i - 1] = 2;
        }

        if (bigSet.has(6) || bigSet.has(7) || bigSet.has(8) || bigSet.has(9)) {
          courseWasStarted = true;
        }
      }
      state.processedSections = processedSections;
      // state.courseWasStarted = courseWasStarted;

      if (
        state.chosenLesson in state.userDataLectures &&
        "started" in state.userDataLectures[state.chosenLesson] &&
        state.userDataLectures[state.chosenLesson].started === "finished"
      ) {
        courseWasStarted = "finished";
      }
      state.userDataLectures[state.chosenLesson].started = courseWasStarted;

      let achievedScore = 0;
      for (let i = 1; i < state.processedExercises.length; i++) {
        for (let j = 0; j < state.processedExercises[i].length; j++) {
          for (let k = 0; k < state.processedExercises[i][j].length; k++) {
            if (
              state.processedExercises[i][j][k] === 2 ||
              state.processedExercises[i][j][k] === 5 ||
              state.processedExercises[i][j][k] === 8 ||
              state.processedExercises[i][j][k] === 9
            ) {
              achievedScore++;
            }
          }
        }
      }
      state.achievedScore = achievedScore;

      // Set recentPage
      if (state.coursePage === 0) {
        state.recentPage = state.courseData[0][0];
      } else if (state.coursePage + 1 === state.numberOfPages) {
        state.recentPage = state.courseData[state.numberOfSections + 1][0];
      } else if (state.coursePage >= 1) {
        state.recentPage =
          state.courseData[state.pageBelongsToSection[state.coursePage]][
            state.pageNumberInSection[state.coursePage]
          ];
      } else {
        state.recentPage = {};
      }

      if (state.coursePage > 0) {
        dispatch("getFirstUnprocessedPage");
        dispatch("checkIfCourseIsFinished");
        // dispatch("createListOfProgressedCourses");
        dispatch("saveExerciseData");
        dispatch("getUserData");
        dispatch("createListOfProgressedCourses");
        // // // alert(JSON.stringify(state.processedExercises, null, 4));
      }
    },

    // Handle audio
    toggleAudio({ state }) {
      state.soundIsTurnedOn = !state.soundIsTurnedOn;
      localStorage.setItem("soundIsTurnedOn", state.soundIsTurnedOn);

      if (!state.soundIsTurnedOn) {
        let sounds = document.getElementsByTagName("audio");
        for (let i = 0; i < sounds.length; i++) sounds[i].pause();
      }
    },

    // Hover intent and second progress bar
    hoverIntent({ state }, payload) {
      if (payload[0] === "setTimeout") {
        if (state.selectedSection === -1) {
          state.timeout = setTimeout(function() {
            state.selectedSection = payload[1] - 1;
          }, 200);
        } else {
          clearTimeout(state.timeout2);
          state.selectedSection = payload[1] - 1;
        }
      } else {
        clearTimeout(state.timeout);
        state.timeout2 = setTimeout(function() {
          state.selectedSection = -1;
        }, 0);
      }
    },
    hoverIntentAuxiliary({ state }, payload) {
      if (payload === "in") {
        setTimeout(() => {
          state.timeoutIsSet = true;
        }, 10);
      } else {
        setTimeout(() => {
          state.timeoutIsSet = false;
        }, 5);
      }
    }
  },
  modules: {}
});
