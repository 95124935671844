<template>
  <svg viewBox="0 0 32 32" role="presentation">
    <title :class="icon" lang="en">{{ icon }} icon</title>
    <g>
      <component
        :is="'icon-' + icon"
        :icon-color="color"
        :icon-stroke="iconStroke"
      />
    </g>
  </svg>
</template>

<script>
import IconLock from "./Lock";
import IconMail from "./Mail";
import IconRefresh from "./Refresh";

export default {
  components: {
    IconLock,
    IconMail,
    IconRefresh
  },

  props: {
    icon: {
      type: String,
      default: "box"
    },
    color: {
      type: String,
      default: "currentColor"
    },
    stroke: {
      type: Number,
      default: 1
    },
    iconWidth: {
      type: Number,
      default: 3.2 // Standard width of 3.2rem
    }
  },
  computed: {
    iconStroke() {
      return (31 * this.stroke) / (10 * this.iconWidth - this.stroke);
    }
  }
};

/* Use as follows:
<app-icon icon="sound" color="red" stroke=2/>
color and stroke optional; defaults:
color = currentColor, stroke = 1 */
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -1px;
  overflow: visible;
  width: 3.2rem;
  height: 3.2rem;
}
</style>
