<template>
  <div class="features">
    <h2 class="heading-secondary features__heading">
      {{ $t("features").heading }}
    </h2>
    <p class="features__introduction">
      {{ $t("features").introduction1 }} <br />
      <br />
      {{ $t("features").introduction2 }}
    </p>
    <div class="features__outer-container">
      <div class="features__text-container div1">
        <div class="features__header">
          {{ $t("features").feature1.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature1.text }}
        </div>
      </div>
      <div class="features__video-container div2">
        <video
          muted
          playsinline
          preload
          v-show="$i18n.locale === 'de' || $i18n.locale === 'en'"
          class="features__video features__video--0-de"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_1_de.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video>
        <!-- <video
          muted
          playsinline
          preload="none"
          v-show="$i18n.locale !== 'de'"
          class="features__video features__video--0-en"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_1_en.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video> -->
      </div>
      <div class="features__video-container div3">
        <video
          muted
          playsinline
          preload
          v-show="$i18n.locale === 'de' || $i18n.locale === 'en'"
          class="features__video features__video--1-de"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_2_de.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video>
        <!-- <video
          muted
          playsinline
          preload="none"
          v-show="$i18n.locale !== 'de'"
          class="features__video features__video--1-en"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_2_en.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video> -->
      </div>
      <div class="features__text-container div4">
        <div class="features__header">
          {{ $t("features").feature2.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature2.text }}
        </div>
      </div>
      <div class="features__text-container div5">
        <div class="features__header">
          {{ $t("features").feature3.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature3.text }}
        </div>
      </div>
      <div class="features__video-container div6">
        <video
          muted
          playsinline
          preload
          v-show="$i18n.locale === 'de' || $i18n.locale === 'en'"
          class="features__video features__video--2-de"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_3_de.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video>
        <!-- <video
          muted
          playsinline
          preload="none"
          v-show="$i18n.locale !== 'de'"
          class="features__video features__video--2-en"
        >
          <source
            src="@/assets/Home/Features/video_smartphone_3_en.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video> -->
      </div>
      <div class="features__video-container div7">
        <video
          muted
          playsinline
          preload
          v-show="$i18n.locale === 'de' || $i18n.locale === 'en'"
          class="features__video features__video--3-de"
        >
          <source
            src="@/assets/Home/Features/laptop_de.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video>
        <!-- <video
          muted
          playsinline
          preload="none"
          v-show="$i18n.locale !== 'de'"
          class="features__video features__video--3-en"
        >
          <source
            src="@/assets/Home/Features/laptop_en.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video> -->
      </div>
      <div class="features__text-container div8">
        <div class="features__header">
          {{ $t("features").feature4.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature4.text }}
        </div>
      </div>
      <div class="features__text-container div9">
        <div class="features__header">
          {{ $t("features").feature5.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature5.text }}
        </div>
      </div>
      <div class="features__video-container div10">
        <picture>
          <!-- <source
            type="image/avif"
            srcset="@/assets/Home/Features/virus.avif"
          /> -->
          <source
            type="image/webp"
            srcset="@/assets/Home/Features/virus.webp"
          />
          <img
            src="@/assets/Home/Features/virus.png"
            alt="Ein Virus"
            class="features__image"
            :class="{ 'features__image--rotated': imageIsRotated }"
          />
        </picture>
      </div>
      <div class="features__video-container div11">
        <!-- <video
          muted
          preload
          playsinline
          autoplay
          loop
          class="features__video features__video--gold"
        >
          <source
            src="@/assets/videos/signet_gold.mp4#t=0.01"
            type="video/mp4"
          />
          Dein Browser kann dieses Video nicht abspielen.
        </video> -->
        <img
          src="@/assets/Home/Features/signet_gold.gif"
          alt="Signet von Mathopia"
          class="features__video features__video--gold"
        />
      </div>
      <div class="features__text-container div12">
        <div class="features__header">
          {{ $t("features").feature6.heading }}
        </div>
        <div class="features__container">
          {{ $t("features").feature6.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      englishVideosLoaded: false,
      video1HasPlayed: false,
      video2HasPlayed: false,
      video3HasPlayed: false,
      video4HasPlayed: false,
      imageIsRotated: false
      // effectsAreReady: false
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    }
  },
  methods: {
    scrollHandlerOverview() {
      /* if (!this.effectsAreReady) {
        return;
      } */

      let sectionTop = this.top(this.section);
      let windowHeight = this.$store.state.windowHeight;

      if (this.top(this.div2) <= windowHeight / 2 && !this.video1HasPlayed) {
        this.video1HasPlayed = true;
        if (this.language === "de" || this.language === "en") {
          this.video1_de.play();
        } else {
          // this.video1_en.play();
        }
      } else if (sectionTop >= windowHeight) {
        if (this.englishVideosLoaded) {
          // this.video1_en.currentTime = 0;
        }
        // this.video1_en.currentTime = 0;
        this.video1_de.currentTime = 0;
        this.video1HasPlayed = false;
      }

      if (this.top(this.div3) <= windowHeight / 2 && !this.video2HasPlayed) {
        this.video2HasPlayed = true;
        if (this.language === "de" || this.language === "en") {
          this.video2_de.play();
        } else {
          // this.video2_en.play();
        }
      } else if (sectionTop >= windowHeight) {
        if (this.englishVideosLoaded) {
          // this.video2_en.currentTime = 0;
        }
        // this.video2_en.currentTime = 0;
        this.video2_de.currentTime = 0;
        this.video2HasPlayed = false;
      }

      if (this.top(this.div6) <= windowHeight / 2 && !this.video3HasPlayed) {
        this.video3HasPlayed = true;
        if (this.language === "de" || this.language === "en") {
          this.video3_de.play();
        } else {
          // this.video3_en.play();
        }
      } else if (sectionTop >= windowHeight) {
        if (this.englishVideosLoaded) {
          // this.video3_en.currentTime = 0;
        }
        // this.video3_en.currentTime = 0;
        this.video3_de.currentTime = 0;
        this.video3HasPlayed = false;
      }

      if (this.top(this.div7) <= windowHeight / 2 && !this.video4HasPlayed) {
        this.video4HasPlayed = true;
        if (this.language === "de" || this.language === "en") {
          this.video4_de.play();
        } else {
          // this.video4_en.play();
        }
      } else if (sectionTop >= windowHeight) {
        if (this.englishVideosLoaded) {
          // this.video4_en.currentTime = 0;
        }
        // this.video4_en.currentTime = 0;
        this.video4_de.currentTime = 0;
        this.video4HasPlayed = false;
      }

      if (this.top(this.div10) <= windowHeight / 2) {
        this.imageIsRotated = true;
      } else if (sectionTop >= windowHeight) {
        this.imageIsRotated = false;
      }
    },
    readjustVideos() {
      setTimeout(() => {
        // this.video1_en = document.querySelector(".features__video--0-en");
        // this.video2_en = document.querySelector(".features__video--1-en");
        // this.video3_en = document.querySelector(".features__video--2-en");
        // this.video4_en = document.querySelector(".features__video--3-en");

        this.englishVideosLoaded = true;

        if (this.video1HasPlayed) {
          this.video1_en.currentTime = 10;
          this.video1_de.currentTime = 10;
        }
        if (this.video2HasPlayed) {
          this.video2_en.currentTime = 10;
          this.video2_de.currentTime = 10;
        }
        if (this.video3HasPlayed) {
          this.video3_en.currentTime = 10;
          this.video3_de.currentTime = 10;
        }
        if (this.video4HasPlayed) {
          this.video4_en.currentTime = 10;
          this.video4_de.currentTime = 10;
        }
      }, 500);
    }
  },
  created() {
    setTimeout(() => {
      this.section = document.querySelector(".features");

      this.div2 = document.querySelector(".div2");
      this.div3 = document.querySelector(".div3");
      this.div6 = document.querySelector(".div6");
      this.div7 = document.querySelector(".div7");
      this.div10 = document.querySelector(".div10");

      /* setTimeout(() => {
        let gold = document.querySelector(".features__video--gold");
        setTimeout(() => {
          gold.play();
        }, 2000);
      }, 3000); */

      // this.video1_en = document.querySelector(".features__video--0-en");
      this.video1_de = document.querySelector(".features__video--0-de");
      // this.video2_en = document.querySelector(".features__video--1-en");
      this.video2_de = document.querySelector(".features__video--1-de");
      // this.video3_en = document.querySelector(".features__video--2-en");
      this.video3_de = document.querySelector(".features__video--2-de");
      // this.video4_en = document.querySelector(".features__video--3-en");
      this.video4_de = document.querySelector(".features__video--3-de");

      this.scrollHandlerOverview();
      window.addEventListener("scroll", this.scrollHandlerOverview);

      // this.effectsAreReady = true;
    }, 500);
  },
  watch: {
    language() {
      this.readjustVideos();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.div2 {
  @include respond(phone) {
    grid-row: 1 / 2;
  }
}

.div6 {
  @include respond(phone) {
    grid-row: 5 / 6;
  }
}

.div10 {
  @include respond(phone) {
    grid-row: 9 / 10;
  }
}

$box-width: calc(50vw - max(50vw - 51.2rem, 3rem) - 0.75rem);

.features {
  position: relative;
  // padding: 0, calc(50% - 51.2rem);
  padding: 0 max(50% - 51.2rem, 3rem);
  padding-top: 12rem;
  padding-bottom: 12rem;

  min-height: 100vh;
  background-color: #131313;

  @include respond(tab-port) {
    padding: 12rem 3rem;
  }

  @include respond(phone) {
    padding: 21rem 1.5rem 12rem; // was: 12rem 1.5rem
    // background-color: #181818;

    background: linear-gradient(
      to bottom,
      $color-black 0,
      $color-black 9rem,
      #181818 9rem,
      #181818 100%
    );
  }

  &__heading {
    position: relative;
    grid-column: 2 / 3;
    width: max-content;

    @include respond(phone) {
      width: auto;
      line-height: 1.3;
    }
  }

  &__introduction {
    grid-column: 2 / 3;
    align-self: start;

    margin-top: 5.75rem;
    width: min(90rem, 100%);
    font-size: 2.6rem;
    line-height: 1.6;
    color: #d4d5d6;
    // color: red;

    @include respond(tab-land) {
      // width: min(80rem, 100%);
    }

    @include respond(tab-port) {
      width: min(80rem, 100%);
      font-size: 2.8rem;
      line-height: 1.5;
      color: #dcdcdc;
    }

    @include respond(phone) {
      margin-bottom: -1.5rem;
    }
  }

  &__outer-container {
    margin-top: 12rem;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50.45rem 50.45rem 50.45rem 50.45rem 50.45rem 50.45rem;
    // grid-template-rows: 45.4rem 45.4rem 45.4rem 45.4rem 45.4rem 45.4rem;
    grid-template-rows: $box-width $box-width $box-width $box-width $box-width $box-width;
    grid-gap: 1.5rem;

    @include respond(tab-port) {
      // grid-template-rows: 47.45rem 47.45rem 47.45rem 47.45rem 47.45rem 47.45rem;
    }

    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, calc(100vw - 3rem) max-content);
    }
  }

  &__text-container {
    padding: 2.5rem;
    padding-top: 4.5rem;
    border-radius: 1rem;
    background-color: #1a1a1a;

    @include respond(phone) {
      margin-top: -0.5rem;
      margin-bottom: 9rem;
      padding: 2.25rem 0 0rem;
      background-color: transparent;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__video-container {
    position: relative;
    border-radius: 1rem;
    background-color: $color-black;

    // height: 45.5rem;

    overflow: hidden;
  }

  &__video {
    position: absolute;
    width: 100%;
    border-radius: 1rem;

    @include respond(phone) {
      height: calc(100vw - 3rem);
    }

    &--gold {
      position: absolute;
      left: calc(50% - 34rem);
      top: calc(50% - 19.125rem);
      width: 68rem;

      @include respond(phone) {
        left: calc(50% - 42rem);
        top: calc(50% - 23.625rem);
        width: 84rem;
        height: 47.25rem;
      }
    }
  }

  &__image {
    position: absolute;
    left: calc(50% - 11.45rem);
    top: calc(50% - 11.45rem);
    transform: rotate(45deg);
    width: 22.9rem;

    transition: transform 4.5s;
    // pointer-events: none;

    &--rotated {
      transform: none;
    }

    @include respond(phone) {
      left: calc(50% - 12.722rem);
      top: calc(50% - 12.722rem);
      width: 25.444rem;
    }
  }

  /* &__explanation-container {
    position: relative;
    transform: translateY(-28rem);
    height: 50rem;
    width: 60rem;
  }

  &__explanation {
    position: absolute;
    margin-top: 4rem;
    font-size: 2.4rem;
    color: rgb(200, 200, 200);
    transition: opacity 1s;
  } */

  &--is-shown {
    opacity: 1;
  }

  &__header {
    font-family: $font-primary-bold;
    font-size: 2.8rem !important;
    margin-bottom: 2rem;
    color: $color-white;
    z-index: 1;
    line-height: 1.3;

    @include respond(phone) {
      font-size: 3rem !important;
    }
  }

  &__container {
    font-family: $font-primary;
    font-size: 2rem;
    color: #b4b4b4;
    z-index: 1;

    @include respond(tab-port) {
      font-size: 2.5rem;
      line-height: 1.5;
      color: #dcdcdc;
    }

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__explanation-text {
    color: #b5b6bc;
    margin-top: 1.5rem;
    width: 54rem;
    padding-right: 10rem;
    font-family: $font-primary;
    font-size: 2.2rem;
    line-height: 1.4;
    z-index: 1;
  }

  &__subheading {
    position: relative;
    margin-bottom: 3rem;
    color: $color-white;

    width: 57rem;
    font-family: $font-primary-bold;
    font-size: 3.6rem;
    font-weight: 100;
    margin-bottom: 2rem;
    z-index: 1;
    line-height: 1.3;
  }
}
</style>
