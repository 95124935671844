<template>
  <g
    :style="{ stroke: iconColor, strokeWidth: iconStroke, fill: 'transparent' }"
  >
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M24.133 19.733l4.267-2.533 2.667 4.267"
    ></path>
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M28.4 17.2c-0.533 6.933-6.667 12-13.6 11.333-6.933-0.533-12-6.667-11.467-13.6s6.667-12 13.6-11.467c3.333 0.267 6.4 1.867 8.4 4.267"
    ></path>
  </g>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "currentColor"
    },
    iconStroke: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style scoped>
path {
  stroke-width: inherit;
}
</style>
