<template>
  <div
    class="picture-choice"
    :class="{
      'picture-choice--activated': activated,
      'picture-choice--deactivated': deactivated,
      'picture-choice--inactive': inactive,
      'picture-choice--wrong': wrong
    }"
  >
    <img
      v-if="icon !== ''"
      :src="require('@/assets/Internal/Course/Picture/' + shownIcon + '.svg')"
      alt="Illustration of the arrow to be drawn"
      class="picture-choice__icon"
    />
    <div class="picture-choice__icon-text">
      {{ t(iconText) }}
    </div>
    <div class="picture-choice__caption">
      {{ t(caption) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "pictureChoice",
  props: {
    icon: String,
    iconText: String,
    caption: Object,
    activated: Boolean,
    deactivated: Boolean,
    inactive: Boolean,
    wrong: Boolean
  },
  computed: {
    shownIcon() {
      if (this.deactivated && this.inactive) {
        return "notAllowed";
      } else {
        return this.icon;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.picture-choice {
  float: left;
  position: relative;
  margin-bottom: 1.125rem;
  width: 12rem;
  // height: 100%;
  height: 12rem;
  background-color: $color-black;
  // border: 1px solid #303030;
  border-radius: 0.2rem;

  transition: transform 0.2s, background-color 0.2s;
  cursor: pointer;
  user-select: none;

  // overflow: hidden;

  @include respond(phone) {
    float: initial;
    justify-self: center;
    margin-bottom: 0;
    width: 28vw;
    height: 28vw;
  }

  &--activated {
    transform: translateY(-1rem);

    @include respond(phone) {
      transform: none;
    }
  }

  &--deactivated {
    opacity: 0.333;
    transform: translateY(1rem);

    @include respond(phone) {
      transform: none;
    }
  }

  &--inactive {
    opacity: 0.333;
    cursor: initial;

    &.picture-choice--deactivated .picture-choice__icon {
      transform: translate(-50%, -50%) scale(0.9);
      opacity: 0.3;
    }
  }

  &--wrong {
    animation: shake 0.667s ease-in-out;

    .picture-choice__caption {
      color: $color-black;
      background-color: $color-wrong !important;
      transition: none;
    }

    & .picture-choice__icon {
      filter: none;
    }
    // background-color: pink;
  }

  &:not(:last-child) {
    margin-right: 1.125rem;

    @include respond(phone) {
      margin-right: 0;
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: calc(50% - 1.5rem);
    transform: translate(-50%, -50%);
    width: 4.5rem;
    height: 4.5rem;
    pointer-events: none;

    filter: brightness(1000);

    @include respond(phone) {
      top: calc(50% - 3.5vw);
      width: 9vw;
      height: 9vw;
    }
  }

  &__icon-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 1.5rem));
    width: 100%;
    text-align: center;
    font-size: 2rem;
    line-height: 1.4;
  }

  &__caption {
    position: absolute;
    bottom: 0;
    z-index: 20000;
    width: 100%;
    height: 3rem;
    background-color: #303030;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;

    font-family: $font-primary-bold;
    font-size: 1.6rem;
    line-height: 3rem;
    text-align: center;

    transition: background-color 0.2s;

    @include respond(phone) {
      height: 7vw;
      font-size: 2rem;
      line-height: 7vw;
    }

    @media (hover: hover) and (pointer: fine) {
      .picture-choice:hover & {
        background-color: $color-primary-dark;
      }
    }

    .picture-choice--activated & {
      background-color: $color-primary-dark;
    }

    .picture-choice--inactive.picture-choice--deactivated & {
      color: $color-black;
    }

    .picture-choice--inactive:hover & {
      background-color: #303030;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}
</style>
