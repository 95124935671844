<template>
  <div>
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1e3 1e3"
      style="enable-background:new 0 0 1e3 1e3"
      v-if="icon === 'earth'"
    >
      <g>
        <path
          class="icon-earth"
          d="M483.6 65.3c10.2-3.7 21.7-3.7 31.9.1 11.3 4.1 21.1 11.4 29.6 19.9 13.7 13.4 24.5 29.5 33.9 46.2 22.6 40.6 37.3 85.1 48.3 130.1-39.5 4.4-79.3 6.4-119.1 7-36.9.2-73.8-1.4-110.6-4.4-8.7-.8-17.4-1.4-26-2.6 11.3-45.4 25.9-90.4 49-131.3 9.1-15.9 19.5-31.3 32.6-44.2C461.8 77.2 471.9 69.4 483.6 65.3z"
        />
        <path
          class="icon-earth"
          d="M229.7 154.8c45.8-35.9 99-62.2 155.2-77.4-34.7 53.3-58.5 113.2-72.3 175.1-35.6-5.6-70.7-14.1-105-24.8-13.3-4-26.3-9-39.5-13.5C186.3 192.2 207.3 172.5 229.7 154.8z"
        />
        <path
          class="icon-earth"
          d="M614.1 78.2c33.7 9.3 66.5 22.1 97.2 39 46.1 25.1 87.5 58.8 121.7 98.7-47.4 17.7-96.5 30.6-146.5 38.6C673.4 192 648.6 132 614.1 78.2z"
        />
        <path
          class="icon-earth"
          d="M130.9 262c55 21.8 112.3 37.8 170.6 47.5-8.7 52.7-13.8 106-14.9 159.5-74.7.0-149.4.0-224.1.0 2.2-31.4 7.6-62.5 16.5-92.7C90.7 336 108.4 297.5 130.9 262z"
        />
        <path
          class="icon-earth"
          d="M698.5 310.5c58.7-9.1 116.4-24.8 171.6-46.6 39.4 61.2 62.4 132.5 67.4 205-75 0-150.1.0-225.1.0C711.7 415.9 707 362.9 698.5 310.5z"
        />
        <path
          class="icon-earth"
          d="M359.7 319.5c36.8 3.4 73.6 5.5 110.5 6.4 56.1 1.6 112.3-1.2 168.2-6.4 7.9 50.1 12.9 100.7 13.9 151.5-102.2.0-204.4.0-306.6.0.7-39.4 3.3-78.7 8.6-117.8C355.9 342 357.5 330.7 359.7 319.5z"
        />
        <path
          class="icon-earth"
          d="M134 528.7c50.9-.2 101.8-.6 152.6-.6.7 57.1 6.4 114.2 16.8 170.3-56.9 8.8-112.9 23.8-166.6 44.5-38.3-56.8-62.2-122.9-71-190.8-1.1-7.7-1.6-15.4-2.5-23.1C87 528.6 110.5 529.2 134 528.7z"
        />
        <path
          class="icon-earth"
          d="M712.3 528.1c74.7.0 149.4.0 224.1.0-4.8 77.7-31.8 153.4-75.2 217.8-53.4-21-109.1-36.5-165.8-45.5C705.6 643.5 711.1 585.8 712.3 528.1z"
        />
        <path
          class="icon-earth"
          d="M345.7 529c102.9.0 205.7.0 308.6.0-1 54.8-6.9 109.3-15.8 163.4-73-7.9-146.7-10-220.1-5.6-19 1.1-37.9 2.7-56.8 4.6C352.3 637.7 347 583.4 345.7 529z"
        />
        <path
          class="icon-earth"
          d="M432.2 744c22.3-1.3 44.6-2.2 67-2.6 41.8.5 83.7 2.5 125.3 7.1-11.6 44.8-26.6 89.3-50.5 129.1-9 15-19.6 29.2-32.6 40.9-10.9 9.9-24.5 18.2-39.6 18.9-13.2.8-25.9-4.8-36.3-12.5-15.2-11.2-27-26.3-37.1-42.1-17-26.9-29.5-56.3-39.7-86.4-5.4-15.7-9.7-31.8-14.1-47.8C393.6 746.3 412.9 745.3 432.2 744z"
        />
        <path
          class="icon-earth"
          d="M175.3 792.9c45.9-15.9 93.3-27.6 141.2-35.4 13 58.2 36.2 114.1 68.4 164.2-64.6-17.5-125.4-49.6-175.4-94.2-10-8.9-19.7-18.3-28.8-28.2C178.9 797.1 176.6 795.3 175.3 792.9z"
        />
        <path
          class="icon-earth"
          d="M683.5 758.3c47.6 7 94.5 18.8 139.5 35.7-34.6 37.5-75.4 69.3-120.5 93.1-25.7 13.5-52.7 24.4-80.6 32.6-2.3.7-4.7 1.1-6.9 2.1C647.4 872 670.7 816.3 683.5 758.3z"
        />
      </g>
    </svg>

    <svg
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style="enable-background:new 0 0 100 100"
      v-if="icon === 'bubble'"
    >
      <path
        class="icon-bubble"
        d="M87.8.0H12.2C5.4.0.0 5.1.0 11.4v57.4c0 6.3 5.4 11.4 12.2 11.4h8v17c0 1 .7 2 1.7 2.4.4.2.8.2 1.2.2.7.0 1.3-.2 1.9-.6l25.2-19.1h37.6c6.8.0 12.2-5.1 12.2-11.4V11.4C1e2 5.1 94.6.0 87.8.0z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "icons",
  props: {
    icon: String
  }
};
</script>
