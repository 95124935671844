import de from "./translations/de";
import en from "./translations/en";

export default {
  de,
  en
};

export const language = {
  methods: {
    $t(text, getAllLanguages) {
      if (getAllLanguages) {
        return {
          de: de[text],
          en: en[text]
        };
      }
      if (this.$i18n.locale === "de") {
        return de[text];
      } else {
        if (en[text] !== undefined) {
          return en[text];
        } else {
          return de[text];
        }
      }
    },
    setLanguage(newLanguage) {
      this.$i18n.locale = newLanguage;
      document.documentElement.lang = this.$i18n.locale;
      document.head.querySelector('meta[name="description"]').content = this.$t(
        "meta_description"
      );
      this.typesetMath();
      localStorage.setItem("language", newLanguage);

      if (this.$store.state.isAuthenticated) {
        this.$store.dispatch("saveLanguage");
      }
    }
  }
};
