<template>
  <g
    :style="{ stroke: iconColor, strokeWidth: iconStroke, fill: 'transparent' }"
  >
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M27.867 7.867l-10.533 9.467c-0.8 0.667-2 0.667-2.8 0l-10.667-9.467"
    ></path>
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M20.8 18.267l7.067 5.867"
    ></path>
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M10.933 18.267l-7.067 5.867"
    ></path>
    <path
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke-miterlimit="10"
      d="M0.667 7.733v16.533c0 1.733 1.333 3.067 3.067 3.067h24.4c1.733 0 3.067-1.333 3.067-3.067v-16.533c0-1.733-1.333-3.067-3.067-3.067h-24.4c-1.733 0-3.067 1.333-3.067 3.067z"
    ></path>
  </g>
</template>

<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "currentColor"
    },
    iconStroke: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style scoped>
path {
  stroke-width: inherit;
}
</style>
