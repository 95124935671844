<template>
  <teleport to="body">
    <div
      @click="closeModal"
      class="overlay"
      :class="{
        'sidebar-is-visible': sidebarIsVisible,
        'transform-sidebar': transformSidebar
      }"
    ></div>
    <component
      :is="shownSidebarMenu"
      class="sidebar"
      :class="{
        'sidebar-is-visible': sidebarIsVisible,
        'transform-sidebar': transformSidebar
      }"
    ></component>
  </teleport>
</template>

<script>
import AppExternalMenu from "@/components/Organisms/SidebarMenus/ExternalMenu.vue";

export default {
  name: "SidebarMenu",
  components: {
    AppExternalMenu
  },
  data() {
    return {
      sidebarIsVisible: false,
      transformSidebar: false,
      shownSidebarMenu: "none"
    };
  },
  computed: {
    modal() {
      return this.$store.state.shownSidebarMenu;
    }
  },
  methods: {
    closeModal() {
      this.$store.state.shownSidebarMenu = "none";
    }
  },
  watch: {
    modal(to) {
      if (to === "none") {
        this.transformSidebar = false;
        setTimeout(() => {
          this.sidebarIsVisible = false;
          this.shownSidebar = to;
        }, 425);
      } else {
        this.shownSidebarMenu = to;
        this.sidebarIsVisible = true;
        setTimeout(() => {
          this.transformSidebar = true;
        }, 15);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 6rem;
  z-index: 2000000;
  width: 100%;
  // width: 100rem;
  height: calc(100vh - 6rem);
  // height: 30rem;
  background-color: #444;
  opacity: 0;

  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 0.325s;

  &.transform-sidebar {
    opacity: 0.675;
    transition: opacity 0.325s;
  }
}

.sidebar {
  display: none;
  position: fixed;
  right: 0;
  top: 6rem;
  z-index: 2000000;
  // transform: translateX(100%);
  transform: translateX(32rem);
  height: 100vh;

  backface-visibility: hidden;
  will-change: transform;
  transition: transform 0.325s ease-in;

  &.transform-sidebar {
    transform: none;
    transition: transform 0.325s ease-out;
  }
}

.sidebar-is-visible {
  display: block;
}
</style>
