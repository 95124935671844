<template>
  <button
    class="hamburger"
    :class="{
      'menu-open': $store.state.shownSidebarMenu !== 'none' || mainMenuIsShown,
      'is-transforming': isTransforming
    }"
  >
    <div aria-hidden="true" class="hamburger__bar hamburger__bar--top"></div>
    <div aria-hidden="true" class="hamburger__bar hamburger__bar--center"></div>
    <div aria-hidden="true" class="hamburger__bar hamburger__bar--bottom"></div>
  </button>
</template>

<script>
export default {
  name: "hamburger",

  data() {
    return {
      isTransforming: false
    };
  },

  computed: {
    shownSidebarMenu() {
      return this.$store.state.shownSidebarMenu;
    },
    mainMenuIsShown() {
      return this.$store.state.mainMenuIsShown;
    }
  },

  watch: {
    shownSidebarMenu() {
      this.isTransforming = true;
      setTimeout(() => {
        this.isTransforming = false;
      }, 250);
    },
    mainMenuIsShown() {
      this.isTransforming = true;
      setTimeout(() => {
        this.isTransforming = false;
      }, 350); // was: 250
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.hamburger {
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  width: 8.4rem;
  height: 8.4rem;
  // transform: translateY(-0.2rem) !important;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:active {
    outline: none;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &::after {
    @include pseudo-element;
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;

    // left: -2rem;
    // width: 10rem;
    // height: 10rem;
  }

  &__bar {
    position: absolute;
    // left: 0;
    right: 0.75rem;
    top: 50%;
    // width: 100%;
    width: 33.33333%;
    height: 0.15rem;
    border-radius: 1px;
    background-color: $color-white;
    // transform: translateY(-0.05rem);

    will-change: transform;
    transition: transform 0.25s;

    &--top {
      transform: translateY(-0.9rem);
    }

    &--bottom {
      transform: translateY(0.9rem);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover &__bar {
      // transition: transform 0.25s 0.25s;

      &--top {
        transform: translateY(-1.1rem);
      }

      &--bottom {
        transform: translateY(1.1rem);
      }
    }
  }

  &.is-transforming.menu-open {
    .hamburger__bar {
      transform: translateY(0);
    }
  }

  &.menu-open {
    .hamburger__bar--top {
      transform: translateY(0) rotate(45deg);
    }

    .hamburger__bar--center {
      opacity: 0;
      transition: opacity 0s 0.25s;
    }

    .hamburger__bar--bottom {
      transform: translateY(0) rotate(-45deg);
    }
  }

  &.is-transforming {
    .hamburger__bar {
      transform: translateY(0);
    }

    .hamburger__bar--center {
      opacity: 0;
    }
  }
}
</style>
