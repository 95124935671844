<template>
  <teleport to="body">
    <div class="header">
      <app-mathopia-logo
        @click="logoNavigation()"
        class="header__logo"
        :class="{
          'header__logo--not-shown':
            $route.path.split('/')[1] === 'course' &&
            $store.state.coursePage >= 0
        }"
      ></app-mathopia-logo>
      <!-- <app-signetfull class="header__logo"></app-signetfull> -->
      <nav
        v-show="$store.state.isAuthenticated && $store.state.coursePage < 0"
        class="header__internal-navigation-container"
      >
        <ul
          v-show="$route.path.split('/')[1] !== ''"
          class="header__internal-navigation"
        >
          <li
            @mousedown="
              goTo('/overview');
              mainMenuIsShown = false;
            "
            class="header__internal-navigation-item"
            :class="{
              'header__internal-navigation-active-item':
                $route.path.split('/')[1] === 'overview'
            }"
          >
            {{ $t("internal_header")[0] }}
          </li>
          <li
            @mousedown="
              setPage(-2);
              goTo('/course/#/lectures');
              mainMenuIsShown = false;
            "
            class="header__internal-navigation-item"
            :class="{
              'header__internal-navigation-active-item':
                $route.path.split('/')[1] === 'course'
            }"
          >
            {{ $t("internal_header")[1] }}
          </li>
          <li
            @mousedown="
              goTo('/vocabulary');
              mainMenuIsShown = false;
            "
            class="header__internal-navigation-item"
            :class="{
              'header__internal-navigation-active-item':
                $route.path.split('/')[1] === 'vocabulary'
            }"
          >
            {{ $t("internal_header")[2] }}
          </li>
          <li
            @mousedown="
              goTo('/contact');
              mainMenuIsShown = false;
            "
            class="header__internal-navigation-item"
            :class="{
              'header__internal-navigation-active-item':
                $route.path.split('/')[1] === 'contact'
            }"
          >
            {{ $t("internal_header")[3] }}
          </li>
          <li
            @mousedown="
              goTo('/help');
              mainMenuIsShown = false;
            "
            class="header__internal-navigation-item"
            :class="{
              'header__internal-navigation-active-item':
                $route.path.split('/')[1] === 'help'
            }"
          >
            {{ $t("internal_header")[4] }}
          </li>
        </ul>
      </nav>
      <div
        class="header__breadcrumb-navigation"
        v-if="$store.state.coursePage === 0"
        v-show="$store.state.breadcrumbNavigationIsShown"
      >
        <app-button
          v-if="
            $store.state.coursePage === -1 &&
              $route.path.split('/')[1] === 'course'
          "
          @mousedown="setPage('--')"
          :text="$t('courses_back_to_selection')"
          variation="transparent"
          class="header__back-button"
        ></app-button>
        <span
          v-if="$store.state.coursePage === 0"
          v-html="t($store.state.chosenLecture)"
          @click="setPage(-2)"
          class="header__breadcrumb-navigation--item"
        ></span>
        <span
          v-if="$store.state.coursePage === 0"
          class="header__breadcrumb-slash"
          >&#8250;</span
        >
        <span
          v-if="$store.state.coursePage === 0"
          v-html="t($store.state.topicGroup)"
          @click="setPage(-1)"
          class="header__breadcrumb-navigation--item"
        ></span>
        <span
          v-if="$store.state.coursePage === 0"
          class="header__breadcrumb-slash"
          >&#8250;</span
        >
        <span
          v-if="$store.state.coursePage === 0"
          v-html="t($store.state.topic)"
          @click="setPage(-1)"
          class="header__breadcrumb-navigation--item"
        ></span>
      </div>
      <span
        v-if="
          $store.state.coursePage === 0 &&
            $route.path.split('/')[1] === 'course'
        "
        @click="$store.state.TOCisOpen = !$store.state.TOCisOpen"
        class="header__total-lesson-number"
      >
        {{ $store.state.numberOfSections }}
        <span v-if="$store.state.numberOfSections === 1">{{
          $t("internal_header")[5]
        }}</span>
        <span v-if="$store.state.numberOfSections !== 1">{{
          $t("internal_header")[6]
        }}</span>
        <span>, {{ $t("internal_header")[7] }} </span>
        <span>&nbsp;</span>
        <span v-if="$store.state.timeRequired !== undefined">
          {{ $store.state.timeRequired }}
        </span>
        <span v-if="!($store.state.timeRequired !== undefined)">
          {{ $store.state.estimatedRequiredTime }}
        </span>
        <span>&nbsp;</span>
        <span
          v-if="
            ($store.state.timeRequired !== undefined &&
              $store.state.timeRequired === 1) ||
              ($store.state.timeRequired === undefined &&
                $store.state.estimatedRequiredTime === 1)
          "
        >
          {{ $t("internal_header")[8] }}
        </span>
        <span
          v-if="
            ($store.state.timeRequired !== undefined &&
              $store.state.timeRequired !== 1) ||
              ($store.state.timeRequired === undefined &&
                $store.state.estimatedRequiredTime !== 1)
          "
        >
          {{ $t("internal_header")[9] }}
        </span>
      </span>
      <div
        v-if="
          $store.state.coursePage >= 1 &&
            $store.state.coursePage + 1 < $store.state.numberOfPages &&
            $route.path.split('/')[1] === 'course' &&
            $store.state.breadcrumbNavigationIsShown
        "
        class="header__progress-bar-container"
      >
        <div class="header__progress-bar-border">
          <div class="header__progress-bar"></div>
        </div>
        <nav
          @mouseover="$store.dispatch('hoverIntentAuxiliary', 'in')"
          @mouseleave="$store.state.timeoutIsSet = false"
          @touchend="$store.state.TOCisOpen = !$store.state.TOCisOpen"
          class="header__progress-bar-navigation"
        >
          <div
            v-for="section in $store.state.numberOfSections"
            :key="section"
            @mouseover="$store.dispatch('hoverIntent', ['setTimeout', section])"
            @mouseout="$store.dispatch('hoverIntent', ['clearTimeout'])"
            @mousemove="setLessonPosition($event)"
            @mousedown="setPage($store.state.sectionBeginsAtPage[section - 1])"
            class="header__progress-bar-navigation-area"
            :style="{ width: $store.state.widthsOfSections[section - 1] }"
          ></div>
        </nav>
        <div class="header__progress-bar-selected-lesson">
          <img
            src="@/assets/Menus/pointer.svg"
            :class="{
              'header__progress-bar-selected-lesson--is-shown':
                $store.state.timeoutIsSet ||
                $store.state.selectedSection + 1 >= 1
            }"
          />
          <div
            :class="{
              'header__progress-bar-selected-lesson--is-shown':
                $store.state.selectedSection + 1 >= 1
            }"
          >
            {{ $store.state.selectedSection + 1 }}
          </div>
        </div>
        <span class="header__lesson"
          >{{ $store.state.pageBelongsToSection[$store.state.coursePage] }} /
          {{ $store.state.numberOfSections }}
        </span>
      </div>
      <div
        v-if="
          $store.state.coursePage >= 0 && $route.path.split('/')[1] === 'course'
        "
        class="header__course-button-container"
      >
        <app-round-button
          @mousedown="$store.state.TOCisOpen = !$store.state.TOCisOpen"
          icon="contents"
          :text="$t('course_header').contents_icon"
          :active="$store.state.TOCisOpen"
          class="header__course-button header__course-button--1"
        ></app-round-button>
        <!-- was: contents16 -->
        <app-round-button
          @mousedown="
            $store.state.feedbackFormIsShown = !$store.state.feedbackFormIsShown
          "
          icon="feedback8"
          :text="$t('course_header').contact_icon"
          :active="$store.state.feedbackFormIsShown"
          class="header__course-button header__course-button--1"
        ></app-round-button>
        <!-- <app-round-button
          @click="toggleModal('app-onboarding', $store.state.recentPage.type)"
          icon="questionmark"
          :text="$t('course_header').help_icon"
          :active="
            $store.state.shownModal !== 'none' &&
              $store.state.shownSubModal === $store.state.recentPage.type
          "
          class="header__course-button header__course-button--2"
        ></app-round-button> -->
        <app-round-button
          @click="
            setPage(-1);
            toggleModal();
            $store.state.mainMenuIsShown = false;
          "
          icon="exit_18"
          :text="$t('course_header').exit_icon"
          :active="
            $store.state.shownModal !== 'none' &&
              $store.state.shownSubModal === 'endcourse'
          "
          class="header__course-button header__course-button--3"
        ></app-round-button>
      </div>
      <div
        v-if="!$store.state.isAuthenticated"
        class="header__button-container"
      >
        <app-button
          @click="
            $store.state.languageMenuIsShown = !$store.state.languageMenuIsShown
          "
          class="header__button header__button--language"
          icon="earth"
          variation="transparent"
          :text="$t('language')"
        ></app-button>
        <app-language-menu
          v-if="$store.state.languageMenuIsShown"
          class="header__language-menu"
        ></app-language-menu>
        <app-button
          @focus="$store.state.languageMenuIsShown = false"
          @click="toggleModal('app-login-modal')"
          class="header__button"
          variation="transparent"
          :text="$t('button_login')"
        ></app-button>
        <app-button
          @click="toggleModal('app-register-modal')"
          class="header__button"
          :text="$t('button_signup')"
        ></app-button>
      </div>
      <app-hamburger
        v-if="!$store.state.isAuthenticated"
        @mousedown="toggleSidebarMenu('app-external-menu', $event)"
        @touchstart="toggleSidebarMenu('app-external-menu', $event)"
        class="header__hamburger"
      ></app-hamburger>
      <app-hamburger
        v-if="$store.state.isAuthenticated"
        @mousedown="
          $event.preventDefault();
          $store.state.mainMenuIsShown = !$store.state.mainMenuIsShown;
        "
        @touchstart="
          $event.preventDefault();
          $store.state.mainMenuIsShown = !$store.state.mainMenuIsShown;
        "
        class="header__hamburger header__hamburger--internal"
      ></app-hamburger>
      <app-main-menu v-if="$store.state.mainMenuIsShown"></app-main-menu>
    </div>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AppMathopiaLogo from "@/components/Atoms/Icons/MathopiaLogo.vue";
// import AppSignetfull from "@/components/Atoms/Icons/Signetfull.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppRoundButton from "@/components/Atoms/RoundButton.vue";
import AppHamburger from "@/components/Atoms/Hamburger.vue";
import AppLanguageMenu from "@/components/Molecules/LanguageMenu.vue";
import AppMainMenu from "@/components/Molecules/MainMenu.vue";

// import { header } from "@/components/Organisms/Header/_assets/_header.script.js";
// import { cookyConsent } from "@/components/Organisms/Header/_assets/_cookieAlert.js";

export default {
  name: "appHeader",
  // mixins: [header, cookyConsent],
  components: {
    AppMathopiaLogo,
    // AppSignetfull,
    AppButton,
    AppRoundButton,
    AppHamburger,
    AppLanguageMenu,
    AppMainMenu
  },
  computed: {
    ...mapGetters(["cookieAlertIsShown"])
  },
  methods: {
    ...mapActions(["setCookieAlertStatus"]),
    logoNavigation() {
      if (!this.$store.state.isAuthenticated) {
        this.goTo("/");
      } else {
        if (this.$store.state.coursePage < 0) {
          this.goTo("/overview");
        } else {
          this.toggleModal("app-onboarding", "endcourse");
        }
      }
    },
    toggleTOC() {
      this.$store.state.TOCisOpen = !this.$store.state.TOCisOpen;
      localStorage.setItem("TOCisOpen", this.$store.state.TOCisOpen);
    },
    setLessonPosition(e) {
      let progressBarContainer = document.querySelector(
        ".header__progress-bar-container"
      );
      let selectedLessonImg = document.querySelector(
        ".header__progress-bar-selected-lesson img"
      );
      let selectedLessonDiv = document.querySelector(
        ".header__progress-bar-selected-lesson div"
      );
      let progressBarContainerLeft = this.left(progressBarContainer);
      let coordinate = this.getCursorPosition(e);
      let xCoordinateMouse = coordinate[0] - progressBarContainerLeft;
      let yCoordinateMouse = coordinate[1] - 40;
      selectedLessonImg.style.transform =
        "translate(" + xCoordinateMouse + "px, " + yCoordinateMouse + "px)";
      let translateDiv = xCoordinateMouse + 4;
      selectedLessonDiv.style.transform = "translateX(" + translateDiv + "px)";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.header {
  position: fixed;
  top: 0;
  z-index: 1000000;
  // transform: translateZ(10px);
  width: 100%;
  height: 6rem;
  // // // @include grey-backdrop-blurred;
  // // // border-bottom: 1px solid $color-grey;
  border-bottom: 1px solid #1d1d1d;
  background: $color-black !important;

  // transform: translateZ(1px);

  /* @media not all and (min-resolution: 0.001dpcm) {
    z-index: initial;
    transform: translateZ(1px);
  } */

  @include grid-template-columns;
  grid-template-rows: 6rem;

  @include respond(phone) {
    border-bottom: max(0.15rem, 1px) solid #222;
  }

  & .button {
    display: initial;
  }

  &__logo {
    grid-column: 2 / 3;
    align-self: center;
    transform: translateY(0.45rem);
    width: auto;
    height: 3.8rem;
    cursor: pointer;

    @include respond(phone) {
      &.header__logo--not-shown {
        display: none;
      }
    }
  }

  &__internal-navigation-container {
    position: absolute;
    left: max(26.5rem, 0.5 * (100vw - #{$width-center}));
    bottom: 1.4rem;

    @include respond(tab-land) {
      left: 25.5rem;
    }

    /* @include respond(tab-port) {
      // left: min(25.3rem, 19.5% + 3.1rem);
      // left: calc(50% + 5.8rem);
      left: 19rem;
      // transform: translateX(-50%);
      // width: max(calc(100% - 46rem), 54%);
      // width: min(60rem, 100% - 40rem);
      width: min(80rem, 100% - 24.5rem);
      // background-color: red;
    } */

    @include respond(tab-port) {
      display: none;
    }
  }

  &__internal-navigation {
    list-style: none;

    @include respond(tab-port) {
      display: grid;
      grid-template-columns: repeat(5, max-content);
      justify-content: space-evenly;
    }
  }

  &__internal-navigation-item {
    position: relative;
    float: left;
    font-family: $font-primary-bold;
    font-size: 2rem;
    line-height: 1;
    color: $color-white;
    cursor: pointer;
    transform: translateY(-0.1rem);

    transition: color 0.2s;

    &:not(:last-child) {
      margin-right: 6.875rem;

      @include respond(tab-port) {
        margin-right: 0;
        float: none;
        width: max-content;
      }
    }

    @include respond(tab-port) {
      font-size: 2.2rem;
    }

    &:hover {
      color: $color-white;
    }

    &::before {
      @include pseudo-element;
      left: -1rem;
      z-index: -1;
      bottom: -1.4rem;
      width: calc(100% + 2rem);
      height: 6rem;
    }

    &.header__internal-navigation-active-item::after {
      @include pseudo-element;
      bottom: calc(-1.5rem - 1px);
      width: 100%;
      height: 1px;
      background-color: #999;
    }
  }

  &__breadcrumb-navigation {
    grid-column: 3 / 4;
    font-family: $font-primary-bold;
    font-size: 1.9rem;
    color: #999;

    @include respond(tab-port) {
      grid-column: 2 / 4;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      padding: 0.4rem 0.75rem;
      line-height: 1.35;
      color: $color-white;
      background-color: $color-primary-dark;
    }

    &--item {
      transition: color 0.2s;

      &:hover {
        color: $color-primary-light;
        cursor: pointer;
      }
    }
  }

  &__back-button {
    margin-top: 1px;
    background-color: #131313;
    color: $color-primary-light;
    padding-left: 0;
    display: none !important;

    &:hover {
      color: $color-primary-lighter;
    }

    @include respond(phone) {
      padding: 0;
      padding-left: 0.75rem;
    }
  }

  &__breadcrumb-slash {
    display: inline-block;
    margin: 0 0.75rem;
    transform: translate(-0.075rem, -0.15rem) scale(1.6);
  }

  &__button-container {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    justify-self: end;
    padding-top: 0.3rem;

    @include respond(tab-port) {
      display: none;
    }
  }

  &__button {
    @include respond(tab-land) {
      font-size: 2.1rem;
    }
  }

  &__button:not(:last-child) {
    margin-right: 1.2rem;
  }

  &__button:last-child {
    background-color: $color-primary-dark;

    &:hover {
      background-color: $color-primary;
    }
  }

  &__button--language {
    position: absolute;
    left: calc(100% - 45rem);
    top: calc(50% + 0.5px);
    transform: translateY(-50%);
  }

  &__hamburger {
    display: none;
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    z-index: 1000000;
    justify-self: end;
    align-self: center;
    transform: translateZ(1px) translateY(0.2rem);

    @include respond(tab-port) {
      display: block;
      transform: translateZ(1px) translateX(0.75rem);
    }

    &--internal {
      display: block;
    }

    @include respond(phone) {
      grid-column: 3 / 6;
      transform: translateZ(1px) translateY(0.2rem);
    }
  }

  &__language-menu {
    right: 46.45rem;
    top: 6rem;
    transform: translateX(100%);
  }

  &__total-lesson-number {
    display: block;
    left: max(22.5rem, 50vw - 0.5 * #{$width-center});
    top: calc(50% + 0.19rem);
    font-size: 1.9rem;

    position: absolute;
    transform: translateY(-50%);
    font-family: $font-primary-bold;
    color: $color-primary-light;

    cursor: pointer;

    @include respond(tab-port) {
      font-size: 2.1rem;
    }

    @include respond(phone) {
      display: block;

      left: 0.75rem;
      top: calc(50% + 0.19rem);
      font-size: 2.2rem;
    }
  }

  &__progress-bar-container {
    position: relative;
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    width: 100%;
    height: 100%;
  }

  &__progress-bar-border {
    position: absolute;
    left: 50%;
    top: calc(50% + 0.2rem);
    transform: translate(-50%, -50%);
    width: 33.4rem;
    height: 0.6rem;
    border-radius: 10rem;
    background-color: #3a3a3a;
    overflow: hidden;

    @include respond(tab-port) {
      height: 0.8rem;
    }

    @include respond(phone) {
      width: 27rem;
      // height: 0.8rem;
    }
  }

  &__progress-bar {
    position: absolute;
    left: -100%;
    transform: translateX(var(--header-progress-bar));
    width: 100%;
    height: 100%;
    // // // background-color: #1876b8;
    // // // background-color: #1c8ad6;
    background-color: $color-primary-light;

    transition: transform 1s;

    @include respond(phone) {
      background-color: $color-primary-light;
    }
  }

  &__progress-bar-navigation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 34rem;
    height: 100%;
  }

  &__progress-bar-navigation-area {
    @media (pointer: coarse) {
      pointer-events: none;
    }
  }

  &__progress-bar-selected-lesson {
    opacity: 1;
    position: absolute;
    top: 6.25rem;
    transform: translateX(calc(1.8rem - 50%));

    @media (pointer: coarse) {
      display: none;
    }

    & img {
      visibility: hidden;
      position: absolute;
      left: -0.5rem;
      top: -2.6rem;
      z-index: 1;
      width: 2rem;
      pointer-events: none;
    }

    .header__progress-bar-navigation:hover + & img {
      visibility: visible;
    }

    & div {
      visibility: hidden;
      opacity: 0;
      padding: 0.9rem 1.4rem;
      border-radius: $border-radius;
      font-family: $font-primary-bold;
      font-size: $font-size-smallest;
      line-height: 1;
      color: $color-white;
      background-color: $color-grey-dark;

      transition: opacity 0.1s;
    }

    & div.header__progress-bar-selected-lesson--is-shown {
      visibility: visible;
      opacity: 1;
    }
  }

  &__progress-bar-navigation-area {
    position: relative;
    float: left;
    height: 100%;

    .header__progress-bar-navigation:hover & {
      cursor: none;
    }
  }

  &__lesson {
    position: absolute;
    left: calc(50% + 19.45rem);
    top: calc(50% + 0.075rem);
    transform: translateY(-50%);
    font-family: $font-primary-bold;
    font-size: 1.9rem;
    color: $color-primary-light;

    @include respond(tab-port) {
      font-size: 2.1rem;
    }

    @include respond(phone) {
      left: 0;
      top: calc(50% + 0.19rem);
      font-size: 2.2rem;
    }
  }

  &__course-button-container {
    position: absolute;
    right: 10.6rem;
    top: 1.4rem;

    @include respond(tab-land) {
      right: 8.6rem;
    }

    /* @include respond(tab-port) {
      display: none;
    }

    @include respond(phone) {
      display: none;
    } */

    /* @media only screen and (max-width: 760px) {
      display: none;
    } */

    @include respond(tab-port) {
      display: none;
    }
  }

  &__course-button {
    float: left;
    margin-right: 1.7rem;

    &--3 {
      margin-right: 0;
    }
  }
}
</style>
