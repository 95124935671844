<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 593.1 256.6"
    style="enable-background:new 0 0 593.1 256.6"
  >
    <g>
      <circle class="st0" cx="3" cy="3" r="3" />
      <circle class="st0" cx="20.8" cy="3" r="3" />
      <circle class="st0" cx="38.6" cy="3" r="3" />
      <circle class="st0" cx="56.3" cy="3" r="3" />
      <circle class="st0" cx="74.1" cy="3" r="3" />
      <circle class="st0" cx="91.9" cy="3" r="3" />
      <circle class="st0" cx="109.7" cy="3" r="3" />
      <circle class="st0" cx="127.5" cy="3" r="3" />
      <circle class="st0" cx="145.3" cy="3" r="3" />
      <circle class="st0" cx="163.1" cy="3" r="3" />
      <circle class="st0" cx="180.9" cy="3" r="3" />
      <circle class="st0" cx="198.7" cy="3" r="3" />
      <circle class="st0" cx="216.5" cy="3" r="3" />
      <circle class="st0" cx="234.3" cy="3" r="3" />
      <circle class="st0" cx="252.1" cy="3" r="3" />
      <circle class="st0" cx="269.8" cy="3" r="3" />
      <circle class="st0" cx="287.6" cy="3" r="3" />
      <circle class="st0" cx="305.4" cy="3" r="3" />
      <circle class="st0" cx="323.2" cy="3" r="3" />
      <circle class="st0" cx="341" cy="3" r="3" />
      <circle class="st0" cx="358.8" cy="3" r="3" />
      <circle class="st0" cx="376.6" cy="3" r="3" />
      <circle class="st0" cx="394.4" cy="3" r="3" />
      <circle class="st0" cx="412.2" cy="3" r="3" />
      <circle class="st0" cx="430" cy="3" r="3" />
      <circle class="st0" cx="447.8" cy="3" r="3" />
      <circle class="st0" cx="465.6" cy="3" r="3" />
      <circle class="st0" cx="483.3" cy="3" r="3" />
      <circle class="st0" cx="501.1" cy="3" r="3" />
      <circle class="st0" cx="518.9" cy="3" r="3" />
      <circle class="st0" cx="536.7" cy="3" r="3" />
      <circle class="st0" cx="554.5" cy="3" r="3" />
      <circle class="st0" cx="572.3" cy="3" r="3" />
      <circle class="st0" cx="590.1" cy="3" r="3" />
    </g>
    <g>
      <circle class="st0" cx="3" cy="253.7" r="3" />
      <circle class="st0" cx="20.8" cy="253.7" r="3" />
      <circle class="st0" cx="38.6" cy="253.7" r="3" />
      <circle class="st0" cx="56.3" cy="253.7" r="3" />
      <circle class="st0" cx="74.1" cy="253.7" r="3" />
      <circle class="st0" cx="91.9" cy="253.7" r="3" />
      <circle class="st0" cx="109.7" cy="253.7" r="3" />
      <circle class="st0" cx="127.5" cy="253.7" r="3" />
      <circle class="st0" cx="145.3" cy="253.7" r="3" />
      <circle class="st0" cx="163.1" cy="253.7" r="3" />
      <circle class="st0" cx="180.9" cy="253.7" r="3" />
      <circle class="st0" cx="198.7" cy="253.7" r="3" />
      <circle class="st0" cx="216.5" cy="253.7" r="3" />
      <circle class="st0" cx="234.3" cy="253.7" r="3" />
      <circle class="st0" cx="252.1" cy="253.7" r="3" />
      <circle class="st0" cx="269.8" cy="253.7" r="3" />
      <circle class="st0" cx="287.6" cy="253.7" r="3" />
      <circle class="st0" cx="305.4" cy="253.7" r="3" />
      <circle class="st0" cx="323.2" cy="253.7" r="3" />
      <circle class="st0" cx="341" cy="253.7" r="3" />
      <circle class="st0" cx="358.8" cy="253.7" r="3" />
      <circle class="st0" cx="376.6" cy="253.7" r="3" />
      <circle class="st0" cx="394.4" cy="253.7" r="3" />
      <circle class="st0" cx="412.2" cy="253.7" r="3" />
      <circle class="st0" cx="430" cy="253.7" r="3" />
      <circle class="st0" cx="447.8" cy="253.7" r="3" />
      <circle class="st0" cx="465.6" cy="253.7" r="3" />
      <circle class="st0" cx="483.3" cy="253.7" r="3" />
      <circle class="st0" cx="501.1" cy="253.7" r="3" />
      <circle class="st0" cx="518.9" cy="253.7" r="3" />
      <circle class="st0" cx="536.7" cy="253.7" r="3" />
      <circle class="st0" cx="554.5" cy="253.7" r="3" />
      <circle class="st0" cx="572.3" cy="253.7" r="3" />
      <circle class="st0" cx="590.1" cy="253.7" r="3" />
    </g>
    <g>
      <path
        class="st1"
        d="M88.1 199.3v-15.9h5.3c2-.1 3.9.7 5.2 2.3 1.3 1.6 1.9 3.6 1.9 5.6.1 2-.6 4-1.8 5.7-1.1 1.5-2.8 2.3-4.6 2.3H88.1zM90.1 197.6h3.2c1.5.1 3-.6 3.9-1.8.9-1.4 1.4-3 1.3-4.7.0-1.5-.4-3-1.3-4.2-.9-1.3-2.4-2-3.9-1.9h-3.2V197.6z"
      />
      <path
        class="st1"
        d="M118.9 197.5v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L118.9 197.5z"
      />
      <path
        class="st1"
        d="M138.2 199.3h-2.1l-2.9-6.9h-4.5v6.9h-1.9v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L138.2 199.3zM128.6 190.8h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7h-4.4L128.6 190.8z"
      />
      <path class="st1" d="M159.5 199.3h-2v-15.9h2V199.3z" />
      <path
        class="st1"
        d="M180.3 199.3h-1.9l-8.2-13.8v1c0 .4.0.7.0 1v11.8h-1.6v-15.9h2.5l7.7 12.9v-1.1c0-.4.0-.8.0-1.1v-10.7h1.6L180.3 199.3z"
      />
      <path
        class="st1"
        d="M201.1 199.3h-1.9l-8.2-13.8v1c0 .4.0.7.0 1v11.8h-1.6v-15.9h2.5l7.7 12.9v-1.1c0-.4.0-.8.0-1.1v-10.7h1.6V199.3z"
      />
      <path
        class="st1"
        d="M215.6 199.6c-1.9.1-3.7-.8-4.8-2.4-1.2-1.7-1.8-3.8-1.7-5.8-.1-2.1.5-4.2 1.8-6 2-2.6 5.7-3.1 8.4-1.1.4.3.8.7 1.1 1.1 1.2 1.7 1.9 3.7 1.8 5.8.0 2.1-.6 4.1-1.7 5.9C219.4 198.7 217.6 199.7 215.6 199.6zM215.7 198c1.4.0 2.6-.7 3.3-1.9.8-1.5 1.2-3.2 1.2-4.9.1-1.7-.3-3.4-1.2-4.8-.7-1.1-1.9-1.7-3.2-1.7-1.3.0-2.6.7-3.2 1.8-.9 1.5-1.3 3.1-1.2 4.8-.1 1.7.3 3.3 1.2 4.8C213.1 197.3 214.3 198 215.7 198z"
      />
      <path
        class="st1"
        d="M240.5 183.4l-5.3 15.9h-1.9l-5.3-15.9h2.1l4.4 13.1 4.4-13.1H240.5z"
      />
      <path
        class="st1"
        d="M257.5 199.3h-2l-1.6-4.6H248l-1.4 4.6h-1.7l5.2-15.9h2.2L257.5 199.3zM253.4 193.2l-2.5-7.6-2.5 7.6h5z"
      />
      <path
        class="st1"
        d="M267.8 199.3h-2v-14.1h-4.5v-1.7h11.1v1.7h-4.6V199.3z"
      />
      <path class="st1" d="M281.7 199.3h-2v-15.9h2V199.3z" />
      <path
        class="st1"
        d="M296.3 199.6c-1.9.1-3.7-.8-4.8-2.4-1.2-1.7-1.8-3.8-1.7-5.8-.1-2.1.5-4.2 1.8-6 2-2.6 5.7-3.1 8.4-1.1.4.3.8.7 1.1 1.1 1.2 1.7 1.9 3.7 1.8 5.8.0 2.1-.6 4.1-1.7 5.9C3e2 198.7 298.2 199.7 296.3 199.6zM296.3 198c1.4.0 2.6-.7 3.3-1.9.8-1.5 1.2-3.2 1.2-4.9.1-1.7-.3-3.4-1.2-4.8-.7-1.1-1.9-1.7-3.2-1.7-1.3.0-2.6.7-3.2 1.8-.9 1.5-1.3 3.1-1.2 4.8-.1 1.7.3 3.3 1.2 4.8C293.7 197.3 295 198 296.3 198z"
      />
      <path
        class="st1"
        d="M322.6 199.3h-1.9l-8.2-13.8v1c0 .4.0.7.0 1v11.8h-1.6v-15.9h2.5l7.7 12.9v-1.1c0-.4.0-.8.0-1.1v-10.7h1.6V199.3z"
      />
      <path
        class="st1"
        d="M329.9 195.1l1.8-.4c.6 2.1 2.5 3.4 4.7 3.2 1 .1 2-.2 2.8-.8.6-.5 1-1.3 1-2.1.0-.6-.2-1.2-.7-1.6-.5-.4-1.1-.7-1.7-.9l-3.2-.8c-.7-.2-1.4-.4-2-.8-.6-.3-1-.8-1.3-1.4-.4-.6-.6-1.4-.5-2.1.0-1.2.5-2.4 1.5-3.2 1.1-.8 2.5-1.2 3.8-1.2 2.6-.2 5 1.4 5.7 3.9l-1.8.5c-.2-.9-.7-1.6-1.5-2.1-.7-.5-1.6-.7-2.5-.7-.8.0-1.6.2-2.3.7-.7.4-1 1.2-1 1.9.0 1.2.9 2 2.6 2.4l3 .7c2.7.7 4.1 2.1 4.1 4.4.0 1.3-.5 2.6-1.5 3.4-3.2 2.3-7.6 1.6-9.9-1.6C330.5 196.2 330.2 195.7 329.9 195.1v0z"
      />
      <path
        class="st1"
        d="M352.3 199.3h-2v-15.9h5.6c1.4-.1 2.9.4 3.9 1.4.8.9 1.3 2.1 1.3 3.3.0 1.3-.5 2.5-1.4 3.4-1 .9-2.3 1.4-3.6 1.3h-3.8V199.3zM352.3 191.2h3.6c.8.0 1.7-.3 2.3-.8.6-.6.9-1.4.9-2.2.0-.8-.3-1.5-.8-2.1-.6-.6-1.4-1-2.2-.9h-3.8v6z"
      />
      <path
        class="st1"
        d="M380.2 199.3h-2.1l-2.9-6.9h-4.5v6.9h-1.9v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L380.2 199.3zM370.6 190.8h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7h-4.4V190.8z"
      />
      <path
        class="st1"
        d="M398.7 197.5v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L398.7 197.5z"
      />
      <path class="st1" d="M408.6 199.3h-2v-15.9h2V199.3z" />
      <path
        class="st1"
        d="M415.9 195.1l1.8-.4c.6 2.1 2.5 3.4 4.7 3.2 1 .1 2-.2 2.8-.8.6-.5 1-1.3 1-2.1.0-.6-.2-1.2-.7-1.6-.5-.4-1.1-.7-1.7-.9l-3.2-.8c-.7-.2-1.4-.4-2-.8-.6-.3-1-.8-1.3-1.4-.4-.6-.6-1.4-.5-2.1.0-1.2.5-2.4 1.5-3.2 1.1-.8 2.5-1.2 3.8-1.2 2.6-.2 5 1.4 5.7 3.9l-1.8.5c-.2-.9-.7-1.6-1.5-2.1-.7-.5-1.6-.7-2.5-.7-.8.0-1.6.2-2.3.7-.7.4-1 1.2-1 1.9.0 1.2.9 2 2.6 2.4l3 .7c2.7.7 4.1 2.1 4.1 4.4.0 1.3-.5 2.6-1.5 3.4-3.2 2.3-7.6 1.6-9.9-1.6C416.5 196.2 416.2 195.7 415.9 195.1v0z"
      />
      <path
        class="st1"
        d="M447.4 199.3v-15.9h5.3c2-.1 3.9.7 5.2 2.3 1.3 1.6 1.9 3.6 1.9 5.6.1 2-.6 4-1.8 5.7-1.1 1.5-2.8 2.3-4.6 2.3H447.4zM449.4 197.6h3.2c1.5.1 3-.6 3.9-1.8.9-1.4 1.4-3 1.3-4.7.0-1.5-.4-3-1.3-4.2-.9-1.3-2.4-2-3.9-1.9h-3.2V197.6z"
      />
      <path
        class="st1"
        d="M478.2 197.5v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L478.2 197.5z"
      />
      <path
        class="st1"
        d="M497.5 199.3h-2.1l-2.9-6.9H488v6.9h-1.9v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L497.5 199.3zm-9.5-8.5h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7H488V190.8z"
      />
    </g>
    <g>
      <path
        class="st1"
        d="M55.2 225.7h-2v-14.1h-4.5v-1.7h11.1v1.7h-4.6V225.7z"
      />
      <path
        class="st1"
        d="M77.3 224v1.7H67v-15.9h10v1.7H69v5h6.4v1.6H69v5.7L77.3 224z"
      />
      <path
        class="st1"
        d="M94.7 220.4l1.8.4c-.9 3.5-2.8 5.3-5.9 5.3-1.9.1-3.7-.9-4.7-2.4-1.2-1.8-1.8-3.9-1.7-6-.1-2.1.5-4.2 1.8-5.9 1.2-1.5 2.9-2.3 4.8-2.2 3 0 4.9 1.7 5.7 5.1l-1.9.4c-.7-2.5-2-3.8-3.9-3.8-1.3.0-2.6.6-3.3 1.8-.9 1.5-1.3 3.2-1.2 4.9-.1 1.6.3 3.3 1.2 4.7.7 1.1 1.9 1.8 3.1 1.8C92.7 224.4 94 223 94.7 220.4z"
      />
      <path
        class="st1"
        d="M115.4 225.7h-2v-7.6H106v7.6h-2v-15.9h2v6.6h7.4v-6.6h2V225.7z"
      />
      <path
        class="st1"
        d="M136.2 225.7h-1.9l-8.2-13.8v1c0 .4.0.7.0 1v11.8h-1.6v-15.9h2.5l7.7 12.9v-1.1c0-.4.0-.8.0-1.1v-10.7h1.6V225.7z"
      />
      <path
        class="st1"
        d="M150.7 226c-1.9.1-3.7-.8-4.8-2.4-1.2-1.7-1.8-3.8-1.7-5.8-.1-2.1.5-4.2 1.8-6 2-2.6 5.7-3.1 8.4-1.1.4.3.8.7 1.1 1.1 1.2 1.7 1.9 3.7 1.8 5.8.0 2.1-.6 4.1-1.7 5.9C154.5 225.2 152.7 226.1 150.7 226zM150.8 224.4c1.4.0 2.6-.7 3.3-1.9.8-1.5 1.2-3.2 1.2-4.9.1-1.7-.3-3.4-1.2-4.8-.7-1.1-1.9-1.7-3.2-1.7-1.3.0-2.6.7-3.2 1.8-.9 1.5-1.3 3.1-1.2 4.8-.1 1.7.3 3.3 1.2 4.8C148.2 223.7 149.5 224.4 150.8 224.4z"
      />
      <path class="st1" d="M174.8 224v1.7h-9.4v-15.9h2V224H174.8z" />
      <path
        class="st1"
        d="M187.1 226c-1.9.1-3.7-.8-4.8-2.4-1.2-1.7-1.8-3.8-1.7-5.8-.1-2.1.5-4.2 1.8-6 2-2.6 5.7-3.1 8.4-1.1.4.3.8.7 1.1 1.1 1.2 1.7 1.9 3.7 1.8 5.8.0 2.1-.6 4.1-1.7 5.9C190.9 225.2 189 226.1 187.1 226zM187.1 224.4c1.4.0 2.6-.7 3.3-1.9.8-1.5 1.2-3.2 1.2-4.9.1-1.7-.3-3.4-1.2-4.8-.7-1.1-1.9-1.7-3.2-1.7-1.3.0-2.6.7-3.2 1.8-.9 1.5-1.3 3.1-1.2 4.8-.1 1.7.3 3.3 1.2 4.8C184.6 223.7 185.8 224.4 187.1 224.4v0z"
      />
      <path
        class="st1"
        d="M213.5 217.7v8.2h-1.2l-.5-2c-1 1.4-2.6 2.2-4.4 2.1-1.9.1-3.8-.8-4.9-2.4-1.2-1.7-1.8-3.7-1.7-5.7-.1-2.2.6-4.3 1.8-6 1.2-1.6 3.1-2.5 5-2.4 2.9.0 4.8 1.5 5.7 4.6l-1.9.5c-.2-1-.7-1.9-1.5-2.6-.7-.5-1.6-.8-2.4-.8-1.4-.1-2.8.7-3.5 1.9-.8 1.5-1.2 3.1-1.1 4.8-.1 1.7.3 3.4 1.2 4.8.8 1.1 2 1.8 3.4 1.7 1 0 2.1-.4 2.8-1.1.8-.8 1.2-1.9 1.1-3.1.0-.2.0-.5.0-.9h-3.9v-1.7h6z"
      />
      <path class="st1" d="M224.3 225.7h-2v-15.9h2V225.7z" />
      <path
        class="st1"
        d="M243.6 224v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L243.6 224z"
      />
      <path
        class="st1"
        d="M262.9 225.7h-2.1l-2.9-6.9h-4.5v6.9h-1.9v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L262.9 225.7zM253.3 217.2h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7h-4.4V217.2z"
      />
      <path
        class="st1"
        d="M281.5 224v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L281.5 224z"
      />
      <path
        class="st1"
        d="M301.1 217.7v8.2h-1.2l-.5-2c-1 1.4-2.6 2.2-4.4 2.1-1.9.1-3.8-.8-4.9-2.4-1.2-1.7-1.8-3.7-1.7-5.7-.1-2.2.6-4.3 1.8-6 1.2-1.6 3.1-2.5 5-2.4 2.9.0 4.8 1.5 5.7 4.6l-1.9.5c-.2-1-.7-1.9-1.5-2.6-.7-.5-1.6-.8-2.4-.8-1.4-.1-2.8.7-3.5 1.9-.8 1.5-1.2 3.1-1.1 4.8-.1 1.7.3 3.4 1.2 4.8.8 1.1 2 1.8 3.4 1.7 1 0 2.1-.4 2.8-1.1.8-.8 1.2-1.9 1.1-3.1.0-.2.0-.5.0-.9h-3.9v-1.7H301.1z"
      />
      <path class="st1" d="M311.8 225.7h-2v-15.9h2V225.7z" />
      <path
        class="st1"
        d="M326.4 226c-1.9.1-3.7-.8-4.8-2.4-1.2-1.7-1.8-3.8-1.7-5.8-.1-2.1.5-4.2 1.8-6 2-2.6 5.7-3.1 8.4-1.1.4.3.8.7 1.1 1.1 1.2 1.7 1.9 3.7 1.8 5.8.0 2.1-.6 4.1-1.7 5.9C330.2 225.2 328.3 226.1 326.4 226zM326.4 224.4c1.4.0 2.6-.7 3.3-1.9.8-1.5 1.2-3.2 1.2-4.9.1-1.7-.3-3.4-1.2-4.8-.7-1.1-1.9-1.7-3.2-1.7-1.3.0-2.6.7-3.2 1.8-.9 1.5-1.3 3.1-1.2 4.8-.1 1.7.3 3.3 1.2 4.8C323.9 223.7 325.1 224.4 326.4 224.4z"
      />
      <path
        class="st1"
        d="M352.7 225.7h-1.9l-8.2-13.8v1c0 .4.0.7.0 1v11.8H341v-15.9h2.5l7.7 12.9v-1.1c0-.4.0-.8.0-1.1v-10.7h1.6V225.7z"
      />
      <path
        class="st1"
        d="M385.4 225.7h-2.2l-4.7-8.8-3.6 4.5v4.2h-2v-15.9h2v9.2l7.2-9.2h2.1l-4.3 5.5L385.4 225.7z"
      />
      <path
        class="st1"
        d="M403.6 225.7h-2l-1.6-4.6h-5.9l-1.4 4.6H391l5.2-15.9h2.2L403.6 225.7zM399.6 219.6l-2.5-7.6-2.5 7.6h5z"
      />
      <path
        class="st1"
        d="M422.2 225.7h-2.1l-2.9-6.9h-4.5v6.9h-1.9v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L422.2 225.7zm-9.5-8.5h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7h-4.4V217.2z"
      />
      <path class="st1" d="M439.9 224v1.7h-9.4v-15.9h2V224H439.9z" />
      <path
        class="st1"
        d="M445.6 221.5l1.8-.4c.6 2.1 2.5 3.4 4.7 3.2 1 .1 2-.2 2.8-.8.6-.5 1-1.3 1-2.1.0-.6-.2-1.2-.7-1.6-.5-.4-1.1-.7-1.7-.9l-3.2-.8c-.7-.2-1.4-.4-2-.8-.6-.3-1-.8-1.3-1.4-.4-.6-.6-1.4-.5-2.1.0-1.2.5-2.4 1.5-3.2 1.1-.8 2.5-1.2 3.8-1.2 2.6-.2 5 1.4 5.7 3.9l-1.8.5c-.2-.9-.7-1.6-1.5-2.1-.7-.5-1.6-.7-2.5-.7-.8.0-1.6.2-2.3.7-.7.4-1 1.2-1 1.9.0 1.2.9 2 2.6 2.4l3 .7c2.7.7 4.1 2.1 4.1 4.4.0 1.3-.5 2.6-1.5 3.4-3.2 2.3-7.6 1.6-9.9-1.6C446.1 222.6 445.8 222.1 445.6 221.5z"
      />
      <path
        class="st1"
        d="M477.4 225.7h-2.1l-2.9-6.9h-4.5v6.9H466v-15.9h6.3c1.4-.1 2.8.4 3.8 1.4.8.8 1.2 1.9 1.2 3 0 .9-.2 1.7-.7 2.4-.5.8-1.3 1.4-2.3 1.7L477.4 225.7zM467.8 217.2h4.1c.9.1 1.8-.2 2.4-.9.5-.6.8-1.3.8-2.1.0-.7-.3-1.5-.9-2-.6-.5-1.4-.7-2.2-.7h-4.4V217.2z"
      />
      <path
        class="st1"
        d="M494.5 209.9h1.7v10.4c0 2.1-.5 3.6-1.5 4.5-1.1.9-2.5 1.4-4 1.4-1.5.1-3-.4-4.1-1.4-1-1.3-1.5-2.8-1.3-4.4V210h2v10.4c-.1.9.1 1.8.4 2.6.3.5.8.9 1.3 1.1.6.2 1.2.4 1.8.4 1 .1 2-.3 2.8-1 .7-.9 1.1-2 1-3.1V209.9z"
      />
      <path
        class="st1"
        d="M516.3 225.7h-2v-7.6H507v7.6h-2v-15.9h2v6.6h7.4v-6.6h2V225.7z"
      />
      <path
        class="st1"
        d="M535.7 224v1.7h-10.3v-15.9h10v1.7h-8.1v5h6.4v1.6h-6.4v5.7L535.7 224z"
      />
    </g>
    <g>
      <path
        class="st2"
        d="M248.9 47.1v104h-20.8l-40.7-70.2c0 1.8.1 2.9.2 3.5.2 1.5.3 3 .3 4.5V151h-19V47h26.3l35.4 61.3c-.3-3-.5-5.4-.5-6.9V47.1H248.9z"
      />
      <path
        class="st2"
        d="M339.7 66.9h-49.5v21.3h38.9v19.6h-38.9v22.7h49.5V151h-74.3V47h74.3V66.9z"
      />
    </g>
    <path
      class="st2"
      d="M384.9 45c-15.3.0-40.6 12.2-40.6 54.1s25.3 54.1 40.6 54.1 40.6-12.2 40.6-54.1S400.2 45 384.9 45zm0 90.4c-11.2.0-14.8-11-14.8-36.3s3.6-36.3 14.8-36.3 14.8 11 14.8 36.3S396.1 135.4 384.9 135.4z"
    />
  </svg>
</template>

<style scoped>
.st0 {
  fill: #c7c7c7;
}
.st1 {
  fill: #aaa;
}
.st2 {
  fill: #b9b9b9;
}
</style>
