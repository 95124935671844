<template>
  <div class="onboarding-zipper">
    <div
      class="onboarding-zipper__zipper-element onboarding-zipper__zipper-element--1"
    ></div>
    <div
      class="onboarding-zipper__zipper-element onboarding-zipper__zipper-element--2"
    ></div>
    <div
      class="onboarding-zipper__zipper-element onboarding-zipper__zipper-element--unsolved onboarding-zipper__zipper-element--3"
    ></div>
    <div
      class="onboarding-zipper__zipper-element onboarding-zipper__zipper-element--unsolved onboarding-zipper__zipper-element--4"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding-zipper {
  width: 100%;
  height: calc(100% - 1.6rem);

  &__zipper-element {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 11.6rem;
    height: 0.9rem;
    border-radius: $border-radius;
    background: linear-gradient(
      to right,
      $color-primary-light 0,
      $color-primary-light 50%,
      #ddd 50%,
      #ddd 100%
    );

    &--1 {
      top: 3rem;
    }

    &--2 {
      top: 4.5rem;
    }

    &--3 {
      top: 6.5rem;
    }

    &--4 {
      top: 8rem;
    }

    &--unsolved {
      width: 13.3rem;
      background: linear-gradient(
        to right,
        $color-primary-light 0,
        $color-primary-light calc(50% - 0.85rem),
        transparent calc(50% - 0.85rem),
        transparent calc(50% + 0.85rem),
        #ddd calc(50% + 0.85rem),
        #ddd 100%
      );
    }
  }
}
</style>
