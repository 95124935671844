<template>
  <teleport to="body">
    <div class="onboarding">
      <div class="onboarding__outer-container">
        <div
          class="onboarding__container"
          :style="['transform: translateX(' + onboardingPosition(0) + ')']"
        >
          <img
            src="@/assets/Internal/Onboarding/screenshot_n_12.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture"
          />
          <img
            src="@/assets/Internal/Onboarding/screenshot_new16.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--smartphone"
          />

          <div class="onboarding__text-container">
            <div class="onboarding__heading">
              {{ $t("onboarding")[1].heading }}
            </div>
            {{ $t("onboarding")[1].text }}
          </div>
        </div>
        <div
          class="onboarding__container"
          :style="['transform: translateX(' + onboardingPosition(1) + ')']"
        >
          <img
            src="@/assets/Internal/Onboarding/screenshot_n_22.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture"
          />
          <img
            src="@/assets/Internal/Onboarding/screenshot_new102.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--smartphone"
          />

          <div class="onboarding__text-container onboarding__text-container--2">
            <div class="onboarding__heading">
              {{ $t("onboarding")[2].heading }}
            </div>
            {{ $t("onboarding")[2].text }}
          </div>
        </div>
        <div
          class="onboarding__container"
          :style="['transform: translateX(' + onboardingPosition(2) + ')']"
        >
          <img
            src="@/assets/Internal/Onboarding/screenshot_n_36.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--3"
          />
          <img
            src="@/assets/Internal/Onboarding/screenshot_new15.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture  onboarding__picture--smartphone onboarding__picture--smartphone-3"
          />

          <div class="onboarding__text-container onboarding__text-container--2">
            <div class="onboarding__heading">
              {{ $t("onboarding")[3].heading }}
            </div>
            {{ $t("onboarding")[3].text[0] }}
            <span class="onboarding__emphasis--big-screen">{{
              $t("onboarding")[3].text[1]
            }}</span>
            <span class="onboarding__emphasis--small-screen">{{
              $t("onboarding")[3].text[2]
            }}</span>
            {{ $t("onboarding")[3].text[3] }}
            <span class="onboarding__emphasis">{{
              $t("onboarding")[3].text[4]
            }}</span>
            {{ $t("onboarding")[3].text[5] }}
            <span class="onboarding__emphasis">{{
              $t("onboarding")[3].text[6]
            }}</span>
            {{ $t("onboarding")[3].text[7] }}
          </div>
        </div>
        <div
          class="onboarding__container"
          :style="['transform: translateX(' + onboardingPosition(3) + ')']"
        >
          <img
            src="@/assets/Internal/Onboarding/screenshot_n_44.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--4"
          />
          <img
            src="@/assets/Internal/Onboarding/screenshot_new103.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--smartphone"
          />

          <div class="onboarding__text-container onboarding__text-container--2">
            <div class="onboarding__heading">
              {{ $t("onboarding")[4].heading }}
            </div>
            {{ $t("onboarding")[4].text[0] }}
            <span class="onboarding__emphasis"
              >{{ $t("onboarding")[4].text[1] }}
            </span>
            {{ $t("onboarding")[4].text[2] }}
            <span class="onboarding__text--big-screen">
              {{ $t("onboarding")[4].text[3] }}
            </span>
            <span class="onboarding__text--small-screen">
              {{ $t("onboarding")[4].text[4] }}
            </span>
          </div>
        </div>
        <div
          class="onboarding__container"
          :style="['transform: translateX(' + onboardingPosition(4) + ')']"
        >
          <img
            src="@/assets/Internal/Onboarding/screenshot_n_52.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture"
          />
          <img
            src="@/assets/Internal/Onboarding/screenshot_new104.png"
            :alt="$t('testimonials2_university')"
            class="onboarding__picture onboarding__picture--smartphone"
          />

          <div class="onboarding__text-container onboarding__text-container--2">
            <div class="onboarding__heading">
              {{ $t("onboarding")[5].heading }}
            </div>
            {{ $t("onboarding")[5].text }}
          </div>
        </div>
        <div class="onboarding__button-x-container">
          <app-button-x @click="closeOnboarding()"></app-button-x>
        </div>
      </div>
      <app-button
        @click="showNextOnboarding()"
        :text="
          shownOnboarding < 4
            ? $t('course_continue_button')
            : $t('onboarding').start_button
        "
        class="onboarding__button"
        :class="{ 'onboarding__button--start': shownOnboarding === 4 }"
      ></app-button>
      <div class="onboarding__navigation-container">
        <div
          @click="shownOnboarding = 0"
          class="onboarding__navigation-circle"
          :class="{
            'onboarding__navigation-circle--full': shownOnboarding === 0
          }"
        ></div>
        <div
          @click="shownOnboarding = 1"
          class="onboarding__navigation-circle"
          :class="{
            'onboarding__navigation-circle--full': shownOnboarding === 1
          }"
        ></div>
        <div
          @click="shownOnboarding = 2"
          class="onboarding__navigation-circle"
          :class="{
            'onboarding__navigation-circle--full': shownOnboarding === 2
          }"
        ></div>
        <div
          @click="shownOnboarding = 3"
          class="onboarding__navigation-circle"
          :class="{
            'onboarding__navigation-circle--full': shownOnboarding === 3
          }"
        ></div>
        <div
          @click="shownOnboarding = 4"
          class="onboarding__navigation-circle"
          :class="{
            'onboarding__navigation-circle--full': shownOnboarding === 4
          }"
        ></div>
      </div>
    </div>
  </teleport>
</template>

<script>
import AppButtonX from "@/components/Atoms/ButtonX.vue";
import AppButton from "@/components/Atoms/Button.vue";

export default {
  components: {
    AppButtonX,
    AppButton
  },
  data() {
    return {
      shownOnboarding: 0
    };
  },
  computed: {
    userDataIsFetched() {
      return this.$store.state.userDataIsFetched;
    },
    onboardingIsShown() {
      return this.$store.state.onboardingIsShown;
    }
  },
  methods: {
    onboardingPosition(index) {
      let rightShift = (index - this.shownOnboarding) * 100;
      return rightShift + "%";
    },
    showNextOnboarding() {
      if (this.shownOnboarding < 4) {
        this.shownOnboarding++;
      } else {
        this.closeOnboarding();
      }
    },
    closeOnboarding() {
      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      let metaThemeColor = document.querySelector("meta[name=theme-color]");

      function setThemeColor(recentColor) {
        recentColor--;
        let color = rgbToHex(recentColor, recentColor, recentColor);
        metaThemeColor.setAttribute("content", color);

        if (recentColor > 19) {
          setTimeout(() => {
            setThemeColor(recentColor);
          }, 150);
        }
      }

      setThemeColor(32);

      this.$store.state.onboardingIsShown = false;
      document.querySelector(
        ".onboarding__navigation-container"
      ).style.display = "none";
      let outerContainer = document.querySelector(
        ".onboarding__outer-container"
      );
      outerContainer.style.transform = "scale(0)";

      let onboarding = document.querySelector(".onboarding");
      onboarding.style.opacity = 0;
      setTimeout(() => {
        onboarding.style.display = "none";
      }, 350);
    },

    showOnboarding() {
      let onboarding = document.querySelector(".onboarding");
      if (this.$store.state.userDataIsFetched) {
        if (
          (!this.$store.state.onboardingDesktopWasShown &&
            this.$store.state.windowWidth > 600) ||
          (!this.$store.state.onboardingPhoneWasShown &&
            this.$store.state.windowWidth <= 600)
        ) {
          onboarding.style.display = "block";
        } else {
          let metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#131313");
        }
      }
    }
  },
  created() {
    let metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", "#202020");

    setTimeout(() => {
      this.showOnboarding();
    }, 0);
  },
  watch: {
    userDataIsFetched(to) {
      if (to) {
        this.showOnboarding();
      }
    },
    shownOnboarding(to) {
      if (to === 4) {
        if (this.$store.state.windowWidth <= 600) {
          this.$store.state.onboardingPhoneWasShown = true;
        } else {
          this.$store.state.onboardingDesktopWasShown = true;
        }

        this.$store.dispatch("saveUserDataOnDatabase");
      }
    },
    onboardingIsShown(to) {
      if (to) {
        this.shownOnboarding = 0;
        let outerContainer = document.querySelector(
          ".onboarding__outer-container"
        );
        outerContainer.style.transform = "initial";
        document.querySelector(
          ".onboarding__navigation-container"
        ).style.display = "grid";
        let onboarding = document.querySelector(".onboarding");
        onboarding.style.display = "block";
        onboarding.style.opacity = 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000000;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  background: linear-gradient(
    to bottom,
    rgba(#fff, 0.205) 0,
    rgba(#fff, 0.205) 6rem,
    #343434 6rem,
    #343434 100%
  );

  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 0.35s;

  @include respond(phone) {
    // display: block;
    height: 100vh;
    background: #000;

    &::after {
      @include pseudo-element;
      position: fixed;
      bottom: 0;
      z-index: -1;
      width: 100%;
      height: 100vh;
      background-color: $color-black;
    }
  }

  &__outer-container {
    position: absolute;
    left: max(50% - 0.5 * #{$width-center}, 3rem);
    top: calc(50vh - 0.5 * min(max(70vh, 70rem), 100vh));
    width: min(#{$width-center}, 100vw - 6rem);
    // height: min(max(70vh, 70rem), 100vh);
    height: max(70vh, 70rem);
    // border: 1rem solid red;
    border-radius: 2rem;
    transform: translateZ(0);

    overflow: hidden;
    transition: transform 0.35s;

    @include respond(phone) {
      left: 0;
      top: 0;
      width: 100vw;
      height: 100%;
      border-radius: 0;
    }
  }

  &__button-x-container {
    position: absolute;
    right: 1.45rem;
    top: -0.3rem;
    width: 3rem;
    height: 3rem;

    @include respond(phone) {
      top: -0.45rem;
    }
  }

  &__container {
    position: absolute;
    left: calc(50% - 0.5 * min(#{$width-center}, 100vw - 6rem));
    width: 100%;
    height: min(max(70vh, 70rem), 100vh);
    // height: min(90rem, 100vh);
    // border-radius: 2rem;
    background-color: #1a1a1a;

    // background-color: yellow;

    overflow: hidden;
    transition: transform 0.35s ease-in-out;

    @include respond(phone) {
      left: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      background-color: #202020;
    }
  }

  &__picture {
    position: absolute;
    left: 50%;
    // top: 5.35rem;
    top: min(5.35rem, 2.35vh + 1rem);
    transform: translateX(-50%);
    // height: 34.2rem;
    height: max(min(34.2rem, 28vh), 28rem);

    &--3 {
      top: 9.15rem;
      height: 24rem;
    }

    &--4 {
      border-top-right-radius: 1.2rem;
    }

    @include respond(phone) {
      display: none;
    }

    &--smartphone {
      display: none;

      @include respond(phone) {
        display: block;
        top: 3rem;
        height: 41rem;
      }
    }

    &--smartphone-3 {
      top: 8.5rem;
      width: 36.1rem;
      height: initial;
    }
  }

  &__text-container {
    position: absolute;
    // top: 44rem;
    top: max(min(44rem, 34vh), 34rem);
    // top: 37.5vh;
    padding: 4.5rem 5.5rem 20rem;
    // min-height: calc(100% - 44rem);
    min-height: calc(100% - max(min(44rem, 34vh), 34rem));
    height: max-content;
    width: 100%;
    border-top: 1px solid #202020;
    font-size: 2.4rem;
    line-height: 1.4;
    text-align: center;
    color: #aaa;
    background-color: #000;

    @include respond(phone) {
      top: 44rem;
      height: calc(100vh - 44rem);
      padding: 2.5rem 0 20rem;
      font-size: 2.6rem;
      background-color: #000;
    }
  }

  &__heading {
    margin-bottom: 1.5rem;
    font-family: $font-primary-bold;
    font-size: 4.2rem;
    color: #fff;

    @include respond(phone) {
      font-size: 5rem;
    }
  }

  &__text {
    &--big-screen {
      @include respond(phone) {
        display: none;
      }
    }

    &--small-screen {
      display: none;

      @include respond(phone) {
        display: inline;
      }
    }
  }

  &__emphasis {
    color: $color-white;

    @include respond(phone) {
      font-family: $font-primary-bold;
    }

    &--big-screen {
      @include respond(phone) {
        display: none;
      }
    }

    &--small-screen {
      display: none;

      @include respond(phone) {
        display: inline;
      }
    }
  }

  &__button {
    position: fixed !important;
    left: 50%;
    transform: translate(
      -50%,
      calc(
        50vh - 0.5 * min(max(70vh, 70rem), 100vh) + min(max(70vh, 70rem), 100vh) -
          11.5rem
      )
    );

    width: min(#{$width-center} - 12rem, 100vw - 12rem);
    height: 6rem;
    font-size: 3rem;
    text-align: center;
    border-radius: 0.6rem;
    background-color: #1a1a1a;

    @media (pointer: coarse) {
      transition: transform 0.75s;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #222;
      }
    }

    &:active {
      background-color: #222;
    }

    @include respond(phone) {
      left: 0;
      transform: translateY(calc(100 * var(--vh) - 12rem));
      width: 100vw;
      height: 6.5rem;
    }

    &--start {
      background-color: $color-primary-dark;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $color-primary;
        }
      }

      &:active {
        background-color: $color-primary;
      }
    }
  }

  &__navigation-container {
    position: fixed;
    left: 50%;
    // transform: translate(-50%, calc(85 * var(--vh) - 3rem));
    transform: translate(
      -50%,
      calc(
        50vh - 0.5 * min(max(70vh, 70rem), 100vh) + min(max(70vh, 70rem), 100vh) -
          3.5rem
      )
    );
    width: 10.5rem;
    height: 1.5rem;

    display: grid;
    grid-template-columns: repeat(5, 1.5rem);
    grid-column-gap: 0.75rem;

    @media (pointer: coarse) {
      transition: transform 0.75s;
    }

    @include respond(phone) {
      transform: translate(-50%, calc(100 * var(--vh) - 3rem));
    }
  }

  &__navigation-circle {
    border: 1px solid #fff;
    border-radius: 50%;

    cursor: pointer;

    &--full {
      background-color: #fff;
    }
  }
}
</style>
