<template>
  <div class="awards">
    <div class="awards__label">&nbsp;{{ $t("awards_heading") }}&nbsp;</div>
    <div class="awards__badge-container">
      <app-logo-gwdi class="awards__badge awards__badge--1"></app-logo-gwdi>
      <app-logo-bmwe class="awards__badge awards__badge--2"></app-logo-bmwe>
      <app-logo-igbw class="awards__badge awards__badge--3"></app-logo-igbw>
      <app-logo-bw class="awards__badge awards__badge--4"></app-logo-bw>
      <app-logo-neo class="awards__badge awards__badge--5"></app-logo-neo>
      <app-logo-trk class="awards__badge awards__badge--6"></app-logo-trk>
    </div>
  </div>
</template>

<script>
import AppLogoGwdi from "@/assets/Home/Awards/LogoGwdi.vue";
import AppLogoBmwe from "@/assets/Home/Awards/LogoBmwe.vue";
import AppLogoIgbw from "@/assets/Home/Awards/LogoIgbw.vue";
import AppLogoBw from "@/assets/Home/Awards/LogoBw.vue";
import AppLogoNeo from "@/assets/Home/Awards/LogoNeo.vue";
import AppLogoTrk from "@/assets/Home/Awards/LogoTrk.vue";

export default {
  name: "app",
  components: {
    AppLogoGwdi,
    AppLogoBmwe,
    AppLogoIgbw,
    AppLogoBw,
    AppLogoNeo,
    AppLogoTrk
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.awards {
  position: relative;
  z-index: 20;
  width: 100%;
  padding: 9rem 0;
  color: $color-white;
  background-color: $color-grey-dark;

  @include grid-template-columns;
  justify-items: center;

  @include respond(phone) {
    padding: 8rem 0;

    padding-top: 50vh;
    margin-top: calc(8rem - 50vh);
  }

  &__label {
    grid-column: 3 / 4;

    margin-bottom: 7rem;
    line-height: 1;

    font-family: $font-primary-bold;
    font-weight: 100;
    font-size: 2.6rem;
    font-style: italic;

    @include respond(phone) {
      margin-left: -0.75rem;
      margin-bottom: 6.25rem;
      font-size: 2.8rem;
    }
  }

  &__badge-container {
    grid-column: 3 / 4;
    width: 90%;

    display: grid;
    grid-template-columns: repeat(1fr, 3);
    align-items: center;
    grid-row-gap: 6rem;

    @include respond(tab-port) {
      width: 100%;
    }

    @include respond(phone) {
      grid-row-gap: 5rem;
    }
  }

  &__badge {
    justify-self: center;
    width: 15rem;

    @include respond(phone) {
      width: 10.5rem;
    }

    &--1 {
      grid-column: 1 / 2;
      grid-row: 1 / 2;

      @include respond(phone) {
        justify-self: left;
      }
    }

    &--2 {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      @include respond(phone) {
        justify-self: left;
      }
    }

    &--3 {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }

    &--4 {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }

    &--5 {
      grid-column: 3 / 4;
      grid-row: 1 / 2;

      @include respond(phone) {
        justify-self: right;
      }
    }

    &--6 {
      grid-column: 3 / 4;
      grid-row: 2 / 3;

      @include respond(phone) {
        justify-self: right;
      }
    }
  }
}
</style>
