<template>
  <div
    @mousedown="selectTopic(indices, contentsPage)"
    @keydown="selectTopic(indices, contentsPage, $event)"
    tabindex="0"
    class="lecture"
    :class="[
      'lecture_' +
        indices[0] +
        '_' +
        indices[1] +
        '_' +
        contentsPage +
        lectureName2,
      {
        'lecture__grey-background':
          ($store.state.coursePage === -1 &&
            getLectureData(
              payload2[indices[0]].topics[indices[1]].subtopics,
              'percentageFinishedExercises'
            ) === 100) ||
          ($store.state.coursePage === -2 &&
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) > 0 &&
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) < 100)
      }
    ]"
  >
    <div class="lecture__image">
      <app-lottie
        v-if="
          getLectureData2(
            payload2[indices[0]].topics[indices[1]].contents,
            'totalProgress'
          ) === 100
        "
        :lottie="'award_n10'"
        class="lecture__award"
      ></app-lottie>
      <div v-for="n in 5" :key="n">
        <app-lottie
          v-if="
            processedCourses === totalNumber &&
              totalNumber > 0 &&
              getAward(payload2[indices[0]].topics[indices[1]].subtopics) ===
                'award_' + n
          "
          :lottie="'award_' + n"
          class="lecture__award lecture__award--2"
          :class="{
            lecture__finished:
              getLectureData(
                payload2[indices[0]].topics[indices[1]].subtopics,
                'percentageFinishedExercises'
              ) === 100
          }"
        ></app-lottie>
      </div>
      <div
        v-if="
          getLectureData(
            payload2[indices[0]].topics[indices[1]].subtopics,
            'lectureWasStarted'
          ) && processedCourses < totalNumber
        "
      >
        <img
          src="@/assets/Menus/settings.svg"
          alt="Ein Zahnrad"
          class="lecture__sheet lecture__sheet--2"
        />
        <img
          src="@/assets/Menus/settings.svg"
          alt="Ein Zahnrad"
          class="lecture__sheet lecture__sheet--3"
        />
      </div>
      <img
        src="@/assets/Internal/Course/Contents/play.svg"
        alt="Ein Play-Icon"
        v-if="
          !getLectureData(
            payload2[indices[0]].topics[indices[1]].subtopics,
            'lectureWasStarted'
          ) &&
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) < 100
        "
        class="lecture__sheet lecture__sheet--4"
      />
      <figcaption class="lecture__figure-caption">
        <p
          v-if="
            $store.state.coursePage === -2 &&
              getLectureData2(
                payload2[indices[0]].topics[indices[1]].contents,
                'totalProgress'
              ) === 0
          "
        >
          {{
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              "totalNumberOfCourses"
            )
          }}
          <span
            v-if="
              getLectureData2(
                payload2[indices[0]].topics[indices[1]].contents,
                'totalNumberOfCourses'
              ) === 1
            "
            >{{ $t("courses_awards")["course"] }}</span
          ><span
            v-if="
              getLectureData2(
                payload2[indices[0]].topics[indices[1]].contents,
                'totalNumberOfCourses'
              ) !== 1
            "
            >{{ $t("courses_awards")["courses"] }}</span
          >
        </p>
        <p
          v-if="
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) > 0 &&
              getLectureData2(
                payload2[indices[0]].topics[indices[1]].contents,
                'totalProgress'
              ) < 100
          "
        >
          {{
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              "totalProgress"
            )
          }}
          % {{ $t("courses_awards")["done"] }}
        </p>
        <p
          v-if="
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) === 100
          "
        >
          {{ $t("courses_awards")["terminated"] }}
        </p>
        <p
          v-if="
            $store.state.coursePage >= -1 &&
              !getLectureData(
                payload2[indices[0]].topics[indices[1]].subtopics,
                'lectureWasStarted'
              )
          "
        >
          {{ totalNumber }}
          <span v-if="totalNumber === 1">{{
            $t("courses_awards")["course"]
          }}</span
          ><span v-if="totalNumber !== 1">{{
            $t("courses_awards")["courses"]
          }}</span>
        </p>
        <p
          v-if="
            getLectureData(
              payload2[indices[0]].topics[indices[1]].subtopics,
              'lectureWasStarted'
            ) && processedCourses < totalNumber
          "
        >
          {{ processedCourses }} / {{ totalNumber }}
          {{ $t("courses_awards")["done"] }}
        </p>
        <p v-if="processedCourses === totalNumber && totalNumber > 0">
          {{ $t("courses_awards")["terminated"] }}
        </p>
      </figcaption>
      <app-progress-round
        v-show="
          $store.state.coursePage === -2 &&
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) > 0 &&
            getLectureData2(
              payload2[indices[0]].topics[indices[1]].contents,
              'totalProgress'
            ) < 100
        "
        :percentage="
          getLectureData2(
            payload2[indices[0]].topics[indices[1]].contents,
            'totalProgress'
          )
        "
        class="lecture__progress-round"
        :class="{
          lecture__finished:
            getLectureData(
              payload2[indices[0]].topics[indices[1]].subtopics,
              'percentageFinishedExercises'
            ) === 100
        }"
      ></app-progress-round>
    </div>
    <div tabindex="-1" class="lecture__text-container">
      <h3 class="lecture__heading">
        {{ t(payload2[indices[0]].topics[indices[1]].subheading) }}
      </h3>
      <p class="lecture__text">
        {{ t(payload2[indices[0]].topics[indices[1]].description) }}
      </p>
    </div>
    <div
      class="lecture__lessons-outer-container"
      :id="
        'lecture__lessons-outer-container_' +
          indices[0] +
          '_' +
          indices[1] +
          '_' +
          contentsPage +
          lectureName2
      "
    >
      <div
        class="lecture__lessons-container"
        :id="
          'lecture__lessons-container_' +
            indices[0] +
            '_' +
            indices[1] +
            '_' +
            contentsPage +
            lectureName2
        "
      >
        <app-lesson
          v-for="(subtopic, index) in payload2[indices[0]].topics[indices[1]]
            .subtopics"
          :class="{ hidden: subtopic.restricted !== true }"
          :key="JSON.stringify(subtopic)"
          :payload3="payload2[indices[0]].topics[indices[1]].subtopics[index]"
          :payloadBefore="
            payload2[indices[0]].topics[indices[1]].subtopics[index - 1]
          "
          :payloadAfter="
            payload2[indices[0]].topics[indices[1]].subtopics[index + 1]
          "
          :lessonsAreShown="lessonsAreShown"
          @mousedown="startCourse(subtopic, $event)"
        ></app-lesson>
      </div>
    </div>
  </div>
</template>

<script>
import AppProgressRound from "@/components/Atoms/ProgressRound.vue";
// import AppProgressDiscrete from "@/components/Atoms/ProgressDiscrete.vue";
import AppLesson from "@/components/Molecules/Lesson.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "lecture",
  components: {
    AppLesson,
    AppProgressRound,
    // AppProgressDiscrete
    AppLottie
  },
  props: {
    indices: {
      type: Array
    },
    contentsPage: {
      type: Number
    },
    payload2: {
      type: Object
    },
    lectureData: {
      type: Object
    },
    lectureName2: {
      type: String
    }
  },
  emits: ["lectureChanged", "initializeCourse"],
  data() {
    return {
      lessonsAreShown: false
    };
  },
  computed: {
    processedCourses() {
      return this.getLectureData(
        this.payload2[this.indices[0]].topics[this.indices[1]].subtopics,
        "finishedCoursesInTopic"
      );
    },
    totalNumber() {
      return this.o([
        this.payload2,
        this.indices[0],
        "topics",
        this.indices[1],
        "subtopics",
        "length",
        0
      ]);
    },
    coursePage() {
      return this.$store.state.coursePage;
    }
  },
  methods: {
    getLectureData2(lecture, requestedData) {
      if (lecture && typeof this.lectureData === "object") {
        let totalNumberOfCourses = 0;
        let totalNumberOfFinishedExercises = 0;
        if (this.lectureData[lecture] === undefined) {
          return;
        }
        for (let i = 0; i < this.lectureData[lecture].length; i++) {
          for (let j = 0; j < this.lectureData[lecture][i].topics.length; j++) {
            if ("subtopics" in this.lectureData[lecture][i].topics[j]) {
              for (
                let k = 0;
                k < this.lectureData[lecture][i].topics[j].subtopics.length;
                k++
              ) {
                totalNumberOfCourses++;
                if (
                  this.lectureData[lecture][i].topics[j].subtopics[k].lesson in
                    this.$store.state.userDataLectures &&
                  "completelyFinishedExercises" in
                    this.$store.state.userDataLectures[
                      this.lectureData[lecture][i].topics[j].subtopics[k].lesson
                    ]
                ) {
                  if (
                    this.$store.state.userDataLectures[
                      this.lectureData[lecture][i].topics[j].subtopics[k].lesson
                    ].finished
                  ) {
                    totalNumberOfFinishedExercises += this.$store.state
                      .userDataLectures[
                      this.lectureData[lecture][i].topics[j].subtopics[k].lesson
                    ].totalNumberOfExercises;
                  } else {
                    totalNumberOfFinishedExercises += this.$store.state
                      .userDataLectures[
                      this.lectureData[lecture][i].topics[j].subtopics[k].lesson
                    ].completelyFinishedExercises;
                  }
                }
              }
            }
          }
        }
        let totalNumberOfSteps = 0;
        if (this.lectureData[lecture] === undefined) {
          return;
        }
        for (let i = 0; i < this.lectureData[lecture].length; i++) {
          for (let j = 0; j < this.lectureData[lecture][i].topics.length; j++) {
            if ("subtopics" in this.lectureData[lecture][i].topics[j]) {
              for (
                let k = 0;
                k < this.lectureData[lecture][i].topics[j].subtopics.length;
                k++
              ) {
                totalNumberOfSteps += this.lectureData[lecture][i].topics[j]
                  .subtopics[k].steps;
              }
            }
          }
        }
        let returnData = {
          totalNumberOfCourses: totalNumberOfCourses,
          totalProgress: this.roundCourseProgress(
            (100 * totalNumberOfFinishedExercises) / totalNumberOfSteps
          )
        };
        if (requestedData) {
          if (requestedData === "totalProgress") {
            this.$store.state.totalProgressOfLectures[lecture] =
              returnData.totalProgress;
          }
          return returnData[requestedData];
        } else {
          return returnData;
        }
      } else {
        return 0;
      }
    },
    getLectureData(lessons, requestedData) {
      if (typeof lessons === "object") {
        let totalNumberOfSteps = 0;
        for (let i = 0; i < lessons.length; i++) {
          if ("steps" in lessons[i]) {
            totalNumberOfSteps += lessons[i].steps;
          }
        }

        let lectureWasStarted = false;
        let finishedCoursesInTopic = 0;
        let completelyFinishedExercisesInTopic = 0;
        let totalScoreInTopic = 0;
        for (let i = 0; i < lessons.length; i++) {
          if (
            ("started" in
              this.$store.state.userDataLectures[lessons[i].lesson] &&
              this.$store.state.userDataLectures[lessons[i].lesson].started) ||
            this.o([
              this.$store.state.userDataLectures,
              lessons[i].lesson,
              "finished",
              false
            ])
          ) {
            lectureWasStarted = true;
          }
          if (
            "finished" in
              this.$store.state.userDataLectures[lessons[i].lesson] &&
            this.$store.state.userDataLectures[lessons[i].lesson].finished
          ) {
            finishedCoursesInTopic++;
          }

          if (
            "completelyFinishedExercises" in
            this.$store.state.userDataLectures[lessons[i].lesson]
          ) {
            completelyFinishedExercisesInTopic += this.$store.state
              .userDataLectures[lessons[i].lesson].completelyFinishedExercises;
          }

          totalScoreInTopic += this.o([
            this.$store.state.userDataLectures,
            lessons[i].lesson,
            "maximumScore",
            0
          ]);
        }

        let returnData = {
          lectureWasStarted,
          finishedCoursesInTopic,
          totalNumberOfSteps,
          completelyFinishedExercisesInTopic,
          percentageFinishedExercises: this.roundCourseProgress(
            (100 * completelyFinishedExercisesInTopic) / totalNumberOfSteps
          ),
          totalScoreInTopic
        };
        if (requestedData) {
          return returnData[requestedData];
        } else {
          return returnData;
        }
      } else {
        if (requestedData) {
          return 0;
        } else {
          return {
            totalNumberOfSteps: 1,
            completelyFinishedExercisesInTopic: 0,
            percentageFinishedExercises: 0
          };
        }
      }
    },
    getAward(lessons) {
      let proportionOfCorrectAnswersInTopic =
        this.getLectureData(lessons, "totalScoreInTopic") /
        Math.max(this.getLectureData(lessons, "totalNumberOfSteps"), 1);

      let award = "award_1";
      if (proportionOfCorrectAnswersInTopic === 1) {
        award = "award_5";
      } else if (proportionOfCorrectAnswersInTopic >= 0.9) {
        award = "award_4";
      } else if (proportionOfCorrectAnswersInTopic >= 0.8) {
        award = "award_3";
      } else if (proportionOfCorrectAnswersInTopic >= 0.7) {
        award = "award_2";
      }

      return award;
    },
    selectTopic(indices, contentsPage, key) {
      if (key) {
        let keycode = key.keyCode ? key.keyCode : key.which;
        if (keycode !== 13 && keycode !== 32) return;
      }

      if (
        this.$store.state.coursePage === -1 &&
        this.payload2[indices[0]].topics[indices[1]].subtopics === undefined
      ) {
        return;
      }

      if (contentsPage === 1) {
        this.setPage(-1);
        this.$store.state.contentsPage = 2;
        let lecture = this.payload2[indices[0]].topics[indices[1]].subheading;
        this.$store.state.chosenLecture = lecture;

        let contents = this.payload2[indices[0]].topics[indices[1]].contents;
        this.$store.state.contentsOfLecture = contents;
        this.$emit("lectureChanged");

        scroll(0, 0);

        localStorage.setItem("chosenLecture", JSON.stringify(lecture));
        localStorage.setItem("contentsOfLecture", contents);
      } else {
        if (
          this.payload2[indices[0]].topics[indices[1]].subtopics !==
            undefined &&
          this.payload2[indices[0]].topics[indices[1]].subtopics.length === 0
        ) {
          return;
        }
        let lessonsOuterContainer = document.getElementById(
          "lecture__lessons-outer-container_" +
            indices[0] +
            "_" +
            indices[1] +
            "_" +
            contentsPage +
            this.lectureName2
        );
        let lessonsContainer = document.getElementById(
          "lecture__lessons-container_" +
            indices[0] +
            "_" +
            indices[1] +
            "_" +
            contentsPage +
            this.lectureName2
        );
        let lessonsContainerHeight =
          this.height(lessonsContainer) + this.remToPx(3.4);

        let lecture = document.querySelector(
          ".lecture_" +
            indices[0] +
            "_" +
            indices[1] +
            "_" +
            contentsPage +
            this.lectureName2
        );
        let lectureHeight = this.height(lecture);

        if (!this.lessonsAreShown) {
          this.lessonsAreShown = true;
          lessonsOuterContainer.style.maxHeight = lessonsContainerHeight + "px";

          let bottomCompleteContainer =
            this.$store.state.windowHeight -
            this.bottom(lessonsOuterContainer) -
            lessonsContainerHeight -
            this.remToPx(2);
          if (bottomCompleteContainer < 0) {
            let differenceY =
              Math.ceil(lectureHeight + lessonsContainerHeight) -
              this.$store.state.windowHeight;
            if (differenceY > this.remToPx(-6)) {
              differenceY = this.remToPx(-6);
            }
            if (this.$store.state.windowWidth <= 1000) {
              differenceY += this.remToPx(8);
            }
            this.scrollTo(lecture, differenceY);
          }
        } else {
          this.lessonsAreShown = false;
          lessonsOuterContainer.style.maxHeight = 0;
        }
      }
    },
    startCourse(lesson, event) {
      event.stopPropagation();
      if (lesson.lesson === undefined) {
        this.toggleAlert(this.$t("contents").lesson_does_not_exist);
        return;
      }

      if (lesson.restricted && !this.$store.state.isLicensed) {
        // // // this.toggleAlert(this.$t("contents").lesson_access_restricted);
        this.toggleModal("app-license-modal", "license", "app-license");
        return;
      }

      this.$store.state.chosenLesson = lesson.lesson;
      this.$store.state.headingOfChosenLesson = lesson.heading;

      localStorage.setItem("chosenLesson", lesson.lesson);
      localStorage.setItem(
        "headingOfChosenLesson",
        JSON.stringify(lesson.heading)
      );
      this.$store.state.courseWasReachedFrom = ["contents"];
      this.setPage("++");
      scroll(0, 0);
    }
  },
  watch: {
    coursePage(to) {
      if (to === -1) {
        this.lessonsAreShown = true;
        this.selectTopic(this.indices, this.contentsPage);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

::selection {
  background-color: transparent;
  color: currentColor;
}

.lecture {
  position: relative;
  width: 100%;
  padding: 1.25rem;
  cursor: pointer;

  @include respond(phone) {
    padding: 0 0.75rem;
    background-color: $color-black;

    border-bottom: 1px solid #222;
    border-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 0.5rem solid #131313;

    @include respond(phone) {
      border-bottom: 1px solid #222;
    }
  }

  &:last-child {
    @include respond(phone) {
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;

      border-bottom: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:last-child::before {
    background: transparent;
  }

  &::after {
    @include pseudo-element;
    left: 0;
    top: 0;
    width: 16.5rem;
    height: 100%;
    background-color: #181818;

    @include respond(phone) {
      content: initial;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    width: 14rem;
    height: 14rem;

    border-radius: 0.2rem;
    background-color: $color-black;

    &::after {
      @include pseudo-element;
      left: 0.3rem;
      bottom: 0.3rem;

      width: calc(100% - 0.6rem);
      height: 3.3rem;
      border-top: 0.375rem solid $color-black;
      border-radius: 0.2rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-color: #181818;
    }

    @include respond(phone) {
      display: none;
    }
  }

  &__grey-background &__image::before {
    @include pseudo-element;
    left: 0.3rem;
    top: 0.3rem;
    width: calc(100% - 0.6rem);
    height: calc(100% - 3.85rem);
    background-color: #181818;
  }

  &__award {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    top: 1.9rem;
    width: 6.5rem;

    &--2 {
      top: 2.35rem;
      width: 6.2rem;
    }
  }

  &__sheet {
    position: absolute;
    left: 50%;
    top: 35.5%;
    z-index: 1;
    transform: translate(-50%, -50%);

    width: 85%;
    height: 85%;

    &.lecture__finished {
      opacity: 1;
    }

    &--2 {
      left: 56.5%;
      top: 35%;
      width: 27.75%;

      filter: invert(0.85);
    }

    &--3 {
      left: 37.5%;
      top: 47.1%;
      width: 16%;

      filter: invert(0.85);
    }

    &--4 {
      top: 39%;
      width: 35%;
    }

    &--5 {
      left: 53%;
      top: 36.5%;
      width: 40%;
    }
  }

  &__figure-caption {
    position: absolute;
    bottom: 0.9rem;
    z-index: 1;
    width: 100%;
    text-align: center;
    font-size: 1.53rem;
    line-height: 1;
  }

  &__progress-round {
    position: absolute;
    left: 50%;
    top: calc(50% - 1.5rem);
    transform: translate(-50%, -50%) scale(0.9);
    z-index: 2;
  }

  &__text-container {
    position: relative;
    min-height: 14rem;
    outline: none;

    transform: translateY(0.2rem);

    @include respond(phone) {
      min-height: 10rem;
      padding: 1rem 0 2rem;
    }
  }

  &__heading {
    margin-left: 17.3rem;
    margin-bottom: 1.3rem;
    font-size: 2.6rem;
    line-height: 1;
    color: #eee;

    @include respond(phone) {
      margin-left: 0;
      margin-top: 0.2rem;
      margin-bottom: 0.7rem; // was: 0.65rem
      font-size: 3rem;
      color: $color-white;
    }
  }

  &__text {
    margin-left: 17.3rem;
    font-size: 2rem;
    line-height: 1.4;
    color: #999;

    @include respond(phone) {
      padding-bottom: 0.5rem;
      margin-left: 0;

      font-size: 2.4rem;
      line-height: 1.3;
      color: #aaa;
    }
  }

  &__lessons-outer-container {
    max-height: 0;
    overflow: hidden;

    will-change: height;
    backface-visibility: hidden;
    transition: max-height 0.3s;
  }
}
</style>
