<template>
  <button type="button" onclick="this.blur()" class="button">
    <span>
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: "linkButton",
  props: {
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.button {
  @include button-settings;
  width: auto;
  height: auto;
  font-size: 1.6rem;
  line-height: 1.1;
  // font-weight: initial;
  font-weight: 100;
  // color: $color-white;
  color: #e8e8e8;
  margin-bottom: 0;
  transition: color 0.2s;

  &:hover,
  &:focus {
    color: #1d9bf0;
    text-decoration: underline;
  }
}
</style>
