<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 588.3 182.7"
    style="enable-background:new 0 0 588.3 182.7"
  >
    <g>
      <circle class="st0" cx="497" cy="91.3" r="91.3" />
      <path
        class="st3"
        d="M482.8 101.5c0 3.4-.5 6.8-1.5 10-.9 2.9-2.3 5.6-4.2 8-1.8 2.2-4 4-6.5 5.3-2.7 1.3-5.6 1.9-8.6 1.9-7.9-.1-15.9-1-23.6-2.6v-15.1V65.5c-1.2-.2-2.1-.4-2.9-.5-.7-.1-1.3-.2-1.6-.3-.3.0-.5-.1-.7-.1-1.4-.2-2.5-1.5-2.4-2.9v-5.5H450v27.5c1.9-2 4.1-3.6 6.5-4.9 2.6-1.3 5.5-1.9 8.5-1.8 5.1-.1 9.8 2.3 12.9 6.3 1.7 2.3 2.9 4.9 3.7 7.7C482.4 94.5 482.8 98 482.8 101.5zm-12 0c0-2.4-.2-4.8-.7-7.2-.3-1.7-1-3.3-1.9-4.8-.8-1.2-1.8-2.1-3.1-2.7-1.3-.6-2.7-.9-4.1-.8-2.2-.1-4.3.5-6.2 1.5-1.8 1.1-3.5 2.6-4.8 4.3V117c3.1.6 6.2.9 9.4.9 1.6.0 3.3-.3 4.7-1 1.4-.7 2.7-1.7 3.6-3 1.1-1.5 1.8-3.3 2.3-5.1C470.6 106.4 470.8 104 470.8 101.5v0z"
      />
      <g>
        <path
          class="st3"
          d="M561.5 77.8v0h-8.8v0h-10.8v5.5c-.1 1.5.9 2.8 2.4 2.9.6.1.7.1 3.4.6l-5.2 18.8c-.4 1.4-.7 2.8-1 4.2-.3 1.3-.5 2.7-.7 4-.3-1.3-.6-2.6-1-3.8-.3-1.3-.7-2.6-1.1-3.9l-.8-2.7h-9.4l6.4 20.5c.4 1.4 1.2 2.1 2.2 2.1h9.4l12.3-39c4-.7 3.7-.7 4.4-.8 1.4-.2 2.5-1.5 2.4-2.9v-5.5L561.5 77.8z"
        />
        <path
          class="st3"
          d="M511.9 103.5l-.7 2.2c-.4 1.4-.9 2.8-1.2 4.1-.4 1.3-.8 2.7-1.1 4-.2-1.3-.4-2.7-.7-4-.2-1.3-.5-2.7-.9-4.1l-5.1-18.9c2.2-.4 2.3-.4 2.9-.5 1.4-.2 2.5-1.5 2.4-2.9v-5.5H497v0h-9.2v0H484v5.5c-.1 1.5.9 2.8 2.4 2.9.7.1.4.1 4.4.8l12.3 39h8.9c1.2.0 2.3-.9 2.5-2.1l6.5-20.5H511.9z"
        />
      </g>
      <path
        class="st1"
        d="M522.1 95.6l1.2-3.8c.3-.9.5-1.9.8-2.8.2-.9.4-1.9.6-2.8.2.9.4 1.8.6 2.7.2.9.5 1.8.8 2.7l1.2 3.9h9.5l-6-20.1c-.2-.6-.6-1.2-1.1-1.6-.6-.4-1.4-.6-2.1-.6h-5.1c-.7.0-1.4.2-2 .6-.5.4-.9.9-1.1 1.6L513 95.6H522.1z"
      />
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M18.2 85.3v3.9H0v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3V61.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h13.2v27.3c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C17.5 83.4 18.3 84.3 18.2 85.3zM14 47.4c-.6 1.2-1.6 2.2-2.8 2.7-.6.3-1.3.4-2 .4-.7.0-1.4-.1-2-.4-.6-.3-1.2-.6-1.6-1.1-.5-.5-.8-1-1.1-1.6-.3-.6-.4-1.3-.4-2 0-.7.1-1.4.4-2 .3-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.6-.3 1.3-.4 2-.4.7.0 1.4.1 2 .4.6.3 1.2.6 1.7 1.1.5.5.9 1 1.1 1.6.3.6.4 1.3.4 2C14.4 46.1 14.2 46.8 14 47.4z"
        />
        <path
          class="st0"
          d="M61.4 85.3v3.9H48.2V67.6c.1-1.7-.4-3.4-1.4-4.8-1.1-1.2-2.7-1.8-4.3-1.7-1.4.0-2.7.3-4 1-1.3.7-2.5 1.6-3.5 2.6v17.9c.8.1 1.5.3 1.9.3.5.1.8.1 1 .2.2.0.3.1.4.1 1 .1 1.7 1 1.7 2.1v3.9H21.9v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3V61.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h5 5c1-.1 1.8.6 2.1 1.5l.6 2.7c.7-.7 1.4-1.3 2.2-1.9.8-.6 1.6-1.1 2.4-1.5.9-.4 1.8-.7 2.7-1 1-.2 2.1-.3 3.2-.3 1.7.0 3.4.3 4.9.9 1.4.6 2.6 1.5 3.6 2.6 1 1.2 1.7 2.6 2.2 4.1.5 1.7.8 3.4.7 5.2v15c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C60.7 83.4 61.5 84.3 61.4 85.3z"
        />
        <path
          class="st0"
          d="M104.6 85.3v3.9H91.5V67.6c.1-1.7-.4-3.4-1.4-4.8-1.1-1.2-2.7-1.8-4.3-1.7-1.4.0-2.7.3-4 1-1.3.7-2.5 1.6-3.5 2.6v17.9c.8.1 1.5.3 1.9.3.5.1.8.1 1 .2.2.0.3.1.4.1 1 .1 1.7 1 1.7 2.1v3.9H65.1v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3V61.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h5 5c1-.1 1.8.6 2.1 1.5l.6 2.7c.7-.7 1.4-1.3 2.2-1.9.8-.6 1.6-1.1 2.4-1.5.9-.4 1.8-.7 2.7-1 1-.2 2.1-.3 3.2-.3 1.7.0 3.4.3 4.9.9 1.4.6 2.6 1.5 3.6 2.6 1 1.2 1.7 2.6 2.2 4.1.5 1.7.8 3.4.7 5.2v15c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C104 83.4 104.7 84.3 104.6 85.3z"
        />
        <path
          class="st0"
          d="M131.8 56c2 .8 3.8 1.9 5.3 3.5 1.5 1.6 2.6 3.4 3.4 5.5 1.6 4.7 1.6 9.8.0 14.5-.7 2.1-1.9 3.9-3.4 5.5-1.5 1.5-3.3 2.7-5.3 3.5-4.4 1.6-9.3 1.6-13.8.0-2-.8-3.8-2-5.3-3.5-1.5-1.6-2.7-3.5-3.4-5.5-1.6-4.7-1.6-9.8.0-14.5.7-2 1.9-3.9 3.4-5.5 1.5-1.5 3.3-2.7 5.3-3.5C122.4 54.4 127.3 54.4 131.8 56zM131.1 80.6c1.4-1.9 2-4.7 2-8.3.0-3.7-.7-6.4-2-8.4-1.4-1.9-3.4-2.9-6.3-2.9-2.9.0-5 1-6.3 2.9-1.4 1.9-2 4.7-2 8.3.0 3.6.7 6.4 2 8.3 1.4 1.9 3.5 2.9 6.3 2.9C127.7 83.4 129.8 82.4 131.1 80.6v0z"
        />
        <path
          class="st0"
          d="M181.9 55.3v3.9c.1 1-.6 1.9-1.7 2.1-.5.1-.3.1-3.1.6l-10.9 27.4h-7.4L148 61.8c-2.8-.5-2.6-.5-3.1-.6-1-.1-1.7-1-1.7-2.1v-3.9h2.2v0h6.8v0h7.5v3.9c.1 1-.6 1.9-1.7 2.1-.4.1-.5.1-1.8.3l4.8 13.3c.4 1.1.7 2.1.9 3.1.3 1 .5 2 .7 3 .2-1 .4-2 .7-3 .3-1.1.6-2.1 1-3.1l4.9-13.3c-1.7-.3-1.8-.3-2.2-.4-1-.1-1.7-1-1.7-2.1v-3.9h7.9v0h6.4v0H181.9z"
        />
        <path
          class="st0"
          d="M217.4 85.2v3.9h-7.8c-.6.0-1.2-.1-1.8-.3-.5-.3-.9-.8-1-1.4l-.7-2.4c-.8.7-1.6 1.4-2.5 2-.8.6-1.7 1.1-2.6 1.5-.9.4-1.8.7-2.8.9-1.1.2-2.2.3-3.3.3-1.3.0-2.7-.2-4-.6-1.2-.4-2.2-.9-3.1-1.7-.9-.8-1.6-1.8-2-2.9-.5-1.3-.8-2.6-.7-4 0-1.3.4-2.6 1-3.8.8-1.4 2-2.6 3.4-3.4 1.9-1.2 4-2 6.2-2.5 3.2-.7 6.4-1.1 9.7-1v-2c.1-1.8-.4-3.6-1.4-5.1-1.1-1.1-2.6-1.8-4.2-1.6-1.1.0-2.2.1-3.3.5-.8.3-1.6.6-2.3 1-.6.4-1.2.7-1.8 1-.6.3-1.2.5-1.8.5-.5.0-1-.1-1.4-.4-.4-.3-.7-.6-1-1.1l-1.5-2.6c3.8-3.5 8.9-5.5 14.1-5.3 1.8.0 3.6.3 5.3 1 1.5.6 2.8 1.5 3.9 2.7 1.1 1.2 1.9 2.6 2.5 4.1.6 1.7.9 3.5.8 5.3v15c.8.1 1.3.2 1.7.3.4.1.6.1.7.1C216.8 83.3 217.5 84.2 217.4 85.2zM205.3 74.7c-2 0-4 .1-6 .4-1.3.2-2.6.6-3.8 1.1-.8.3-1.5.9-2.1 1.6-.4.6-.6 1.3-.6 2-.1 1.2.4 2.3 1.3 3.1 1 .7 2.1 1 3.3.9.8.0 1.6-.1 2.3-.2.7-.1 1.4-.4 2-.7.6-.3 1.2-.7 1.8-1.1.6-.5 1.2-1 1.8-1.6L205.3 74.7z"
        />
        <path
          class="st0"
          d="M228.1 87.2c-1.7-1.9-2.5-4.4-2.4-6.9V61.4h-3.4c-.9.0-1.6-.6-1.6-1.5.0-.1.0-.1.0-.2v-3.2l5.4-.9 1.7-9.2c.1-.4.3-.8.6-1 .3-.3.8-.4 1.2-.4h4.2v10.7h8.9v5.8h-8.9v18.4c-.1.9.2 1.8.8 2.5.5.6 1.3.9 2.1.9.4.0.9-.1 1.3-.2.3-.1.6-.2.9-.4.3-.1.5-.3.7-.4.2-.1.4-.2.6-.2.2.0.4.1.6.2.2.2.4.3.5.5l2.4 4c-1.2 1-2.6 1.8-4.1 2.2-1.5.5-3.2.8-4.8.8C232.4 89.9 229.9 88.9 228.1 87.2z"
        />
        <path
          class="st0"
          d="M265.2 85.3v3.9H247v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3V61.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h13.2v27.3c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C264.5 83.4 265.3 84.3 265.2 85.3zM261 47.4c-.6 1.2-1.6 2.2-2.8 2.7-.6.3-1.3.4-2 .4-.7.0-1.4-.1-2-.4-1.2-.5-2.2-1.5-2.7-2.7-.3-.6-.4-1.3-.4-2 0-.7.1-1.4.4-2 .3-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.6-.3 1.3-.4 2-.4.7.0 1.4.1 2 .4.6.3 1.2.6 1.7 1.1.5.5.9 1 1.1 1.6.3.6.4 1.3.4 2C261.4 46.1 261.2 46.8 261 47.4v0z"
        />
        <path
          class="st0"
          d="M292.2 56c2 .8 3.8 1.9 5.3 3.5 1.5 1.6 2.6 3.4 3.4 5.5 1.6 4.7 1.6 9.8.0 14.5-.7 2.1-1.9 3.9-3.4 5.5-1.5 1.5-3.3 2.7-5.3 3.5-4.4 1.6-9.3 1.6-13.8.0-2-.8-3.8-2-5.3-3.5-1.5-1.6-2.7-3.5-3.4-5.5-1.6-4.7-1.6-9.8.0-14.5.7-2 1.9-3.9 3.4-5.5 1.5-1.5 3.3-2.7 5.3-3.5C282.9 54.4 287.8 54.4 292.2 56zM291.6 80.6c1.4-1.9 2-4.7 2-8.3.0-3.7-.7-6.4-2-8.4-1.4-1.9-3.4-2.9-6.3-2.9-2.9.0-5 1-6.3 2.9-1.4 1.9-2 4.7-2 8.3.0 3.6.7 6.4 2 8.3 1.4 1.9 3.5 2.9 6.3 2.9C288.2 83.4 290.3 82.4 291.6 80.6v0z"
        />
        <path
          class="st0"
          d="M345.2 85.3v3.9H332V67.6c.1-1.7-.4-3.4-1.4-4.8-1.1-1.2-2.7-1.8-4.3-1.7-1.4.0-2.7.3-4 1-1.3.7-2.5 1.6-3.5 2.6v17.9c.8.1 1.5.3 1.9.3.5.1.8.1 1 .2.2.0.3.1.4.1 1 .1 1.7 1 1.7 2.1v3.9h-18.1v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3V61.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h5 5c1-.1 1.8.6 2.1 1.5l.6 2.7c.7-.7 1.4-1.3 2.2-1.9.8-.6 1.6-1.1 2.4-1.5.9-.4 1.8-.7 2.7-1 1-.2 2.1-.3 3.2-.3 1.7.0 3.4.3 4.9.9 1.4.6 2.6 1.5 3.6 2.6 1 1.2 1.7 2.6 2.2 4.1.5 1.7.8 3.4.7 5.2v15c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C344.5 83.4 345.3 84.3 345.2 85.3z"
        />
        <path
          class="st0"
          d="M373.3 78.4c0 1.5-.3 3.1-.9 4.5-.6 1.4-1.5 2.6-2.6 3.6-1.2 1.1-2.7 1.9-4.2 2.3-1.9.6-3.8.9-5.8.8-3.7.0-7.3-.6-10.7-1.8v-9.6h3.8c.8-.1 1.4.5 1.5 1.2.1.3.2 1.2.4 3.9 1.7.4 3.5.6 5.3.6.8.0 1.7-.1 2.5-.3.6-.2 1.2-.5 1.7-.9.4-.3.8-.8 1-1.3.2-.5.3-1 .3-1.5.0-.7-.2-1.4-.7-1.9-.5-.6-1.2-1-1.9-1.3-.9-.4-1.8-.7-2.7-1-1-.3-2-.6-3.1-1-1-.4-2.1-.8-3.1-1.2-1-.5-1.9-1.1-2.7-1.8-.8-.8-1.4-1.7-1.9-2.7-.5-1.2-.7-2.5-.7-3.8.0-1.4.3-2.7.8-4 .6-1.3 1.4-2.4 2.4-3.3 1.2-1 2.5-1.8 4-2.2 1.8-.6 3.7-.9 5.5-.8 3.3.0 5.1.3 9.8 1.7v0 9.6h-3.8c-.8.1-1.4-.5-1.5-1.2-.1-.3-.2-1.2-.4-3.7-1.3-.3-2.6-.5-3.9-.4-1.3-.1-2.7.3-3.8 1-.9.6-1.4 1.6-1.4 2.7.0.7.2 1.3.7 1.8.5.5 1.2 1 1.9 1.3.9.4 1.7.7 2.7 1 1 .3 2 .6 3 1 1 .4 2 .8 3 1.2 1 .4 1.9 1 2.7 1.7.8.7 1.4 1.5 1.9 2.5C373.1 76 373.3 77.2 373.3 78.4z"
        />
      </g>
      <g>
        <path
          class="st2"
          d="M54.7 109.2c1.2.3 2.4.7 3.5 1.3h9.7v3c0 .4-.1.9-.4 1.2-.4.3-.8.5-1.4.6l-3 .6c.2.6.4 1.2.5 1.8.1.7.2 1.3.2 2 .1 3.1-1.3 6.1-3.8 8-1.2 1-2.7 1.7-4.2 2.2-1.7.5-3.5.8-5.3.8-1.3.0-2.5-.1-3.7-.4-1.1.6-1.6 1.4-1.6 2.1.0.6.3 1.2.9 1.5.8.4 1.6.6 2.5.7 1.2.1 2.3.2 3.5.2 1.3.0 2.6.1 4 .2 1.3.1 2.7.3 4 .6 1.2.2 2.4.7 3.5 1.3 1 .6 1.9 1.4 2.5 2.4.7 1.2 1 2.6.9 3.9.0 1.5-.4 3-1.1 4.4-.8 1.5-1.9 2.8-3.2 3.8-1.5 1.2-3.3 2.1-5.1 2.7-2.2.7-4.6 1-6.9 1-2.3.0-4.5-.2-6.7-.7-1.7-.4-3.3-1.1-4.7-2-1.1-.7-2.1-1.7-2.8-2.9-.6-1-.9-2.2-.9-3.4.0-1.5.5-2.9 1.5-4 1.1-1.2 2.5-2.1 4-2.6-.8-.4-1.5-1.1-2-1.8-.5-.9-.8-1.9-.8-3 0-.5.1-1.1.3-1.6.2-.6.5-1.1.8-1.6.4-.6.9-1.1 1.4-1.5.6-.5 1.2-.9 1.9-1.3-1.6-.9-3-2.1-4-3.7-2.6-4.4-1.6-10.1 2.3-13.4 1.3-1 2.7-1.7 4.3-2.2C48.3 108.6 51.5 108.5 54.7 109.2v0zM58.9 143.1c-.5-.5-1-.8-1.6-1-.8-.3-1.6-.4-2.4-.5-.9-.1-1.9-.2-2.9-.2-1-.1-2.1-.1-3.2-.2-1.1-.1-2.1-.1-3.2-.3-.9.4-1.6 1.1-2.2 1.8-.6.7-.9 1.5-.8 2.4.0.6.2 1.2.4 1.7.3.6.8 1 1.4 1.3.8.4 1.6.7 2.5.9 2.6.4 5.2.4 7.7.0 1-.2 1.9-.5 2.7-1 .6-.3 1.2-.8 1.6-1.5.3-.6.5-1.2.5-1.8C59.5 144.1 59.3 143.5 58.9 143.1zM53.2 125c.7-.2 1.3-.6 1.9-1.1.5-.5.9-1.1 1.1-1.7.3-.7.4-1.5.4-2.2.1-1.5-.5-2.9-1.5-4-1.2-1.1-2.9-1.6-4.5-1.5-1.6-.1-3.3.4-4.5 1.5-1 1.1-1.6 2.5-1.5 4 0 .8.1 1.5.4 2.2.2.7.6 1.3 1.1 1.8.5.5 1.2.9 1.9 1.2C49.7 125.5 51.5 125.5 53.2 125z"
        />
        <path
          class="st2"
          d="M110.3 139.3v3.9h-10c-1 .1-1.8-.6-2.1-1.5l-.6-2.7c-.7.7-1.4 1.3-2.2 1.9-.7.6-1.5 1.1-2.4 1.5-.9.4-1.8.7-2.7 1-1 .2-2.1.4-3.2.3-1.7.0-3.4-.3-4.9-.9-1.4-.6-2.6-1.5-3.6-2.7-1-1.2-1.7-2.6-2.2-4.1-.5-1.7-.8-3.4-.7-5.2v-15c-2.8-.5-2.8-.5-3.3-.6-1-.1-1.7-1-1.7-2.1v-3.9h5v0h8.2v21.5c-.1 1.7.4 3.4 1.4 4.8 1.1 1.2 2.7 1.8 4.3 1.7 1.4.0 2.7-.3 4-.9 1.3-.7 2.5-1.5 3.5-2.6v-17.9c-2.8-.5-2.8-.5-3.3-.6-1-.1-1.7-1-1.7-2.1v-3.9h13.1v27.3c2.8.5 2.8.5 3.3.6C109.7 137.4 110.4 138.3 110.3 139.3z"
        />
        <path
          class="st2"
          d="M120.8 141.2c-1.7-1.9-2.5-4.4-2.4-6.9v-18.9H115c-.9.0-1.6-.6-1.6-1.5.0-.1.0-.1.0-.2v-3.2l5.4-.9 1.7-9.2c.1-.4.3-.8.6-1 .3-.3.8-.4 1.2-.4h4.2v10.7h8.9v5.8h-8.9v18.4c-.1.9.2 1.8.8 2.5.5.6 1.3.9 2.1.9.4.0.9-.1 1.3-.2.3-.1.6-.2.9-.4.3-.1.5-.3.7-.4.2-.1.4-.2.6-.2.2.0.4.1.6.2.2.2.4.3.5.5l2.4 4c-1.2 1-2.6 1.8-4.1 2.2-1.5.5-3.2.8-4.8.8C125.1 143.9 122.6 142.9 120.8 141.2z"
        />
        <path
          class="st2"
          d="M164.5 132.4c0 1.5-.3 3.1-.9 4.5-.6 1.4-1.5 2.6-2.6 3.6-1.2 1.1-2.7 1.9-4.2 2.3-1.9.6-3.8.9-5.8.8-3.7.0-7.3-.6-10.7-1.8v-9.6h3.8c.8-.1 1.4.5 1.5 1.2.1.3.2 1.2.4 3.9 1.7.4 3.5.6 5.3.6.8.0 1.7-.1 2.5-.3.6-.2 1.2-.5 1.7-.9.4-.3.8-.8 1-1.3.2-.5.3-1 .3-1.5.0-.7-.2-1.4-.7-1.9-.5-.6-1.2-1-1.9-1.3-.9-.4-1.8-.7-2.7-1-1-.3-2-.6-3.1-1-1-.4-2.1-.8-3.1-1.2-1-.5-1.9-1.1-2.7-1.8-.8-.8-1.4-1.7-1.9-2.7-.5-1.2-.7-2.5-.7-3.8.0-1.4.3-2.7.8-4 .6-1.3 1.4-2.4 2.4-3.3 1.2-1 2.5-1.8 4-2.2 1.8-.6 3.7-.9 5.5-.8 3.3.0 5.1.3 9.8 1.7v0 9.6H159c-.8.1-1.4-.5-1.5-1.2-.1-.3-.2-1.2-.4-3.7-1.3-.3-2.6-.5-3.9-.4-1.3-.1-2.7.3-3.8 1-.9.6-1.4 1.6-1.4 2.7.0.7.2 1.3.7 1.8.5.5 1.2 1 1.9 1.3.9.4 1.7.7 2.7 1 1 .3 2 .6 3 1 1 .4 2 .8 3 1.2 1 .4 1.9 1 2.7 1.7.8.7 1.4 1.5 1.9 2.5C164.3 130 164.5 131.2 164.5 132.4z"
        />
        <path
          class="st2"
          d="M197.1 138.1c-.9 1-1.8 1.9-2.9 2.7-1 .7-2.1 1.3-3.3 1.7-1.1.4-2.3.7-3.5.9-1.2.2-2.4.3-3.6.3-2 0-4-.4-5.9-1.2-1.8-.8-3.5-1.9-4.8-3.4-1.4-1.6-2.5-3.5-3.3-5.5-.8-2.4-1.2-4.9-1.2-7.4.0-2.3.3-4.7 1.1-6.9.7-2 1.7-3.9 3.2-5.5 1.4-1.6 3.2-2.8 5.1-3.6 2.2-.9 4.6-1.4 7.1-1.3 2.7.0 6.3.8 10.7 2.4v10.4h-3.8c-.8.1-1.4-.5-1.5-1.2-.1-.6-.3-2.1-.5-4.8-1.4-.4-2.8-.6-4.3-.6-1.3.0-2.6.2-3.8.8-1.1.5-2 1.3-2.7 2.2-.8 1.1-1.3 2.3-1.6 3.5-.4 1.5-.5 3.1-.5 4.7.0 1.6.2 3.2.6 4.8.3 1.3.9 2.5 1.7 3.5.7.9 1.6 1.7 2.6 2.2 1.1.5 2.3.8 3.5.7 1.1.0 2.1-.1 3.2-.5.7-.3 1.4-.6 2-1.1.5-.4 1-.7 1.4-1.1.4-.3.9-.5 1.4-.5.6.0 1.2.3 1.5.8L197.1 138.1z"
        />
        <path
          class="st2"
          d="M239.8 139.3v3.9h-13.2v-21.6c.1-1.7-.4-3.4-1.4-4.8-1.1-1.2-2.7-1.8-4.3-1.7-1.4.0-2.7.3-4 1-1.3.7-2.5 1.6-3.5 2.6v17.9c.8.1 1.5.3 1.9.3.5.1.8.1 1 .2.2.0.3.1.4.1 1 .1 1.7 1 1.7 2.1v3.9h-18.1v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3v-36c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h13.2V113c1.3-1.2 2.8-2.3 4.4-3 1.7-.8 3.7-1.2 5.6-1.2 1.7.0 3.4.3 4.9.9 1.4.6 2.6 1.5 3.6 2.6 1 1.2 1.7 2.6 2.2 4.1.5 1.7.8 3.4.7 5.2v15c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C239.1 137.4 239.8 138.3 239.8 139.3z"
        />
        <path
          class="st2"
          d="M265.1 109.8c1.7.6 3.3 1.7 4.6 3 1.3 1.4 2.4 3 3 4.8.8 2.1 1.1 4.3 1.1 6.5.0.5.0 1-.1 1.5.0.3-.1.7-.3 1-.1.2-.3.4-.6.5-.3.1-.6.2-.9.1h-20.9c.2 3.5 1.2 6 2.8 7.7 1.7 1.7 4.1 2.6 6.5 2.4 1.2.0 2.3-.1 3.4-.5.9-.3 1.7-.6 2.5-1 .7-.4 1.3-.7 1.9-1 .5-.3 1-.4 1.6-.5.3.0.6.1.9.2.2.1.5.3.6.6l2.4 3c-.9 1-1.9 1.9-3 2.7-1.1.7-2.3 1.3-3.5 1.7-1.2.4-2.5.7-3.7.9-1.2.2-2.4.3-3.7.3-2.2.0-4.5-.4-6.6-1.2-2-.8-3.8-1.9-5.3-3.5-1.6-1.6-2.8-3.6-3.5-5.7-.9-2.5-1.3-5.2-1.3-7.8.0-2.2.4-4.4 1.1-6.5.7-2 1.8-3.8 3.2-5.3 1.4-1.5 3.2-2.8 5.1-3.6 2.2-.9 4.5-1.4 6.8-1.3C261.3 108.8 263.3 109.1 265.1 109.8zM253.8 116.6c-1.5 1.6-2.4 3.6-2.6 5.7h15.3c0-1-.1-2-.4-3-.3-.9-.7-1.7-1.3-2.5-.6-.7-1.4-1.3-2.2-1.7-1-.4-2.1-.6-3.2-.6C257.4 114.5 255.3 115.2 253.8 116.6v0z"
        />
        <path
          class="st2"
          d="M295.4 139.3v3.9h-18.2v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3v-20.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h13.2v27.3c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C294.7 137.4 295.4 138.3 295.4 139.3zM291.1 101.4c-.6 1.2-1.6 2.2-2.8 2.7-.6.3-1.3.4-2 .4-.7.0-1.4-.1-2-.4-1.2-.5-2.2-1.5-2.7-2.7-.3-.6-.4-1.3-.4-2 0-.7.1-1.4.4-2 .3-.6.6-1.2 1.1-1.6.5-.5 1-.8 1.6-1.1.6-.3 1.3-.4 2-.4.7.0 1.4.1 2 .4.6.3 1.2.6 1.7 1.1.5.5.9 1 1.1 1.6.3.6.4 1.3.4 2C291.6 100.1 291.4 100.8 291.1 101.4v0z"
        />
        <path
          class="st2"
          d="M338.4 139.3v3.9h-13.2v-21.6c.1-1.7-.4-3.4-1.4-4.8-1.1-1.2-2.7-1.8-4.3-1.7-1.4.0-2.7.3-4 1-1.3.7-2.5 1.6-3.5 2.6v17.9c.8.1 1.5.3 1.9.3.5.1.8.1 1 .2.2.0.3.1.4.1 1 .1 1.7 1 1.7 2.1v3.9h-18.1v-3.9c-.1-1 .6-1.9 1.7-2.1.1.0.2.0.4-.1.2.0.5-.1 1-.2.5-.1 1.1-.2 2-.3v-20.8c-.8-.1-1.5-.3-2-.3-.5-.1-.8-.1-1-.2-.2.0-.3-.1-.4-.1-1-.1-1.7-1-1.7-2.1v-3.9h5 5c1-.1 1.8.6 2.1 1.5l.6 2.7c.7-.7 1.4-1.3 2.2-1.9.8-.6 1.6-1.1 2.4-1.5.9-.4 1.8-.7 2.7-1 1-.2 2.1-.3 3.2-.3 1.7.0 3.4.3 4.9.9 1.4.6 2.6 1.5 3.6 2.6 1 1.2 1.7 2.6 2.2 4.1.5 1.7.8 3.4.7 5.2v15c.8.1 1.5.3 2 .3.5.1.8.1 1 .2.2.0.3.1.4.1C337.7 137.4 338.4 138.3 338.4 139.3z"
        />
        <path
          class="st2"
          d="M363.8 109.8c1.7.6 3.3 1.7 4.6 3 1.3 1.4 2.4 3 3 4.8.8 2.1 1.1 4.3 1.1 6.5.0.5.0 1-.1 1.5.0.3-.1.7-.3 1-.1.2-.3.4-.6.5-.3.1-.6.2-.9.1h-20.9c.2 3.5 1.2 6 2.8 7.7 1.7 1.7 4.1 2.6 6.5 2.4 1.2.0 2.3-.1 3.4-.5.9-.3 1.7-.6 2.5-1 .7-.4 1.3-.7 1.9-1 .5-.3 1-.4 1.6-.5.3.0.6.1.9.2.2.1.5.3.6.6l2.4 3c-.9 1-1.9 1.9-3 2.7-1.1.7-2.3 1.3-3.5 1.7-1.2.4-2.5.7-3.7.9-1.2.2-2.4.3-3.7.3-2.2.0-4.5-.4-6.6-1.2-2-.8-3.8-1.9-5.3-3.5-1.6-1.6-2.8-3.6-3.5-5.7-.9-2.5-1.3-5.2-1.3-7.8.0-2.2.4-4.4 1.1-6.5.7-2 1.8-3.8 3.2-5.3 1.4-1.5 3.2-2.8 5.1-3.6 2.2-.9 4.5-1.4 6.8-1.3C359.9 108.8 361.9 109.1 363.8 109.8zM352.5 116.6c-1.5 1.6-2.4 3.6-2.6 5.7h15.3c0-1-.1-2-.4-3-.3-.9-.7-1.7-1.3-2.5-.6-.7-1.4-1.3-2.2-1.7-1-.4-2.1-.6-3.2-.6C356 114.5 354 115.2 352.5 116.6z"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.st0 {
  fill: #c3c3c3;
}
.st1 {
  fill: #393939;
}
.st2 {
  fill: #888;
}

.st3 {
  fill: $color-grey-dark;
}
</style>
