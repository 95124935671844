<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 597.6 187.1"
    style="enable-background:new 0 0 597.6 187.1"
  >
    <g>
      <path class="st0" d="M21.8 40.6v5.9h-7.3v23.8h-7V46.5H0v-5.9H21.8z" />
      <path
        class="st0"
        d="M41.8 60.4H27.7c0 .4.0.7.0 1-.1 1.3.4 2.6 1.2 3.5.8.8 1.9 1.2 3 1.2 2.2.0 3.5-1.2 3.9-3.5l5.9.4c-1.6 5.1-5 7.6-10 7.6-1.9.1-3.9-.4-5.6-1.3-1.6-.9-2.9-2.2-3.8-3.8-1-1.8-1.5-3.9-1.5-6-.1-3.1.9-6.1 3-8.5 4.1-4.2 10.7-4.3 14.9-.2.0.0.0.0.0.0C40.8 52.8 41.8 56 41.8 60.4zM27.7 56.6h7.8c-.2-3-1.4-4.5-3.7-4.5C29.4 52.1 28 53.6 27.7 56.6z"
      />
      <path
        class="st0"
        d="M65.2 62c-.2 2.4-1.3 4.7-3.1 6.3-1.9 1.7-4.4 2.5-7 2.4-2.8.1-5.6-1-7.6-3.1-2-2.2-3.1-5.2-2.9-8.2-.1-3.1.9-6.1 3-8.4 2.1-2.2 5-3.3 8-3.2 2.4-.1 4.8.8 6.7 2.4 1.7 1.5 2.8 3.7 2.9 6l-6 .3c-.2-2.6-1.3-4-3.4-4-2.6.0-3.8 2.1-3.8 6.3.0 2.8.4 4.6 1.1 5.3.7.7 1.6 1.1 2.6 1.1 2 0 3.2-1.3 3.6-3.8L65.2 62z"
      />
      <path
        class="st0"
        d="M75.3 40.6v10.7c1.1-2.2 3.4-3.6 5.9-3.4 1.3.0 2.6.3 3.8 1 1 .6 1.8 1.4 2.2 2.5.5 1.4.7 2.9.6 4.5v14.5h-6.5V57.9c0-1 0-2-.2-3-.1-.5-.4-1-.8-1.4-.4-.4-1-.6-1.5-.6-.9.0-1.7.4-2.4.9-.8.6-1.2 2-1.2 4v12.4H69V40.6H75.3z"
      />
      <path
        class="st0"
        d="M112.3 70.2h-6.5V57.9c0-1 0-2-.2-3.1-.1-.5-.4-1-.8-1.4-.5-.4-1.1-.6-1.7-.6-2.3.0-3.4 1.7-3.4 5v12.3h-6.5V48.3h6V52c.7-1.4 1.7-2.6 3-3.4 1-.5 2.1-.8 3.2-.8 1.8-.1 3.6.5 5 1.7 1.3 1.1 1.9 3.3 1.9 6.6V70.2z"
      />
      <path
        class="st0"
        d="M126.7 47.8c3-.2 5.9 1.1 7.8 3.3 1.9 2.3 2.9 5.2 2.8 8.2.1 3-.9 5.9-2.9 8.1-2 2.2-4.8 3.4-7.8 3.2-2.9.1-5.6-1.1-7.5-3.2-1.9-2.3-2.9-5.2-2.8-8.3-.1-2.1.4-4.1 1.3-5.9.8-1.6 2-2.9 3.5-3.9C122.8 48.3 124.7 47.8 126.7 47.8zM126.9 52.1c-1.1-.1-2.2.5-2.8 1.4-.6.9-.9 2.8-.9 5.7-.1 1.9.2 3.8.9 5.6.5 1.1 1.6 1.7 2.8 1.7 1.1.0 2.1-.5 2.7-1.4.7-1 1-2.8 1-5.5.0-2.9-.3-4.8-.9-5.9C129.1 52.6 128 52 126.9 52.1v0z"
      />
      <path class="st0" d="M141.4 70.2V40.6h6.5v29.7h-6.5z" />
      <path
        class="st0"
        d="M162.3 47.8c3-.2 5.9 1.1 7.8 3.3 1.9 2.3 2.9 5.2 2.8 8.2.1 3-.9 5.9-2.9 8.1-2 2.2-4.8 3.4-7.8 3.2-2.9.1-5.6-1.1-7.5-3.2-1.9-2.3-2.9-5.2-2.8-8.3-.1-2.1.4-4.1 1.3-5.9.8-1.6 2-2.9 3.5-3.9C158.4 48.3 160.3 47.8 162.3 47.8zM162.5 52.1c-1.1-.1-2.2.5-2.8 1.4-.6.9-.9 2.8-.9 5.7-.1 1.9.2 3.8.9 5.6.5 1.1 1.6 1.7 2.8 1.7 1.1.0 2.1-.5 2.7-1.4.7-1 1-2.8 1-5.5.0-2.9-.3-4.8-.9-5.9C164.7 52.6 163.6 52 162.5 52.1v0z"
      />
      <path
        class="st0"
        d="M199 48.8c-.6-.1-1.1-.2-1.7-.2-1.2-.2-2.3.7-2.5 1.9 1.1 1.1 1.8 2.6 1.8 4.2.0 2-.9 3.9-2.6 5-1.7 1.2-4.2 1.8-7.5 1.8-1.1.0-2.3-.1-3.4-.3-.7.3-1.1 1-1.1 1.7-.1.7.3 1.3.9 1.5 1.5.4 3 .5 4.5.5 2.3.1 4.7.3 7 .6 1.2.2 2.4.9 3.2 1.8.9 1 1.3 2.2 1.3 3.5.0 2.1-1.1 4-2.8 5.1-1.9 1.4-5.2 2.1-9.9 2.1-4.4.0-7.4-.5-9-1.4-1.4-.7-2.3-2.1-2.3-3.7.0-2 1.3-3.3 3.8-4-1.9-.9-2.8-2.2-2.8-3.9.0-2.1 1.5-3.7 4.4-4.9-2.6-1.1-3.9-2.9-3.9-5.4.0-2 1-3.9 2.7-5 2.2-1.4 4.8-2.1 7.4-1.9 2 0 3.9.3 5.8 1 .1-1.1.5-2.2 1.1-3.1.6-.8 1.9-1.2 3.8-1.2.6.0 1.2.1 1.8.2L199 48.8zM182 70.1c-.4.1-.7.2-1 .5-.4.3-.6.8-.6 1.3.0 1 .6 1.6 1.9 1.8 1.5.3 2.9.4 4.4.4 1.6.1 3.1-.1 4.7-.5.8-.2 1.3-.8 1.3-1.6.0-.6-.4-1-1.1-1.3-.7-.3-2.7-.5-6-.5h-1.3c-.5.0-1.1.0-1.6-.1C182.5 70.1 182.3 70.1 182 70.1v0zM186.3 51.3c-1-.1-1.9.3-2.6.9-.6.6-1 1.5-.9 2.4.0 2.2 1.2 3.3 3.6 3.3 1 .1 2-.3 2.7-1 1.3-1.3 1.3-3.4.1-4.7C188.4 51.6 187.4 51.3 186.3 51.3v0z"
      />
      <path
        class="st0"
        d="M208.1 40v6.5h-6.5V40h6.5zM208.1 48.3v21.9h-6.5V48.3h6.5z"
      />
      <path
        class="st0"
        d="M233.2 60.4H219c0 .4.0.7.0 1-.1 1.3.4 2.6 1.2 3.5.8.8 1.9 1.2 3 1.2 2.2.0 3.5-1.2 3.9-3.5l5.9.4c-1.6 5.1-5 7.6-10 7.6-1.9.1-3.9-.4-5.6-1.3-1.6-.9-2.9-2.2-3.8-3.8-1-1.8-1.5-3.9-1.5-6-.1-3.1.9-6.1 3-8.5 4.1-4.2 10.7-4.3 14.9-.2.0.0.0.0.0.0C232.1 52.8 233.2 56 233.2 60.4zM219 56.6h7.8c-.2-3-1.4-4.5-3.7-4.5C220.7 52.1 219.3 53.6 219 56.6z"
      />
      <path
        class="st0"
        d="M244.5 58.5v11.8h-7V40.6h13c2-.1 3.9.2 5.8.8 1.5.6 2.8 1.7 3.6 3.1 1 1.5 1.5 3.2 1.4 5 0 3.6-1.7 6.1-5.1 7.7l5.5 13.1H254l-4.6-11.8H244.5zM244.5 45.9v7.6h4.3c1 0 2-.1 3-.3.7-.2 1.3-.7 1.7-1.3.5-.7.8-1.5.7-2.3.0-.8-.3-1.6-.7-2.2-.4-.6-1-1-1.6-1.2-1.1-.2-2.2-.3-3.3-.2L244.5 45.9z"
      />
      <path
        class="st0"
        d="M285.4 60.4h-14.1c0 .4.0.7.0 1-.1 1.3.4 2.6 1.2 3.5.8.8 1.9 1.2 3 1.2 2.2.0 3.5-1.2 3.9-3.5l5.9.4c-1.6 5.1-5 7.6-10 7.6-1.9.1-3.9-.4-5.6-1.3-1.6-.9-2.9-2.2-3.8-3.8-1-1.8-1.5-3.9-1.5-6-.1-3.1.9-6.1 3-8.5 4.1-4.2 10.7-4.3 14.9-.2.0.0.0.0.0.0C284.4 52.8 285.4 56 285.4 60.4zM271.3 56.6h7.8c-.2-3-1.4-4.5-3.7-4.5C272.9 52.1 271.6 53.6 271.3 56.6z"
      />
      <path
        class="st0"
        d="M311.4 48.8c-.6-.1-1.1-.2-1.7-.2-1.2-.2-2.3.7-2.5 1.9 1.1 1.1 1.8 2.6 1.8 4.2.0 2-.9 3.9-2.6 5-1.7 1.2-4.2 1.8-7.5 1.8-1.1.0-2.3-.1-3.4-.3-.7.3-1.1 1-1.1 1.7-.1.7.3 1.3.9 1.5 1.5.4 3 .5 4.5.5 2.3.1 4.7.3 7 .6 1.2.2 2.4.9 3.2 1.8.9 1 1.3 2.2 1.3 3.5.0 2.1-1.1 4-2.8 5.1-1.9 1.4-5.2 2.1-9.9 2.1-4.4.0-7.4-.5-9-1.4-1.4-.7-2.3-2.1-2.3-3.7.0-2 1.3-3.3 3.8-4-1.9-.9-2.8-2.2-2.8-3.9.0-2.1 1.5-3.7 4.4-4.9-2.6-1.1-3.9-2.9-3.9-5.4.0-2 1-3.9 2.7-5 2.2-1.4 4.8-2.1 7.4-1.9 2 0 3.9.3 5.8 1 .1-1.1.5-2.2 1.1-3.1.6-.8 1.9-1.2 3.8-1.2.6.0 1.2.1 1.8.2V48.8zM294.5 70.1c-.4.1-.7.2-1 .5-.4.3-.6.8-.6 1.3.0 1 .6 1.6 1.9 1.8 1.5.3 2.9.4 4.4.4 1.6.1 3.1-.1 4.7-.5.8-.2 1.3-.8 1.3-1.6.0-.6-.4-1-1.1-1.3-.7-.3-2.7-.5-6-.5h-1.3c-.5.0-1.1.0-1.6-.1C295 70.1 294.8 70.1 294.5 70.1v0zM298.8 51.3c-1-.1-1.9.3-2.6.9-.6.6-1 1.5-.9 2.4.0 2.2 1.2 3.3 3.6 3.3 1 .1 2-.3 2.7-1 1.3-1.3 1.3-3.4.1-4.7C300.9 51.6 299.8 51.3 298.8 51.3v0z"
      />
      <path
        class="st0"
        d="M320.6 40v6.5h-6.5V40h6.5zM320.6 48.3v21.9h-6.5V48.3h6.5z"
      />
      <path
        class="st0"
        d="M335 47.8c3-.2 5.9 1.1 7.8 3.3 1.9 2.3 2.9 5.2 2.8 8.2.1 3-.9 5.9-2.9 8.1-2 2.2-4.8 3.4-7.8 3.2-2.9.1-5.6-1.1-7.5-3.2-1.9-2.3-2.9-5.2-2.8-8.3-.1-2.1.4-4.1 1.3-5.9.8-1.6 2-2.9 3.5-3.9C331.1 48.3 333 47.8 335 47.8zM335.2 52.1c-1.1-.1-2.2.5-2.8 1.4-.6.9-.9 2.8-.9 5.7-.1 1.9.2 3.8.9 5.6.5 1.1 1.6 1.7 2.8 1.7 1.1.0 2.1-.5 2.7-1.4.7-1 1-2.8 1-5.5.0-2.9-.3-4.8-.9-5.9C337.4 52.6 336.3 52 335.2 52.1v0z"
      />
      <path
        class="st0"
        d="M368.8 70.2h-6.5V57.9c0-1 0-2-.2-3.1-.1-.5-.4-1-.8-1.4-.5-.4-1.1-.6-1.7-.6-2.3.0-3.4 1.7-3.4 5v12.3h-6.5V48.3h6V52c.7-1.4 1.7-2.6 3-3.4 1-.5 2.1-.8 3.2-.8 1.8-.1 3.6.5 5 1.7 1.3 1.1 1.9 3.3 1.9 6.6V70.2z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M198.1 96.8v13.5l9-13.5h6.4l-6.9 10.2 8.6 18.4h-7.4l-5.7-12.5-4 5.9v6.6h-6.6V96.8H198.1z"
      />
      <path
        class="st0"
        d="M236.6 125.5h-6.1c-.2-1.1-.3-2.2-.3-3.3-.8 1.3-1.9 2.3-3.2 3-1.1.5-2.4.8-3.6.7-1.8.1-3.5-.5-4.8-1.6-1.2-1.1-1.9-2.7-1.8-4.4.0-1.3.4-2.6 1.1-3.7.8-1.2 2-2.1 3.4-2.6 1.6-.6 4.5-1.1 8.6-1.4V111c0-2.1-1-3.1-3.1-3.1-2.2.0-3.4 1-3.7 3.1l-5.8-.5c.2-2.2 1.5-4.1 3.4-5.1 2-1 4.2-1.6 6.5-1.5 1.4.0 2.7.2 4.1.5 1.1.2 2.1.8 3 1.5.7.6 1.2 1.3 1.5 2.1.3 1.3.4 2.6.4 3.9v9.6C236.2 122.9 236.3 124.2 236.6 125.5zM230 115.3c-4.4.4-6.6 1.8-6.6 4 0 .7.2 1.4.7 1.9.5.6 1.2.9 2 .8 1.1.0 2.1-.5 2.8-1.4.8-1 1.2-2.2 1.1-3.4V115.3z"
      />
      <path
        class="st0"
        d="M247.5 125.5h-6.1v-21.2h5.7v4c.9-2.9 2.4-4.4 4.6-4.4.3.0.7.0 1.1.1v6.2c-1-.1-2 .1-3 .6-.8.5-1.4 1.2-1.7 2.1-.5 1.3-.7 2.7-.7 4.1V125.5z"
      />
      <path class="st0" d="M256.4 125.5V96.8h6.3v28.7H256.4z" />
      <path
        class="st0"
        d="M283.7 109.4l-4.6 1.2c-.5-1.9-1.9-2.9-4.1-2.9-2.1.0-3.2.6-3.2 1.9.0.5.2.9.6 1.2.9.5 2 .7 3 .8 1.8.2 3.6.6 5.4 1.1 1.1.4 2.1 1.1 2.8 2.1.7 1.1 1.1 2.4 1 3.7.1 2.1-.9 4-2.5 5.3-2 1.5-4.5 2.2-7 2.1-5.4.0-8.6-2.1-9.7-6.3l5.3-.8c.4 1.9 2 2.9 4.7 2.9.9.0 1.8-.2 2.6-.6.6-.2 1-.8 1.1-1.5.0-1-.8-1.6-2.4-1.7-4.3-.5-7.1-1.3-8.5-2.5-1.4-1.1-2.1-2.9-2-4.7.0-1.9.8-3.8 2.4-5 1.9-1.4 4.2-2.1 6.6-2 1.9-.1 3.8.4 5.4 1.3C282 106.2 283.1 107.7 283.7 109.4z"
      />
      <path
        class="st0"
        d="M294.2 125.5h-6.1v-21.2h5.7v4c.9-2.9 2.4-4.4 4.6-4.4.3.0.7.0 1.1.1v6.2c-1-.1-2 .1-3 .6-.8.5-1.4 1.2-1.7 2.1-.5 1.3-.7 2.7-.7 4.1V125.5z"
      />
      <path
        class="st0"
        d="M321.2 104.3v21.2h-5.8v-3.6c-.9 2.6-3.5 4.3-6.3 4.1-1.3.1-2.6-.2-3.7-.9-.9-.6-1.5-1.5-1.8-2.4-.4-1.4-.6-3-.6-4.5v-13.9h6.1v13.9c0 1.9.8 2.9 2.4 2.9.8.0 1.5-.2 2.1-.7.5-.4.9-.9 1.1-1.5.2-.9.3-1.8.2-2.7v-11.9H321.2z"
      />
      <path
        class="st0"
        d="M332.5 96.8v10.3c1.1-2.2 3.3-3.5 5.7-3.3 1.3.0 2.6.3 3.7.9 1 .6 1.7 1.4 2.1 2.4.5 1.4.7 2.8.6 4.3v14h-6.3v-12c0-1 0-2-.2-2.9-.1-.5-.4-1-.8-1.3-.4-.4-.9-.6-1.5-.5-.9.0-1.7.4-2.3.9-.8.6-1.2 1.9-1.2 3.9v12h-6.1V96.8H332.5z"
      />
      <path
        class="st0"
        d="M368.9 115.9h-13.7c0 .4.0.7.0 1-.1 1.3.3 2.5 1.2 3.4.8.8 1.8 1.2 2.9 1.2 2.1.0 3.3-1.1 3.8-3.3l5.7.4c-1.6 4.9-4.8 7.4-9.6 7.4-1.9.1-3.7-.4-5.4-1.3-1.5-.9-2.8-2.2-3.7-3.7-1-1.8-1.5-3.8-1.4-5.8-.1-3 .9-5.9 2.9-8.2 3.9-4.1 10.4-4.2 14.4-.2.0.0.0.0.0.0C367.9 108.6 368.9 111.7 368.9 115.9zM355.2 112.3h7.5c-.2-2.9-1.4-4.4-3.6-4.4C356.8 107.9 355.5 109.4 355.2 112.3z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M74.4 181.5h-2.5V172h-9.3v9.5h-2.5v-20h2.5v8.3h9.3v-8.3h2.5v20z"
      />
      <path
        class="st0"
        d="M81.6 161.5v2.6h-2.5v-2.6h2.5zM81.5 166.7v14.8h-2.3v-14.8H81.5z"
      />
      <path
        class="st0"
        d="M98.2 164.4v1.8c-.7-.1-1.5.1-2.1.4-.4.3-.7.8-.7 1.3.9 1 1.4 2.2 1.4 3.6.0 1.3-.6 2.6-1.5 3.5-1.1 1-2.6 1.5-4.2 1.5-.3.0-.7.0-1-.1-.3-.1-.7-.1-1-.2-.3.0-.7.2-1 .4-.3.2-.6.6-.6 1 0 .5.3 1 .8 1.1 1.2.3 2.5.4 3.8.3 1.3.0 2.5.1 3.7.3.8.2 1.5.6 2 1.2.6.6.9 1.4.8 2.3.0 2.8-2.5 4.2-7.4 4.2-1.8.1-3.6-.2-5.1-1.1-1-.6-1.6-1.7-1.6-2.8.0-1.4.9-2.6 2.3-3-.9-.5-1.4-1.4-1.5-2.4.1-1.2.9-2.3 2.1-2.6-1.2-.9-1.9-2.4-1.9-3.9.0-1.4.6-2.7 1.6-3.6 1.1-.9 2.5-1.4 4-1.4 1 0 2.1.2 3 .6.0-.8.4-1.5 1-2 .7-.4 1.6-.6 2.4-.6C97.7 164.4 97.9 164.4 98.2 164.4zM88.4 180.9c-.9.3-1.6 1.1-1.6 2 0 .8.6 1.5 1.3 1.8 1.1.5 2.2.7 3.4.6 1.2.0 2.4-.2 3.5-.5 1.1-.4 1.6-1 1.6-1.8.0-.4-.2-.8-.5-1.1-.4-.3-.8-.5-1.3-.6-.5-.1-1.5-.1-2.8-.2C90.4 181.1 89.2 181 88.4 180.9zM91.2 174.6c.9.0 1.7-.3 2.4-.9.6-.6 1-1.5.9-2.3.1-1.8-1.3-3.3-3.1-3.3-.1.0-.1.0-.2.0-.9.0-1.7.3-2.3.9-1.3 1.3-1.4 3.4-.1 4.8C89.4 174.4 90.3 174.7 91.2 174.6v0z"
      />
      <path
        class="st0"
        d="M112.4 181.5h-2.3v-9c0-.8.0-1.6-.2-2.4-.1-.6-.5-1-.9-1.4-.5-.4-1.1-.6-1.7-.6-1.2.0-2.3.5-3 1.5-.8 1.1-1.3 2.5-1.2 3.9v8h-2.3v-20h2.3v7.9c1-2.1 2.5-3.1 4.5-3.1.8.0 1.6.2 2.3.5.6.3 1.2.8 1.6 1.3.3.5.6 1.1.7 1.6.2.9.2 1.8.2 2.7L112.4 181.5z"
      />
      <path
        class="st0"
        d="M123.8 179.9v1.8c-.7.1-1.4.2-2.1.2-2.9.0-4.3-1.5-4.3-4.5v-8.9h-2.6v-1.8h2.6l.1-3.7 2.1-.2v3.9h3.3v1.8h-3.3v9.3c0 1.5.8 2.3 2.4 2.3C122.6 180.1 123.2 180 123.8 179.9z"
      />
      <path
        class="st0"
        d="M135.5 177.1l2.1.4c-.4 1.3-1.2 2.4-2.3 3.1-1.2.8-2.6 1.3-4 1.2-1.9.1-3.7-.7-4.9-2.1-2.5-3.4-2.5-8 .1-11.3 1.2-1.4 3-2.2 4.9-2.2 1.8-.1 3.5.7 4.7 2 1.2 1.3 1.8 3.3 1.8 6h-10.8c0 2.2.5 3.7 1.4 4.5.8.7 1.9 1.2 3 1.2C133.4 180 135 178.8 135.5 177.1zM135.4 172.6c0-.8-.1-1.5-.4-2.2-.3-.7-.7-1.2-1.3-1.6-.6-.5-1.4-.7-2.2-.7-1.1.0-2.2.4-2.9 1.2-.8.9-1.3 2.1-1.3 3.3H135.4z"
      />
      <path
        class="st0"
        d="M150.1 176.3l2.1.4c-.2 1.5-.9 2.8-1.9 3.8-2.5 2.1-6.2 1.8-8.3-.7.0-.1-.1-.1-.1-.2-1.1-1.6-1.7-3.6-1.6-5.5-.1-2 .5-4 1.7-5.6 1.1-1.5 2.8-2.3 4.6-2.2 1.4-.1 2.8.5 3.9 1.4 1 1 1.6 2.3 1.8 3.7l-2.1.4c-.4-2.4-1.6-3.7-3.5-3.7-1.2.0-2.3.6-2.9 1.6-.7 1.3-1.1 2.7-1 4.2-.1 1.5.3 3.1 1 4.4.6 1 1.7 1.6 2.9 1.6C148.4 180 149.6 178.8 150.1 176.3z"
      />
      <path
        class="st0"
        d="M167 181.5h-2.3v-9c0-.8.0-1.6-.2-2.4-.1-.5-.5-1-.9-1.4-.5-.4-1.1-.6-1.7-.6-1.2.0-2.3.5-3 1.5-.8 1.1-1.3 2.5-1.2 3.9v8h-2.3v-20h2.3v7.9c1-2.1 2.5-3.1 4.5-3.1.8.0 1.6.2 2.3.5.6.3 1.2.8 1.6 1.3.3.5.6 1.1.7 1.6.2.9.2 1.8.2 2.7V181.5z"
      />
      <path
        class="st0"
        d="M185.8 179.9v1.8c-.7.1-1.4.2-2.1.2-2.9.0-4.3-1.5-4.3-4.5v-8.9h-2.6v-1.8h2.6l.1-3.7 2.1-.2v3.9h3.3v1.8h-3.3v9.3c0 1.5.8 2.3 2.4 2.3C184.6 180.1 185.2 180 185.8 179.9z"
      />
      <path
        class="st0"
        d="M190.6 181.5h-2.3v-14.8h2v3.5c.4-1.2 1.2-2.3 2.1-3.2.6-.5 1.4-.7 2.2-.7.3.0.6.0.9.1v2.2h-.5c-1.2.0-2.4.5-3.2 1.4-.9 1-1.4 2.4-1.3 3.7V181.5z"
      />
      <path
        class="st0"
        d="M200.5 161.5v2.6H198v-2.6h2.5zM200.5 166.7v14.8h-2.3v-14.8H200.5z"
      />
      <path
        class="st0"
        d="M211.2 166.7v1.8h-3.5v13h-2.3v-13h-2.5v-1.8h2.5c0-2.4.5-3.9 1.4-4.6.9-.7 2-1.1 3.2-1 .7.0 1.4.1 2.1.2v1.8c-.5-.1-.9-.2-1.4-.2-.8-.1-1.6.2-2.2.7-.6.7-.8 1.6-.7 2.5v.6H211.2z"
      />
      <path
        class="st0"
        d="M221 166.7v1.8h-3.5v13h-2.3v-13h-2.5v-1.8h2.5c0-2.4.5-3.9 1.4-4.6.9-.7 2-1.1 3.2-1 .7.0 1.4.1 2.1.2v1.8c-.5-.1-.9-.2-1.4-.2-.8-.1-1.6.2-2.2.7-.6.7-.8 1.6-.7 2.5v.6H221z"
      />
      <path
        class="st0"
        d="M231.4 179.9v1.8c-.7.1-1.4.2-2.1.2-2.9.0-4.3-1.5-4.3-4.5v-8.9h-2.6v-1.8h2.6l.1-3.7 2.1-.2v3.9h3.3v1.8h-3.3v9.3c0 1.5.8 2.3 2.4 2.3C230.2 180.1 230.8 180 231.4 179.9z"
      />
      <path class="st0" d="M253.4 179.3v2.2h-11.8v-20h2.6v17.8H253.4z" />
      <path
        class="st0"
        d="M265.8 177.1l2.1.4c-.4 1.3-1.2 2.4-2.3 3.1-1.2.8-2.6 1.3-4 1.2-1.9.1-3.7-.7-4.9-2.1-2.5-3.4-2.5-8 .1-11.3 1.2-1.4 3-2.2 4.9-2.2 1.8-.1 3.5.7 4.7 2 1.2 1.3 1.8 3.3 1.8 6h-10.8c0 2.2.5 3.7 1.4 4.5.8.7 1.9 1.2 3 1.2C263.7 180 265.3 178.8 265.8 177.1zM265.7 172.6c0-.8-.1-1.5-.4-2.2-.3-.7-.7-1.2-1.3-1.6-.6-.5-1.4-.7-2.2-.7-1.1.0-2.2.4-2.9 1.2-.8.9-1.3 2.1-1.3 3.3H265.7z"
      />
      <path
        class="st0"
        d="M272.8 181.5h-1.1v-20h2.3v7.7c.8-1.7 2.5-2.9 4.4-2.9 1.7.0 3.2.7 4.3 2 1.3 1.7 1.9 3.7 1.7 5.8.1 2-.5 3.9-1.7 5.5-1 1.4-2.7 2.3-4.4 2.2-2 0-3.8-1.1-4.6-2.9L272.8 181.5zM274 175.7c0 1.2.5 2.4 1.4 3.3.7.6 1.7 1 2.6 1 1.1.0 2.2-.6 2.8-1.5.8-1.2 1.2-2.7 1.1-4.2.1-1.6-.3-3.1-1-4.5-.6-1-1.7-1.7-2.9-1.6-1 0-1.9.4-2.6 1.2-.8.8-1.3 1.9-1.4 3V175.7z"
      />
      <path
        class="st0"
        d="M297.6 177.1l2.1.4c-.4 1.3-1.2 2.4-2.3 3.1-1.2.8-2.6 1.3-4 1.2-1.9.1-3.7-.7-4.9-2.1-2.5-3.4-2.5-8 .1-11.3 1.2-1.4 3-2.2 4.9-2.2 1.8-.1 3.5.7 4.7 2 1.2 1.3 1.8 3.3 1.8 6h-10.8c0 2.2.5 3.7 1.4 4.5.8.7 1.9 1.2 3 1.2C295.4 180 297.1 178.8 297.6 177.1zM297.4 172.6c0-.8-.1-1.5-.4-2.2-.3-.7-.7-1.2-1.3-1.6-.6-.5-1.4-.7-2.2-.7-1.1.0-2.2.4-2.9 1.2-.8.9-1.3 2.1-1.3 3.3H297.4z"
      />
      <path
        class="st0"
        d="M315.1 181.5h-2.2V172c.1-1-.2-2.1-.9-2.9-.6-.6-1.4-.9-2.2-.8-1.1.0-2.2.5-3 1.4-.8 1-1.3 2.4-1.2 3.7v8.1h-2.3v-14.8h2.1v2.7c1.2-2.1 2.8-3.1 4.7-3.1 1 0 2 .3 2.8.9.7.5 1.3 1.1 1.6 1.9.4 1.3.5 2.6.4 3.9L315.1 181.5z"
      />
      <path
        class="st0"
        d="M317.7 177.7l2.2-.5c.5 1.9 2 2.8 4.6 2.8.9.0 1.7-.2 2.4-.6.6-.4 1-1.1 1-1.8.0-1.2-.8-1.9-2.5-2.2l-3.1-.6c-1.1-.2-2.1-.6-2.9-1.4-.7-.7-1.1-1.7-1.1-2.7.0-1.2.5-2.4 1.5-3.1 1.1-.9 2.5-1.3 4-1.3 3.2.0 5.2 1.3 5.9 3.8l-2.1.5c-.6-1.5-2.1-2.5-3.7-2.3-.8.0-1.6.2-2.3.6-.6.3-.9 1-.9 1.6.0 1.1.8 1.8 2.3 2.1l2.9.5c3 .5 4.5 2 4.5 4.4.0 1.3-.6 2.5-1.7 3.3-1.3.9-2.9 1.3-4.5 1.3C320.7 181.9 318.5 180.5 317.7 177.7z"
      />
      <path
        class="st0"
        d="M345.2 181.5h-2.3c-.1-.5-.2-1.4-.3-2.7-.9 2-3 3.2-5.2 3.1-1.4.1-2.8-.4-3.8-1.3-.9-.8-1.3-2-1.3-3.2.0-1.5.7-2.9 1.9-3.7 1.3-1 3.4-1.4 6.5-1.4.4.0 1.1.0 2 .1.0-.9-.1-1.7-.3-2.5-.2-.6-.6-1-1.1-1.3-.7-.3-1.5-.5-2.3-.5-2.3.0-3.7.9-4.1 2.7l-2.1-.4c.5-2.7 2.7-4.1 6.5-4.1 2 0 3.5.5 4.3 1.4.9.9 1.3 2.5 1.3 4.7v6.3C344.8 179.6 345 180.5 345.2 181.5zM342.6 174.1c-.9-.1-1.7-.1-2.1-.1-1.5-.1-2.9.2-4.2.9-1 .5-1.5 1.5-1.5 2.6.0.7.3 1.4.8 1.9.7.5 1.5.8 2.4.8 1.3.0 2.6-.5 3.4-1.5.9-1 1.4-2.2 1.3-3.6C342.7 174.8 342.7 174.5 342.6 174.1z"
      />
      <path
        class="st0"
        d="M351.8 181.5h-2.3v-14.8h2v3.5c.4-1.2 1.2-2.3 2.1-3.2.6-.5 1.4-.7 2.2-.7.3.0.6.0.9.1v2.2h-.5c-1.2.0-2.4.5-3.2 1.4-.9 1-1.4 2.4-1.3 3.7V181.5z"
      />
      <path
        class="st0"
        d="M367 179.9v1.8c-.7.1-1.4.2-2.1.2-2.9.0-4.3-1.5-4.3-4.5v-8.9H358v-1.8h2.6l.1-3.7 2.1-.2v3.9h3.3v1.8h-3.3v9.3c0 1.5.8 2.3 2.4 2.3C365.9 180.1 366.5 180 367 179.9z"
      />
    </g>
    <g>
      <linearGradient
        id="SVGID_1_logo_trk"
        gradientUnits="userSpaceOnUse"
        x1="495.4524"
        y1="650.6191"
        x2="612.7143"
        y2="767.8809"
        gradientTransform="matrix(1 0 0 1 -39.4378 -626.3333)"
      >
        <stop offset="0" style="stop-color:#3e3e3e" />
        <stop offset="1" style="stop-color:#a3a3a3" />
      </linearGradient>
      <circle class="st1" cx="514.6" cy="82.9" r="82.9" />
      <circle class="st2" cx="514.6" cy="82.9" r="69.8" />
      <path
        d="M562.4 126.9c-6.3 7.3-14.2 13.1-23.1 16.9-9 3.8-18.7 5.5-28.4 4.9-4.8-.3-9.6-1.1-14.3-2.5-4.6-1.4-9.1-3.2-13.3-5.6-8.4-4.7-15.6-11.4-21-19.4 5.9 7.4 13.3 13.6 21.8 18 16.9 8.9 36.9 10 54.7 3.1C547.6 138.9 555.6 133.6 562.4 126.9z"
      />
      <path
        d="M462.6 44.2c5.6-8.1 13-14.8 21.7-19.5 4.4-2.4 9-4.2 13.8-5.5 9.6-2.5 19.7-2.8 29.4-.8 4.8 1 9.6 2.6 14 4.7 9 4.2 16.8 10.4 22.8 18.2-6.5-7.2-14.5-12.9-23.5-16.8-18-7.7-38.4-7.1-55.9 1.6C476.3 30.5 468.7 36.6 462.6 44.2z"
      />
      <g>
        <path
          class="st3"
          d="M456.4 87.3c-.5-6.8-.3-13.6.3-20.4-4.4-.6-7.4-.9-7.4-.9-.6 7.3-.7 14.7-.3 22.1L456.4 87.3z"
        />
        <path
          class="st3"
          d="M458.7 67.2c-.6 6.6-.7 13.3-.3 19.9l5.7-.6c-.4-6.2.0-12.4 1.2-18.5C463 67.8 460.7 67.5 458.7 67.2z"
        />
        <path
          class="st3"
          d="M456.7 90.3l-7.4.8c.3 4.7 1.2 9.3 2.6 13.7 2.8-.9 5.3-1.7 7.3-2.3C458 98.5 457.1 94.5 456.7 90.3z"
        />
        <path
          class="st3"
          d="M458.7 90.1c.4 4 1.2 7.9 2.4 11.8 3-1 4.8-1.5 4.8-1.5-.7-3.6-1.2-7.2-1.5-10.9L458.7 90.1z"
        />
      </g>
    </g>
    <path
      class="st0"
      d="M501.1 90.7V126h-21.4V36.8h39.9c7.4.0 13.3.8 17.7 2.5 4.6 1.8 8.5 5 11.1 9.2 2.9 4.4 4.4 9.6 4.4 15 0 10.7-5.3 18.4-15.8 23.1l16.8 39.4h-23.5l-13.9-35.4H501.1zm0-38v22.7h13c3.1.1 6.2-.2 9.3-.9 2.1-.7 3.9-2.1 5.2-3.9 1.5-2 2.3-4.5 2.3-7 0-2.4-.8-4.8-2.3-6.7-1.2-1.7-2.9-3-4.9-3.6-3.3-.6-6.6-.8-10-.7L501.1 52.7z"
    />
  </svg>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.st0 {
  fill: #aaa;
}
.st1 {
  fill: url(#SVGID_1_logo_trk);
}
.st2 {
  fill: $color-grey-dark;
}
.st3 {
  fill: #000;
}
</style>
