<template>
  <div
    class="message"
    :class="{
      'message--error': data.type === 'error',
      'message--shown': messageIsShown,
      'message--no-transition': transitionIsSuspended
    }"
  >
    <div class="message__icon">
      <img
        src="@/assets/Internal/Course/Message/bulb.svg"
        alt="A bulb"
        v-if="data.type === 'error'"
        class="message__icon-image"
      />
      <img
        src="@/assets/Internal/Course/Message/information.svg"
        alt="An information sign"
        v-if="data.type !== 'error'"
        class="message__icon-image"
      />
    </div>
    <p class="message__text">
      <app-glossary-link
        :text="t(data.message)"
        textNumber="0"
      ></app-glossary-link>
    </p>
    <img
      v-if="data.image"
      :src="
        'http://mathopia3.s3-website.eu-central-1.amazonaws.com/' + data.image
      "
      alt="Image of the message"
      class="message__image"
    />
    <div
      @touchstart="
        messageIsShown = false;
        $emit('messageClosed', false);
      "
      class="message__x-button-container"
    >
      <app-button-x
        @click="
          messageIsShown = false;
          $emit('messageClosed', false);
        "
        :xColor="data.type === 'error' ? 'black' : 'white'"
        class="message__x-button"
      ></app-button-x>
    </div>
  </div>
</template>

<script>
import AppGlossaryLink from "@/components/Molecules/GlossaryLink.vue";
import AppButtonX from "@/components/Atoms/ButtonX.vue";

export default {
  name: "appMessage",
  props: {
    data: Object,
    isShown: Boolean,
    transitionIsSuspended: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      messageIsShown: false
    };
  },
  components: {
    AppGlossaryLink,
    AppButtonX
  },
  watch: {
    data() {
      this.typesetMath();
    },
    isShown(to) {
      setTimeout(() => {
        window.MathJax.typeset();
      }, 0);
      if (to) {
        this.messageIsShown = true;
      } else {
        this.messageIsShown = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.message {
  position: relative;
  transform: translateY(4.5rem);
  padding: 2rem;
  border-radius: 1rem;
  background-color: $color-primary-dark;
  // background-color: #1876b8;
  // background-color: #125a8c;
  // background-color: #131313;
  // background-color: #1a1a1a;
  // background-color: #000;
  // background-color: $color-secondary;
  // background-color: darkgreen;
  // color: $color-black;
  visibility: hidden;
  // opacity: 0;
  pointer-events: none;

  display: grid;
  // grid-template-columns: 7.5rem 1fr max-content 3.5rem;
  grid-template-columns: 7.8rem 1fr max-content 5rem; // last value was 11rem

  transition: transform 0.3s ease-out, opacity 0.3s, visibility 0s 0.3s;

  @include respond(phone) {
    grid-template-columns: 5.55rem 1fr max-content 3.8rem;
    transform: translateY(calc(100% + 1.8rem));
    border-radius: 0.6rem;
    background-color: $color-primary-dark;
    opacity: 1;

    transition: transform 0.4s ease-in, visibility 0s 0.4s;
  }

  &--error {
    color: $color-black;
    background-color: $color-wrong;
  }

  &--shown {
    transform: none;
    visibility: visible;
    opacity: 1;
    pointer-events: initial;

    transition: transform 0.3s ease-out, opacity 0.3s, visibility 0s;

    @include respond(phone) {
      transition: transform 0.4s ease-out, visibility 0s;
    }
  }

  &--no-transition {
    transition: none;
  }

  &__icon {
    position: relative;
    width: 4rem;
    height: 4rem;
    margin-top: 0.2rem;
    border-radius: 50%;
    font-family: $font-primary-bold;
    font-size: 3rem;
    font-weight: 900;
    line-height: 4rem;
    text-align: center;
    background-color: $color-white;
    color: $color-black;

    // transform: scale(0.9);
    // opacity: 0;

    @include respond(phone) {
      transform: translateX(-0.35rem);
    }
  }

  &--error &__icon {
    // background-color: #333;
    margin-top: 0;
    background-color: transparent;

    /* @include respond(phone) {
      background-color: transparent;
    } */
  }

  &__icon-image {
    margin-left: -0.15rem;
    margin-top: 0.85rem;
    width: 2.3rem; // was: 2.3rem
    height: 2.3rem; // was: 2.3rem

    @include respond(phone) {
      margin-left: 0;
    }
  }

  &--error &__icon-image {
    margin-left: -0.25rem;
    margin-top: 0;
    width: 5.45rem;
    height: 5.45rem;
    transform: translate(-0.5rem, -0.5rem) scaleX(-1);
  }

  &__text {
    margin: -0.5rem 5.5rem 0 0;
    // margin: -0.5rem 5.5rem -0.5rem 0;
    font-size: 2.2rem;
    white-space: pre-line;

    // background-color: red;

    @include respond(tab-land) {
      font-size: 2.4rem;
    }

    @include respond(phone) {
      margin: -0.5rem 0 0 0;
      font-size: 2.6rem;
      line-height: 1.3;
    }
  }

  &__image {
    width: 17.5rem;
    border-radius: 0.6rem;
  }

  &__x-button-container {
    position: relative;
    grid-column: 4 / 5;
    margin-left: 2rem;
    justify-self: end;

    transform: translate(1.25rem, -1.25rem);
    width: 5rem;
    height: 5rem;
    // background-color: yellow;
  }

  &__x-button {
    position: absolute;
    // grid-column: 4 / 5;
    // transform: translate(1.25rem, 1.25rem);
    margin: 1.25rem 0 0 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    //  margin-left: 2rem;
    // justify-self: end;
  }
}
</style>
