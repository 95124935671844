<template>
  <div class="progress-round" :class="{ 'progress-round__inactive': inactive }">
    <div class="progress-round__progress-container">
      <div
        class="progress-round__progress"
        :style="'transform: rotate(' + Math.min(3.6 * percentage, 180) + 'deg)'"
      ></div>
    </div>
    <div
      class="progress-round__progress-container progress-round__progress-container--left"
    >
      <div
        class="progress-round__progress progress-round__progress--left"
        :style="
          'transform: rotate(' + Math.max(3.6 * percentage - 180, 0) + 'deg)'
        "
      ></div>
    </div>
    <div
      class="progress-round__text"
      :class="{ 'progress-round__text--white': percentage > 0 }"
    >
      {{ percentage }} <span class="progress-round__percent">%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "progressRound",
  props: {
    percentage: Number,
    inactive: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.progress-round {
  width: 5rem; // was: 7.5rem
  height: 5rem; // was: 7.5rem
  border-radius: 50%;
  background-color: $color-black;

  &::after {
    @include pseudo-element;
    left: 1.05rem;
    top: 1.05rem;
    width: calc(100% - 2.1rem);
    height: calc(100% - 2.1rem);
    border-radius: 50%;
    background: radial-gradient(#262626 1.2rem, $color-black 1.2rem);
  }

  &__inactive::after {
    // background: radial-gradient(#1a1a1a 1.2rem, $color-black 1.2rem);
  }

  &::before {
    @include pseudo-element;

    left: -4.05rem;
    top: -2.1rem;
    z-index: -1;
    width: 13.1rem;
    height: 9.3rem;
    border-radius: 0.6rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: radial-gradient(circle, #000 2.8rem, #181818 2.8rem);
  }

  &__inactive::before {
    // background: radial-gradient(circle, #000 2.8rem, #181818 2.8rem);
  }

  &__progress-container {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;

    overflow: hidden;

    &--left {
      left: 0;
      border-radius: 0;
      border-top-left-radius: 10rem;
      border-bottom-left-radius: 10rem;
    }
  }

  &__progress {
    position: absolute;
    left: -200%;
    width: 200%;
    height: 100%;
    background-color: $color-primary;

    transform-origin: right;
    transition: background-color 0.2s;

    .progress-round__inactive & {
      background-color: #333; // was: #343434
    }

    &--left {
      left: 100%;
      transform-origin: left;
    }
  }

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 10;
    padding-left: 0.05rem;
    transform: translate(-50%, -50%);
    width: max-content;
    font-size: 1.8rem;

    color: #aaa;
    color: transparent;
  }

  &__percent {
    display: inline-block;
    transform: translateX(-0.1rem);
  }
}
</style>
