<template>
  <div class="modal tabstart" tabindex="0">
    <div class="modal__top-container">
      <h2 class="modal__heading">
        {{ $t("course_unlock_modal").heading }}
      </h2>
      <app-lottie
        v-if="
          $store.state.shownModal2 === 'none' || $store.state.modalNumber === 1
        "
        :lottie="'lock'"
        class="modal__animation"
      ></app-lottie>
    </div>
    <div class="modal__text-container">
      {{ $t("course_unlock_modal").paragraph }}
      <div class="modal__button-container">
        <app-button
          @mousedown="startCourse()"
          :text="$t('course_unlock_modal').button"
          class="modal__button"
        ></app-button>
      </div>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButton,
    AppButtonXRound,
    AppLottie
  },
  methods: {
    startCourse() {
      if (
        !(this.$store.state.chosenLesson in this.$store.state.userDataLectures)
      ) {
        this.$store.state.userDataLectures[this.$store.state.chosenLesson] = {};
      }
      this.$store.state.userDataLectures[this.$store.state.chosenLesson][
        "isUnlocked"
      ] = true;

      // this.playAudio("unlock");

      if (this.$store.state.coursePageRequestedViaTOC > 0) {
        this.setPage(this.$store.state.coursePageRequestedViaTOC);
        if (this.$store.state.windowWidth < this.remToPx(189.1)) {
          this.$store.state.TOCisOpen = false;
        }
      } else {
        this.setPage("++");
      }
      this.toggleModal();

      this.$store.state.userWasAskedAboutSound = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.modal {
  width: 46rem;
  border-radius: 1rem;
  font-family: $font-primary;
  color: $color-white;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__top-container {
    height: 35.85rem;
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3.3rem;
    }
  }

  &__animation {
    margin: 4.25rem 0 8.5rem;
    transform: translate(calc(23rem - 50%), 2rem);
    width: 20.5rem;
    margin-top: 2.6rem;
    margin-bottom: 4.5rem;

    @include respond(tab-port) {
      transform: translate(calc(25rem - 50%), 1.825rem);
    }

    @include respond(phone) {
      transform: translate(calc(min(207px, 50vw - 0.75rem) - 50%), -1rem);
      margin: 4rem 0 4.5rem;
      width: 25.05rem;
      height: 25.05rem;
    }
  }

  &__text-container {
    padding: 2.75rem 2.75rem 1.375rem;
    font-size: 2rem;
    background-color: #151515;

    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @include respond(tab-port) {
      padding: 1.915rem 2.5rem 2.5rem 2.5rem;
      font-size: 2.4rem;
      line-height: 1.45;
    }

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__button-container {
    transform: translateX(calc(46rem - 5.5rem - 100%));
    margin-top: 2.75rem;
    width: max-content;

    @include respond(tab-port) {
      transform: translateX(calc(50rem - 5rem - 100%));
    }

    @include respond(phone) {
      margin-top: 3.5rem;
      transform: translateX(calc(min(414px, 100vw) - 1.5rem - 5rem - 100%));
    }
  }

  &__button {
    text-align: center;
    padding: 0.9rem 2rem;
    background-color: #333;

    @include respond(phone) {
      font-size: 2.6rem;
    }

    &:hover {
      background-color: #444;
    }
  }
}
</style>
