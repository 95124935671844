<template>
  <div
    @touchstart="touchIsStarted($event)"
    @mousemove="progressArrow($event)"
    @touchmove="progressArrow($event)"
    @mouseup="finishArrow($event)"
    @touchend="finishArrow($event)"
    class="picture"
    :class="{
      'picture--part-1': partOfExercise === 1,
      'picture--part-2': partOfExercise === 2,
      'picture--finished': exerciseIsFinished,
      'picture--scroll-released': scrollIsReleased,
      'picture--no-transition': transitionIsSuspended,
      'picture--arrows-type': exerciseType === 'arrows',
      'picture--small-picture-is-hidden': content.noSmallPicture,
      'picture--continue-button-is-shown': continueButtonIsShown
    }"
  >
    <h2
      class="picture__heading"
      :class="{ picture__invisible: partOfExercise > 1 }"
    >
      {{ t(content.heading) }}
    </h2>
    <p
      class="picture__instructions"
      :class="{ picture__invisible: partOfExercise > 1 }"
    >
      <app-glossary-link
        :text="content.instructions"
        textNumber="0"
      ></app-glossary-link>

      &nbsp;<span class="picture__handling-desktop">
        {{ t(content.handling) }}
      </span>
      <span class="picture__handling-phone">
        {{ t(content.handlingPhone) }}
      </span>
    </p>
    <div
      class="picture__picture-container"
      :class="{
        picture__correct: answerIsCorrect,
        picture__wrong: answerIsWrong,
        'picture__picture-container--2': partOfExercise > 1
      }"
      @mousemove="progressArrow($event)"
      @touchmove="progressArrow($event)"
    >
      <figcaption
        v-if="content.subsubtype !== 'diagram' && !content.noSmallPicture"
        class="picture__figure-caption"
      >
        {{ t(textLeftSide) }}
      </figcaption>
      <figcaption
        v-show="!content.noSmallPicture"
        class="picture__figure-caption picture__figure-caption--2"
        :class="{ 'picture__figure-caption--at-top': pictureIsSmall }"
      >
        {{ t(textRightSide) }}
      </figcaption>

      <div
        v-if="content.subsubtype !== 'diagram'"
        class="picture__picture"
        :class="{ 'picture__picture--is-small': pictureIsSmall }"
      >
        <svg
          :width="svgSize[0]"
          :height="svgSize[1]"
          :viewBox="'0 0 ' + svgSize[0] + ' ' + svgSize[1]"
          style="display: block"
          :ref="'leftPicture'"
          v-html="getSVGData"
        ></svg>
        <div class="picture__picture-text-container">
          <span
            v-for="(textBlock, index) in content.textPicture"
            :key="textBlock.text + 'andIndex' + index"
            :style="
              'color:' +
                getColor(textBlock.color) +
                '; font-size:' +
                getFontSize(textBlock.fontSize) +
                'px; transform: translate(' +
                textBlock.position[0] +
                'px,' +
                textBlock.position[1] +
                'px) rotate(' +
                getRotation(textBlock.rotation) +
                'deg)'
            "
            class="picture__picture-text"
            :class="{ 'fade-out': textBlock.fadeOut }"
            >{{ t(textBlock.text) }}</span
          >
        </div>
      </div>

      <template v-for="(item, index) in draggables" :key="item">
        <div
          class="picture__picture picture__picture-draggable"
          :class="{
            'picture__picture-draggable--invisible': draggableIsInvisible,
            'picture__on-left-side': droppedElements.indexOf(index) === -1,
            'picture__on-right-side': droppedElements.indexOf(index) > -1
          }"
          :ref="'picture--' + index"
        >
          <svg
            :width="svgSize[0]"
            :height="svgSize[1]"
            :viewBox="'0 0 ' + svgSize[0] + ' ' + svgSize[1]"
            :style="['display: block; transform: ' + transformDraggable]"
            @mousedown="startDrag($event, index)"
            @touchstart="startDrag($event, index)"
            @mousemove="progressArrow($event)"
            @touchmove="progressArrow($event)"
            @mouseup="stopDrag($event)"
            @touchend="stopDrag($event)"
            :class="['draggable-element--' + index]"
          >
            <g v-html="item" :class="['draggable-inner-element--' + index]"></g>
          </svg>
          <div
            v-if="typeof textIndexForDraggable[index] === 'number'"
            :style="[
              'z-index: 100; left: calc(50% + 25.6rem + ' +
                textDraggables[textIndexForDraggable[index]].position[0] +
                'px); top: calc(50% + ' +
                textDraggables[textIndexForDraggable[index]].position[1] +
                'px); font-size:' +
                textDraggables[textIndexForDraggable[index]].fontSize +
                'px; color:' +
                textDraggables[textIndexForDraggable[index]].color
            ]"
            class="picture__picture picture__picture--text picture__picture-draggable"
            :class="[
              {
                'picture__on-left-side': droppedElements.indexOf(index) === -1
              },
              'draggable--' + index
            ]"
          >
            <span
              @mousedown="startDrag($event, index)"
              @touchstart="startDrag($event, index)"
              @mousemove="progressArrow($event)"
              @touchmove="progressArrow($event)"
              @mouseup="stopDrag($event)"
              @touchend="stopDrag($event)"
              :style="[
                'display: inline-block; transform: rotate(' +
                  getRotation(
                    textDraggables[textIndexForDraggable[index]].rotation
                  ) +
                  'deg)'
              ]"
              :class="[
                {
                  'picture__draggable-text-span--dark-fill':
                    textDraggables[textIndexForDraggable[index]].darkFill &&
                    answerIsWrong &&
                    droppedElements.indexOf(index) > -1
                },
                'picture__draggable-text-span--' + index
              ]"
              >{{ t(textDraggables[textIndexForDraggable[index]].text) }}</span
            >
          </div>
        </div>
      </template>

      <svg
        @mousedown="startArrow($event)"
        @touchstart="startArrow($event)"
        @mousemove="progressArrow($event)"
        @touchmove="progressArrow($event)"
        :width="svgSize[0]"
        :height="svgSize[1]"
        :viewBox="'0 0 ' + svgSize[0] + ' ' + svgSize[1]"
        :style="['transform:' + transformAdditional[1]]"
        class="picture__picture picture__picture-draggable picture__additional-element"
        :class="{
          'picture__additional-element--visible': additionalElementIsVisible
        }"
        :ref="'additional--1'"
        v-html="additionalElements"
      ></svg>
      <span
        v-for="(textBlock, index) in textAdditional"
        :key="textBlock.text + 'andIndex' + index"
        :style="
          'color:' +
            getColor(textBlock.color) +
            '; font-size:' +
            getFontSize(textBlock.fontSize) +
            'px; transform: translate(calc(' +
            transformValueTextX +
            ' + ' +
            scaleOfSolution * textBlock.position[0] +
            'px), calc(' +
            transformValueTextY +
            ' + ' +
            scaleOfSolution * textBlock.position[1] +
            'px)) scale(' +
            scaleOfSolution +
            ')'
        "
        class="picture__picture picture__picture-draggable picture__additional-element picture__additional-text"
        :class="{
          'picture__additional-element--visible': additionalElementIsVisible
        }"
        >{{ t(textBlock.text) }}</span
      >

      <template v-for="(arrow, index) in arrowData" :key="arrow">
        <div
          class="picture__arrow-container"
          :class="'picture__arrow-container--' + index"
        >
          <div
            class="picture__arrow-inner-container"
            :class="{
              'picture__arrow-inner-container--wrong':
                wrongArrowIsIndicated && arrow === arrowData[activatedPicture],
              'picture__arrow-container--dark':
                arrowDrawingStarted && deactivatedPictures.indexOf(index) > -1
            }"
          >
            <div
              :style="'background-color:' + getArrowColor(arrow)"
              class="picture__arrow"
              :class="'picture__arrow--' + index"
            >
              <svg
                v-show="content.subsubtype !== 'diagram'"
                width="9.51074"
                height="8.54754"
                viewBox="0 0 9.51074 8.54754"
                class="picture__arrow-tip"
                :class="'picture__arrow-tip--' + index"
              >
                <g>
                  <!-- <path d="M4.75555,0V0" fill="#fff" /> -->
                  <path
                    d="M4.75555,0A30.222,30.222,0,0,1,0,8.54754L4.7558,6.82715l4.75494,1.72A30.19626,30.19626,0,0,1,4.75555,0Z"
                    :fill="getArrowColor(arrow)"
                  />
                </g>
              </svg>
              <!-- <svg
                v-show="content.subsubtype !== 'diagram'"
                width="10"
                height="10"
                class="picture__arrow-tip"
                :class="'picture__arrow-tip--' + index"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  stroke="black"
                  stroke-width="2"
                  fill="blue"
                />
              </svg> -->
            </div>
          </div>
        </div>
        <figcaption
          :style="
            'color:' +
              getArrowCaptionColor(arrow.caption) +
              '; font-size:' +
              getArrowCaptionFontSize(arrow.caption) +
              'px;'
          "
          class="picture__arrow-caption"
          :class="[
            'picture__arrow-caption--' + index,
            {
              'picture__arrow-caption--dark':
                arrowDrawingStarted && deactivatedPictures.indexOf(index) > -1
            }
          ]"
        >
          {{ t(getArrowCaptionText(arrow.caption)) }}
        </figcaption>
      </template>
    </div>

    <div
      class="picture__selection-container"
      :class="{
        'picture__selection-container--shown':
          pictureSelectionIsShown &&
          !arrowPartIsFinished &&
          !continueButtonIsShown
      }"
    >
      <app-course-button
        :text="
          activatedPicture === -1
            ? t(content.selectionButtonText)
            : t(content.changeButtonText)
        "
        v-show="!arrowPartIsFinished && !continueButtonIsShown"
        @click="showPictureSelection()"
        @touchstart="showPictureSelection()"
        :active="true"
        class="picture__show-selection-button"
      />
      <app-picture-choice
        v-for="(picture, index) in arrowData"
        :key="picture"
        @click="choosePicture(picture, index)"
        @touchstart="choosePicture(picture, index)"
        :caption="picture.name"
        :icon="picture.icon"
        :iconText="picture.iconText"
        :activated="activatedPicture === index"
        :deactivated="activatedPicture !== -1 && activatedPicture !== index"
        :inactive="deactivatedPictures.indexOf(index) > -1"
        :wrong="wrongPicture === index"
      ></app-picture-choice>
    </div>

    <div class="picture__hint-button-container">
      <div class="picture__hint-button-inner-container">
        <app-course-button
          @click="showHint()"
          @touchstart="showHint()"
          v-show="
            activatedPicture !== -1 && 'hint' in arrowData[activatedPicture]
          "
          :text="$t('course_arrows').show_hint"
          variation="grey"
          :active="true"
          class="picture__hint-button"
        />
        <app-course-button
          @click="solveCurrentArrow()"
          @touchstart="solveCurrentArrow()"
          v-show="activatedPicture !== -1"
          :text="$t('course_arrows').show_solution"
          variation="grey"
          :active="true"
          class="picture__hint-button"
        />
      </div>
    </div>

    <app-course-button
      v-show="partOfExercise === 1"
      :text="$t('course_picture').start_button"
      @click="
        partOfExercise++;
        setTimeout(() => {
          showPictureSelection();
        }, 200);
      "
      @touchstart="
        partOfExercise++;
        setTimeout(() => {
          showPictureSelection();
        }, 200);
      "
      :active="true"
      class="picture__start-button"
    />
    <app-course-button
      v-show="partOfExercise === 2"
      :hidden="partOfExercise === 1 || exerciseIsFinished"
      :text="$t('course_order').evaluate_button"
      @click="evaluateAnswer()"
      @touchstart="evaluateAnswer()"
      variation="secondary"
      :active="droppedElements.length > 0 && evaluateButtonIsActive"
      class="picture__evaluate-button"
      :class="{
        'picture__evaluate-button--hidden':
          partOfExercise === 1 || exerciseIsFinished
      }"
    />

    <div
      class="picture__message-container"
      :class="{
        'picture__message-container--is-shown': messageIsShown,
        'picture__message-container--mistake': messageType === 'mistake',
        'picture__message-container--with-selection-container': pictureSelectionIsShown,
        'picture__message-container--with-continue-button': continueButtonIsShown
      }"
    >
      <app-message
        :data="messageData"
        :isShown="messageIsShown"
        :transitionIsSuspended="transitionIsSuspended"
        @messageClosed="messageIsShown = false"
        class="picture__message"
      ></app-message>
    </div>

    <app-course-button
      :text="$t('course_continue_button')"
      @click="setPage('++')"
      @touchstart="setPage('++')"
      :active="true"
      class="picture__evaluate-button picture__continue-button"
      :class="{
        'picture__continue-button--shown': continueButtonIsShown
      }"
    />
  </div>
</template>

<script>
import AppGlossaryLink from "@/components/Molecules/GlossaryLink.vue";
import AppCourseButton from "@/components/Atoms/CourseButton.vue";
// import AppButton from "@/components/Atoms/Button.vue";
import AppMessage from "@/components/Molecules/Message.vue";

import AppPictureChoice from "@/components/Molecules/PictureChoice.vue";

export default {
  components: {
    AppGlossaryLink,
    AppCourseButton,
    // AppButton,
    AppMessage,
    AppPictureChoice
  },
  props: {
    content: Object,
    exerciseIsReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      correctAnswer: [],
      feedbackMissingElements: {},
      feedbackAdditionalElements: {},
      textLeftSide: "",
      textRightSide: "",

      rightShifts: {},
      topShifts: {},
      scaleOfSolution: 1.5,

      svgSize: [200, 200],
      coordinates: [], // left - right - top - bottom
      scaleFactor: 1,
      scaleFactor2: 1,

      exerciseType: "picture",

      draggables: [],
      draggableIsInvisible: true,
      elementIsGrabbed: false,
      draggedElementNumber: 0,
      xPositionDragStart: 0,
      yPositionDragStart: 0,
      positions: {},
      translationX: 0,
      translationY: 0,
      droppedElements: [],
      preventStopDrag: false,
      pictureIsSmall: false,
      exerciseIsFinished: false,
      evaluateButtonIsActive: true,
      continueButtonIsShown: false,
      transitionIsSuspended: false,
      answerIsCorrect: false,
      answerIsWrong: false,
      textDraggables: [],
      transformDraggable: [],
      transformAdditional: [],
      // textAdditional: [],
      transformValueTextX: "0px",
      transformValueTextY: "0px",
      additionalElementIsVisible: false,
      partOfExercise: 1,
      scrollIsReleased: false,
      scrollIsProhibited: false,

      // Variables for picture choice
      activatedPicture: -1,
      deactivatedPictures: [],
      wrongPicture: -1,
      arrowDrawingStarted: false,
      wrongArrowIsIndicated: false,
      hintWasRequested: false,
      arrowPartIsFinished: false,
      pictureSelectionIsShown: false,
      arrowLength: 56,
      minimumArrowLength: 20,
      endAudio: false,
      chooseArrowMessage: "",
      points: {},
      angles: {},
      arrowData: {},
      conditionsForConditionalElements: {
        angle: { arrows: ["weight", "normal"] },
        greenLine: { hints: ["normal"] },
        redLines: { arrows: ["normal", "friction"] }
      },

      successMessageArrowsFinal: "",
      errorMessages: [
        /* {
          satisfied: [{ angle: "b" }, { end: "A" }],
          message: {
            de: "Fehlertext",
            en: "Error message"
          }
        } // General errorMessages (not associated to specific arrow) */
      ],
      feedbackArrowGeneral: "Allgemeines Feedback",
      feedbackOtherArrow: [
        "Du hast die ",
        { otherArrow: true },
        " statt der ",
        { arrow: true },
        " eingezeichnet. Korrigiere deine Lösung."
      ],

      message: "",
      type: "message",
      image: "",
      messageData: {},
      messageIsShown: false,
      messageType: ""
    };
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    getSVGData() {
      let svgData = "";
      for (let i = 0; i < this.content.pictureLeft.length; i++) {
        if (
          typeof this.content.pictureLeft[i] === "string" ||
          this.content.pictureLeft[i].fadeOut !== true
        ) {
          svgData += this.chooseColor(
            this.content.pictureLeft[i],
            this.partOfExercise === 1
          );
        } else {
          svgData +=
            "<g class='fade-out'>" +
            this.chooseColor(
              this.content.pictureLeft[i].svg,
              this.partOfExercise === 1
            ) +
            "</g>";
        }
      }
      return svgData;
    },
    textIndexForDraggable() {
      let indexForDraggable = [];
      for (let i = 0; i < this.content.draggables.length; i++) {
        for (let j = 0; j < this.textDraggables.length; j++) {
          if (
            "number" in this.textDraggables[j] &&
            this.textDraggables[j].number === i
          ) {
            indexForDraggable[i] = j;
          }
        }
        /* if (typeof indexForDraggable[i] !== "number") {
          indexForDraggable[i] = null;
        } */
      }

      for (let k = 0; k < this.textDraggables.length; k++) {
        if (
          "number" in this.textDraggables[k] &&
          this.textDraggables[k].number > indexForDraggable.length - 1
        ) {
          indexForDraggable[
            this.textDraggables[k].number
          ] = this.textDraggables[k].number;
        }
      }
      return indexForDraggable;
    },
    additionalElements() {
      let additionalElements = this.content.additionalElements;

      for (let key in this.content.conditionalElements) {
        let conditionalElementIsShown = false;

        if (key in this.conditionsForConditionalElements) {
          if ("arrows" in this.conditionsForConditionalElements[key]) {
            let isShown = true;
            for (
              let i = 0;
              i < this.conditionsForConditionalElements[key].arrows.length;
              i++
            ) {
              if (
                this.deactivatedPictures.indexOf(
                  this.conditionsForConditionalElements[key].arrows[i]
                ) === -1
              ) {
                isShown = false;
              }
            }

            conditionalElementIsShown = isShown;
          }

          if ("hints" in this.conditionsForConditionalElements[key]) {
            for (
              let i = 0;
              i < this.conditionsForConditionalElements[key].hints.length;
              i++
            ) {
              if (
                this.activatedPicture ===
                  this.conditionsForConditionalElements[key].hints[i] &&
                this.hintWasRequested
              )
                conditionalElementIsShown = true;
            }
          }
        }

        if (conditionalElementIsShown) {
          additionalElements += this.content.conditionalElements[key];
        }
      }

      return additionalElements;
    },
    textAdditional() {
      let additionalText = this.content.textAdditional || [];
      let textAdditional = additionalText.slice();
      let textConditional = this.content.textConditional || [];

      for (let i = 0; i < textConditional.length; i++) {
        let textConditionalIsShown = false;
        let key = textConditional[i].label;

        if (key in this.conditionsForConditionalElements) {
          if ("arrows" in this.conditionsForConditionalElements[key]) {
            let isShown = true;
            for (
              let i = 0;
              i < this.conditionsForConditionalElements[key].arrows.length;
              i++
            ) {
              if (
                this.deactivatedPictures.indexOf(
                  this.conditionsForConditionalElements[key].arrows[i]
                ) === -1
              ) {
                isShown = false;
              }
            }

            textConditionalIsShown = isShown;
          }

          if ("hints" in this.conditionsForConditionalElements[key]) {
            for (
              let i = 0;
              i < this.conditionsForConditionalElements[key].hints.length;
              i++
            ) {
              if (
                this.activatedPicture ===
                  this.conditionsForConditionalElements[key].hints[i] &&
                this.hintWasRequested
              )
                textConditionalIsShown = true;
            }
          }
        }

        if (textConditionalIsShown) {
          textAdditional.push(this.content.textConditional[i]);
        }
      }
      return textAdditional;
    },
    arrowNumber() {
      let counter = 0;
      for (let key in this.arrowData) {
        if (key === this.activatedPicture) {
          break;
        }
        counter++;
      }
      if (this.activatedPicture === -1) {
        counter -= 1;
      }
      return counter;
    },
    correctArrows() {
      let correctArrows = {};

      for (let key in this.arrowData) {
        if (
          !(
            "type" in this.arrowData[key] &&
            this.arrowData[key].type === "wrongAnswer"
          )
        )
          correctArrows[key] = this.arrowData[key];
      }

      return correctArrows;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    getRotation(angle) {
      if (angle !== undefined) {
        return angle;
      } else {
        return 0;
      }
    },
    getFontSize(fontSize) {
      if (fontSize !== undefined) {
        return fontSize;
      } else {
        return 22;
      }
    },
    getColor(color) {
      if (color !== undefined) {
        return this.chooseColor(color, this.partOfExercise === 1);
      } else {
        return "#fff";
      }
    },
    getArrowColor(arrow) {
      if ("arrowColor" in arrow) {
        return arrow.arrowColor;
      } else if ("type" in arrow && arrow.type === "wrongAnswer") {
        return "transparent";
      } else {
        return "#d00";
      }
    },
    getArrowCaptionText(textData) {
      if (textData !== undefined && "text" in textData) {
        return textData.text;
      } else {
        return "";
      }
    },
    getArrowCaptionFontSize(fontSizeData) {
      if (fontSizeData !== undefined && "fontSize" in fontSizeData) {
        return fontSizeData.fontSize;
      } else {
        return 18;
      }
    },
    getArrowCaptionColor(colorData) {
      if (colorData !== undefined && "color" in colorData) {
        return colorData.color;
      } else {
        return "#d00";
      }
    },
    touchIsStarted(e) {
      if (this.scrollIsReleased) {
        return;
      }
      e.preventDefault();
    },
    singleQuotation() {
      let text = "";
      return text.replace(/"/g, "'");
    },
    startDrag(e, draggedElementNumber) {
      if (this.exerciseIsFinished) {
        return;
      }

      if (this.droppedElements.indexOf(draggedElementNumber) === -1) {
        this.xPositionDragStart = 0;
        if (this.$store.state.windowWidth > 600) {
          this.yPositionDragStart = 0;
        } else {
          /* this.yPositionDragStart =
            -0.25 * this.$store.state.windowHeight + this.remToPx(10.5); */
          this.yPositionDragStart =
            -0.25 * this.$store.state.windowHeight + this.remToPx(8.6);
        }
      } else {
        if (draggedElementNumber in this.positions) {
          if (this.$store.state.windowWidth > 600) {
            this.xPositionDragStart =
              this.positions[draggedElementNumber][0] +
              Math.min(
                this.remToPx(25.6),
                0.25 * this.$store.state.windowWidth - this.remToPx(1.5)
              );
            this.yPositionDragStart = this.positions[draggedElementNumber][1];
          } else {
            this.xPositionDragStart = this.positions[draggedElementNumber][0];
            this.yPositionDragStart =
              0.25 * this.$store.state.windowHeight -
              this.remToPx(1.8) +
              this.positions[draggedElementNumber][1];
          }
        } else {
          this.xPositionDragStart = Math.min(
            this.remToPx(51.2),
            0.5 * this.$store.state.windowWidth - this.remToPx(3)
          );
          this.yPositionDragStart = 0;
        }
      }
      this.draggedElementNumber = draggedElementNumber;
      let coordinate = this.getCursorPosition(e);
      this.xPositionStart = coordinate[0] - this.xPositionDragStart;
      this.yPositionStart = coordinate[1] - this.yPositionDragStart;
      this.elementIsGrabbed = true;
      /* let draggedElement = this.$refs[
        "draggable--" + this.draggedElementNumber
      ]; */
      // // // draggedElement.style.stroke = "#14649c";
      // // // draggedElement.style.fill = "#14649c";
    },
    progressDrag(e) {
      if (!this.elementIsGrabbed || this.exerciseIsFinished) {
        return;
      }

      let picture = this.$refs["picture--" + this.draggedElementNumber];

      let draggedElement = document.getElementById(
        "draggable--" + this.draggedElementNumber
      );

      let coordinate = this.getCursorPosition(e);
      let xCoordinateMouse = coordinate[0];
      let yCoordinateMouse = coordinate[1];

      this.xPositionDragStart = xCoordinateMouse - this.xPositionStart;
      this.yPositionDragStart = yCoordinateMouse - this.yPositionStart;

      if (this.$store.state.windowWidth > 600) {
        this.translationX =
          "calc(-50% - min(25.6rem, 25vw - 1.5rem) + " +
          this.xPositionDragStart +
          "px)";
        this.translationY = "calc(-50% + " + this.yPositionDragStart + "px)";
      } else {
        this.translationX = "calc(-50%  + " + this.xPositionDragStart + "px)";
        this.translationY = "calc(-50% + " + this.yPositionDragStart + "px)";
      }

      picture.style.transform =
        "translate(" +
        this.translationX +
        "," +
        this.translationY +
        ") scale(" +
        this.scaleFactor2 +
        ")";
      picture.style.zIndex = 100;
      draggedElement.style.stroke = "#14649c";
      draggedElement.style.fill = "#14649c";
    },
    stopDrag() {
      if (
        this.preventStopDrag ||
        !this.elementIsGrabbed ||
        this.exerciseIsFinished
      ) {
        return;
      }
      this.preventStopDrag = true;
      this.elementIsGrabbed = false;
      this.evaluateButtonIsActive = true;
      this.messageIsShown = false;
      this.playAudio("accept");
      this.setTimeout(() => {
        this.preventStopDrag = false;
      }, 10);
      this.elementIsGrabbed = false;
      let picture = this.$refs["picture--" + this.draggedElementNumber];
      let draggedElement = document.getElementById(
        "draggable--" + this.draggedElementNumber
      );

      draggedElement.style.stroke = "white";
      // // // draggedElement.style.fill = "white";
      draggedElement.style.fill = "transparent";

      let horizontalMiddleDraggedElement;
      let verticalMiddleDraggedElement;
      if (this.$store.state.windowWidth > 600) {
        horizontalMiddleDraggedElement =
          (this.right(draggedElement) + this.left(draggedElement)) / 2;
      } else {
        verticalMiddleDraggedElement =
          (this.top(draggedElement) + this.bottom(draggedElement)) / 2;
      }

      picture.style.transition = "transform 0.2s";
      // picture.style.zIndex = "initial";
      // draggedElement.style.fill = "black";
      this.setTimeout(() => {
        // // // picture.style.zIndex = "initial";
        picture.style.zIndex = 100;
        picture.style.transition = "none";
        draggedElement.style.stroke = "white"; // // //
        draggedElement.style.fill = "transparent"; // // //
      }, 200);

      if (this.$store.state.windowWidth > 600) {
        if (horizontalMiddleDraggedElement < window.innerWidth / 2) {
          this.droppedElements = this.droppedElements.filter(
            x => x !== this.draggedElementNumber
          );

          picture.style.transform =
            "translate(calc(-50% - min(25.6rem, 25vw - 1.5rem)), -50%) scale(" +
            this.scaleFactor2 +
            ")";
        } else {
          if (this.droppedElements.indexOf(this.draggedElementNumber) === -1) {
            this.droppedElements.push(this.draggedElementNumber);
          }
          picture.style.transform =
            "translate(calc(-50% + min(25.6rem, 25vw - 1.5rem)), -50%) scale(" +
            this.scaleFactor2 +
            ")";
        }
      } else {
        if (
          verticalMiddleDraggedElement <
          0.5 * this.$store.state.windowHeight - this.remToPx(5)
        ) {
          this.droppedElements = this.droppedElements.filter(
            x => x !== this.draggedElementNumber
          );

          picture.style.transform =
            "translate(-50%, calc(-50% - 25vh + 8.7rem)) scale(" +
            this.scaleFactor2 +
            ")";
        } else {
          if (this.droppedElements.indexOf(this.draggedElementNumber) === -1) {
            this.droppedElements.push(this.draggedElementNumber);
          }
          picture.style.transform =
            "translate(-50%, calc(-50% + 25vh - 1.8rem)) scale(" +
            this.scaleFactor2 +
            ")";
        }
      }

      this.reOrderDroppedElements(false, false);

      this.$store.dispatch("addExerciseData", [
        "droppedElements",
        this.droppedElements
      ]);
    },
    reOrderDroppedElements(isEndPosition, isReset, isSavedPosition) {
      let translations = [];

      for (let i = 0; i < this.droppedElements.length; i++) {
        let rightShift = 0;
        if (this.droppedElements[i] in this.rightShifts) {
          let isTriggered = false;
          for (let j = 0; j < this.droppedElements.length; j++) {
            if (
              this.rightShifts[this.droppedElements[i]].triggeredBy.indexOf(
                this.droppedElements[j]
              ) > -1
            ) {
              isTriggered = true;
            }
          }
          if (isTriggered) {
            rightShift = this.rightShifts[this.droppedElements[i]].shift;
          }
        }
        let topShift = 0;
        if (this.droppedElements[i] in this.topShifts) {
          let isTriggered = false;
          for (let j = 0; j < this.droppedElements.length; j++) {
            if (
              this.topShifts[this.droppedElements[i]].triggeredBy.indexOf(
                this.droppedElements[j]
              ) > -1
            ) {
              isTriggered = true;
            }
          }
          if (isTriggered) {
            topShift = this.topShifts[this.droppedElements[i]].shift;
          }
        }

        translations.push([rightShift, topShift]);
      }
      let backshift = this.determineBackshift(translations);
      let backshiftToLeft = backshift[0];
      let backshiftToTop = backshift[1];

      for (let i = 0; i < this.droppedElements.length; i++) {
        let horizontalShift = translations[i][0] + backshiftToLeft;
        let verticalShift = translations[i][1] + backshiftToTop;
        if (!isEndPosition && isSavedPosition) {
          if (this.$store.state.windowWidth > 600) {
            this.transformDraggable[this.droppedElements[i]] =
              "translate(calc(-50% + min(25.6rem, 25vw - 1.5rem) + " +
              horizontalShift +
              "px), calc(-50% + " +
              verticalShift +
              "px)) scale(" +
              this.scaleFactor2 +
              ")";
          } else {
            // alert(1);
            this.transformDraggable[this.droppedElements[i]] =
              "translate(calc(-50% + " +
              horizontalShift +
              "px), calc(-50% + 25vh - 1.8rem + " +
              verticalShift +
              "px)) scale(" +
              this.scaleFactor2 +
              ")";
          }

          this.setTimeout(() => {
            let element = this.$refs["picture--" + this.droppedElements[i]];
            // element.style.transition = "transform 0.2s";
            if (this.$store.state.windowWidth > 600) {
              element.style.transform =
                "translate(calc(-50% + min(25.6rem, 25vw - 1.5rem) + " +
                horizontalShift +
                "px), calc(-50% + " +
                verticalShift +
                "px)) scale(" +
                this.scaleFactor2 +
                ")";
            } else {
              // alert(2);
              element.style.transform =
                "translate(calc(-50% + " +
                horizontalShift +
                "px), calc(-50% + 25vh - 1.8rem + " +
                verticalShift +
                "px)) scale(" +
                this.scaleFactor2 +
                ")";
            }
            this.transformDraggable = [];
            /* this.setTimeout(() => {
              element.style.transition = "none";
            }, 200); */
          }, 0); // was: 500
        } else if (isSavedPosition) {
          // alert(4);
          let transformValue;
          let transformValueTextX;
          let transformValueTextY;
          if (this.$store.state.windowWidth > 600) {
            transformValue =
              "translate(calc(-50% + 15.6rem + " +
              horizontalShift +
              "px), calc(-50% + " +
              verticalShift +
              "px)) scale(" +
              this.scaleOfSolution +
              ")";
            transformValueTextX = "-50% + 15.6rem + " + horizontalShift + "px";
            transformValueTextY = "-50% + " + verticalShift + "px";
          } else {
            // alert(3);
            transformValue =
              "translate(calc(-50% +  " +
              horizontalShift +
              "px), calc(-50% + 25vh - 19.2rem + " +
              verticalShift +
              "px)) scale(" +
              this.scaleOfSolution +
              ")";
            transformValueTextX = "-50% +  " + horizontalShift + "px";
            transformValueTextY =
              "-50% + 25vh - 19.2rem + " + verticalShift + "px";
          }

          this.transformDraggable[this.droppedElements[i]] = transformValue;
          this.transformAdditional[1] = transformValue;
          this.transformValueTextX = transformValueTextX;
          this.transformValueTextY = transformValueTextY;

          this.setTimeout(() => {
            let element = this.$refs["picture--" + this.droppedElements[i]];
            // element.style.transition = "transform 0.2s";
            if (this.$store.state.windowWidth > 600) {
              element.style.transform =
                "translate(calc(-50% + 15.6rem + " +
                horizontalShift +
                "px), calc(-50% + " +
                verticalShift +
                "px)) scale(" +
                this.scaleOfSolution +
                ")";
            } else {
              // alert(4);
              element.style.transform =
                "translate(calc(-50% +  " +
                horizontalShift +
                "px), calc(-50% + 25vh - 19.2rem + " +
                verticalShift +
                "px)) scale(" +
                this.scaleOfSolution +
                ")";
            }
            this.transformDraggable = [];
            this.transformAdditional = [];
            // this.transformValueTextX = "0px";
            // this.transformValueTextY = "0px";
            /* this.setTimeout(() => {
              element.style.transition = "none";
            }, 200); */
          }, 0); // was: 500
        } else if (!isEndPosition && !isReset) {
          // alert(4);
          let element = this.$refs["picture--" + this.droppedElements[i]];

          element.style.transition = "transform 0.2s";
          if (this.$store.state.windowWidth > 600) {
            element.style.transform =
              "translate(calc(-50% + min(25.6rem, 25vw - 1.5rem) + " +
              horizontalShift +
              "px), calc(-50% + " +
              verticalShift +
              "px)) scale(" +
              this.scaleFactor2 +
              ")";
          } else {
            // alert(5);
            element.style.transform =
              "translate(calc(-50% + " +
              horizontalShift +
              "px), calc(-50% + 25vh - 1.8rem + " +
              verticalShift +
              "px)) scale(" +
              this.scaleFactor2 +
              ")";
            element.style.transform =
              "translate(calc(-50% + " +
              horizontalShift +
              "px), calc(-50% + 25vh - 1.8rem + " +
              verticalShift +
              "px)) scale(" +
              this.scaleFactor2 +
              ")";
          }

          this.setTimeout(() => {
            element.style.transition = "none";
          }, 200);
        } else if (!isReset) {
          // alert(6);
          let element = this.$refs["picture--" + this.droppedElements[i]];
          let transformValue;
          let transformValueTextX;
          let transformValueTextY;
          if (this.$store.state.windowWidth > 600) {
            transformValue =
              "translate(calc(-50% + 15.6rem + " +
              horizontalShift +
              "px), calc(-50% + " +
              verticalShift +
              "px)) scale(" +
              this.scaleOfSolution +
              ")";
            transformValueTextX = "-50% + 15.6rem + " + horizontalShift + "px";
            transformValueTextY = "-50% + " + verticalShift + "px";
          } else {
            // alert(7);
            transformValue =
              "translate(calc(-50% +  " +
              horizontalShift +
              "px), calc(-50% + 25vh - 19.2rem + " +
              verticalShift +
              "px)) scale(" +
              this.scaleOfSolution +
              ")";
            transformValueTextX = "-50% +  " + horizontalShift + "px";
            transformValueTextY =
              "-50% + 25vh - 19.2rem + " + verticalShift + "px";
          }

          this.transformAdditional[1] = transformValue;
          this.transformValueTextX = transformValueTextX;
          this.transformValueTextY = transformValueTextY;

          element.style.transition = "transform 0.333s ease-in-out";
          element.style.transform = transformValue;

          this.setTimeout(() => {
            element.style.transition = "none";
          }, 350);
        } else {
          // alert(8);
          let element = this.$refs["picture--" + this.droppedElements[i]];
          // let additionalElement = this.$refs["additional--" + 1];
          if (this.$store.state.windowWidth > 600) {
            element.style.transform =
              "translate(calc(-50% - min(25.6rem, 25vw - 1.5rem)), -50%) scale(" +
              this.scaleFactor2 +
              ")";
          } else {
            // alert(9);
            element.style.transform =
              "translate(-50%, calc(-50% - 25vh + 8.7rem)) scale(" +
              this.scaleFactor2 +
              ")";
          }
        }

        if (this.$store.state.windowWidth > 600) {
          // alert(10);
          this.positions[this.droppedElements[i]] = [
            Math.min(
              this.remToPx(25.6),
              0.25 * this.$store.state.windowWidth - this.remToPx(1.5)
            ) + horizontalShift,
            verticalShift
          ];
          /* setTimeout(() => {
            this.transformValueTextY = "-50% - 2.37px";
          }, 5000); */
        } else {
          // alert(7);
          this.positions[this.droppedElements[i]] = [
            horizontalShift,
            verticalShift
          ];
        }
      }
      // this.transformDraggable = "none"; ///// !!!!!!
    },
    determineBackshift(translations) {
      let leftMin = 10000;
      let leftMax = 0;
      let topMin = 10000;
      let topMax = 0;

      for (let i = 0; i < translations.length; i++) {
        let left =
          this.coordinates[this.droppedElements[i]][0] + translations[i][0];
        leftMin = Math.min(leftMin, left);
        let right =
          this.coordinates[this.droppedElements[i]][1] + translations[i][0];
        leftMax = Math.max(leftMax, right);

        let top =
          this.coordinates[this.droppedElements[i]][2] + translations[i][1];
        topMin = Math.min(topMin, top);
        let bottom =
          this.coordinates[this.droppedElements[i]][3] + translations[i][1];
        topMax = Math.max(topMax, bottom);
      }

      let horizontalMiddle = (leftMin + leftMax) / 2;
      let backshiftLeft =
        this.scaleFactor2 * (this.svgSize[0] / 2 - horizontalMiddle);

      let verticalMiddle = (topMin + topMax) / 2;
      let backshiftTop =
        this.scaleFactor2 * (this.svgSize[1] / 2 - verticalMiddle);

      return [backshiftLeft, backshiftTop];
    },
    evaluateAnswer() {
      let answerIsCorrect = true;
      for (let i = 0; i < this.correctAnswer.length; i++) {
        if (this.droppedElements.indexOf(this.correctAnswer[i]) === -1) {
          answerIsCorrect = false;
        }
      }
      for (let i = 0; i < this.droppedElements.length; i++) {
        if (this.correctAnswer.indexOf(this.droppedElements[i]) === -1) {
          answerIsCorrect = false;
        }
      }

      if (answerIsCorrect) {
        this.exerciseIsFinished = true;
        this.$store.dispatch("setExerciseStatus", 4);
        this.$store.dispatch("addExerciseData", ["exerciseIsFinished", true]);
        this.playAudio("correct");
        this.answerIsCorrect = true;
        this.setTimeout(() => {
          this.answerIsCorrect = false;
        }, 700);
        this.setTimeout(() => {
          if (this.content.audio !== undefined) {
            this.playAudio(this.content.audio);
          }
        }, 1250);

        this.setTimeout(() => {
          this.pictureIsSmall = true;
          this.reOrderDroppedElements(true, false);

          this.messageType = "success";
          this.generateMessageText("success");

          this.setTimeout(() => {
            this.scrollIsReleased = true;
          }, 1600);
        }, 750);

        this.setTimeout(() => {
          this.additionalElementIsVisible = true;
        }, 1250);
      } else {
        this.$store.dispatch("setExerciseStatus", 2);
        this.playAudio("wrong");
        this.answerIsWrong = true;
        this.setTimeout(() => {
          this.answerIsWrong = false;
        }, 700);

        this.messageType = "mistake";
        this.generateMessageText("mistake");
        this.evaluateButtonIsActive = false;
      }
    },
    generateMessageText(type, delay) {
      if (delay === undefined) {
        delay = 500;
      }

      if (type === "success") {
        let successMessage = this.t(this.content.successMessage);

        if (successMessage.length > 0) {
          this.messageData = this.generateMessage(successMessage, "message");

          this.scrollToBottom(delay);

          this.setTimeout(() => {
            this.messageIsShown = true;
            this.$store.dispatch("addExerciseData", ["messageIsShown", true]);

            let continueButton = document.querySelector(
              ".picture__continue-button"
            );
            continueButton.style.transition = "transform 0.3s 0.075s";
            this.continueButtonIsShown = true;

            this.setTimeout(() => {
              continueButton.style.transition = "transform 0.75s";
            }, 400);
          }, delay);
        } else {
          this.setTimeout(() => {
            let continueButton = document.querySelector(
              ".picture__continue-button"
            );
            continueButton.style.transition = "transform 0.75s";
            this.continueButtonIsShown = true;
          }, delay);
        }
      } else if (type === "mistake") {
        let feedbackGeneral = this.t(this.content.feedbackGeneral);

        let feedbackMissingGeneral = this.t(
          this.content.feedbackMissingGeneral
        );
        let feedbackAdditionalGeneral = this.t(
          this.content.feedbackAdditionalGeneral
        );
        let feedbackAdditionalGeneral2 = this.t(
          this.content.feedbackAdditionalGeneral2
        );

        let feedbackFinalComment = this.t(this.content.feedbackFinalComment);
        let thereAreElementsMissing = false;

        for (let i = 0; i < this.correctAnswer.length; i++) {
          if (this.droppedElements.indexOf(this.correctAnswer[i]) === -1) {
            thereAreElementsMissing = true;
          }
        }
        let missingElements = [];
        for (let key in this.feedbackMissingElements) {
          if (this.droppedElements.indexOf(parseInt(key)) === -1) {
            missingElements.push(key);
          }
        }

        let thereAreAdditionalElements = false;
        for (let i = 0; i < this.droppedElements.length; i++) {
          if (this.correctAnswer.indexOf(this.droppedElements[i]) === -1) {
            thereAreAdditionalElements = true;
          }
        }

        let additionalElements = [];
        for (let i = 0; i < this.droppedElements.length; i++) {
          if (this.droppedElements[i] in this.feedbackAdditionalElements) {
            additionalElements.push(this.droppedElements[i]);
          }
          additionalElements.sort(function(a, b) {
            return a - b;
          });
        }

        let feedback = "";
        if (missingElements.length > 0) {
          for (let i = 0; i < missingElements.length; i++) {
            feedback =
              feedback +
              " " +
              this.t(this.feedbackMissingElements[missingElements[i]]);
          }
        } else if (
          thereAreElementsMissing &&
          feedbackMissingGeneral.length > 0
        ) {
          feedback = feedbackMissingGeneral;
        }
        if (additionalElements.length > 0) {
          for (let i = 0; i < additionalElements.length; i++) {
            feedback =
              feedback +
              " " +
              this.t(this.feedbackAdditionalElements[additionalElements[i]]);
          }
        } else if (
          thereAreAdditionalElements &&
          feedbackAdditionalGeneral.length > 0 &&
          feedback.length === 0
        ) {
          feedback = feedbackAdditionalGeneral;
        } else if (
          thereAreAdditionalElements &&
          feedbackAdditionalGeneral2.length > 0 &&
          feedback.length > 0
        ) {
          feedback = feedback + " " + feedbackAdditionalGeneral2;
        }

        if (feedback.length === 0 && feedbackGeneral.length > 0) {
          feedback = feedbackGeneral;
        }

        if (feedback.length > 0 && feedbackFinalComment.length > 0) {
          feedback = feedback + " " + feedbackFinalComment;
        }

        if (feedback.length > 0) {
          this.messageData = this.generateMessage(feedback, "error");
          this.setTimeout(() => {
            this.messageIsShown = true;
          }, delay);
        }
      }
    },
    resetExercise(resetAfterResize) {
      if (this.exerciseType === "arrows") {
        for (let i = 0; i < Object.keys(this.arrowData).length; i++) {
          this.$store.dispatch("setExerciseStatus", [i, 0]);
        }

        if (resetAfterResize === undefined) {
          this.$store.dispatch("addExerciseData", ["deactivatedPictures", []]);
          this.$store.dispatch("addExerciseData", [
            "arrowPartIsFinished",
            false
          ]);
          this.deactivatedPictures = [];
        }

        this.deleteUnfinishedArrows();
        this.continueButtonIsShown = false;
        this.activatedPicture = -1;
        this.wrongPicture = -1;
        this.arrowDrawingStarted = false;
        this.wrongArrowIsIndicated = false;
        this.hintWasRequested = false;
        this.arrowPartIsFinished = false;
        this.messageIsShown = false;
        this.textRightSide = this.content.selectionInstructionStart;
        // !arrowPartIsFinished && !continueButtonIsShown
        this.pictureSelectionIsShown = false;

        for (let key in this.arrowData) {
          let arrow = document.querySelector(".picture__arrow--" + key);
          arrow.style.transition = "none";
          arrow.style.height = 0;

          let arrowTip = document.querySelector(".picture__arrow-tip--" + key);
          arrowTip.style.transition = "none";
          arrowTip.style.transform = "none";

          let arrowContainer = document.querySelector(
            ".picture__arrow-container--" + key
          );
          arrowContainer.style.transition = "none";
          arrowContainer.style.transform = "none";

          let arrowCaption = document.querySelector(
            ".picture__arrow-caption--" + key
          );
          // arrowCaption.style.visibility = "hidden";
          // arrowCaption.style.opacity = 0;
          arrowCaption.style.display = "none";
          arrowCaption.style.transform = "none";
        }
      } else {
        this.$store.dispatch("setExerciseStatus", 0);
        this.$store.dispatch("addExerciseData", ["droppedElements", []]);
        this.$store.dispatch("addExerciseData", ["exerciseIsFinished", false]);
        this.reOrderDroppedElements(false, true);
        this.transitionIsSuspended = true;
        this.setTimeout(() => {
          this.transitionIsSuspended = false;
        }, 100);

        (this.elementIsGrabbed = false),
          (this.draggedElementNumber = 0),
          (this.xPositionDragStart = 0),
          (this.yPositionDragStart = 0),
          (this.translationX = 0),
          (this.translationY = 0),
          (this.droppedElements = []),
          (this.preventStopDrag = false),
          (this.pictureIsSmall = false),
          (this.exerciseIsFinished = false),
          (this.evaluateButtonIsActive = true),
          (this.continueButtonIsShown = false),
          (this.answerIsCorrect = false);
        this.answerIsWrong = false;
        (this.transformAdditional = []),
          (this.transformValueTextX = "0px"),
          (this.transformValueTextY = "0px"),
          (this.additionalElementIsVisible = false),
          (this.message = ""),
          (this.type = "message"),
          (this.image = ""),
          (this.messageData = {}),
          (this.messageIsShown = false),
          (this.scrollIsReleased = false);
        this.scrollIsProhibited = false;
      }

      if (this.$store.state.windowWidth <= 600) {
        this.partOfExercise = 1;
      }

      this.scrollToTop();
    },
    scrollToBottom(delay) {
      this.setTimeout(() => {
        let message = document.querySelector(".picture__message-container");
        let bottomMessage = this.height(message);
        if (this.$store.state.windowWidth > 600) {
          bottomMessage += this.remToPx(22.8);
        }

        let pictureContainer = document.querySelector(
          ".picture__picture-container"
        );
        if (delay === 0) {
          this.scrollElement(pictureContainer, bottomMessage, 1);
        } else {
          this.setTimeout(() => {
            this.scrollElement(pictureContainer, bottomMessage, 25);
          }, delay);
        }
        this.setTimeout(() => {
          this.scrollIsReleased = true;
        }, 500);
      }, 0);
    },
    resizeWindow() {
      /* if (
        this.resizeWidth !== this.$store.state.windowWidth ||
        this.resizeHeight !== this.$store.state.windowHeight
      ) {
        this.resizeWidth = window.innerWidth;
        this.resizeHeight = window.innerHeight;
      } else {
        return;
      } */

      if (this.$store.state.windowWidth > 600) {
        this.scaleFactor2 =
          this.scaleFactor *
          (Math.min(this.$store.state.windowWidth, 1024) / 1024);
      } else {
        this.scaleFactor2 = this.scaleFactor;
      }

      let html = document.getElementsByTagName("html")[0];
      html.style.setProperty("--scale-of-picture", this.scaleFactor2);
      this.scaleOfSolution2 =
        this.scaleOfSolution *
        (Math.min(this.$store.state.windowWidth, 1024) / 1024);
      html.style.setProperty(
        "--scale-of-picture-2",
        1.5 * this.scaleOfSolution2 + "px"
      );

      if (this.exerciseType === "arrows") {
        this.resetExercise(true);
        this.autoSolveArrows();

        if (
          this.$store.state.windowWidth > 600 &&
          this.$store.state.windowHeight > 600
        ) {
          this.partOfExercise = 2;
        }
      } else {
        return;
      }
    },
    orientationChange() {
      this.transitionIsSuspended = true;
      this.setTimeout(() => {
        this.transitionIsSuspended = false;
      }, 1000);
    },

    // Begin of arrow part //
    showPictureSelection() {
      this.pictureSelectionIsShown = !this.pictureSelectionIsShown;
      this.messageIsShown = false;
    },
    choosePicture(picture, index) {
      if (this.deactivatedPictures.indexOf(index) > -1) {
        return;
      }
      if ("type" in picture && picture.type === "wrongAnswer") {
        // this.activatedPicture = -1;
        if (this.wrongPicture === -1) {
          this.messageIsShown = false;
        }

        setTimeout(() => {
          if (this.wrongPicture === index) {
            this.wrongPicture = -1;
            this.messageIsShown = false;
            return;
          }

          // this.wrongPicture = index;
          if (this.activatedPicture !== -1) {
            this.activatedPicture = -1;
            setTimeout(() => {
              this.wrongPicture = index;
            }, 205);
          } else {
            this.wrongPicture = index;
          }

          this.$store.dispatch("setExerciseStatus", [this.arrowNumber, 2]);

          this.playAudio("wrong");

          if ("messageWrong" in picture) {
            setTimeout(() => {
              let feedback = picture.messageWrong;
              this.messageData = this.generateMessage(feedback, "error");
              this.messageIsShown = true;
            }, 667);
          } else {
            setTimeout(() => {
              this.wrongPicture = -1;
            }, 667);
          }
        }, 0);
        //
      } else {
        this.wrongPicture = -1;
        this.activatedPicture === index
          ? (this.activatedPicture = -1)
          : (this.activatedPicture = index);
        if (this.activatedPicture === -1) {
          if (this.deactivatedPictures.length > 0) {
            this.textRightSide = this.content.selectionInstructionProgress;
          } else {
            this.textRightSide = this.content.selectionInstructionStart;
          }
        } else {
          this.textRightSide = picture.name;
        }
        this.wrongArrowIsIndicated = false;
        this.hintWasRequested = false;
        if (this.activatedPicture !== -1) {
          this.pictureSelectionIsShown = false;
        }
        this.deleteUnfinishedArrows();
        this.playAudio("click");
      }
    },
    startArrow(e) {
      e.preventDefault();

      if (
        this.arrowPartIsFinished ||
        this.continueButtonIsShown ||
        this.partOfExercise === 1
      ) {
        return;
      }

      if (this.activatedPicture === -1) {
        let message = this.$t("course_arrows", true);
        let feedback;
        if (this.isDefined(this.chooseArrowMessage)) {
          feedback = this.chooseArrowMessage;
        } else {
          feedback = {
            de: message.de.choose_arrow,
            en: message.en.choose_arrow
          };
        }

        this.messageData = this.generateMessage(feedback, "error");
        this.messageIsShown = true;
      }

      if (this.exerciseType !== "arrows" || this.activatedPicture === -1) {
        return;
      }

      this.wrongArrowIsIndicated = false;
      this.deleteUnfinishedArrows();
      let coordinate = this.getCursorPosition(e);
      this.arrowDrawingStarted = true;
      this.leftArrow = coordinate[0];
      this.topArrow = coordinate[1];
    },
    progressArrow(e) {
      e.preventDefault();
      if (
        this.exerciseType !== "arrows" ||
        !this.arrowDrawingStarted ||
        this.activatedPicture === -1
      ) {
        return;
      }

      this.messageIsShown = false;
      this.wrongArrowIsIndicated = false;
      let coordinate = this.getCursorPosition(e);

      let xCoordinate =
        (this.leftArrow - this.left(this.outerPictureContainer)) /
        this.remToPx(1);

      let yCoordinate =
        (this.topArrow - this.top(this.outerPictureContainer)) /
        this.remToPx(1);

      let length =
        Math.sqrt(
          Math.pow(coordinate[0] - this.leftArrow, 2) +
            Math.pow(coordinate[1] - this.topArrow, 2)
        ) / this.remToPx(1);

      let quotient =
        (coordinate[1] - this.topArrow) / (coordinate[0] - this.leftArrow);

      let angle = (Math.atan(quotient) * 180) / Math.PI + 90;
      if (coordinate[0] >= this.leftArrow) {
        angle = (Math.atan(quotient) * 180) / Math.PI - 90;
      }

      this.drawArrow(this.activatedPicture, {
        startPoint: [xCoordinate, yCoordinate],
        length,
        angle
      });
    },
    finishArrow(e) {
      e.preventDefault();
      if (
        this.exerciseType !== "arrows" ||
        !this.arrowDrawingStarted ||
        this.activatedPicture === -1
      ) {
        return;
      }

      this.arrowDrawingStarted = false;

      let coordinate = this.getCursorPosition(e);

      let length = Math.sqrt(
        Math.pow(coordinate[0] - this.leftArrow, 2) +
          Math.pow(coordinate[1] - this.topArrow, 2)
      );
      if (length < 25) {
        this.deleteUnfinishedArrows();
        this.generateArrowsErrorText("too_short");
        return;
      }

      ////
      let additionalElement = document.querySelector(
        ".picture__additional-element"
      );
      let leftAdditionalElement = this.left(additionalElement);
      let topAdditionalElement = this.top(additionalElement);

      let startX = this.leftArrow - leftAdditionalElement;
      let startY = this.topArrow - topAdditionalElement;
      let endX = coordinate[0] - leftAdditionalElement;
      let endY = coordinate[1] - topAdditionalElement;
      let arrowEvaluation = this.evaluateArrow([startX, startY], [endX, endY]);
      // this.a(this.evaluateArrow([startX, startY], [endX, endY]));
      ////

      if (arrowEvaluation.arrowIsCorrect) {
        this.$store.dispatch("setExerciseStatus", [this.arrowNumber, 4]);
        this.$store.dispatch("addExerciseData", [
          "deactivatedPictures",
          this.deactivatedPictures
        ]);
        this.hintWasRequested = false;

        let rightCaption = document.querySelector(
          ".picture__figure-caption--2"
        );
        rightCaption.style.transition = "opacity 0.5s";
        rightCaption.style.opacity = 0;
        setTimeout(() => {
          if (!this.arrowPartIsFinished && !this.continueButtonIsShown) {
            this.textRightSide = this.content.selectionInstructionProgress;
          } else {
            this.textRightSide = "";
          }
          rightCaption.style.transition = "opacity 0.25s";
          rightCaption.style.opacity = 1;
        }, 1250);
        this.playAudio("correct");

        // Snap arrow in end position
        this.drawArrow(this.activatedPicture, {}, true);

        // Deactivate pictures and show messages
        {
          this.showSuccessMessage(this.activatedPicture);
          setTimeout(() => {
            if (
              // this.deactivatedPictures.length ===
              // Object.keys(this.arrowData).length
              this.deactivatedPictures.length >=
              Object.keys(this.correctArrows).length
            ) {
              this.continueButtonIsShown = true;
            }
          }, 700);

          this.playPictureAudio();

          this.deactivatedPictures.push(this.activatedPicture);
          this.activatedPicture = -1;

          if (
            // this.deactivatedPictures.length ===
            // Object.keys(this.arrowData).length
            this.deactivatedPictures.length >=
            Object.keys(this.correctArrows).length
          ) {
            for (let key in this.arrowData) {
              if (!(key in this.deactivatedPictures)) {
                this.deactivatedPictures.push(key);
              }
            }
            for (let i = 0; i < Object.keys(this.arrowData).length; i++) {
              this.$store.dispatch("setExerciseStatus", [i, 4]);
            }
            this.$store.dispatch("addExerciseData", [
              "arrowPartIsFinished",
              true
            ]);
            this.$store.dispatch("addExerciseData", [
              "exerciseIsFinished",
              true
            ]);
          }
        }
      } else {
        this.$store.dispatch("setExerciseStatus", [this.arrowNumber, 2]);
        this.wrongArrowIsIndicated = true;
        this.playAudio("wrong");
        this.generateArrowsErrorText(arrowEvaluation);
      }
    },
    showSuccessMessage(activatedPicture, solutionButtonWasUsed) {
      setTimeout(() => {
        let feedback = [];

        if (
          solutionButtonWasUsed &&
          "solutionButtonMessage" in this.arrowData[activatedPicture]
        ) {
          feedback = [this.arrowData[activatedPicture].solutionButtonMessage];
        } else if ("successMessage" in this.arrowData[activatedPicture]) {
          feedback = [this.arrowData[activatedPicture].successMessage];
        }

        if (
          // this.deactivatedPictures.length ===
          // Object.keys(this.arrowData).length &&
          this.deactivatedPictures.length >=
            Object.keys(this.correctArrows).length &&
          this.isDefined(this.successMessageArrowsFinal)
        ) {
          if (feedback.length > 0) {
            feedback.push(" ");
          }
          feedback.push(this.successMessageArrowsFinal);
        }

        if (feedback.length > 0) {
          this.messageData = this.generateMessage(feedback);
          this.messageIsShown = true;
        }
      }, 700);
    },
    playPictureAudio() {
      let audio = "";
      if (
        "audio" in this.arrowData[this.activatedPicture] &&
        this.isDefined(this.arrowData[this.activatedPicture].audio)
      ) {
        audio = this.t(this.arrowData[this.activatedPicture].audio);
        this.getAudioDuration(audio);

        if (
          // this.deactivatedPictures.length + 1 ===
          // Object.keys(this.arrowData).length
          this.deactivatedPictures.length + 1 >=
          Object.keys(this.correctArrows).length
        ) {
          this.getAudioDuration(audio);
        }
        setTimeout(() => {
          this.playAudio(audio);
        }, 800);
      }

      if (
        this.isDefined(this.endAudio) &&
        // this.deactivatedPictures.length + 1 ===
        // Object.keys(this.arrowData).length
        this.deactivatedPictures.length + 1 >=
          Object.keys(this.correctArrows).length
      ) {
        let endAudio = this.t(this.endAudio);

        setTimeout(() => {
          let delay = 0;
          if (audio in this.$store.state.audioDurations) {
            delay = this.$store.state.audioDurations[audio] + 300;
          }

          setTimeout(() => {
            this.playAudio(endAudio);
          }, delay);
        }, 800);
      }
    },
    drawArrow(arrowKey, arrowData, endPosition, autosolve) {
      // arrowKey = this.activatedPicture; // // //
      let arrow = document.querySelector(".picture__arrow--" + arrowKey);

      let arrowLength = this.scaleOfSolution * 56;
      if (endPosition) {
        if (autosolve) {
          arrow.style.transition = "none";
        } else {
          arrow.style.transition = "height 0.1875s"; // was: 0.2s
        }
        if ("arrowLength" in this.arrowData[arrowKey]) {
          arrowLength =
            this.scaleOfSolution * this.arrowData[arrowKey].arrowLength;
        } else if (this.isDefined(this.arrowLength)) {
          arrowLength = this.scaleOfSolution * this.arrowLength;
        }
      }
      if ("length" in arrowData) {
        arrow.style.height = arrowData.length + "rem";
      } else {
        arrow.style.height = arrowLength + "px";
      }

      let arrowContainer = document.querySelector(
        ".picture__arrow-container--" + arrowKey
      );

      if (endPosition !== undefined && endPosition) {
        if (autosolve === undefined || !autosolve) {
          arrowContainer.style.transition = "transform 0.2875s"; // was: 0.2s
        }

        let additionalElement = document.querySelector(
          ".picture__additional-element"
        );
        let leftAdditionalElement = this.left(additionalElement);
        let topAdditionalElement = this.top(additionalElement);

        // // // GET ARROW END DATA:
        // Get start point
        let startPoint = [];
        if ("start" in this.arrowData[arrowKey]) {
          let xCoordinate =
            (leftAdditionalElement -
              this.left(this.outerPictureContainer) +
              this.scaleOfSolution *
                this.points[this.arrowData[arrowKey].start].x) /
              this.remToPx(1) -
            (0.0625 * this.$store.state.windowWidth) / 1024;
          let yCoordinate =
            (topAdditionalElement -
              this.top(this.outerPictureContainer) +
              this.scaleOfSolution *
                this.points[this.arrowData[arrowKey].start].y) /
              this.remToPx(1) -
            (0.0625 * this.$store.state.windowWidth) / 1024;
          startPoint = [xCoordinate, yCoordinate];
        }
        // Get end point
        let endPoint = [];
        if ("end" in this.arrowData[arrowKey]) {
          let xCoordinate =
            (leftAdditionalElement -
              this.left(this.outerPictureContainer) +
              this.scaleOfSolution *
                this.points[this.arrowData[arrowKey].end].x) /
            this.remToPx(1);
          let yCoordinate =
            (topAdditionalElement -
              this.top(this.outerPictureContainer) +
              this.scaleOfSolution *
                this.points[this.arrowData[arrowKey].end].y -
              1.25) /
              this.remToPx(1) -
            (0.0625 * this.$store.state.windowWidth) / 1024;
          endPoint = [xCoordinate, yCoordinate];
        }
        // Get angle
        let angle;
        if ("angle" in this.arrowData[arrowKey]) {
          angle = 180 - this.angles[this.arrowData[arrowKey].angle].angle;
        }
        // Get caption position
        let captionPosition = [];
        if ("position" in this.arrowData[arrowKey].caption) {
          captionPosition = this.arrowData[arrowKey].caption.position;
        }

        arrowData = {
          startPoint,
          endPoint,
          angle,
          captionPosition
        };
      }

      let angle = arrowData.angle;
      let xCoordinate;
      let yCoordinate;
      let correctionX = -0.1675;
      let correctionY = -0.125;
      // Handle start point
      if ("startPoint" in arrowData && arrowData.startPoint.length === 2) {
        xCoordinate = arrowData.startPoint[0];
        yCoordinate = arrowData.startPoint[1];
      }
      // Handle end point
      if ("endPoint" in arrowData && arrowData.endPoint.length === 2) {
        correctionX = 0.125;
        xCoordinate = arrowData.endPoint[0];
        yCoordinate = arrowData.endPoint[1];
        correctionY = -arrowLength / this.remToPx(1) - 0.25;
      }

      arrowContainer.style.transform =
        "translate(" +
        xCoordinate +
        "rem, " +
        yCoordinate +
        "rem) rotate(" +
        angle +
        "deg) translate(" +
        correctionX +
        "rem, " +
        correctionY +
        "rem)";
      setTimeout(() => {
        arrowContainer.style.visibility = "visible";
      }, 0);

      // Handle arrow caption
      let delay = 0;
      if (autosolve === undefined || !autosolve) {
        delay = 200;
      }
      if ("captionPosition" in arrowData) {
        let arrowCaption = document.querySelector(
          ".picture__arrow-caption--" + arrowKey
        );
        let leftCaption =
          xCoordinate + arrowData.captionPosition[0] / this.remToPx(1);
        let topCaption =
          yCoordinate + arrowData.captionPosition[1] / this.remToPx(1);
        arrowCaption.style.transform =
          "translate(" + leftCaption + "rem, " + topCaption + "rem)";
        setTimeout(() => {
          // arrowCaption.style.visibility = "visible";
          arrowCaption.style.display = "block";
          // arrowCaption.style.opacity = 1;
        }, delay);
      }
    },
    deleteUnfinishedArrows() {
      (this.messageIsShown = false), (this.arrowDrawingStarted = false);

      setTimeout(() => {
        for (let key in this.arrowData) {
          if (this.deactivatedPictures.indexOf(key) === -1) {
            let arrow = document.querySelector(".picture__arrow--" + key);
            // arrow.style.visibility = "hidden";
            arrow.style.transition = "none";
            arrow.style.height = 0;

            let arrowTip = document.querySelector(
              ".picture__arrow-tip--" + key
            );
            arrowTip.style.transition = "none";
            arrowTip.style.transform = "none";

            let arrowContainer = document.querySelector(
              ".picture__arrow-container--" + key
            );
            arrowContainer.style.visibility = "hidden";
            arrowContainer.style.transition = "none";
            arrowContainer.style.transform = "none";

            let arrowCaption = document.querySelector(
              ".picture__arrow-caption--" + key
            );
            // arrowCaption.style.visibility = "hidden";
            // arrowCaption.style.opacity = 0;
            arrowCaption.style.transform = "none";
          }
        }
      }, 0);
    },
    evaluateArrow(startArrow, endArrow) {
      let start = [];
      let end = [];
      let transit = [];
      let angles = [];
      let onSpannedLines = [];
      let correctProperties = {};
      let wrongProperties = {};
      let correctArrows = [];
      let otherCorrectArrows = [];
      let arrowIsCorrect = false;

      for (let key in this.points) {
        let distance1 = this.distance(startArrow, [
          this.scaleOfSolution * this.points[key].x,
          this.scaleOfSolution * this.points[key].y
        ]);
        let distance2 = this.distance(endArrow, [
          this.scaleOfSolution * this.points[key].x,
          this.scaleOfSolution * this.points[key].y
        ]);
        let projectionData = this.evaluateProjection(startArrow, endArrow, [
          this.scaleOfSolution * this.points[key].x,
          this.scaleOfSolution * this.points[key].y
        ]);
        let isOnSection = projectionData.isOnSection;
        let projection = projectionData.projection;
        let distance3 = this.distance(projection, [
          this.scaleOfSolution * this.points[key].x,
          this.scaleOfSolution * this.points[key].y
        ]);
        let R = 10;
        if ("r" in this.points[key]) {
          R = this.points[key].r;
        }
        if (distance1 <= R) {
          start.push(key);
        }
        if (distance2 <= R) {
          end.push(key);
        }
        if (isOnSection && distance3 <= R) {
          if (start.indexOf(key) === -1 && end.indexOf(key) === -1) {
            transit.push(key);
          }
        }
      }

      let quotient =
        (endArrow[1] - startArrow[1]) / (endArrow[0] - startArrow[0]);
      let angle = -(Math.atan(quotient) * 180) / Math.PI + 90;
      if (endArrow[0] >= startArrow[0]) {
        angle = -(Math.atan(quotient) * 180) / Math.PI + 270;
      }

      for (let key in this.angles) {
        let targetedAngle = this.angles[key].angle;
        if (angle <= 90 && targetedAngle >= 270) {
          angle += 360;
        } else if (targetedAngle <= 90 && angle >= 270) {
          targetedAngle += 360;
        }

        let Tolerance = 10;
        if ("tolerance" in this.angles[key]) {
          Tolerance = this.angles[key].tolerance;
        }

        let angleDifference = Math.abs(angle - targetedAngle);
        if (angleDifference > 180) {
          angleDifference = 360 - angleDifference;
        }

        if (angleDifference <= Tolerance) {
          angles.push(key);
        }

        if (
          angleDifference <= Tolerance ||
          angleDifference >= 180 - Tolerance
        ) {
          onSpannedLines.push(key);
        }
      }

      for (let key in this.arrowData) {
        correctProperties[key] = [];
        wrongProperties[key] = [];

        if ("start" in this.arrowData[key]) {
          if (start.indexOf(this.arrowData[key].start) > -1) {
            correctProperties[key].push("start");
          } else {
            wrongProperties[key].push("start");
          }
        }
        if ("end" in this.arrowData[key]) {
          if (end.indexOf(this.arrowData[key].end) > -1) {
            correctProperties[key].push("end");
          } else {
            wrongProperties[key].push("end");
          }
        }
        if ("angle" in this.arrowData[key]) {
          if (angles.indexOf(this.arrowData[key].angle) > -1) {
            correctProperties[key].push("angle");
          } else {
            wrongProperties[key].push("angle");
          }
        }
      }

      for (let key in wrongProperties) {
        if (wrongProperties[key].length === 0) {
          correctArrows.push(key);
        }
      }

      otherCorrectArrows = correctArrows;
      otherCorrectArrows = otherCorrectArrows.filter(
        x => x !== this.activatedPicture
      );

      if (correctArrows.indexOf(this.activatedPicture) > -1) {
        arrowIsCorrect = true;
      }

      /* else if (correctArrows.length > 0) {
        // alert(correctArrows);
        this.activatedPicture = "normal";
        // this.arrowData["friction"] = this.arrowData["normal"];
        arrowIsCorrect = true;
      } */

      // alert(onSpannedLines);

      return {
        start,
        end,
        transit,
        angle: angles,
        onSpannedLine: onSpannedLines,
        correctProperties,
        wrongProperties,
        correctArrows,
        otherCorrectArrows,
        arrowIsCorrect
      };
    },
    distance(P, Q) {
      // P=[x1,y2], Q=[x2,y2]
      return Math.sqrt(Math.pow(P[0] - Q[0], 2) + Math.pow(P[1] - Q[1], 2));
    },
    evaluateProjection(startArrow, endArrow, P) {
      let Q = [];
      Q[0] = P[0] - startArrow[0];
      Q[1] = P[1] - startArrow[1];
      let v = [];
      v[0] = endArrow[0] - startArrow[0];
      v[1] = endArrow[1] - startArrow[1];
      let length = Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2));

      let scalarProduct = (v[0] * Q[0] + v[1] * Q[1]) / (length * length);
      let isOnSection = scalarProduct > 0 && scalarProduct < 1;

      let projectionX = startArrow[0] + scalarProduct * v[0];
      let projectionY = startArrow[1] + scalarProduct * v[1];
      let projection = [projectionX, projectionY];
      return { isOnSection, projection };
    },
    generateArrowsErrorText(arrowEvaluation) {
      let feedback = [];

      if (arrowEvaluation === "too_short") {
        let message = this.$t("course_arrows", true);
        let feedback = { de: message.de.too_short, en: message.en.too_short };
        this.messageData = this.generateMessage(feedback, "error");
        this.messageIsShown = true;
        return;
      }

      /* if (arrowEvaluation === "too_short") {
        feedback = this.$t("arrows").too_short;
      } else if (this.feedbackOtherArrow.length > 0) {
        for (let i = 0; i < this.feedbackOtherArrow.length; i++) {
          if (typeof this.feedbackOtherArrow[i] === "string") {
            feedback += this.feedbackOtherArrow[i];
          } else if ("otherArrow" in this.feedbackOtherArrow[i]) {
            feedback += this.t(
              this.arrowData[arrowEvaluation.otherCorrectArrows[0]].name
            );
          } else if ("arrow" in this.feedbackOtherArrow[i]) {
            feedback += this.t(this.arrowData[this.activatedPicture].name);
          }
        }
      } */

      // Change !!!!!!!!
      // // // this.a(arrowEvaluation);

      // satisfied: [{ start: ["B", "D"] }, { end: "C" }]
      // notSatisfied: [{ angle: "a" }],

      let isFirstNonConcatenate = true;
      if (
        this.arrowData[this.activatedPicture].errorMessages &&
        this.arrowData[this.activatedPicture].errorMessages.length > 0
      ) {
        for (
          let i = 0;
          i < this.arrowData[this.activatedPicture].errorMessages.length;
          i++
        ) {
          let requirementsAreSatisfied1 = true;
          if (
            "satisfied" in
            this.arrowData[this.activatedPicture].errorMessages[i]
          ) {
            if (
              !Array.isArray(
                this.arrowData[this.activatedPicture].errorMessages[i].satisfied
              )
            ) {
              this.arrowData[this.activatedPicture].errorMessages[
                i
              ].satisfied = [
                this.arrowData[this.activatedPicture].errorMessages[i].satisfied
              ];
            }
            for (
              let j = 0;
              j <
              this.arrowData[this.activatedPicture].errorMessages[i].satisfied
                .length;
              j++
            ) {
              let values = Object.values(
                this.arrowData[this.activatedPicture].errorMessages[i]
                  .satisfied[j]
              )[0];
              if (typeof values === "string") {
                values = [values];
              }
              for (let k = 0; k < values.length; k++) {
                if (
                  arrowEvaluation[
                    Object.keys(
                      this.arrowData[this.activatedPicture].errorMessages[i]
                        .satisfied[j]
                    )[0]
                  ].indexOf(values[k]) === -1
                ) {
                  requirementsAreSatisfied1 = false;
                }
              }
            }
          }

          let requirementsAreSatisfied2 = true;
          if (
            "notSatisfied" in
            this.arrowData[this.activatedPicture].errorMessages[i]
          ) {
            if (
              !Array.isArray(
                this.arrowData[this.activatedPicture].errorMessages[i]
                  .notSatisfied
              )
            ) {
              this.arrowData[this.activatedPicture].errorMessages[
                i
              ].notSatisfied = [
                this.arrowData[this.activatedPicture].errorMessages[i]
                  .notSatisfied
              ];
            }
            for (
              let j = 0;
              j <
              this.arrowData[this.activatedPicture].errorMessages[i]
                .notSatisfied.length;
              j++
            ) {
              let values = Object.values(
                this.arrowData[this.activatedPicture].errorMessages[i]
                  .notSatisfied[j]
              )[0];
              if (typeof values === "string") {
                values = [values];
              }
              for (let k = 0; k < values.length; k++) {
                if (
                  arrowEvaluation[
                    Object.keys(
                      this.arrowData[this.activatedPicture].errorMessages[i]
                        .notSatisfied[j]
                    )[0]
                  ].indexOf(values[k]) > -1
                ) {
                  requirementsAreSatisfied2 = false;
                }
              }
            }
          }

          if (requirementsAreSatisfied1 && requirementsAreSatisfied2) {
            if (
              "concatenate" in
                this.arrowData[this.activatedPicture].errorMessages[i] &&
              this.arrowData[this.activatedPicture].errorMessages[i].concatenate
            ) {
              feedback.push(
                this.arrowData[this.activatedPicture].errorMessages[i].message
              );
            } else if (isFirstNonConcatenate) {
              feedback.push(
                this.arrowData[this.activatedPicture].errorMessages[i].message
              );
              isFirstNonConcatenate = false;
            }
          }
        }
      }

      if (this.isDefined(this.errorMessages)) {
        for (let i = 0; i < this.errorMessages.length; i++) {
          let requirementsAreSatisfied1 = true;
          if ("satisfied" in this.errorMessages[i]) {
            if (!Array.isArray(this.errorMessages[i].satisfied)) {
              this.errorMessages[i].satisfied = [
                this.errorMessages[i].satisfied
              ];
            }
            for (let j = 0; j < this.errorMessages[i].satisfied.length; j++) {
              let values = Object.values(this.errorMessages[i].satisfied[j])[0];
              if (typeof values === "string") {
                values = [values];
              }
              for (let k = 0; k < values.length; k++) {
                if (
                  arrowEvaluation[
                    Object.keys(this.errorMessages[i].satisfied[j])[0]
                  ].indexOf(values[k]) === -1
                ) {
                  requirementsAreSatisfied1 = false;
                }
              }
            }
          }

          let requirementsAreSatisfied2 = true;
          if ("notSatisfied" in this.errorMessages[i]) {
            if (!Array.isArray(this.errorMessages[i].notSatisfied)) {
              this.errorMessages[i].notSatisfied = [
                this.errorMessages[i].notSatisfied
              ];
            }
            for (
              let j = 0;
              j < this.errorMessages[i].notSatisfied.length;
              j++
            ) {
              let values = Object.values(
                this.errorMessages[i].notSatisfied[j]
              )[0];
              if (typeof values === "string") {
                values = [values];
              }
              for (let k = 0; k < values.length; k++) {
                if (
                  arrowEvaluation[
                    Object.keys(this.errorMessages[i].notSatisfied[j])[0]
                  ].indexOf(values[k]) > -1
                ) {
                  requirementsAreSatisfied2 = false;
                }
              }
            }
          }

          if (requirementsAreSatisfied1 && requirementsAreSatisfied2) {
            if (
              "concatenate" in this.errorMessages[i] &&
              this.errorMessages[i].concatenate
            ) {
              feedback.push(this.errorMessages[i].message);
            } else if (isFirstNonConcatenate) {
              feedback.push(this.errorMessages[i].message);
              isFirstNonConcatenate = false;
            }
          }
        }
      }

      if (feedback.length > 0) {
        this.messageData = this.generateMessage(feedback, "error");
        // this.setTimeout(() => {
        this.messageIsShown = true;
        // }, delay);
      }
    },
    autoSolveArrows() {
      for (let i = 0; i < this.deactivatedPictures.length; i++) {
        this.drawArrow(this.deactivatedPictures[i], {}, true, true);
      }
    },
    showHint() {
      if (this.activatedPicture === -1) {
        return;
      }

      if ("hint" in this.arrowData[this.activatedPicture]) {
        this.hintWasRequested = true;
        let feedback = this.arrowData[this.activatedPicture].hint;
        this.messageData = this.generateMessage(feedback);
        this.messageIsShown = true;
        this.playAudio("whoosh");
      }
    },
    solveCurrentArrow() {
      if (this.activatedPicture === -1) {
        return;
      }
      this.playPictureAudio();
      this.deactivatedPictures.push(this.activatedPicture);
      this.hintWasRequested = false;
      this.autoSolveArrows();
      this.messageIsShown = false;
      let rightCaption = document.querySelector(".picture__figure-caption--2");
      rightCaption.style.transition = "opacity 0.5s";
      rightCaption.style.opacity = 0;
      setTimeout(() => {
        if (!this.arrowPartIsFinished && !this.continueButtonIsShown) {
          this.textRightSide = this.content.selectionInstructionProgress;
        } else {
          this.textRightSide = "";
        }
        rightCaption.style.transition = "opacity 0.25s";
        rightCaption.style.opacity = 1;
      }, 1250);
      this.playAudio("correct");
      this.$store.dispatch("setExerciseStatus", [this.arrowNumber, 3]);
      //  this.$store.dispatch("setExerciseStatus", [this.arrowNumber, 4]);
      this.$store.dispatch("addExerciseData", [
        "deactivatedPictures",
        this.deactivatedPictures
      ]);
      if (
        // this.deactivatedPictures.length === Object.keys(this.arrowData).length
        // this.deactivatedPictures.length ===
        this.deactivatedPictures.length >=
        Object.keys(this.correctArrows).length
      ) {
        for (let key in this.arrowData) {
          if (!(key in this.deactivatedPictures)) {
            this.deactivatedPictures.push(key);
          }
        }
        this.$store.dispatch("addExerciseData", ["arrowPartIsFinished", true]);
        setTimeout(() => {
          this.continueButtonIsShown = true;
        }, 700);
      }

      this.showSuccessMessage(this.activatedPicture, true);
      this.activatedPicture = -1;
    }
  },
  created() {
    if (this.$store.state.windowWidth > 600) {
      this.partOfExercise = 2;
    }

    document.body.style.position = "initial";
    document.body.style.overflow = "initial";

    let html = document.getElementsByTagName("html")[0];

    this.scaleFactor = this.content.scaleFactor || 1;
    this.scaleOfSolution = this.content.scaleOfSolution || 1.5;

    if (this.$store.state.windowWidth > 600) {
      this.scaleFactor2 =
        this.scaleFactor *
        (Math.min(this.$store.state.windowWidth, 1024) / 1024);
    } else {
      this.scaleFactor2 = this.scaleFactor;
    }

    html.style.setProperty("--scale-of-picture", this.scaleFactor2);
    this.scaleOfSolution2 =
      this.scaleOfSolution *
      (Math.min(this.$store.state.windowWidth, 1024) / 1024);
    html.style.setProperty(
      "--scale-of-picture-2",
      1.5 * this.scaleOfSolution2 + "px"
    );

    if (this.content.textLeftSide !== undefined) {
      this.textLeftSide = this.content.textLeftSide;
    }
    if (this.content.textRightSide !== undefined) {
      this.textRightSide = this.content.textRightSide;
    }
    if (
      this.content.textLeftSide === undefined &&
      this.content.variation === "freeBody"
    ) {
      this.textLeftSide = this.$t("course_picture").original;
    }
    if (
      this.content.textRightSide === undefined &&
      this.content.variation === "freeBody"
    ) {
      this.textRightSide = this.$t("course_picture").free_body;
    }

    this.correctAnswer = this.content.correctAnswer;
    this.textDraggables = this.content.textDraggables || [];
    // this.textAdditional = this.content.textAdditional || [];
    this.endAudio = this.content.endAudio || false;
    this.feedbackMissingElements = this.content.feedbackMissingElements;
    this.feedbackAdditionalElements = this.content.feedbackAdditionalElements;
    this.svgSize[0] = this.content.pictureWidth || 200;
    this.svgSize[1] = this.content.pictureHeight || 200;

    html.style.setProperty("--svg-width", this.svgSize[0] + "px");
    html.style.setProperty("--svg-height", this.svgSize[1] + "px");

    this.rightShifts = this.content.rightShifts || {};
    this.topShifts = this.content.topShifts || {};

    // Create draggable element
    let draggables = this.content.draggables;
    for (let i = 0; i < draggables.length; i++) {
      let svgString = draggables[i];
      svgString = svgString.trim();
      let letterIndex = 0;
      for (let i = 0; i < svgString.length; i++) {
        if (svgString[svgString.length - 1 - i] === "/") {
          if (svgString[svgString.length - 2 - i] === "<") {
            letterIndex = i + 1;
            break;
          } else {
            letterIndex = i;
            break;
          }
        }
      }
      let svgStringPart1 =
        svgString.slice(0, svgString.length - letterIndex - 1) + " ";
      let svgStringPart2 = svgString.slice(svgString.length - letterIndex - 1);
      let newSvgString =
        svgStringPart1 +
        " class='picture__draggable' id='draggable--" +
        i +
        "' " +
        svgStringPart2;
      let newSvgString2 =
        svgStringPart1 +
        " class='picture__draggable picture__draggable2'" +
        svgStringPart2;

      if (
        "enlargedDraggables" in this.content &&
        // this.content.enlargedDraggables.indexOf(i) > -1
        i in this.content.enlargedDraggables
      ) {
        let string = " picture__draggable--" + i + "'";

        newSvgString2 =
          svgStringPart1 +
          " class='picture__draggable picture__draggable2" +
          string +
          svgStringPart2;

        let html = document.getElementsByTagName("html")[0];
        html.style.setProperty(
          "--stroke-width-" + i,
          this.content.enlargedDraggables[i] + "px"
        );
      }

      draggables[i] = newSvgString2 + newSvgString;
      // draggables[i + 10] = newSvgString2;

      /* let html = document.getElementsByTagName("html")[0];
      html.style.setProperty(
        "--stroke-width-" + i,
        this.content.enlargedDraggables[i] + "px"
      ); */
    }
    this.draggables = draggables;
    // alert(this.draggables);

    setTimeout(() => {
      for (let i = 0; i < this.textDraggables.length; i++) {
        if (
          "number" in this.textDraggables[i] &&
          this.textDraggables[i].number > this.draggables.length - 1
        ) {
          this.draggables[this.textDraggables[i].number] =
            "<rect x='" +
            this.textDraggables[i].position[0] +
            "' y='" +
            this.textDraggables[i].position[1] +
            "' width='0.1' height='0.1' fill='none' opacity='0' class='picture__draggable' id='draggable--" +
            this.textDraggables[i].number +
            "'/>";

          /* setTimeout(() => {
            let text = document.querySelector(
              ".picture__draggable-text-span--" + this.textDraggables[i].number
            );
            let xPosition =
              this.textDraggables[i].position[0] + this.width(text) / 2;
            let yPosition =
              this.textDraggables[i].position[1] + this.height(text) / 2;
            this.draggables[this.textDraggables[i].number] =
              "<rect x='" +
              xPosition +
              "' y='" +
              yPosition +
              "' width='0.1' height='0.1' fill='none' opacity='0' class='picture__draggable' id='draggable--" +
              this.textDraggables[i].number +
              "'/>";

            this.typesetMath();
          }, 100); */
        }
      }
      this.typesetMath();

      setTimeout(() => {
        // document.querySelector(".picture__picture-draggable").style.opacity = 1;
        this.draggableIsInvisible = false;
      }, 0);
    }, 0);

    // Set start ccordinates of dragging elements
    this.coordinates = this.content.coordinates || [];
    if (this.coordinates.length === 0) {
      for (let i = this.coordinates.length; i < this.draggables.length; i++) {
        this.coordinates[i] = [0, 1, 0, 1];
      }
    }

    // Get cooordinates of dragging elements
    /* setTimeout(() => {
      for (let i = 0; i < this.content.draggables.length; i++) {
        let draggable = document.querySelector(
          ".draggable-inner-element--" + i
        );
        let picture = document.querySelector(".picture__picture-draggable");
        this.coordinates[i] = [
          (1 / this.scaleFactor) * (this.left(draggable) - this.left(picture)),
          (1 / this.scaleFactor) * (this.right(draggable) - this.left(picture)),
          (1 / this.scaleFactor) * (this.top(draggable) - this.top(picture)),
          (1 / this.scaleFactor) * (this.bottom(draggable) - this.top(picture))
        ];
      }
      this.a(this.coordinates);
    }, 2000); */

    /* this.exerciseIsFinished = true;
    this.droppedElements = this.content.correctAnswer;
    this.pictureIsSmall = true;
    this.reOrderDroppedElements(true, false, true);
    this.additionalElementIsVisible = true;
    this.textRightSide = this.content.selectionInstructionStart; */

    if ("subtype" in this.content && this.content.subtype === "arrows") {
      this.exerciseType = "arrows";
      this.exerciseIsFinished = true;
      this.droppedElements = this.content.correctAnswer;
      this.pictureIsSmall = true;
      this.reOrderDroppedElements(true, false, true);
      this.additionalElementIsVisible = true;
      this.textRightSide = this.content.selectionInstructionStart;
      this.arrowData = this.content.arrowData;
      this.successMessageArrowsFinal =
        this.content.successMessageArrowsFinal || "";
      this.chooseArrowMessage = this.content.chooseArrowMessage || "";

      html.style.setProperty("--scale-of-picture", this.scaleOfSolution);

      // Complete arrow data
      for (let key in this.arrowData) {
        if (!("name" in this.arrowData[key])) {
          let newName = {};
          let nameData = this.$t("course_arrows", true);

          for (let language in nameData) {
            if (
              "names" in nameData[language] &&
              nameData[language]["names"][key] !== undefined
            ) {
              newName[language] = nameData[language]["names"][key];
            } else if (
              "names" in nameData["de"] &&
              nameData["de"]["names"][key] !== undefined
            ) {
              newName[language] = nameData["de"]["names"][key];
            } else {
              newName[language] = "";
            }
          }
          this.arrowData[key].name = newName;
        }

        if (!("icon" in this.arrowData[key])) {
          this.arrowData[key].icon = key;
        }
      }
      //////////

      let pointsCopy = JSON.parse(JSON.stringify(this.content.points));
      if (matchMedia("(hover: none)").matches) {
        // not supported on Android!
        if (pointsCopy === undefined) {
          return;
        }

        for (let point in pointsCopy) {
          if ("r" in pointsCopy[point]) {
            pointsCopy[point]["r"] = 2 * pointsCopy[point]["r"];
          }
        }
      }
      this.points = pointsCopy;
      this.angles = this.content.angles;

      this.deactivatedPictures = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "deactivatedPictures",
        []
      ]);
      if (this.deactivatedPictures.length > 0) {
        this.partOfExercise = 2;
      }

      this.arrowPartIsFinished = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "arrowPartIsFinished",
        false
      ]);

      if (this.deactivatedPictures.length > 0) {
        if (!this.arrowPartIsFinished && !this.continueButtonIsShown) {
          this.textRightSide = this.content.selectionInstructionProgress;
        } else {
          this.textRightSide = "";
        }
      }

      this.deleteUnfinishedArrows();
      setTimeout(() => {
        this.outerPictureContainer = document.querySelector(
          ".picture__picture-container"
        );

        this.autoSolveArrows();
      }, 0);

      if (this.arrowPartIsFinished) {
        this.continueButtonIsShown = true;
      }
    } else {
      this.exerciseIsFinished = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "exerciseIsFinished",
        false
      ]);
      if (this.exerciseIsFinished) {
        // this.pictureIsSmall = true;
        // this.reOrderDroppedElements(true, false, true);
        this.exerciseIsFinished = true;
        this.droppedElements = this.content.correctAnswer;
        this.pictureIsSmall = true;
        this.reOrderDroppedElements(true, false, true);
        this.additionalElementIsVisible = true;
      } else {
        this.droppedElements = this.o([
          this.$store.state.userDataLectures[this.$store.state.chosenLesson]
            .savedExerciseData[this.$store.state.coursePage],
          "droppedElements",
          []
        ]);
        this.reOrderDroppedElements(false, false, true);
      }

      this.deleteUnfinishedArrows();

      this.partOfExercise = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "partOfExercise",
        1
      ]);

      /* this.droppedElements = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "droppedElements",
        []
      ]); */
      /* this.exerciseIsFinished = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "exerciseIsFinished",
        false
      ]); */

      if (this.exerciseIsFinished) {
        this.continueButtonIsShown = true;
        this.additionalElementIsVisible = true;
        this.messageType = "success";
      } else {
        this.messageType = "mistake";
      }

      this.transitionIsSuspended = true;
      let messageIsShown = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "messageIsShown",
        false
      ]);
      if (messageIsShown) {
        if (this.exerciseIsFinished) {
          this.generateMessageText("success", 0);
        } else {
          this.generateMessageText("mistake", 0);
          this.evaluateButtonIsActive = false;
        }
      }
      this.setTimeout(() => {
        this.transitionIsSuspended = false;
        document.querySelector(".picture__continue-button").style.transition =
          "transform 0.75s";
      }, 100);

      /* if (this.exerciseIsFinished) {
        this.pictureIsSmall = true;
        this.reOrderDroppedElements(true, false, true);
      } else {
        this.reOrderDroppedElements(false, false, true);
      } */
    }

    /* if (!this.exerciseIsFinished) {
      this.reOrderDroppedElements(false, false, true);
    } */

    /* this.setTimeout(() => {
    document.querySelector(".picture").style.opacity = 1;
     }, 0); */

    window.addEventListener("mousemove", $event => this.progressDrag($event));
    window.addEventListener("touchmove", $event => this.progressDrag($event));
    window.addEventListener("mouseup", $event => this.stopDrag($event));
    window.addEventListener("touchend", $event => this.stopDrag($event));

    // if (this.exerciseType === "arrows") {
    this.resizeWidth = window.innerWidth;
    this.resizeHeight = window.innerHeight;

    /* let vm = this;
    function startResize() {
      setTimeout(() => {
        vm.resizeWindow();
      }, 3000);
    } */

    /* ["resize", "scroll"].forEach(evt =>
        window.addEventListener(
          evt,
          function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
              vm.organizeAfterResize();
              /* setTimeout(() => {
                vm.determineDistances();
              }, 0);
            }, 250);
          },
          false
        )
      );
    }, 0); // was: 300 */
    window.addEventListener("resize", this.resizeWindow);

    /* let resizeTimer;
    let vm = this;
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        vm.resizeWindow();
        setTimeout(() => {
          vm.determineDistances();
        }, 0);
      }, 2500);
    }); */

    // window.addEventListener("resize", startResize);
    // window.addEventListener("resize", vm.determineDistances, false);
    //  }

    window.addEventListener("orientationchange", this.orientationChange);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  watch: {
    exerciseIsReset(to) {
      if (to) {
        this.clearAllTimeouts();
        this.resetExercise();
      }
    },
    language() {
      if (this.messageIsShown) {
        this.generateMessageText(this.messageType);
      }
      if (
        this.content.textLeftSide === undefined &&
        this.content.variation === "freeBody"
      ) {
        this.textLeftSide = this.$t("course_picture").original;
      }
      if (
        this.exerciseType !== "arrows" &&
        this.content.textRightSide === undefined &&
        this.content.variation === "freeBody"
      ) {
        this.textRightSide = this.$t("course_picture").free_body;
      }
    },
    partOfExercise(to) {
      this.$store.dispatch("addExerciseData", ["partOfExercise", to]);
    },
    textAdditional() {
      this.typesetMath();
    },
    messageIsShown(to) {
      this.$store.dispatch("addExerciseData", ["messageIsShown", to]);

      if (!to) {
        this.wrongPicture = -1;
      }
    },
    windowWidth() {
      let html = document.getElementsByTagName("html")[0];
      this.scaleFactor2 =
        this.scaleFactor *
        (Math.min(this.$store.state.windowWidth, 1024) / 1024);
      html.style.setProperty("--scale-of-picture", this.scaleFactor2);
      // alert(1);

      this.scaleOfSolution2 =
        this.scaleOfSolution *
        (Math.min(this.$store.state.windowWidth, 1024) / 1024);
      html.style.setProperty(
        "--scale-of-picture-2",
        1.5 * this.scaleOfSolution2 + "px"
      );
    }
  }
};
</script>

<style lang="scss">
@import "sass/main.scss";

.picture {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 150vh;
  padding-top: max(11rem, min(13.75rem, 10vh));
  color: $color-white;
  background-color: #131313; // !!!
  overflow-x: hidden; // // // !!!

  @include grid-template-columns;
  grid-template-rows: repeat(6, min-content) 1fr 1fr;

  @include respond(phone) {
    background-color: $color-black;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 100vw;
  }

  &--part-1 {
    @include respond(phone) {
      &::before {
        // Hide arrow at beginning
        @include pseudo-element;
        z-index: 10000;
        width: 3rem;
        height: 10rem;
        background-color: $color-black;
      }
    }
  }

  /* &--scroll-released {
    overflow-x: hidden;
  } */

  &__heading {
    margin-top: 11.5rem;
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-weight: 400;
    text-align: center;

    @include respond(tab-land) {
      margin-bottom: 3.78125rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      margin-top: 10.65rem;
      margin-bottom: 3.6rem;

      text-align: center;
      font-size: 4rem;
    }
  }

  &__instructions {
    width: min(80rem, 100vw - 2 * #{$side-margin});
    grid-column: 3 / 4;
    justify-self: center;
    margin-bottom: 6.5rem;
    font-size: 2.6rem;
    text-align: center;

    @include respond(tab-land) {
      width: min(80rem, 100vw - 2 * #{$side-margin-land});
      margin-bottom: 4.125rem;
    }

    @include respond(phone) {
      width: 100%;
      text-align: center;
      line-height: 1.4;
    }
  }

  &__handling-desktop {
    @include respond(phone) {
      display: none;
    }
  }

  &__handling-phone {
    display: none;

    @include respond(phone) {
      display: inline;
    }
  }

  &__picture-container {
    position: relative;
    grid-column: 3 / 4;
    height: min(51.2rem, 50vw - 3rem);
    background-color: $color-black;
    border-radius: 0.6rem;
    user-select: none;

    overflow: hidden;

    .picture--arrows-type & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      height: 36rem;
      border-radius: 0; //

      transform: translateY(-0.75rem); //

      overflow-x: initial;

      .picture--arrows-type & {
        overflow-x: hidden;
      }

      &::before {
        @include pseudo-element;
        left: 0;
        top: 0.75rem;
        margin-bottom: -13.6rem;
        width: 100vw;
        height: calc(21.6vh - 0.75rem);
        border-radius: 1rem;
        background-color: #131313;
        opacity: 0;

        .picture--small-picture-is-hidden & {
          content: initial;
        }
      }

      .picture--part-1.picture--arrows-type & {
        @include respond(phone) {
          transform: translateY(-26rem);
          width: 100vw;
          height: calc(100vh - 21rem);
          background-color: transparent;
        }
      }
    }

    .picture--part-1 &::before {
      @include respond(phone) {
        content: initial;
      }
    }

    .picture--finished &::before {
      @include respond(phone) {
        opacity: 1;
        transition: opacity 0.333s 1.083s;
      }
    }

    &::after {
      @include pseudo-element;
      left: calc(50% - 0.5px);
      width: 1px;
      height: 100%;
      background-color: #333;

      @include respond(phone) {
        content: initial;
      }

      .picture--finished & {
        opacity: 0;
        transition: opacity 0.333s 0.75s;

        @include respond(phone) {
          opacity: 1;
          transform: translateY(-16.6vh);
          transition: transform 0.333s 0.75s;
        }
      }
    }

    &--2 {
      @include respond(phone) {
        margin-top: 6.75rem;
        height: calc(100vh - 21rem);

        &::after {
          content: "";
          left: -0.75rem;
          top: calc(50% - 0.375rem);
          width: 100vw;
          height: 1px;
          background-color: #222;

          .picture--small-picture-is-hidden & {
            content: initial;
          }
        }
      }
    }
  }

  &__picture {
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--svg-width);
    height: var(--svg-height);
    z-index: 1;
    line-height: 1;
    /* transform: translate(calc(-50% - 0.25 * #{$width-center}), -50%)
      scale(var(--scale-of-picture)); */
    transform: translate(
        calc(-50% - 0.25 * min(#{$width-center}, 100vw - 6rem)),
        -50%
      )
      scale(var(--scale-of-picture));
    background-color: transparent;
    pointer-events: none;
    will-change: transform;

    // background-color: rgba(green, 0.3);

    .picture--small-picture-is-hidden & {
      left: calc(50% - 0.5 * min(25.6rem, 25vw - 1.5rem));
      top: calc(50% - 3rem);

      @include respond(phone) {
        left: 50%;
        top: 50%;
      }
    }

    @include respond(phone) {
      // top: 20%;
      transform: translate(-50%, calc(-50% - 25vh + 8.7rem))
        scale(var(--scale-of-picture));
      // background-color: $color-black;

      .picture--small-picture-is-hidden.picture--part-2 & {
        top: calc(50% - 15rem); /////
      }

      .picture--part-1 & {
        transform: translate(-50%, -50%) scale(var(--scale-of-picture));
      }

      .picture__picture-container--2 & {
        // transform: translate(-50%, calc(-50% - 25vh + 8.7rem))
        // scale(var(--scale-of-picture)); // !!!!!!!!!!!!!!!!!!
      }
    }

    .picture--arrows-type & {
      pointer-events: initial;
    }

    &--text {
      z-index: 1000;
      display: inline-block;
      transform: translate(calc((-50%) - 0.25 * #{$width-center}), -50%);

      // background-color: orange;

      & * {
        // pointer-events: none;
      }
    }
  }

  &__picture-text-container {
    position: relative;
    transform: translateY(-100%);
    width: 100%;
    height: 100%;
  }

  &__picture-text {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    line-height: 1;

    pointer-events: none;
  }

  &__invisible {
    @include respond(phone) {
      display: none;
    }
  }

  .picture--finished &__on-left-side {
    display: none;
  }

  &__wrong {
    animation: shake 0.667s ease-in-out;
  }

  &__picture-draggable {
    z-index: 20;

    @include respond(phone) {
      .picture--part-1:not(.picture--arrows-type) & {
        display: none;
      }
    }

    &--invisible {
      opacity: 0;
    }
  }

  &__picture--is-small {
    box-sizing: content-box;
    transform: translate(
        max(-50vw + 4rem - 0.5 * 50%, -50.2rem - 0.5 * 50%),
        calc(max(-24.6rem, -25vw + 2.5rem) - 0.5 * 50%)
      )
      scale(0.5);
    padding: 7.5rem;
    border-radius: 1.2rem;
    background-color: #171717;

    transition: transform 0.333s ease-in-out,
      background-color 0.333s ease-in-out;

    .picture--small-picture-is-hidden & {
      display: none;
      // background-color: green;
    }

    @include respond(phone) {
      transform: translate(calc(-50%), calc(-50% - 25vh - 3rem)) scale(0.5) !important;
      background-color: transparent;
    }

    .picture--part-1.picture--arrows-type & {
      @include respond(phone) {
        visibility: hidden;
      }
    }
  }

  &__additional-element {
    opacity: 0;

    &--visible {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &__additional-text {
    z-index: 1000;
    // transform: translateZ(1px);
  }

  &__figure-caption {
    position: absolute;
    left: 2.15rem;
    top: 1.8rem;
    z-index: 10;
    font-family: $font-primary-bold;
    font-size: 2rem;
    line-height: 1;
    will-change: transform;

    .picture--arrows-type &--2 {
      display: none;
    }

    @include respond(phone) {
      visibility: hidden;
      left: 0.75rem;
      font-size: 2.2rem;

      .picture__picture-container--2 & {
        visibility: visible;
      }

      .picture--arrows-type &--2 {
        display: block;
        transition: opacity 0.5s;
      }
    }

    &::selection {
      background-color: transparent;
    }

    .picture--finished & {
      transform: translateZ(1px);

      @include respond(phone) {
        transform: none;
      }
    }

    &--2 {
      left: min(53.2rem, 50vw - 1rem);

      @include respond(phone) {
        left: 0.75rem;
        top: calc(50vh - 8.375rem);
      }
    }

    &--at-top {
      opacity: 0;
      transition: opacity 0.333s;

      @include respond(phone) {
        opacity: 1;
        transform: translateY(-16.6vh) scale(1.3) !important;
        transform-origin: left;
        transition: transform 0.333s ease-in-out;
      }
    }
  }

  &__draggable {
    // // // fill: transparent;

    cursor: pointer;
    pointer-events: auto;

    // stroke-width: 20px;
    // stroke: transparent !important;
    // stroke: initial;
    stroke: white;

    fill: transparent;
    z-index: 100;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        stroke: $color-primary-dark !important;
        fill: $color-primary-dark !important;

        transition: stroke 0.2s, fill 0.2s;
      }
    }

    .picture--finished &:hover {
      stroke: white !important;
      fill: transparent !important;
      // stroke-width: initial !important;
      cursor: initial !important;
    }

    .picture__correct :not(.picture__on-left-side) & {
      stroke: forestgreen !important;
      fill: green !important;
    }

    &2 {
      // stroke-width: 30px;
      stroke: transparent;
      // stroke: red;
      // fill: pink;
      fill: transparent; //
      z-index: 10;
      // display: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          stroke: transparent !important;
          fill: transparent !important;
        }
      }

      .picture--finished &:hover {
        stroke: transparent !important;
        // // // fill: transparent !important;
      }

      .picture__correct :not(.picture__on-left-side) & {
        stroke: transparent !important;
      }
    }

    /* &3 {
      // stroke-width: 30px;
      stroke-width: var(--stroke-width);
    } */
  }

  &__draggable {
    &--0 {
      stroke-width: var(--stroke-width-0);
    }
    &--1 {
      stroke-width: var(--stroke-width-1);
    }
    &--2 {
      stroke-width: var(--stroke-width-2);
    }
    &--3 {
      stroke-width: var(--stroke-width-3);
    }
    &--4 {
      stroke-width: var(--stroke-width-4);
    }
    &--5 {
      stroke-width: var(--stroke-width-5);
    }
    &--6 {
      stroke-width: var(--stroke-width-6);
    }
    &--7 {
      stroke-width: var(--stroke-width-7);
    }
    &--8 {
      stroke-width: var(--stroke-width-8);
    }
    &--9 {
      stroke-width: var(--stroke-width-9);
    }
    &--10 {
      stroke-width: var(--stroke-width-10);
    }
    &--11 {
      stroke-width: var(--stroke-width-11);
    }
    &--12 {
      stroke-width: var(--stroke-width-12);
    }
    &--13 {
      stroke-width: var(--stroke-width-13);
    }
    &--14 {
      stroke-width: var(--stroke-width-14);
    }
    &--15 {
      stroke-width: var(--stroke-width-15);
    }
    &--16 {
      stroke-width: var(--stroke-width-16);
    }
    &--17 {
      stroke-width: var(--stroke-width-17);
    }
    &--18 {
      stroke-width: var(--stroke-width-18);
    }
    &--19 {
      stroke-width: var(--stroke-width-19);
    }
    &--20 {
      stroke-width: var(--stroke-width-20);
    }
    &--21 {
      stroke-width: var(--stroke-width-21);
    }
    &--22 {
      stroke-width: var(--stroke-width-22);
    }
    &--23 {
      stroke-width: var(--stroke-width-23);
    }
    &--24 {
      stroke-width: var(--stroke-width-24);
    }
    &--25 {
      stroke-width: var(--stroke-width-25);
    }
  }

  &__wrong &__on-right-side &__draggable {
    // stroke: lightsalmon !important;
    stroke: $color-wrong !important;
    stroke-width: initial;
    fill: $color-wrong !important;
  }

  &__wrong &__on-right-side &__picture-draggable {
    color: $color-wrong !important;
  }

  text {
    pointer-events: none;
    transition: color 0.2s, stroke 0.2s, fill 0.2s;

    &::selection {
      background-color: transparent;
    }
  }

  .picture__draggable-text-span--dark-fill {
    color: $color-black;
  }

  &__selection-container {
    display: none;
    grid-column: 3 / 4;
    z-index: 1;
    height: 100%;
    padding: 1.125rem;
    padding-bottom: 0;
    background-color: #1a1a1a;
    border-top: 1px solid #1d1d1d;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;

    .picture--arrows-type & {
      display: block;

      @include respond(phone) {
        position: absolute;
        grid-column: 1 / 6;
        transform: translateY(calc(100 * var(--vh) - 7.5rem));
        padding: 1.125rem;
        padding-top: 4vw;
        padding-bottom: 4vw;
        height: initial;
        width: 100vw;
        background-color: #131313;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 4vw;

        transition: transform 0.75s;
      }
    }

    .picture--arrows-type &--shown {
      @include respond(phone) {
        transform: translateY(calc(100 * var(--vh) - 7.5rem - 100%));
      }
    }

    .picture--small-picture-is-hidden.picture--arrows-type & {
      @include respond(phone) {
        transform: translateY(calc(100 * var(--vh) - 7.5rem - 100%)) !important;
      }
    }

    .picture--small-picture-is-hidden.picture--arrows-type.picture--continue-button-is-shown
      & {
      @include respond(phone) {
        transform: translateY(
          calc(100 * var(--vh) - 14.5rem - 100%)
        ) !important;
      }
    }

    .picture--part-1 & {
      display: none;
    }
  }

  &__show-selection-button {
    position: absolute;
    top: -3.5rem;
    width: 100vw !important;
    height: 6rem;
    border-radius: 0.6rem !important;
    font-size: 2.4rem !important;

    .picture--small-picture-is-hidden & {
      display: none;
    }
  }

  &__hint-button-container {
    display: none;
    position: relative;
    grid-column: 3 / 4;
    z-index: 1;
    padding: 0.85rem 0 0;

    .picture--arrows-type & {
      display: block;

      @include respond(phone) {
        position: absolute;
        left: -0.75rem;
        top: 0;
        z-index: initial;
        width: 100vw;
        transform: translateY(calc(100 * var(--vh) - 100% - 14.25rem));
        transition: transform 0.75s;
      }
    }

    .picture--arrows-type.picture--small-picture-is-hidden & {
      @include respond(phone) {
        transform: translateY(calc(100 * var(--vh) - 100% - 27.125rem));
      }
    }
  }

  &__hint-button-inner-container {
    float: right;

    @include respond(phone) {
      float: initial;
    }
  }

  &__hint-button {
    display: inline-block;
    transform: none !important;
    border-radius: 0.6rem !important;
    font-size: 2rem !important;

    &:first-child {
      margin-right: 0.75rem;
    }

    @include respond(phone) {
      width: calc(50% - 0.375rem) !important;
      height: 6rem;
      font-size: 2.2rem !important;
    }

    &:nth-child(2) {
      @include respond(phone) {
        float: right;
      }
    }
  }

  &__arrow-container {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 30000000;
    pointer-events: none;

    transition: opacity 0.2s;
    &--dark {
      filter: brightness(0.5);
    }
  }

  &__arrow-inner-container {
    position: relative;

    &--wrong {
      animation: shake 0.667s ease-in-out;
    }
  }

  &__arrow {
    position: absolute;
    // width: var(--scale-of-picture-2);
    width: 2.5px;
    height: 0;
    z-index: 10000;
    background-color: #d00;
    border-radius: 10rem;

    .picture__arrow-inner-container--wrong & {
      background: repeating-linear-gradient(
        to top,
        $color-wrong,
        $color-wrong 3.5px,
        #000 3.5px,
        #000 10px
      );

      &::after {
        @include pseudo-element;
        left: -2.5rem;
        top: -2.5rem;
        width: 5rem;
        height: calc(100% + 5rem);
        border-radius: 100rem;
        background-color: rgba($color-wrong, 0.175);
      }
    }
  }

  &__arrow-tip {
    // visibility: hidden;
    position: absolute;
    left: -0.375rem;
    top: calc(100% - 0.6rem);
    transform: scaleY(-1) rotate(2deg) !important;
    // opacity: 0.5;

    .picture__arrow-inner-container--wrong & * {
      fill: $color-wrong;
    }
  }

  &__arrow-caption {
    display: none;
    position: absolute;
    z-index: 1000;
    font-size: 2rem;
    line-height: 1;
    color: #d00;
    user-select: none;
    pointer-events: none;

    &--dark {
      filter: brightness(0.5);
    }
  }

  &__start-button {
    position: fixed;
    left: 0;
    top: 0;

    transform: translateY(
      calc(var(--vh, 1vh) * 100 - 13.5rem + 1px)
    ) !important;
    width: 100vw !important;
    height: 6rem;
    font-size: 2.4rem !important;
    background-color: $color-primary-dark !important;

    @include respond(phone) {
      transition: transform 0.75s !important;
    }
  }

  &__evaluate-button {
    grid-column: 3 / 4;
    margin-top: 2rem;
    transform: translateX(min(102.4rem - 100%, 100vw - 6rem - 100%)) !important;
    padding: 0.85rem 2.4rem !important;

    @include respond(phone) {
      position: fixed;
      grid-column: 1 / 6;
      left: 0;
      top: 0;
      margin-top: 0;
      transform: translateY(
        calc(var(--vh, 1vh) * 100 - 13.5rem + 1px)
      ) !important;
      width: 100vw !important;
      height: 6rem;
      font-size: 2.6rem !important;

      transition: transform 0.75s;
    }

    &--hidden {
      display: none;
    }
  }

  &__continue-button {
    visibility: hidden;
    transform: translateX(min(102.4rem - 100%, 100vw - 6rem - 100%)) !important;
    padding: 0.85rem 2.4rem !important;

    @include respond(phone) {
      transform: translateY(
        calc(var(--vh, 1vh) * 100 - 6.5rem + 1px)
      ) !important;
      transition: transform 0.75s;
    }

    &--shown {
      visibility: visible;

      @include respond(phone) {
        transform: translateY(
          calc(var(--vh, 1vh) * 100 - 13.5rem + 1px)
        ) !important;
        transition: transform 0.75s;
      }
    }
  }

  &__message-container {
    position: fixed;
    visibility: hidden;
    grid-column: 3 / 4;
    left: calc(50vw - 0.5 * #{$width-center});
    top: 0;
    z-index: 10000;
    // transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 6.75rem));
    transform: translateY(calc(var(--vh, 1vh) * 100 - 6.75rem));
    width: $width-center;
    transition: transform 0.75s;
    user-select: none;

    @include respond(tab-land) {
      left: 3rem;
      max-width: calc(100vw - 6rem);
    }

    @include respond(phone) {
      left: 0;
      max-width: 100%;
      width: 100%;
    }

    &--is-shown {
      visibility: visible;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 7.5rem));

      @include respond(phone) {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
      }
    }

    &--mistake {
      // transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 7.5rem));
      ////// transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 6.75rem));
    }

    &--with-selection-container.picture__message-container {
      @include respond(phone) {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 7.5rem));
      }
    }

    &--with-continue-button.picture__message-container {
      transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
    }
  }

  &__message {
    //  background-color: blue !important;
    // transform: translateY(-30rem) !important;

    &--shown {
      //  transform: translateY(-50rem) !important;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}

.dark-fill {
  transition: fill 0.2s;
  color: black;

  .picture__wrong .picture__on-right-side & {
    color: $color-black;
  }
}

.fade-out {
  .picture--finished & {
    opacity: 0;
    transition: opacity 0.333s 0.75s;
  }
}

.picture--arrows-type .fade-out-arrows {
  display: none;
}

.picture--no-transition * {
  transition: none !important;
}
</style>

<style lang="scss" scoped>
span {
  pointer-events: none !important;
}
</style>
