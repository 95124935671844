import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Views/Home.vue";

// import Course from "@/components/Views/Course.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () => import("@/components/Views/Imprint.vue")
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/components/Views/Terms.vue")
  },
  {
    path: "/data-protection",
    name: "data-protection",
    component: () => import("@/components/Views/DataProtection.vue")
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/components/Views/Account.vue")
  },
  {
    path: "/overview",
    name: "overview",
    component: () => import("@/components/Views/Overview.vue")
  },
  {
    path: "/course",
    name: "course",
    component: () => import("@/components/Views/Empty.vue")
  },
  {
    path: "/vocabulary",
    name: "vocabulary",
    component: () => import("@/components/Views/Vocabulary.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/components/Views/Contact.vue")
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/components/Views/Help.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Views/About.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Errorpage",
    // component: Home
    component: () => import("@/components/Views/Errorpage.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.querySelector(".app").scrollIntoView();

    document.body.style.position = "initial";
    document.body.style.overflow = "initial";

    let app = document.querySelector(".app");
    // app.style.height = "initial";
    setTimeout(() => {
      window.scrollTo(0, 0);
      app.scrollTo(0, 0);
    }, 0);
  }
});

export default router;
