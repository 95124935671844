<template>
  <teleport to="body">
    <div
      class="home"
      :class="{
        'home__modal-is-open':
          $store.state.shownModal !== 'none' ||
          $store.state.shownSidebarMenu !== 'none' ||
          $store.state.alertText !== ''
      }"
    >
      <app-hero></app-hero>
      <app-awards></app-awards>
      <app-introduction-1></app-introduction-1>
      <app-introduction-2></app-introduction-2>
      <app-introduction-3></app-introduction-3>
      <app-features></app-features>
      <app-testimonials-1></app-testimonials-1>
      <app-testimonials-2></app-testimonials-2>
      <app-testimonials-3></app-testimonials-3>
      <app-about-us></app-about-us>
      <app-plans></app-plans>
      <app-footer></app-footer>
    </div>
  </teleport>
</template>

<script>
import AppHero from "@/components/Templates/Hero.vue";
import AppAwards from "@/components/Templates/Awards.vue";
import AppIntroduction1 from "@/components/Templates/Introduction1.vue";
import AppIntroduction2 from "@/components/Templates/Introduction2.vue";
import AppIntroduction3 from "@/components/Templates/Introduction3.vue";
import AppFeatures from "@/components/Templates/Features.vue";
import AppTestimonials1 from "@/components/Templates/Testimonials1.vue";
import AppTestimonials2 from "@/components/Templates/Testimonials2.vue";
import AppTestimonials3 from "@/components/Templates/Testimonials3.vue";
import AppAboutUs from "@/components/Templates/AboutUs.vue";
import AppPlans from "@/components/Templates/Plans.vue";
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "Home",
  components: {
    AppHero,
    AppAwards,
    AppIntroduction1,
    AppIntroduction2,
    AppIntroduction3,
    AppFeatures,
    AppTestimonials1,
    AppTestimonials2,
    AppTestimonials3,
    AppAboutUs,
    AppPlans,
    AppFooter
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.home {
  position: relative;
  // z-index: -10;
  z-index: 1;
  transition: z-index 0s 0.5s;

  background-color: #000 !important;

  &__modal-is-open {
    @include respond(phone) {
      z-index: -1;
      transition: none;
    }
  }
}
</style>
