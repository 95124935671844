<template>
  <div class="multiple-choice">
    <h2 class="multiple-choice__heading">
      {{ t(content.heading) }}
    </h2>
    <p class="multiple-choice__instructions">
      {{ t(content.instructions) }}
    </p>
    <div
      class="multiple-choice__answer-container"
      :class="[
        'multiple-choice__answer-container--' + numberOfColumns + '-columns',
        'multiple-choice__answer-container--' + numberOfRows + '-rows'
      ]"
    >
      <div
        v-for="(answer, index) in answersInNewOrder"
        :key="'answer' + 'and' + index"
        @click="evaluateAnswer(index)"
        class="multiple-choice__answer"
        :class="[
          'multiple-choice__answer--' + index,
          {
            'multiple-choice__answer--correct':
              index === correctAnswer[step] && correctIsIndicated,
            'multiple-choice__answer--wrong': wrongIsIndicated === index,
            'multiple-choice__answer--grey':
              (answersAreDeactivated && index !== correctAnswer[step]) ||
              continueButtonIsShown,
            'multiple-choice__answer--deactivated':
              (index === correctAnswer[index] &&
                step > index &&
                endColorIsIndicated >= index &&
                content.changePosition) ||
              (index === correctAnswer[step] && endColorIsIndicated === step) ||
              (correctAnswer.indexOf(index) < step &&
                correctAnswer.indexOf(index) !== -1 &&
                !content.changePosition),
            'multiple-choice__answer--no-caption': answer[0].length === 0,
            'multiple-choice__answer--no-transition': transitionIsSuspended
          }
        ]"
      >
        <span class="multiple-choice__answer-caption">{{ answer[0] }}</span>
        <span
          class="multiple-choice__answer-text"
          :style="{
            'font-size': answer[2] !== undefined ? answer[2] + 'rem' : '2.3rem'
          }"
          >{{ answer[1] }}</span
        >
      </div>
    </div>
    <app-message
      :data="messageData"
      :isShown="messageIsShown"
      :transitionIsSuspended="transitionIsSuspended"
      @messageClosed="closeFeedback()"
      class="multiple-choice__message"
      :class="{
        'multiple-choice__message--is-shown': messageIsShown,
        'multiple-choice__message--final-position':
          exerciseIsFinished && messageIsShown,
        'multiple-choice__message--no-transition': transitionIsSuspended
      }"
    ></app-message>
    <app-button
      @click="setPage('++')"
      size="big"
      :text="$t('course_continue_button')"
      class="multiple-choice__continue-button"
      :class="{
        'multiple-choice__continue-button--is-shown': continueButtonIsShown
      }"
    ></app-button>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppMessage from "@/components/Molecules/Message.vue";

export default {
  props: {
    content: Object,
    exerciseIsReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 0,
      answersInNewOrder: [],
      correctAnswer: [],
      orderOfAnswers: [],
      correctIsIndicated: false,
      wrongIsIndicated: -1,
      exerciseIsFinished: false,
      answersAreDeactivated: false,
      endColorIsIndicated: -1,
      continueButtonIsShown: false,

      messageText: "",
      message: "",
      type: "message",
      image: "",
      messageData: {},
      messageIsShown: false,
      transitionIsSuspended: false
    };
  },
  components: {
    AppButton,
    AppMessage
  },
  computed: {
    numberOfColumns() {
      let answers = this.content.answers.length;

      if (this.$store.state.windowWidth <= 600) {
        if (answers <= 2 || answers === 4) {
          return 2;
        } else {
          return 3;
        }
      } else {
        if (answers <= 6) {
          return 3;
        } else if (answers <= 8) {
          return 4;
        } else {
          return 5;
        }
      }
    },
    numberOfRows() {
      let answers = this.content.answers.length;

      if (this.$store.state.windowWidth <= 600) {
        if (answers <= 3) {
          return 1;
        } else if (answers <= 6) {
          return 2;
        } else if (answers <= 9) {
          return 3;
        } else {
          return 4;
        }
      } else {
        if (answers <= 8) {
          return 4;
        } else {
          return 5;
        }
      }
    },
    correctMessages() {
      if (typeof this.content.correctMessages === "string") {
        return [this.content.correctMessages];
      } else {
        return this.content.correctMessages;
      }
    }
  },
  methods: {
    evaluateAnswer(index) {
      if (
        this.exerciseIsFinished ||
        (index < this.step && this.content.changePosition)
      ) {
        return;
      }

      if (index === this.correctAnswer[this.step]) {
        this.exerciseIsFinished = true;
        this.answersAreDeactivated = true;
        this.playAudio("correct");
        this.correctIsIndicated = true;
        // this.$store.dispatch("setExerciseStatus", 4);
        let startStep = this.content.startFromStep || 0;
        this.$store.dispatch("setExerciseStatus", [this.step - startStep, 4]);
        this.messageIsShown = false;

        this.$store.dispatch("addExerciseData", ["step", this.step + 1]);
        this.$store.dispatch("addExerciseData", ["exerciseIsFinished", true]);
        this.$store.dispatch("addExerciseData", ["messageText", ""]);

        setTimeout(() => {
          // this.answersAreDeactivated = true;
          this.correctIsIndicated = false;
          this.endColorIsIndicated = this.step;

          if (this.step + 1 === this.correctAnswer.length) {
            this.continueButtonIsShown = true;
          } else {
            this.exerciseIsFinished = false;
            this.answersAreDeactivated = false;

            //  this.step++;
          }

          if (this.isDefined(this.correctMessages[this.step])) {
            let feedback = this.correctMessages[this.step];
            this.messageData = this.generateMessage(feedback);
            this.messageIsShown = true;
            this.$store.dispatch("addExerciseData", ["messageText", feedback]);
            this.$store.dispatch("addExerciseData", ["messageType", "correct"]);
          } else {
            this.$store.dispatch("addExerciseData", ["messageText", ""]);
          }

          // this.changePosition(this.step, index);

          if (
            ("changePosition" in this.content && this.content.changePosition) ||
            (this.content.correctAnswer.length - this.step > 0 &&
              this.content.correctAnswer.length > 1)
          ) {
            this.changePosition(this.step, index);
            if (
              "changePosition" in this.content &&
              this.content.changePosition
            ) {
              setTimeout(() => {
                this.step++;
              }, 760);
            } else {
              this.step++;
            }
          } else {
            //  alert("Message");
          }

          // this.answersInNewOrder.splice(index, 1);
          // this.answersInNewOrder.unshift(1);

          /* setTimeout(() => {
            this.step++;
          }, 760); */
        }, 1000);
      } else {
        this.playAudio("wrong");
        this.wrongIsIndicated = index;
        this.$store.dispatch("setExerciseStatus", 2);

        setTimeout(() => {
          this.wrongIsIndicated = -1;
        }, 667);

        let feedback = "";
        if (
          this.isDefined(this.content.wrongMessages[this.orderOfAnswers[index]])
        ) {
          feedback = this.content.wrongMessages[this.orderOfAnswers[index]];
        } else if (this.isDefined(this.content.feedbackGeneral)) {
          feedback = this.content.feedbackGeneral;
        }

        if (feedback !== "") {
          if (this.messageIsShown && this.messageText !== feedback) {
            this.messageIsShown = false;

            setTimeout(() => {
              this.messageText = feedback;
              this.messageData = this.generateMessage(feedback, "error");
              this.messageIsShown = true;
              this.$store.dispatch("addExerciseData", [
                "messageText",
                feedback
              ]);
              this.$store.dispatch("addExerciseData", ["messageType", "error"]);
            }, 1000);
          } else if (!this.messageIsShown) {
            setTimeout(() => {
              this.messageText = feedback;
              this.messageData = this.generateMessage(feedback, "error");
              this.messageIsShown = true;
              this.$store.dispatch("addExerciseData", [
                "messageText",
                feedback
              ]);
              this.$store.dispatch("addExerciseData", ["messageType", "error"]);
            }, 667);
          }
        } else {
          this.messageIsShown = false;
          setTimeout(() => {
            this.messageText = "";
          }, 760);
        }
      }
    },
    closeFeedback() {
      this.messageIsShown = false;
      this.$store.dispatch("addExerciseData", ["messageText", ""]);
      setTimeout(() => {
        this.messageText = "";
      }, 301);
    },
    changePosition(element1, element2) {
      if (!this.content.changePosition) return;

      let firstElement = document.querySelector(
        ".multiple-choice__answer--" + element1
      );
      let secondElement = document.querySelector(
        ".multiple-choice__answer--" + element2
      );

      let left1 = this.left(firstElement);
      let left2 = this.left(secondElement);
      let translateX = left2 - left1;
      let top1 = this.top(firstElement);
      let top2 = this.top(secondElement);
      let translateY = top2 - top1;

      firstElement.style.transform =
        "translate(" + translateX + "px, " + translateY + "px)";
      secondElement.style.transform =
        "translate(" + -translateX + "px, " + -translateY + "px)";

      setTimeout(() => {
        // let answer = this.answersInNewOrder[element2];
        // this.answersInNewOrder.splice(element2, 1);
        // this.answersInNewOrder.unshift(answer);
        [this.answersInNewOrder[element1], this.answersInNewOrder[element2]] = [
          this.answersInNewOrder[element2],
          this.answersInNewOrder[element1]
        ];
        this.correctAnswer[
          this.correctAnswer.indexOf(this.step)
        ] = this.correctAnswer[this.step];
        this.correctAnswer[this.step] = this.step;

        firstElement.style.transition = "none";
        secondElement.style.transition = "none";
        firstElement.style.transform = "none";
        secondElement.style.transform = "none";
        setTimeout(() => {
          firstElement.style.transition =
            "background-color 0.2s, transform 0.5s ease-in-out";
          secondElement.style.transition =
            "background-color 0.2s, transform 0.5s ease-in-out";
        }, 100);

        // this.correctAnswer = [0];
        // this.step++;
      }, 760);
    },
    resetExercise() {
      this.transitionIsSuspended = true;
      // this.$store.dispatch("setExerciseStatus", 0);
      let startStep = this.content.startFromStep || 0;
      // alert("startStep: " + startStep);
      for (let i = 0; i < this.content.correctAnswer.length - startStep; i++) {
        this.$store.dispatch("setExerciseStatus", [i, 0]);
      }
      this.$store.dispatch("addExerciseData", ["step", 0]);
      this.$store.dispatch("addExerciseData", ["exerciseIsFinished", false]);
      this.$store.dispatch("addExerciseData", ["orderOfAnswers", []]);
      this.$store.dispatch("addExerciseData", ["messageText", ""]);

      this.step = 0;
      this.correctIsIndicated = false;
      this.wrongIsIndicated = -1;
      this.exerciseIsFinished = false;
      this.answersAreDeactivated = false;
      this.endColorIsIndicated = -1;
      this.continueButtonIsShown = false;
      // this.transitionIsSuspended = true;
      this.messageIsShown = false;
      this.messageText = "";

      setTimeout(() => {
        this.autosolveExercise();
      }, 0);
      setTimeout(() => {
        this.transitionIsSuspended = false;
      }, 100);

      this.randomizeAnswers();
      // alert(0);
    },
    randomizeAnswers(orderOfAnswers) {
      let numberArray = this.generateNumberArray(this.content.answers.length);

      if (orderOfAnswers && orderOfAnswers.length > 0) {
        numberArray = orderOfAnswers;
      } else {
        this.answersInNewOrder = this.content.answers.slice(0);

        if (this.content.randomizeAnswers) {
          numberArray = this.shuffle(numberArray);
        }
        this.orderOfAnswers = numberArray;
      }

      for (let i = 0; i < numberArray.length; i++) {
        this.answersInNewOrder[i] = this.content.answers[numberArray[i]];
      }

      let correctAnswer = this.content.correctAnswer;
      // this.correctAnswer = numberArray.indexOf(this.content.correctAnswer);
      if (typeof correctAnswer === "number") {
        correctAnswer = [correctAnswer];
      }
      for (let i = 0; i < correctAnswer.length; i++) {
        this.correctAnswer[i] = numberArray.indexOf(correctAnswer[i]);
      }

      this.$store.dispatch("addExerciseData", [
        "orderOfAnswers",
        this.orderOfAnswers
      ]);
    },
    autosolveExercise(step) {
      // this.exerciseIsFinished = true;
      // this.answersAreDeactivated = true;
      // this.continueButtonIsShown = true;

      let vm = this;
      // setTimeout(() => {
      let startStep = vm.content.startFromStep || 0;
      if (step && step > 0) {
        startStep = step;
      }
      if (startStep === 0) return;
      this.endColorIsIndicated = startStep - 1;
      function autosolve() {
        let element1 = vm.step;
        let element2 = vm.correctAnswer[element1];

        if (vm.content.changePosition) {
          [vm.answersInNewOrder[element1], vm.answersInNewOrder[element2]] = [
            vm.answersInNewOrder[element2],
            vm.answersInNewOrder[element1]
          ];
          // }
          vm.correctAnswer[vm.correctAnswer.indexOf(vm.step)] =
            vm.correctAnswer[vm.step];
          vm.correctAnswer[vm.step] = vm.step;
        }

        vm.step++;

        if (vm.step < startStep) {
          autosolve();
        } else if (vm.step === vm.content.correctAnswer.length) {
          vm.exerciseIsFinished = true;
          vm.continueButtonIsShown = true;
        }
      }

      autosolve();
      // }, 2000);
    }
  },
  created() {
    if (
      this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "orderOfAnswers",
        "length",
        0
      ]) > 0
    ) {
      this.orderOfAnswers = this.$store.state.userDataLectures[
        this.$store.state.chosenLesson
      ].savedExerciseData[this.$store.state.coursePage].orderOfAnswers;
    }
    this.randomizeAnswers(this.orderOfAnswers);

    let step = this.o([
      this.$store.state.userDataLectures[this.$store.state.chosenLesson]
        .savedExerciseData[this.$store.state.coursePage],
      "step",
      0
    ]);
    if (
      this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "exerciseIsFinished",
        false
      ]) ||
      ("startFromStep" in this.content && this.content.startFromStep > 0) ||
      step > 0
    ) {
      this.autosolveExercise(step);
    }
    if (
      this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "messageText",
        ""
      ]) !== ""
    ) {
      let feedback = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "messageText",
        ""
      ]);
      let type = this.o([
        this.$store.state.userDataLectures[this.$store.state.chosenLesson]
          .savedExerciseData[this.$store.state.coursePage],
        "messageType",
        "correct"
      ]);
      if (type !== "error") {
        this.messageData = this.generateMessage(feedback);
      } else {
        this.messageData = this.generateMessage(feedback, "error");
      }
      this.transitionIsSuspended = true;
      this.messageText = feedback;
      setTimeout(() => {
        this.messageIsShown = true;
        setTimeout(() => {
          this.transitionIsSuspended = false;
        }, 100);
      }, 0);
    }
  },
  watch: {
    exerciseIsReset(to) {
      if (to) {
        this.resetExercise();
        // this.startExercise();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.multiple-choice {
  position: absolute;
  top: 6rem;
  width: 100%;
  min-height: calc(100vh - 6rem);
  padding-top: 5.5rem;
  color: $color-white;
  text-align: center;
  // background-color: #131313;
  background-color: #101010;

  @include grid-template-columns;
  grid-template-rows: min-content min-content 1fr;

  @include respond(phone) {
    min-height: max-content;
    padding-top: 4.6rem;
    background-color: $color-black;
    overflow: hidden;
  }

  &__heading {
    grid-column: 1 / 6;
    margin-bottom: 4.125rem;
    text-align: center;
    font-weight: 400;

    @include respond(medium-size) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      padding: 0 0.75rem;
      margin-bottom: 3.6rem;
      font-size: 4rem;
    }
  }

  &__instructions {
    grid-column: 3 / 4;
    margin-bottom: 6.5rem;
    text-align: center;
    font-size: 2.6rem;

    @include respond(medium-size) {
      margin-bottom: 4.125rem;
    }

    @include respond(phone) {
      padding: 0 0.75rem;
      text-align: center;
      line-height: 1.4;
      margin-bottom: 4.25rem;
    }
  }

  &__answer-container {
    grid-column: 3 / 4;
    width: min-content;
    height: min-content;
    justify-self: center;
    // // // margin-bottom: 14.7rem;

    display: grid;
    // // // grid-gap: 1rem;
    grid-gap: 0.75rem;

    &--2-columns {
      grid-template-columns: repeat(2, min-content);
    }

    &--3-columns {
      grid-template-columns: repeat(3, min-content);
    }

    &--4-columns {
      grid-template-columns: repeat(4, min-content);
    }

    &--5-columns {
      grid-template-columns: repeat(5, min-content);
    }

    &--2-rows {
      grid-template-rows: repeat(2, min-content);
    }

    &--3-rows {
      grid-template-rows: repeat(3, min-content);
    }

    &--4-rows {
      grid-template-rows: repeat(4, min-content);
    }
  }

  &__answer {
    // padding-top: 1.8rem; // // //
    padding: 0 0.75rem;
    border-radius: 0.6rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    // font-size: 2.4rem;
    font-size: 2.3rem;
    // line-height: calc((#{$width-center} - 3rem) / 4);
    // // // background-color: #1a1a1a;
    background-color: #1d1d1d;

    cursor: pointer;
    transition: background-color 0.2s, transform 0.5s ease-in-out; // was: transform 0.75s

    will-change: background-color, transform;
    backface-visibility: hidden;

    display: grid;
    align-content: center;
    line-height: 1.2;
    // font-size: 2.6rem;
    // height: 10rem;
    // transition: background-color 0.2s;

    /* &--deactivated {
      &:nth-child(1) {
        transform: translateX(calc(100% + 1rem));
      }

      &:nth-child(2) {
        transform: translateX(calc(-100% - 1rem));
      }
    } */

    @include respond(phone) {
      padding: 0 0.375rem;
      font-size: 2.1rem;
    }

    &--no-transition {
      transition: none;
    }

    .multiple-choice__answer-container--2-columns & {
      @include respond(phone) {
        width: calc(50vw - 0.375rem);
        height: calc(50vw - 0.375rem);
        // // // line-height: calc(50vw - 0.375rem);
      }
    }

    .multiple-choice__answer-container--3-columns & {
      width: calc(#{$width-center} / 4 - 0.5625rem);
      height: calc(#{$width-center} / 4 - 0.5625rem);
      // // // line-height: calc(#{$width-center} / 4 - 0.5625rem);

      @include respond(phone) {
        width: calc(33.33333vw - 0.5rem);
        height: calc(33.33333vw - 0.5rem);
        // // // line-height: calc(33.33333vw - 0.5rem);
      }
    }

    .multiple-choice__answer-container--4-columns & {
      width: calc(#{$width-center} / 4 - 0.5625rem);
      height: calc(#{$width-center} / 4 - 0.5625rem);
      // // // line-height: calc(#{$width-center} / 4 - 0.5625rem);
    }

    .multiple-choice__answer-container--5-columns & {
      width: calc(#{$width-center} / 5 - 0.6rem);
      height: calc(#{$width-center} / 5 - 0.6rem);
      // // // line-height: calc(#{$width-center} / 5 - 0.6rem);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(.multiple-choice__answer--correct):not(.multiple-choice__answer--wrong):not(.multiple-choice__answer--deactivated) {
        background-color: #333;
      }
    }

    &--correct {
      background-color: green;
      transition: none;
      // transition: transform 0.375s 1s ease-in-out;
    }

    &--wrong {
      background-color: $color-wrong;
      color: $color-black;
      transition: none;

      animation: shake 0.667s ease-in-out;
    }

    &--grey {
      background-color: #151515;
      color: $color-black;

      cursor: initial;

      &:hover {
        background-color: #151515 !important;
      }
    }

    & .multiple-choice__answer-text {
      transition: inherit;
    }

    &--deactivated {
      z-index: 10000;
      // background-color: #333;
      // background-color: $color-primary-dark !important;
      background: linear-gradient(
        to bottom,
        $color-primary-darker 0,
        $color-primary-darker 3.6rem,
        $color-primary-dark 3.6rem,
        $color-primary-dark 100%
      );
      color: $color-white !important;
      cursor: initial;

      pointer-events: none;

      &:hover {
        background-color: $color-primary-dark !important;
        color: $color-white !important;
        cursor: initial;
      }

      & .multiple-choice__answer-text {
        //  transform: translateY(1.2rem);
      }

      &.multiple-choice__answer--no-caption {
        background: $color-primary-dark;
      }
    }
  }

  &__answer-caption {
    display: none;
    position: absolute;
    // left: 0.75rem;
    top: 0.9rem;
    // font-family: $font-primary;
    // padding: 0 0 0.75rem 0rem;
    padding-left: 0.75rem;
    width: 100%;
    text-align: left;
    font-size: 1.8rem;
    line-height: 1;
    // color: $color-primary-lighter;
    // filter: brightness(1.5);
    // text-decoration: underline;
    // text-decoration-thickness: 0.15rem;
    // font-style: italic;

    /* &::after {
      @include pseudo-element;
      transform: translate(-0.75rem, 0.565rem);
      width: 100%;
      height: 1px;
      // background-color: $color-white;
      // background-color: $color-black;
      background-color: #101010;
      // background-color: $color-primary;

      @include respond(phone) {
        background-color: $color-black;
      }
    } */

    .multiple-choice__answer--deactivated & {
      display: block;
    }
  }

  &__message {
    position: fixed;
    left: calc(50vw - 0.5 * min(#{$width-center}, 100vw - 6rem));
    top: 0;
    z-index: 10;
    width: min(#{$width-center}, 100vw - 6rem);
    // transform: translateY(calc(var(--vh, 1vh) * 100 - 6.75rem));
    transform: translateY(calc(var(--vh, 1vh) * 100 - 6rem));
    text-align: left;

    transition: transform 0.75s, visibility 0s 0.75s;

    &--is-shown {
      transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
      transition: transform 0.75s;
    }

    &--no-transition {
      transition: none;
    }

    @include respond(phone) {
      left: 0;
      width: 100%;
      box-shadow: 0 -0.5rem 0rem $color-black;

      &::before {
        @include pseudo-element;
        top: -0.5rem;
        z-index: -10;
        transform: translateZ(10000px);
        width: 100%;
        height: 0.5rem;
        background-color: $color-black;
      }

      &--is-shown {
        // transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 12.75rem));
        transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 7.7rem));
      }

      &--final-position {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 100% - 13.9rem));
      }
    }
  }

  &__continue-button {
    visibility: hidden;
    position: fixed;
    left: calc(50vw + 0.5 * min(#{$width-center}, 100vw - 6rem));
    top: calc(100 * var(--vh) - 7.5rem);
    transform: translate(-100%, -100%);
    width: max-content;
    height: max-content;
    border: 1px solid transparent !important;
    border-radius: 0.6rem;
    // padding: 0.85rem 2.4rem !important;
    padding: 1rem 3.2rem !important;
    background-color: $color-primary-dark;

    @include respond(phone) {
      position: fixed;
      display: block;
      left: 0;
      top: 0;
      transform: translateY(calc(var(--vh, 1vh) * 100 - 6.5rem + 1px));
      width: 100vw;
      height: 6rem;
      font-size: 2.8rem;
      text-align: center;
      visibility: hidden;
      will-change: transform;
      transition: transform 0.75s;

      &--is-shown {
        transform: translateY(calc(var(--vh, 1vh) * 100 - 13.5rem + 1px));
      }
    }

    &--is-shown {
      visibility: visible;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
