<template>
  <template
    v-for="(span, index) in getHTML(text)[$i18n.locale]"
    :key="span + 'andIndex' + index"
  >
    <span v-if="span.type === 'text'" v-html="span.text"></span
    ><span
      v-if="span.type === 'glossary'"
      @mouseenter="
        toggleGlossaryModal(
          true,
          span.topic,
          span.subtopic,
          span.paragraph,
          textNumber + '-' + index
        )
      "
      @touchstart="
        toggleGlossaryModal(
          'toggle',
          span.topic,
          span.subtopic,
          span.paragraph,
          textNumber + '-' + index
        )
      "
      @mousedown="
        toggleGlossaryModal(
          'toggle',
          span.topic,
          span.subtopic,
          span.paragraph,
          textNumber + '-' + index
        )
      "
      @mouseleave="
        toggleGlossaryModal(
          false,
          span.topic,
          span.subtopic,
          span.paragraph,
          textNumber + '-' + index
        )
      "
      class="glossary__link"
      :id="['glossary__link--'] + textNumber + '-' + index"
    >
      {{ span.text }}
    </span>
  </template>
</template>

<script>
export default {
  name: "glossaryLink",
  props: {
    text: {
      type: String
    },
    textNumber: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
@import "sass/main.scss";

.glossary__link {
  position: relative;
  color: $color-primary-lighter;
  cursor: pointer;

  transform-style: preserve-3d;

  &:hover {
  }
}
</style>
