<template>
  <div class="plans">
    <h2 class="heading-secondary plans__heading">
      {{ $t("plans_heading") }}
    </h2>
    <p class="plans__introduction">
      {{ $t("plans_paragraph")[0] }}
      <span class="plans__italic">{{ $t("plans_paragraph")[1] }}</span>
      {{ $t("plans_paragraph")[2] }}
      <span class="plans__italic">{{ $t("plans_paragraph")[3] }}</span>
      {{ $t("plans_paragraph")[4] }}
    </p>
    <div class="plans__container">
      <h3 class="plans__subheading">
        {{ $t("plans_for_home")[0] }}
      </h3>
      <p class="plans__text">
        {{ $t("plans_for_home")[1] }}
      </p>
      <app-plan-individual
        data="plans_plan_home1"
        class="plans__plan plans__plan--1"
      ></app-plan-individual>
      <app-plan-individual
        data="plans_plan_home2"
        class="plans__plan plans__plan--2"
      ></app-plan-individual>
      <app-plan-individual
        data="plans_plan_home3"
        class="plans__plan plans__plan--3"
      ></app-plan-individual>
      <app-plan-individual
        data="plans_plan_home4"
        class="plans__plan plans__plan--4"
      ></app-plan-individual>
      <h3 class="plans__subheading plans__subheading--college">
        {{ $t("plans_for_college")[0] }}
      </h3>
      <p class="plans__text">
        {{ $t("plans_for_college")[1] }}
      </p>
      <app-plan-college></app-plan-college>
    </div>
  </div>
</template>

<script>
import AppPlanIndividual from "@/components/Organisms/PlanIndividual.vue";
import AppPlanCollege from "@/components/Organisms/PlanCollege.vue";

export default {
  name: "plans",
  components: {
    AppPlanIndividual,
    AppPlanCollege
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.plans {
  position: relative;
  width: 100%;
  min-height: 150vh;
  padding-top: 12rem;
  background-color: $color-black;
  color: $color-white !important;

  display: grid;
  grid-template-columns: 1fr min(100vw - 6rem, #{$width-center}) 1fr;

  @include respond(tab-port) {
    grid-template-columns: 3rem 1fr 3rem;
  }

  @include respond(phone) {
    grid-template-columns: 1.5rem 1fr 1.5rem;
  }

  &__heading {
    position: relative;
    grid-column: 2 / 3;
    max-width: max-content;

    @include respond(phone) {
      width: calc(100% - 3rem);
      line-height: 1.3;
    }
  }

  &__introduction {
    grid-column: 2 / 3;
    align-self: start;

    margin-top: 5.75rem;
    max-width: 90rem;
    font-size: 2.6rem;
    line-height: 1.6;
    color: #d4d5d6;

    @include respond(tab-port) {
      width: min(80rem, 100%);
      font-size: 2.8rem;
      line-height: 1.5;
      color: rgb(220, 220, 220);
    }

    @include respond(phone) {
      margin-bottom: -1.5rem;
      width: calc(100% - 3rem);
    }
  }

  &__italic {
    font-style: italic;
    margin-right: 0.2rem;
  }

  &__container {
    position: relative;
    grid-column: 2 / 3;
    margin-bottom: 22vh;
    height: min-content;
    border-radius: $border-radius;

    display: grid;
    grid-template-columns: repeat(4, min(21.85rem, (100% - 3 * 4rem) / 4));
    justify-content: space-between;

    @include respond(tab-port) {
      grid-template-columns: repeat(4, min(21.85rem, (100% - 3 * 3rem) / 4));
    }

    @include respond(phone) {
      grid-template-columns: repeat(4, min(21.85rem, (100% - 3 * 1.5rem) / 4));
    }

    @media only screen and (max-width: 530px) {
      width: calc(100vw - 3rem);
      grid-template-columns: repeat(2, calc(50% - 0.75rem));
    }
  }

  &__subheading {
    grid-column: 1 / 5;
    font-size: 3rem; // was: 3.1rem
    font-weight: 400;
    line-height: 1;
    // margin-top: 12rem;
    margin-top: 11.22rem;

    @include respond(tab-port) {
      font-family: $font-primary-bold;
      // font-size: 3.6rem;
      // font-size: 3.2rem;
      font-size: 2.8rem;
      font-weight: 100;
      z-index: 1;
      line-height: 1.3;
    }

    @include respond(phone) {
      width: calc(100% - 3rem);
    }

    @media only screen and (max-width: 530px) {
      grid-column: 1 / 3;
      // margin-top: 8rem;
      line-height: 1.4;
    }

    &--college {
      margin-top: 18rem; // was: 16rem
    }

    @media only screen and (max-width: 530px) {
      &--college {
        // margin-top: 12rem;
        margin-top: 11.4rem;
      }
    }
  }

  &__text {
    display: block;
    grid-column: 1 / 5;
    margin-top: 2rem;
    font-size: 2.4rem;
    color: #999;

    @include respond(tab-port) {
      font-size: 2.8rem;
      line-height: 1.5;
      color: rgb(220, 220, 220);
    }

    @include respond(phone) {
      margin-top: 1.7rem;
      width: calc(100% - 3rem);
      font-size: 2.6rem;
    }

    @media only screen and (max-width: 530px) {
      grid-column: 1 / 3;
    }
  }

  &__plan {
    &--1 {
      grid-column: 1 / 2;
    }

    &--2 {
      grid-column: 2 / 3;
    }

    &--3 {
      grid-column: 3 / 4;

      /* @include respond(phone) {
        grid-column: 1 / 2;
      } */

      @media only screen and (max-width: 530px) {
        grid-column: 1 / 2;
      }
    }

    &--4 {
      grid-column: 4 / 5;

      /* @include respond(phone) {
        grid-column: 2 / 3;
      } */

      @media only screen and (max-width: 530px) {
        grid-column: 2 / 3;
      }
    }
  }
}
</style>
