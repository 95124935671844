<template>
  <div class="modal tabstart" tabindex="0">
    <h2 class="modal__heading">{{ $store.state.modalData.heading }}</h2>
    <app-lottie
      :lottie="$store.state.modalData.lottie"
      :loop="$store.state.modalData.loop"
      class="modal__animation"
      :class="{
        'modal__animation--background': $store.state.modalData.background,
        'modal__animation--overflow-hidden':
          $store.state.modalData.overflowHidden
      }"
    ></app-lottie>
    <div class="modal__text-container">
      {{ $store.state.modalData.text }}
      <div class="modal__button-container">
        <app-button
          @click="$store.state.modalData.ghostButtonAction()"
          :text="$store.state.modalData.ghostButton"
          class="modal__button"
        ></app-button>
        <app-button
          @click="toggleModal()"
          :text="$store.state.modalData.button"
          class="modal__button"
        ></app-button>
      </div>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButton,
    AppButtonXRound,
    AppLottie
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.modal {
  width: 46rem;
  border-radius: 1rem;
  font-family: $font-primary;
  color: $color-white;
  background-color: $color-black;

  &:focus {
    outline: none;
  }

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3.3rem;
    }
  }

  &__animation {
    transform: translate(calc(23rem - 50%), 1.25rem);
    margin: 4.5rem 0 8rem;

    width: 14rem;
    height: 14rem;
    border-radius: 50%;

    @include respond(tab-port) {
      transform: translate(calc(25rem - 50%), 1.25rem);
    }

    @include respond(phone) {
      transform: translate(calc(min(207px, 50vw - 0.75rem) - 50%), 1.25rem);
      margin: 6rem 0 8.75rem;
      width: 18rem;
      height: 18rem;
    }

    &--background {
      background-color: #181818;
    }

    &--overflow-hidden {
      overflow: hidden;
    }
  }

  &__text-container {
    padding: 2.75rem 2.75rem 1.375rem;
    font-size: 2rem;
    background-color: #151515;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @include respond(tab-port) {
      padding: 1.915rem 2.5rem 2.5rem 2.5rem;
      font-size: 2.4rem;
      line-height: 1.45;
    }

    @include respond(phone) {
      padding: 2.75rem 2.75rem 2.375rem;
      font-size: 2.6rem;
    }
  }

  &__button-container {
    margin-top: 2.75rem;

    transform: translateX(calc(40.5rem - 100%));
    width: max-content;

    display: grid;
    grid-template-columns: max-content max-content;

    @include respond(tab-port) {
      transform: translateX(calc(50rem - 5rem - 100%));
    }

    @include respond(phone) {
      margin-top: 3.5rem;
      transform: translateX(calc(min(414px, 100vw) - 6.5rem - 100%));
    }
  }

  &__button {
    font-size: 2rem;
    text-align: center;
    padding: 0.7rem 2rem;

    background-color: #2a2a2a;

    @include respond(phone) {
      background-color: #303030;
      font-size: 2.4rem;
    }

    &:hover {
      background-color: #404040;
    }

    &:first-child {
      margin-right: 1.375rem;
      text-decoration: underline;
      background-color: transparent;
    }
  }
}
</style>
