<template>
  <div
    class="table-of-contents"
    :class="{
      'table-of-contents__is-shown':
        $store.state.coursePage >= 0 &&
        ($store.state.selectedSection !== -1 || $store.state.TOCisOpen),
      'table-of-contents__on-first-page': $store.state.coursePage === 0
    }"
  >
    <div class="table-of-contents__header">
      <h2 class="table-of-contents__heading">
        {{ t($store.state.headingOfChosenLesson) }}
      </h2>
    </div>
    <div class="table-of-contents__scroll-container">
      <nav class="table-of-contents__list-of-topics">
        <div
          v-for="(content, index) in contents"
          :key="
            JSON.stringify(content) +
              'andIndex' +
              index +
              'andChosenLesson' +
              $store.state.chosenLesson
          "
          @mousedown="goToSection(index)"
          class="table-of-contents__topic"
          :class="{
            'table-of-contents__topic--active':
              index + 1 ===
                $store.state.pageBelongsToSection[$store.state.coursePage] &&
              $store.state.coursePage + 1 !== $store.state.numberOfPages,
            'table-of-contents__topic-processed':
              $store.state.processedSections[index] === 2 &&
              $store.state.processedSections[index + 1] === 2,
            'table-of-contents__topic--selected':
              $store.state.selectedSection === index
          }"
        >
          <div class="table-of-contents__circle"></div>
          <app-checkmark-blue
            alt="A checkmark marking a processed task"
            v-if="$store.state.processedSections[index] === 2"
            class="table-of-contents__checkmark"
          ></app-checkmark-blue>
          <app-pending
            alt="A clock marking a task in progress"
            v-if="$store.state.processedSections[index] === 1"
            class="table-of-contents__checkmark"
          ></app-pending>
          <span class="table-of-contents__content">
            {{ t(content) }}
          </span>
        </div>
      </nav>
    </div>
  </div>
  <div
    aria-hidden="true"
    @click="$store.state.TOCisOpen = !$store.state.TOCisOpen"
    class="table-of-contents__backdrop"
    :class="{
      'table-of-contents__backdrop-is-shown':
        $store.state.coursePage >= 0 &&
        ($store.state.selectedSection !== -1 || $store.state.TOCisOpen) &&
        $store.state.windowWidth < remToPx(189.1),
      'table-of-contents__backdrop-on-first-page': $store.state.coursePage === 0
    }"
  ></div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppPending from "@/components/Atoms/Images/CourseIcons/Pending.vue";

export default {
  name: "tableOfContents",
  components: {
    AppCheckmarkBlue,
    AppPending
  },
  props: {
    prerequisites: Object,
    contents: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    selectedSection() {
      return this.$store.state.selectedSection;
    },
    TOCisOpen() {
      return this.$store.state.TOCisOpen;
    }
  },
  methods: {
    goToSection(section) {
      if (this.$store.state.coursePage > 0) {
        this.setPage(this.$store.state.sectionBeginsAtPage[section]);

        if (this.$store.state.windowWidth < this.remToPx(189.1)) {
          // !!!!!!!
          this.$store.state.TOCisOpen = false;
        }
      } else {
        this.$store.state.coursePageRequestedViaTOC = this.$store.state.sectionBeginsAtPage[
          section
        ];

        // Check, if prerequisite courses were made
        let allPrerequisiteCoursesMade = true;
        if (
          !this.o([
            this.$store.state.userDataLectures,
            this.$store.state.chosenLesson,
            "isUnlocked",
            false
          ])
        ) {
          let prerequisites = this.prerequisites || [];
          for (let i = 0; i < prerequisites.length; i++) {
            if (
              !(prerequisites[i] in this.$store.state.userDataLectures) ||
              !(
                "finished" in
                this.$store.state.userDataLectures[prerequisites[i]]
              ) ||
              !this.$store.state.userDataLectures[prerequisites[i]].finished
            ) {
              allPrerequisiteCoursesMade = false;
            }
          }
        }

        if (!allPrerequisiteCoursesMade) {
          if (!this.$store.state.userWasAskedAboutSound) {
            this.toggleModal(
              "app-audio-choice",
              "audiochoice",
              "app-unlock-course"
            );
          } else {
            this.toggleModal("app-unlock-course", "audiochoice", "none");
          }
        } else if (
          this.o([
            this.$store.state.userDataLectures,
            this.$store.state.chosenLesson,
            "started",
            false
          ]) === true
        ) {
          if (!this.$store.state.userWasAskedAboutSound) {
            this.toggleModal(
              "app-audio-choice",
              "audiochoice",
              "app-resume-course"
            );
          } else {
            this.toggleModal("app-resume-course", "audiochoice", "none");
          }
        } else {
          if (!this.$store.state.userWasAskedAboutSound) {
            this.toggleModal("app-audio-choice", "audiochoice", "none");
          } else {
            this.$store.dispatch("resetLectureDataForFinishedCourse");
            // this.setPage("++");
            this.setPage(this.$store.state.sectionBeginsAtPage[section]);
            if (this.$store.state.windowWidth < this.remToPx(189.1)) {
              this.$store.state.TOCisOpen = false;
            }
          }
        }
      }
    }
  },
  created() {
    this.processedSections = this.$store.state.processedSections.slice();

    window.addEventListener("resize", () => {
      document.querySelector(
        ".table-of-contents__scroll-container"
      ).scrollTop = 0;
    });
  },
  watch: {
    selectedSection() {
      if (this.selectedSection === -1) {
        return;
      }
      let topicsContainer = document.querySelector(
        ".table-of-contents__list-of-topics"
      );
      let topicsContainerTop = this.top(topicsContainer);
      let nthChild = this.selectedSection + 1;
      let topic = document.querySelector(
        ".table-of-contents__topic:nth-child(" + nthChild + ")"
      );
      let topicTop = this.top(topic);
      let topicHeight = this.height(topic);
      let topicBottom = window.innerHeight - this.bottom(topic);
      let remValue = this.remToPx(1);
      if (topicTop < 13.9 * remValue) {
        document.querySelector(
          ".table-of-contents__scroll-container"
        ).scrollTop = topicTop - topicsContainerTop + 2.1 * remValue;
      } else if (topicBottom < 3.8 * remValue) {
        document.querySelector(
          ".table-of-contents__scroll-container"
        ).scrollTop =
          topicTop -
          topicsContainerTop +
          20.4 * remValue +
          1 +
          topicHeight -
          window.innerHeight;
      }
    },
    TOCisOpen(to) {
      if (to) {
        document.querySelector(
          ".table-of-contents__scroll-container"
        ).scrollTop = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.table-of-contents {
  position: fixed;
  //  position: absolute;
  right: 0;
  top: 6rem;
  z-index: 200000;
  //  transform: translateZ(2px) translateX(100%);
  transform: translateX(100%);
  width: 43.35rem;
  min-height: calc(100vh - 6rem);
  border-left: 1px solid $color-grey;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  background-color: $color-black;

  transition: transform 0.5s ease-in;

  &__on-first-page {
    @include respond(phone) {
      top: calc(6rem + var(--breadcrumb-height, 3.2rem));
    }
  }

  &__is-shown {
    // transform: translateZ(2px);
    transform: none;
    transition: transform 0.5s ease-out;
  }

  &__header {
    position: relative;
    min-height: 6rem;
    width: 100%;
    padding: 1.5rem 8rem 1.25rem 3rem;
    background-color: #1a1a1a;
  }

  &__heading {
    font-size: 2.4rem;
    line-height: 1.4;
  }

  &__scroll-container {
    width: 100%;
    height: calc(100vh - 17.5rem - 1px);
    padding-bottom: 13rem;

    overflow-x: hidden;
    overflow-y: auto;
    transform-style: preserve-3d;

    @include respond(phone) {
      height: calc(100vh - 6rem - var(--breadcrumb-height, 3.2rem) - 1px);
    }
  }

  &__list-of-topics {
    margin-top: 4.15rem;
    margin-left: 3.9rem;
    font-size: 2.4rem;
    line-height: 1;

    transform-style: preserve-3d;
  }

  &__topic {
    position: relative;
    padding: 0 4rem 3.12rem 4.2rem;
    min-height: 6rem;
    line-height: 1.2;
    border-left: 1px dashed #444;
    transform: translateZ(1px) translateY(-0.4rem);

    transform-style: preserve-3d;

    cursor: pointer;

    &:last-child {
      border-left: 1px dashed transparent;
    }

    &--selected,
    &:hover {
      &::before {
        @include pseudo-element;
        left: -4rem;
        top: -1.8rem;
        z-index: -1;
        transform: translateZ(-1px);
        width: calc(100% + 8rem);
        height: 100%;
        background-color: #303030;

        @media (pointer: coarse) {
          background-color: transparent;
        }
      }
    }
  }

  &__topic--active {
    color: #28aff8;
  }

  &__topic-processed {
    border-left: 0.15rem solid #444;
  }

  &__circle {
    position: absolute;
    left: calc(-1.2rem - 0.25px);
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #444;
    border-radius: 50%;
    background-color: #101010;
  }

  &__checkmark {
    position: absolute;
    left: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__content {
    display: inline-block;
    transform: translateY(-0.15rem);
  }
}

.table-of-contents__backdrop {
  position: fixed;
  visibility: hidden;
  left: 0;
  top: 6rem;
  z-index: 100000;
  width: 100vw;
  height: calc(100vh - 12rem);
  background-color: rgba(#444, 0.675);

  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;

  @include respond(phone) {
    height: 100vh;
  }

  &-is-shown {
    visibility: visible;
    opacity: 1;

    transition: opacity 0.5s;
  }
}

.table-of-contents__backdrop-on-first-page {
  height: calc(100vh - 6rem);

  @include respond(phone) {
    height: 100vh;
  }
}
</style>
