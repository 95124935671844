<template>
  <div @click="closeModal" class="main-menu__overlay"></div>
  <div class="main-menu" :class="{ 'submenu-open': subMenuIsOpen }">
    <div
      class="main-menu__primary-menu"
      :class="{ 'submenu-open': subMenuIsOpen }"
    >
      <app-menu-item
        @click="
          $store.state.mainMenuIsShown = false;
          goTo('/account');
        "
        icon="user"
        :text="$t('main_menu').primary[0]"
        class="main-menu__line-bottom"
      ></app-menu-item>
      <app-menu-item
        @click="
          subMenuIsOpen = true;
          chosenSubMenu = 1;
        "
        icon="settings"
        :text="$t('main_menu').primary[1]"
        :arrow="true"
      ></app-menu-item>
      <app-menu-item
        @click="
          subMenuIsOpen = true;
          chosenSubMenu = 2;
        "
        icon="help"
        :text="$t('main_menu').primary[2]"
        :arrow="true"
      ></app-menu-item>
      <app-menu-item
        @click="showFeedbackForm()"
        icon="feedback"
        :text="$t('main_menu').primary[3]"
      ></app-menu-item>
      <app-menu-item
        v-if="$store.state.coursePage < 0 || $store.state.windowWidth > 1000"
        @click="logout()"
        icon="logout"
        :text="$t('main_menu').primary[4]"
        class="main-menu__line-top"
      ></app-menu-item>
      <app-menu-item
        v-if="$store.state.coursePage >= 0 && $store.state.windowWidth <= 1000"
        @click="
          setPage(-1);
          toggleModal();
          $store.state.mainMenuIsShown = false;
        "
        icon="exit"
        :text="$t('main_menu').primary[5]"
        class="main-menu__line-top"
      ></app-menu-item>
    </div>
    <div
      class="main-menu__submenu"
      :class="{ 'submenu-open': chosenSubMenu === 1 }"
    >
      <app-menu-item
        @click="
          subMenuIsOpen = false;
          chosenSubMenu = 0;
        "
        icon="arrow-left"
        :text="$t('main_menu').primary[1]"
        class="main-menu__submenu-heading"
      ></app-menu-item>
      <app-menu-item
        v-if="isTouchscreen || isIOS"
        @click="
          setFullscreen(isInFullscreenMode);
          showIOSAlert();
          $store.state.mainMenuIsShown = false;
        "
        icon="fullscreen"
        :text="
          !isInFullscreenMode
            ? $t('main_menu').settings[0]
            : $t('main_menu').settings[1]
        "
      ></app-menu-item>
      <app-menu-item
        icon="earth"
        :text="$t('main_menu').settings[2]"
        @click="openLanguageSelection()"
      ></app-menu-item>
      <app-menu-item
        icon="sound"
        :text="
          $store.state.soundIsTurnedOn
            ? $t('main_menu').settings[3]
            : $t('main_menu').settings[4]
        "
        @click="$store.dispatch('toggleAudio')"
      ></app-menu-item>
    </div>
    <div
      class="main-menu__submenu"
      :class="{ 'submenu-open': chosenSubMenu === 2 }"
    >
      <app-menu-item
        @click="
          subMenuIsOpen = false;
          chosenSubMenu = 0;
        "
        icon="arrow-left"
        :text="$t('main_menu').primary[2]"
        class="main-menu__submenu-heading"
      ></app-menu-item>
      <app-menu-item
        @click="toggleModal('app-onboarding', $store.state.recentPage.type)"
        icon="page"
        :text="$t('main_menu').assistance[1]"
      ></app-menu-item>
      <app-menu-item
        @click="
          toggleAlert({
            de:
              'Die Seite mit den Bedienungshinweisen befindet sich noch in der Entwicklung. Bitte schaue in Kürze nochmal rein.',
            en:
              'The operating instructions page is still under development. Please check back shortly.'
          })
        "
        icon="book"
        :text="$t('main_menu').assistance[2]"
      ></app-menu-item>
      <app-menu-item
        @click="$store.state.onboardingIsShown = true"
        icon="information"
        :text="$t('main_menu').assistance[0]"
      ></app-menu-item>
    </div>
  </div>
</template>

<script>
import AppMenuItem from "@/components/Atoms/MenuItem.vue";

export default {
  name: "mainMenu",
  components: {
    AppMenuItem
  },
  data() {
    return {
      subMenuIsOpen: false,
      chosenSubMenu: 0,
      mainMenuMayBeClosed: false,
      isInFullscreenMode: false
    };
  },
  computed: {
    isIOS() {
      // return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod"
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    isTouchscreen() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    mainMenuIsShown() {
      return this.$store.state.mainMenuIsShown;
    }
  },
  methods: {
    showFeedbackForm() {
      this.$store.state.feedbackFormIsShown = !this.$store.state
        .feedbackFormIsShown;
      if (this.$store.state.windowWidth <= 600) {
        this.$store.state.mainMenuIsShown = false;
      }
    },
    openLanguageSelection() {
      // this.$store.state.languageMenuRequested = 1;
      // this.goTo("/account");
      this.toggleModal("app-language-modal", "language", "app-language");
      // // // this.toggleModal("app-license-modal", "license", "app-license");
      this.$store.state.mainMenuIsShown = false;
    },
    logout() {
      this.$store.dispatch("logout");
    },
    setFullscreenStatus() {
      if (document.fullscreenElement) {
        this.isInFullscreenMode = true;
      } else {
        this.isInFullscreenMode = false;
      }
    },
    showIOSAlert() {
      if (this.isIOS) {
        this.toggleAlert(this.$t("fullscreen_alert_iOS"));
      }
    }
  },
  created() {
    this.setFullscreenStatus();
    let vm = this;
    window.addEventListener("resize", vm.setFullscreenStatus, false);
    setTimeout(() => {
      // alert(2);
      let overlay = document.querySelector(".main-menu__overlay");
      // alert(overlay);
      overlay.style.opacity = 0.675;
    }, 0);
  },
  watch: {
    mainMenuIsShown(to) {
      let overlay = document.querySelector(".main-menu__overlay");

      if (!to) {
        if (!this.mainMenuMayBeClosed) {
          document.querySelector(".main-menu").style.display = "none";
          this.mainMenuMayBeClosed = true;
          this.$store.state.mainMenuIsShown = true;
          overlay.style.opacity = 0;

          setTimeout(() => {
            this.$store.state.mainMenuIsShown = false;
          }, 325);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.main-menu__overlay {
  display: none;
  position: fixed;
  top: 6rem;
  width: 100vw;
  height: calc(100vh - 6rem);
  background-color: #444;
  opacity: 0;
  //  opacity: 0.675;

  will-change: opacity;
  transition: opacity 0.325s;

  @include respond(tab-port) {
    display: block;
  }
}

.main-menu {
  position: absolute;
  right: 2rem;
  top: 6rem;
  z-index: 1000;
  height: 31rem;
  border: 1px solid var(--header-line-color);
  border-top: none;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding: 0.5rem 0 0;
  // // // background-color: #161616;
  background-color: $color-black;
  transform: translateZ(10px);

  // background-color: green;

  overflow: hidden;
  transition: height 0.225s ease-in-out;

  @include respond(phone) {
    right: 0.75rem;
  }

  &.submenu-open {
    height: 24rem;
  }

  &__line-bottom {
    border-bottom: 1px solid var(--header-line-color);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 1.5rem;
  }

  &__line-top {
    border-top: 1px solid var(--header-line-color);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 1.5rem;
  }

  &__primary-menu {
    transform: none;

    transition: transform 0.225s ease-in-out;

    &.submenu-open {
      transform: translateX(-100%);
    }
  }

  &__submenu {
    height: 0;
    transform: translate(100%, -30.5rem);

    transition: transform 0.225s ease-in-out;

    &.submenu-open {
      height: auto;
      transform: translateY(-30.5rem);
    }
  }
}
</style>
