<template>
  <div class="testimonials2">
    <div class="testimonials2__container">
      <div class="testimonials2__sticky-container">
        <div class="testimonials2__image-container">
          <img
            src="@/assets/Home/Testimonials2/univ_2n6.png"
            :alt="$t('testimonials2_university')"
            class="testimonials2__image testimonials2__image--1"
          />
          <img
            src="@/assets/Home/Testimonials2/testimonial_bottom.png"
            :alt="$t('testimonials2_person_image')"
            class="testimonials2__image testimonials2__image--2"
          />
        </div>
        <figcaption class="testimonials2__image-caption">
          {{ $t("testimonials2_university") }}
        </figcaption>
        <p class="testimonials2__testimonial">
          <span class="testimonials2__testimonial-heading">{{
            $t("testimonials2_subheading")
          }}</span>
          <span class="testimonials2__testimonial-quotation">
            <!-- &bdquo; -->{{ $t("testimonials2_quotation")
            }}<!-- &ldquo; -->
            <br />
          </span>
          <img
            src="@/assets/Home/Testimonials2/signature_2.png"
            :alt="$t('testimonials2_person_signature')"
            class="testimonials2__signature"
          />
          <span class="testimonials2__testimonial-name">{{
            $t("testimonials2_name")
          }}</span>
          <span class="testimonials2__testimonial-name-description">{{
            $t("testimonials2_name_description")
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollHandler() {
      let sectionTop = this.top(this.testimonials2);

      // Handle position for smoother scrolling
      /* if (
        sectionTop > 0.285 * this.$store.state.windowHeight &&
        this.$store.state.windowWidth > 600
      ) {
        this.stickyContainer.style.position = "absolute";
        this.stickyContainer.style.top = 0;
      } else if (
        sectionTop < -0.565 * this.$store.state.windowHeight &&
        this.$store.state.windowWidth > 600
      ) {
        this.stickyContainer.style.position = "absolute";
        this.stickyContainer.style.top = "initial";
        this.stickyContainer.style.bottom = "12rem";
      } else {
        this.stickyContainer.style.position = "sticky";
        this.stickyContainer.style.top =
          "clamp(7rem, 50vh - 34.12277rem, 23.5vh)";
      } */

      let scaleImage1 = this.value(
        sectionTop,
        1,
        1.5,
        this.$store.state.windowHeight,
        -this.$store.state.windowHeight / 2,
        "inOut",
        2
      );
      this.image1.style.transform = "scale(" + scaleImage1 + ")";

      let opacityImage1 = this.value(
        sectionTop,
        1,
        0,
        0,
        -this.$store.state.windowHeight / 2,
        "inOut",
        2
      );
      this.image1.style.opacity = opacityImage1;

      let opacityTestimonial = this.value(
        sectionTop,
        0,
        1,
        -0.15 * this.$store.state.windowHeight,
        -this.$store.state.windowHeight / 2,
        "inOut",
        2
      );
      this.testimonial.style.opacity = opacityTestimonial;

      this.image1.style.opacity = opacityImage1;
      this.imageCaption.style.opacity = 1 - 1.1 * opacityTestimonial;

      let sectionBottom = this.bottom(this.testimonials2);

      let translateCover = this.value(
        sectionBottom,
        0,
        this.$store.state.windowHeight / 2,
        0.5 * this.$store.state.windowHeight,
        0,
        "in",
        1
      );
      this.cover.style.transform = "translateY(" + translateCover + "px)";

      if (sectionBottom <= 0) {
        this.cover.style.visibility = "hidden";
      } else {
        this.cover.style.visibility = "visible";
      }
    }
  },
  created() {
    setTimeout(() => {
      this.testimonials2 = document.querySelector(".testimonials2");
      this.container = document.querySelector(".testimonials2__container");
      this.stickyContainer = document.querySelector(
        ".testimonials2__sticky-container"
      );
      this.image1 = document.querySelector(".testimonials2__image--1");
      this.testimonial = document.querySelector(".testimonials2__testimonial");
      this.imageCaption = document.querySelector(
        ".testimonials2__image-caption"
      );

      // Next testimonial section
      this.cover = document.querySelector(".testimonials3__bottom-cover");

      this.scrollHandler();

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.testimonials2 {
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  background-color: #101010;
  color: $color-white;

  display: grid;
  grid-template-columns: 1fr $width-center 1fr;

  @include respond(tab-land) {
    grid-template-columns:
      1fr min(100% - 2 * #{$side-margin-land}, #{$width-center})
      1fr;
  }

  @include respond(phone) {
    grid-template-columns: 1.5rem 1fr 1.5rem;
    padding-bottom: 15rem;
  }

  &__container {
    position: relative;
    grid-column: 2 / 3;
    padding-bottom: 12rem;
    height: calc(80vh + max(50vh, 68.24554rem)); // was: 150vh
  }

  &__sticky-container {
    position: sticky;
    top: clamp(7rem, 50vh - 34.12277rem, 23.5vh);
    width: 100%;
    height: max(50vh, 68.24554rem);

    // transform: translateZ(1px);

    @include respond(phone) {
      top: 7.5rem !important;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 68.24554rem;
    background-color: $color-black;

    overflow: hidden;

    @include respond(phone) {
      height: calc(0.66667 * (100vw - 3rem));
    }

    &::after {
      @include pseudo-element;
      bottom: 0;
      z-index: 100;
      width: 100%;
      height: calc(68.24554rem - 63.4vw);
      background-color: #101010;
      // background-color: red;
    }

    @include respond(phone) {
      &::after {
        content: initial;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;

    &--1 {
      z-index: 10;
      transform-origin: center 75%;
      transition: transform 0.05s linear;
      will-change: transform;
    }

    &--2 {
      transform: translate(min(11.1rem, 100vw - 86rem), 6rem);
      width: initial;
      // height: 79.79232%;
      height: 72.5%;
      // height: 70%;

      @include respond(phone) {
        position: relative;
        height: 85%;
        transform: translate(calc(50vw - 1.6rem - 50%), 5%);
      }
    }
  }

  &__testimonial {
    position: absolute;
    right: 4rem;
    z-index: 1;
    // transform: translateY(calc(-100% - 19.7rem));
    transform: translateY(
      calc(-100% - 20rem - max(68.24554rem - 63.4vw - 6.5rem, 0rem))
    );
    margin-top: 8.375rem;
    width: 40rem;
    font-size: 2.4rem;
    color: rgb(160, 160, 160);

    @include respond(phone) {
      position: relative;
      transform: none;
      height: 100vh;

      right: 0;
      // margin-top: 5.5rem;
      margin-top: 5rem;
      margin-left: 0;
      width: 100%;
      font-size: 2.6rem;

      opacity: 1 !important;
    }
  }

  &__testimonial-heading {
    display: block;
    margin-bottom: 0.75rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    color: #f9f9f9;

    @include respond(phone) {
      font-size: 2.8rem;
    }
  }

  &__testimonial-quotation {
    position: relative;
    z-index: 2;
    font-style: italic;
  }

  &__signature {
    visibility: hidden;
    position: relative;
    z-index: 0;
    // margin-top: 4.4rem;
    margin-top: 1.4rem;
    width: 28.5rem;
    margin-bottom: 18.6rem;
  }

  &__testimonial-name {
    position: relative;
    display: block;
    z-index: 10;
    margin-top: -17.4rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    color: #f9f9f9;

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__testimonial-name-description {
    position: relative;
    display: block;
    z-index: 10;
    font-size: 2.2rem;
    color: rgb(217, 217, 217);

    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &__image-caption {
    position: absolute;
    right: 0;
    top: 68.7rem;
    z-index: 10000;
    transform: translateY(min(63.4vw - 68.24554rem, 0rem));
    border-radius: 0.1rem;
    font-size: 2rem;
    color: rgb(220, 220, 220);

    @include respond(phone) {
      display: none;
    }
  }
}
</style>
