<template>
  <teleport to="body">
    <div
      class="glossary-modal"
      :class="{
        'glossary-modal--is-shown': isShown,
        'glossary-modal--is-on-top': $store.state.glossaryIsOnTop,
        'glossary-modal--is-fixed': $store.state.glossaryIsFixed,
        'glossary-modal--flex':
          'imageData' in glossary && glossary.imageData.height === undefined
      }"
      :key="
        language +
          'topic' +
          $store.state.glossaryTopic +
          'subtopic' +
          $store.state.glossarySubtopic +
          'paragraph' +
          $store.state.glossaryParagraph
      "
      @mouseenter="toggleGlossaryModal(true, topic, subtopic, paragraph)"
      @mouseleave="toggleGlossaryModal(false, topic, subtopic, paragraph)"
    >
      <div class="glossary-modal__text-container">
        <h3 class="glossary-modal__heading">
          {{ t(glossary.heading) }}
        </h3>
        {{ t(glossary.text) }}
      </div>
      <div class="glossary-modal__picture-container">
        <app-image
          :svg="glossary.image"
          :imageData="glossary.imageData"
          :imageText="glossary.imageText"
        ></app-image>
      </div>
      <div class="glossary-modal__arrow"></div>
    </div>
  </teleport>
</template>

<script>
import getGlossary from "@/glossary.json";

import AppImage from "@/components/Organisms/Image.vue";

export default {
  name: "glossaryModal",
  props: {
    topic: {
      type: String
    },
    subtopic: {
      type: String
    },
    paragraph: {
      type: String
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    arrow: {
      type: Number,
      default: 0
    },
    isShown: {
      type: Boolean
    }
  },
  data() {
    return {
      getGlossary
    };
  },
  components: {
    AppImage
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    glossary() {
      let glossaryContents = this.getGlossary.glossary;

      if (
        this.topic in glossaryContents &&
        this.subtopic in glossaryContents[this.topic] &&
        this.paragraph in glossaryContents[this.topic][this.subtopic]
      ) {
        return glossaryContents[this.topic][this.subtopic][this.paragraph];
      } else {
        return {
          heading: "",
          text: "",
          image: ""
        };
      }
    }
  },
  methods: {
    setPositions() {
      setTimeout(() => {
        let element = document.querySelector(".glossary-modal");
        element.style.left = this.left + "px";
        element.style.top = this.top + "px";
        let arrow = document.querySelector(".glossary-modal__arrow");
        arrow.style.transform = "translateX(" + this.arrow + "px)";
      }, 0);
    }
  },
  created() {
    this.setPositions();

    let vm = this;
    window.addEventListener("resize", vm.toggleGlossaryModal, false);
  },
  watch: {
    left() {
      this.setPositions();
    },
    top() {
      this.setPositions();
    },
    arrow() {
      this.setPositions();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.glossary-modal {
  position: absolute;
  visibility: hidden;
  left: -1000px;
  top: -1000px;
  z-index: 1000000;
  padding: 0.375rem;
  border-radius: 0.4rem;
  color: $color-white;
  background-color: $color-primary-dark; // was: darker
  border: 0.5px solid #131313;
  box-shadow: 0 0 1.25rem #131313;

  transform: translateY(2.25rem) scaleY(0.8);
  transform-origin: bottom;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s ease-out, visibility 0s 0.3s;

  @include respond(phone) {
    position: fixed;
    left: 0 !important;
    width: 100vw;
    padding: 1.5rem 0.75rem;
    border-radius: 0.6rem;
  }

  &--is-on-top {
    transform: translateY(-2.25rem) scaleY(0.8);
    transform-origin: top;
  }

  &--is-shown {
    visibility: visible;
    transform: none;
    opacity: 1;
    transition: transform 0.3s, opacity 0.3s;
  }

  &--is-fixed {
    position: fixed;
  }

  &--flex {
    display: flex;

    @include respond(phone) {
      display: block;
    }
  }

  &::after {
    @include pseudo-element;
    left: 0;
    top: -12px;
    z-index: -10;
    width: 100%;
    height: calc(100% + 24px);
  }

  &__text-container {
    float: left;
    width: calc(38rem - 4.5rem);
    padding: 1rem;
    padding-bottom: 0;
    padding: 0.875rem 0.875rem 0 1.125rem;
    border-radius: 0.6rem;
    font-size: 1.8rem;
    line-height: 1.25;
    color: #e5e5e5;

    @include respond(phone) {
      float: initial;
      position: relative;
      width: 100vw !important;
      height: min-content;
      padding-top: 0;
      font-size: 2.6rem;
    }
  }

  &__heading {
    margin-bottom: 0.4rem;
    font-family: $font-primary-bold;
    font-size: 1.8rem;
    line-height: 1.25;
    color: $color-white;

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__picture-container {
    float: right;
    margin-left: 0.75rem;
    border-radius: 0.4rem;
    background-color: $color-black;

    @include respond(phone) {
      float: initial;
      width: calc(100vw - 3rem) !important;
      height: max-content;
      border-radius: 0.6rem;
    }
  }

  &__arrow {
    position: absolute;
    left: 5rem;
    top: -0.95rem;
    width: 2rem;
    height: 1rem;
    overflow: hidden;

    &::after {
      @include pseudo-element;
      top: 0.35rem;
      width: 100%;
      height: 2rem;
      transform: rotate(45deg);
      background-color: $color-primary-dark; // was: darker
      border-bottom: 0.5px solid #131313;
      border-right: 0.5px solid #131313;
      border-bottom-right-radius: 0.2rem;
    }

    .glossary-modal--is-on-top & {
      top: initial;
      bottom: 0.3rem;
      overflow: initial;
    }
  }
}

.mathjax-formula {
  // display: block;
  // margin: -8px 0;
}
</style>
