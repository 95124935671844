<template>
  <div class="testimonials3">
    <div class="testimonials3__sticky-container">
      <app-testimonial
        data="testimonials_student_4"
        image="testimonial_0.png"
        :opacity="getOpacity(0)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[0]"
      ></app-testimonial>
      <app-testimonial
        data="testimonials_student_3"
        image="testimonial_1.png"
        :opacity="getOpacity(1)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[1]"
      ></app-testimonial>
      <app-testimonial
        data="testimonials_student_2"
        image="testimonial_2.png"
        :opacity="getOpacity(2)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[2]"
      ></app-testimonial>
      <app-testimonial
        data="testimonials_student_1"
        image="testimonial_3.png"
        :opacity="getOpacity(3)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[3]"
      ></app-testimonial>
      <app-testimonial
        data="testimonials_student_6"
        image="testimonial_4.png"
        :opacity="getOpacity(4)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[4]"
      ></app-testimonial>
      <app-testimonial
        data="testimonials_student_5"
        image="testimonial_5.png"
        :opacity="getOpacity(5)"
        class="testimonials3__testimonial"
        :class="'testimonials3__testimonial--' + testimonialOrder[5]"
      ></app-testimonial>
      <div
        class="testimonials3__arrow testimonials3__arrow--left"
        :class="{ 'testimonials3__arrow--is-shown': arrowsAreShown }"
        @click="mutateOrder('left')"
      ></div>
      <div
        class="testimonials3__arrow testimonials3__arrow--right"
        :class="{ 'testimonials3__arrow--is-shown': arrowsAreShown }"
        @click="mutateOrder('right')"
      ></div>
      <div class="testimonials3__bottom-cover"></div>
    </div>
  </div>
</template>

<script>
import AppTestimonial from "@/components/Organisms/Testimonial.vue";

export default {
  data() {
    return {
      testimonialOrder: [1, 2, 3, 4, 5, 6],
      testimonialElements: [],
      sectionTop: 10000,
      arrowsAreShown: false
    };
  },
  components: {
    AppTestimonial
  },
  methods: {
    getOpacity(index) {
      if (this.sectionTop >= -this.$store.state.windowHeight / 5) {
        return 0;
      } else if (this.testimonialOrder[index] === 4) {
        return 1;
      } else if (
        this.testimonialOrder[index] === 3 ||
        this.testimonialOrder[index] === 5
      ) {
        let media = window.matchMedia("(max-width: 600px)");
        if (media.matches) {
          return 0;
        } else {
          return 0.325;
        }
      } else {
        return 0;
      }
    },
    mutateOrder(direction) {
      if (direction === "right") {
        this.testimonialOrder.push(this.testimonialOrder[0]);
        this.testimonialOrder = this.testimonialOrder.slice(1, 7);

        this.testimonialElements.unshift(this.testimonialElements[5]);
        this.testimonialElements = this.testimonialElements.slice(0, 6);
        this.scrollHandler();
      } else {
        this.testimonialOrder.unshift(this.testimonialOrder[5]);
        this.testimonialOrder = this.testimonialOrder.slice(0, 6);

        this.testimonialElements.push(this.testimonialElements[0]);
        this.testimonialElements = this.testimonialElements.slice(1, 7);
        this.scrollHandler();
      }
    },
    scrollHandler() {
      let sectionTop = this.top(this.section);
      this.sectionTop = sectionTop;
      if (this.sectionTop < -2 * this.$store.state.windowHeight) {
        this.stickyContainer.style.display = "none";
        return;
      }
      this.stickyContainer.style.display = "block";

      let backgroundColor = this.value(
        sectionTop,
        0,
        26,
        this.$store.state.windowHeight / 4,
        -(1 / 2) * this.$store.state.windowHeight,
        "in",
        1
      );
      this.stickyContainer.style.backgroundColor =
        "rgba(" +
        backgroundColor +
        " ," +
        backgroundColor +
        " ," +
        backgroundColor +
        ")";

      let scaleTestimonial = this.value(
        sectionTop,
        1,
        0.1,
        0.5 * this.$store.state.windowHeight, // 0.563
        -0.4 * this.$store.state.windowHeight, // was: -0.75
        "inOut",
        2
      );
      let opacityTestimonial = this.value(
        sectionTop,
        1,
        0.325,
        0.1 * this.$store.state.windowHeight,
        -0.4 * this.$store.state.windowHeight,
        "inOut",
        2
      );

      if (sectionTop >= 0) {
        opacityTestimonial = this.value(
          sectionTop,
          0,
          1,
          0.225 * this.$store.state.windowHeight,
          0.1 * this.$store.state.windowHeight,
          "inOut",
          2
        );
      }

      if (sectionTop < -0.35 * this.$store.state.windowHeight) {
        this.arrowsAreShown = true;
        for (let i = 0; i < this.testimonialElements.length; i++) {
          this.testimonialElements[i].style.transition =
            "transform 0.1s linear, opacity 1s";
        }
      } else {
        this.arrowsAreShown = false;
        for (let i = 0; i < this.testimonialElements.length; i++) {
          this.testimonialElements[i].style.transition =
            "transform 0.1s linear";
        }
      }

      let media = window.matchMedia("(max-width: 600px)");

      this.testimonialElements[3].style.transform =
        "scale(" + scaleTestimonial + ")";
      this.testimonialElements[2].style.transform =
        "scale(" + scaleTestimonial + ")";
      this.testimonialElements[4].style.transform =
        "scale(" + scaleTestimonial + ")";
      this.testimonialElements[3].style.opacity = 1;
      this.testimonialElements[2].style.opacity = opacityTestimonial;
      this.testimonialElements[4].style.opacity = opacityTestimonial;

      if (media.matches) {
        this.testimonialElements[2].style.opacity = 0;
        this.testimonialElements[4].style.opacity = 0;
      }

      this.testimonialElements[0].style.transform = "scale(0.1)";
      this.testimonialElements[1].style.transform = "scale(0.1)";
      this.testimonialElements[5].style.transform = "scale(0.1)";
      this.testimonialElements[0].style.opacity = 0;
      this.testimonialElements[1].style.opacity = 0;
      this.testimonialElements[5].style.opacity = 0;
    }
  },
  created() {
    setTimeout(() => {
      this.section = document.querySelector(".testimonials3");
      this.stickyContainer = document.querySelector(
        ".testimonials3__sticky-container"
      );

      for (let i = 0; i < this.testimonialOrder.length; i++) {
        let j = i + 1;
        this.testimonialElements[i] = document.querySelector(
          ".testimonials3__testimonial--" + j + " .testimonial-image"
        );
      }

      this.scrollHandler();

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.testimonials3 {
  position: relative;
  z-index: 0;
  min-height: calc(262.5vh + 10rem);
  margin-bottom: -100vh;
  background-color: #1a1a1a;

  min-height: 300vh;

  &__sticky-container {
    position: sticky;
    top: 100vh;
    width: 100%;
    // height: max(100vh, 90.86977rem);
    height: max(100vh, 83.86977rem + clamp(7rem, 50vh - 32.93489rem, 18.07vh));
    background-color: $color-black;

    transform: translateY(-100vh);
    overflow: hidden;
  }

  &__bottom-cover {
    position: absolute;
    bottom: 0;
    bottom: calc(100% - 100vh);
    z-index: 2;
    width: 100%;
    height: 50vh;
    background-color: #101010;
  }

  &__testimonial {
    position: absolute;
    left: 50%;
    top: clamp(7rem, 50vh - 32.93489rem, 18.07vh);

    transition: transform 1s;
    will-change: transform;
    transform-origin: bottom;

    @include respond(phone) {
      transition: transform 0.667s;
    }

    &--1 {
      transform: translateX(-50%);
    }

    &--2 {
      transform: translateX(150%) scale(0.9025);
    }

    &--3 {
      transform: translateX(52.63158%) scale(0.95);
    }

    &--4 {
      transform: translateX(-50%);
      z-index: 1;
    }

    &--5 {
      transform: translateX(-152.63158%) scale(0.95);
    }

    &--6 {
      transform: translateX(-250%) scale(0.9025);
    }
  }

  &__arrow {
    position: absolute;
    top: calc(45vh - 1.75rem);
    z-index: 200;
    width: 3.5rem;
    height: 3.5rem;
    border-left: 2px solid rgb(200, 200, 200);
    border-bottom: 2px solid rgb(200, 200, 200);
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    transition: transform 0.5s ease-in-out, opacity 0.5s, visibility 0s 0.5s;

    @include respond(phone) {
      transition: transform 0.333s ease-in-out, opacity 0.333s,
        visibility 0s 0.333s;
    }

    @include respond(phone) {
      top: calc(50vh - 1.75rem);
    }

    &::after {
      @include pseudo-element;
      left: -2.25rem;
      top: -2.25rem;
      width: 8rem;
      height: 8rem;

      @include respond(phone) {
        left: -4.95rem;
        top: -4.95rem;
        width: 14rem;
        height: 14rem;
      }

      // background-color: rgba(green, 0.5);
    }

    &.testimonials3__arrow--is-shown {
      opacity: 1;
      visibility: visible;
      transition: transform 0.5s ease-in-out, opacity 0.5s;

      @include respond(phone) {
        transition: transform 0.333s ease-out, opacity 0.333s;
      }
    }

    &--left {
      // left: calc(50% - 57.2rem);
      left: max(calc(50vw - 57.2rem), 2.25rem);
      transform: translateX(2rem) rotate(45deg);

      @include respond(phone) {
        left: 2.2rem;
        // left: -3rem;
      }

      &.testimonials3__arrow--is-shown {
        transform: rotate(45deg);

        @include respond(phone) {
          // transform: translateX(5.2rem) rotate(45deg);
        }
      }
    }

    &--right {
      // right: calc(50% - 57.2rem);
      right: max(calc(50vw - 57.2rem), 2.25rem);
      transform: translateX(-2rem) rotate(-135deg);

      @include respond(phone) {
        right: 2.2rem;
        // right: -3rem;
      }

      &.testimonials3__arrow--is-shown {
        transform: rotate(-135deg);

        @include respond(phone) {
          // transform: translateX(-5.2rem) rotate(-135deg);
        }
      }
    }
  }
}
</style>
