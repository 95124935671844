<template>
  <div class="effect-button__border">
    <div type="button" onclick="this.blur()" tabindex="0" class="effect-button">
      <div class="effect-button__moving-text">
        <span>
          {{ text[1] }}
        </span>
        <span>
          {{ text[0] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "effectButton",
  props: {
    text: Array
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.effect-button__border {
  // transform: translateY(1rem);
  position: relative;
  // width: max-content;
  height: 5.5rem;
  // border: 1px solid orange;
  border: 1px solid $color-grey-light;
  // border-left: 1.11111px solid $color-grey-light;
  // border-right: 1.11111px solid $color-grey-light;
  border-left: 1px solid $color-grey-light;
  border-right: 1px solid $color-grey-light;
  border-radius: 100rem;
  // transform: scaleX(0.9);
  // transform-origin: left;
}

.effect-button {
  @include button-settings;
  // transform: scale(1.005) translateY(-0.7rem);
  // transform: scale(1.01) translateY(-0.7rem);

  position: relative;
  // top: 0;

  transform: scale(1.01);
  // transform-origin: bottom;
  width: auto;
  height: 5.5rem;
  // font-size: $font-size-big;
  font-size: 2.9rem;
  font-weight: 400;
  // // // color: $color-grey-light;
  color: #2a2a2a;
  border-radius: 100rem;
  background-color: transparent;
  z-index: 1;
  overflow: hidden;

  & span:first-child {
    color: $color-white;
  }

  /* @media (hover: hover) and (pointer: fine) {
    &:hover span:first-child {
      // color: $color-white;
      // color: transparent;
      color: $color-white;
      background-color: transparent;
    }
  } */

  &__moving-text {
    will-change: transform;
  }

  & + &__moving-text {
    // background-color: green;
    width: initial;
  }

  &:active {
    outline: none;
  }

  & &__moving-text {
    height: 16.5rem;
    transform: translateY(-8.3rem);
    // transform: translateY(0);
    padding: 0 4.125rem; // 3.55 or 3.15
    // // border: 1px solid red;
    text-align: center;
    background: linear-gradient(
      to bottom,
      $color-black 0,
      $color-black 50%,
      transparent 50%,
      transparent 100%
    );

    backface-visibility: hidden;
    transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.35);

    & span {
      display: block;
      height: 5.5rem;
      // transform: scaleX(1.11111) translateY(4rem);
      // transform: translateY(4rem);
      transform: translateY(4.025rem);

      @include respond(phone) {
        // color: $color-white;
        font-size: 3.4rem; // was: 3.6rem
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover .effect-button__moving-text {
      transform: translateY(-2.75rem);
    }
  }
}
</style>
