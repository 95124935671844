<template>
  <div class="onboarding-continue-course">
    <div class="onboarding-continue-course__progress-bar"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding-continue-course {
  width: 100%;
  height: calc(100% - 1.6rem);

  &__progress-bar {
    position: absolute;
    left: 50%;
    top: 51%;
    transform: translateX(-50%);
    width: 12rem;
    height: 0.7rem;
    border-radius: 10rem;
    background: linear-gradient(
      to right,
      $color-primary 0%,
      $color-primary 60%,
      #e8e8e8 60%,
      #e8e8e8 100%
    );

    &::after {
      @include pseudo-element;
      left: 54.775%;
      top: -2.2rem;
      width: 1.25rem;
      height: 1.25rem;
      transform: rotate(45deg);
      background: linear-gradient(
        to right bottom,
        transparent 0%,
        transparent 50%,
        #d2d2d2 50%,
        #d2d2d2 100%
      );
    }
  }
}
</style>
