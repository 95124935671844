<template>
  <teleport to="body">
    <div
      class="alert"
      :class="{
        'alert__is-visible': alertIsVisible,
        'alert__is-transformed': alertIsTransformed
      }"
    >
      <div @mousedown="toggleAlert()" class="alert__background"></div>
      <div class="alert__message">
        {{ $store.state.alertText }}
        <div class="alert__button-container">
          <app-button
            v-if="$store.state.alertTextGhostButton !== ''"
            @click="$store.state.alertGhostAction()"
            :text="$store.state.alertTextGhostButton"
            class="alert__button alert__button--1"
          ></app-button>
          <app-button
            @click="toggleAlert()"
            text="Ok"
            class="alert__button"
          ></app-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
export default {
  name: "alert",
  data() {
    return {
      alertIsVisible: false,
      alertIsTransformed: false
    };
  },

  components: {
    AppButton
  },
  computed: {
    alertText() {
      return this.$store.state.alertText;
    }
  },
  watch: {
    alertText(to) {
      if (to !== "") {
        this.alertIsVisible = true;
        setTimeout(() => {
          this.alertIsTransformed = true;
        }, 15);
      } else {
        this.alertIsTransformed = false;
        setTimeout(() => {
          this.alertIsVisible = false;
        }, 500);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.alert {
  &__background {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3500000000;
    width: 100%;
    height: 100vh;
    background-color: rgba($color-black, 0.425);
    opacity: 0;
    transition: opacity 0.5s;

    .alert__is-visible & {
      display: block;
    }

    .alert__is-transformed & {
      opacity: 1;
    }
  }

  &__message {
    display: none;
    position: fixed;
    left: 50vw;
    top: 50vh;
    z-index: 4000000000;
    width: 43.25rem;
    padding: 2.2rem;
    border-radius: 1rem;
    font-family: $font-primary;
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 100;
    color: #111;
    background-color: $color-white;

    opacity: 0;
    transform: translate(-50%, -50%) scale(0.75);
    transition: opacity 0.125s ease-out, transform 0.225s;

    @include respond(tab-port) {
      width: 46rem;
      font-size: 2.4rem;
    }

    @include respond(phone) {
      width: calc(100% - 3rem);
      font-size: 2.8rem;
      color: $color-black;
    }

    .alert__is-visible & {
      display: block;
    }

    .alert__is-transformed & {
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: opacity 0.5s, transform 0.5s;
    }
  }

  &__button-container {
    margin-top: 4rem;
    width: max-content;
    transform: translateX(calc(38.85rem - 100%));

    @include respond(tab-port) {
      transform: translateX(calc(41.6rem - 100%));
    }

    @include respond(phone) {
      transform: translateX(calc(100vw - 100% - 7.5rem));
    }
  }

  &__button {
    float: left;
    padding: 0.7rem 2rem;

    @include respond(phone) {
      font-size: 2.6rem;
    }

    &--1 {
      letter-spacing: -0.05px;
      color: #444;
      background-color: transparent;
      transition: color 0.2s;

      &:hover {
        color: $color-primary;
        background-color: transparent;
      }
    }
  }
}
</style>
