<template>
  <div class="register-modal tabstart" tabindex="0">
    <h2 class="register-modal__heading">
      {{ $t("modal_email_verified")["heading"] }}
    </h2>
    <app-lottie
      :lottie="'mail'"
      :timeout="375"
      :speed="1.15"
      class="register-modal__animation"
    ></app-lottie>
    <div class="register-modal__instructions-container">
      <p
        class="register-modal__instructions"
        :class="{
          'register-modal__instructions--are-shown': instructionsAreShown
        }"
      >
        {{ $t("modal_email_verified")["message"] }}
      </p>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";
import AppLottie from "@/components/Views/Lottiecontainer.vue";

export default {
  name: "appRegisterForm",
  components: {
    AppButtonXRound,
    AppLottie
  },
  data() {
    return {
      instructionsAreShown: false
    };
  },
  computed: {
    registrationSuccessful() {
      return this.$store.state.registrationSuccessful;
    }
  },
  created() {
    setTimeout(() => {
      this.instructionsAreShown = true;
      this.$store.state.modalMayBeClosed = false;
      setTimeout(() => {
        this.$store.state.modalMayBeClosed = true;
      }, 3000);
    }, 0);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.register-modal {
  width: 46rem;
  height: 41rem; // was 40rem
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
    height: 46rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;
  }

  &__animation {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 33.33333%;
    transform: translate(-50%, calc(-50% - 1.55rem));
  }

  &__instructions-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    overflow: hidden;
  }

  &__instructions {
    position: absolute;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 0.75rem 1.75rem;
    border-top: none;
    border-radius: 0.95rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 2rem;
    color: #fff;

    background-color: #008000;

    transform: translateY(100%);
    transition: transform 0.5s 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.35); // 2.825s

    &--are-shown {
      transform: none;
    }
  }
}
</style>
