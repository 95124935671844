<template>
  <div class="closing-x">
    <div aria-hidden="true"></div>
    <button type="button" onclick="this.blur()" class="closing-x-button">
      <div aria-hidden="true">
        <div class="closing-x__bar closing-x__bar--1"></div>
        <div class="closing-x__bar closing-x__bar--2"></div>
      </div>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.closing-x {
  position: absolute;
  right: 0;
  top: 0;
  width: 5.5rem;
  height: 5.5rem;

  @include respond(phone) {
    // right: 2.1875rem;
    right: 2.1rem;
    // top: 1.725rem;
    top: 1.6375rem;
    // top: 1rem;
    transform: scale(1.15);
  }

  & > div {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include respond(phone) {
      overflow: visible;
    }

    &::after {
      @include pseudo-element;
      position: absolute;
      left: calc((5.5rem - 0.2rem - 2.14142rem) / 2);
      top: calc((5.5rem + 0.2rem - 2rem) / (-2));
      box-sizing: initial;
      width: 100%;
      height: 100%;
      // border: 0.2rem solid #333;
      border: 0.2rem solid #2e2e2e;
      border-radius: 50%;

      @include respond(phone) {
        left: calc((5.5rem - 0.375rem - 2.05rem) / 2);
        top: calc((5.5rem + 0.375rem - 2.15rem) / (-2));
        border: 0.375rem solid #2e2e2e;
      }
    }
  }

  & button {
    position: absolute;
    left: calc((5.5rem + 0.2rem - 2.14142rem) / 2);
    top: calc((5.5rem - 0.2rem - 2rem) / (-2));
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
    background: radial-gradient(
      #222 0,
      $color-black calc(0.5 * 5.5rem),
      transparent calc(0.5 * 5.5rem),
      transparent 100%
    );
    cursor: pointer;

    @include respond(phone) {
      left: calc((5.5rem + 0.375rem - 2.1rem) / 2);
      top: calc((5.5rem - 0.375rem - 2.1rem) / (-2));
    }

    & div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: rotate(45deg);

      will-change: transform;
      transition: transform 0.25s;

      & .closing-x__bar {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 2.82843rem;
        height: 0.2rem;
        // border-radius: 1rem;
        border-radius: 0;
        background-color: $color-white;

        will-change: transform;
        transition: transform 0.25s 0.3s;

        &--1 {
          transform: translate(-50%, -50%);
        }

        &--2 {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover div {
        transform: rotate(-45deg);

        & .closing-x__bar--1,
        & .closing-x__bar--2 {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }

    &:focus {
      // outline: none;
    }
  }
}
</style>
