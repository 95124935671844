<template>
  <div class="testimonial">
    <div class="testimonial-container" :style="{ opacity: opacity }">
      <div class="testimonial-head">
        <span class="testimonial-name">{{ $t(data).name }}</span>
        <span class="testimonial-data"
          >{{ $t(data).subject
          }}<!--,--></span
        >
        <span class="testimonial-data testimonial-college">{{
          $t(data).college
        }}</span>
        <!-- <img :src="url" alt="Eine Studentin" class="testimonial-image" /> -->
      </div>
      <div class="testimonial-text-container">
        <span class="quotation-mark">&ldquo;</span>
        <div class="testimonial-quotation">
          {{ $t(data).quotation }}
        </div>
      </div>
    </div>
    <img
      :src="require('@/assets/Home/Testimonials3/' + image)"
      alt="Eine Studentin"
      class="testimonial-image"
    />
  </div>
</template>

<script>
export default {
  name: "Testimonial",
  props: {
    data: String,
    image: String,
    opacity: Number
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

$wt: 34.13333rem; // = width of testimonial

.testimonial {
  position: relative;
  width: $wt;
  height: calc(2.10556 * #{$wt});
  color: $color-white;
}

.testimonial-container {
  height: 100%;
  border-radius: 1rem;
  background-color: #101010;
  overflow: hidden;
  transition: opacity 1s;
}

.testimonial-head {
  width: $wt;
  height: calc(1.09167 * #{$wt});

  &::after {
    display: block;
    content: "";
    width: calc(1.38889 * #{$wt});
    height: calc(1.38889 * #{$wt});
    border-radius: 0.6rem;
    border: 0.2rem solid #242424;
    background-color: $color-black;

    transform: translate(calc(-0.19444 * #{$wt}), calc(-0.55556 * #{$wt}))
      scaleX(2) rotate(45deg);
  }
}

.testimonial-name {
  position: absolute;
  top: calc(0.04167 * #{$wt});
  transform: translateX(calc(0.5 * #{$wt} - 50%));
  font-size: 3.4rem; // was 3.6rem
  z-index: 1;

  &::after {
    @include pseudo-element;
    left: 50%;
    width: calc(0.19444 * #{$wt});
    height: 0.25rem;
    transform: translate(-50%, 0.45rem);
    border-radius: 1rem;
    background-color: $color-secondary;
  }
}

.testimonial-data {
  position: absolute;
  top: calc(0.25 * #{$wt});
  z-index: 1;
  transform: translateX(calc(0.5 * #{$wt} - 50%));
  font-size: 2.2rem; // was 2.3rem
}

.testimonial-college {
  top: calc(0.34028 * #{$wt});
}

.testimonial-image {
  position: absolute;
  top: calc(-1 * clamp(7rem, 50vh - 32.93489rem, 18.07vh) + 50vh - 94.8rem);
  left: calc(0.5 * #{$wt} - 0.5 * 10 * 0.36111 * #{$wt});
  width: calc(10 * 0.36111 * #{$wt});
  z-index: 1;
  transition: transform 0.1s linear;
  transform-origin: center
    calc(50% - 50vh + clamp(7rem, 50vh - 32.93489rem, 18.07vh) + 24.8rem);
  will-change: transform;
}

.testimonial-text-container {
  position: absolute;
  left: 0;
  top: calc(1.1194 * #{$wt});
  width: $wt;
  height: calc(1.01389 * #{$wt});
  color: #ccc;
}

.quotation-mark {
  position: absolute;
  left: calc(0.01806 * #{$wt});
  top: calc(-0.125 * #{$wt});
  font-family: "Times New Roman", Times, serif;
  font-size: calc(0.30556 * #{$wt});
  // color: #a8a8a8;
  color: transparent;
}

.testimonial-quotation {
  margin-top: calc(0.14583 * #{$wt});
  padding: 0 calc(0.0625 *#{$wt});
  font-size: 2.1rem; // was 2.1rem
}
</style>
