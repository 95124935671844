<template>
  <button type="button" onclick="this.blur()" class="footer-link">
    <span> {{ text[0] }}&thinsp; </span>
    <span class="span-link">
      {{ text[1] }}
    </span>
  </button>
</template>

<script>
export default {
  name: "footerLink",
  props: {
    text: Array
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.footer-link {
  @include button-settings;
  width: 100%;
  margin-top: 5.5rem;
  height: 5.5rem;
  font-size: 2.2rem;
  line-height: 1;
  border-top: 1px solid #333;
  color: $color-white;

  @include respond(phone) {
    height: 6.5rem;
    font-size: 3rem;
  }

  & span {
    display: inline-block;
    transform: translateY(0.1rem);

    transition: color 0.2s;
  }

  & .span-link {
    margin-left: 0.4rem;
    font-family: $font-primary-bold;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover span {
      color: #1d9bf0;
      text-decoration: underline;
    }
  }

  &:focus span {
    color: #1d9bf0;
    text-decoration: underline;
  }
}
</style>
