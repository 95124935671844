<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    style="enable-background:new 0 0 1000 1000;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M500,0C223.9,0,0,223.9,0,500s223.9,500,500,500s500-223.9,500-500C999.6,224,776,0.4,500,0z M536.6,500
			c-0.1,10.5-4.6,20.5-12.4,27.6L329,698.3c-15.2,13.5-38.5,12.1-52-3.2c-13.5-15.2-12.1-38.5,3.2-52l0,0l183.2-159.8V182.9
			c0-20.2,16.4-36.6,36.6-36.6c20.2,0,36.6,16.4,36.6,36.6V500z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #666666;
}
</style>
