<template>
  <div class="testimonials">
    <h2 class="heading-secondary testimonials__heading">
      {{ $t("testimonials1_heading") }}
    </h2>
    <p class="testimonials__introduction">
      {{ $t("testimonials1_paragraph") }}
    </p>
    <div class="testimonials__container">
      <div class="testimonials__sticky-container">
        <div class="testimonials__image-container">
          <picture>
            <!-- <source
              type="image/avif"
              srcset="@/assets/Home/Testimonials1/university_1.avif"
              class="testimonials__image testimonials__image--1"
            />
            <source
              type="image/webp"
              srcset="@/assets/Home/Testimonials1/university_1.webp"
              class="testimonials__image testimonials__image--1"
            /> -->
            <!-- <img
              src="@/assets/Home/Testimonials1/university_1.avif"
              :alt="$t('testimonials1_university')"
              class="testimonials__image testimonials__image--1"
            /> -->
            <!-- <img
              src="@/assets/Home/Testimonials1/university_1.webp"
              :alt="$t('testimonials1_university')"
              class="testimonials__image testimonials__image--1"
            /> -->
            <img
              src="@/assets/Home/Testimonials1/univ_1n4.png"
              :alt="$t('testimonials1_university')"
              class="testimonials__image testimonials__image--1"
            />
          </picture>
          <img
            src="@/assets/Home/Testimonials1/testimonial_top.png"
            :alt="$t('testimonials1_person_image')"
            class="testimonials__image testimonials__image--2"
          />
        </div>
        <figcaption class="testimonials__image-caption">
          {{ $t("testimonials1_university") }}
        </figcaption>
        <div class="testimonials__flicker-stop"></div>
        <p class="testimonials__testimonial">
          <span class="testimonials__testimonial-heading">{{
            $t("testimonials1_subheading")
          }}</span>
          <span class="testimonials__testimonial-quotation">
            <!-- &bdquo; -->{{ $t("testimonials1_quotation")
            }}<!-- &ldquo; -->
            <br />
          </span>
          <img
            src="@/assets/Home/Testimonials1/signature_1.png"
            :alt="$t('testimonials1_person_signature')"
            class="testimonials__signature"
          />
          <span class="testimonials__testimonial-name">{{
            $t("testimonials1_name")
          }}</span>
          <span class="testimonials__testimonial-name-description">{{
            $t("testimonials1_name_description")
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollHandler() {
      // let sectionTop = this.top(this.testimonials);
      let containerTop = this.top(this.container);

      // Handle position for smoother scrolling
      /* if (sectionTop > 0 && this.$store.state.windowWidth > 600) {
        this.stickyContainer.style.position = "absolute";
        this.stickyContainer.style.top = 0;
      } else if (
        containerTop < -0.565 * this.$store.state.windowHeight &&
        this.$store.state.windowWidth > 600
      ) {
        this.stickyContainer.style.position = "absolute";
        this.stickyContainer.style.top = "initial";
        this.stickyContainer.style.bottom = 0;
      } else {
        this.stickyContainer.style.position = "sticky";
        this.stickyContainer.style.top =
          "clamp(7rem, 50vh - 34.12277rem, 23.5vh)";
      } */

      let scaleImage1 = this.value(
        containerTop,
        1,
        1.5,
        0.9 * this.$store.state.windowHeight,
        -0.5 * this.$store.state.windowHeight,
        "inOut",
        2
      );
      this.image1.style.transform = "scale(" + scaleImage1 + ")";

      let opacityImage1 = this.value(
        containerTop,
        1,
        0,
        0.1 * this.$store.state.windowHeight,
        -0.5 * this.$store.state.windowHeight,
        "inOut",
        2
      );
      this.image1.style.opacity = opacityImage1;

      let opacityTestimonial = this.value(
        containerTop,
        0,
        1,
        -0.15 * this.$store.state.windowHeight,
        -0.5 * this.$store.state.windowHeight,
        "inOut",
        2
      );
      this.testimonial.style.opacity = opacityTestimonial;
      this.imageCaption.style.opacity = 1 - 1.1 * opacityTestimonial;
    }
  },
  created() {
    setTimeout(() => {
      this.testimonials = document.querySelector(".testimonials");
      this.container = document.querySelector(".testimonials__container");
      this.stickyContainer = document.querySelector(
        ".testimonials__sticky-container"
      );
      this.image1 = document.querySelector(".testimonials__image--1");
      this.testimonial = document.querySelector(".testimonials__testimonial");
      this.imageCaption = document.querySelector(
        ".testimonials__image-caption"
      );

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.testimonials {
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  padding-top: 12rem;
  background-color: #101010;
  padding-bottom: 25vh;
  color: $color-white;

  display: grid;
  grid-template-columns: 1fr $width-center 1fr;

  @include respond(tab-land) {
    grid-template-columns:
      1fr min(100% - 2 * #{$side-margin-land}, #{$width-center})
      1fr;
  }

  @include respond(phone) {
    grid-template-columns: 1.5rem 1fr 1.5rem;
    padding-bottom: 65vh;
  }

  &__heading {
    position: relative;
    grid-column: 2 / 3;
    width: max-content;

    @include respond(phone) {
      width: auto;
      line-height: 1.3;
    }
  }

  &__introduction {
    grid-column: 2 / 3;
    align-self: start;

    margin-top: 5.75rem;
    width: min(90rem, 100%);
    font-size: 2.6rem;
    line-height: 1.6;
    color: #d4d5d6;

    @include respond(tab-port) {
      width: min(80rem, 100%);
      font-size: 2.8rem;
      line-height: 1.5;
      color: rgb(220, 220, 220);
    }

    @include respond(phone) {
      margin-bottom: -1.5rem;
    }
  }

  &__container {
    position: relative;
    grid-column: 2 / 3;
    margin-top: 12rem;
    height: calc(130vh + max(0rem, 88rem - 100vh));
    margin-bottom: 6rem;

    @include respond(phone) {
      height: 100vh;
    }
  }

  &__sticky-container {
    position: sticky;
    top: clamp(7rem, 50vh - 34.12277rem, 23.5vh);
    width: 100%;
    height: calc(50vh + max(0, 88rem - 100vh));

    @include respond(phone) {
      top: 7.5rem !important;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 68.24554rem;
    background-color: $color-black;

    overflow: hidden;

    @include respond(phone) {
      height: calc(0.66667 * (100vw - 3rem));

      background-color: $color-black;
    }

    &::after {
      @include pseudo-element;
      bottom: 0;
      z-index: 100;
      width: 100%;
      height: calc(68.24554rem - 66.7vw);
      background-color: #101010;
    }

    @include respond(phone) {
      &::after {
        content: initial;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;

    &--1 {
      z-index: 10;
      transition: transform 0.05s linear;
      will-change: transform;
    }

    &--2 {
      transform: translateX(48.3rem);
      width: initial;
      height: 79.79232%;

      @include respond(phone) {
        position: relative;
        transform: translate(calc(50vw - 1.5rem - 50%), 5%);
      }
    }
  }

  &__testimonial {
    position: absolute;
    z-index: 1;
    transform: translateY(
      calc(-100% - 20rem - max(68.24554rem - 66.7vw - 6.5rem, 0rem))
    );
    margin-top: 8.375rem;
    margin-left: 4rem;
    width: 40rem;
    font-size: 2.4rem;
    color: rgb(160, 160, 160);
    opacity: 0;

    @include respond(phone) {
      transform: none;
      margin-top: 5rem;
      margin-left: 0;
      width: 100%;
      font-size: 2.6rem;

      opacity: 1 !important;
    }
  }

  &__testimonial-heading {
    display: block;
    margin-bottom: 0.75rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    color: #f9f9f9;

    @include respond(phone) {
      font-size: 2.8rem;
    }
  }

  &__testimonial-quotation {
    position: relative;
    z-index: 2;
    font-style: italic;
  }

  &__signature {
    visibility: hidden;
    position: relative;
    z-index: 0;
    margin-top: 5rem;
    // margin-top: 4.5rem;
    width: 22.2rem;
    // width: 0;
    margin-bottom: 12.55rem;
  }

  &__testimonial-name {
    position: relative;
    display: block;
    z-index: 10;
    // margin-top: -12.7rem;
    margin-top: -15.7rem;
    font-family: $font-primary-bold;
    font-size: 2.4rem;
    color: #f9f9f9;

    @include respond(phone) {
      font-size: 2.6rem;
    }
  }

  &__testimonial-name-description {
    display: block;
    font-size: 2.2rem;
    color: rgb(217, 217, 217);

    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &__image-caption,
  &__flicker-stop {
    position: absolute;
    right: 0;
    top: 68.7rem;
    z-index: 10000;
    transform: translateY(min(66.7vw - 68.24554rem, 0rem));
    border-radius: 0.1rem;
    font-size: 2rem;
    color: rgb(220, 220, 220);

    @include respond(phone) {
      display: none;
    }
  }

  &__flicker-stop {
    width: 100vw;
    height: 10rem;

    background: linear-gradient(
      to bottom,
      transparent 0,
      transparent 5rem,
      #101010 5rem,
      #101010 10rem
    );
  }
}
</style>
