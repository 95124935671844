<template>
  <div class="footer">
    <app-icon-signetfull :color="'#ddd'" class="footer__signet" />
    <span class="footer__copyright">
      Copyright {{ new Date().getFullYear() }} / Mathopia
    </span>
    <span @click="openImprint()" class="footer__imprint">
      {{ $t("footer").imprint }}
    </span>
    <span @click="openTerms()" class="footer__terms">
      {{ $t("footer").termsOfUse }}
    </span>
    <span @click="openDataProtection()" class="footer__data-protection">
      {{ $t("footer").privacyPolicy }}
    </span>
  </div>
</template>

<script>
import AppIconSignetfull from "@/components/Atoms/Icons/Signetfull.vue";

export default {
  components: {
    AppIconSignetfull
  },
  methods: {
    openImprint() {
      this.goTo("/imprint");
    },
    openTerms() {
      this.goTo("/terms");
    },
    openDataProtection() {
      this.goTo("/data-protection");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.footer {
  position: relative;
  // position: absolute;
  // top: 0;
  width: 100%;

  display: grid;
  grid-template-columns: 4rem auto auto auto auto 1fr 4rem;
  align-items: center;

  background-color: #161616;
  min-height: 6rem;
  font-size: 2.4rem;
  transform: translateY(var(--transition-footer));
  ///  transform: translateY(calc(100% - 6rem));

  // background-color: green !important;

  transition: transform 0.8s;

  @include respond(tab-land) {
    margin-bottom: 6rem;
    // position: relative;
    // transform: translateY(var(--transition-footer));
    grid-template-columns: 3rem auto auto auto auto 1fr 3rem;
  }

  @include respond(phone) {
    padding-top: 0.25rem;
    // border-top: 0.75px solid #303030;
    grid-template-columns: 1.5rem max-content 1fr 1.5rem;
  }

  &__signet {
    grid-column: 2 / 3;
    margin-right: 1.25rem; // was 1.5rem
    margin-top: 1.25rem;

    width: 4.5rem;
    height: 4.5rem;

    @include respond(phone) {
      grid-row: 4 / 5;
      transform: translate(-0.2rem, 0.1rem) scale(0.75);
      // transform: translateY(0.1rem) scale(1.375);
      transform-origin: left top;
      margin-right: 0;
      margin-bottom: 0.8rem;
    }
  }

  &__copyright {
    grid-column: 3 /4;
    padding-right: 2.5rem;
    font-size: 1.8rem;
    line-height: 1.2;
    color: #ddd;

    @include respond(phone) {
      grid-row: 4 / 5;
      margin-left: 0.1rem;
      margin-bottom: 0.8rem;
      font-size: 2.2rem;
    }
  }

  &__imprint {
    grid-column: 4 / 5;
    padding-right: 2.5rem;
    color: $color-white;
    cursor: pointer;

    @include respond(phone) {
      grid-column: 2 / 4;
      margin-top: 1.2rem;
      line-height: 1.9;
    }
  }

  &__terms {
    grid-column: 5 / 6;
    padding-right: 2.5rem;
    color: $color-white;
    cursor: pointer;

    @include respond(phone) {
      grid-column: 2 / 4;
      line-height: 1.9;
    }
  }

  &__data-protection {
    grid-column: 6 / 7;
    color: $color-white;
    cursor: pointer;

    @include respond(phone) {
      grid-column: 2 / 4;
      margin-bottom: 3.5rem;
      line-height: 1.9;
    }
  }
}
</style>
