export const utilities = {
  methods: {
    // Debounce function
    debounce: fn => {
      let frame;
      return (...params) => {
        if (frame) {
          cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      };
    },
    // Positions, sizes and easing function
    left(element) {
      return element.getBoundingClientRect().left || 0;
    },
    right(element) {
      return element.getBoundingClientRect().right || 0;
    },
    top(element) {
      return element.getBoundingClientRect().top || 0;
    },
    bottom(element) {
      return element.getBoundingClientRect().bottom || 0;
    },
    width(element) {
      return element.getBoundingClientRect().width || 0;
    },
    height(element) {
      return element.getBoundingClientRect().height || 0;
    },
    getCoordinates(element) {
      let topElement = element.getBoundingClientRect().top || 0;
      let rightElement = element.getBoundingClientRect().right || 0;
      let bottomElement = element.getBoundingClientRect().bottom || 0;
      let leftElement = element.getBoundingClientRect().left || 0;
      let widthElement = element.getBoundingClientRect().width || 0;
      let heightElement = element.getBoundingClientRect().height || 0;
      return {
        top: topElement,
        right: rightElement,
        bottom: bottomElement,
        left: leftElement,
        width: widthElement,
        height: heightElement
      };
    },
    remToPx(rem) {
      // converts rem to pixels
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    },
    ease(type, power, value) {
      if (type === "in") {
        return Math.pow(value, power);
      } else if (type === "out") {
        return 1 - Math.abs(Math.pow(value - 1, power));
      } else {
        if (value < 0.5) {
          return 0.5 * this.ease("in", power, 2 * value);
        } else {
          return 0.5 + 0.5 * this.ease("out", power, 2 * value - 1);
        }
      }
    },
    value(elementPos, styleFrom, styleTo, scrollFrom, scrollTo, easing, power) {
      if (elementPos < scrollTo) {
        return styleTo;
      } else if (elementPos > scrollFrom) {
        return styleFrom;
      }
      let proportion = (elementPos - scrollFrom) / (scrollTo - scrollFrom);
      proportion = this.ease(easing, power, proportion);
      return styleFrom + proportion * (styleTo - styleFrom);
    },
    getCursorPosition(e) {
      let xCoordinate;
      let yCoordinate;

      if (
        e.type == "touchstart" ||
        e.type == "touchmove" ||
        e.type == "touchend" ||
        e.type == "touchcancel"
      ) {
        var evt = typeof e.originalEvent === "undefined" ? e : e.originalEvent;
        var touch = evt.touches[0] || evt.changedTouches[0];
        xCoordinate = touch.pageX;
        yCoordinate = touch.pageY;
      } else if (
        e.type == "mousedown" ||
        e.type == "mouseup" ||
        e.type == "mousemove" ||
        e.type == "mouseover" ||
        e.type == "mouseout" ||
        e.type == "mouseenter" ||
        e.type == "mouseleave"
      ) {
        xCoordinate = e.clientX;
        yCoordinate = e.clientY;
      }
      return [xCoordinate, yCoordinate];
    },

    // Return array in random order
    shuffle(shuffleObject) {
      const newOrder = arr =>
        [...arr].reduceRight(
          (res, _, __, s) => (
            res.push(s.splice(0 | (Math.random() * s.length), 1)[0]), res
          ),
          []
        );
      if (typeof shuffleObject === "number") {
        let i = 0,
          a = Array(shuffleObject);
        while (i < shuffleObject) a[i++] = i - 1;
        shuffleObject = a;
      }
      return newOrder(shuffleObject);
    },

    shiftEmptyElements(orderArray, elementArray, emptyKey) {
      let shiftedArray = orderArray.slice(0);
      for (let i = 0; i < orderArray.length; i++) {
        if (!elementArray[orderArray[i]][emptyKey]) {
          shiftedArray.splice(i, 1, "-");
          shiftedArray.push(orderArray[i]);
        }
      }
      shiftedArray = shiftedArray.filter(f => f !== "-");

      return shiftedArray;
    },

    generateNumberArray(number) {
      let auxArray = [];
      for (let i = 0; i < number; i++) {
        auxArray[i] = i;
      }
      return auxArray;
    },

    invertArray(array) {
      let invertedArray = [];
      for (let i = 0; i < array.length; i++) {
        invertedArray[i] = array.indexOf(i);
      }
      return invertedArray;
    },

    // Color choice
    chooseColor(text, conditionForFirstChoice) {
      const myArray = text.split("##");

      let colorArray = [];

      if (text.slice(0, 2) === "##" || text.slice(0, 3) === " ##") {
        for (let i = 0; i < myArray.length; i += 2) {
          let color = myArray[i];
          color = color.split(",");
          if (conditionForFirstChoice) {
            colorArray[i] = color[0];
          } else {
            colorArray[i] = color[1];
          }
        }
      } else {
        for (let i = 1; i < myArray.length; i += 2) {
          let color = myArray[i];
          color = color.split(",");
          if (conditionForFirstChoice) {
            colorArray[i] = color[0];
          } else {
            colorArray[i] = color[1];
          }
        }
      }

      let newText = "";
      for (let j = 0; j < myArray.length; j++) {
        if (j % 2 == 0) {
          newText += myArray[j];
        } else {
          newText += colorArray[j];
        }
      }

      return newText;
    }
  }
};
