<template>
  <div class="smartphone1">
    <app-sign-blue class="smartphone1__icon"></app-sign-blue>
    <span class="smartphone1__heading">
      <div class="smartphone1__heading-line">
        {{ $t("step1_smartphone")[0] }}
      </div>
      <div class="smartphone1__heading-line">
        {{ $t("step1_smartphone")[1] }}
      </div>
    </span>
    <div class="smartphone1__subject-container">
      <div class="smartphone1__subject">{{ $t("step1_smartphone")[2] }}</div>
    </div>
    <div class="smartphone1__item smartphone1__item--1">
      <div class="smartphone1__menu-symbol">+</div>
      <span class="smartphone1__content">{{ $t("step1_smartphone")[3] }}</span>
    </div>
    <div class="smartphone1__item smartphone1__item--2">
      <div class="smartphone1__menu-symbol">&ndash;</div>
      <span class="smartphone1__content">{{ $t("step1_smartphone")[4] }}</span>
      <app-checkmark-blue class="smartphone1__checkmark"></app-checkmark-blue>
      <span class="smartphone1__subheading smartphone1__subheading--1">
        {{ $t("step1_smartphone")[5] }}
      </span>
      <p class="smartphone1__description smartphone1__description--1">
        {{ $t("step1_smartphone")[6] }} <br />
        {{ $t("step1_smartphone")[7] }}
      </p>
      <div class="smartphone1__circle"></div>
      <span class="smartphone1__subheading smartphone1__subheading--2">
        {{ $t("step1_smartphone")[8] }}
      </span>
      <p class="smartphone1__description smartphone1__description--2">
        {{ $t("step1_smartphone")[9] }}
      </p>
      <app-lock icon="lock" class="smartphone1__lock" />
      <span class="smartphone1__subheading smartphone1__subheading--3">
        {{ $t("step1_smartphone")[10] }}
      </span>
      <p class="smartphone1__description smartphone1__description--3">
        {{ $t("step1_smartphone")[11] }}
      </p>
    </div>
    <div class="smartphone1__item smartphone1__item--3">
      <div class="smartphone1__menu-symbol">+</div>
      <span class="smartphone1__content">{{ $t("step1_smartphone")[12] }}</span>
    </div>
  </div>
</template>

<script>
import AppSignBlue from "@/components/Atoms/Images/SmartphoneIcons/SignBlue.vue";
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";
import AppLock from "@/components/Atoms/Icons/Lock.vue";

export default {
  components: {
    AppSignBlue,
    AppCheckmarkBlue,
    AppLock
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.smartphone1 {
  display: grid;
  grid-template-rows: 350px max-content 102.5px max-content max-content;

  width: 360px !important;
  padding: 0 22px;
  color: #ccc;

  &__icon {
    grid-row: 1 / 2;
    position: absolute;
    left: 50%;
    top: 180px;
    transform: translateX(-50%);
    width: 95px;
  }

  &__heading {
    grid-row: 2 / 3;

    font-size: 29px;
    line-height: 1.2;
  }

  &__heading-line {
    display: block;
  }

  &__subject-container {
    grid-row: 3 / 4;
    position: relative;
    height: 57.5px;
    margin-top: 20px;
    padding: 0 15px;
    font-size: 24px;
    background-color: #0c0c09;

    display: grid;
  }

  &__subject {
    grid-column: 1 / 2;
    position: relative;
    line-height: 1;
    align-self: center;

    &::before {
      @include pseudo-element;
      right: 4px;
      top: -4px;
      width: 17px;
      height: 17px;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      transform: rotate(45deg);
    }
  }

  &__item {
    border-top: 1px solid var(--header-line-color);
    font-size: 25px;
    line-height: 1;

    display: grid;
    grid-template-columns: 55px 1fr;
    align-items: center;

    &--1 {
      grid-row: 4 / 5;
    }

    &--2 {
      grid-row: 5 / 6;

      padding: 10px 0;
    }

    &--3 {
      grid-row: 6 / 7;
      border-bottom: 1px solid var(--header-line-color);
    }
  }

  &__menu-symbol {
    font-size: 50px;
    justify-self: center;
    transform: translateY(-0.15rem);
  }

  &__content {
    grid-column: 2 / 3;
    margin-left: 5px;
    transform: translateY(0.5px);
  }

  &__checkmark {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    z-index: 1;
    width: 24px;
    height: 24px;
    transform: translate(14px, 6px);
    background-color: $color-black;
  }

  &__circle {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #eee;
    transform: translate(14px, -1.5px);
  }

  &__lock {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
    width: 33.14px;
    height: auto;
    transform: translate(9.75px, -5px);
  }

  &__subheading {
    grid-column: 2 / 3;

    margin-left: 5px;
    font-family: $font-primary-bold;
    font-size: 22px;

    &--1 {
      grid-row: 2 / 3;
      margin-top: 17px;
    }

    &--2 {
      grid-row: 4 / 5;
    }

    &--3 {
      grid-row: 6 / 7;
      margin-top: 2px;
    }
  }

  &__description {
    grid-column: 2 / 3;
    position: relative;

    margin-left: 5px;
    margin-top: 6px;
    font-size: 19px;
    line-height: 1.4;
    color: #a6a6a6;

    &::after {
      @include pseudo-element;
      left: -35px;
      top: -8.5px;
      height: 100%;
      border-left: 1.5px dashed #444;
    }

    &:last-child {
      &::after {
        border-left: none;
      }
    }

    &--1 {
      grid-row: 3 / 4;
      padding-bottom: 25px;
    }

    &--2 {
      grid-row: 5 / 6;
      padding-bottom: 25px;
    }

    &--3 {
      grid-row: 7 / 8;
      margin-top: 3.5px;
      padding-bottom: 10px;
    }
  }
}
</style>
