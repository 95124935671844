<template>
  <div
    @mouseup="mouseIsDown = false"
    @touchend="mouseIsDown = false"
    class="drawing"
  >
    <h2 class="drawing__heading">
      {{ t(content.heading) }}
    </h2>
    <p class="drawing__question">{{ t(content.instructions) }}</p>
    <svg
      v-for="(svg, index) in svgData"
      :key="svg"
      xmlns="http://www.w3.org/2000/svg"
      :width="svgWidth"
      :height="svgHeight"
      class="drawing__character-svg"
    >
      <path :class="'drawing__character-path--' + index" :d="svg" />
    </svg>

    <svg
      v-for="(svg, index) in svgData"
      :key="svg"
      xmlns="http://www.w3.org/2000/svg"
      :width="svgWidth"
      :height="svgHeight"
      class="drawing__character-svg drawing__character-svg--written"
      :class="[
        'drawing__character-svg--written-' + index,
        { 'drawing__character-svg--written-solved': exerciseIsFinished }
      ]"
    >
      <g>
        <path :d="svg" />
      </g>
    </svg>

    <canvas
      @mousedown="mouseIsPressedDown($event)"
      @touchstart="mouseIsPressedDown($event)"
      @mousemove="drawOnCanvas($event)"
      @touchmove="drawOnCanvas($event)"
      @mouseup="checkAccuracy()"
      @touchend="checkAccuracy()"
      @mouseout="triggerDetermination()"
      class="drawing__canvas"
    >
      Your browser does not support the canvas element.
    </canvas>
    <div class="drawing__canvas-direction-point"></div>
    <div
      class="drawing__canvas-background"
      :class="{
        'drawing__canvas-background--correct': correctAnswerIsIndicated,
        'drawing__canvas-background--wrong': wrongAnswerIsIndicated
      }"
    ></div>

    <app-button
      @mousedown="setPage('++')"
      @touchstart="setPage('++')"
      size="big"
      :text="$t('course_continue_button')"
      class="drawing__continue-button"
      :class="{
        'drawing__continue-button--is-shown': continueButtonIsShown,
        'drawing__continue-button--no-transition': transitionIsSuspended
      }"
    ></app-button>

    <!-- <div
      class="drawing__scroll-auxiliary"
      :class="{
        'drawing__scroll-auxiliary--is-triggered': exerciseIsFinished
      }"
    ></div> -->

    <app-message
      :data="messageData"
      :isShown="messageIsShown"
      :transitionIsSuspended="false"
      @messageClosed="messageIsShown = false"
      class="drawing__message"
    ></app-message>

    <audio v-if="audio" ref="sound-effect-drawing">
      <source
        :src="
          // 'http://mathopia3.s3-website.eu-central-1.amazonaws.com/' +
          'https://mathopia.com/audio/' + audio + '.mp3'
        "
        type="audio/mpeg"
        preload="true"
      />
    </audio>

    <audio id="sound-correct">
      <source
        src="@/assets/audio/correct.mp3"
        type="audio/mpeg"
        preload="true"
      />
    </audio>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppMessage from "@/components/Molecules/Message.vue";

export default {
  components: {
    AppButton,
    AppMessage
  },
  data() {
    return {
      mouseIsDown: false,
      ctx: {},
      leftCanvas: 0,
      topCanvas: 0,
      devicePixelRatio: 1,
      lineNumber: 0,
      lastPositionX: 0,
      lastPositionY: 0,
      drawnPixels: [],
      mouseWasLiftedOnce: false,
      // correctAnswerIsIndicated: false,
      // wrongAnswerIsIndicated: false,
      exerciseIsFinished: false,
      drawingIsPrevented: false,
      continueButtonIsShown: false,
      directionPointIsAnimated: false,
      audio: false,
      transitionIsSuspended: false,

      svgWidth: 600,
      svgHeight: 400,
      svgData: [],

      characterData: [],
      characterPaths: [],
      canvasDirectionPoint: {},

      exerciseWasBegunWasStored: false,

      message: "",
      type: "message",
      image: "",
      messageData: {},
      messageIsShown: false
    };
  },
  props: {
    content: Object,
    exerciseIsReset: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    mouseIsPressedDown(e) {
      e.preventDefault(); // !!!!!!!!!
      let cursorPosition = this.getCursorPosition(e);
      (this.lastPositionX = cursorPosition[0] - this.leftCanvas),
        (this.lastPositionY = cursorPosition[1] - this.topCanvas);

      this.mouseIsDown = true;
    },
    drawOnCanvas(e) {
      if (
        !this.mouseIsDown ||
        this.exerciseIsFinished ||
        this.drawingIsPrevented
      ) {
        return;
      }

      if (!this.exerciseWasBegunWasStored) {
        this.exerciseWasBegunWasStored = true;
        this.$store.dispatch("setExerciseStatus", 1);
      }

      let cursorPosition = this.getCursorPosition(e);
      let xPosition = cursorPosition[0] - this.leftCanvas;
      let yPosition = cursorPosition[1] - this.topCanvas;

      this.drawLine(xPosition, yPosition);

      this.drawnPixels.push([xPosition, yPosition]);
    },
    drawLine(newX, newY) {
      /* if (
        Math.abs(newX - this.lastPositionX) < 1 &&
        Math.abs(newY - this.lastPositionY) < 1
      ) {
        return;
      } */

      this.ctx.beginPath();
      // this.ctx.strokeStyle = "#0d6bb5";
      // this.ctx.strokeStyle = "#0d6190";
      // // // this.ctx.strokeStyle = "#14649c";
      this.ctx.strokeStyle = "#1876b8";
      // this.ctx.strokeStyle = "#14639b";
      this.ctx.strokeStyle = "linear-gradient(red blue)";
      this.ctx.lineWidth = 10 * this.devicePixelRatio;
      this.ctx.lineJoin = "round";
      this.ctx.lineCap = "round";
      this.ctx.moveTo(
        this.devicePixelRatio * this.lastPositionX,
        this.devicePixelRatio * this.lastPositionY
      );

      let posOld = [
        this.devicePixelRatio * this.lastPositionX,
        this.devicePixelRatio * this.lastPositionY
      ];
      let posNew = [this.devicePixelRatio * newX, this.devicePixelRatio * newY];
      let xc = (posOld[0] + posNew[0]) / 2;
      let yc = (posOld[1] + posNew[1]) / 2;

      this.ctx.quadraticCurveTo(posOld[0], posOld[1], xc, yc);

      /* this.ctx.lineTo(
        this.devicePixelRatio * newX,
        this.devicePixelRatio * newY
      ); */
      // this.ctx.closePath(); // ?
      this.ctx.stroke();

      this.lastPositionX = (this.lastPositionX + newX) / 2;
      this.lastPositionY = (this.lastPositionY + newY) / 2;
    },
    clearCanvas() {
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

      this.drawnPixels = [];
      this.mouseWasLiftedOnce = false;
    },
    getSquaredDistanceBetweenTwoPoints(point1, point2) {
      return (
        Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2)
      ); // = Euclidean distance squared
    },
    checkDistanceBetweenTwoSets(set1, set2, range) {
      let returnValue = true;
      for (let i = 0; i < set1.length; i += 3) {
        let isClose = false;
        for (let j = 0; j < set2.length; j += 3) {
          if (
            this.getSquaredDistanceBetweenTwoPoints(set1[i], set2[j]) < range
          ) {
            isClose = true;
            break;
          }
        }
        if (!isClose) {
          returnValue = false;
          break;
        }
      }

      return returnValue;
    },
    checkAccuracy() {
      this.drawingIsPrevented = true;
      if (this.exerciseIsFinished) {
        return;
      }

      if (this.drawnPixels.length > 0) {
        this.mouseWasLiftedOnce = true;
      }

      let set1 = [...new Set(this.drawnPixels)];
      let set2 = [...new Set(this.characterData[this.lineNumber])];

      setTimeout(() => {
        let isAccurate =
          this.checkDistanceBetweenTwoSets(set1, set2, 2700) &&
          this.checkDistanceBetweenTwoSets(set2, set1, 2700);

        // this.drawingIsPrevented = false;
        this.evaluateExercise(isAccurate);
      }, 0);
    },
    triggerDetermination() {
      if (this.mouseIsDown) {
        this.checkAccuracy();
      }
    },
    determineIfDirectionIsCorrect() {
      let xStart = this.characterData[this.lineNumber][0][0];
      let yStart = this.characterData[this.lineNumber][0][1];
      let xEnd = this.characterData[this.lineNumber][
        this.characterData[this.lineNumber].length - 1
      ][0];
      let yEnd = this.characterData[this.lineNumber][
        this.characterData[this.lineNumber].length - 1
      ][1];
      let distanceSquared =
        Math.pow(xEnd - xStart, 2) + Math.pow(yEnd - yStart, 2);

      if (distanceSquared < 2700) {
        return "undecided";
      }

      let xStartDrawing = this.drawnPixels[0][0];
      let yStartDrawing = this.drawnPixels[0][1];
      let distanceAtStartSquared =
        Math.pow(xStartDrawing - xStart, 2) +
        Math.pow(yStartDrawing - yStart, 2);
      if (distanceAtStartSquared < 2700) {
        return true;
      } else {
        return false;
      }
    },
    evaluateExercise(isAccurate) {
      if (this.exerciseIsFinished) {
        return;
      }

      if (isAccurate && this.determineIfDirectionIsCorrect() !== false) {
        // this.$store.dispatch("setExerciseStatus", 4);
        // this.$store.dispatch("addExerciseData", ["exerciseIsFinished", true]);
        if (this.lineNumber >= this.characterData.length - 1) {
          setTimeout(() => {
            this.playAudio("correct");
          }, 100);
        }

        let xMinDrawing = 1000000;
        let xMaxDrawing = -1000000;
        let yMinDrawing = 1000000;
        let yMaxDrawing = -1000000;
        for (let i = 0; i < this.drawnPixels.length; i++) {
          if (this.drawnPixels[i][0] < xMinDrawing) {
            xMinDrawing = this.drawnPixels[i][0];
          }
          if (this.drawnPixels[i][0] > xMaxDrawing) {
            xMaxDrawing = this.drawnPixels[i][0];
          }
          if (this.drawnPixels[i][1] < yMinDrawing) {
            yMinDrawing = this.drawnPixels[i][1];
          }
          if (this.drawnPixels[i][1] > yMaxDrawing) {
            yMaxDrawing = this.drawnPixels[i][1];
          }
        }
        this.drawnPixels = [];
        setTimeout(() => {
          let translateX =
            xMinDrawing - this.characterPaths[this.lineNumber].left;
          let translateY =
            yMinDrawing - this.characterPaths[this.lineNumber].top;
          let drawnCharacter = document.querySelector(
            ".drawing__character-svg--written-" + this.lineNumber
          );

          drawnCharacter.style.transform =
            "translate(" + translateX + "px, " + translateY + "px)";

          setTimeout(() => {
            this.clearCanvas();
            drawnCharacter.style.opacity = 1;
            drawnCharacter.style.transition =
              "transform 0.4s cubic-bezier(0.5, 1.24, 0.5, 1.24)";
            // drawnCharacter.style.transition = "transform 0.4s ease-out";

            /* let html = document.getElementsByTagName("html")[0];
            html.style.setProperty(
              "--character-transition",
              "transform 0.4s ease-out"
            ); */

            this.lineNumber++;

            if (this.lineNumber >= this.characterData.length) {
              this.$store.dispatch("setExerciseStatus", 4);
              this.$store.dispatch("addExerciseData", [
                "exerciseIsFinished",
                true
              ]);
            }

            /* setTimeout(() => {
              drawnCharacter.style.transform = "none";
              setTimeout(() => {
                drawnCharacter.style.transition = "none";
                // html.style.setProperty("--character-transition", "none");
              }, 400);
            }, 50); // 20
            // window.requestAnimationFrame(
              // (drawnCharacter.style.transform = "none")
            // );
          }, 100);
        }, 50); */

            setTimeout(() => {
              drawnCharacter.style.transform = "none";
              if (
                this.lineNumber < this.characterData.length &&
                !this.exerciseIsFinished
              ) {
                this.drawingIsPrevented = false;
              }
              setTimeout(() => {
                drawnCharacter.style.transition = "none";
              }, 400);
            }, 10); // 20
          }, 20);
        }, 10);

        setTimeout(() => {
          if (this.lineNumber >= this.characterData.length) {
            if (this.exerciseIsFinished) {
              return;
            }
            this.exerciseIsFinished = true;
            // this.playAudio("effect-drawing");
            this.playAudio(this.audio);

            this.drawing.style.position = "relative";
            let canvas = document.querySelector(".drawing__canvas");
            setTimeout(() => {
              if (this.exerciseIsFinished) {
                this.continueButtonIsShown = true;
                this.scrollElement(canvas, this.remToPx(14.5));
              }
            }, 875);
          }
        }, 1067);
      } else {
        // this.$store.dispatch("setExerciseStatus", 1);
        this.clearCanvas();
        this.drawingIsPrevented = false;
      }
    },
    autoSolve() {
      this.exerciseIsFinished = true;
      this.continueButtonIsShown = true;
      setTimeout(() => {
        this.drawing.style.position = "relative";
        let canvas = document.querySelector(".drawing__canvas");
        this.scrollElement(canvas, this.remToPx(14.5));
      }, 500);
    },
    animateDirectionPoint() {
      let vm = this;

      function animatePoint(step, lineNumber) {
        if (vm.exerciseIsFinished) {
          vm.directionPointIsAnimated = false;
          vm.canvasDirectionPoint.style.visibility = "hidden";
          return;
        }

        if (vm.lineNumber !== lineNumber) {
          lineNumber = vm.lineNumber;
          step = 0;
        }

        let translateX = vm.characterData[vm.lineNumber][step][0] - 300;
        let translateY = vm.characterData[vm.lineNumber][step][1] - 5;

        vm.canvasDirectionPoint.style.transform =
          "translate(" + translateX + "px, " + translateY + "px)";

        let timeout = 5.25;
        if (step < vm.characterData[vm.lineNumber].length - 1) {
          step++;
        } else {
          step = 0;
          setTimeout(() => {
            vm.canvasDirectionPoint.style.opacity = 0;
          }, 5.25);
          setTimeout(() => {
            vm.canvasDirectionPoint.style.opacity = 1;
          }, 400);
          timeout = 400; // Delay after each pass
        }

        setTimeout(() => {
          if (vm.lineNumber < vm.characterData.length) {
            animatePoint(step, lineNumber);
          } else {
            vm.directionPointIsAnimated = false;
            vm.canvasDirectionPoint.style.visibility = "hidden";
          }
        }, timeout);
      }

      if (this.directionPointIsAnimated) {
        return;
      }
      this.directionPointIsAnimated = true;
      this.canvasDirectionPoint.style.visibility = "visible";
      animatePoint(0, 0);
    },
    getInitialData(delay) {
      setTimeout(() => {
        let canvas = document.querySelector(".drawing__canvas");
        this.ctx = canvas.getContext("2d");

        this.devicePixelRatio = window.devicePixelRatio;
        canvas.width = Math.floor(600 * this.devicePixelRatio);
        canvas.height = Math.floor(400 * this.devicePixelRatio);

        this.leftCanvas = this.left(canvas);
        this.topCanvas = this.top(canvas);

        if (this.content.audio) {
          this.audio = this.t(this.content.audio);
        }
      }, 0); // was 600

      setTimeout(() => {
        // Get path data
        for (let i = 0; i < this.svgData.length; i++) {
          let line = document.querySelector(".drawing__character-path--" + i);

          let pathLength = line.getTotalLength();
          this.characterData[i] = [];
          for (let j = 0; j <= pathLength; j++) {
            this.characterData[i][j] = [
              line.getPointAtLength(j).x + (600 - this.svgWidth) / 2,
              line.getPointAtLength(j).y + (400 - this.svgHeight) / 2
            ];
          }

          /* this.characterPaths[i] = {
            left: this.left(line) - this.leftCanvas,
            top: this.top(line) - this.topCanvas,
            width: this.width(line),
            height: this.height(line)
          }; */

          for (let i = 0; i < this.characterData.length; i++) {
            let pathLeft = 1000000;
            let pathTop = 1000000;
            for (let j = 0; j < this.characterData[i].length; j++) {
              if (this.characterData[i][j][0] < pathLeft) {
                pathLeft = this.characterData[i][j][0];
              }
              if (this.characterData[i][j][1] < pathTop) {
                pathTop = this.characterData[i][j][1];
              }
            }

            this.characterPaths[i] = {
              left: pathLeft,
              top: pathTop
            };
          }
        }

        let xMinCharacter = 1000000;
        let xMaxCharacter = -1000000;
        let yMinCharacter = 1000000;
        let yMaxCharacter = -1000000;
        for (let i = 0; i < this.characterData.length; i++) {
          if (this.characterData[i][0] < xMinCharacter) {
            xMinCharacter = this.characterData[i][0];
          }
          if (this.characterData[i][0] > xMaxCharacter) {
            xMaxCharacter = this.characterData[i][0];
          }
          if (this.characterData[i][1] < yMinCharacter) {
            yMinCharacter = this.characterData[i][1];
          }
          if (this.characterData[i][1] > yMaxCharacter) {
            yMaxCharacter = this.characterData[i][1];
          }
        }
        this.leftCharacter = xMinCharacter;
        this.topCharacter = yMinCharacter;
        this.widthcaracter = xMaxCharacter - xMinCharacter;
        this.heightCharacter = yMaxCharacter - yMinCharacter;
      }, delay);

      setTimeout(() => {
        this.drawing = document.querySelector(".drawing");

        if (this.exerciseIsFinished) {
          this.scrollAuxiliary.style.scrollSnapAlign = "end";
          setTimeout(() => {
            let buttonBottom = this.bottom(this.continueButton);
            this.exerciseIsFinished = true;
            this.buttonHeight =
              buttonBottom - window.innerHeight + this.remToPx(10.05) - 2;
            this.drawing.style.position = "relative";
            this.scrollAuxiliary.style.height = this.buttonHeight + "px";
            setTimeout(() => {
              this.scrollAuxiliary.style.scrollSnapAlign = "initial";
            }, 1525);
          }, 0);
        }
      }, 0);
    },
    initializeData(delay) {
      this.getInitialData(delay);

      setTimeout(() => {
        this.getInitialData(delay);
      }, 0);
    }
  },
  created() {
    this.transitionIsSuspended = true;
    setTimeout(() => {
      this.transitionIsSuspended = false;
    }, 100);

    setTimeout(() => {
      document.body.style.position = "fixed";
      document.body.style.overflow = "hidden";

      if (
        this.o([
          this.$store.state.userDataLectures[this.$store.state.chosenLesson]
            .savedExerciseData[this.$store.state.coursePage],
          "exerciseIsFinished",
          false
        ])
      ) {
        this.autoSolve();
      }

      (this.svgWidth = this.content.svgWidth),
        (this.svgHeight = this.content.svgHeight),
        (this.svgData = this.content.svgData);

      this.initializeData(500);

      let vm = this;
      // window.addEventListener("resize", vm.getInitialData, false);
      window.addEventListener("resize", vm.initializeData, false);

      setTimeout(() => {
        // Direction point
        this.canvasDirectionPoint = document.querySelector(
          ".drawing__canvas-direction-point"
        );

        this.animateDirectionPoint();
      }, 500);
    }, 0);
  },
  watch: {
    exerciseIsReset(to) {
      if (to) {
        this.drawing.style.position = "absolute";

        this.$store.dispatch("setExerciseStatus", 0);
        this.$store.dispatch("addExerciseData", ["exerciseIsFinished", false]);
        this.exerciseIsFinished = false;
        this.drawingIsPrevented = false;
        this.continueButtonIsShown = false;
        this.clearCanvas();
        this.lineNumber = 0;

        for (let i = 0; i < this.characterData.length; i++) {
          let drawnCharacter = document.querySelector(
            ".drawing__character-svg--written-" + i
          );
          drawnCharacter.style.opacity = 0;
          // drawnCharacter.style.transition = "none";
        }

        this.animateDirectionPoint();
        this.getInitialData(0); // ???
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.drawing {
  position: absolute;
  // position: relative;
  top: 6rem;
  // top: 0;
  width: 100%;
  min-height: calc(100vh - 6rem);
  padding-top: 5.5rem;
  color: $color-white;
  background-color: #131313;

  overflow: hidden;

  @include grid-template-columns;
  // // // grid-template-rows: max-content max-content 400px 16rem;
  grid-template-rows: max-content max-content 400px 10rem 10rem;
  // grid-template-rows: max-content max-content 1fr;

  @include respond(phone) {
    padding-top: 4.6rem;
    // border: 1.5rem solid red;
    min-height: 110vh;

    // grid-template-rows: max-content max-content calc(100vw - 3rem);
    background: linear-gradient(
      #000 0,
      #000 calc(100% - 9rem),
      #131313 calc(100% - 9rem),
      #131313 100%
    );

    /* overflow-y: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y proximity; */
  }

  &__heading {
    grid-column: 3 / 4;
    margin-bottom: 4.125rem;
    font-weight: 400;
    text-align: center;
    // background-color: red;

    @include respond(tab-land) {
      margin-bottom: 3.78rem;
      line-height: 1.2;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      margin-bottom: 3.6rem;
      // width: 100%;
      font-size: 4rem;
      // text-align: right;
      // background-color: red;
    }
  }

  &__question {
    grid-column: 3 / 4;
    justify-self: center;
    width: min(80rem, 100vw - 2 * #{$side-margin});
    margin-bottom: 9.5rem;
    font-size: 2.6rem;
    text-align: center;
    // background-color: green;

    @include respond(tab-land) {
      width: min(80rem, 100vw - 2 * #{$side-margin-land});
      margin-bottom: 4.125rem;
    }

    @include respond(phone) {
      grid-column: 1 / 6;
      // width: min(80rem, 100vw - 2 * #{$side-margin-phone});
      width: calc(100% - 1.5rem);
      // background-color: green;
      line-height: 1.4;
      margin-bottom: -1.25rem; // was: 3rem // 2.9
      // text-align: right;
    }
  }

  &__character {
    position: absolute;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    justify-self: center;
    z-index: 1;

    height: 300px;
    filter: invert(1);
    opacity: 0.5;

    &--wrong {
      animation: shake 0.967s ease-in-out;
    }

    &--written {
      opacity: 0;
      transform-origin: left top;
    }
  }

  &__character-svg {
    position: absolute;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    justify-self: center;
    align-self: center;

    &--written {
      opacity: 0;
      // transition: var(--character-transition);
      // transform-box: fill-box;
    }

    & path {
      fill: none;
      stroke: #444;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-width: 10px;
    }

    &--written path {
      // stroke: $color-primary-dark;
      stroke: #1876b8;
      // stroke: #14639b;
    }

    &--written-solved {
      opacity: 1;
    }
  }

  &__canvas {
    position: relative;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    justify-self: center;
    z-index: 10000;
    margin-bottom: 3.25rem; // was: 3.75rem

    border-radius: 1rem;

    width: 600px;
    height: 400px;

    // transform: translateY(-10rem);
    // background-color: rgba(red, 0.3);

    // background-color: green;

    /* &--wrong {
      animation: shake 0.967s ease-in-out;
    } */

    @include respond(phone) {
      // width: calc(100vw - 3rem);
      // height: calc(100vw - 3rem);
    }
  }

  &__canvas-direction-point {
    visibility: hidden;
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    justify-self: center;
    z-index: 20000;
    // transform: translateX(-295px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #c0c0c0;
    pointer-events: none;

    // transform: transform 0.005s linear;
  }

  &__canvas-background {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    justify-self: center;
    width: 600px;
    height: 400px;
    // border: 0.5rem solid #1a1a1a;
    // border-radius: 1rem;
    // border: 1.5rem solid red;
    // border: 1px solid #1d1d1d; // ???
    border-radius: 0.6rem;
    background-color: $color-black;
    // background-color: red;
    // background-color: blue;

    // margin-bottom: 9rem;

    @include respond(phone) {
      // z-index: -1;
      // transform: translateZ(-1px) translateY(5rem);
      margin-top: calc(200px - 50vw + 1.5rem);
      width: calc(100vw - 3rem);
      height: calc(100vw - 3rem);
      background-color: transparent;
    }
  }

  &__continue-button {
    visibility: hidden;
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    width: max-content;
    height: max-content;
    left: calc(50vw + 0.5 * min(#{$width-center}, 100vw - 6rem));
    transform: translateX(-100%);
    border: 1px solid transparent !important;
    border-radius: 0.6rem;
    padding: 0.85rem 2.4rem !important;
    background-color: $color-primary;

    transform-style: preserve-3d;

    &::after {
      @include pseudo-element;
      left: -0.75rem;
      top: calc(-1.1rem - 1px);
      transform: translateZ(-1px);
      width: 100vw;
      height: 8.2rem;
      backdrop-filter: blur(10px);
    }

    @include respond(phone) {
      position: fixed;
      display: block;
      // left: 0.75rem;
      left: 0;
      top: 0;
      // transform: translateY(calc(var(--vh, 1vh) * 100 - 6.7rem)); // was: -9rem
      transform: translateY(calc(var(--vh, 1vh) * 100 - 6.5rem + 1px));
      // width: calc(100vw - 1.5rem);
      width: 100vw;
      height: 6rem;
      font-size: 2.8rem;
      text-align: center;
      // background-color: #1876b8;
      background-color: $color-primary-dark;
      visibility: hidden;
      will-change: transform;
      transition: transform 0.75s;

      &--is-shown {
        // transform: translateY(calc(var(--vh, 1vh) * 100 - 13.85rem + 1px));
        transform: translateY(calc(var(--vh, 1vh) * 100 - 13.5rem + 1px));
      }

      &--no-transition {
        transition: none;
      }
    }

    &--is-shown {
      visibility: visible;
    }
  }

  &__message {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
    margin: 5.5rem 0 0 21.4rem;
    height: max-content;
  }
}
</style>
