<template>
  <div class="inquiry-modal tabstart" tabindex="0">
    <app-input-complex
      :placeholder="$t('name')"
      :text="name"
      @inputEvent="name = $event"
      @keyupEvent="loginOnEnterKey($event)"
      @blurEvent="checkEmail"
    ></app-input-complex>
    <app-input-complex
      :autocompleteInput="'email'"
      :placeholder="$t('email')"
      :text="email"
      @inputEvent="email = $event"
      @keyupEvent="loginOnEnterKey($event)"
      @blurEvent="checkEmail"
    ></app-input-complex>
    <textarea
      v-model="message"
      :placeholder="$t('feedback_form')['placeholder']"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      class="inquiry-modal__text-field"
    ></textarea>
    <app-button
      @click="sendFeedback()"
      size="big"
      :text="$t('plans_college_inquiry')['button']"
      class="inquiry-modal__button"
    ></app-button>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppInputComplex from "@/components/Molecules/InputComplex.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";

export default {
  name: "appInquiryForm",
  components: {
    AppInputComplex,
    AppButton,
    AppButtonXRound
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      placeholderIsSmall: false, // for password
      inputType: "password",
      password: ""
    };
  },
  methods: {
    loginOnEnterKey(e) {
      if (e.code === "Enter" || e.keyCode === 13) {
        this.login();
      }
    },
    sendFeedback() {
      if (this.buttonIsDecativated) {
        return;
      }

      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!re.test(this.email) && this.message.length >= 10) {
        this.toggleAlert(this.$t("plans_college_inquiry")["email_not_valid"]);
        return;
      } else if (!re.test(this.email)) {
        this.toggleAlert(
          this.$t("plans_college_inquiry")["email_not_valid"] +
            " " +
            this.$t("plans_college_inquiry")[
              "email_not_valid_message_too_short"
            ]
        );
        return;
      } else if (this.message.length < 10) {
        this.toggleAlert(this.$t("feedback_form")["message_too_short"]);
        return;
      }

      this.buttonIsDeactivated = true;

      let language = this.$i18n.locale;
      if (language === "de") {
        language = "Deutsch";
      } else if (language === "en") {
        language = "Englisch";
      }

      const url =
        "https://us-central1-mathopia-71418.cloudfunctions.net/sendEmail?location=" +
        "none" +
        "&name=" +
        this.name +
        "&language=" +
        language +
        "&emailFrom=" +
        this.email +
        "&message=" +
        this.message;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };

      fetch(url, requestOptions);

      this.toggleAlert(this.$t("plans_college_inquiry")["confirmation"]);
      setTimeout(() => {
        this.toggleModal();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.inquiry-modal {
  width: 46rem;
  // padding: 5.5rem 5.5rem 0;
  padding: 5.5rem;
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
    padding: 7.5rem 3rem 0;
  }

  &:focus {
    outline: none;
  }

  &__text-field {
    resize: none;
    left: 1.5rem;
    width: 100%;
    height: 21rem;
    padding: 0.75rem;
    border-radius: 0.4rem;
    border: 1px solid #202020;
    font-family: $font-primary;
    font-size: 2rem !important;
    color: $color-white !important;
    background-color: #101010;

    @include respond(tab-port) {
      font-size: 2.2rem !important;
    }

    @include respond(phone) {
      // left: -1.5rem;
      width: 100%;
      // height: 50vh;
      // height: max-content;
      height: 30rem;
      font-size: 2.6rem !important;
      border-radius: 0.6rem;
      background-color: #1a1a1a;
    }

    &:focus {
      outline: none;
      border: 1px solid $color-primary;
    }

    &::placeholder {
      color: $color-white;
    }
  }

  &__button {
    margin-top: 2.875rem;
    width: 100%;
    height: 5rem;
    font-family: $font-primary;
    font-size: 2.6rem;
    text-align: center;

    @include respond(phone) {
      margin-top: 5.5rem;
      margin-bottom: 3rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }
}
</style>
