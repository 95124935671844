<template>
  <div ref="lavContainer"></div>
</template>

<script>
import lottie from "lottie-web";

export default {
  props: {
    lottie: String,
    timeout: Number,
    loop: Boolean,
    speed: Number,
    playedframes: Array,
    playFromFrame: Number,
    frame: Number,
    play: Boolean
  },
  methods: {
    goToFrame(frame) {
      this.anim.goToAndStop(frame, true);
    },
    playFrames(frame, lastFrame) {
      this.anim.goToAndStop(frame, true);

      frame++;
      if (frame >= lastFrame) {
        this.$emit("frameReached");
        return;
      }
      let vm = this;
      window.requestAnimationFrame(() => {
        vm.playFrames(frame, lastFrame);
      });

      /* setTimeout(() => {
        this.playFrames(frame, lastFrame);
      }, 14); */
    }
  },
  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer, // the dom element that will contain the animation
      renderer: "svg",
      loop: this.loop,
      autoplay: false,
      path: "/" + this.lottie + ".json"
    });

    /* let vm = this;
    this.anim.addEventListener("data_ready", function() {
      vm.anim.frameRate = 120;
    }); */

    setTimeout(() => {
      if (this.playedframes[3] === "once") {
        setTimeout(() => {
          this.playFrames(this.playedframes[0], this.playedframes[1]);
        }, this.playedframes[2]);
        return;
      }

      this.anim.setSpeed(this.speed);
      if (this.playedframes[0] !== 0 || this.playedframes[1] !== 0) {
        this.anim.play();
        // this.anim.goToAndStop(30, true);
        setTimeout(() => {
          this.anim.playSegments(
            [this.playedframes[0], this.playedframes[1]],
            true
          );
        }, this.playedframes[2]);

        if (!this.play) {
          setTimeout(() => {
            if (!this.play) {
              this.anim.stop();
            }
          }, this.playedframes[2]);
        }
      } else {
        this.anim.goToAndStop(this.playFromFrame, true);
        // this.getFrame(210);
        this.anim.play();
        if (!this.play) {
          this.anim.stop();
        }
      }
    }, this.timeout);
  },
  watch: {
    play(to) {
      if (to) {
        this.anim.play();
      } else {
        this.anim.stop();
      }
    },
    frame(to) {
      this.goToFrame(to);
    },
    speed(to) {
      this.anim.setSpeed(to);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
</style>
