<template>
  <div class="onboarding-cloze">
    <div class="onboarding-cloze__text">
      <div class="onboarding-cloze__text-line">
        <div
          class="onboarding-cloze__text-block onboarding-cloze__text-block--1"
        ></div>
        <div class="onboarding-cloze__gap onboarding-cloze__gap--1"></div>
        <div
          class="onboarding-cloze__text-block onboarding-cloze__text-block--2"
        ></div>
      </div>
      <div class="onboarding-cloze__text-line onboarding-cloze__text-line--2">
        <div class="onboarding-cloze__gap onboarding-cloze__gap--2"></div>
        <div
          class="onboarding-cloze__text-block onboarding-cloze__text-block--3"
        ></div>
        <div class="onboarding-cloze__gap onboarding-cloze__gap--3"></div>
        <div
          class="onboarding-cloze__text-block onboarding-cloze__text-block--4"
        ></div>
      </div>
    </div>
    <div class="onboarding-cloze__button-container">
      <div class="onboarding-cloze__button onboarding-cloze__button--1"></div>
      <div class="onboarding-cloze__button onboarding-cloze__button--2"></div>
      <div class="onboarding-cloze__button onboarding-cloze__button--3"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding-cloze {
  width: 100%;
  height: calc(100% - 1.6rem);

  &__text {
    position: absolute;
    left: 3.3rem;
    top: 3.2rem;
    // top: 0;
    width: 12rem;
  }

  &__text-line {
    position: absolute;
    width: 100%;
    height: 0.9rem;

    &--2 {
      top: 2.15rem;
    }
  }

  &__text-block {
    position: absolute;
    height: 100%;
    background-color: #ddd;

    &--1 {
      width: 6rem;
    }

    &--2 {
      left: 10.25rem;
      width: 1.75rem;
    }

    &--3 {
      left: 2.375rem;
      width: 2.5rem;
    }

    &--4 {
      left: 8.625rem;
      width: 3.375rem;
    }
  }

  &__gap {
    position: absolute;
    top: 0.7rem;
    height: 0.2rem;

    &--1 {
      left: 6.375rem;
      width: 3.5rem;
      border-radius: 10rem;
      background-color: $color-primary;
    }

    &--2 {
      left: 0;
      width: 2rem;
      border-radius: 0.1rem;
      background-color: #b0b0b0;
    }

    &--3 {
      left: 5.25rem;
      width: 3rem;
      border-radius: 0.1rem;
      background-color: #b0b0b0;
    }
  }

  &__button-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 2.6rem;
    height: 2.5rem;
  }

  &__button {
    position: absolute;
    // // height: 1rem;
    height: 0.9rem;
    border-radius: 0.1rem;
    background-color: $color-primary;

    &--1 {
      left: 3.3rem;
      width: 3rem;
    }

    &--2 {
      // // left: 6.8rem;
      left: 6.675rem;
      width: 2rem;
    }

    &--3 {
      // // left: 9.3rem;
      left: 9.05rem;
      width: 3.5rem;
    }
  }
}
</style>
