<template>
  <div class="smartphone3">
    <div class="smartphone3__exercise">
      {{ $t("sp3_exercise") }}
    </div>
    <div class="smartphone3__exercise-element">
      $\sum\limits_{i=1}^n(2i-1)=n^2$
    </div>
    <div class="smartphone3__zipper-element smartphone3__zipper-element--1">
      $2\cdot 1 - 1 = i^2$
    </div>
    <div class="smartphone3__zipper-element smartphone3__zipper-element--2">
      $2n - 1 = n^2$
    </div>
    <div class="smartphone3__zipper-element smartphone3__zipper-element--3">
      $2\cdot1-1 = 1^2$
      <app-checkmark-blue
        :color="'#1971af'"
        class="smartphone3__checkmark"
        :class="{ 'smartphone3__checkmark--is-shown': checkmarkIsShown }"
      ></app-checkmark-blue>
    </div>
  </div>
</template>

<script>
import AppCheckmarkBlue from "@/components/Atoms/Images/SmartphoneIcons/CheckmarkBlue.vue";

export default {
  name: "smartphone3",
  props: {
    checkmarkIsShown: Boolean
  },
  components: {
    AppCheckmarkBlue
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.test {
  color: #2fbcf8;
}

.smartphone3 {
  display: grid;
  grid-template-rows: 12.5rem max-content max-content max-content max-content;

  width: 39rem;
  height: 78rem;
  padding: 0 1.5rem;
  color: #ccc;

  &__exercise {
    grid-row: 2 / 3;

    font-size: 3rem;
    line-height: 1.4;

    @include respond(desktop-big) {
      font-size: 3.33333rem;
    }
    @include respond(desktop) {
      font-size: 3.75rem;
    }
    @include respond(tab-land) {
      font-size: 4rem;
    }
  }

  &__exercise-element {
    position: relative;
    grid-row: 3 / 4;
    margin-top: 1rem;
    z-index: 100000000;
    width: 100%;
    height: 8.7rem;
    background-color: #2a3037;
    // background-color: #111;
    font-size: 2.6rem;

    display: grid;
    justify-content: center;
    align-content: center;

    @include respond(desktop-big) {
      font-size: 2.42778rem;
    }
    @include respond(desktop) {
      font-size: 2.5875rem;
    }
    @include respond(tab-land) {
      font-size: 2.68333rem;
    }

    &::after {
      @include pseudo-element;
      top: calc(100% - 0.25rem);
      left: 0;
      width: 36rem;
      height: 2.57143rem; // 24px
      background: linear-gradient(-225deg, #2a3037 1.28571rem, transparent 0),
        linear-gradient(225deg, #2a3037 1.28571rem, transparent 0); // 12px
      /* background: linear-gradient(-225deg, #111 1.28571rem, transparent 0),
        linear-gradient(225deg, #111 1.28571rem, transparent 0); */
      background-position: left-bottom;
      background-repeat: repeat-x;
      background-size: 2.57143rem 2.57143rem;
      transform: scaleY(0.925);
    }
  }

  &__zipper-element {
    position: relative;
    width: 100%;
    height: 8rem;
    padding-top: 0.7rem;
    font-size: 2.55rem;
    background-color: #2a3037;
    // background-color: #111;
    text-align: center;
    line-height: 1;

    display: grid;
    justify-content: center;
    align-content: center;

    @include respond(desktop-big) {
      font-size: 2.5333rem;
    }
    @include respond(desktop) {
      font-size: 2.7rem;
    }
    @include respond(tab-land) {
      font-size: 2.8rem;
    }

    &::after {
      @include pseudo-element;
      top: -18px;
      left: 0;
      width: 36rem;
      height: 2.57143rem;
      background: linear-gradient(-45deg, #2a3037 1.28571rem, transparent 0),
        linear-gradient(45deg, #2a3037 1.28571rem, transparent 0);
      /* background: linear-gradient(-45deg, #111 1.28571rem, transparent 0),
        linear-gradient(45deg, #111 1.28571rem, transparent 0); */
      background-position: -1.28571rem 0;
      background-repeat: repeat-x;
      background-size: 2.57143rem 2.57143rem;
      transform: scaleY(0.925);
    }

    &--1 {
      grid-row: 4 / 5;
      margin-top: 12.5rem;
    }

    &--2 {
      grid-row: 5 / 6;
      margin-top: 2.6rem;
    }

    &--3 {
      grid-row: 6 / 7;
      margin-top: 2.6rem;
    }
  }

  &__checkmark {
    position: absolute;
    right: 1.35rem;
    bottom: 1.35rem;
    width: 2.7rem;
    height: auto;
    opacity: 0;

    transform: scale(0);
    transition: transform 0.225s ease-in-out, opacity 0.225s ease-in-out;

    &--is-shown {
      opacity: 1;
      transform: scale(1);
      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.35);
    }
  }
}
</style>
