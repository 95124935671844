<template>
  <div class="login-modal tabstart" tabindex="0">
    <h2 class="login-modal__heading">
      {{ $t("form_password")["enter_password"] }}
    </h2>
    <app-input-complex
      :id="'password-modal__password'"
      type="password"
      :placeholder="$t('password')"
      :placeholderIsSmall="placeholderIsSmall"
      :eyeIsShown="true"
      @focusEvent="setFocusOnPassword"
      @inputEvent="password = $event"
      @keyupEvent="sendPasswordOnEnterKey($event)"
      @mousedownEvent="
        placeholderIsSmall = true;
        secondHintIsShownEye = true;
      "
      @mouseupEvent="
        placeholderIsSmall = false;
        secondHintIsShownEye = false;
      "
    ></app-input-complex>
    <app-button
      @click="sendPassword()"
      size="big"
      :text="$t('form_password').button"
      class="login-modal__button"
    ></app-button>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppInputComplex from "@/components/Molecules/InputComplex.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "appLoginForm",
  components: {
    AppInputComplex,
    AppButton,
    AppButtonXRound
  },
  data() {
    return {
      placeholderIsSmall: false,
      password: ""
    };
  },
  methods: {
    sendPasswordOnEnterKey(e) {
      if (e.code === "Enter") {
        this.sendPassword();
      }
    },
    sendPassword() {
      if (this.password.length < 6) {
        this.toggleAlert(this.$t("form_password").too_short_password);
        return;
      } else {
        /* this.$store.dispatch("resendEmailVerificationWithPassword", {
          email: "pbreuning@yahoo.de",
          password: this.password,
          language: this.$i18n.locale
        }); */

        let actionCode = this.$store.state.actionCode;
        this.$store.state.actionCode = "";

        let vm = this;

        firebase
          .auth()
          .confirmPasswordReset(actionCode, this.password)
          .then(() => {
            this.toggleModal();
            this.toggleAlert(this.$t("form_password").changed_successfully);
          })
          .catch(error => {
            this.toggleModal();
            if (error.code === "auth/invalid-action-code") {
              this.toggleAlert(
                this.$t("form_password").link_not_valid,
                this.$t("form_password").request_new_link,
                () => {
                  vm.toggleAlert();
                  vm.toggleModal("app-changepassword-modal");
                }
              );
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";
.login-modal {
  width: 46rem;
  padding: 5.5rem;
  // border-radius: $border-radius;
  border-radius: 1rem;
  font-family: $font-primary;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &:focus {
    outline: none;
  }

  &__heading {
    margin-top: -0.6rem;
    margin-bottom: 3.625rem;
    // font-size: 2.8rem;
    font-size: 2.6rem;
    line-height: 1;
    font-weight: 100;
    color: $color-white;

    @include respond(phone) {
      margin-bottom: 4.25rem;
      font-size: 3rem;
    }
  }

  &__button {
    margin-top: 2.875rem;
    width: 100%;
    height: 5rem;
    font-family: $font-primary-bold;
    // font-size: 2.6rem;
    font-size: 2.4rem;
    text-align: center;

    @include respond(phone) {
      margin-top: 5.5rem;
      margin-bottom: 3rem;
      height: 6.5rem;
      font-family: $font-primary-bold;
      font-size: 3rem;
    }
  }
}
</style>
