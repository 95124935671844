<template>
  <div class="introduction1">
    <div class="introduction1__container">
      <div class="introduction1__overflow-container">
        <app-smartphone1 class="introduction1__content"></app-smartphone1>
        <div class="introduction1__smartphone-header">
          <app-mathopia-logo
            icon="logo"
            :stroke="0"
            class="introduction1__logo"
          />
          <div class="introduction1__smartphone-menu">
            <svg>
              <line x1="0" y1="0" x2="100%" y2="0" />
              <line x1="0" y1="0" x2="100%" y2="0" />
              <line x1="0" y1="0" x2="100%" y2="0" />
            </svg>
          </div>
        </div>
        <div class="introduction1__smartphone-footer"></div>
        <img
          src="@/assets/Home/Introduction1/smartphone_black1.png"
          alt="A black smartphone"
          class="introduction1__smartphone-cover"
        />
      </div>
      <div class="introduction1__text-container">
        <div class="introduction1__explanation-container">
          <div class="introduction1__explanation--2">
            <h2 class="introduction1__screen-heading">
              {{ $t("step1_heading")[0] }}&nbsp; {{ $t("step1_heading")[1] }}
            </h2>
            <p class="introduction1__screen-description">
              {{ $t("step1_paragraph") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSmartphone1 from "@/assets/Home/Introduction1/Smartphone1.vue";
import AppMathopiaLogo from "@/components/Atoms/Icons/MathopiaLogo.vue";

export default {
  data() {
    return {
      section: {},
      smartphone: {},
      content: {}
    };
  },
  components: {
    AppSmartphone1,
    AppMathopiaLogo
  },
  methods: {
    scrollHandler() {
      let sectionTop = this.top(this.section);

      let scaleOverflow = this.$store.state.scaleOverflow;

      let mediaWidth = window.matchMedia("(max-width: 600px)");
      let mediaHeight = window.matchMedia("(max-height: 520px)");

      if (!mediaWidth.matches && !mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          scaleOverflow,
          this.$store.state.windowHeight,
          -this.$store.state.windowHeight / 2,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translateX(-50%) scale(" + scaleOverflow1 + ")";
      } else if (!mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.2 * scaleOverflow,
          0.8 * scaleOverflow,
          this.$store.state.windowHeight,
          -this.$store.state.windowHeight / 2,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translate(-50%, 6.5rem) scale(" +
          scaleOverflow1 +
          ")";
      } else {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          1.2 * scaleOverflow,
          this.$store.state.windowHeight,
          -this.$store.state.windowHeight / 2,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 3rem, 0) translateX(-50%) scale(" +
          scaleOverflow1 +
          ")";
      }

      /* scaleOverflow1 = 1.5 * scaleOverflow;
      if (sectionTop < this.$store.state.windowHeight / 2) {
        scaleOverflow1 = scaleOverflow;
      } */

      /* this.smartphone.style.transform =
        "translate3d(0, 0, 0) translateX(-50%) scale(" + scaleOverflow1 + ")"; */

      if (sectionTop > this.$store.state.windowHeight + 20) {
        this.smartphone.style.opacity = 0;
      } else {
        this.smartphone.style.opacity = 1;
      }

      let scaleContent = this.$store.state.remValue / 10;
      let translateContent = this.value(
        sectionTop,
        0,
        -100,
        30,
        -this.$store.state.windowHeight / 1.5 - 100,
        "in",
        2
      );
      this.content.style.transform =
        "translateY(" + translateContent + "rem) scale(" + scaleContent + ")";

      let opacityExplanation2 = this.value(
        sectionTop,
        0,
        1,
        0,
        -300,
        "inOut",
        2
      );
      let translateYExplanation2 = this.value(
        sectionTop,
        3.5,
        0,
        0,
        -300,
        "inOut",
        2
      );

      this.introductionExplanation.style.opacity = opacityExplanation2;
      this.introductionExplanation.style.transform =
        "translateY(" + translateYExplanation2 + "rem)";
    }
  },
  created() {
    setTimeout(() => {
      this.section = document.querySelector(".introduction1");
      this.smartphone = document.querySelector(
        ".introduction1__overflow-container"
      );
      this.content = document.querySelector(".introduction1__content");
      this.introductionExplanation = document.querySelector(
        ".introduction1__text-container"
      );
      this.scrollHandler();
      // this.section.style.opacity = 1;

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.introduction1 {
  position: relative;
  z-index: 2;
  width: 100%;
  // height: calc(266.66667vh + 50rem);
  height: 252vh;
  // height: 200vh;
  margin-bottom: -100vh;

  background: linear-gradient(
    to bottom,
    $color-black 0,
    $color-black 100vh,
    transparent 100vh,
    transparent 100%
  );

  @include respond(tiny-height) {
    height: 300vh;
  }

  &__container {
    position: sticky;
    top: 100vh;
    transform: translateY(-100vh);
    width: 100%;
    height: 100vh;
    // background-color: $color-black;
    background: linear-gradient(
      to bottom,
      transparent 0,
      transparent 30vh,
      $color-black 30vh,
      $color-black 100%
    );

    overflow: hidden;

    @include respond(tiny-height) {
      height: calc(100vh + 23.5rem);
    }
  }

  &__overflow-container {
    position: absolute;
    left: 50vw;
    top: max(calc(40vh - 40rem + 3rem), calc(37.5vh - 33vh + 3rem));
    transform-origin: top;
    width: 40rem;
    height: 80rem;
    box-shadow: 0 0 0 1rem $color-black;
    overflow: hidden;

    will-change: transform;

    &::after {
      @include pseudo-element;
      left: 0;
      top: -0.1rem;
      width: 100%;
      height: 0.2rem;
      background-color: $color-black;
    }
  }

  &__content {
    position: absolute;
    left: 5%;
    top: 0.5%;
    width: 90%;
    transform-origin: left top;

    will-change: transform;
  }

  &__smartphone-header {
    position: absolute;
    left: 5%;
    top: 0.5%;
    width: 90%;
    height: 10.5rem;
    background-color: rgba($color-black, 0.96);

    @supports (-webkit-backdrop-filter: blur(10px)) or
      (backdrop-filter: blur(10px)) {
      background-color: transparent;
      backdrop-filter: blur(10px);
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 2.2rem;
      top: 10.5rem;
      width: calc(100% - 4.4rem);
      height: 1px;
      background-color: var(--header-line-color);
    }
  }

  &__logo {
    position: absolute;
    left: 2.2rem;
    top: 6.35rem;
    width: 11.5rem;
    height: auto;
  }

  &__smartphone-menu {
    position: absolute;
    right: 2.25rem;
    top: 6.9rem;
    width: 2.5rem;
    height: 1.9rem;

    & svg {
      position: absolute;
      width: 100%;

      line {
        stroke: #eee;
        stroke-width: 1.5;

        &:first-child {
          transform: translateY(1px);
        }

        &:nth-child(2) {
          transform: translateY(calc(0.85rem + 1px));
        }

        &:nth-child(3) {
          transform: translateY(calc(1.7rem + 1px));
        }
      }
    }
  }

  &__smartphone-footer {
    position: absolute;
    left: 5%;
    bottom: 0;
    width: 90%;
    height: 3.6rem;
    background-color: $color-black;
  }

  &__smartphone-cover {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25vh;
    transform: translateX(-50%);
    font-family: $font-primary-bold;
    font-size: 6.5rem;
    color: #bbb;

    will-change: transform;
  }

  &__explanation-container {
    position: absolute;
    width: 100%;
    transform: translateY(min(6.5rem, calc(25vh - 100% - 4.5rem)));

    /* @include respond(phone) {
      transform: translateY(-6rem);
    } */
  }

  &__explanation--2 {
    width: 100%;

    display: grid;
    grid-template-columns: 4rem 1fr $width-center 1fr 4rem;

    @include respond(tab-port) {
      // was: tab-land
      grid-template-columns: 3rem 0 1fr 0 3rem;
    }

    @include respond(phone) {
      grid-template-columns: 1.5rem 0 1fr 0 1.5rem;
    }
  }

  &__screen-heading {
    grid-column: 3 / 4;

    width: 57rem;
    font-family: $font-primary-bold;
    font-size: 2.8rem;
    margin-bottom: 2rem;
    color: $color-white;
    line-height: 1.3;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__screen-description {
    display: block;
    grid-column: 3 / 4;

    width: 54rem;
    color: rgb(200, 200, 200);
    font-family: $font-primary;
    font-size: 2.2rem;

    @include respond(tab-land) {
      width: 57rem;
    }

    @include respond(phone) {
      width: 100%;
      font-size: 2.6rem;
      line-height: 1.4;
      color: rgb(220, 220, 220);
    }
  }
}
</style>
