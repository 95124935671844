<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="1e3"
    height="1e3"
    viewBox="0 0 1e3 1e3"
  >
    <g>
      <circle
        :style="{ fill: color }"
        class="cls-1"
        cx="500"
        cy="500"
        r="500"
      />
      <path
        class="cls-2"
        d="M410.7219 715.50325q-.66965.0-1.34056-.02283a38.959 38.959.0 0 1-28.924-14.40113L246.79256 536.22666A38.96149 38.96149.0 0 1 307.31915 487.15L412.76126 617.19638 686.64519 323.22443a38.96151 38.96151.0 0 1 57.0135 53.11739L439.22737 703.1009A38.9621 38.9621.0 0 1 410.7219 715.50325z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "checkmarkBlue",
  props: {
    color: {
      type: String,
      default: "#0d7bc5"
    }
  }
};
</script>

<style scoped>
.cls-1 {
  fill: #0d7bc5;
}
.cls-2 {
  fill: #fff;
}
</style>
