<template>
  <div class="onboarding">
    <h2 class="onboarding__heading">
      {{ contents[subModal].heading }}
    </h2>
    <div class="onboarding__image">
      <div class="onboarding__image-circle">
        <div class="onboarding__image-screen">
          <div class="onboarding__image-header">
            <div
              v-for="n in 3"
              :key="n"
              class="onboarding__image-header-element"
            ></div>
          </div>
          <component
            :is="'app-' + subModal + '-image'"
            :prop="prop"
          ></component>
        </div>
      </div>
    </div>
    <p class="onboarding__instructions">{{ contents[subModal].text }}</p>
    <div
      class="onboarding__button-container"
      :class="{
        'onboarding__button-container--two-buttons':
          'leftButton' in contents[subModal]
      }"
    >
      <app-button
        v-if="contents[subModal].leftButton"
        size="big"
        :text="contents[subModal].leftButton"
        @click="contents[subModal].leftAction()"
        class="onboarding__button onboarding__button--left"
      ></app-button>
      <app-button
        size="big"
        :text="contents[subModal].rightButton"
        @mousedown="contents[subModal].rightAction()"
        class="onboarding__button"
      ></app-button>
    </div>
    <app-button-x-round @click="toggleModal()"></app-button-x-round>
  </div>
</template>

<script>
import AppButton from "@/components/Atoms/Button.vue";
import AppButtonXRound from "@/components/Atoms/ButtonXRound.vue";

import AppUnlockcourseImage from "@/components/Atoms/Images/Onboarding/UnlockCourse.vue";
import AppResumecourseImage from "@/components/Atoms/Images/Onboarding/ResumeCourse.vue";
// import AppEndcourseImage from "@/components/Atoms/Images/Onboarding/EndCourse.vue";
import AppClozeImage from "@/components/Atoms/Images/Onboarding/Cloze.vue";
import AppZipperImage from "@/components/Atoms/Images/Onboarding/Zipper.vue";
import AppDraganddropImage from "@/components/Atoms/Images/Onboarding/DragAndDrop.vue";

export default {
  name: "appHeader",
  components: {
    AppButton,
    AppButtonXRound,
    AppUnlockcourseImage,
    AppResumecourseImage,
    // AppEndcourseImage,
    AppClozeImage,
    AppZipperImage,
    AppDraganddropImage
  },
  data() {
    return {
      prop: false,
      buttonIsDisabled: false,
      contents: {
        cloze: {
          heading: this.$t("course_cloze_modal").heading,
          text: this.$t("course_cloze_modal").paragraph,
          rightButton: this.$t("course_cloze_modal").button, // was: "Aufgabe starten"
          rightAction: () => {
            this.toggleModal();
          }
        },
        draganddrop: {
          heading: this.$t("course_order_modal").heading,
          text: this.$t("course_order_modal").paragraph,
          rightButton: this.$t("course_order_modal").button, // was: "Aufgabe starten"
          rightAction: () => {
            this.toggleModal();
          }
        },
        zipper: {
          heading: this.$t("course_zipper_modal").heading,
          text: this.$t("course_zipper_modal").paragraph,
          rightButton: this.$t("course_zipper_modal").button, // was: "Aufgabe starten"
          rightAction: () => {
            this.toggleModal();
          }
        },
        unlockcourse: {
          heading: this.$t("course_unlock_modal").heading,
          text: this.$t("course_unlock_modal").paragraph,
          rightButton: this.$t("course_unlock_modal").button,
          rightAction: () => {
            if (this.buttonIsDisabled) {
              return;
            }
            this.buttonIsDisabled = true;
            this.prop = true;
            this.playAudio("unlock");
            setTimeout(() => {
              this.setPage("++");
              this.toggleModal();
            }, 750); // was 725
            setTimeout(() => {
              this.buttonIsDisabled = false;
            }, 1250);
          }
        },
        /* endcourse: {
          heading: this.$t("course_exit_modal").heading,
          text: this.$t("course_exit_modal").paragraph,
          leftButton: this.$t("course_exit_modal").continue_button,
          leftAction: () => {
            this.toggleModal();
          },
          rightButton: this.$t("course_exit_modal").exit_button,
          rightAction: () => {
            this.setPage(-1);
            this.toggleModal();
          }
        }, */
        start: {
          heading: this.$t("course_start_modal").heading,
          text: this.$t("course_start_modal").paragraph,
          rightButton: this.$t("course_start_modal").button,
          rightAction: () => {
            this.toggleModal();
          }
        },
        end: {
          heading: this.$t("course_end_modal").heading,
          text: this.$t("course_end_modal").paragraph,
          rightButton: this.$t("course_end_modal").button,
          rightAction: () => {
            this.toggleModal();
          }
        },
        catchAll: {
          heading: this.$t("course_catch_all_modal").heading,
          text: this.$t("course_catch_all_modal").paragraph,
          rightButton: this.$t("course_catch_all_modal").button,
          rightAction: () => {
            this.toggleModal();
          }
        }
      }
    };
  },
  computed: {
    subModal() {
      let stateSubModal = this.$store.state.shownSubModal;
      return stateSubModal in this.contents ? stateSubModal : "catchAll";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.onboarding {
  width: 51.2rem;
  padding-bottom: 0.02px;
  border-radius: 1rem;
  font-family: $font-primary;
  text-align: center;
  color: $color-white;
  background-color: $color-black;

  @include respond(tab-port) {
    width: 50rem;
  }

  @include respond(phone) {
    width: min(414px, 100vw - 1.5rem);
  }

  &__heading {
    padding-top: 3.3rem;
    text-align: center;
    font-size: 3.2rem;
    line-height: 1;
    color: $color-white;

    @include respond(phone) {
      font-size: 3.3rem;
    }
  }

  &__instructions {
    padding: 0 5.5rem;
    font-size: 2.4rem;
  }

  &__button-container {
    padding: 2.5rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1.375rem;
    justify-content: center;

    &--two-buttons {
      padding: 5.5rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__button {
    font-family: $font-primary-bold;
    font-weight: 100 !important;
    font-size: 2.4rem;
    text-align: center;
    height: 5.5rem;

    &--left {
      background-color: #202020;

      &:hover {
        background-color: #333;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 31rem;
  }

  &__image-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    background-color: $color-primary;
  }

  &__image-screen {
    position: absolute;
    top: 3.2rem;
    width: 20rem;
    height: 13.6rem;
    border: 0.7rem solid #333;
    border-radius: 0.5rem;
    background-color: $color-white;
  }

  &__image-header {
    position: relative;
    width: 100%;
    height: 1.6rem;
    background-color: #eee;
  }

  &__image-header-element {
    float: left;
    margin-left: 0.4rem;
    transform: translateY(0.4rem);
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #aaa;
  }
}
</style>
