<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1e3 1e3"
    style="enable-background:new 0 0 1e3 1e3"
  >
    <g>
      <g><circle class="st0" cx="500" cy="500" r="500" /></g>
      <g>
        <path
          class="st1"
          d="M734.3 355H725c-18-36.6-62.7-81.7-183.9-81.7-121.1.0-213.6.0-213.6.0l167.8 228.2-184.9 214c0 0 82.3.0 227.1.0s172.1-57.1 187.2-81.9c5.1.0 9.6.0 9.6.0l-43.3 112.7H264.7v-7.5l186.5-216.1L264.7 270.5v-15.1h426L734.3 355z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #0d7bc5;
}
.st1 {
  fill: #fff;
}
</style>
