<template>
  <div class="lecture-group">
    <div class="lecture-group__header">
      <span>{{ t(payload[index].heading) }}</span>
    </div>
    <div class="lecture-group__container">
      <app-lecture
        v-for="(item, index2) in payload[index].topics"
        :key="JSON.stringify(item)"
        :indices="[index, index2]"
        :contentsPage="contentsPage"
        :payload2="payload"
        :lectureData="lectureData"
        :lectureName2="lectureName"
        @lectureChanged="$emit('lectureChanged')"
        @initializeCourse="$emit('initializeCourse')"
      ></app-lecture>
    </div>
  </div>
</template>

<script>
import AppLecture from "@/components/Molecules/Lecture.vue";

export default {
  name: "lectureGroup",
  components: {
    AppLecture
  },
  props: {
    index: {
      type: Number
    },
    contentsPage: {
      type: Number
    },
    payload: {
      type: Object
    },
    lectureData: {
      type: Object
    },
    lectureName: {
      type: String
    }
  },
  emits: ["lectureChanged", "initializeCourse"],
  computed: {
    coursePage() {
      return this.$store.state.coursePage;
    }
  },
  watch: {
    coursePage(to) {
      if (to === -1) {
        // this.a(this.payload[this.index].heading);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.lecture-group {
  width: 100%;
  padding-bottom: 0;
  border-radius: 0.2rem;
  background-color: $color-black;
  overflow: hidden;

  @include respond(phone) {
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  &__header {
    position: relative;
    width: 100%;
    height: 4.5rem;
    font-family: $font-primary-bold;
    font-size: 2.2rem;
    line-height: 1;
    color: #eee;
    background-color: #181818;
    border-bottom: 0.375rem solid $color-black;

    @include respond(phone) {
      height: 4rem;
      border-radius: 0.4rem;
      background-color: #1c1c1c;
    }

    & span {
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);

      @include respond(phone) {
        left: 0.9rem;
      }
    }
  }
}
</style>
