<template>
  <div class="input-complex">
    <!-- <label class="input-complex__label">
      <app-icon
        :icon="icon"
        :stroke="1.5"
        :iconWidth="3"
        class="input-complex__icon"
      />
    </label> -->
    <input
      v-model="inputText"
      @focus="$emit('focusEvent', true)"
      @input="$emit('inputEvent', inputText)"
      @keyup="$emit('keyupEvent', $event)"
      @blur="$emit('blurEvent', inputText)"
      :type="inputType"
      :autocomplete="autocompleteInput"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      class="input-complex__input"
      :class="{ 'input-complex__input--password': type === 'password' }"
      :id="id"
    />
    <span
      class="input-complex__placeholder"
      :class="{
        'input-complex__placeholder--small':
          inputText.length > 0 || placeholderIsSmall
      }"
      >{{ placeholder }}</span
    >
    <button
      v-if="eyeIsShown"
      @mousedown="$emit('mousedownEvent', true)"
      @mouseup="$emit('mouseupEvent', true)"
      @click="revealPassword()"
      class="input-complex__reveal-password"
    >
      <div
        class="input-complex__eye"
        :class="{ 'input-complex__eye-is-crossed': inputType === 'text' }"
      ></div>
    </button>
    <div class="input-complex__underline"></div>
  </div>
</template>

<script>
// import AppIcon from "@/components/Atoms/Icons/Icon.vue";

export default {
  name: "inputComplex",
  props: {
    icon: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "input_id"
    },
    type: {
      type: String,
      default: "text"
    },
    autocompleteInput: {
      type: String,
      default: "off"
    },
    placeholder: {
      type: String,
      default: ""
    },
    placeholderIsSmall: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    eyeIsShown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputText: "",
      inputType: "text"
    };
  },
  components: {
    // AppIcon
    // AppLock
  },
  methods: {
    revealPassword() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
      let passwordField = document.getElementById(this.id);
      passwordField.focus();
    }
  },
  watch: {
    text(to) {
      this.inputText = to;
    }
  },
  created() {
    this.inputType = this.type;
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.input-complex {
  position: relative;
  margin-bottom: 2.75rem;
  border-radius: 0.2rem;
  // // // overflow: hidden;

  &__label {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 5rem;
    height: 100%;
    background-color: #2a2a2a;
    transform-style: preserve-3d;

    opacity: 1;

    &::after {
      @include pseudo-element;
      left: 1.7rem;
      top: 0.7rem;
      z-index: -1;
      transform: rotate(45deg);
      width: 3.53553rem;
      height: 3.53553rem;
      background-color: #2a2a2a;
    }
  }

  &__icon {
    position: absolute;
    left: 1.05rem;
    top: 1.2rem;
    width: 2.625rem;
    height: 2.625rem;
    color: $color-white;
  }

  &__input {
    width: 100%;
    height: 5rem;
    border: none;
    border-radius: 10rem;
    padding: 1.4rem 4.8rem 0;
    // padding: 1.4rem 5.7rem 0 4.8rem;
    font-family: $font-primary;
    font-size: 2.4rem;
    color: $color-white;
    background-color: #626262;
    transition: background-color 0.3s;

    @include respond(phone) {
      height: 6.5rem;
    }

    &--password {
      padding: 1.4rem 5.75rem 0 4.8rem;
    }

    &:focus {
      outline: none;
    }

    &::after:focus {
      border: 2px solid $color-primary;
    }
  }

  &__placeholder {
    display: block;
    position: absolute;
    left: 4.8rem;
    top: 0.9rem;
    font-family: $font-primary;
    font-size: 2.4rem;
    color: $color-white;
    transform-origin: left top;
    transition: transform 0.3s ease-in-out;
    will-change: transform;
    pointer-events: none;

    font-family: $font-primary-bold;
    font-size: 2.4rem;
    color: #c8c8c8;

    @include respond(phone) {
      // top: 1rem;
      font-size: 3rem;
      color: #ddd;
    }

    .input-complex__input:focus + &,
    &--small {
      transform: translateY(-0.8rem) scale(0.6);
    }
  }

  &__reveal-password {
    position: absolute;
    right: 0;
    top: 0;
    width: 5.5rem;
    height: 5rem;
    border: none;
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
    background-color: transparent;
    cursor: pointer;
  }

  &__eye {
    position: absolute;
    left: calc(50% - 0.775rem);
    top: calc(50% + 0.1rem);
    width: 2.2rem; // should be 2.26274rem
    height: 2.2rem; // should be 2.26274rem
    // border: 1.5px solid $color-white;
    border: 1.5px solid #eee;
    border-radius: 1.8rem 0.25rem; // adjust!
    transform: translate(-50%, -50%) rotate(45deg);

    transition: opacity 0.2s;

    @include respond(phone) {
      left: calc(50% - 1.25rem);
      top: calc(50% + 0.7rem);
      transform: translate(-50%, -50%) rotate(45deg) scale(1.25);
    }

    &::before {
      @include pseudo-element;
      left: 0.5rem;
      left: calc(25% - 0.25px);
      top: calc(25% - 0.25px);
      width: 1rem;
      height: 1rem;
      // border: 1.5px solid $color-white;
      border: 1.5px solid #eee;
      border-radius: 50%;
    }

    &::after {
      @include pseudo-element;
      left: calc(-0.4rem - 0.5px);
      top: calc(0.95rem - 0.5px);
      width: 2.8rem;
      height: 1.5px;
      border-radius: 10rem;
      background-color: $color-white;
      background-color: #eee;
      box-shadow: 0 0 0 1px #626262;
      opacity: 0;
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out, opacity 0s 0.2s;
    }

    &-is-crossed::after {
      opacity: 1;
      transform: scaleX(1);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__underline {
    position: absolute;
    left: 1.8rem;
    bottom: 0;
    width: calc(100% - 3.6rem);
    border-bottom-left-radius: 60%;
    border-bottom-right-radius: 60%;
    height: 2px;
    background-color: $color-primary;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;

    .input-complex__input:focus + .input-complex__placeholder + & {
      // transform: scaleX(1);
    }
  }
}
</style>
