<template>
  <div class="plan">
    <div v-if="$t(data)[5] !== undefined" class="plan__top-offer">
      {{ $t(data)[5] }}
    </div>
    <div class="plan__header">
      {{ $t(data)[0] }}
    </div>
    <div class="plan__per-month">
      {{ $t(data)[1] }}
    </div>
    <div class="plan__period">
      {{ $t(data)[2] }}
    </div>
    <div class="plan__total-payment">
      <div>{{ $t(data)[3] }}</div>
      {{ $t(data)[4] }}
    </div>
    <app-button
      @mousedown="toggleModal('app-register-modal', 'register')"
      :text="$t('button_select')"
      variation="primary"
      class="plan__button"
    ></app-button>
  </div>
</template>

<script>
// // // import AppButton from "@/components/Atoms/Button/Button.vue";
import AppButton from "@/components/Atoms/Button.vue";

export default {
  name: "plan",
  components: {
    AppButton
  },
  props: {
    data: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.plan {
  position: relative;
  margin-top: 6.3rem;
  text-align: center;
  border-radius: 0.6rem;
  background-color: #101010;
  color: $color-white !important;

  @include respond(phone) {
    margin-top: 4rem;
    // background-color: #1a1a1a;
    background-color: #161616;
  }

  &:nth-child(5),
  &:nth-child(6) {
    /* @include respond(phone) {
      margin-top: 1.5rem;
    } */

    @media only screen and (max-width: 530px) {
      margin-top: 5.87rem;
    }
  }

  &:nth-child(6) {
    border-top-right-radius: 0;
  }
}

.plan__top-offer {
  position: absolute;
  right: 0;
  transform: translateY(-100%);
  padding: 0 1.1rem;
  font-family: $font-primary-bold;
  font-size: 1.6rem;
  line-height: 1.6;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  background-color: $color-primary-dark;

  @include respond(phone) {
    font-size: 1.8rem;
  }
}

.plan__header {
  margin-top: 5rem;
  font-size: 3.6rem;
  line-height: 1;
  font-style: italic;

  &::after {
    @include pseudo-element;
    left: 50%;
    transform: translate(-50%, 2rem);
    width: 6.4rem;
    height: 0.15rem;
    background-color: #999;
  }
}

.plan__per-month {
  height: 2.2rem;
  margin-top: 4.4rem;
  font-size: 2.2rem;
  line-height: 1;
  color: #999;

  @include respond(phone) {
    font-size: 2.4rem;
    color: #ccc;
  }
}

.plan__period {
  margin-top: 6.25rem;
  margin-bottom: 5rem;
  height: 3.6rem;
  font-size: 3.6rem;
  line-height: 1;
}

.plan__total-payment {
  height: 5.65rem;
  font-size: 1.8rem;
  color: #999;

  @include respond(phone) {
    font-size: 2rem;
    color: #bbb;
  }
}

.plan__button {
  margin: 5rem auto !important;
  width: min(15.52rem, 100% - 2rem);
  height: 4.4rem;
  text-align: center;
  font-size: 2.4rem;
  background-color: $color-primary-dark;

  @include respond(phone) {
    height: 5rem;
  }
}

.plans__plan:nth-child(3) .plan__button,
.plans__plan:nth-child(4) .plan__button,
.plans__plan:nth-child(5) .plan__button {
  font-family: $font-primary;
  background-color: #222;

  @include respond(phone) {
    background-color: #303030;
  }

  &:hover {
    background-color: #333;

    @include respond(phone) {
      background-color: #404040;
    }
  }
}
</style>
