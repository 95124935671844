<template>
  <div class="hero__video-container">
    <video
      v-show="videoIsShown"
      muted
      preload
      playsinline
      loop
      autoplay
      class="hero__video"
    >
      <source
        src="@/assets/Home/Hero/formula_gold.mp4#t=0.01"
        type="video/mp4"
      />
      {{ $t("cannot_play_video") }}
    </video>
  </div>
  <div class="hero">
    <div class="hero__bottom-container">
      <div class="hero__content-container">
        <div class="hero__text-container">
          <h1 class="hero__heading">
            {{ $t("hero_heading") }}
          </h1>
          <p class="hero__paragraph">
            {{ $t("hero_paragraph") }}
          </p>
          <app-effect-button
            @click="toggleModal('app-register-modal')"
            :text="$t('button_hero')"
            class="hero__button"
          ></app-effect-button>
        </div>
      </div>
      <div class="hero__smartphone">
        <app-smartphone-white></app-smartphone-white>
        <app-mathopia-logo class="hero__smartphone-logo"></app-mathopia-logo>
        <p class="hero__smartphone-text hero__smartphone-text--1">
          <span>
            {{ $t("hero_smartphone")[0] }}
          </span>
          <span>
            {{ $t("hero_smartphone")[1] }}
          </span>
        </p>
        <p class="hero__smartphone-text hero__smartphone-text--2">
          {{ $t("hero_smartphone")[2] }}
        </p>
        <p class="hero__smartphone-text hero__smartphone-text--3">
          {{ $t("hero_smartphone")[3] }}
        </p>
      </div>
    </div>
  </div>
  <div class="hero-sp">
    <div class="hero-sp__video-container">
      <img
        src="@/assets/Home/Hero/formula_gold.gif"
        alt="Logo von Mathopia"
        class="hero-sp__video"
      />
    </div>
    <h1 class="hero-sp__heading">
      {{ $t("hero_heading") }}
    </h1>
    <app-effect-button
      @click="toggleModal('app-register-modal')"
      :text="$t('button_hero')"
      class="hero-sp__button"
    ></app-effect-button>
    <p class="hero-sp__paragraph">{{ $t("hero_paragraph") }}</p>
  </div>
</template>

<script>
import AppEffectButton from "@/components/Atoms/EffectButton.vue";
import AppSmartphoneWhite from "@/components/Atoms/Images/SmartphoneWhite.vue";
import AppMathopiaLogo from "@/components/Atoms/Icons/MathopiaLogo.vue";

export default {
  data() {
    return {
      hero: {},
      video: {},
      videoIsShown: false
    };
  },
  components: {
    AppEffectButton,
    AppSmartphoneWhite,
    AppMathopiaLogo
  },
  methods: {
    scrollHandler() {
      let heroTop = this.top(this.hero);

      let scaleVideo = this.value(
        heroTop,
        1,
        0.5,
        0,
        -this.$store.state.windowHeight / 2,
        "in",
        2
      );
      this.video.style.transform =
        "translate(-50%, -52%) scale(" + scaleVideo + ")";

      if (heroTop < -this.$store.state.windowHeight / 2.5) {
        this.video.style.visibility = "hidden";
      } else {
        this.video.style.visibility = "visible";
      }
    }
  },
  created() {
    setTimeout(() => {
      this.hero = document.querySelector(".hero");
      this.video = document.querySelector(".hero__video");

      this.videoIsShown = true;

      setTimeout(() => {
        this.scrollHandler();
        const storeScroll = () => {
          this.scrollHandler();
        };
        document.addEventListener("scroll", this.debounce(storeScroll), {
          passive: true
        });
        window.addEventListener("resize", this.scrollHandler, false);
      }, 500);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.hero {
  position: relative;
  z-index: 4;
  padding-bottom: 6rem;
  min-height: max(calc(100vh + 1rem), 64rem);

  background: linear-gradient(
    185deg,
    transparent 0,
    transparent calc(max(50vh, 34rem) + 4.37443vw),
    $color-white calc(max(50vh, 34rem) + 4.37443vw + 1px),
    $color-white 100%
  );

  @include respond(phone) {
    display: none;
  }

  &__video-container {
    position: fixed;
    left: calc(50% - min(40%, 0.5 * #{$width-center}));
    top: 3rem;
    width: min(80%, #{$width-center});
    height: max(45vh, 40rem);

    overflow: hidden;

    @include respond(phone) {
      display: none;
    }
  }

  &__video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
    will-change: transform;
    width: clamp(60rem, 120%, 110rem);

    @include respond(tab-land) {
      width: clamp(60rem, 120%, 90rem);
    }

    @include respond(small-height) {
      top: 42.5%;
      width: clamp(50rem, 110vh, 90rem);
    }
  }

  &__bottom-container {
    width: min(#{$width-center}, 100% - 5rem);
    min-height: max(calc(100vh + 1rem), 64rem);
    margin-left: max(2.5rem, 50% - 0.5 * #{$width-center});
    padding-top: max(50vh, 34rem);

    display: grid;
  }

  &__content-container {
    align-self: end;
    padding: 9rem 35% calc(50vh - 56rem) 0;
  }

  &__heading {
    margin-bottom: 5.7rem;
    max-width: min(52rem, 100vw - 36rem);
    font-size: 5.5rem;
    line-height: 1.35;
    font-weight: 100;
    user-select: none;

    @include respond(desktop) {
      font-size: 5rem;
    }
  }

  &__paragraph {
    margin-bottom: 5.1rem;
    width: min(52rem, 100vw - 36rem);
    font-size: 2.45rem;
    line-height: 1.5;
    color: #555;

    @include respond(tab-land) {
      font-size: 2.7rem;
    }

    @include respond(tab-port) {
      font-size: 2.8rem;
      line-height: 1.35;
      color: #333;
    }

    @include respond(small-height) {
      font-size: 2.6rem;
      line-height: 1.25;
    }
  }

  &__button {
    width: max-content;
  }

  &__smartphone {
    position: absolute;
    right: max(2.5rem, 50% - 0.5 * #{$width-center});
    top: max(50vh, 32rem);
    transform: translateY(-10%);
    width: 30rem;

    @include respond(small-height) {
      transform: translateY(calc(-11.8% - 2vh)) scale(0.85);
      transform-origin: right top;
    }
  }

  &__smartphone-logo {
    position: absolute;
    left: 9.5%;
    top: 15.631%;
    height: 3.85%;
  }

  &__smartphone-text {
    position: absolute;
    font-size: 2.208rem;
    line-height: 1.4;
    color: $color-white;

    &--1 {
      left: 9.5%;
      top: 52.9%;
    }

    &--2 {
      font-size: 1.987rem;
      left: 13.82%;
      top: 0;
      transform: translateY(42.4rem);
    }

    &--3 {
      left: 23.32%;
      top: 0;
      transform: translateY(49.225rem);
    }

    & > span {
      display: block;
    }
  }
}

.hero-sp {
  display: none;
  position: relative;
  z-index: 100000; // was: 40
  min-height: 100vh;
  padding-bottom: 10.5rem;

  background: linear-gradient(
    5deg,
    $color-white 0,
    $color-white calc(100% - 50vh + 0.5rem),
    $color-black calc(100% - 50vh + 0.5rem + 1px),
    $color-black 100%
  );

  @include respond(phone) {
    display: block;
  }

  &__video-container {
    position: relative;
    margin-bottom: 14.9vh;
    transform: translateY(max(0rem, 25vh - 26.25rem));
    width: 100%;
    height: 40vh;

    overflow: hidden;
  }

  &__video {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    margin-top: 3.75rem;
    max-width: 72rem;
  }

  &__lottie {
    width: 64rem;
    transform: translate(-7.8rem, 4.25rem);
  }

  &__heading {
    margin-top: 16rem;
    margin-bottom: 4.4rem;
    padding: 0 1.5rem;
    font-size: 5rem;
    line-height: 1.4;
    font-weight: 100;
    text-align: center;
  }

  &__button {
    left: 50%;
    margin-top: 3.95rem;
    margin-bottom: 10.5rem;
    width: 66.667%;
    height: 6.5rem;
    border-radius: 10rem;
    transform: translateX(-50%);
  }

  &__paragraph {
    padding: 0 1.5rem;
    width: 100%;
    font-size: 3.2rem;
    line-height: 1.4;
    text-align: center;
    color: #333; // was: #222
  }
}
</style>
