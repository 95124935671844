<template>
  <button
    class="button-x"
    :class="{ 'button-x__black': xColor === 'black' }"
  ></button>
</template>

<script>
export default {
  name: "appButtonX",
  props: {
    xColor: {
      type: String,
      default: "white"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.button-x {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;

  transition: transform 0.25s;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: rotate(-90deg);
    }
  }

  &::before,
  &::after {
    @include pseudo-element;
    top: calc(50% - 1px);
    width: 100%;
    height: 1.5px;
    border-radius: 1rem;
    background-color: $color-white;
    transition: transform 0.25s 0.3s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover::before {
      transform: rotate(90deg);
    }

    &:hover::after {
      transform: rotate(-90deg);
    }
  }

  &.button-x__black {
    &::before,
    &::after {
      background-color: $color-black;
    }
  }
}
</style>
