<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    style="enable-background:new 0 0 24 24;"
    xml:space="preserve"
  >
    <g class="lock">
      <path
        class="st0"
        d="M5.6,9.5c-1.2,0-2.1,0.9-2.1,2.1l0.9,9.8c0,1.2,1,2.1,2.1,2.1h10.9c1.2,0,2.1-0.9,2.1-2.1l0.9-9.8
		c0-1.2-1-2.1-2.1-2.1H5.6z"
      />
      <path
        class="st0"
        d="M13.1,19.5h-2.2L10.4,16c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5L13.1,19.5z"
      />
      <path
        class="st0"
        :class="{ 'lock-is-unlocked': isUnlocked }"
        d="M7.5,9.5V5c0-2.3,2.1-4.2,4.5-4.2c2.4,0,4.5,1.9,4.5,4.2v4.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "lock",
  props: {
    isUnlocked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style type="text/css" scoped>
.lock {
  transform: scale(0.875) translateY(0.5px);
  transform-origin: center;
}

.st0 {
  fill: none;
  stroke: #eee;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.lock-is-unlocked {
  transform: rotate(30deg);
  transform-origin: 70% 35%;

  transition: transform 0.25s 0.3s;
}
</style>
