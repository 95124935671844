<template>
  <div class="introduction2">
    <div class="introduction2__container">
      <div class="introduction2__overflow-container">
        <img
          src="@/assets/Home/Introduction2/smartphone_black2.png"
          alt="A smartphone"
          class="introduction2__smartphone"
        />
        <div class="introduction2__content-container">
          <div class="introduction2__content-inner-container">
            <app-smartphone2 class="introduction2__content"></app-smartphone2>
          </div>
        </div>
        <div class="introduction2__blurry-header"></div>
        <div
          class="introduction2__content-container introduction2__content-container--2"
        >
          <div class="introduction2__smartphone-header">
            <div class="introduction2__smartphone-section">
              3<span class="introduction2__slash">/</span>12
            </div>
            <div class="introduction2__smartphone-progress">
              <div
                aria-hidden="true"
                class="introduction2__smartphone-progress-bar"
              ></div>
            </div>
            <div class="introduction2__smartphone-menu">
              <svg>
                <line x1="0" y1="0" x2="100%" y2="0" />
                <line x1="0" y1="0" x2="100%" y2="0" />
                <line x1="0" y1="0" x2="100%" y2="0" />
              </svg>
            </div>
          </div>
        </div>

        <img
          src="@/assets/Home/Introduction2/cover_smartphone2.png"
          alt="A smartphone"
          class="introduction2__smartphone"
        />
      </div>
      <div class="introduction2__text-container">
        <div class="introduction2__explanation-container">
          <div class="introduction2__explanation--2">
            <h2 class="introduction2__screen-heading">
              {{ $t("step2_heading")[0] }}&nbsp; {{ $t("step2_heading")[1] }}
            </h2>
            <p class="introduction2__screen-description">
              {{ $t("step2_paragraph") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSmartphone2 from "@/assets/Home/Introduction2/Smartphone2.vue";

export default {
  data() {
    return {
      section: {},
      smartphone: {},
      content: {}
    };
  },
  components: {
    AppSmartphone2
  },
  methods: {
    scrollHandler() {
      let sectionTop = this.top(this.section);

      /* if (sectionTop <= this.$store.state.windowHeight / 2) {
        this.smartphone.style.display = "block";
        // this.section.style.display = "block";
      } else {
        this.section.style.display = "none";
      } */

      let backgroundColor = this.value(
        sectionTop,
        26,
        0,
        this.$store.state.windowHeight / 2,
        0,
        "in",
        1
      );
      this.introductionContainer.style.backgroundColor =
        "rgba(" +
        backgroundColor +
        " ," +
        backgroundColor +
        " ," +
        backgroundColor +
        ")";

      /* if (this.isMobileDevice) {
        this.scaleOverflow = Math.min(1, (0.62 * this.windowOuterHeight) / 80);
      } else {
        this.scaleOverflow = Math.min(1, (0.66 * this.windowHeight) / 80);
      } */

      // this.windowOuterHeight = window.outerHeight / this.remToPx(1);
      // let scaleOverflow = Math.min(0.8, (0.62 * this.windowOuterHeight) / 80);

      let scaleOverflow = this.$store.state.scaleOverflow;

      let mediaWidth = window.matchMedia("(max-width: 600px)");
      let mediaMediumWidth = window.matchMedia("(max-width: 1000px)"); // was: 750px
      let mediaHeight = window.matchMedia("(max-height: 520px)");

      let scaleFactor =
        1 + (this.$store.state.windowWidth - 750) / (750 * 2.307);

      if (!mediaMediumWidth.matches && !mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) scale(" + scaleOverflow1 + ")"; // translateY 6.5rem
      } else if (!mediaWidth.matches && !mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.15 * scaleFactor * scaleOverflow,
          0.7667 *
            scaleFactor *
            (Math.min(this.$store.state.windowWidth, 360) / 360) *
            scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translateY(15rem) scale(" +
          scaleOverflow1 +
          ")";
      } else if (!mediaHeight.matches) {
        let scaleOverflow1 = this.value(
          sectionTop,
          0.975 * scaleOverflow,
          0.61579 *
            (Math.min(this.$store.state.windowWidth, 360) / 360) *
            scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 0, 0) translateY(15rem) scale(" +
          scaleOverflow1 +
          ")";
      } else {
        let scaleOverflow1 = this.value(
          sectionTop,
          1.5 * scaleOverflow,
          1.2 * scaleOverflow,
          this.$store.state.windowHeight,
          0,
          "inOut",
          2
        );

        this.smartphone.style.transform =
          "translate3d(0, 5.75rem, 0) scale(" + scaleOverflow1 + ")";
      }

      let scaleContent = this.$store.state.remValue / 10;
      let translateContent2 = this.value(
        sectionTop,
        0,
        -100,
        30,
        -this.$store.state.windowHeight / 1.5,
        "inOut",
        2
      );
      this.content.style.transform =
        "translateY(" + translateContent2 + "rem) scale(" + scaleContent + ")";

      let opacityExplanation = this.value(sectionTop, 0, 1, 300, 0, "inOut", 2);
      let translateYExplanation = this.value(
        sectionTop,
        3.5,
        0,
        300,
        0,
        "inOut",
        2
      );

      this.introductionExplanation.style.opacity = opacityExplanation;
      this.introductionExplanation.style.transform =
        "translateY(" + translateYExplanation + "rem)";

      let progressWidth = this.value(
        sectionTop,
        0.2,
        0.45,
        30,
        -this.$store.state.windowHeight / 1.5,
        "inOut",
        2
      );
      this.progressBar.style.transform = "scaleX(" + progressWidth + ")";

      /* let backgroundColor = this.value(
        sectionTop,
        26,
        0,
        0.5 * this.$store.state.windowHeight,
        0,
        "inOut",
        2
      ); */
      /* this.section.style.backgroundColor =
        "rgba(" +
        backgroundColor +
        "," +
        backgroundColor +
        "," +
        backgroundColor +
        ")"; */
      /* if (sectionTop <= this.$store.state.windowHeight / 2) {
        this.section.style.backgroundColor = "#000";
      } else {
        this.section.style.backgroundColor = "#1a1a1a";
      } */

      /* let scaleSmartphone = this.value(
        sectionTop,
        1.538,
        0.969, // was 0.875
        this.$store.state.windowHeight,
        0,
        "inOut",
        2
      );
      this.smartphone.style.transform = "scale(" + scaleSmartphone + ")";

      let translateContent = this.value(
        sectionTop,
        31,
        -1000,
        this.$store.state.windowHeight / 5,
        -this.$store.state.windowHeight / 2,
        "in",
        2
      );
      this.content.style.transform = "translateY(" + translateContent + "px)"; */
    }
  },
  created() {
    setTimeout(() => {
      this.section = document.querySelector(".introduction2");
      this.introductionContainer = document.querySelector(
        ".introduction2__container"
      );
      this.smartphone = document.querySelector(
        ".introduction2__overflow-container"
      );
      this.content = document.querySelector(".introduction2__content");
      this.progressBar = document.querySelector(
        ".introduction2__smartphone-progress-bar"
      );
      this.introductionExplanation = document.querySelector(
        ".introduction2__explanation--2"
      );
      this.scrollHandler();

      const storeScroll = () => {
        this.scrollHandler();
      };
      document.addEventListener("scroll", this.debounce(storeScroll), {
        passive: true
      });

      let vm = this;
      window.addEventListener("resize", vm.scrollHandler, false);
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.introduction2 {
  position: relative;
  width: 100%;
  height: calc(200vh + 40rem);
  z-index: 1;
  // // // z-index: -3;

  margin-bottom: -100vh;

  &__container {
    position: sticky;
    left: 0;
    top: 100vh;
    transform: translateY(-100vh);
    width: 100%;
    height: 100vh;
    // background-color: transparent !important;

    overflow: hidden;

    @include respond(tiny-height) {
      height: calc(100vh + 23.5rem);
    }
  }

  &__overflow-container {
    position: absolute;
    left: calc(50vw - 0.5 * #{$width-center});
    top: 0;
    width: $width-center;
    height: 100vh;
    will-change: transform;
    transform-origin: top;

    @media (pointer: coarse) {
      transition: transform 0.075s linear;
    }
  }

  &__smartphone {
    position: absolute;
    left: 50%;
    top: 25.5vh;
    width: 97rem;
    transform: translateX(-50%);

    // opacity: 0.5;

    @include respond(phone) {
      // width: 61%;
    }
  }

  &__content-container {
    position: absolute;
    left: calc(50% - 1.7rem);
    top: calc(25.5vh - 22.3rem);
    width: 39rem;
    height: 81rem;
    border-bottom-left-radius: 5rem;

    transform: translate3d(-58%, 5.5rem, 5rem) perspective(262.5rem)
      rotateY(-1.5deg) rotateX(55.5deg) rotateZ(-41.625deg) scale(1.12);
    overflow: hidden;
    backface-visibility: hidden;

    &--2 {
      transform: translate3d(-58%, 5.5rem, 50rem) perspective(262.5rem)
        rotateY(-1.5deg) rotateX(55.5deg) rotateZ(-41.625deg) scale(1.12);
      overflow: visible;
    }
  }

  &__content-inner-container {
    transform-origin: left top;
    backface-visibility: hidden;
  }

  &__content {
    background-color: transparent;
    transform-origin: left top;
    backface-visibility: hidden;
    will-change: transform;
  }

  &__blurry-header {
    position: absolute;
    left: 0rem;
    top: 25.15vh;
    width: 37rem;
    height: 9.825rem;
    transform: translate(13%, 53.4%) rotate(-23.95deg) skewX(35deg) scaleY(-1);
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;

    background-color: rgba(#1e2432, 0.95);

    @supports (-webkit-backdrop-filter: blur(10px)) or
      (backdrop-filter: blur(10px)) {
      background-color: transparent;
      backdrop-filter: blur(10px);
    }

    &::after {
      @include pseudo-element;
      left: 3.5rem;
      top: 0;
      width: calc(100% - 5.75rem);
      height: 0.5px;
      background-color: #2a2a2a;
    }
  }

  &__smartphone-header {
    position: absolute;
    left: 0;
    top: -4px;
    width: 100%;
    height: 15.25rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  &__smartphone-section {
    position: absolute;
    left: 2.2rem;
    top: 7.5rem;
    font-family: $font-primary-bold;
    font-size: 2.5rem;
    color: #7cccff;

    @include respond(desktop-big) {
      font-size: 2.63889rem;
    }
    @include respond(desktop) {
      font-size: 2.8125rem;
    }
    @include respond(tab-land) {
      font-size: 2.91667rem;
    }
  }

  &__slash {
    margin: 0 0.3rem;
  }

  &__smartphone-progress {
    position: absolute;
    left: 50%;
    top: 9.05rem;
    width: 15rem;
    height: 1rem;
    transform: translateX(-50%);
    border-radius: 10rem;
    background-color: #333;
    overflow: hidden;
  }

  &__smartphone-progress-bar {
    width: 100%;
    height: 100%;
    background-color: #0d7bc5;
    transform: scaleX(0.2);
    transform-origin: left;
  }

  &__smartphone-menu {
    position: absolute;
    right: 2.25rem;
    top: 8.6rem;
    width: 2.5rem;
    height: 1.9rem;

    & svg {
      position: absolute;
      width: 100%;

      line {
        stroke: #eee;
        stroke-width: 1.5;

        &:first-child {
          transform: translateY(1px);
        }

        &:nth-child(2) {
          transform: translateY(calc(0.85rem + 1px));
        }

        &:nth-child(3) {
          transform: translateY(calc(1.7rem + 1px));
        }
      }
    }
  }

  &__explanation--1 {
    position: absolute;
    top: 3.25rem;
    transform: translateX(calc(50vw - 50%));
    line-height: 1;
    transform-origin: 50% 750%;
  }

  &__text-container {
    position: absolute;
    left: 50vw;
    bottom: 0;
    width: 100%;
    height: 25vh;
    transform: translateX(-50%);
    font-family: $font-primary-bold;
    font-size: 6.5rem;
    color: #bbb;
  }

  &__explanation-container {
    position: absolute;
    width: 100%;
    transform: translateY(min(6.5rem, calc(25vh - 100% - 4.5rem)));
  }

  &__explanation--2 {
    width: 100%;

    display: grid;
    grid-template-columns: 4rem 1fr $width-center 1fr 4rem;

    @include respond(tab-port) {
      // was: tab-land
      grid-template-columns: 3rem 0 1fr 0 3rem;
    }

    @include respond(phone) {
      grid-template-columns: 1.5rem 0 1fr 0 1.5rem;
    }
  }

  &__screen-heading {
    grid-column: 3 / 4;

    width: 57rem;
    font-family: $font-primary-bold;
    font-size: 2.8rem;
    margin-bottom: 2rem;
    color: $color-white;
    z-index: 1;
    line-height: 1.3;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__screen-description {
    grid-column: 3 / 4;

    width: 54rem;
    color: rgb(200, 200, 200);
    font-family: $font-primary;
    font-size: 2.2rem;
    z-index: 1;

    @include respond(phone) {
      width: 100%;
      font-size: 2.6rem; // was: 2.6rem
      line-height: 1.4; // was: 1.6
      color: rgb(220, 220, 220);
    }
  }
}
</style>
